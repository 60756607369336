const GET_IMPIAN_LOADING = 'GET_IMPIAN_LOADING'
const GET_IMPIAN_SUCCESS = 'GET_IMPIAN_SUCCESS'
const GET_IMPIAN_FAILED = 'GET_IMPIAN_FAILED'
const GET_IMPIAN_DETAIL_LOADING = 'GET_IMPIAN_DETAIL_LOADING'
const GET_IMPIAN_DETAIL_SUCCESS = 'GET_IMPIAN_DETAIL_SUCCESS'
const GET_IMPIAN_DETAIL_FAILED = 'GET_IMPIAN_DETAIL_FAILED'
const CREATE_IMPIAN_LOADING = 'CREATE_IMPIAN_LOADING'
const CREATE_IMPIAN_SUCCESS = 'CREATE_IMPIAN_SUCCESS'
const CREATE_IMPIAN_FAILED = 'CREATE_IMPIAN_FAILED'
const PUT_IMPIAN_LOADING = 'PUT_IMPIAN_LOADING'
const PUT_IMPIAN_SUCCESS = 'PUT_IMPIAN_SUCCESS'
const PUT_IMPIAN_FAILED = 'PUT_IMPIAN_FAILED'
const PUT_IMPIAN_SHOW_LOADING = 'PUT_IMPIAN_SHOW_LOADING'
const PUT_IMPIAN_SHOW_SUCCESS = 'PUT_IMPIAN_SHOW_SUCCESS'
const PUT_IMPIAN_SHOW_FAILED = 'PUT_IMPIAN_SHOW_FAILED'
const PUT_IMPIAN_HIDE_LOADING = 'PUT_IMPIAN_HIDE_LOADING'
const PUT_IMPIAN_HIDE_SUCCESS = 'PUT_IMPIAN_HIDE_SUCCESS'
const PUT_IMPIAN_HIDE_FAILED = 'PUT_IMPIAN_HIDE_FAILED'
const PUT_IMPIAN_DELETE_LOADING = 'PUT_IMPIAN_DELETE_LOADING'
const PUT_IMPIAN_DELETE_SUCCESS = 'PUT_IMPIAN_DELETE_SUCCESS'
const PUT_IMPIAN_DELETE_FAILED = 'PUT_IMPIAN_DELETE_FAILED'

export {
  GET_IMPIAN_LOADING,
  GET_IMPIAN_SUCCESS,
  GET_IMPIAN_FAILED,
  GET_IMPIAN_DETAIL_LOADING,
  GET_IMPIAN_DETAIL_SUCCESS,
  GET_IMPIAN_DETAIL_FAILED,
  CREATE_IMPIAN_LOADING,
  CREATE_IMPIAN_SUCCESS,
  CREATE_IMPIAN_FAILED,
  PUT_IMPIAN_LOADING,
  PUT_IMPIAN_SUCCESS,
  PUT_IMPIAN_FAILED,
  PUT_IMPIAN_SHOW_LOADING,
  PUT_IMPIAN_SHOW_SUCCESS,
  PUT_IMPIAN_SHOW_FAILED,
  PUT_IMPIAN_HIDE_LOADING,
  PUT_IMPIAN_HIDE_SUCCESS,
  PUT_IMPIAN_HIDE_FAILED,
  PUT_IMPIAN_DELETE_LOADING,
  PUT_IMPIAN_DELETE_SUCCESS,
  PUT_IMPIAN_DELETE_FAILED
}
