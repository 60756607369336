const GET_ADMIN_LOADING = 'GET_ADMIN_LOADING'
const GET_ADMIN_SUCCESS = 'GET_ADMIN_SUCCESS'
const GET_ADMIN_FAILED = 'GET_ADMIN_FAILED'
const GET_ADMIN_DETAIL_FAILED = 'GET_ADMIN_DETAIL_FAILED'
const GET_ADMIN_DETAIL_SUCCESS = 'GET_ADMIN_DETAIL_SUCCESS'
const GET_ADMIN_DETAIL_LOADING = 'GET_ADMIN_DETAIL_LOADING'
const POST_MANAGE_ADMIN_LOADING = 'POST_MANAGE_ADMIN_LOADING'
const POST_MANAGE_ADMIN_FAILED = 'POST_MANAGE_ADMIN_FAILED'
const POST_MANAGE_ADMIN_SUCCESS = 'POST_MANAGE_ADMIN_SUCCESS'
const PUT_MANAGE_ADMIN_LOADING = 'PUT_MANAGE_ADMIN_LOADING'
const PUT_MANAGE_ADMIN_FAILED = 'PUT_MANAGE_ADMIN_FAILED'
const PUT_MANAGE_ADMIN_SUCCESS = 'PUT_MANAGE_ADMIN_SUCCESS'
const GET_ADMIN_APPROVAL_LOADING = 'GET_ADMIN_APPROVAL_LOADING'
const GET_ADMIN_APPROVAL_SUCCESS = 'GET_ADMIN_APPROVAL_SUCCESS'
const GET_ADMIN_APPROVAL_FAILED = 'GET_ADMIN_APPROVAL_FAILED'
const GET_ADMIN_APPROVAL_DETAIL_LOADING = 'GET_ADMIN_APPROVAL_DETAIL_LOADING'
const GET_ADMIN_APPROVAL_DETAIL_SUCCESS = 'GET_ADMIN_APPROVAL_DETAIL_SUCCESS'
const GET_ADMIN_APPROVAL_DETAIL_FAILED = 'GET_ADMIN_APPROVAL_DETAIL_FAILED'
const GET_ENTRANCE_LIST_LOADING = 'GET_ENTRANCE_LIST_LOADING'
const GET_ENTRANCE_LIST_SUCCESS = 'GET_ENTRANCE_LIST_SUCCESS'
const GET_ENTRANCE_LIST_FAILED = 'GET_ENTRANCE_LIST_FAILED'
const PUT_ADMIN_APPROVAL_LOADING = 'PUT_ADMIN_APPROVAL_LOADING'
const PUT_ADMIN_APPROVAL_FAILED = 'PUT_ADMIN_APPROVAL_FAILED'
const PUT_ADMIN_APPROVAL_SUCCESS = 'PUT_ADMIN_APPROVAL_SUCCESS'
const PUT_APPROVE_ADMIN_APPROVAL_LOADING = 'PUT_APPROVE_ADMIN_APPROVAL_LOADING'
const PUT_APPROVE_ADMIN_APPROVAL_FAILED = 'PUT_APPROVE_ADMIN_APPROVAL_FAILED'
const PUT_APPROVE_ADMIN_APPROVAL_SUCCESS = 'PUT_APPROVE_ADMIN_APPROVAL_SUCCESS'
const PUT_REJECT_ADMIN_APPROVAL_LOADING = 'PUT_REJECT_ADMIN_APPROVAL_LOADING'
const PUT_REJECT_ADMIN_APPROVAL_FAILED = 'PUT_REJECT_ADMIN_APPROVAL_FAILED'
const PUT_REJECT_ADMIN_APPROVAL_SUCCESS = 'PUT_REJECT_ADMIN_APPROVAL_SUCCESS'

const GET_ADMIN_UNIV_LOADING = 'GET_ADMIN_UNIV_LOADING'
const GET_ADMIN_UNIV_SUCCESS = 'GET_ADMIN_UNIV_SUCCESS'
const GET_ADMIN_UNIV_FAILED = 'GET_ADMIN_UNIV_FAILED'

export {
  GET_ADMIN_LOADING,
  GET_ADMIN_SUCCESS,
  GET_ADMIN_FAILED,
  GET_ADMIN_DETAIL_FAILED,
  GET_ADMIN_DETAIL_SUCCESS,
  GET_ADMIN_DETAIL_LOADING,
  POST_MANAGE_ADMIN_LOADING,
  POST_MANAGE_ADMIN_FAILED,
  POST_MANAGE_ADMIN_SUCCESS,
  PUT_MANAGE_ADMIN_LOADING,
  PUT_MANAGE_ADMIN_FAILED,
  PUT_MANAGE_ADMIN_SUCCESS,
  GET_ADMIN_APPROVAL_LOADING,
  GET_ADMIN_APPROVAL_SUCCESS,
  GET_ADMIN_APPROVAL_FAILED,
  GET_ADMIN_APPROVAL_DETAIL_FAILED,
  GET_ADMIN_APPROVAL_DETAIL_SUCCESS,
  GET_ADMIN_APPROVAL_DETAIL_LOADING,
  GET_ENTRANCE_LIST_LOADING,
  GET_ENTRANCE_LIST_SUCCESS,
  GET_ENTRANCE_LIST_FAILED,
  PUT_ADMIN_APPROVAL_LOADING,
  PUT_ADMIN_APPROVAL_SUCCESS,
  PUT_ADMIN_APPROVAL_FAILED,
  PUT_APPROVE_ADMIN_APPROVAL_LOADING,
  PUT_APPROVE_ADMIN_APPROVAL_FAILED,
  PUT_APPROVE_ADMIN_APPROVAL_SUCCESS,
  PUT_REJECT_ADMIN_APPROVAL_LOADING,
  PUT_REJECT_ADMIN_APPROVAL_FAILED,
  PUT_REJECT_ADMIN_APPROVAL_SUCCESS,
  
  GET_ADMIN_UNIV_LOADING,
  GET_ADMIN_UNIV_SUCCESS,
  GET_ADMIN_UNIV_FAILED,
}
