import { get, put } from 'axios'
import config from '../services/config'
const { BASE_URL_LOGIN, BASE_URL_NOAUTH, TIMEOUT } = config

const getData = (token) => {
  return get(`${BASE_URL_NOAUTH}api/initialLoad`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const data = response.data ? response.data : undefined
    const normalizeData = 
      {
        footCountryId: data ? data.footCountryId : '',
        footProvinceId: data ? data.footProvinceId : '',
        footCitId: data ? data.footCitId : '',
        footAddress: data ? data.footAddress : '',
        footCitName: data ? data.footCitName : '',
        footCountryName: data ? data.footCountryName : '',
        footIg: data ? data.footIg : '',
        footInfoEmail: data ? data.footInfoEmail : '',
        footInfoWa: data ? data.footInfoWa : '',
        footLang: data ? data.footLang : '',
        footLat: data ? data.footLat : '',
        footLogoBidiktiName: data ? data.footLogoBidiktiName : '',
        footLogoBidiktiPath: data ? data.footLogoBidiktiPath : '',
        footLogoLoyolaName: data ? data.footLogoLoyolaName : '',
        footPostalCode: data ? data.footPostalCode : '',
        footProvinceName: data ? data.footProvinceName : '',
        footTelp1: data ? data.footTelp1 : '',
        footTelp2: data ? data.footTelp2 : '',
        footUrlIg: data ? data.footUrlIg : '',
        footUrlYoutube: data ? data.footUrlYoutube : '',
        headInfoEmail: data ? data.headInfoEmail : '',
        headInfoWa: data ? data.headInfoWa : '',
        headLogoLoyolaPath: data ? data.headLogoLoyolaPath : '',
        registerBg: data ? data.registerBg : '',
      }
    return {
      data: normalizeData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      totalData: 0,
      error,
      data: []
    }
  })
}

const handleEdit = (token, userId, body) => {
  return put(`${BASE_URL_LOGIN}be/api/loyolaInfoAdmin?usersId=${userId}`,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: '',
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: [],
    }
  })
}


export default {
  getData,
  handleEdit
}