import {get, post} from 'axios'
import config from '../services/config'

const {BASE_URL_LOGIN, TIMEOUT} = config

const getDaftarAcara = (token, id, sort, size, page, search) => {
  return get(`${BASE_URL_LOGIN}be/api/eventListAdminUniv?univId=${id}&search=${search}&sort=${sort}&size=${size}&page=${page-1}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.Data.map(res => {
      return {
        univEventId: res.univEventId,
        univEventName: res.univEventName,
        univIsOnline: res.univIsOnline,
        univEventDay: res.univEventDay,
        univId: res.univId,
        univEventDate: res.univEventDate,
        isOnlineStatus: res.isOnlineStatus,
        id: res.univEventId,
        univEventApprovalStatus: res.univEventApprovalStatus,
      }
    })
    return {
      data: normalizeData,
      totalData: response.data.TotalData,
      status: 'success',
      error: 'fail'
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const getDaftarAcaraDetail = (token, id) => {
  return get(`${BASE_URL_LOGIN}be/api/eventDetailAdminUniv?univEventId=${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = {
      univLogoPath: response.data.Data.univLogoPath,
      univEventLoyolaQuotaUsed: response.data.Data.univEventLoyolaQuotaUsed,
      univEventLoyolaQuota: response.data.Data.univEventLoyolaQuota,
      univEventNonLoyolaQuotaUsed: response.data.Data.univEventNonLoyolaQuotaUsed,
      univEventNonLoyolaQuota: response.data.Data.univEventNonLoyolaQuota,
      univEventImgPath: response.data.Data.univEventImgPath,
      univEventIsOnline: response.data.Data.univEventIsOnline,
      univEventMediaJoinId: response.data.Data.univEventMediaJoinId,
      univEventMediaPwd: response.data.Data.univEventMediaPwd,
      univEventDesc: response.data.Data.univEventDesc,
      univEventTime: response.data.Data.univEventTime,
      isOnlineStatus: response.data.Data.isOnlineStatus,
      univEventMediaUrl: response.data.Data.univEventMediaUrl,
      univEventId: response.data.Data.univEventId,
      univEventName: response.data.Data.univEventName,
      univName: response.data.Data.univName,
      univEventMedia: response.data.Data.univEventMedia,
      univEventDayAndDate: response.data.Data.univEventDayAndDate,
      univId: response.data.Data.univId,
      id: response.data.Data.univEventId,
    }
    return {
      data: normalizeData,
      status: 'success',
      error: 'fail'
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const getAcaraParticipantList = (token, roleGroupId, univEventId, sort, size, page, search) => {
  return get(`${BASE_URL_LOGIN}be/api/participantEventAdminUniv?roleGroupId=${roleGroupId}&univEventId=${univEventId}&search=${search}&sort=${sort}&size=${size}&page=${page-1}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.Data.map(res => {
      return {
        univEventParticipantRole: res.univEventParticipantRole,
        univEventParticipantRoleId: res.univEventParticipantRoleId,
        univEventParticipantEmail: res.univEventParticipantEmail,
        univEventParticipantId: res.univEventParticipantId,
        univEventParticipantName: res.univEventParticipantName,
        univEventParticipantUserId: res.univEventParticipantUserId,
        id: res.univEventParticipantId,
      }
    })
    return {
      data: normalizeData,
      totalData: response.data.TotalData,
      status: 'success',
      error: 'fail'
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const postEvent = (token, body) => {
  return post(`${BASE_URL_LOGIN}be/api/addEventAdminUniv`, body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      totalData: 0,
      error,
      data: []
    }
  })
}

export default {
  getDaftarAcara,
  getDaftarAcaraDetail,
  getAcaraParticipantList,
  postEvent,
}
