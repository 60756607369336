import React, { Component } from "react";
import "../../global/global.css";
import { 
   Breadcrumb, Card, Row, Col, Typography,
   Table
} from "antd";
import { 
   UISearch 
} from "../../components";
import { connect } from "react-redux";
import profileUniversitas from "../../actions/profileUniversitas";

const { Text } = Typography;

class ProfileUniversitas extends Component {
   constructor(props) {
      super(props);
      this.state = {
         univId: sessionStorage.getItem("univId"),
         param: {
            sort: "",
            search: ""
         }
      };
   }

   componentDidMount() {
      this.props.handleGetProfileUniverstas(this.props.token, this.state.univId, this.state.param);
   }

   handleRedirectDetail = (dataRes, status) => {
      this.props.history.push(`/profile-universitas-detail/${dataRes}/${status}`)
   }

   handleRedirectEdit = () => {
      this.props.history.push(`/profile-universitas-edit`);
   }

   handleSearch = key => {
      this.setState({
         param: {
            ...this.state.param,
            search: key
         }
      }, () => {
         this.props.handleGetProfileUniverstas(this.props.token, this.state.univId, this.state.param);
      })
   }

   handleTableChange = (page, filters, sorter) => {
      let sortByValue = "";

      if ( sorter.order === "ascend" ) {
         sortByValue = `${sorter.field},asc`;
      } else if ( sorter.order === "descend" ) {
         sortByValue = `${sorter.field},desc`;
      } else {
         sortByValue = "";
      }

      this.setState({
         param: {
            ...this.state.param,
            sort: sortByValue
         }
      }, () => {
         this.props.handleGetProfileUniverstas(this.props.token, this.state.univId, this.state.param);
      })
   }

   render() {
      const { dataProfileUniversitas } = this.props;
      const columnsTable = [
         {
            title: "Nama Universitas",
            dataIndex: "univName",
            key: "univName",
            sorter: true,
            render: namaUniversitas => <Text style={{ color: '#EF712B', fontWeight: 600, cursor: "pointer" }}>{namaUniversitas}</Text>,
            onCell: record => {
               return {
                 onClick: () => {
                   this.handleRedirectDetail(record.dataRes, record.status)
                 }
               }
            }
         },
         {
            title: "Jenis Universitas",
            dataIndex: "univCatName",
            key: "univCatName",
            sorter: true
         },
         {
            title: "Status",
            dataIndex: "status",
            key: "status",
            sorter: true,
            render: status => {
               if ( status === "On Approval" ) {
                  return <Text style={{ color: '#21296C', fontWeight: 600 }}>{status}</Text>
               } else if ( status === "Approved" ) {
                  return <Text style={{ color: '#EF712B', fontWeight: 600 }}>Published</Text>
               } else {
                  return <Text style={{ color: '#E22828', fontWeight: 600 }}>{status}</Text>
               }
            }
         },
         {
            title: "Action",
            dataIndex: "",
            key: "",
            render: record => {
               if ( record.status === "Approved" ) {
                  return <Text style={{ color: '#EF712B', fontWeight: 600, cursor: "pointer" }}>Edit</Text>
               }
            },
            onCell: record => {
               return {
                 onClick: () => {
                   this.handleRedirectEdit()
                 }
               }
            }
         },
      ];

      return (
         <div className="root">
            <Breadcrumb className="breadcrumb_style">
               <Breadcrumb.Item className='pathNow_style'>
                  <span>Profil Universitas</span>
               </Breadcrumb.Item>
            </Breadcrumb>
            <Card className="bodyCard_style">
               <Row>
                  <Col span={8}>
                     <UISearch placeholder='Search' handleSearch={key => this.handleSearch(key)}/>
                  </Col>
               </Row>
               <Card
                  bordered={false}
                  style={{ minHeight: '300px' }}>
                  <Table
                     dataSource={dataProfileUniversitas.data}
                     columns={columnsTable}
                     loading={dataProfileUniversitas.status === "loading"}
                     pagination= {false}
                     rowKey={record => record.id}
                     onChange={(page, filters, sorter) => this.handleTableChange(page, filters, sorter)}
                  />
               </Card>
            </Card>
         </div>
      )
   }
} 

const mapStateToProps = state => (
   {
      dataProfileUniversitas: state.getProfileUniversitas,
   }
)

const mapDispatchToProps = dispatch => (
   {
      handleGetProfileUniverstas: (token, univId, param) => dispatch(profileUniversitas.getProfileUniversitas(token, univId, param)),
   }
)

export default connect(mapStateToProps, mapDispatchToProps)(ProfileUniversitas);