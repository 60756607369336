import types from '../constants'
import kerjasama from "../api/kerjasama";

const getDataKerjasama = (param) => dispatch => {
  dispatch({
    type: types.GET_KERJASAMA_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: [],
      totalData: 0
    }
  })
  return kerjasama.getDataKerjasama(param).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.GET_KERJASAMA_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_KERJASAMA_SUCCESS,
        payload: result
      })
    }
  })
}

const handleShow = (token, univId, userId) => dispatch => {
  dispatch({
    type: types.PUT_KERJASAMA_SHOW_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return kerjasama.showKerjasama(token, univId, userId).then( result => {
    if (result.status === 'error') {
      return{
        type: types.PUT_KERJASAMA_SHOW_FAILED,
        payload: result
      }
    }else {
      return {
        type: types.PUT_KERJASAMA_SHOW_SUCCESS,
        payload: result,
      }
    }
  })
}

const handleHide = (token, univId, userId) => dispatch => {
  dispatch({
    type: types.PUT_KERJASAMA_HIDE_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return kerjasama.hideKerjasama(token, univId, userId).then( result => {
    if (result.status === 'error') {
      return{
        type: types.PUT_KERJASAMA_HIDE_FAILED,
        payload: result
      }
    }else {
      return {
        type: types.PUT_KERJASAMA_HIDE_SUCCESS,
        payload: result,
      }
    }
  })
}

export default {
  getDataKerjasama,
  handleShow,
  handleHide
}