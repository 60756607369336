import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../global/global.css'
import {Breadcrumb, Card, notification} from "antd";
import { UIDetailAdminApprovalUniv, UIModalListEntrance, UIModalCreateAdminUniv } from "../../../components";
import getAdminApproval from "../../../actions/manageAdmin";


class ApprovalAdminUnivDetail extends Component {

  constructor(props) {
    super(props)
    this.state = {
      initial: '',
      id: '',
      visibleListEntrance : false,
      visibleCreate : false,
      loadingEdit: false,
      disableApprove: true,
    }
  }

  componentDidMount() {
    let id = this.props.location.pathname.split('/')[2]
    this.setState({
      id: id
    })
    this.props.handleDataDetail(this.props.token, id)
    this.props.handleListEntrance(this.props.token, id)
  }

  handleDirect = name => {
    if (name === 'list') {
      this.props.history.push(`/approval-admin-univ`)
    }else {
      this.props.history.push(`/dashboard`)
    }
  }

  handleShowList = () =>{
    this.setState({
      visibleListEntrance: true
    })
  }

  closeModal = () => {
    this.setState({
      visibleListEntrance: false,
      visibleCreate: false,
    })
  }

  handleShowCreate = () => {
    this.setState({
      visibleCreate: true,
    })
  }

  handleFinishUpdate = (value) => {
    this.setState({
      loadingEdit: true,
    })
    this.props.handleUpdateAdminUniv(this.props.token, value, this.state.id).then(res => {
      if (res.payload.status !== 'error') {
        if (res.payload.data.data.Status === 'OK') {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Update Admin Success',
          });
          this.setState({
            loadingEdit: false,
            disableApprove: false,
          }, () => {
            this.props.handleDataDetail(this.props.token, this.state.id)
          })
        }else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Update Admin Failed'
          });
          this.setState({
            loadingEdit: false,
          })
        }
      }else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: res.payload.error.message ? res.payload.error.message : 'Update Admin Failed'
        });
        this.setState({
          loadingEdit: false,
        })
      }
    })
  }

  handleCreate = value => {
    const {
      dataDetail
    } = this.props;
    this.setState({
      loadingEdit: true,
    })
    let body = {
      univUserName: value.username,
      univEmail: dataDetail.data.univEmail ? dataDetail.data.univEmail : null,
      univPassword: value.password,
      usersId: sessionStorage.getItem('userId')
    }
    this.props.handleCreateAdminUniv(this.props.token, body, this.state.id).then(res => {
      if (res.payload.status !== 'error') {
        if (res.payload.data.data.Status === 'OK') {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Create Admin Success',
          });
          setTimeout(() => {
            this.props.history.push(`/approval-admin-univ`)
          },  this.state.loadingEdit ? this.state.loadingEdit : 2000);
          this.setState({
            loadingEdit: false,
          })
        }else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Create Admin Failed'
          });
          this.setState({
            loadingEdit: false,
          })
        }
      }else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: res.payload.error.message ? res.payload.error.message : 'Create Admin Failed'
        });
        this.setState({
          loadingEdit: false,
        })
      }
    })
  }

  handleReject = () => {
    this.setState({
      loadingEdit: true,
    })
    let body = {
      usersId: sessionStorage.getItem('userId')
    }
    this.props.handleRejectAdminUniv(this.props.token, body, this.state.id).then(res => {
      if (res.payload.status !== 'error') {
        if (res.payload.data.data.Status === 'OK') {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Reject Admin Success',
          });
          setTimeout(() => {
            this.props.history.push(`/approval-admin-univ`)
          },  this.state.loadingEdit ? this.state.loadingEdit : 2000);
          this.setState({
            loadingEdit: false,
          })
        }else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Reject Admin Failed'
          });
          this.setState({
            loadingEdit: false,
          })
        }
      }else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: res.payload.error.message ? res.payload.error.message : 'Update Admin Failed'
        });
        this.setState({
          loadingEdit: false,
        })
      }
    })
  }

  render() {
    const {
      dataDetail,
      dataListEntrance,
    } = this.props

    const {
      visibleListEntrance,
      visibleCreate,
      loadingEdit,
      disableApprove,
    } = this.state

    return (
      <div className='root'>
        <Breadcrumb className="breadcrumb_style" >
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.handleDirect('dashBoard')}>Kelola Admin </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.handleDirect('list')}>Persetujuan Admin Universitas</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className='pathNow_style'>
            Detail Persetujuan Admin Universitas
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card
          loading={dataDetail.status === 'loading'}
          className='bodyCard_style'
        >
          <UIDetailAdminApprovalUniv
            isMobile={this.props.isMobile}
            dataDetail={dataDetail.data ? dataDetail.data : []}
            handleShowList={() => this.handleShowList()}
            token={this.props.token}
            handleFinish={(value) => this.handleFinishUpdate(value)}
            handleApprove={() => this.handleShowCreate()}
            handleReject={() => this.handleReject()}
            loadingApprove={loadingEdit}
            loadingReject={loadingEdit}
            loadingUpdate={loadingEdit}
            disableApprove={disableApprove}
          />
        </Card>
        <UIModalListEntrance
          visible={visibleListEntrance}
          isMobile={this.props.isMobile}
          handleBack={() => this.closeModal()}
          dataEntrance={dataListEntrance ? dataListEntrance : []}
        />
        <UIModalCreateAdminUniv
          visible={visibleCreate}
          isMobile={this.props.isMobile}
          handleBack={() => this.closeModal()}
          handleCreate={(value) => this.handleCreate(value)}
          loadingSubmit={loadingEdit}
        />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return{
    handleDataDetail: (token, id) => dispatch(getAdminApproval.getDataDetailAdminApproval(token, id)),
    handleListEntrance: (token, id) => dispatch(getAdminApproval.getUnivListEntrance(token, id)),
    handleUpdateAdminUniv: (token, body, id) => dispatch(getAdminApproval.updateAdminApproval(token, body, id)),
    handleCreateAdminUniv: (token, body, id) => dispatch(getAdminApproval.createAdminApproval(token, body, id)),
    handleRejectAdminUniv: (token, body, id) => dispatch(getAdminApproval.rejectAdminApproval(token, body, id)),
  }
}

const mapStateToProps = (state) => {
  return {
    dataDetail: state.detailAdminApproval,
    dataListEntrance: state.listEntranceUniv,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalAdminUnivDetail)
