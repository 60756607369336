import React from "react";
import 'antd/dist/antd.css';
import './global/global.css';
import { Layout } from 'antd'
import Routes from './Routes'

import {useMediaQuery} from 'react-responsive'

export const AppAdmin = () => {

  const isMobile = useMediaQuery({
    query: '(max-width: 1224px)'
  })

  return (
    <Layout className='container_App'>
      <Routes isMobile={isMobile}/>
    </Layout>
  );
}
