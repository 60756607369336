import React, { Component } from "react";
import {
   Breadcrumb, Card, Row, Col, Select, 
   Button, Table
} from "antd";
import "../../global/global.css";
import { connect } from "react-redux";
import masterKota from "../../actions/masterKota";
import lov from "../../actions/lov";
import FileSaver from 'file-saver';
import XLSX from 'xlsx';
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

const { Option } = Select;

class Export extends Component {
   constructor(props) {
      super(props);
      this.state = {
         paramApiKota: {
            filter: "isDeleted=false",
            orderBy: "",
            top: "",
            skip: "",
            token: this.props.token
         },
         countrySelected: null,
         provinceSelected: null,
         fileType: null,
         isFilter: false,
         isDonwload: false
      };
   }

   componentDidMount() {
      this.props.handleGetLOVCountry();
   }

   handleChangeCountry = value => {
      this.setState({ 
         countrySelected: value, 
         provinceSelected: null 
      })
      this.props.handleGetLOVProvince(value);
   }

   handleChangeProvince = value => {
      this.setState({ provinceSelected: value });
   }

   handleClickFilter = () => {
      const { paramApiKota, countrySelected, provinceSelected } = this.state;
      this.setState({
         paramApiKota: {
            ...paramApiKota,
            filter: `isDeleted=false ${countrySelected ? `and provinceId.countryId.countryId=${countrySelected}` : ""} ${provinceSelected ? `and provinceId.provinceId=${provinceSelected}` : ""}`
         },
         isFilter: true
      }, () => {
         this.props.handleGetKota(this.state.paramApiKota)
      })
   }

   handleChangeFileType = value => {
      this.setState({ 
         fileType: value,
         isDonwload: true
      });
   }

   handleClickDonwload = () => {
      const { fileType } = this.state
      const { dataKota } = this.props;
		const today = new Date();
		const fileName = 'Attachment Report ' + moment(today).format('YYYY/MM/DD');
      const tablehead = [['Kota', 'Provinsi', 'Negara']];
      const data = dataKota.data.map( res => ({
         cityName: res.cityName,
         provinceName: res.provinceName,
         countryName: res.countryName
      }))
      const dataConvert = this.convertToCSV(tablehead[0].toString(), data)
		if (fileType === 'csv') {
			const csvData = new Blob([dataConvert], { type: 'text/csv;charset=utf-8;' })
			FileSaver.saveAs(csvData, fileName + '.csv');
		} else if (fileType === 'excel') {
			const filename = fileName + '.xlsx'
			const ws_name = 'Attachment_SHEET'
			const wb = XLSX.utils.book_new()
			const ws = XLSX.utils.aoa_to_sheet(tablehead)
			XLSX.utils.sheet_add_json(ws, data, {
				header: ['cityName', 'provinceName', 'countryName'],
				skipHeader:true,
				origin:1
			})
			XLSX.utils.book_append_sheet(wb, ws, ws_name);
			XLSX.writeFile(wb, filename);
		}else {
			this.handlePdfExport(tablehead, data, fileName)
		}
   }

   convertToCSV = (head, array) => {
		let str = head + '\r\n';

		for (let i = 0; i < array.length; i++) {
				let line = '';
				for (let index in array[i]) {
					if (line !== '') line += ','

					line += array[i][index];
				}
			str += line + '\r\n';
		}
		return str;
	}

   handlePdfExport = (head, dataJSON, name) => {
		const unit = "pt";
      const size = "A3"; // Use A1, A2, A3 or A4
      const orientation = "landscape"; // portrait or landscape

      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);

         doc.setFontSize(12);
         doc.setLineWidth(300)

      const title = name+" PDF";

      const dataPDF = dataJSON.map(elt=> [elt.cityName, elt.provinceName, elt.countryName]);

      let content = {
         startY: 50,
         head: head,
            body: dataPDF,
            columnStyles: {
               0: {cellWidth: 200},
               1: {cellWidth: 200},
               2: {cellWidth: 200},
               // 8: {cellWidth: 100},
            }
      };

      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      doc.save(name+".pdf")
	}


   render() {
      const { dataKota, dataLOVCountry, dataLOVProvince } = this.props;
      const { provinceSelected, isFilter, isDonwload } = this.state;

      const columnsTable = [
         {
            title: "Kota",
            dataIndex: 'cityName',
            key: 'cityName',
         },
         {
            title: "Provinsi",
            dataIndex: 'provinceName',
            key: 'provinceName',
         },
         {
            title: "Negara",
            dataIndex: 'countryName',
            key: 'countryName',
         }
      ];

      return (
         <div className="root">
            <Breadcrumb className="breadcrumb_style">
               <Breadcrumb.Item className='pathNow_style'>
                  Export
               </Breadcrumb.Item>
            </Breadcrumb>
            <Card className="bodyCard_style">
               <Row gutter={[16, 16]}>
                  <Col span={4}>
                     <Select placeholder="Negara" className="select" onChange={this.handleChangeCountry}>
                        { 
                           dataLOVCountry.data &&
                           dataLOVCountry.data.map((res, index) => (
                              <Option key={index} value={res.id}>{res.name}</Option>
                           ))
                        }
                     </Select>
                  </Col>
                  <Col span={4}>
                     <Select placeholder="Provinsi" className="select" onChange={this.handleChangeProvince} value={provinceSelected}>
                        { 
                           dataLOVProvince.data &&
                           dataLOVProvince.data.map((res, index) => (
                              <Option key={index} value={res.id}>{res.name}</Option>
                           ))
                        }
                     </Select>
                  </Col>
                  <Col span={4}>
                     <Button type="primary" className="updateButton" onClick={this.handleClickFilter}>Filter</Button>
                  </Col>
               </Row>
               { isFilter &&
                  <Row gutter={[16, 0]}>
                     <Col span={4}>
                        <Select className="select" placeholder="File Type" onChange={this.handleChangeFileType}>
                           <Option value="csv">CSV</Option>
                           <Option value="pdf">PDF</Option>
                           <Option value="excel">Excel</Option>
                        </Select>
                     </Col>
                     { isDonwload &&
                        <Col span={4}>
                           <Button type="primary" className="buttonUpload" onClick={this.handleClickDonwload}>Donwload</Button>
                        </Col>
                     }
                  </Row>
               }
               { isFilter &&
                  <Card
                     bordered={false}
                     loading={dataKota.loading}
                     style={{ minHeight: '300px' }}>
                     <Table 
                        columns={columnsTable} 
                        dataSource={dataKota.data} 
                        rowKey={record => record.cityId}
                        pagination={false}
                        loading={dataKota.status === "loading"}
                     />
                  </Card>
               }
            </Card>
         </div>
      )
   }
}

const mapStateToProps = (state) => ({
   dataKota: state.masterKota,
   dataLOVCountry: state.lovCountry,
   dataLOVProvince: state.lovProvince,
});

const mapDispatchToProps = dispatch => ({
   handleGetKota: (param) => dispatch(masterKota.getData(param)),
   handleGetLOVCountry: () => dispatch(lov.getLOVCountry()),
   handleGetLOVProvince: (id) => dispatch(lov.getLOVProvince(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Export);