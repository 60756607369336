import { get } from 'axios'
import config from '../services/config'
const { BASE_URL_LOGIN, BASE_URL_NOAUTH, TIMEOUT } = config

const getData = (token) => {
  let url = `${BASE_URL_LOGIN}be/api/dashboard/lifeSharingScholarship`
  // let url = `https://demo5436246.mockable.io/listApapun`
  return get(url,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const data = response.data ? response.data.Data : undefined
    const backgroundColors = [
      "rgb(7, 42, 111)",
      "rgb(255, 230, 0)",
      "rgb(240, 58, 71)",
      "rgb(120, 255, 214)",
      "rgb(23, 195, 178)",
      "rgb(10, 211, 255)",
      "rgb(92, 122, 255)",
    ]
    const backgroundColorsBar = [
      "rgb(120, 255, 214)",
      "rgb(23, 195, 178)",
      "rgb(10, 211, 255)",
      "rgb(92, 122, 255)",
      "rgb(36, 106, 115)"
    ]
    const dataPie = {
      labels: data.labelsBeasiswa,
      datasets: [{
        label: data.datasets[1].label,
        data:data.datasets[1].data,
        backgroundColor: backgroundColors
      }]
    }
    const dataBar = {
      labels: data.labelsLifeSharing,
      datasets: [{
        label: data.datasets[0].label,
        data:data.datasets[0].data,
        backgroundColor: backgroundColorsBar
      }]
    }
    const normalizeData = 
      {
        dataPie: dataPie,
        dataBar: dataBar
      }
    return {
      data: normalizeData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      totalData: 0,
      error,
      data: null
    }
  })
}


export default {
  getData
}