import { get, post, put } from "axios";
import config from "../services/config";

import moment from 'moment'

const { BASE_URL_LOGIN, TIMEOUT } = config;

const getData = (token, param) => {
   return get(`${BASE_URL_LOGIN}be/api/eventListApproveAdminUniv?univId=${param.univId}&sort=${param.sort}&search=${param.search}&page=${param.page - 1}&size=${param.size}`,
      {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
         timeout: TIMEOUT
      }
   ).then( response => {
      const normalizeData = response.data.Data.map( res => {
         return {
            id: res.univEventId,
            univEventId: res.univEventId,
            univEventName: res.univEventName,
            univIsOnline: res.univIsOnline,
            univEventDay: res.univEventDay,
            univEventDate: moment(res.univEventDate).format('YYYY-MM-DD')
         }
      })
      return {
         data: normalizeData,
         totalData: response.data.totalData,
         status: "success",
         error: ""
      }
   }).catch( error => {
      return {
         data: [],
         totalData: 0,
         status: "error",
         error
      }
   })
}

const uploadGaleri = (token, formData) => {
   return post(`${BASE_URL_LOGIN}be/api/uploadEventGalleryAdminUniv`, 
      formData,
      {
         headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
         },
         timeout: TIMEOUT
      }
   ).then( response => {
      return {
         data: response,
         totalData: 0,
         status: "success",
         error: ""
      }
   }).catch( error => {
      return {
         data: [],
         totalData: 0,
         status: "error",
         error
      }
   })
}

const postDaftarGaleri = (token, body) => {
   return post(`${BASE_URL_LOGIN}be/api/eventGalleryAdminUniv`, 
      body,
      {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
         timeout: TIMEOUT
      }
   ).then( response => {
      return {
         data: response,
         totalData: 0,
         status: "success",
         error: ""
      }
   }).catch( error => {
      return {
         data: [],
         totalData: 0,
         status: "error",
         error
      }
   })
}

const getDetail = (token, univEventId) => {
   return get(`${BASE_URL_LOGIN}be/api/detailGalleryAdminUniv?univEventId=${univEventId}`,
      {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
         timeout: TIMEOUT
      }
   ).then( response => {
      return {
         data: response.data.Data,
         totalData: 0,
         status: "success",
         error: ""
      }
   }).catch( error => {
      return {
         data: [],
         totalData: 0,
         status: "error",
         error
      }
   })
}

const putThumbnailDaftarGaleri = (token, param) => {
   return put(`${BASE_URL_LOGIN}be/api/setThumbnailGalleryAdminUniv?userId=${param.userId}&univGalleryId=${param.univGalleryId}`,
      {},   
      {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
         timeout: TIMEOUT
      }
   ).then( response => {
      return {
         data: response,
         totalData: 0,
         status: "success",
         error: ""
      }
   }).catch( error => {
      return {
         data: [],
         totalData: 0,
         status: "error",
         error
      }
   })
}

export default {
   getData,
   uploadGaleri,
   postDaftarGaleri,
   getDetail,
   putThumbnailDaftarGaleri,
}