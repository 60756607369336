import types from "../constants";
import dashboardUniversitas from "../api/dashboardUniversitas";

const getDashboardListUniversitas = (token, id) => dispatch => {
   dispatch({
      type: types.GET_DASHBOARD_LIST_UNIVERSITAS_LOADING,
      payload: {
         data: [],
         totalData: 0,
         status: "loading",
         error: ""
      }
   })
   return dashboardUniversitas.getDashboardListUniversitas(token, id).then(result => {
      if (result.status === "error") {
         return dispatch({
            type: types.GET_DASHBOARD_LIST_UNIVERSITAS_FAILED,
            payload: result
         });
      } else {
         return dispatch({
            type: types.GET_DASHBOARD_LIST_UNIVERSITAS_SUCCESS,
            payload: result
         });
      }
   })
}

export default {
   getDashboardListUniversitas
}