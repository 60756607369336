import React, {useState, useEffect} from 'react'
import {Row, Col, Modal, DatePicker, Typography, Card, Input, Button, Form} from 'antd'
import { CloseOutlined} from "@ant-design/icons";
import moment from 'moment'
import '../../global/global.css'
// import { UItable, UISearch } from '../index'
const { TextArea } = Input
const { Text } = Typography
const { RangePicker } = DatePicker


const ModalEditDetail = props => {
  const [themeId, setThemeId] = useState(props.dataDetail.bwThemeInd)
  const [themeEng, setThemeEng] = useState(props.dataDetail.bwThemeEng)
  const [dateRegis, setDateRegis] = useState([])
  const [dateEvent, setDateEvent] = useState([])
  const [validateThemeName, setValidateThemeName] = useState({
    themeNameInd: true,
    themeNameEng: true
  });

  useEffect(() => {
    setThemeId(props.dataDetail.bwThemeInd);
    setThemeEng(props.dataDetail.bwThemeEng);
  }, [props.dataDetail]);

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const format = 'YYYY-MM-DD'

  const [form] = Form.useForm()
  const disabledDate = current => {
    const start = dateRegis[0] && current.diff(moment(dateRegis[0]), 'h') < 0
    return start
  }

  // const disabledDateRegis = current => {
  //   const end = dateEvent[1] && current.diff(moment(dateEvent[1]), 'h') > 0
  //   return end
  // }

  const handleChangeThemeId = ( event ) => {
    const value = event.target.value;
    setThemeId(value);
    if ( !value.length ) {
      setValidateThemeName({
        ...validateThemeName,
        themeNameInd: false
      })
    } else {
      setValidateThemeName({
        ...validateThemeName,
        themeNameInd: true
      })
    }
  }

  const handleChangeThemeEn = ( event ) => {
    const value = event.target.value;
    setThemeEng(value);
    if ( !value.length ) {
      setValidateThemeName({
        ...validateThemeName,
        themeNameEng: false
      })
    } else {
      setValidateThemeName({
        ...validateThemeName,
        themeNameEng: true
      })
    }
  }

  const handleChangeDate = date => {
    if(dateEvent.length > 0) {
      if (dateEvent[0].diff(date[0], 'h') < 0) {
        let temp = [date[0], dateEvent[1]]
        form.setFieldsValue({
          startEndDateEvent: temp
        })
      }
    }
    if (date === null) {
      setDateRegis([])
    }else {
      setDateRegis(date)
      form.setFieldsValue({startEndDateEvent: null});
    }
  }

  const handleChangeDateEvent = date => {
    if(dateRegis.length > 0) {
      if (dateRegis[1].diff(date[1], 'h') > 0) {
        let temp = [dateRegis[0], date[1]]
        form.setFieldsValue({
          startEndDateRegis: temp
        })
      }
    }
    if (date === null) {
      setDateEvent([])
    }else {
      setDateEvent(date)
    }
  }

  const checkFinish = (values) => {
    // const temp = form.getFieldsValue()
    const body = {
      bwThemeInd: themeId,
      bwThemeEng: themeEng,
      bwDescInd: values.descThemeId,
      bwDescEng: values.descThemeEng,
      bwDurationStart: moment(values.startEndDateEvent[0]).format('YYYY-MM-DD'),
      bwDurationFinish: moment(values.startEndDateEvent[1]).format('YYYY-MM-DD'),
      bwNarationTitleInd: values.narationId,
      bwNarationTitleEng: values.narationEng,
      bwNarationDescInd: values.narationDescId,
      bwNarationDescEng: values.narationDescEng,
      bwUnivRegStart: moment(values.startEndDateRegis[0]).format('YYYY-MM-DD'),
      bwUnivRegFinish: moment(values.startEndDateRegis[1]).format('YYYY-MM-DD'),
    }

    const tempValidate = validateThemeName;
    
    tempValidate.themeNameInd = themeId.length ? true : false;
    tempValidate.themeNameEng = themeEng.length ? true : false;
    
    setValidateThemeName({...tempValidate});

    if ( validateThemeName.themeNameEng && validateThemeName.themeNameInd ) {
      props.handleEditBW(body)
    }
  }

  return(
    <>
      <Modal
        visible={props.visible}
        width={props.isMobile ? '100%' : "50%"}
        className={props.isMobile ? 'modalMobile' : 'modalImage'}
        bodyStyle={{padding: 0}}
        centered
        onCancel={() => props.handleBack()}
        footer={null}
        closable
        destroyOnClose={true}
        maskClosable
        style={{borderRadius: '25px'}}
        closeIcon={<CloseOutlined style={{ color: '#fff' }} />}
      >
        <>
          <Row className="modalHeadEdit">
            <Col span={24}>
              <h1 className="modalTitleEdit">Edit Tema BIDikTi Week</h1>
              <div className='dividerGalery' />
            </Col>
            <Col span={23}>
              <Form.Item
                validateStatus={validateThemeName.themeNameInd ? "success" : "error"}
                help={validateThemeName.themeNameInd ? "" : "Please input theme name BIDikTi Week!"}
                style={{marginBottom: 0}}
              >
                <Input
                  name='themeId'
                  value={themeId}
                  placeholder="Nama Tema BIDikTi Week (Bahasa Indonesia)"
                  className="input_style_modal"
                  onChange={event => handleChangeThemeId(event)}
                  // onPressEnter={() => this.handleFinish()}
                />
              </Form.Item>
            </Col>
            <Col span={1} style={{ paddingLeft: '5px'}}>
              <span className="mandatoryLarge">&#8727;</span>
            </Col>
            <Col span={23} style={{ margin: '15px 0'}}>
              <Form.Item
                validateStatus={validateThemeName.themeNameEng ? "success" : "error"}
                help={validateThemeName.themeNameEng ? "" : "Please input theme name BIDikTi Week!"}
                style={{marginBottom: 0}}
              >
                <Input
                  name='themeEng'
                  value={themeEng}
                  placeholder="Nama Tema BIDikTi Week (Bahasa Inggris)"
                  className="input_style_modal"
                  onChange={event => handleChangeThemeEn(event)}
                  // onPressEnter={() => this.handleFinish()}
                />
              </Form.Item>
            </Col>
            <Col span={1} style={{ margin: '15px 0', paddingLeft: '5px'}}>
              <span className="mandatoryLarge">&#8727;</span>
            </Col>
            <Col span={23} style={{ textAlign: 'right', margin: '22px 0'}}>
              <Button
                  disabled={themeId === "" || themeEng === ""}
                  form="formEditTheme"
                  htmlType="submit"
                  loading={props.loading}
                  className='updateButton'
              >
                Submit
              </Button>
            </Col>
          </Row>
          <Card bordered={false} style={{minHeight: '200px'}}>
            <Row>
              <Col span={24}>
                <Form
                  id="formEditTheme"
                  form={form}
                  onFinish={values => checkFinish(values)}
                  onFinishFailed={onFinishFailed}
                  initialValues={props.dataDetail ? {
                    descThemeId: props.dataDetail.bwDescInd,
                    descThemeEng: props.dataDetail.bwDescEng,
                    narationId: props.dataDetail.bwNarationTitleInd,
                    narationEng: props.dataDetail.bwNarationTitleEng,
                    narationDescId: props.dataDetail.bwNarationDescInd,
                    narationDescEng: props.dataDetail.bwNarationDescEng,
                    startEndDateRegis: [moment(props.dataDetail.bwUnivRegStart, 'YYYY-MM-DD'), moment(props.dataDetail.bwUnivRegFinish, 'YYYY-MM-DD')],
                    startEndDateEvent: [moment(props.dataDetail.bwDurationStart, 'YYYY-MM-DD'), moment(props.dataDetail.bwDurationFinish, 'YYYY-MM-DD')]
                  } : {}}
                >
                  <Row gutter={[10,0]}>
                    <Col span={11}>
                      <Text className='headerFormText'>
                        <span className="mandatory">* </span>
                        Durasi Pendaftaran
                      </Text>
                      <Form.Item
                        name='startEndDateRegis'
                        rules={[
                          {required: true, message: "Please input duration registration"}
                        ]}
                      >
                        <RangePicker
                          allowClear={false}
                          format={format}
                          placeholder={['Tanggal Start', 'Tanggal Finish']}
                          onCalendarChange={(valueMoment) => handleChangeDate(valueMoment)}
                          // disabledDate={ date => disabledDateRegis(date)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                    </Col>
                    <Col span={11}>
                      <Text className='headerFormText'>
                        <span className="mandatory">* </span>
                        Durasi Pelaksanaan
                      </Text>
                      <Form.Item
                        name='startEndDateEvent'
                        rules={[
                          {required: true, message: "Please input duration implementation"}
                        ]}
                      >
                        <RangePicker
                          allowClear={false}
                          format={format}
                          placeholder={['Tanggal Start', 'Tanggal Finish']}
                          onCalendarChange={(valueMoment) => handleChangeDateEvent(valueMoment)}
                         disabledDate={ date => disabledDate(date)}/>
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                    </Col>
                    <Col span={23}>
                      <Form.Item
                        name='descThemeId'
                        rules={[
                          {required: true, message: "Please input BIDikTi week theme description (indonesian language)"}
                        ]}
                      >
                        <TextArea
                          maxLength={256}
                          size="large"
                          rows={4}
                          className='input_style'
                          placeholder={"Deskripsi Tema BIDikTi Week (Bahasa Indonesia)"}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <span className="mandatoryLarge">&#8727;</span>
                    </Col>
                    <Col span={23}>
                      <Form.Item
                        name='descThemeEng'
                        rules={[
                          {required: true, message: "Please input BIDikTi week theme description (english language)"}
                        ]}
                      >
                         <TextArea
                          maxLength={256}
                          size="large"
                          rows={4}
                          className='input_style'
                          placeholder={"Deskripsi Tema BIDikTi Week (Bahasa Inggris)"}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <span className="mandatoryLarge">&#8727;</span>
                    </Col>
                    <Col span={23}>
                      <Form.Item
                        name='narationId'
                      >
                         <TextArea
                          maxLength={256}
                          size="large"
                          rows={2}
                          className='input_style'
                          placeholder={"Narasi Judul Pendaftaran Universitas dan Sekolah Kedinasan (Bahasa Indonesia)"}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={23}>
                      <Form.Item
                        name='narationEng'
                      >
                         <TextArea
                          maxLength={256}
                          size="large"
                          rows={2}
                          className='input_style'
                          placeholder={"Narasi Judul Pendaftaran Universitas dan Sekolah Kedinasan (Bahasa Inggris)"}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={23}>
                      <Form.Item
                        name='narationDescId'
                      >
                         <TextArea
                          maxLength={256}
                          size="large"
                          rows={4}
                          className='input_style'
                          placeholder={"Narasi Deskripsi Pendaftaran Universitas dan Sekolah Kedinasan (Bahasa Indonesia)"}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={23}>
                      <Form.Item
                        name='narationDescEng'
                      >
                         <TextArea
                          maxLength={256}
                          size="large"
                          rows={4}
                          className='input_style'
                          placeholder={"Narasi Deskripsi Pendaftaran Universitas dan Sekolah Kedinasan (Bahasa Inggris)"}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Card>
        </>
      </Modal>
    </>
  )
}

export const UIModalEditDetail = ModalEditDetail;
