import types from "../constants";
import daftarGaleri from "../api/daftarGaleri";

const getData = (token, param) => dispatch => {
   dispatch({
      type: types.GET_DAFTAR_GALERI_LOADING,
      payload: {
         data: [],
         totalData: 0,
         status: "loading",
         error: ""
      }
   })
   return daftarGaleri.getData(token, param).then(result => {
      if (result.status === "error") {
         return dispatch({
            type: types.GET_DAFTAR_GALERI_FAILED,
            payload: result
         });
      } else {
         return dispatch({
            type: types.GET_DAFTAR_GALERI_SUCCESS,
            payload: result
         });
      }
   })
}

const uploadGaleri = (token, formData) => dispatch => {
   dispatch({
      type: types.POST_UPLOAD_DAFTAR_GALERI_LOADING,
      payload: {
         data: [],
         totalData: 0,
         status: "loading",
         error: ""
      }
   })
   return daftarGaleri.uploadGaleri(token, formData).then(result => {
      if (result.status === "error") {
         return dispatch({
            type: types.POST_UPLOAD_DAFTAR_GALERI_FAILED,
            payload: result
         });
      } else {
         return dispatch({
            type: types.POST_UPLOAD_DAFTAR_GALERI_SUCCESS,
            payload: result
         });
      }
   })
}

const postDaftarGaleri = (token, body) => dispatch => {
   dispatch({
      type: types.POST_DAFTAR_GALERI_LOADING,
      payload: {
         data: [],
         totalData: 0,
         status: "loading",
         error: ""
      }
   })
   return daftarGaleri.postDaftarGaleri(token, body).then(result => {
      if (result.status === "error") {
         return dispatch({
            type: types.POST_DAFTAR_GALERI_FAILED,
            payload: result
         });
      } else {
         return dispatch({
            type: types.POST_DAFTAR_GALERI_SUCCESS,
            payload: result
         });
      }
   })
}

const getDetail = (token, univEventId) => dispatch => {
   dispatch({
      type: types.GET_DETAIL_DAFTAR_GALERI_LOADING,
      payload: {
         data: [],
         totalData: 0,
         status: "loading",
         error: ""
      }
   })
   return daftarGaleri.getDetail(token, univEventId).then(result => {
      if (result.status === "error") {
         return dispatch({
            type: types.GET_DETAIL_DAFTAR_GALERI_FAILED,
            payload: result
         });
      } else {
         return dispatch({
            type: types.GET_DETAIL_DAFTAR_GALERI_SUCCESS,
            payload: result
         });
      }
   })
}

const putThumbnailDaftarGaleri = (token, param) => dispatch => {
   dispatch({
      type: types.PUT_THUMBNAIL_DAFTAR_GALERI_LOADING,
      payload: {
         data: [],
         totalData: 0,
         status: "loading",
         error: ""
      }
   })
   return daftarGaleri.putThumbnailDaftarGaleri(token, param).then(result => {
      if (result.status === "error") {
         return dispatch({
            type: types.PUT_THUMBNAIL_DAFTAR_GALERI_FAILED,
            payload: result
         });
      } else {
         return dispatch({
            type: types.PUT_THUMBNAIL_DAFTAR_GALERI_SUCCESS,
            payload: result
         });
      }
   })
}

export default {
   getData,
   uploadGaleri,
   postDaftarGaleri,
   getDetail,
   putThumbnailDaftarGaleri,
}