import types from '../constants'

const initialState = {
  status: '',
  data: []
}

export default function postLifeSharingGallery(state = initialState, action) {
  switch (action.type) {
    case types.POST_LIFE_SHARING_GALLERY_LOADING:
      return {
        ...state,
        ...action.payload
      }
    case types.POST_LIFE_SHARING_GALLERY_SUCCESS:
      return {
        isSuccess: true,
        ...state,
        ...action.payload
      }
    case types.POST_LIFE_SHARING_GALLERY_FAILED:
      return {
        isSuccess: false,
        ...state,
        ...action.payload
      }
    default:
      return { ...initialState, ...state }
  }
}
