import React from "react";
import {Row, Col, Modal, Button, Form, Input} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "../../global/global.css";

const ModalCreateAdminUnivCore = (props) => {

  const { confirm } = Modal;

  const [form] = Form.useForm();
  if (props.visible === false) {
    form.resetFields();
  }

  const handleResetForm = () => {
    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const checkFinish = (value) => {
    confirm({
      title: 'Apakah anda yakin menambah data?',
      content: '',
      icon: <></>,
      centered: true,
      onOk() {
        props.handleCreate(value);
      },
      onCancel() {

      },
    });
  };

  return (
    <Modal
      visible={props.visible}
      width={props.isMobile ? '100%' : "35%"}
      bodyStyle={{ padding: 0 }}
      className={props.isMobile ? 'modalMobile' : 'modalImage'}
      centered
      onCancel={() => props.handleBack()}
      footer={null}
      closable
      maskClosable
      style={{ borderRadius: "10px" }}
      closeIcon={<CloseOutlined style={{ color: "#565353" }} />}
    >
      <div className="head_modal_center">
        <span className="title_head_create_admin" style={{ display: "block" }}>
          Create Account for Admin University
        </span>
        <div className="dividerModal" />
      </div>
      <div style={{ padding: "10px" }}>
        <Row>
          <Col span={24}>
            <Form
              form={form}
              onFinish={(values) => checkFinish(values)}
              onFinishFailed={onFinishFailed}
            >
              <Row gutter={[0, 0]}>
                <Col span={4}></Col>
                <Col span={16}>
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input username!",
                      },
                    ]}
                  >
                    <Input
                      maxLength={15}
                      placeholder="Username"
                      className="input_style"
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={4}></Col>
                <Col span={4}></Col>
                <Col span={16}>
                  <Form.Item
                    name="password"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please input this section!",
                      },
                      {
                        min: 5,
                        message: "Password can't less than 5.",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder={"Password"}
                      className="input_style"
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={4}></Col>
                <Col span={4}></Col>
                <Col span={16}>
                  <Form.Item
                    name=""
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please input this section!",
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject("Password do not match");
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      placeholder={"Confirm Password"}
                      className="input_style"
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={4}></Col>
                <Col span={24}>
                  <Form.Item
                    style={{
                      textAlign: "center",
                      marginBottom: "0px",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      type="link"
                      className="resetButton"
                      size={"small"}
                      onClick={() => handleResetForm()}
                    >
                      Reset
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="updateButton"
                      loading={props.loadingSubmit}
                      size={"small"}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export const UIModalCreateAdminUniv = ModalCreateAdminUnivCore;
