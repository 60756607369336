import types from "../constants";

const initialState = {
   status: "loading",
   data: []
}

export default function deleteMasterKota(state = initialState, action) {
   switch( action.type ) {
      case types.DELETE_KOTA_LOADING:
         return {
            ...state,
            ...action.payload
         }
      case types.DELETE_KOTA_SUCCESS:
         return {
            isSuccess: true,
            ...state,
            ...action.payload
         }
      case types.DELETE_KOTA_FAILED:
         return {
            isSuccess: false,
            ...state,
            ...action.payload
         }
      default:
         return { ...initialState, ...state }
   }  
}