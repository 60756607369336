import { get, put, post } from "axios";
import config from "../services/config";

const { BASE_URL_LOGIN, TIMEOUT } = config;

const getData = param => {
   return get(`${BASE_URL_LOGIN}be/api/cityAdmin/readAll?filter=${param.filter}&orderby=${param.orderBy}&top=${param.top}&skip=${param.skip}`, 
      {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${param.token}`,
         },
         timeout: TIMEOUT
      }
   ).then( response => {
      const normalizeData = response.data.items.map( res => {
         return {
            id: res.cityId,
            cityId: res.cityId,
            cityName: res.cityName,
            provinceId: res.provinceId.provinceId,
            provinceName: res.provinceId.provinceName,
            countryId: res.provinceId.countryId.countryId,
            countryName: res.provinceId.countryId.countryName
         }
      });
      return {
         data: normalizeData,
         totalData: response.data.totalItems ? response.data.totalItems : 0,
         status: 'success',
         error: '' 
      }
   }).catch(error => {
      return {
         status: 'error',
         totalData: 0,
         error,
         data: []
      }
   });
};

const createKota = (token, body)=> {
   return post(`${BASE_URL_LOGIN}be/api/submitCityAdmin`,
      body,
      {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
         timeout: TIMEOUT
      }
   ).then( response => {
      return {
         data: response,
         status: "success",
         error: ''
      }
   }).catch( error => {
      return {
         data: [],
         status: "error",
         error
      }
   })
}

const editKota = (token, body, id) => {
   return put(`${BASE_URL_LOGIN}be/api/updateCityAdmin/${id}`,
      body,
      {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
            
         },
         timeout: TIMEOUT
      }
   ).then( response => {
      return {
         data: response,
         status: "success",
         error: ""
      }
   }).catch( error => {
      return {
         data: [],
         status: "error",
         error
      }
   })
}

const deleteKota = (token ,body, id) => {
   return put(`${BASE_URL_LOGIN}be/api/deleteCityAdmin/${id}`,
      body,
      {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
         },
         timeout: TIMEOUT
      }
   ).then( response => {
      return {
         data: response,
         status: "success",
         error: ""
      }
   }).catch( error => {
      return {
         data: [],
         status: "error",
         error
      }
   })
}

export default {
   getData,
   createKota,
   editKota,
   deleteKota
}