import { combineReducers } from 'redux'
import approval from './approval'
import dashboardUser from './dashboardUser'
import dashboardBeasiswa from './dashboardBeasiswa'
import presentation from "./presentation";
import lateRegister from "./lateRegister";
import detailApproval from './detailApproval'
import lovSchedule from './lovSchedule'
import lovSession from './lovSession'
import updateApproval from './updateApproval'
import lovInstansi  from './lovInstansi'
import putApproval from './putApproval'
import rejectApproval from './rejectApproval'
import postUniv from './postUniv'
import detailPresentation from "./detailPresentation";
import participantListLoyola from "./participantListLoyola";
import participantListPublic from "./participantListPublic";
import rejectLateRegister from "./rejectLateRegister";
import sendEmail from './sendEmail'
import gallery from "./gallery";
import postGallery from "./postGallery";
import putGallery from "./putGallery";
import manageBidiktiWeek from "./manageBidiktiWeek";
import detailManageBidiktiWeek from "./detailManageBidiktiWeek";
import galeryActive from './galeryActive'
import geleryInstitusion from './geleryInstitusion'
import bwSesion from './bwSession'
import bwSchedule from './bwSchedule'
import createBW from './createBW'
import createSchedule from './createSchedule'
import createSesi from './createSesi'
import updateBW from './updateBW'
import kerjasama from './kerjasama'
import homepage from './homepage'
import headerFooter from './headerFooter'
import faq from './faq'
import faqDetail from './faqDetail'
import lovFaqCat from './lovFaqCat'
import settingLifeSharing from './settingLifeSharing'
import lovCountry from './lovCountry'
import lovProvince from './lovProvince'
import lovCity from './lovCity'
import impian from './impian'
import impianDetail from './impianDetail'
import lovLifeSharingCategory from "./lovLifeSharingCategory";
import masterKota from './masterKota'
import createMasterKota from './createMasterKota'
import putMasterKota from './putMasterKota'
import deleteMasterKota from './deleteMasterKota'
import masterProvinsi from './masterProvinsi'
import createMasterProvinsi from './createMasterProvinsi'
import putMasterProvinsi from './putMasterProvinsi'
import deleteMasterProvinsi from './deleteMasterProvinsi'
import lovScheduleGallery from "./lovScheduleGallery";
import lovSessionGallery from "./lovSessionGallery";
import masterNegara from './masterNegara'
import createMasterNegara from './createMasterNegara'
import putMasterNegara from './putMasterNegara'
import deleteMasterNegara from './deleteMasterNegara'
import masterUang from './masterUang'
import createMasterUang from './createMasterUang'
import putMasterUang from './putMasterUang'
import deleteMasterUang from './deleteMasterUang'
import manageAdmin from "./manageAdmin";
import manageAdminUniv from "./manageAdminUniv";
import detailAdmin from "./detailAdmin";
import putAdmin from "./putAdmin";
import manageAdminApproval from "./manageAdminApproval";
import approveBeasiswa from "./beasiswa/approveBeasiswa";
import rejectBeasiswa from "./beasiswa/rejectBeasiswa";
import listBeasiswa from "./beasiswa/listBeasiswa";
import putDeleteListBeasiswa from "./beasiswa/putDeleteListBeasiswa";
import detailBeasiswa from "./beasiswa/detailBeasiswa";
import detailAdminApproval from "./detailAdminApproval";
import listEntranceUniv from "./listEntranceUniv";
import putAdminUnivApproval from "./putAdminUnivApproval";
import lovDataInstansi from "./lovDataInstansi";
import getLifeSharingEvent from "./lifeSharing/getLifeSharingEvent";
import getLifeSharingEventDetail from "./lifeSharing/getLifeSharingEventDetail";
import getLifeSharingEventParticipant from "./lifeSharing/getLifeSharingEventParticipant";
import getLOVSharingType from "./lifeSharing/getLOVSharingType";
import postLifeSharingEvent from "./lifeSharing/postLifeSharingEvent";
import deleteLifeSharingEvent from "./lifeSharing/deleteLifeSharingEvent";
import approveAdminUniv from "./manageAdmin/approveAdminUniv";
import rejectAdminUniv from "./manageAdmin/rejectAdminUniv";
import getLOVLifeSharingType from "./lifeSharing/getLOVLifeSharingType";
import lifeSharingGallery from "./lifeSharingGallery";
import lifeSharingGalleryDetail from "./lifeSharingGalleryDetail";
import lovLifeSharingType from "./lovLifeSharingType";
import lovLifeSharingSpeaker from "./lovLifeSharingSpeaker";
import lovLifeSharingDate from "./lovLifeSharingDate";
import lovLifeSharingTime from "./lovLifeSharingTime";
import postLifeSharingGallery from "./postLifeSharingGallery";
import getDaftarAcara from "./AdminUnivDaftarAcara/getDaftarAcara";
import daftarPersetujuanGaleri from "./daftarPersetujuanGaleri";
import approveDaftarPersetujuanGaleri from "./approveDaftarPersetujuanGaleri";
import rejectDaftarPersetujuanGaleri from "./rejectDaftarPersetujuanGaleri";
import approveEventUniversity from "./daftarAcara/approveEventUniversity";
import rejectEventUniversity from "./daftarAcara/rejectEventUniversity";
import listEventUniversity from "./daftarAcara/listEventUniversity";
import detailEventUniversity from "./daftarAcara/detailEventUniversity";
import updateEventUniversity from "./daftarAcara/updateEventUniversity";
import getDetailDaftarAcara from "./AdminUnivDaftarAcara/getDetailDaftarAcara";
import getParticipantList from "./AdminUnivDaftarAcara/getParticipantList";
import getEventParticipant from "./daftarAcara/getEventParticipant";
import daftarGaleri from "./daftarGaleri";
import lovEventNameByUniv from "./lovEventNameByUniv";
import lovDateByEvent from "./lovDateByEvent";
import postUploadDaftarGaleri from "./postUploadDaftarGaleri";
import postDaftarGaleri from "./postDaftarGaleri";
import detailDaftarGaleri from "./detailDaftarGaleri";
import putThumbnailDaftarGaleri from "./putThumbnailDaftarGaleri";
import getListSubscriber from "./subscribe/getListSubscriber";
import sendMailNews from "./subscribe/sendMailNews";
import uploadBanner from "./subscribe/uploadBanner";
import getProfileUniversitas from "./profileUniversitas/getProfileUniversitas";
import getDetailProfileUniversitas from "./profileUniversitas/getDetailProfileUniversitas";
import getProfileUniversitasJalurMasuk from "./profileUniversitas/getProfileUniversitasJalurMasuk";
import getProfileUniversitasDetailJalurMasuk from "./profileUniversitas/getProfileUniversitasDetailJalurMasuk";
import lovUniversitasCategori from "./lovUniversitasCategori";
import postLogoProfileUniversitas from "./profileUniversitas/postLogoProfileUniversitas";
import postBannerProfileUniversitas from "./profileUniversitas/postBannerProfileUniversitas";
import postHumasProfileUniversitas from "./profileUniversitas/postHumasProfileUniversitas";
import postFormProfileUniversitas from "./profileUniversitas/postFormProfileUniversitas";
import postJalurMasukProfileUniversitas from "./profileUniversitas/postJalurMasukProfileUniversitas";
import putEditProfileUniversitas from "./profileUniversitas/putEditProfileUniversitas";
import getDaftarPersetujuanKonten from "./daftarPersetujuanKonten/getDaftarPersetujuanKonten";
import getDetailDaftarPersetujuanKonten from "./daftarPersetujuanKonten/getDetailDaftarPersetujuanKonten";
import getJalurMasukDaftarPersetujuanKonten from "./daftarPersetujuanKonten/getJalurMasukDaftarPersetujuanKonten";
import approveDaftarPersetujuanKonten from "./daftarPersetujuanKonten/approveDaftarPersetujuanKonten";
import rejectDaftarPersetujuanKonten from "./daftarPersetujuanKonten/rejectDaftarPersetujuanKonten";
import deleteLifeSharingGallery from "./lifeSharing/deleteLifeSharingGallery";
import updateUrlLifeSharingGallery from "./lifeSharing/updateUrlLifeSharingGallery";
import getDashboardListUniversitas from "./dashboard/getDashboardListUniversitas";
import getDashboardBidiktiWeek from "./dashboard/getDashboardBidiktiWeek";
import lovTypeLogging from './lovTypeLoging'
import downloadLogging from './downloadLoging'

export default combineReducers({
  approval,
  dashboardUser,
  dashboardBeasiswa,
  detailApproval,
  presentation,
  lateRegister,
  lovSchedule,
  lovSession,
  updateApproval,
  lovInstansi,
  putApproval,
  rejectApproval,
  postUniv,
  detailPresentation,
  participantListLoyola,
  participantListPublic,
  rejectLateRegister,
  sendEmail,
  gallery,
  postGallery,
  putGallery,
  manageBidiktiWeek,
  detailManageBidiktiWeek,
  galeryActive,
  geleryInstitusion,
  bwSesion,
  bwSchedule,
  createBW,
  createSchedule,
  createSesi,
  updateBW,
  kerjasama,
  homepage,
  headerFooter,
  faq,
  faqDetail,
  lovFaqCat,
  settingLifeSharing,
  lovCountry,
  lovProvince,
  lovCity,
  impian,
  impianDetail,
  lovLifeSharingCategory,
  masterKota,
  createMasterKota,
  putMasterKota,
  deleteMasterKota,
  masterProvinsi,
  createMasterProvinsi,
  putMasterProvinsi,
  deleteMasterProvinsi,
  lovScheduleGallery,
  lovSessionGallery,
  masterNegara,
  createMasterNegara,
  putMasterNegara,
  deleteMasterNegara,
  masterUang,
  createMasterUang,
  putMasterUang,
  deleteMasterUang,
  manageAdmin,
  manageAdminUniv,
  detailAdmin,
  putAdmin,
  manageAdminApproval,
  approveBeasiswa,
  rejectBeasiswa,
  listBeasiswa,
  putDeleteListBeasiswa,
  detailBeasiswa,
  detailAdminApproval,
  listEntranceUniv,
  putAdminUnivApproval,
  lovDataInstansi,
  getLifeSharingEvent,
  getLifeSharingEventDetail,
  getLifeSharingEventParticipant,
  getLOVSharingType,
  postLifeSharingEvent,
  deleteLifeSharingEvent,
  approveAdminUniv,
  rejectAdminUniv,
  getLOVLifeSharingType,
  lifeSharingGallery,
  lifeSharingGalleryDetail,
  lovLifeSharingType,
  lovLifeSharingSpeaker,
  lovLifeSharingDate,
  lovLifeSharingTime,
  postLifeSharingGallery,
  getDaftarAcara,
  daftarPersetujuanGaleri,
  approveDaftarPersetujuanGaleri,
  rejectDaftarPersetujuanGaleri,
  approveEventUniversity,
  rejectEventUniversity,
  listEventUniversity,
  detailEventUniversity,
  updateEventUniversity,
  getDetailDaftarAcara,
  getParticipantList,
  getEventParticipant,
  daftarGaleri,
  lovEventNameByUniv,
  lovDateByEvent,
  postUploadDaftarGaleri,
  postDaftarGaleri,
  detailDaftarGaleri,
  putThumbnailDaftarGaleri,
  getListSubscriber,
  sendMailNews,
  uploadBanner,
  getProfileUniversitas,
  getDetailProfileUniversitas,
  getProfileUniversitasJalurMasuk,
  getProfileUniversitasDetailJalurMasuk,
  lovUniversitasCategori,
  postLogoProfileUniversitas,
  postBannerProfileUniversitas,
  postHumasProfileUniversitas,
  postFormProfileUniversitas,
  postJalurMasukProfileUniversitas,
  putEditProfileUniversitas,
  getDaftarPersetujuanKonten,
  getDetailDaftarPersetujuanKonten,
  getJalurMasukDaftarPersetujuanKonten,
  approveDaftarPersetujuanKonten,
  rejectDaftarPersetujuanKonten,
  deleteLifeSharingGallery,
  updateUrlLifeSharingGallery,
  getDashboardListUniversitas,
  getDashboardBidiktiWeek,
  lovTypeLogging,
  downloadLogging
})
