import types from "../constants";
import masterNegara from "../api/masterNegara";

const getData = param => dispatch => {
   dispatch({
      type: types.GET_NEGARA_LOADING,
      payload: {
         data: [],
         totalData: 0,
         status: "loading",
         error: ""
      }
   })
   return masterNegara.getData(param).then(result => {
      if (result.status === "error") {
         return dispatch({
            type: types.GET_NEGARA_FAILED,
            payload: result
         });
      } else {
         return dispatch({
            type: types.GET_NEGARA_SUCCESS,
            payload: result
         });
      }
   })
}

const createNegara = (token, body) => dispatch => {
   dispatch({
      type: types.CREATE_NEGARA_LOADING,
      payload: {
         status: 'loading',
         error: '',
         data: []
      }
   })
   return masterNegara.createNegara(token, body).then(result => {
      if (result.status === "error") {
         return dispatch({
            type: types.CREATE_NEGARA_FAILED,
            payload: result
         })
      } else {
         return dispatch({
            type: types.CREATE_NEGARA_SUCCESS,
            payload: result
         })
      }
   })
}

const editNegara = (token, body, id) => dispatch => {
   dispatch({
      type: types.PUT_NEGARA_LOADING,
      payload: {
         status: "loading",
         error: "",
         data: []
      }
   })
   return masterNegara.editNegara(token, body, id).then(result => {
      if (result.status === "error") {
         return dispatch({
            type: types.PUT_NEGARA_FAILED,
            payload: result
         });
      } else {
         return dispatch({
            type: types.PUT_NEGARA_SUCCESS,
            payload: result
         });
      }
   })
}

const deleteNegara = (token, body, id) => dispatch => {
   dispatch({
      type: types.DELETE_NEGARA_LOADING,
      payload: {
         data: [],
         status: "loading",
         error: ""
      }
   })
   return masterNegara.deleteNegara(token, body, id).then(result => {
      if (result.status === "error") {
         return dispatch({
            type: types.DELETE_NEGARA_FAILED,
            payload: result
         });
      } else {
         return dispatch({
            type: types.DELETE_NEGARA_SUCCESS,
            payload: result
         });
      }
   })
}

export default {
   getData,
   createNegara,
   editNegara,
   deleteNegara
}