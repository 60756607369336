import types from "../constants";
import masterKota from "../api/masterKota";

const getData = param => dispatch => {
   dispatch({
      type: types.GET_KOTA_LOADING,
      payload: {
         status: 'loading',
         error: '',
         data: undefined,
         totalData: 0
      }
   })
   return masterKota.getData(param).then( result => {
      if (result.status === 'error') {
         return dispatch({
            type: types.GET_KOTA_FAILED,
            payload: result
         })
      } else {
         return dispatch({
            type: types.GET_KOTA_SUCCESS,
            payload: result
         })
      }
   })
}

const createKota = (token, body) => dispatch => {
   dispatch({
      type: types.CREATE_KOTA_LOADING,
      payload: {
         status: 'loading',
         error: '',
         data: []
      }
   })
   return masterKota.createKota(token, body).then( result => {
      if ( result.status === "error" ) {
         return dispatch({
            type: types.CREATE_KOTA_FAILED,
            payload: result
         })
      } else {
         return dispatch({
            type: types.CREATE_KOTA_SUCCESS,
            payload: result
         })
      }
   })
}   

const editKota = (token, body, id) => dispatch => {
   dispatch({
      type: types.PUT_KOTA_LOADING,
      payload: {
         status: "loading",
         error: "",
         data: []
      }
   })
   return masterKota.editKota(token, body, id).then( result => {
      if ( result.status === "error" ) {
         return dispatch({
            type: types.PUT_KOTA_FAILED,
            payload: result
         });
      } else {
         return dispatch({
            type: types.PUT_KOTA_SUCCESS,
            payload: result
         });
      }
   })
}

const deleteKota = (token, body, id) => dispatch => {
   dispatch({
      type: types.DELETE_KOTA_LOADING,
      payload: {
         data: [],
         status: "loading",
         error: ""
      }
   })
   return masterKota.deleteKota(token, body, id).then( result => {
      if ( result.status === "error" ) {  
         return dispatch({
            type: types.DELETE_KOTA_FAILED,
            payload: result
         });
      } else {
         return dispatch({
            type: types.DELETE_KOTA_SUCCESS,
            payload: result
         });
      }
   })
}

export default {
   getData,
   createKota,
   editKota,
   deleteKota
}