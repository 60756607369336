import types from '../constants'
import lifeSharing from "../api/settingLifeSharing";

const getData = (token) => dispatch => {
  dispatch({
    type: types.GET_SETTING_LIFE_SHARING_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: undefined,
      totalData: 0
    }
  })
  return lifeSharing.getData(token).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.GET_SETTING_LIFE_SHARING_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_SETTING_LIFE_SHARING_SUCCESS,
        payload: result
      })
    }
  })
}

const handleEdit = (token, userId, body) => dispatch => {
  dispatch({
    type: types.PUT_SETTING_LIFE_SHARING_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return lifeSharing.handleEdit(token, userId, body).then( result => {
    if (result.status === 'error') {
      return{
        type: types.PUT_SETTING_LIFE_SHARING_FAILED,
        payload: result
      }
    }else {
      return {
        type: types.PUT_SETTING_LIFE_SHARING_SUCCESS,
        payload: result,
      }
    }
  })
}

export default {
  getData,
  handleEdit
}