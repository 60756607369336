import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../global/global.css'
import getAdmin from '../../../actions/manageAdmin'
import {
  Card,
  Row,
  Col,
  Breadcrumb,
  Typography
} from 'antd'
import { UItable, UISearch } from '../../../components'

const { Text } = Typography

class AdminUniv extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderBy: '',
      size: 10,
      page: 1,
      search: '',
      visibleRegis: false,
      initial: undefined,
    }
  }

  componentDidMount() {
    const {orderBy, size, page, search } = this.state
    this.props.handleDataAdminUniv(this.props.token, orderBy, size, page, search)
  }

  handleRedirectDetail = id => {
    // this.props.history.push(`/admin-detail/${id}`)
  }

  handleTableChange = (page, filter, sorter) => {
    const { size, search} = this.state
    let sorterField
    if ( sorter.field === "adminName" ) {
      sorterField = "univ_name";
    }else if ( sorter.field === "adminUserName" ) {
      sorterField = "u.users_username";
    } else if ( sorter.field === "adminEmail" ) {
      sorterField = "u.users_email";
    }

    let orderBy = "";
    if (sorter.order === "ascend" ) {
      orderBy = `${sorterField},asc`;
    } else if (sorter.order === "descend") {
      orderBy = `${sorterField},desc`;
    }

    this.setState({
      page: page.current,
      orderBy: orderBy
    }, ()=> {
      this.props.handleDataAdminUniv(this.props.token, orderBy, size, page.current, search)
    })
  }

  handleSearch = key => {
    const {orderBy, size} = this.state
    this.setState({
      search: key,
      page: 1
    })
    this.props.handleDataAdminUniv(this.props.token, orderBy, size, 1, key)
  }

  render() {
    const columnsTable = [
      {
        title: 'Name',
        dataIndex: 'adminName',
        key: 'adminName',
        sorter: true,
        render: name => <Text style={{ color: '#EF712B', cursor: 'pointer', fontWeight: 600}}>{name}</Text>,
        onCell: record => {
          return{
            onClick: () => {
              this.handleRedirectDetail(record.adminId ? record.adminId : '')
            }
          }
        }
      },
      {
        title: 'Username',
        dataIndex: 'adminUserName',
        key: 'adminUserName',
        sorter: true,
        onCell: record => {
          return{
            onClick: () => {
              this.handleRedirectDetail(record.adminId ? record.adminId : '')
            }
          }
        }
      },
      {
        title: 'Email',
        dataIndex: 'adminEmail',
        key: 'adminEmail',
        sorter: true,
        onCell: record => {
          return{
            onClick: () => {
              this.handleRedirectDetail(record.adminId ? record.adminId : '')
            }
          }
        }
      },
    ]
    const {
      dataAdminUniv,
    } = this.props

    const {
      page
    } = this.state

    return (
      <div className='root'>
        <Breadcrumb className="breadcrumb_style">
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/dashboard`)}>Kelola Admin </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className='pathNow_style'>
            Admin Universitas
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card className='bodyCard_style'>
          <Row>
            <Col span={8}>
              <UISearch placeholder='Search' handleSearch={key => this.handleSearch(key)} />
            </Col>
          </Row>
          <Card
            bordered={false}
            loading={dataAdminUniv.loading}
            style={{minHeight: '300px'}}>
            <UItable
              dataTable={dataAdminUniv.data}
              columns={columnsTable}
              withRows={false}
              isMobile={this.props.isMobile}
              totalData={dataAdminUniv.totalData}
              loading={dataAdminUniv.status}
              page={page}
              handleTableChange={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter)}
            />
          </Card>
        </Card>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return{
    handleDataAdminUniv: (token, sort, size, page, search) => dispatch(getAdmin.getDataAdminUniv(token, sort, size, page, search)),
  }
}

const mapStateToProps = (state) => {
  return {
    dataAdminUniv: state.manageAdminUniv,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUniv)
