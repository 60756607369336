import types from '../constants'

const initialState = {
  status: 'loading',
  data: {
    faqAnswerEng: '',
    faqAnswerInd: '',
    faqCategoryName: '',
    faqQuestionEng: '',
    faqQuestionInd: '',
  }
}

export default function faqDetail(state = initialState, action) {
  switch (action.type) {
    case types.GET_FAQDETAIL_LOADING:
      return {
        ...state,
        ...action.payload
      }
    case types.GET_FAQDETAIL_SUCCESS:
      return {
        isSuccess: true,
        ...state,
        ...action.payload
      }
    case types.GET_FAQDETAIL_FAILED:
      return {
        isSuccess: false,
        ...state,
        ...action.payload
      }
    default:
      return { ...initialState, ...state }
  }
}
