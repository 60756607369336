import types from '../../constants'

const initialState = {
  status: 'loading',
  data: []
}

export default function getLOVLifeSharingType(state = initialState, action) {
  switch (action.type) {
    case types.GET_LOV_LIFE_SHARING_LOADING:
      return {
        ...state,
        ...action.payload
      }
    case types.GET_LOV_LIFE_SHARING_SUCCESS:
      return {
        isSuccess: true,
        ...state,
        ...action.payload
      }
    case types.GET_LOV_LIFE_SHARING_FAILED:
      return {
        isSuccess: false,
        ...state,
        ...action.payload
      }
    default:
      return { ...initialState, ...state }
  }
}
