import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../global/global.css'
import { UIModalParticipantDetail, UIContentDetailLSEvent } from '../../../components'
import { Card, notification, Breadcrumb } from 'antd'
import getEventDetail from '../../../actions/lifeSharingEvent'

class LifeSharingEventDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initial: '',
      id: '',
      visiblePartisipan: false,
      loyolaActive: true,
      page: 1,
      orderBy: '',
      size: 10,
      search: '',
      loadingPost: false
    }
  }

  componentDidMount() {
    let id = this.props.location.pathname.split('/')[2]
    this.setState({
      id: id
    })
    this.props.handleDataDetail(this.props.token, id)
  }

  openPartisipan = () => {
    this.setState({
      visiblePartisipan: true
    })
    this.props.handleDataParticipantList(this.props.token, this.state.id, 'komunitas loyola', 'status', '10',1, '');
  }

  closeModal = () => {
    this.setState({
      visiblePartisipan: false
    })
  }

  changeButton = (val) => {
    this.setState({
      loyolaActive: val,
      page: 1,
      orderBy: '',
      search: '',
    })
    if (val) {
      this.props.handleDataParticipantList(this.props.token, this.state.id, 'komunitas loyola', 'status', '10', 1, '');
    }else {
      this.props.handleDataParticipantList(this.props.token, this.state.id, 'non loyola', 'status', '10', 1, '');
    }
  }

  handleTableChange = (page, filter, sorter, from) => {
    const { size, search} =this.state

    let sorterField = ""
    if ( sorter.field === "roleName" ) {
      if (from === 'loyola') {
        sorterField = "roleName";
      }else {
        sorterField = "roleName";
      }
    }else if ( sorter.field === "sharingParticipantName" ) {
      sorterField = "sharingParticipantName";
    }else {
      sorterField = "email";
    }

    let orderBy = "";
    if (sorter.order === "ascend" ) {
      orderBy = `${sorterField},asc`;
    } else if (sorter.order === "descend") {
      orderBy = `${sorterField},desc`;
    }

    this.setState({
      page: page.current,
      orderBy: orderBy
    }, ()=> {
      if (from === 'loyola') {
        this.props.handleDataParticipantList(this.props.token, this.state.id, 'komunitas loyola', orderBy, size, page.current, search)
      }else {
        this.props.handleDataParticipantList(this.props.token, this.state.id, 'non loyola', orderBy, size, page.current, search)
      }
    })
  }

  handleSearch = key => {
    const {loyolaActive, page, size, orderBy} = this.state
    this.setState({
      search: key
    })
    if (loyolaActive) {
      this.props.handleDataParticipantList(this.props.token, this.state.id, 'komunitas loyola', orderBy, size, page, key);
    }else {
      this.props.handleDataParticipantList(this.props.token, this.state.id, 'non loyola', orderBy, size, page, key);
    }
  }

  handleShare = () => {
    this.setState({
      loadingPost: true
    })
    this.props.handleSendEmail(this.props.token, this.state.id).then(res => {
      if (res.payload.status !== 'error') {
        if (res.payload.data.data.Status === 'OK') {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Send Email Success',
          });
          this.setState({
            search: '',
            page: 1,
            orderBy: '',
            loadingPost: false,
          })
        }else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.error.response.data.message ? res.payload.error.response.data.message : 'Send Email Failed'
          });
          this.setState({
            loadingPost: false,
          })
        }
      }else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: res.payload.error.response.data.message ? res.payload.error.response.data.message : 'Send Email Failed'
        });
        this.setState({
          loadingPost: false,
        })
      }
    })
  }

  render() {

    const columnsTableLoyola = [
      {
        title: 'Nama',
        dataIndex: 'sharingParticipantName',
        key: 'sharingParticipantName',
        sorter: true,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        sorter: true,
      },
      {
        title: 'Status',
        dataIndex: 'roleName',
        key: 'roleName',
        sorter: true,
      },
    ];

    const columnsTablePublic = [
      {
        title: 'Nama',
        dataIndex: 'sharingParticipantName',
        key: 'sharingParticipantName',
        sorter: true,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        sorter: true,
      },
      {
        title: 'Status',
        dataIndex: 'roleName',
        key: 'roleName',
        sorter: true,
      },
    ];

    const { dataDetail, dataParticipantList } = this.props
    const {
      visiblePartisipan,
      loyolaActive,
      page,
      loadingPost
    } = this.state
    return(
      <div className='root'>
        <Breadcrumb className="breadcrumb_style" >
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/dashboard`)}>Life Sharing </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/life-sharing-event`)}>Daftar Acara </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className='pathNow_style'>
            Detail Acara
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card
          loading={dataDetail.status === 'loading'}
          className='bodyCard_style'
        >
          <UIContentDetailLSEvent
            from={'presentation'}
            isMobile={this.props.isMobile}
            dataDetail={dataDetail ? dataDetail.data : []}
            handleOpenModalInfo={() => this.openPartisipan()}
            handleShare={() => this.handleShare()}
            loadingPost={loadingPost}
          />
        </Card>
        <UIModalParticipantDetail
          visible={visiblePartisipan}
          isMobile={this.props.isMobile}
          handleBack={() => this.closeModal()}
          dataParticipantLoyola={dataParticipantList.data}
          dataParticipantPublic={dataParticipantList.data}
          columnsLoyola={columnsTableLoyola}
          columnsPublic={columnsTablePublic}
          loadingLoyola={dataParticipantList.status}
          loadingPublic={dataParticipantList.status}
          changeButton={val => this.changeButton(val)}
          loyolaActive={loyolaActive}
          totalDataLoyola={dataParticipantList.totalData}
          handleTableChangeLoyola={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter, 'loyola')}
          page={page}
          handleSearch={key => this.handleSearch(key)}
          totalDataPublic={dataParticipantList.totalData}
          handleTableChangePublic={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter, 'public')}
        />
      </div>
    )
  }
}
const mapDispatchToProps = dispatch => {
  return{
    handleDataDetail: (token, id) => dispatch(getEventDetail.getDataEventDetail(token, id)),
    handleDataParticipantList: (token, id, group, sort, size, page, search) => dispatch(getEventDetail.getDataEventParticipant(token, id, group, sort, size, page, search)),
    handleSendEmail: (token, id) => dispatch(getEventDetail.shareEvent(token, id)),
  }
}

const mapStateToProps = (state) => {
  return {
    dataDetail: state.getLifeSharingEventDetail,
    dataParticipantList: state.getLifeSharingEventParticipant,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LifeSharingEventDetail)
