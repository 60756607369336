import types from '../../constants'

const initialState = {
  status: 'loading',
  data: []
}

export default function listBeasiswa(state = initialState, action) {
  switch (action.type) {
    case types.GET_LIST_BEASISWA_LOADING:
      return {
        ...state,
        ...action.payload
      }
    case types.GET_LIST_BEASISWA_SUCCESS:
      return {
        isSuccess: true,
        ...state,
        ...action.payload
      }
    case types.GET_LIST_BEASISWA_FAILED:
      return {
        isSuccess: false,
        ...state,
        ...action.payload
      }
    default:
      return { ...initialState, ...state }
  }
}
