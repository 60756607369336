import React, {useState} from 'react';
import {Row, Col, Modal, Button, Form, Input, Typography} from 'antd';
import {CaretRightOutlined} from '@ant-design/icons';
import "../../global/global.css";
import ImagePreviewComponent from '../../components/imagePreview'
import {GlobalVideoCheck} from '../../global/checkingVideo'

const {Title} = Typography

const DetailContentLSGalleryCore = props => {
  const [visibleVideo, setVisibleVideo] = useState(false);
  const [urlVideo, setUrlVideo] = useState('');
  const [idVideo, setIdVideo] = useState('')

  const [visibleEdit, setVisibleEdit] = useState(false)

  const dataDetail = props.dataDetail;

  const [form] = Form.useForm();

  const handleOpenVideo = url => {
    setUrlVideo(url);
    setVisibleVideo(true);
  }

  const handleCloseVideo = () => {
    setUrlVideo('');
    setVisibleVideo(false);
  }

  const handleOpenEdit = data => {
    setIdVideo(data.sharingGalleryId)
    setUrlVideo(data.sharingGalleryUrl);
    form.setFieldsValue({
      linkYoutube: data.sharingGalleryUrl,
    })
    setVisibleEdit(true);
  }

  const handleCloseEdit = () => {
    setUrlVideo('');
    setVisibleEdit(false);
  }

  const handleResetForm = () => {
    form.resetFields();
  };

  const onFinish = values => {
    const data = {
      ...values,
      id: idVideo
    }
    props.onFinish(data)
  }

  return (
    <Row gutter={[10, 10]}>
      <Col span={12}>
        <Col span={24}>
          <span className='header_text_detail'>
            Nama Lengkap Pemateri
          </span>
        </Col>
        <Col span={24}>
          <span className='text_detail_institusi'>
            {dataDetail.sharingEventSpeaker ? dataDetail.sharingEventSpeaker : '-'}
          </span>
        </Col>
        <Col span={24}>
          <span className='header_text_detail'>
            Tahun Lulus KEKL
          </span>
        </Col>
        <Col span={24}>
          <span className='data_text_detail'>
            {dataDetail.sharingEventKeklGradYear ? dataDetail.sharingEventKeklGradYear : '-'}
          </span>
        </Col>
        <Col span={24}>
          <span className='header_text_detail'>
            Deskripsi Acara Narasumber
          </span>
        </Col>
        <Col span={24}>
          <span className='data_text_detail'>
            {dataDetail.sharingEventDesc ? dataDetail.sharingEventDesc : '-'}
          </span>
        </Col>
      </Col>
      <Col span={12}>
        <Col span={24}>
          <span className='header_text_detail'>
            Media
          </span>
        </Col>
        <Col span={24}>
          <Row gutter={[10, 16]}>
            {
              dataDetail.detailSharingGallery && dataDetail.detailSharingGallery.map((res, index) => {
                return (
                  <>
                    <Col span={props.isMobile ? 24 : 12} key={index}>
                      <div className='gradientCard'>
                        <CaretRightOutlined 
                          className='playButton'
                          onClick={() => handleOpenVideo(res.sharingGalleryEmbed)}
                        />
                        <ImagePreviewComponent
                          isMobile={props.isMobile}
                          styles={{ width: '100%' }}
                          uri={res.sharingGalleryImage}
                          imageBorder={'6px'}
                        />
                      </div>
                    </Col>
                    <Col span={props.isMobile ? 24 : 11} style={{textAlign: 'right', margin: 'auto'}}>
                      <Button
                        loading={props.loadingEdit}
                        className='editButton'
                        onClick={() => handleOpenEdit(res)}
                        type="link"
                      >
                        Edit
                      </Button>
                    </Col>
                  </>
                )
              })
            }
            <Modal
              visible={visibleVideo}
              centered
              className={props.isMobile ? 'cardVideoMobile' : 'cardVideo'}
              footer={null}
              width={props.isMobile ? '100%' : '75%'}
              onCancel={() => handleCloseVideo()}
            >

            {GlobalVideoCheck.checking(urlVideo) ? 
              (
                <iframe
                  style={{width: '-webkit-fill-available', height: props.isMobile ? '280px' : '500px'}}
                  id="modalVideo"
                  title='modalVideo'
                  src={urlVideo}
                  frameBorder="0"
                  allow="accelerometer;
                  autoplay;
                  encrypted-media;
                  gyroscope;
                  picture-in-picture"
                  allowFullScreen="allowfullscreen"
                />
              ) : 
              (
                <span className='data_text_detail'>
                  Url Video Tidak Valid/Error
                </span>
              )
            }
            </Modal>
            <Modal
              visible={visibleEdit}
              maskClosable
              className={props.isMobile ? 'modalMobile' : 'modalImage'}
              footer={null}
              width={props.isMobile ? '100%' : 633}
              centered
              onCancel={() => handleCloseEdit()}
              destroyOnClose={true}
            >
              <Row>
                <Col span={24}>
                  <div className='headerForm'>
                    <Title className='titleModalGalery' level={4}>Update URL Video</Title>
                  </div>
                  <div className='dividerGalery'/>
                </Col>
                <Col span={24}>
                  <Form
                    form={form}
                    onFinish={(value) => onFinish(value)}
                    onFinishFailed={(errorInfo) => props.onFinishFailed(errorInfo)}
                  >
                    <Row gutter={[10, 0]}>
                      <Col span={23}>
                  <span className='data_text_detail'>
                    Media:
                  </span>&nbsp;
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          name="linkYoutube"
                          rules={[
                            {required: true, message: 'Mohon isi link youtube'},
                            {
                              pattern: /^http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/ , 
                              message: 'Invalid youtube URL!'
                            }
                          ]}
                          style={{marginBottom: 0}}
                        >
                          <Input
                            value={urlVideo}
                            className='input_style_BW'
                            placeholder='Url Video Youtube'
                          />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <span className="mandatoryLarge">&#8727;</span>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          style={{
                            textAlign: 'right',
                            marginBottom: '0px',
                            marginTop: '10px'
                          }}
                        >
                          <Button
                            type="link"
                            className='resetButton'
                            size={'small'}
                            onClick={() => handleResetForm()}
                          >
                            Reset
                          </Button>
                          <Button
                            type="primary"
                            htmlType='submit'
                            className='updateButton'
                            loading={props.loadingEdit}
                            size={'small'}
                          >
                            Submit
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Modal>
          </Row>
        </Col>
      </Col>
    </Row>
  )
}

export const UIContentDetailLSGallery = DetailContentLSGalleryCore
