const GET_MANAGE_BIDIKTI_WEEK_LOADING = 'GET_MANAGE_BIDIKTI_WEEK_LOADING'
const GET_MANAGE_BIDIKTI_WEEK_SUCCESS = 'GET_MANAGE_BIDIKTI_WEEK_SUCCESS'
const GET_MANAGE_BIDIKTI_WEEK_FAILED = 'GET_MANAGE_BIDIKTI_WEEK_FAILED'
const GET_MANAGE_BIDIKTI_WEEK_DETAIL_LOADING = 'GET_MANAGE_BIDIKTI_WEEK_DETAIL_LOADING'
const GET_MANAGE_BIDIKTI_WEEK_DETAIL_SUCCESS = 'GET_MANAGE_BIDIKTI_WEEK_DETAIL_SUCCESS'
const GET_MANAGE_BIDIKTI_WEEK_DETAIL_FAILED = 'GET_MANAGE_BIDIKTI_WEEK_DETAIL_FAILED'
const GET_SESION_BW_LOADING = 'GET_SESION_BW_LOADING'
const GET_SESION_BW_SUCCESS = 'GET_SESION_BW_SUCCESS'
const GET_SESION_BW_FAILED = 'GET_SESION_BW_FAILED'
const GET_SCHEDULLE_BW_LOADING = 'GET_SCHEDULLE_BW_LOADING'
const GET_SCHEDULLE_BW_SUCCESS = 'GET_SCHEDULLE_BW_SUCCESS'
const GET_SCHEDULLE_BW_FAILED = 'GET_SCHEDULLE_BW_FAILED'
const POST_MANAGE_BW_LOADING = 'POST_MANAGE_BW_LOADING'
const POST_MANAGE_BW_SUCCESS = 'POST_MANAGE_BW_SUCCESS'
const POST_MANAGE_BW_FAILED = 'POST_MANAGE_BW_FAILED'
const POST_SCHEDULE_LOADING = 'POST_SCHEDULE_LOADING'
const POST_SCHEDULE_SUCCESS = 'POST_SCHEDULE_SUCCESS'
const POST_SCHEDULE_FAILED = 'POST_SCHEDULE_FAILED'
const POST_SESION_LOADING = 'POST_SESION_LOADING'
const POST_SESION_SUCCESS = 'POST_SESION_SUCCESS'
const POST_SESION_FAILED = 'POST_SESION_FAILED'
const PUT_MANAGE_BW_LOADING = 'PUT_MANAGE_BW_LOADING'
const PUT_MANAGE_BW_SUCCESS = 'PUT_MANAGE_BW_SUCCESS'
const PUT_MANAGE_BW_FAILED = 'PUT_MANAGE_BW_FAILED'
const PUT_SESION_LOADING = 'PUT_SESION_LOADING'
const PUT_SESION_SUCCESS = 'PUT_SESION_SUCCESS'
const PUT_SESION_FAILED = 'PUT_SESION_FAILED'
const DELETE_SCHEDULE_LOADING = 'DELETE_SCHEDULE_LOADING'
const DELETE_SCHEDULE_SUCCESS = 'DELETE_SCHEDULE_SUCCESS'
const DELETE_SCHEDULE_FAILED = 'DELETE_SCHEDULE_FAILED'
const DELETE_SESION_LOADING = 'DELETE_SESION_LOADING'
const DELETE_SESION_SUCCESS = 'DELETE_SESION_SUCCESS'
const DELETE_SESION_FAILED = 'DELETE_SESION_FAILED'


export {
  GET_MANAGE_BIDIKTI_WEEK_LOADING,
  GET_MANAGE_BIDIKTI_WEEK_SUCCESS,
  GET_MANAGE_BIDIKTI_WEEK_FAILED,
  GET_MANAGE_BIDIKTI_WEEK_DETAIL_LOADING,
  GET_MANAGE_BIDIKTI_WEEK_DETAIL_SUCCESS,
  GET_MANAGE_BIDIKTI_WEEK_DETAIL_FAILED,
  GET_SESION_BW_LOADING,
  GET_SESION_BW_SUCCESS,
  GET_SESION_BW_FAILED,
  GET_SCHEDULLE_BW_LOADING,
  GET_SCHEDULLE_BW_SUCCESS,
  GET_SCHEDULLE_BW_FAILED,
  POST_MANAGE_BW_LOADING,
  POST_MANAGE_BW_SUCCESS,
  POST_MANAGE_BW_FAILED,
  POST_SCHEDULE_LOADING,
  POST_SCHEDULE_SUCCESS,
  POST_SCHEDULE_FAILED,
  POST_SESION_LOADING,
  POST_SESION_SUCCESS,
  POST_SESION_FAILED,
  PUT_MANAGE_BW_LOADING,
  PUT_MANAGE_BW_SUCCESS,
  PUT_MANAGE_BW_FAILED,
  PUT_SESION_LOADING,
  PUT_SESION_SUCCESS,
  PUT_SESION_FAILED,
  DELETE_SCHEDULE_LOADING,
  DELETE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_FAILED,
  DELETE_SESION_LOADING,
  DELETE_SESION_SUCCESS,
  DELETE_SESION_FAILED,
}
