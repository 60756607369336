import types from '../constants'
import homepage from "../api/homepage";

const getDataHomepage = (token) => dispatch => {
  dispatch({
    type: types.GET_HOMEPAGE_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: undefined,
      totalData: 0
    }
  })
  return homepage.getDataHomepage(token).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.GET_HOMEPAGE_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_HOMEPAGE_SUCCESS,
        payload: result
      })
    }
  })
}

const handleEdit = (token, contentId, body) => dispatch => {
  dispatch({
    type: types.PUT_HOMEPAGE_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return homepage.handleEdit(token, contentId, body).then( result => {
    if (result.status === 'error') {
      return{
        type: types.PUT_HOMEPAGE_FAILED,
        payload: result
      }
    }else {
      return {
        type: types.PUT_HOMEPAGE_SUCCESS,
        payload: result,
      }
    }
  })
}

export default {
  getDataHomepage,
  handleEdit
}