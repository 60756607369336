import { get } from 'axios'
import config from '../services/config'
const { BASE_URL_NOAUTH, BASE_URL_LOGIN, TIMEOUT } = config

const getDataSchedule = (token) => {
  return get(`${BASE_URL_NOAUTH}api/listSchedule`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.Data.map(res =>{
      return {
        name: res.bwSchedule,
        id: res.bwScheduleId
      }
    })
    return {
      data: normalizeData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const getDataScheduleGallery = (token, id) => {
  return get(`${BASE_URL_LOGIN}be/api/listScheduleGallery?univRegId=${id}`,
  {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    timeout: TIMEOUT
  }
  ).then(response => {
    const normalizeData = response.data.Data.map(res =>{
      return {
        name: res.bwSchedule,
        id: res.bwScheduleId,
        idInstansi: id,
      }
    })
    return {
      data: normalizeData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const getDataSession = (token, id) => {
  return get(`${BASE_URL_NOAUTH}api/listSession?bwScheduleId=${id}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.Data.map(res =>{
      return {
        name: res.bwSession,
        id: res.bwSessionId
      }
    })
    return {
      data: normalizeData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}


const getDataSessionGallery = (token, idInstansi, id) => {
  return get(`${BASE_URL_LOGIN}be/api/listSessionGallery?univRegId=${idInstansi}&bwScheduleId=${id}`,
  {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    timeout: TIMEOUT
  }
  ).then(response => {
    const normalizeData = response.data.Data.map(res =>{
      return {
        name: res.bwSession,
        id: res.bwSessionId
      }
    })
    return {
      data: normalizeData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const getDataInstansi = (token, search, size, isKedutaan) => {
  let url = ''
  if (size !==  0) {
    url = `be/api/institution?search=${search}&isKedutaan=${isKedutaan}&size=${size}&sort=univ_reg_name`
  }else {
    url = `be/api/institution?search=${search}&isKedutaan=${isKedutaan}&sort=univ_reg_name`
  }
  return get(`${BASE_URL_LOGIN}${url}`,
  {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    timeout: TIMEOUT
  }
  ).then(response => {
    const normalizeData = response.data.Data.map(res =>{
      return {
        name: res.univRegName,
        id: res.univRegId,
        alamat: res.univRegAddress,
        phoneNumber: res.univRegPhone,
        logoPath: res.univLogoPath
      }
    })
    return {
      data: normalizeData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const getDataLOVInstansi = (token, search, isKedutaan) => {
  let URL = '';
    URL = `be/api/lovInstitution?search=${search}&isKedutaan=${isKedutaan}`
  return get(`${BASE_URL_LOGIN}${URL}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.Data.map(res =>{
      return {
        name: res.univRegName,
        id: res.univRegId,
        alamat: res.univRegAddress,
        phoneNumber: res.univRegPhone,
        logoPath: res.univLogoPath
      }
    })
    return {
      data: normalizeData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const getLOVFAQCat = (token) => {
  return get(`${BASE_URL_LOGIN}be/api/faqCategory`,
  {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    timeout: TIMEOUT
  }
  ).then(response => {
    const normalizeData = response.data.Data.map(res =>{
      return {
        name: res.faqCategoryName,
        id: res.faqCategoryId
      }
    })
    return {
      data: normalizeData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const getLOVCountry = () => {
  return get(`${BASE_URL_NOAUTH}api/country`,
  {
    headers: {
      'Content-Type': 'application/json',
    },
    timeout: TIMEOUT
  }
  ).then(response => {
    const normalizeData = response.data.Data.map(res =>{
      return {
        name: res.univCountryName,
        id: res.univCountryId
      }
    })
    return {
      data: normalizeData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const getLOVProvince = (id) => {
  return get(`${BASE_URL_NOAUTH}api/province?countryId=${id}`,
  {
    headers: {
      'Content-Type': 'application/json',
    },
    timeout: TIMEOUT
  }
  ).then(response => {
    const normalizeData = response.data.Data.map(res =>{
      return {
        name: res.univProvName,
        id: res.univProvId
      }
    })
    return {
      data: normalizeData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const getLOVCity = (id) => {
  return get(`${BASE_URL_NOAUTH}api/city?provinceId=${id}`,
  {
    headers: {
      'Content-Type': 'application/json',
    },
    timeout: TIMEOUT
  }
  ).then(response => {
    const normalizeData = response.data.Data.map(res =>{
      return {
        name: res.univCityName,
        id: res.univCityId
      }
    })
    return {
      data: normalizeData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}
const getLOVLifeSharingCategory = (token) => {
  return get(`${BASE_URL_LOGIN}be/api/lifeSharingContentAdmin`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.Data
    return {
      data: normalizeData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const getLOVLifeSharingType = (token) => {
  return get(`${BASE_URL_LOGIN}be/api/sharingType`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.items.map( res => {
      return {
        id: res.sharingTypeId,
        sharingTypeId: res.sharingTypeId,
        sharingTypeName: res.sharingTypeName
      }
    });
    return {
      data: normalizeData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const getLOVLifeSharingSpeaker = (token, typeId) => {
  return get(`${BASE_URL_LOGIN}be/api/sharingSpeakerGallery?sharingType=${typeId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.items.map( res => {
      return {
        id: res.sharingEventId,
        sharingEventId: res.sharingEventId,
        sharingTypeName: res.sharingTypeName,
        sharingEventSpeaker: res.sharingEventSpeaker
      }
    });
    return {
      data: normalizeData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const getLOVLifeSharingDate = (token, param) => {
  return get(`${BASE_URL_LOGIN}be/api/sharingDateGallery?sharingType=${param.sharingTypeId}&speaker=${param.speaker}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.items.map( res => {
      return {
        id: res.sharingEventId,
        sharingTypeName: res.sharingTypeName,
        sharingEventSpeaker: res.sharingEventSpeaker,
        sharingEventDay: res.sharingEventDay,
        sharingEventDate: res.sharingEventDate
      }
    });
    return {
      data: normalizeData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const getLOVLifeSharingTime = (token, param) => {
  return get(`${BASE_URL_LOGIN}be/api/sharingTimeGallery?sharingType=${param.sharingTypeId}&speaker=${param.speaker}&eventDate=${param.eventDate}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.items.map( res => {
      return {
        id: res.sharingEventId,
        sharingEventId: res.sharingEventId,
        sharingEventDate: res.sharingEventDate,
        sharingEventDay: res.sharingEventDay,
        sharingEventSpeaker: res.sharingEventSpeaker,
        sharingEventTime: res.sharingEventTime,
        sharingTypeName: res.sharingTypeName
      }
    });
    return {
      data: normalizeData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const getLOVEventNameByUniv = (token, univId, search) => {
  return get(`${BASE_URL_LOGIN}be/api/eventAdminUniv?univId=${univId}&search=${search}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.Data.map( res => {
      return {
        id: res.univEventId,
        univEventId: res.univEventId,
        univEventName: res.univEventName,
        result: res.result,
        univEventDate: res.univEventDate,
        univEventDay: res.univEventDay,
        univEventDesc: res.univEventDesc,
        univEventTime: res.univEventTime,
        isOnline: res.isOnline
      }
    });
    return {
      data: normalizeData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const getLOVDateByEvent = (token, univEventId) => {
  return get(`${BASE_URL_LOGIN}be/api/eventAdminUniv?univEventId=${univEventId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.Data.map( res => {
      return {
        id: res.univEventId,
        univEventId: res.univEventId,
        univEventDay: res.univEventDay,
        univEventDate: res.univEventDate
      }
    });
    return {
      data: normalizeData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const getLOVUniversitasCategori = (token) => {
  return get(`${BASE_URL_LOGIN}be/api/univCat`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.Data.map( res => {
      return {
        ...res,
        id: res.univCatId
      }
    });
    return {
      data: normalizeData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

export default {
  getDataSchedule,
  getDataSession,
  getDataInstansi,
  getLOVFAQCat,
  getLOVCountry,
  getLOVProvince,
  getLOVCity,
  getLOVLifeSharingCategory,
  getDataScheduleGallery,
  getDataSessionGallery,
  getDataLOVInstansi,
  getLOVLifeSharingType,
  getLOVLifeSharingSpeaker,
  getLOVLifeSharingDate,
  getLOVLifeSharingTime,
  getLOVEventNameByUniv,
  getLOVDateByEvent,
  getLOVUniversitasCategori
}
