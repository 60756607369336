import React, { Component } from 'react'
import { connect } from 'react-redux'
import { post } from 'axios'
import config from '../../services/config'
import urlBidikti from '../../global/assets/BIDikTi.png'
import urlLoyola from '../../global/assets/logo.png'
import { Row, Col, Input, Button, notification, Form } from 'antd'
import {
  Link,
  withRouter
} from "react-router-dom";



// const { Title } = Typography
const { BASE_URL_NOAUTH } = config

class ForgetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initial: '',
      loading: false
    }
  }

  formRef = React.createRef();

  onFinish = values => {
    const body = {
      userName: values.username,
      userEmail : values.email,
      userPassword : values.password
    }
    this.postResetPassword(body);
  };

  onFinishFailed = errorInfo => {
    console.error('Failed:', errorInfo);
  };

  postResetPassword = body => {
    this.setState({ loading: true});
    post(`${BASE_URL_NOAUTH}api/admin/forgotPassword`, body)
    .then(res => {
      if (res.data.Status === "OK") {
        notification.success({
          placement: 'bottomRight',
          message: 'Success',
          description: res.data.Message ? res.data.Message : "Berhasil Mengganti Password"
        });
        this.formRef.current.resetFields();
      } else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: res.data.Message ? res.data.Message : 'Username atau Email salah!"!',
        });
      }
      this.setState({
        loading: false
      })
    })
    .catch(error => {
      notification.error({
        placement: 'bottomRight',
        message: 'Error',
        description: error.data
          ? error.data.Message
          : 'Username atau Email salah!',
      });
      this.setState({
        loading: false
      })
    })
  }

  handleRedirectDetail = () => {
    this.props.history.push(`/`)
   }

  render() {
    return(
      <div className="container_Body">
        <Row>
          <Col span={this.props.isMobile ? 14 : 16}>
            <div className="background_left">
              <Row justify='center'>
                <Col span={24} style={{marginBottom: '25px'}} >
                  <img height={this.props.isMobile ? 50 : 100} alt="loyolaLogo" width={this.props.isMobile ? 50 : 100} src={urlLoyola} style={{marginRight: '25px'}}/>
                  <img height={this.props.isMobile ? 50 : 100} alt="BIDikTiLogo" width={this.props.isMobile ? 50 : 100} src={urlBidikti} />
                </Col>
                <Col span={24} >
                  <span className="text_login_content">Admin Page <br></br> Bursa Informasi Pendidikan Tinggi <br></br> SMA Kolese Loyola</span>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={this.props.isMobile ? 10 : 8}>
            <div>
              <div className = "btnback" style={{backgroundColor: 'white'}}>
              <Link
                type="link"
                className="btnBack"
                onClick={() => this.handleRedirectDetail()}
              >
                <span className="arrow">&lt;</span> Login
              </Link>
              </div>
              <div className="background_right-forget">
                <Form
                  name="resetPassword"
                  ref={this.formRef}
                  onFinish={this.onFinish}
                  onFinishFailed={this.onFinishFailed}
                >
                  <Row justify='center'>
                    <Col span={16} style={{ textAlign: 'center'}}>
                      <span className="text_forget_password">Lupa Password</span>
                      <div className="divider_login"></div>
                    </Col>
                    <Col span={24} style={{ marginBottom: '15px' }}>
                      <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'Please input username!' }]}
                        style={{ marginBottom: 0}}
                      >
                        <Input placeholder="Username" className="input_style_login" />
                      </Form.Item>                
                    </Col>
                    <Col span={24} style={{ marginBottom: '15px' }}>
                      <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Please input email!' }]}
                        style={{ marginBottom: 0}}
                      >
                        <Input placeholder="Email" className="input_style_login" />
                      </Form.Item> 
                    </Col>
                    <Col span={24} style={{ marginBottom: '15px' }}>
                      <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please input password!' }]}
                        style={{ marginBottom: 0}}
                      >
                        <Input.Password placeholder="New Password" style={{ borderRadius: '5px' }}/>
                      </Form.Item> 
                    </Col>
                    <Col span={24} style={{ marginBottom: '15px' }}>
                      <Form.Item
                        name="confirm"
                        dependencies={['password']}
                        rules={[
                          { required: true, message: 'Please input confirm password!' },
                          ({ getFieldValue}) => ({
                            validator(rule, value) {
                              if ( !value || getFieldValue('password') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject('The two passwords that you entered do not match!');
                            }
                          })
                        ]}
                        style={{ marginBottom: 0}}
                      >
                        <Input.Password placeholder="Confirm New Password" style={{ borderRadius: '5px'}}/>
                      </Form.Item> 
                    </Col>
                    <Col span={24} style={{ textAlign: 'center'}}>
                      <Form.Item>
                        <Button className="updateButton" loading={this.state.loading} htmlType="submit">
                          Submit
                        </Button>
                      </Form.Item> 
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return{
    // handleDataHome: () => dispatch(getHome.getDataHome())
  }
}

const mapStateToProps = (state) => {
  return {
    // dataHome: state.home,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)((withRouter)(ForgetPassword))