import types from '../constants'
import gallery from "../api/gallery";

const getDataGallery = (token, sort, size, page, search) => dispatch => {
  dispatch({
    type: types.GET_GALLERY_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: [],
      totalData: 0
    }
  })
  return gallery.getGallery(token, sort, size, page, search).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.GET_GALLERY_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_GALLERY_SUCCESS,
        payload: result
      })
    }
  })
}

const getDataActive = (token) => dispatch => {
  dispatch({
    type: types.GET_ACTIVE_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: [],
      totalData: 0
    }
  })
  return gallery.getActiveAdmin(token).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.GET_ACTIVE_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_ACTIVE_SUCCESS,
        payload: result
      })
    }
  })
}

const getDataInstitusi = (token, search) => dispatch => {
  dispatch({
    type: types.GET_INSTITUSION_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: [],
      totalData: 0
    }
  })
  return gallery.getInstitutionAdmin(token, search).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.GET_INSTITUSION_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_INSTITUSION_SUCCESS,
        payload: result
      })
    }
  })
}

const inputGallery = (token, body) => dispatch => {
  dispatch({
    type: types.POST_GALLERY_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return gallery.postGallery(token, body).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.POST_GALLERY_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.POST_GALLERY_SUCCESS,
        payload: result
      })
    }
  })
}

const deleteGallery = (token, body) => dispatch => {
  dispatch({
    type: types.POST_GALLERY_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return gallery.putDeleteGallery(token, body).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.POST_GALLERY_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.POST_GALLERY_SUCCESS,
        payload: result
      })
    }
  })
}

export default {
  getDataGallery,
  getDataActive,
  getDataInstitusi,
  inputGallery,
  deleteGallery
}
