const GET_APPROVAL_LOADING = 'GET_APPROVAL_LOADING'
const GET_APPROVAL_SUCCESS = 'GET_APPROVAL_SUCCESS'
const GET_APPROVAL_FAILED = 'GET_APPROVAL_FAILED'
const GET_APPROVAL_DETAIL_LOADING = 'GET_APPROVAL_DETAIL_LOADING'
const GET_APPROVAL_DETAIL_SUCCESS = 'GET_APPROVAL_DETAIL_SUCCESS'
const GET_APPROVAL_DETAIL_FAILED = 'GET_APPROVAL_DETAIL_FAILED'
const UPDATE_APPROVAL_DETAIL_LOADING = 'UPDATE_APPROVAL_DETAIL_LOADING'
const UPDATE_APPROVAL_DETAIL_SUCCESS = 'UPDATE_APPROVAL_DETAIL_SUCCESS'
const UPDATE_APPROVAL_DETAIL_FAILED = 'UPDATE_APPROVAL_DETAIL_FAILED'
const PUT_APPROVAL_LOADING = 'PUT_APPROVAL_LOADING'
const PUT_APPROVAL_SUCCESS = 'PUT_APPROVAL_SUCCESS'
const PUT_APPROVAL_FAILED = 'PUT_APPROVAL_FAILED'
const REJECT_APPROVAL_LOADING = 'REJECT_APPROVAL_LOADING'
const REJECT_APPROVAL_SUCCESS = 'REJECT_APPROVAL_SUCCESS'
const REJECT_APPROVAL_FAILED = 'REJECT_APPROVAL_FAILED'
const POST_UNIV_LOADING = 'POST_UNIV_LOADING'
const POST_UNIV_SUCCESS = 'POST_UNIV_SUCCESS'
const POST_UNIV_FAILED = 'POST_UNIV_FAILED'

export {
  GET_APPROVAL_LOADING,
  GET_APPROVAL_SUCCESS,
  GET_APPROVAL_FAILED,
  GET_APPROVAL_DETAIL_LOADING,
  GET_APPROVAL_DETAIL_SUCCESS,
  GET_APPROVAL_DETAIL_FAILED,
  UPDATE_APPROVAL_DETAIL_LOADING,
  UPDATE_APPROVAL_DETAIL_SUCCESS,
  UPDATE_APPROVAL_DETAIL_FAILED,
  PUT_APPROVAL_LOADING,
  PUT_APPROVAL_SUCCESS,
  PUT_APPROVAL_FAILED,
  REJECT_APPROVAL_LOADING,
  REJECT_APPROVAL_SUCCESS,
  REJECT_APPROVAL_FAILED,
  POST_UNIV_LOADING,
  POST_UNIV_SUCCESS,
  POST_UNIV_FAILED,
}
