import types from '../constants';

const initialState = {
   status: '',
   data: []
}

export default function putMasterUang(state = initialState, action) {
   switch (action.type) {
      case types.PUT_UANG_LOADING:
         return {
            ...state,
            ...action.payload
         }
      case types.PUT_UANG_SUCCESS:
         return {
            isSuccess: true,
            ...state,
            ...action.payload
         }
      case types.PUT_UANG_FAILED:
         return {
            isSuccess: false,
            ...state,
            ...action.payload
         }
      default:
         return { ...initialState, ...state }
   }
}