import { get, put, post } from "axios";
import config from "../services/config";

const { BASE_URL_LOGIN, TIMEOUT } = config;

const getData = param => {
   return get(`${BASE_URL_LOGIN}be/api/currencyListAdmin?search=${param.search}&size=${param.size}&page=${param.page}&sort=${param.sort}`,
      {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${param.token}`,
         },
         timeout: TIMEOUT
      }
   ).then( response => {
      const normalizeData = response.data.Data.map( res => {
         return {
            id: res.currencyId,
            currencyName: res.currencyName,
            currencyId: res.currencyId
         }
      })
      return {
         data: normalizeData,
         totalData: response.data.totalData,
         status: "success",
         error: ""
      }
   }).catch( error => {
      return {
         data: [],
         totalData: 0,
         status: "error",
         error
      }
   })
}

const createUang = (token, body) => {
   return post(`${BASE_URL_LOGIN}be/api/submitCurrencyAdmin?userId=${body.userId}&currencyName=${body.currencyName}`,
      body,
      {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
         timeout: TIMEOUT
      }
   ).then(response => {
      return {
         data: response,
         status: "success",
         error: ''
      }
   }).catch(error => {
      return {
         data: [],
         status: "error",
         error
      }
   })
}

const editUang = (token, body, id) => {
   return put(`${BASE_URL_LOGIN}be/api/updateCurrencyAdmin?userId=${body.userId}&currencyName=${body.currencyName}&currencyId=${id}`,
      body,
      {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`

         },
         timeout: TIMEOUT
      }
   ).then(response => {
      return {
         data: response,
         status: "success",
         error: ""
      }
   }).catch(error => {
      return {
         data: [],
         status: "error",
         error
      }
   })
}

const deleteUang = (token, body, id) => {
   return put(`${BASE_URL_LOGIN}be/api/deleteCurrencyAdmin?userId=${body.userId}&currencyId=${id}`,
      body,
      {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
         },
         timeout: TIMEOUT
      }
   ).then(response => {
      return {
         data: response,
         status: "success",
         error: ""
      }
   }).catch(error => {
      return {
         data: [],
         status: "error",
         error
      }
   })
}

export default {
   getData,
   createUang,
   editUang,
   deleteUang
}