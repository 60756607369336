import { get, put, post } from 'axios'
import config from '../services/config'
const { BASE_URL_LOGIN, TIMEOUT } = config

const getListEventUniversity = (token, sort, size, page, search) => {
  return get(`${BASE_URL_LOGIN}be/api/eventUnivListAdmin?sort=${sort}&pageSize=${size}&pageNo=${page - 1}&search=${search}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.Data.map(res => {
      return {
        ...res,
      }
    })
    return {
      data: normalizeData,
      totalData: response.data.totalItems,
      status: 'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const getDetailEventUniversity = (token, id) => {
  return get(`${BASE_URL_LOGIN}be/api/eventDetailAdminUniv?univEventId=${id}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status: 'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const updateEventUniversity = (token, id, body) => {
  return put(`${BASE_URL_LOGIN}be/api/updateUnivEventAdmin?univEventId=${id}`,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status: 'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const approveEventUniversity = (token, id, data) => {
  return put(`${BASE_URL_LOGIN}be/api/eventUnivApproveAdmin?univEventId=${id}`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status: 'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const rejectEventUniversity = (token, id, data) => {
  return put(`${BASE_URL_LOGIN}be/api/eventUnivRejectAdmin?univEventId=${id}`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status: 'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const shareEventUniversity = (token, id) => {
  return post(`${BASE_URL_LOGIN}be/api/sendEmailUnivAdmin?univEventId=${id}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status: 'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const getEventParticipant = (token, id, group, sort, size, page, search) => {
  return get(`${BASE_URL_LOGIN}be/api/participantEventAdminUniv?univEventId=${id}&roleGroupId=${group}&sort=${sort}&search=${search}&page=${page - 1}&size=${size}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    timeout: TIMEOUT
  }
  ).then(response => {
    const normalizeData = response.data.Data.map(res => {
      return {
        ...res,
      }
    })
    return {
      data: normalizeData,
      totalData: response.data.TotalData,
      status: 'success',
      error: 'fail'
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

export default {
  getListEventUniversity,
  getDetailEventUniversity,
  updateEventUniversity,
  approveEventUniversity,
  rejectEventUniversity,
  shareEventUniversity,
  getEventParticipant
}
