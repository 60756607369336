const GET_HEADERFOOTER_LOADING = 'GET_HEADERFOOTER_LOADING'
const GET_HEADERFOOTER_SUCCESS = 'GET_HEADERFOOTER_SUCCESS'
const GET_HEADERFOOTER_FAILED = 'GET_HEADERFOOTER_FAILED'
const PUT_HEADERFOOTER_LOADING = 'PUT_HEADERFOOTER_LOADING'
const PUT_HEADERFOOTER_SUCCESS = 'PUT_HEADERFOOTER_SUCCESS'
const PUT_HEADERFOOTER_FAILED = 'PUT_HEADERFOOTER_FAILED'

export {
  GET_HEADERFOOTER_LOADING,
  GET_HEADERFOOTER_SUCCESS,
  GET_HEADERFOOTER_FAILED,
  PUT_HEADERFOOTER_LOADING,
  PUT_HEADERFOOTER_SUCCESS,
  PUT_HEADERFOOTER_FAILED,
}
