import { get, post, put } from 'axios'
import config from '../services/config'
const { BASE_URL_LOGIN, TIMEOUT } = config

const getManageBidiktiWeek = (token, sort, size, page, search) => {
  return get(`${BASE_URL_LOGIN}be/api/bwListAdmin?sort=${sort}&size=${size}&page=${page - 1}&search=${search}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.Data.map(res =>{
      return {
        bwDuration: res.bwDuration,
        bwUnivRegisDuration: res.bwUnivRegisDuration,
        bwThemeInd: res.bwThemeInd,
        bwId: res.bwId,
        id: res.bwId,
        status: res.Status,
      }
    })
    return {
      data: normalizeData,
      totalData: response.data.totalData,
      dataNaration: response.data.dataNaration,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      totalData: 0,
      error,
      data: []
    }
  })
}

const getManageBidiktiWeekDetail = (token, id) => {
  return get(`${BASE_URL_LOGIN}be/api/bwDetailAdmin?bwId=${id}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = {
      bwStatus : response.data.dataBw.Status,
      listDataBwSession: response.data.listDataBwSession,
      listDataBwImage: response.data.listDataBwImage,
      Message : response.data.Message,
      listDataBwSchedule: response.data.listDataBwSchedule,
      bwId: response.data.dataBw.bwId,
      bwThemeInd: response.data.dataBw.bwThemeInd,
      bwThemeEng: response.data.dataBw.bwThemeEng,
      bwDescInd: response.data.dataBw.bwDescInd,
      bwDescEng: response.data.dataBw.bwDescEng,
      bwNarationTitleInd: response.data.dataBw.bwNarationTitleInd,
      bwNarationTitleEng: response.data.dataBw.bwNarationTitleEng,
      bwNarationDescInd: response.data.dataBw.bwNarationDescInd,
      bwNarationDescEng: response.data.dataBw.bwNarationDescEng,
      bwUnivRegStart: response.data.dataBw.bwUnivRegStart,
      bwUnivRegFinish: response.data.dataBw.bwUnivRegFinish,
      bwDurationStart: response.data.dataBw.bwDurationStart,
      bwDurationFinish: response.data.dataBw.bwDurationFinish,
    }
    return {
      data: normalizeData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const getDataSessionBW = (token, sort, size, page, search, id) => {
  return get(`${BASE_URL_LOGIN}be/api/bwSessionDetailAdmin?sort=${sort}&size=${size}&page=${page - 1}&search=${search}&bwId=${id}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.listDataBwSession.map(res =>{
      return {
        status: res.Status,
        bwSessionTimeStartFinish: res.bwSessionTimeStartFinish,
        bwSessionName: res.bwSessionName,
        bwSessionId: res.bwSessionId,
        id: res.bwSessionId
      }
    })
    return {
      data: normalizeData,
      totalData: response.data.totalData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      totalData: 0,
      error,
      data: []
    }
  })
}

const getDataScheduleBW = (token, sort, size, page, search, id) => {
  return get(`${BASE_URL_LOGIN}be/api/bwScheduleDetailAdmin?sort=${sort}&size=${size}&page=${page - 1}&search=${search}&bwId=${id}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.Data.map(res =>{
      return {
        bwScheduleDate: res.bwScheduleDate,
        bwScheduleDay: res.bwScheduleDay,
        bwScheduleId: res.bwScheduleId,
        id: res.bwScheduleId
      }
    })
    return {
      data: normalizeData,
      totalData: response.data.totalData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      totalData: 0,
      error,
      data: []
    }
  })
}

const createBW = (token, body) => {
  return post(`${BASE_URL_LOGIN}be/api/createBidiktiWeek`, body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      totalData: 0,
      error,
      data: []
    }
  })
}

const createSchedule = (token, body) => {
  return post(`${BASE_URL_LOGIN}be/api/bwScheduleDetailAdmin/create`, body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      totalData: 0,
      error,
      data: []
    }
  })
}

const createSesi = (token, body) => {
  return post(`${BASE_URL_LOGIN}be/api/bwSessionDetailAdmin/create`, body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      totalData: 0,
      error,
      data: []
    }
  })
}

const updateBW = (token, body, id) => {
  return put(`${BASE_URL_LOGIN}be/api/bidiktiWeekAdmin?bwId=${id}`, body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      totalData: 0,
      error,
      data: []
    }
  })
}

const updateSesi = (token, body, id) => {
  return put(`${BASE_URL_LOGIN}be/api/bwSessionDetailAdmin/update/${id}`, body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      totalData: 0,
      error,
      data: []
    }
  })
}

const deleteSchedule = (token, body, id) => {
  return put(`${BASE_URL_LOGIN}be/api/bwScheduleDetailAdmin/delete/${id}`, body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      totalData: 0,
      error,
      data: []
    }
  })
}

const deleteSesi = (token, body, id) => {
  return put(`${BASE_URL_LOGIN}be/api/bwSessionDetailAdmin/delete/${id}`, body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      totalData: 0,
      error,
      data: []
    }
  })
}


export default {
  getManageBidiktiWeek,
  getManageBidiktiWeekDetail,
  getDataSessionBW,
  getDataScheduleBW,
  createBW,
  createSchedule,
  createSesi,
  updateBW,
  updateSesi,
  deleteSchedule,
  deleteSesi
}
