import React, {Component} from 'react'
import {connect} from 'react-redux'
import '../../../global/global.css'
import {UIContentDetailAdmin} from '../../../components'
import {Card, Breadcrumb} from 'antd'

import getAdmin from "../../../actions/manageAdmin";

class AdminDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initial: '',
      id: '',
    }
  }

  componentDidMount() {
    let id = this.props.location.pathname.split('/')[2]
    this.setState({
      id: id
    })
    this.props.handleDataDetail(this.props.token, id)
  }

  render() {
    const { dataDetail } = this.props
    // const {
    //   visiblePartisipan,
    //   loyolaActive,
    //   page,
    //   loadingPost
    // } = this.state

    return (
      <div className='root'>
        <Breadcrumb className="breadcrumb_style">
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/dashboard`)}>Kelola Admin </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/admin`)}>Admin </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className='pathNow_style'>
            Detail Admin
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card
          loading={dataDetail.status === 'loading'}
          className='bodyCard_style'>
          <UIContentDetailAdmin
            dataDetail={dataDetail.data ? dataDetail.data : []}
            isMobile={this.props.isMobile}
            isForm={false}
            // handleShare={() => this.handleShare()}
            // loadingPost={loadingPost}
          />
        </Card>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // handleDataHome: () => dispatch(getHome.getDataHome())
     handleDataDetail: (token, id) => dispatch(getAdmin.getDataDetailAdmin(token, id)),
    // handleDataParticipantListLoyola: (token, sort, size, page, search) => dispatch(getPresentation.getDataParticipantListLoyola(token, sort, size, page, search)),
    // handleDataParticipantListPublic: (token, sort, size, page, search) => dispatch(getPresentation.getDataParticipantListPublic(token, sort, size, page, search)),
    // handleSendEmail: (token, id) => dispatch(getPresentation.postSendEmail(token, id)),
  }
}

const mapStateToProps = (state) => {
  return {
    // dataHome: state.home,
    dataDetail: state.detailAdmin,
    // dataParticipantListLoyola: state.participantListLoyola,
    // dataParticipantListPublic: state.participantListPublic,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDetail)
