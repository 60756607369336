import types from "../constants";
import masterUang from "../api/masterUang";

const getData = param => dispatch => {
   dispatch({
      type: types.GET_UANG_LOADING,
      payload: {
         data: [],
         totalData: 0,
         status: "loading",
         error: ""
      }
   })
   return masterUang.getData(param).then( result => {
      if ( result.status === "error" ) {
         return dispatch({
            type: types.GET_UANG_FAILED,
            payload: result
         });
      } else {
         return dispatch({
            type: types.GET_UANG_SUCCESS,
            payload: result
         });
      }
   })
}

const createUang = (token, body) => dispatch => {
   dispatch({
      type: types.CREATE_UANG_LOADING,
      payload: {
         status: 'loading',
         error: '',
         data: []
      }
   })
   return masterUang.createUang(token, body).then(result => {
      if (result.status === "error") {
         return dispatch({
            type: types.CREATE_UANG_FAILED,
            payload: result
         })
      } else {
         return dispatch({
            type: types.CREATE_UANG_SUCCESS,
            payload: result
         })
      }
   })
}

const editUang = (token, body, id) => dispatch => {
   dispatch({
      type: types.PUT_UANG_LOADING,
      payload: {
         status: "loading",
         error: "",
         data: []
      }
   })
   return masterUang.editUang(token, body, id).then(result => {
      if (result.status === "error") {
         return dispatch({
            type: types.PUT_UANG_FAILED,
            payload: result
         });
      } else {
         return dispatch({
            type: types.PUT_UANG_SUCCESS,
            payload: result
         });
      }
   })
}

const deleteUang = (token, body, id) => dispatch => {
   dispatch({
      type: types.DELETE_UANG_LOADING,
      payload: {
         data: [],
         status: "loading",
         error: ""
      }
   })
   return masterUang.deleteUang(token, body, id).then(result => {
      if (result.status === "error") {
         return dispatch({
            type: types.DELETE_UANG_FAILED,
            payload: result
         });
      } else {
         return dispatch({
            type: types.DELETE_UANG_SUCCESS,
            payload: result
         });
      }
   })
}

export default {
   getData,
   createUang,
   editUang,
   deleteUang
}