import types from "../constants";
import dashboardBidiktiWeek from "../api/dashboardBidiktiWeek";

const getDashboardBidiktiWeek = (token) => dispatch => {
   dispatch({
      type: types.GET_DASHBOARD_BIDIKTI_WEEK_LOADING,
      payload: {
         data: [],
         totalData: 0,
         status: "loading",
         error: ""
      }
   })
   return dashboardBidiktiWeek.getDashboardBidiktiWeek(token).then(result => {
      if (result.status === "error") {
         return dispatch({
            type: types.GET_DASHBOARD_BIDIKTI_WEEK_FAILED,
            payload: result
         });
      } else {
         return dispatch({
            type: types.GET_DASHBOARD_BIDIKTI_WEEK_SUCCESS,
            payload: result
         });
      }
   })
}

export default {
   getDashboardBidiktiWeek
}