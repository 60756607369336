import types from '../constants'
import impian from "../api/impian";

const getData = (param) => dispatch => {
  dispatch({
    type: types.GET_IMPIAN_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: [],
      totalData: 0
    }
  })
  return impian.getData(param).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.GET_IMPIAN_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_IMPIAN_SUCCESS,
        payload: result
      })
    }
  })
}

const getDataDetail = (token, id) => dispatch => {
  dispatch({
    type: types.GET_IMPIAN_DETAIL_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: [],
      totalData: 0
    }
  })
  return impian.getDataDetail(token, id).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.GET_IMPIAN_DETAIL_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_IMPIAN_DETAIL_SUCCESS,
        payload: result
      })
    }
  })
}

const handleCreate = (token, body) => dispatch => {
  dispatch({
    type: types.CREATE_IMPIAN_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return impian.handleCreate(token, body).then( result => {
    if (result.status === 'error') {
      return{
        type: types.CREATE_IMPIAN_FAILED,
        payload: result
      }
    }else {
      return {
        type: types.CREATE_IMPIAN_SUCCESS,
        payload: result,
      }
    }
  })
}

const handleEdit = (token, id, body) => dispatch => {
  dispatch({
    type: types.PUT_IMPIAN_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return impian.handleEdit(token, id, body).then( result => {
    if (result.status === 'error') {
      return{
        type: types.PUT_IMPIAN_FAILED,
        payload: result
      }
    }else {
      return {
        type: types.PUT_IMPIAN_SUCCESS,
        payload: result,
      }
    }
  })
}

const handleShow = (token, id) => dispatch => {
  dispatch({
    type: types.PUT_IMPIAN_SHOW_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return impian.handleShow(token, id).then( result => {
    if (result.status === 'error') {
      return{
        type: types.PUT_IMPIAN_SHOW_FAILED,
        payload: result
      }
    }else {
      return {
        type: types.PUT_IMPIAN_SHOW_SUCCESS,
        payload: result,
      }
    }
  })
}

const handleHide = (token, id) => dispatch => {
  dispatch({
    type: types.PUT_IMPIAN_HIDE_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return impian.handleHide(token, id).then( result => {
    if (result.status === 'error') {
      return{
        type: types.PUT_IMPIAN_HIDE_FAILED,
        payload: result
      }
    }else {
      return {
        type: types.PUT_IMPIAN_HIDE_SUCCESS,
        payload: result,
      }
    }
  })
}


const handleDelete = (token, id) => dispatch => {
  dispatch({
    type: types.PUT_IMPIAN_DELETE_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return impian.handleDelete(token, id).then( result => {
    if (result.status === 'error') {
      return{
        type: types.PUT_IMPIAN_DELETE_FAILED,
        payload: result
      }
    }else {
      return {
        type: types.PUT_IMPIAN_DELETE_SUCCESS,
        payload: result,
      }
    }
  })
}

export default {
  getData,
  getDataDetail,
  handleCreate,
  handleEdit,
  handleShow,
  handleHide,
  handleDelete,
}