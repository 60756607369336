import types from '../constants'
import faq from "../api/faq.js";

const getData = (param) => dispatch => {
  dispatch({
    type: types.GET_FAQ_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: undefined,
      totalData: 0
    }
  })
  return faq.getData(param).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.GET_FAQ_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_FAQ_SUCCESS,
        payload: result
      })
    }
  })
}

const getDataDetail = (token, id) => dispatch => {
  dispatch({
    type: types.GET_FAQDETAIL_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: {
        faqAnswerEng: '',
        faqAnswerInd: '',
        faqCategoryName: '',
        faqQuestionEng: '',
        faqQuestionInd: '',
      },
    }
  })
  return faq.getDataDetail(token, id).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.GET_FAQDETAIL_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_FAQDETAIL_SUCCESS,
        payload: result
      })
    }
  })
}

const createFAQ = (token, userId, body) => dispatch => {
  dispatch({
    type: types.CREATE_FAQ_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return faq.createFAQ(token, userId, body).then( result => {
    if (result.status === 'error') {
      return{
        type: types.CREATE_FAQ_FAILED,
        payload: result
      }
    }else {
      return {
        type: types.CREATE_FAQ_SUCCESS,
        payload: result,
      }
    }
  })
}

const editFAQ = (token, userId, id, body) => dispatch => {
  dispatch({
    type: types.PUT_FAQ_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return faq.editFAQ(token, userId, id, body).then( result => {
    if (result.status === 'error') {
      return{
        type: types.PUT_FAQ_FAILED,
        payload: result
      }
    }else {
      return {
        type: types.PUT_FAQ_SUCCESS,
        payload: result,
      }
    }
  })
}

const deleteFAQ = (token, userId, id) => dispatch => {
  dispatch({
    type: types.DELETE_FAQ_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return faq.deleteFAQ(token, userId, id).then( result => {
    if (result.status === 'error') {
      return{
        type: types.DELETE_FAQ_FAILED,
        payload: result
      }
    }else {
      return {
        type: types.DELETE_FAQ_SUCCESS,
        payload: result,
      }
    }
  })
}

export default {
  getData,
  getDataDetail,
  editFAQ,
  createFAQ,
  deleteFAQ
}