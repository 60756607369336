const GET_DAFTAR_ACARA_LOADING = 'GET_DAFTAR_ACARA_LOADING'
const GET_DAFTAR_ACARA_SUCCESS = 'GET_DAFTAR_ACARA_SUCCESS'
const GET_DAFTAR_ACARA_FAILED = 'GET_DAFTAR_ACARA_FAILED'
const GET_DETAIL_DAFTAR_ACARA_LOADING = 'GET_DETAIL_DAFTAR_ACARA_LOADING'
const GET_DETAIL_DAFTAR_ACARA_SUCCESS = 'GET_DETAIL_DAFTAR_ACARA_SUCCESS'
const GET_DETAIL_DAFTAR_ACARA_FAILED = 'GET_DETAIL_DAFTAR_ACARA_FAILED'
const UPDATE_DAFTAR_ACARA_LOADING = 'UPDATE_DAFTAR_ACARA_LOADING'
const UPDATE_DAFTAR_ACARA_SUCCESS = 'UPDATE_DAFTAR_ACARA_SUCCESS'
const UPDATE_DAFTAR_ACARA_FAILED = 'UPDATE_DAFTAR_ACARA_FAILED'
const APPROVE_DAFTAR_ACARA_LOADING = 'APPROVE_DAFTAR_ACARA_LOADING'
const APPROVE_DAFTAR_ACARA_SUCCESS = 'APPROVE_DAFTAR_ACARA_SUCCESS'
const APPROVE_DAFTAR_ACARA_FAILED = 'APPROVE_DAFTAR_ACARA_FAILED'
const REJECT_DAFTAR_ACARA_LOADING = 'REJECT_DAFTAR_ACARA_LOADING'
const REJECT_DAFTAR_ACARA_SUCCESS = 'REJECT_DAFTAR_ACARA_SUCCESS'
const REJECT_DAFTAR_ACARA_FAILED = 'REJECT_DAFTAR_ACARA_FAILED'
const SHARE_DAFTAR_ACARA_LOADING = 'SHARE_DAFTAR_ACARA_LOADING'
const SHARE_DAFTAR_ACARA_SUCCESS = 'SHARE_DAFTAR_ACARA_SUCCESS'
const SHARE_DAFTAR_ACARA_FAILED = 'SHARE_DAFTAR_ACARA_FAILED'
const GET_DAFTAR_ACARA_DETAIL_LOADING = 'GET_DAFTAR_ACARA_DETAIL_LOADING'
const GET_DAFTAR_ACARA_DETAIL_SUCCESS = 'GET_DAFTAR_ACARA_DETAIL_SUCCESS'
const GET_DAFTAR_ACARA_DETAIL_FAILED = 'GET_DAFTAR_ACARA_DETAIL_FAILED'
const GET_ACARA_PARTICIPANT_LIST_LOADING = 'GET_ACARA_PARTICIPANT_LIST_LOADING'
const GET_ACARA_PARTICIPANT_LIST_SUCCESS = 'GET_ACARA_PARTICIPANT_LIST_SUCCESS'
const GET_ACARA_PARTICIPANT_LIST_FAILED = 'GET_ACARA_PARTICIPANT_LIST_FAILED'
const POST_DAFTAR_ACARA_LOADING = 'POST_DAFTAR_ACARA_LOADING'
const POST_DAFTAR_ACARA_SUCCESS = 'POST_DAFTAR_ACARA_SUCCESS'
const POST_DAFTAR_ACARA_FAILED = 'POST_DAFTAR_ACARA_FAILED'
const GET_PARTICIPANT_DAFTAR_ACARA_LOADING = 'GET_PARTICIPANT_DAFTAR_ACARA_LOADING'
const GET_PARTICIPANT_DAFTAR_ACARA_SUCCESS = 'GET_PARTICIPANT_DAFTAR_ACARA_SUCCESS'
const GET_PARTICIPANT_DAFTAR_ACARA_FAILED = 'GET_PARTICIPANT_DAFTAR_ACARA_FAILED'

export {
  GET_DAFTAR_ACARA_LOADING,
  GET_DAFTAR_ACARA_SUCCESS,
  GET_DAFTAR_ACARA_FAILED,
  GET_DETAIL_DAFTAR_ACARA_LOADING,
  GET_DETAIL_DAFTAR_ACARA_SUCCESS,
  GET_DETAIL_DAFTAR_ACARA_FAILED,
  UPDATE_DAFTAR_ACARA_LOADING,
  UPDATE_DAFTAR_ACARA_SUCCESS,
  UPDATE_DAFTAR_ACARA_FAILED,
  APPROVE_DAFTAR_ACARA_LOADING,
  APPROVE_DAFTAR_ACARA_SUCCESS,
  APPROVE_DAFTAR_ACARA_FAILED,
  REJECT_DAFTAR_ACARA_LOADING,
  REJECT_DAFTAR_ACARA_SUCCESS,
  REJECT_DAFTAR_ACARA_FAILED,
  SHARE_DAFTAR_ACARA_LOADING,
  SHARE_DAFTAR_ACARA_SUCCESS,
  SHARE_DAFTAR_ACARA_FAILED,
  POST_DAFTAR_ACARA_LOADING,
  POST_DAFTAR_ACARA_SUCCESS,
  POST_DAFTAR_ACARA_FAILED,
  GET_DAFTAR_ACARA_DETAIL_LOADING,
  GET_DAFTAR_ACARA_DETAIL_SUCCESS,
  GET_DAFTAR_ACARA_DETAIL_FAILED,
  GET_ACARA_PARTICIPANT_LIST_LOADING,
  GET_ACARA_PARTICIPANT_LIST_SUCCESS,
  GET_ACARA_PARTICIPANT_LIST_FAILED,
  GET_PARTICIPANT_DAFTAR_ACARA_LOADING,
  GET_PARTICIPANT_DAFTAR_ACARA_SUCCESS,
  GET_PARTICIPANT_DAFTAR_ACARA_FAILED
}
