import types from '../../constants'

const initialState = {
  status: 'loading',
  data: []
}

export default function updateEventUniversity(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_DAFTAR_ACARA_LOADING:
      return {
        ...state,
        ...action.payload
      }
    case types.UPDATE_DAFTAR_ACARA_SUCCESS:
      return {
        isSuccess: true,
        ...state,
        ...action.payload
      }
    case types.UPDATE_DAFTAR_ACARA_FAILED:
      return {
        isSuccess: false,
        ...state,
        ...action.payload
      }
    default:
      return { ...initialState, ...state }
  }
}
