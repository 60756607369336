import React, { Component } from "react";
import "../../global/global.css";
import { 
   Breadcrumb, Card, Row, Col, Button,
   Modal, Typography, notification
} from "antd";
import { connect } from "react-redux";
import daftarGaleri from "../../actions/daftarGaleri";
import { CaretRightOutlined } from '@ant-design/icons';
import {GlobalVideoCheck} from '../../global/checkingVideo'

import ImagePreviewComponent from '../../components/imagePreview'
const { Title } = Typography;
class DaftarGaleriDetail extends Component {
   constructor(props) {
      super(props);  
      this.state = {
         id: "",
         visibleVideo: false,
         urlVideo: "",
         visibleThumbnail: false,
         isActive: null,
         univGalleryId: null
      }
   }

   componentDidMount() {
      const id = this.props.location.pathname.split('/')[2];
      this.setState({ id }, () => {
         this.props.handleData(this.props.token, this.state.id);
      });
   }

   handleOpenVideo = (urlVideo) => {
      this.setState({
         urlVideo,
         visibleVideo: true
      });
   }

   handleCloseVideo = () => {
      this.setState({ visibleVideo: false, urlVideo: '' });
   }

   handleOpenThumbnail = () => {
      this.setState({
         visibleThumbnail: true
      });
   }

   handleCloseThumbnail = () => {
      this.setState({ 
         visibleThumbnail: false,
         isActive: null,
         univGalleryId: null
      });
   }

   handleClickThumbnail = (index, univGalleryId) => {
      this.setState({ 
         isActive: index,
         univGalleryId
      });
   }

   handlePutThumbnail = (token, param) => {
      this.props.handlePutThumbnail(token, param).then( res => {
         if ( 
               res.payload.data &&
               res.payload.data.data &&
               res.payload.data.data.Status ?  
               res.payload.data.data.Status === "OK" : false
            ) {
            notification.success({
               placement: 'bottomRight',
               message: 'Success',
               description: res.payload.data.data.Message ? res.payload.data.data.Message : "Berhasil mengubah thumbnail.",
            });
            this.handleCloseThumbnail();
            this.props.handleData(this.props.token, this.state.id);
         } else {
            notification.error({
               placement: 'bottomRight',
               message: 'Error',
               description: "gagal mengubah thumbnail.",
            });
         }
      })
   }

   handleSubmitThumbnail = () => {
      if ( this.state.univGalleryId ) {
         const param = {
            userId: this.props.userId,
            univGalleryId: this.state.univGalleryId
         }
         this.handlePutThumbnail(this.props.token, param);
      } else {
         notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: "pilih gambar terlebih dahulu.",
         });
      }
   }

   render() {
      const { visibleVideo, urlVideo, visibleThumbnail, isActive } = this.state;
      const { dataDetailDaftarGaleri, dataPutThumbnailDaftaGaleri, isMobile } = this.props;
      return (
         <div className="root">
            <Breadcrumb className="breadcrumb_style">
               <Breadcrumb.Item>
                  <span style={{ cursor: 'pointer' }}>Universitas</span>
               </Breadcrumb.Item>
               <Breadcrumb.Item>
                  <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/daftar-galeri`)}>
                     Daftar Galeri
                  </span>
               </Breadcrumb.Item>
               <Breadcrumb.Item className='pathNow_style'>
                  Detail Daftar Galeri
               </Breadcrumb.Item>
            </Breadcrumb>
            <Card 
               loading={dataDetailDaftarGaleri.status === "loading"} 
               className="bodyCard_style"
            >
               <Row gutter={[24, 10]}>
                  <Col span={isMobile ? 12 : 4} style={{textAlign: "center"}}>
                     <ImagePreviewComponent
                        isMobile={isMobile}
                        styles={{ width: isMobile ? '100%' : "90%" }}
                        uri={dataDetailDaftarGaleri.data.univLogoPath}
                     />
                     {/* <img 
                        src={dataDetailDaftarGaleri.data.univLogoPath} 
                        alt="Logo"
                        width="90%" 
                     /> */}
                  </Col>
                  <Col span={isMobile ? 24 : 20}>
                     <Row gutter={[10,10]}>
                        <Col span={12}>
                           <Row gutter={[10, 10]}>
                              <Col span={24}>
                                 <span className="header_text_detail">Nama Institusi</span>
                              </Col>
                              <Col span={24}>
                                 <span className="text_detail_institusi">
                                    {dataDetailDaftarGaleri.data.univName}
                                 </span>
                              </Col>
                              <Col span={24}>
                                 <span className="header_text_detail">Nama Acara</span>
                              </Col>
                              <Col span={24}>
                                 <span className="data_text_detail">
                                    {dataDetailDaftarGaleri.data.univEventName}
                                 </span>
                              </Col>
                              <Col span={24}>
                                 <span className="data_text_detail">Thumbnail</span>
                              </Col>
                              <Col span={24}>
                                 <Row gutter={[16, 10]} align="middle">
                                    <Col span={isMobile ? 24 :12}>
                                    <ImagePreviewComponent
                                       isMobile={isMobile}
                                       styles={{ width: "100%" }}
                                       imageBorder={'6px'}
                                       uri={dataDetailDaftarGaleri.data.univThumbnail}
                                    />
                                       {/* <img 
                                          src={
                                             dataDetailDaftarGaleri.data.univThumbnail ? dataDetailDaftarGaleri.data.univThumbnail+'?'+new Date().getTime() : 
                                             emptyData
                                          } 
                                          alt="thumbnail"
                                          width="100%"
                                          style={{borderRadius: "6px"}}
                                       /> */}
                                    </Col>
                                    <Col span={isMobile ? 24 :12}>
                                       <Button 
                                          className="donwloadButton"
                                          onClick={() => this.handleOpenThumbnail()}
                                       >
                                          Pilih
                                       </Button>
                                    </Col>
                                 </Row>
                              </Col>
                           </Row>
                        </Col>
                        <Col span={12}>
                           <Row gutter={[10, 10]}>
                              <Col span={24}>
                                 <span className="header_text_detail">Deskripsi Acara</span>
                              </Col>
                              <Col span={24}>
                                 <pre className='text_detail_deskripsi_acara' style={{whiteSpace: "pre-wrap"}}>
                                    {dataDetailDaftarGaleri.data.univEventDesc ? dataDetailDaftarGaleri.data.univEventDesc.replace('\\n', '\r\n') : '-'}
                                 </pre>
                              </Col>
                           </Row>
                        </Col>
                     </Row>
                     <Row gutter={[0, 10]}>
                        <Col span={24}>
                           <span className="data_text_detail">Gambar</span>
                           <hr/>
                        </Col>
                        <Col span={24} style={{textAlign: "center"}}>
                           <span className="header_text_detail" style={{color: "#6BC733"}}>
                              Approved
                           </span>
                        </Col>
                        <Col span={24}>
                           <Row gutter={[24, 24]}>
                              { dataDetailDaftarGaleri.data.listUnivGalleryImgPathApproved &&
                                 dataDetailDaftarGaleri.data.listUnivGalleryImgPathApproved.length > 0 ?
                                 dataDetailDaftarGaleri.data.listUnivGalleryImgPathApproved.map( (res, index) => (
                                 <Col span={isMobile ? 12 : 6} key={index}>
                                    <ImagePreviewComponent
                                       isMobile={isMobile}
                                       styles={{ width: "100%" }}
                                       imageBorder={'6px'}
                                       uri={res.univGalleryImgPath}
                                    />
                                    {/* <img 
                                       alt="Gambar approved"
                                       src={res.univGalleryImgPath+'?'+new Date().getTime()}
                                       width="100%"
                                       style={{borderRadius: "6px"}}
                                    /> */}
                                 </Col>
                              )) :
                              (
                                 <Col span={24} style={{textAlign: "center"}}>
                                    <span className="header_text_detail">Data Kosong</span>
                                 </Col>
                              )}
                           </Row>
                        </Col>
                        <Col span={24} style={{textAlign: "center"}}>
                           <span className="header_text_detail" style={{color: "#21296C"}}>
                              On Approval
                           </span>
                        </Col>
                        <Col span={24}>
                           <Row gutter={[24, 24]}>
                              { dataDetailDaftarGaleri.data.listUnivGalleryImgPathOnApproval &&
                                 dataDetailDaftarGaleri.data.listUnivGalleryImgPathOnApproval.length > 0 ?
                                 dataDetailDaftarGaleri.data.listUnivGalleryImgPathOnApproval.map( (res, index) => (
                                 <Col span={isMobile ? 12 : 6} key={index}>
                                    <ImagePreviewComponent
                                       isMobile={isMobile}
                                       styles={{ width: "100%" }}
                                       imageBorder={'6px'}
                                       uri={res.univGalleryImgPath}
                                    />
                                    {/* <img 
                                       alt="Gambar approved"
                                       src={res.univGalleryImgPath+'?'+new Date().getTime()}
                                       width="100%"
                                       style={{borderRadius: "6px"}}
                                    /> */}
                                 </Col>
                              )) :
                              (
                                 <Col span={24} style={{textAlign: "center"}}>
                                    <span className="header_text_detail" >Data Kosong</span>
                                 </Col>
                              ) }
                           </Row>
                        </Col>
                        <Col span={24} style={{textAlign: "center"}}>
                           <span className="header_text_detail" style={{color: "#E22828"}}>
                              Rejected
                           </span>
                        </Col>
                        <Col span={24}>
                           <Row gutter={[24,24]}>
                              { dataDetailDaftarGaleri.data.listUnivGalleryImgPathRejected &&
                                 dataDetailDaftarGaleri.data.listUnivGalleryImgPathRejected.length > 0 ?
                                 dataDetailDaftarGaleri.data.listUnivGalleryImgPathRejected.map( (res, index) => (
                                 <Col span={isMobile ? 12 : 6} key={index}>
                                    <ImagePreviewComponent
                                       isMobile={isMobile}
                                       styles={{ width: "100%" }}
                                       imageBorder={'6px'}
                                       uri={res.univGalleryImgPath}
                                    />
                                    {/* <img 
                                       alt="Gambar approved"
                                       src={res.univGalleryImgPath+'?'+new Date().getTime()}
                                       width="100%"
                                       style={{borderRadius: "6px"}}
                                    /> */}
                                 </Col>
                              )) :
                              (
                                 <Col span={24} style={{textAlign: "center"}}>
                                    <span className="header_text_detail" >Data Kosong</span>
                                 </Col>
                              ) }
                           </Row>
                        </Col>
                     </Row>
                     <Row gutter={[0, 10]}>
                        <Col span={24}>
                           <span className="data_text_detail">Video</span>
                           <hr/>
                        </Col>
                        <Col span={24} style={{textAlign: "center"}}>
                           <span className="header_text_detail" style={{color: "#6BC733"}}>
                              Approved
                           </span>
                        </Col>
                        <Col span={24}>
                           <Row gutter={[24,24]}>
                              { dataDetailDaftarGaleri.data.listUnivGalleryVideoUrlApproved &&
                                 dataDetailDaftarGaleri.data.listUnivGalleryVideoUrlApproved.length > 0 ?
                                 dataDetailDaftarGaleri.data.listUnivGalleryVideoUrlApproved.map( (res, index) => (
                                 <Col span={ isMobile ? 12 : 6} key={index}>
                                    <div className="gradientCard">
                                       <CaretRightOutlined 
                                          className="playButton" 
                                          style={{cursor: "pointer"}}
                                          onClick={() => this.handleOpenVideo(res.univGalleryVideoUrl)} 
                                       />
                                       <ImagePreviewComponent
                                          isMobile={isMobile}
                                          styles={{ width: "100%" }}
                                          imageBorder={'6px'}
                                          uri={res.univGalleryImageDefault}
                                       />
                                       {/* <img 
                                          alt="Gambar approved"
                                          src={res.univGalleryImageDefault+'?'+new Date().getTime()}
                                          width="100%"
                                          style={{borderRadius: "6px"}}
                                       /> */}
                                    </div>
                                 </Col>
                              )) :
                              (
                                 <Col span={24} style={{textAlign: "center"}}>
                                    <span className="header_text_detail" >Data Kosong</span>
                                 </Col>
                              ) }
                           </Row>
                        </Col>
                        <Col span={24} style={{textAlign: "center"}}>
                           <span className="header_text_detail" style={{color: "#21296C"}}>
                              On Approval
                           </span>
                        </Col>
                        <Col span={24}>
                           <Row gutter={[24,24]}>
                              { dataDetailDaftarGaleri.data.listUnivGalleryVideoUrlOnApproval &&
                                 dataDetailDaftarGaleri.data.listUnivGalleryVideoUrlOnApproval.length > 0 ?
                                 dataDetailDaftarGaleri.data.listUnivGalleryVideoUrlOnApproval.map( (res, index) => (
                                 <Col span={isMobile ? 12 : 6} key={index}>
                                    <div className="gradientCard">
                                       <CaretRightOutlined 
                                          className="playButton"
                                          style={{cursor: "pointer"}}
                                          onClick={() => this.handleOpenVideo(res.univGalleryVideoUrl)} 
                                       />
                                       <ImagePreviewComponent
                                          isMobile={isMobile}
                                          styles={{ width: "100%" }}
                                          imageBorder={'6px'}
                                          uri={res.univGalleryImageDefault}
                                       />
                                       {/* <img 
                                          alt="Gambar approved"
                                          src={res.univGalleryImageDefault+'?'+new Date().getTime()}
                                          width="100%"
                                          style={{borderRadius: "6px"}}
                                       /> */}
                                    </div>
                                 </Col>
                              )) :
                              (
                                 <Col span={24} style={{textAlign: "center"}}>
                                    <span className="header_text_detail" >Data Kosong</span>
                                 </Col>
                              ) }
                           </Row>
                        </Col>
                        <Col span={24} style={{textAlign: "center"}}>
                           <span className="header_text_detail" style={{color: "#E22828"}}>
                              Rejected
                           </span>
                        </Col>
                        <Col span={24}>
                           <Row gutter={[24,24]}>
                              { dataDetailDaftarGaleri.data.listUnivGalleryVideoUrlRejected &&
                                 dataDetailDaftarGaleri.data.listUnivGalleryVideoUrlRejected.length > 0 ?
                                 dataDetailDaftarGaleri.data.listUnivGalleryVideoUrlRejected.map( (res, index) => (
                                 <Col span={isMobile ? 12 : 6} key={index}>
                                    <div className="gradientCard">
                                       <CaretRightOutlined 
                                          className="playButton" 
                                          style={{cursor: "pointer"}}
                                          onClick={() => this.handleOpenVideo(res.univGalleryVideoUrl)} 
                                       />
                                       <ImagePreviewComponent
                                          isMobile={isMobile}
                                          styles={{ width: "100%" }}
                                          imageBorder={'6px'}
                                          uri={res.univGalleryImageDefault}
                                       />
                                       {/* <img 
                                          alt="Gambar approved"
                                          src={res.univGalleryImageDefault+'?'+new Date().getTime()}
                                          width="100%"
                                          style={{borderRadius: "6px"}}
                                       /> */}
                                    </div>
                                 </Col>
                              )) :
                              (
                                 <Col span={24} style={{textAlign: "center"}}>
                                    <span className="header_text_detail" >Data Kosong</span>
                                 </Col>
                              ) }
                           </Row>
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </Card>
            <Modal
              visible={visibleVideo}
              centered
              className={this.props.isMobile ? 'cardVideoMobile' : 'cardVideo'}
              footer={null}
              onCancel={() => this.handleCloseVideo()}
              destroyOnClose
              width={this.props.isMobile ? '100%' : '75%'}
            >
              {GlobalVideoCheck.checking(urlVideo) ? 
                (
                  <iframe
                    style={{ width: '-webkit-fill-available', height: isMobile ? '280px' : '500px' }}
                    id="modalVideo"
                    title='modalVideo'
                    src={urlVideo}
                    frameBorder="0"
                    allow="accelerometer;
                    autoplay;
                    encrypted-media;
                    gyroscope;
                    picture-in-picture"
                    allowFullScreen="allowfullscreen"
                  />
                ) : 
                (
                  <span className='data_text_detail'>
                    Url Video Tidak Valid/Error
                  </span>
                )
              }
            </Modal>
            <Modal
               visible={visibleThumbnail}
               centered
               className={this.props.isMobile ? 'cardVideoMobile' : 'cardVideo'}
               footer={null}
               onCancel={() => this.handleCloseThumbnail()}
               destroyOnClose
               width={this.props.isMobile ? '100%' : '50%'}
            >
               <Row>
                  <Col span={24}>
                     <div className='headerForm'>
                        <Title className='titleModalGalery' level={4}>Set Thumbnail</Title>
                     </div>
                     <div style={{ width: "200px"}} className='dividerGalery'/>
                  </Col>
               </Row>
               <Row gutter={[0, 10]}>
                  <Col span={24}>
                     <span className="data_text_detail">Gambar Yang Sudah di Approve</span>
                  </Col>
                  <Col span={24}>
                     <hr />
                  </Col>
                  <Col span={24}>
                     <Row gutter={[24, 24]}>
                        { dataDetailDaftarGaleri.data.listUnivGalleryImgPathApproved &&
                           dataDetailDaftarGaleri.data.listUnivGalleryImgPathApproved.length > 0 ?
                           dataDetailDaftarGaleri.data.listUnivGalleryImgPathApproved.map( (res, index) => (
                           <Col span={isMobile ? 12 : 6} key={index}>
                              {/* <ImagePreviewComponent
                                 isMobile={isMobile}
                                 styles={{ width: "100%" }}
                                 imageBorder={'6px'}
                                 uri={res.univGalleryImgPath}
                                 className={isActive === index ? "thumbnailActive" : ""}
                              /> */}
                              <img 
                                 alt="Gambar approved"
                                 src={res.univGalleryImgPath}
                                 width="100%"
                                 onClick={() => this.handleClickThumbnail(index, res.univGalleryId)}
                                 style={{borderRadius: "6px"}}
                                 className={isActive === index ? "thumbnailActive" : ""}
                              />
                           </Col>
                        )) :
                        (
                           <Col span={24} style={{textAlign: "center"}}>
                              <span className="header_text_detail">Data Kosong</span>
                           </Col>
                        )}
                     </Row>
                  </Col>
                  <Col span={24} style={{textAlign:"right"}}>
                     <Button 
                        className="updateButton"
                        onClick={() => this.handleSubmitThumbnail()}
                        loading={dataPutThumbnailDaftaGaleri.status === "loading"}
                     >
                        Submit
                     </Button>
                  </Col>
               </Row>
            </Modal>
         </div>
      )
   }
}

const mapDispatchToProps = dispatch => (
   {
      handleData: (token, univEventId) => dispatch(daftarGaleri.getDetail(token, univEventId)),
      handlePutThumbnail: (token, param) => dispatch(daftarGaleri.putThumbnailDaftarGaleri(token, param))
   }
)

const mapStateToProps = state => (
   {
      dataDetailDaftarGaleri: state.detailDaftarGaleri,
      dataPutThumbnailDaftaGaleri: state.putThumbnailDaftarGaleri
   }
)


export default connect(mapStateToProps, mapDispatchToProps)(DaftarGaleriDetail);