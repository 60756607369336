const GET_LIST_SUBSCRIBER_LOADING = 'GET_LIST_SUBSCRIBER_LOADING'
const GET_LIST_SUBSCRIBER_SUCCESS = 'GET_LIST_SUBSCRIBER_SUCCESS'
const GET_LIST_SUBSCRIBER_FAILED = 'GET_LIST_SUBSCRIBER_FAILED'
const UPLOAD_BANNER_LOADING = 'UPLOAD_BANNER_LOADING'
const UPLOAD_BANNER_SUCCESS = 'UPLOAD_BANNER_SUCCESS'
const UPLOAD_BANNER_FAILED = 'UPLOAD_BANNER_FAILED'
const SEND_MAIL_NEWS_LOADING = 'SEND_MAIL_NEWS_LOADING'
const SEND_MAIL_NEWS_SUCCESS = 'SEND_MAIL_NEWS_SUCCESS'
const SEND_MAIL_NEWS_FAILED = 'SEND_MAIL_NEWS_FAILED'

export {
  GET_LIST_SUBSCRIBER_LOADING,
  GET_LIST_SUBSCRIBER_SUCCESS,
  GET_LIST_SUBSCRIBER_FAILED,
  UPLOAD_BANNER_LOADING,
  UPLOAD_BANNER_SUCCESS,
  UPLOAD_BANNER_FAILED,
  SEND_MAIL_NEWS_LOADING,
  SEND_MAIL_NEWS_SUCCESS,
  SEND_MAIL_NEWS_FAILED
}
