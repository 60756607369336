const GET_HOMEPAGE_LOADING = 'GET_HOMEPAGE_LOADING'
const GET_HOMEPAGE_SUCCESS = 'GET_HOMEPAGE_SUCCESS'
const GET_HOMEPAGE_FAILED = 'GET_HOMEPAGE_FAILED'
const PUT_HOMEPAGE_LOADING = 'PUT_HOMEPAGE_LOADING'
const PUT_HOMEPAGE_SUCCESS = 'PUT_HOMEPAGE_SUCCESS'
const PUT_HOMEPAGE_FAILED = 'PUT_HOMEPAGE_FAILED'

export {
  GET_HOMEPAGE_LOADING,
  GET_HOMEPAGE_SUCCESS,
  GET_HOMEPAGE_FAILED,
  PUT_HOMEPAGE_LOADING,
  PUT_HOMEPAGE_SUCCESS,
  PUT_HOMEPAGE_FAILED,
}
