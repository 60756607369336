import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../global/global.css'
import faqAction from '../../actions/faq'
import lov from '../../actions/lov'
import {
  Card,
  notification,
  Button,
  Row,
  Col,
  Breadcrumb,
  Typography,
  Popconfirm
} from 'antd'
import { UItable, UISearch, UIModalCreate } from '../../components'

const { Text } = Typography

class ListFAQ extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initial: '',
      orderBy: '',
      size: 10,
      page: 1,
      search: '',
      visibleCreate: false,
      loading: false,
      initalData: {
        category: undefined,
        pertanyaanInd: undefined,
        pertanyaanEng: undefined,
        jawabanInd: undefined,
        jawabanEng: undefined,
      },
      idFaq: undefined,
      isEdit: false
    }
  }

  componentDidMount() {
    const {orderBy, size, page, search} = this.state
    const body = {
      token: this.props.token,
      sort: orderBy,
      size: size,
      page: page,
      search: search,
    }
    this.props.handleData(body)
    this.props.handleLOV(this.props.token)
  }

  handleSearch = key => {
    const {orderBy, size, page } = this.state
    this.setState({
      search: key,
      page: 1
    })
    const body = {
      token: this.props.token,
      sort: orderBy,
      size: size,
      page: page,
      search: key,
    }
    this.props.handleData(body)
  }

  openModal = () => {
    this.setState({
      visibleCreate: true,
      isEdit: false,
      initalData: {
        category: undefined,
        pertanyaanInd: undefined,
        pertanyaanEng: undefined,
        jawabanInd: undefined,
        jawabanEng: undefined,
      },
    })
  }

  handleTableChange = (page, filter, sorter) => {
    const {size, search } = this.state

    let sorterField = ""
    if (sorter.field === "faqCategoryName") {
      sorterField = "fc.faq_category_name";
    } else {
      sorterField = "faq_question_ind";
    }

    let sort = "";
    if (sorter.order === "ascend") {
      sort = `${sorterField},asc`;
    } else if (sorter.order === "descend") {
      sort = `${sorterField},desc`;
    }
    const body = {
      token: this.props.token,
      sort: sort,
      size: size,
      page: page.current,
      search: search,
    }
    this.setState({
      page: page.current,
      orderBy: sort
    }, () => {
      this.props.handleData(body)
    })
  }

  handleCloseModal = () => {
    this.setState({
      visibleCreate: false,
      isEdit: false
    })
  }

  handleFinish = values => {
    this.setState({
      loadingSubmit: true
    })

    const bodySend = {
      faqCategoryId: {
        faqCategoryId: values.category
      },
      faqQuestionInd: values.pertanyaanInd,
      faqQuestionEng: values.pertanyaanEng,
      faqAnswerInd: values.jawabanInd,
      faqAnswerEng: values.jawabanEng
    }

    const body = {
      token: this.props.token,
      sort: '',
      size: 10,
      page: 1,
      search: '',
    }

    if (this.state.isEdit) {
      this.handleSubmitEdit(body, bodySend)
    }else {
      this.handleSubmitCreate(body, bodySend)
    }
  }

  handleSubmitCreate = (body, bodySend) => {
    this.props.handleCreate(this.props.token, this.props.userId, bodySend).then(res => {
      if (res.payload.status !== 'error') {
        if (res.payload.data.data.Status === 'OK') {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Create FAQ Success',
          });
          this.setState({
            search: '',
            page: 1,
            orderBy: '',
            loadingSubmit: false,
            visibleCreate: false
          }, () => {
            this.props.handleData(body)
          })
        }else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Create FAQ Failed'
          });
          this.setState({
            loadingSubmit: false
          })
        }
      }else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: 'Create FAQ Failed'
        });
        this.setState({
          loadingSubmit: false
        })
      }
    })
  }

  handleSubmitEdit = (body, bodySend) => {
    this.props.handleEdit(this.props.token, this.props.userId, this.state.idFaq, bodySend).then(res => {
      if (res.payload.status !== 'error') {
        if (res.payload.data.data.Status === 'OK') {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Edit FAQ Success',
          });
          this.setState({
            search: '',
            page: 1,
            orderBy: '',
            loadingSubmit: false,
            visibleCreate: false,
          }, () => {
            this.props.handleData(body)
          })
        }else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Edit FAQ Failed'
          });
          this.setState({
            loadingSubmit: false
          })
        }
      }else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: 'Edit FAQ Failed'
        });
        this.setState({
          loadingSubmit: false
        })
      }
    })
  }

  handleReject = id => {
    const body = {
      token: this.props.token,
      sort: '',
      size: 10,
      page: 1,
      search: '',
    }
    this.props.handleDelete(this.props.token, this.props.userId, id).then(res => {
      if (res.payload.status !== 'error') {
        if (res.payload.data.data.Status === 'OK') {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Delete FAQ Success',
          });
          this.setState({
            search: '',
            page: 1,
            orderBy: '',
          }, () => {
            this.props.handleData(body)
          })
        }else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Delete FAQ Failed'
          });
        }
      }else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: 'Delete FAQ Failed'
        })
      }
    })
  }

  handleEditClick = data => {
    this.setState({
      initalData: {
        category: data.faqCategoryId,
        pertanyaanInd: data.faqQuestionInd,
        pertanyaanEng: data.faqQuestionEng,
        jawabanInd: data.faqAnswerInd,
        jawabanEng: data.faqAnswerEng,
      },
      idFaq: data.id,
      visibleCreate: true,
      isEdit: true
    })
  }

  handleRedirectDetail = id => {
    this.props.history.push(`/faq-detail/${id}`)
  }

  render() {

    const text = 'Apakah anda yakin menghapus FAQ ini ?'

    const columnsTable = [
      {
        title: 'Category',
        dataIndex: 'faqCategoryName',
        key: 'faqCategoryName',
        sorter: true,
        render: faqCategoryName => <Text style={{ color: '#222222', fontWeight: 600, cursor: 'pointer' }}>{faqCategoryName}</Text>,
        onCell: record => {
          return{
            onClick: () => {
              this.handleRedirectDetail(record.id)
            }
          }
        }
      },
      {
        title: 'Question',
        dataIndex: 'faqQuestionInd',
        key: 'faqQuestionInd',
        sorter: true,
      },
      {
        title: 'Action',
        dataIndex: '',
        key: '',
        render: (data) => <>
          <Button
            onClick={() => this.handleEditClick(data)}
            type='link'
            style={{ color: '#EF712B', cursor: 'pointer', fontWeight: 600 }}
          >
            Edit
          </Button>
          <Popconfirm
            placement="bottomRight"
            title={text}
            onConfirm={() => this.handleReject(data.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type='link'
              style={{ color: '#E22828', cursor: 'pointer', fontWeight: 600 }}
            >
              Delete
            </Button>
          </Popconfirm>
        </>
      },
    ]

    const formItem = [
      {
        name: 'category',
        type: 'LOV',
        colNumber: 11,
        dataLOV: this.props.dataLOV.data,
        rules: [
          {
            required: true,
            message: 'Please select category!'
          }
        ],
        placeholder: 'Pilih Kategori'
      },
      {
        type: 'blank',
        colNumber: 11,
        name: 'category',
        rules: []
      },
      {
        name: 'pertanyaanInd',
        maxLength: 256,
        type: 'textArea',
        rowsText: 5,
        colNumber: 11,
        rules: [
          {
            required: true,
            message: 'Please input your Question (ind)!'
          }
        ],
        placeholder: 'Pertanyaan (Bahasa Indonesia)'
      },
      {
        name: 'pertanyaanEng',
        maxLength: 256,
        type: 'textArea',
        rowsText: 5,
        colNumber: 11,
        rules: [
          {
            required: true,
            message: 'Please input your Question (eng)!'
          }
        ],
        placeholder: 'Pertanyaan (Bahasa Inggris)'
      },
      {
        name: 'jawabanInd',
        maxLength: 256,
        type: 'textArea',
        rowsText: 5,
        colNumber: 11,
        rules: [
          {
            required: true,
            message: 'Please input your Answer (ind)!'
          }
        ],
        placeholder: 'Jawaban (Bahasa Indonesia)'
      },
      {
        name: 'jawabanEng',
        maxLength: 256,
        type: 'textArea',
        rowsText: 5,
        colNumber: 11,
        rules: [
          {
            required: true,
            message: 'Please input your Answer (eng)!'
          }
        ],
        placeholder: 'Jawaban (Bahasa Inggris)'
      },
    ]

    const {page, visibleCreate, loading, initalData, isEdit } = this.state
    const { dataFaq } = this.props
    return (
      <div className='root'>
        <Breadcrumb className="breadcrumb_style">
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/dashboard`)}>Pengaturan Umum</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className='pathNow_style'>
            List FAQ
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card className='bodyCard_style'>
          <Row>
            <Col span={8}>
              <UISearch placeholder='Search' handleSearch={key => this.handleSearch(key)} />
            </Col>
            <Col span={16} style={{ textAlign: 'right'}}>
              <Button onClick={() => this.openModal()} className='updateButton'>Create FAQ</Button>
            </Col>
          </Row>
          <Card
            bordered={false}
            loading={dataFaq.loading}
            style={{minHeight: '300px'}}>
            <UItable
              dataTable={dataFaq.data}
              columns={columnsTable}
              withRows={false}
              isMobile={this.props.isMobile}
              loading={dataFaq.status}
              page={page}
              totalData={dataFaq.totalData}
              handleTableChange={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter)}
            />
          </Card>
        </Card>
        <UIModalCreate
          formItem={formItem}
          visible={visibleCreate}
          isMobile={this.props.isMobile}
          loading={loading}
          initialValues={initalData}
          title={ isEdit ? 'Edit FAQ' : 'Create New FAQ'}
          widthDivider='250px'
          close={() => this.handleCloseModal()}
          checkFinish={ values => this.handleFinish(values)}
          loadingSubmit={false}
        />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return{
    handleData: (param) => dispatch(faqAction.getData(param)),
    handleLOV: (token) => dispatch(lov.getLOVFAQCat(token)),
    handleCreate: (token, userId, body) => dispatch(faqAction.createFAQ(token, userId, body)),
    handleEdit: (token, userId, id, body) => dispatch(faqAction.editFAQ(token, userId, id, body)),
    handleDelete: (token, userId, id) => dispatch(faqAction.deleteFAQ(token, userId, id)),

  }
}

const mapStateToProps = (state) => {
  return {
    dataFaq: state.faq,
    dataLOV: state.lovFaqCat,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ListFAQ)
