import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../global/global.css'
import { UIContentDetailLSGallery } from '../../../components'
import {
  Card, Breadcrumb, notification,
} from 'antd'
import lifeSharingGallery from "../../../actions/lifeSharingGallery";

class lifeSharingGalleryDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      loadingEdit: false,
    }
  }

  componentDidMount() {
    const id = this.props.location.pathname.split('/')[2];
    this.setState({ id: id })
    this.props.handleDataDetail(id ,this.props.token);
  }

  handleEdit = value => {
    this.setState({
      loadingEdit: true,
    })
    let body = {
      sharingGalleryUrl: value.linkYoutube,
      usersId: sessionStorage.getItem('userId'),
    }
    this.props.handleEditData(this.props.token, body, value.id).then(res => {
      if (res.payload.status !== 'error') {
        if (res.payload.data.status === 200) {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Edit URL Success',
          });
          this.setState({
            loadingEdit: false,
          }, () => {
            this.props.handleDataDetail(this.state.id, this.props.token);
          });
        }else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Edit URL Failed'
          });
          this.setState({
            loadingEdit: false,
          })
        }
      }else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: res.payload.error.response.data.message ? res.payload.error.response.data.message : 'Edit URL Failed'
        });
        this.setState({
          loadingEdit: false,
        })
      }
    })
  }

  finishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  render() {
    const { dataDetailLifeSharingGallery } = this.props;
    const { loadingEdit } = this.state;
    return (
      <div className='root'>
        <Breadcrumb className="breadcrumb_style">
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/dashboard`)}>Life Sharing</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/life-sharing-Gallery`)}>Daftar Galeri </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className='pathNow_style'>
            Detail Galeri
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card
          loading={dataDetailLifeSharingGallery.status === 'loading'}
          className='bodyCard_style'
        >
          <UIContentDetailLSGallery
            from={'presentation'}
            isMobile={this.props.isMobile}
            dataDetail={dataDetailLifeSharingGallery ? dataDetailLifeSharingGallery.data : [] }
            onFinish={(value) => this.handleEdit(value)}
            onFinishFailed={errorInfo => this.finishFailed(errorInfo)}
            loadingEdit={loadingEdit}
          />
        </Card>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleDataDetail: (id, token) => dispatch(lifeSharingGallery.getDetail(id, token)),
    handleEditData: (token, body, id) => dispatch(lifeSharingGallery.putDetailLifeSharingGallery(token, body, id)),
  }
}

const mapStateToProps = (state) => {
  return {
    dataDetailLifeSharingGallery: state.lifeSharingGalleryDetail,
    dataEditDetailLifeSharingGallery : state.putDetailLifeSharingGallery,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(lifeSharingGalleryDetail)
