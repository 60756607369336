import { get, post, put } from 'axios'
import config from '../services/config'
const { BASE_URL_LOGIN, TIMEOUT } = config

const getApproval = (token, sort, size, page, search) => {
  return get(`${BASE_URL_LOGIN}be/api/bwEventOnApproval?sort=${sort}&size=${size}&page=${page - 1}&search=${search}`,
  {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    timeout: TIMEOUT
  }
  ).then(response => {
    const normalizeData = response.data.Data.map(res =>{
      return {
        bwScheduleDate: res.bwScheduleDate,
        bwScheduleDay: res.bwScheduleDay,
        bwSessionName: res.bwSessionName,
        bwUnivId: res.bwUnivId,
        id: res.bwUnivId,
        bwUnivSpeaker: res.bwUnivSpeaker,
        univRegName: res.univRegName,
        status: res.Status,
      }
    })
    return {
      data: normalizeData,
      totalData: response.data.totalData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      totalData: 0,
      error,
      data: []
    }
  })
}

const getApprovalDetail = (token, id) => {
  return get(`${BASE_URL_LOGIN}be/api/bwEventDetailAdmin?bwUnivId=${id}`,
  {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    timeout: TIMEOUT
  }
  ).then(response => {
    const normalizeData = {
      bwScheduleId: response.data.Data.bwScheduleId,
      bwSessionId: response.data.Data.bwSessionId,
      bwUnivLoyolaQuota: response.data.Data.bwUnivLoyolaQuota,
      bwUnivMedia: response.data.Data.bwUnivMedia,
      bwUnivMediaJoinId: response.data.Data.bwUnivMediaJoinId,
      bwUnivMediaJoinPassword: response.data.Data.bwUnivMediaJoinPassword,
      bwUnivMediaJoinUrl: response.data.Data.bwUnivMediaJoinUrl,
      bwUnivOtherQuota: response.data.Data.bwUnivOtherQuota,
      bwUnivPicName: response.data.Data.bwUnivPicName,
      bwUnivPicWa: response.data.Data.bwUnivPicWa,
      bwUnivSpeaker: response.data.Data.bwUnivSpeaker,
      bwUnivSpeakerDesc: response.data.Data.bwUnivSpeakerDesc,
      isOnline: response.data.Data.isOnline,
      lastModifiedBy: response.data.Data.lastModifiedBy,
      univRegAddress: response.data.Data.univRegAddress,
      univRegName: response.data.Data.univRegName,
      univRegPhone: response.data.Data.univRegPhone,
      logoPath: response.data.Data.logoPath,
      bwScheduleDay: response.data.Data.bwScheduleDay,
      bwScheduleDate: response.data.Data.bwScheduleDate,
    }
    return {
      data: normalizeData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const editDetail = (token, id, body) => {
  return post(`${BASE_URL_LOGIN}be/api/bwEventMedia?bwUnivId=${id}`,
  body,
  {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    timeout: TIMEOUT
  }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const putApproval = (token, body) => {
  return put(`${BASE_URL_LOGIN}be/api/bwEventApproval`,
  body,
  {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    timeout: TIMEOUT
  }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const rejectApproval = (token, body) => {
  return put(`${BASE_URL_LOGIN}be/api/bwEventReject`,
  body,
  {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    timeout: TIMEOUT
  }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const postUniv = (token, body) => {
  return post(`${BASE_URL_LOGIN}be/api/registerBidiktiWeekByAdmin`,
  body,
  {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    timeout: TIMEOUT
  }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

export default {
  getApproval,
  getApprovalDetail,
  editDetail,
  putApproval,
  rejectApproval,
  postUniv
}
