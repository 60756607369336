const GET_DAFTAR_PERSETUJUAN_GALERI_LOADING = 'GET_DAFTAR_PERSETUJUAN_GALERI_LOADING';
const GET_DAFTAR_PERSETUJUAN_GALERI_SUCCESS = 'GET_DAFTAR_PERSETUJUAN_GALERI_SUCCESS';
const GET_DAFTAR_PERSETUJUAN_GALERI_FAILED = 'GET_DAFTAR_PERSETUJUAN_GALERI_FAILED';
const APPROVE_DAFTAR_PERSETUJUAN_GALERI_LOADING = 'APPROVE_DAFTAR_PERSETUJUAN_GALERI_LOADING';
const APPROVE_DAFTAR_PERSETUJUAN_GALERI_SUCCESS = 'APPROVE_DAFTAR_PERSETUJUAN_GALERI_SUCCESS';
const APPROVE_DAFTAR_PERSETUJUAN_GALERI_FAILED = 'APPROVE_DAFTAR_PERSETUJUAN_GALERI_FAILED';
const REJECT_DAFTAR_PERSETUJUAN_GALERI_LOADING = 'REJECT_DAFTAR_PERSETUJUAN_GALERI_LOADING';
const REJECT_DAFTAR_PERSETUJUAN_GALERI_SUCCESS = 'REJECT_DAFTAR_PERSETUJUAN_GALERI_SUCCESS';
const REJECT_DAFTAR_PERSETUJUAN_GALERI_FAILED = 'REJECT_DAFTAR_PERSETUJUAN_GALERI_FAILED';
const DELETE_DAFTAR_PERSETUJUAN_GALERI_LOADING = 'DELETE_DAFTAR_PERSETUJUAN_GALERI_LOADING';
const DELETE_DAFTAR_PERSETUJUAN_GALERI_SUCCESS = 'DELETE_DAFTAR_PERSETUJUAN_GALERI_SUCCESS';
const DELETE_DAFTAR_PERSETUJUAN_GALERI_FAILED = 'DELETE_DAFTAR_PERSETUJUAN_GALERI_FAILED';

export {
  GET_DAFTAR_PERSETUJUAN_GALERI_LOADING,
  GET_DAFTAR_PERSETUJUAN_GALERI_SUCCESS,
  GET_DAFTAR_PERSETUJUAN_GALERI_FAILED,
  APPROVE_DAFTAR_PERSETUJUAN_GALERI_LOADING,
  APPROVE_DAFTAR_PERSETUJUAN_GALERI_SUCCESS,
  APPROVE_DAFTAR_PERSETUJUAN_GALERI_FAILED,
  REJECT_DAFTAR_PERSETUJUAN_GALERI_LOADING,
  REJECT_DAFTAR_PERSETUJUAN_GALERI_SUCCESS,
  REJECT_DAFTAR_PERSETUJUAN_GALERI_FAILED,
  DELETE_DAFTAR_PERSETUJUAN_GALERI_LOADING,
  DELETE_DAFTAR_PERSETUJUAN_GALERI_SUCCESS,
  DELETE_DAFTAR_PERSETUJUAN_GALERI_FAILED,
}
