import React from 'react'
import { Select } from 'antd'
import './selectComponent.css'

const { Option } = Select

const SelectCore = props => {
  const dataLOV = props.dataLOV || []
  const placeholder= props.placeholder || ''
  const lovDisabled = props.lovDisabled || false
  const isForm = props.fromForm || false
  const handleChange = value => {
    if (!isForm) {
      return props.handleSelect(value)
    }else {
      return value
    }
  }
  
  return(
    <Select
      placeholder={placeholder}
      disabled={lovDisabled}
      className="containter_select"
      onChange={(event) => handleChange(event)}>
    {
      dataLOV ?
      dataLOV.map((item, index) => (
          <Option key={item.id + 'LOV' + index} value={item.id}>{item.value}</Option>  
        )
      ):(
        <></>
      )
    }
  </Select>
  )
}

export const UISelect = SelectCore