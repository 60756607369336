import types from '../constants'
import approval from '../api/approval'

const getDataApproval = (token, sort, size, page, search) => dispatch => {
  dispatch({
    type: types.GET_APPROVAL_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: [],
      totalData: 0
    }
  })
  return approval.getApproval(token, sort, size, page, search).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.GET_APPROVAL_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_APPROVAL_SUCCESS,
        payload: result
      })
    }
  })
}

const getDataDetailApproval = (token, id) => dispatch => {
  dispatch({
    type: types.GET_APPROVAL_DETAIL_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return approval.getApprovalDetail(token, id).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.GET_APPROVAL_DETAIL_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_APPROVAL_DETAIL_SUCCESS,
        payload: result
      })
    }
  })
}

const postEditDetail = (token, id, body) => dispatch => {
  dispatch({
    type: types.UPDATE_APPROVAL_DETAIL_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return approval.editDetail(token, id, body).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.UPDATE_APPROVAL_DETAIL_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.UPDATE_APPROVAL_DETAIL_SUCCESS,
        payload: result
      })
    }
  })
}

const putApproval = (token, body) => dispatch => {
  dispatch({
    type: types.PUT_APPROVAL_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return approval.putApproval(token, body).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.PUT_APPROVAL_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.PUT_APPROVAL_SUCCESS,
        payload: result
      })
    }
  })
}

const rejectApproval = (token, body) => dispatch => {
  dispatch({
    type: types.REJECT_APPROVAL_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return approval.rejectApproval(token, body).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.REJECT_APPROVAL_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.REJECT_APPROVAL_SUCCESS,
        payload: result
      })
    }
  })
}

const regisUniv = (token, body) => dispatch => {
  dispatch({
    type: types.POST_UNIV_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return approval.postUniv(token, body).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.POST_UNIV_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.POST_UNIV_SUCCESS,
        payload: result
      })
    }
  })
}

export default {
  getDataApproval,
  getDataDetailApproval,
  postEditDetail,
  putApproval,
  rejectApproval,
  regisUniv
}