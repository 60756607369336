import { get, put, post } from "axios";
import config from "../services/config";

const { BASE_URL_LOGIN, TIMEOUT } = config;

const getData = param => {
   return get(`${BASE_URL_LOGIN}be/api/countryAdmin/readAll?filter=${param.filter}&orderby=${param.orderBy}&top=${param.top}&skip=${param.skip}`,
      {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${param.token}`,
         },
         timeout: TIMEOUT
      }
   ).then(response => {
      const normalizeData = response.data.items.map(res => {
         return {
            id: res.countryId,
            countryId: res.countryId,
            countryName: res.countryName,
            countryCode: res.countryCode
         }
      })
      return {
         data: normalizeData,
         totalData: response.data.totalItems,
         status: "success",
         error: ""
      }
   }).catch(error => {
      return {
         data: [],
         totalData: 0,
         status: "error",
         error
      }
   })
}

const createNegara = (token, body) => {
   return post(`${BASE_URL_LOGIN}be/api/submitCountryAdmin`,
      body,
      {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
         timeout: TIMEOUT
      }
   ).then(response => {
      return {
         data: response,
         status: "success",
         error: ''
      }
   }).catch(error => {
      return {
         data: [],
         status: "error",
         error
      }
   })
}

const editNegara = (token, body, id) => {
   return put(`${BASE_URL_LOGIN}be/api/updateCountryAdmin/${id}`,
      body,
      {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`

         },
         timeout: TIMEOUT
      }
   ).then(response => {
      return {
         data: response,
         status: "success",
         error: ""
      }
   }).catch(error => {
      return {
         data: [],
         status: "error",
         error
      }
   })
}

const deleteNegara = (token, body, id) => {
   return put(`${BASE_URL_LOGIN}be/api/deleteCountryAdmin/${id}`,
      body,
      {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
         },
         timeout: TIMEOUT
      }
   ).then(response => {
      return {
         data: response,
         status: "success",
         error: ""
      }
   }).catch(error => {
      return {
         data: [],
         status: "error",
         error
      }
   })
}

export default {
   getData,
   createNegara,
   editNegara,
   deleteNegara
}