import { get } from 'axios'
import config from '../services/config'
const { BASE_URL_NOAUTH, BASE_URL_LOGIN, TIMEOUT } = config

const getLOVType = (token) => {
  return get(`${BASE_URL_LOGIN}be/api/log`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.Data.map(res =>{
      return {
        name: res.namaLogging,
        id: res.jenisLogging
      }
    })
    return {
      data: normalizeData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const downloadLoging = (token, type, start, end) => {
  return get(`${BASE_URL_LOGIN}be/api/downloadLog?jenisLogging=${type}&startDate=${start}&endDate=${end}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = {
      link: response.data.Link
    }
    return {
      data: normalizeData,
      status:'success',
      isLoading: false,
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      isLoading: false,
      error,
      data: []
    }
  })
}


export default {
    getLOVType,
    downloadLoging
  }
  