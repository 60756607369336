import types from '../constants'
import subscribe from '../api/subscribe'

const getDataListSubscriber = (token, sort, size, page, search) => dispatch => {
  dispatch({
    type: types.GET_LIST_SUBSCRIBER_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return subscribe.getListSubscriber(token, sort, size, page, search).then(result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_LIST_SUBSCRIBER_FAILED,
        payload: result
      })
    } else {
      return dispatch({
        type: types.GET_LIST_SUBSCRIBER_SUCCESS,
        payload: result
      })
    }
  })
}

const sendDataMailNews = (token, body) => dispatch => {
  dispatch({
    type: types.SEND_MAIL_NEWS_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return subscribe.sendMailNews(token, body).then(result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.SEND_MAIL_NEWS_FAILED,
        payload: result
      })
    } else {
      return dispatch({
        type: types.SEND_MAIL_NEWS_SUCCESS,
        payload: result
      })
    }
  })
}

const uploadDataBanner = (token, formData) => dispatch => {
  dispatch({
    type: types.UPLOAD_BANNER_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return subscribe.uploadBanner(token, formData).then(result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.UPLOAD_BANNER_FAILED,
        payload: result
      })
    } else {
      return dispatch({
        type: types.UPLOAD_BANNER_SUCCESS,
        payload: result
      })
    }
  })
}

export default {
  getDataListSubscriber,
  sendDataMailNews,
  uploadDataBanner
}
