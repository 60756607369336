import React, { Component } from "react";
import { 
   Breadcrumb, Card, Row, Col, Typography
} from "antd";
import {
   UItable, UISearch
} from "../../components";
import "../../global/global.css";
import { connect } from "react-redux";
import daftarPersetujuanKonten from "../../actions/daftarPersetujuanKonten";

const { Text } = Typography;

class DaftarPersetujuanKonten extends Component {
   constructor(props) {
      super(props);
      this.state = {
         param: {
            search: "",
            sort: "",
            size: 10,
            page: 1
         }
      };
   }

   componentDidMount() {
      this.props.handleGetData(this.props.token, this.state.param);
   }

   handleRedirectDetail = (id, statusApproval) => {
      let status = "";
      if ( statusApproval === "approved" ) {
         status = "Approved";
      } else if ( statusApproval === "rejected" ) {
         status = "Rejected";
      } else {
         status = "On Approval";
      }
      this.props.history.push(`/daftar-persetujuan-konten-detail/${id}/${status}`);
   }

   handleSearch = key => {
      this.setState({
         param: {
            ...this.state.param,
            search: key,
            page: 1
         }
      }, () => {
         this.props.handleGetData(this.props.token, this.state.param);
      });
   }

   handleTableChange = (pageNow, filters, sorter) => {
      let sortBy = "";
      if ( sorter.field === "univName" ) {
         sortBy = "univ_name";
      } else if ( sorter.field === "univEmail" ) {
         sortBy = "univ_email";
      } else if ( sorter.field === "univWebsite" ) {
         sortBy = "univ_website";
      } else if ( sorter.field === "statusApproval") {
         sortBy = "status_approval";
      }

      let sortByValue = "";
      if ( sorter.order === "ascend" ) {
         sortByValue = `${sortBy},asc`;
      } else if ( sorter.order === "descend" ) {
         sortByValue = `${sortBy},desc`;
      }
      this.setState({
         param: {
            ...this.state.param,
            sort: sortByValue,
            page: pageNow.current
         }
      }, () => {
         this.props.handleGetData(this.props.token, this.state.param);
      })
   }

   render() {
      const { getDaftarPersetujuanKonten } = this.props;
      const { param } = this.state;
      const columnsTable = [
         {
            title: "Universitas",
            dataIndex: "univName",
            key: "univName",
            sorter: true,
            render: universitas => <Text style={{ color: '#EF712B', fontWeight: 600, cursor: 'pointer' }}>{universitas}</Text>,
            onCell: record => {
               return{
                 onClick: () => {
                   this.handleRedirectDetail(record.univId, record.statusApproval);
                 }
               }
            }
         },
         {
            title: "Email",
            dataIndex: "univEmail",
            key: "univEmail",
            sorter: true
         },
         {
            title: "Website Universitas",
            dataIndex: "univWebsite",
            key: "univWebsite",
            sorter: true
         },
         {
            title: "Status",
            dataIndex: "statusApproval",
            key: "statusApproval",
            sorter: true,
            render: statusApproval => {
               if ( statusApproval === "onApproval" ) {
                  return <Text style={{ color: '#21296C', fontWeight: 600 }}>On Approval</Text>
               } else if ( statusApproval === "approved" ) {
                  return <Text style={{ color: '#6BC733', fontWeight: 600 }}>Approved</Text>
               } else {
                  return <Text style={{ color: '#E22828', fontWeight: 600 }}>Rejected</Text>
               }
            }
         }
      ];
      
      return (
         <div className="root">
            <Breadcrumb className="breadcrumb_style">
               <Breadcrumb.Item>
                  <span style={{ cursor: 'pointer' }}>Universitas</span>
               </Breadcrumb.Item>
               <Breadcrumb.Item className='pathNow_style'>
                  Daftar Persetujuan Konten
               </Breadcrumb.Item>
            </Breadcrumb>
            <Card className="bodyCard_style">
               <Row>
                  <Col span={8}>
                     <UISearch placeholder='Search' handleSearch={key => this.handleSearch(key)} />
                  </Col>
               </Row>
               <Card
                  bordered={false}
                  loading={false}
                  style={{ minHeight: '300px' }}>
                  <UItable
                     dataTable={getDaftarPersetujuanKonten.data}
                     columns={columnsTable}
                     withRows={false}
                     isMobile={this.props.isMobile}
                     loading={getDaftarPersetujuanKonten.status}
                     page={param.page}
                     totalData={getDaftarPersetujuanKonten.totalData}
                     handleTableChange={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter)}
                  />
               </Card>
            </Card>
         </div>
      )
   }
} 

const mapStateToProps = state => (
   {
      getDaftarPersetujuanKonten: state.getDaftarPersetujuanKonten,
      approveDaftarPersetujuanKonten: state.approveDaftarPersetujuanKonten,
      rejectDaftarPersetujuanKonten: state.rejectDaftarPersetujuanKonten,
   }
);

const mapDispatchToProps = dispatch => (
   {
      handleGetData: (token, param) => dispatch(daftarPersetujuanKonten.getData(token, param)),
      handleApprove: (token, univId, body) => dispatch(daftarPersetujuanKonten.approveDaftarPersetujuanKonten(token, univId, body)),
      handleReject: (token, univId, body) => dispatch(daftarPersetujuanKonten.rejectDaftarPersetujuanKonten(token, univId, body)),
   }
)

export default connect(mapStateToProps, mapDispatchToProps)(DaftarPersetujuanKonten);