const GET_KERJASAMA_LOADING = 'GET_KERJASAMA_LOADING'
const GET_KERJASAMA_SUCCESS = 'GET_KERJASAMA_SUCCESS'
const GET_KERJASAMA_FAILED = 'GET_KERJASAMA_FAILED'
const PUT_KERJASAMA_SHOW_LOADING = 'PUT_KERJASAMA_SHOW_LOADING'
const PUT_KERJASAMA_SHOW_SUCCESS = 'PUT_KERJASAMA_SHOW_SUCCESS'
const PUT_KERJASAMA_SHOW_FAILED = 'PUT_KERJASAMA_SHOW_FAILED'
const PUT_KERJASAMA_HIDE_LOADING = 'PUT_KERJASAMA_HIDE_LOADING'
const PUT_KERJASAMA_HIDE_SUCCESS = 'PUT_KERJASAMA_HIDE_SUCCESS'
const PUT_KERJASAMA_HIDE_FAILED = 'PUT_KERJASAMA_HIDE_FAILED'

export {
  GET_KERJASAMA_LOADING,
  GET_KERJASAMA_SUCCESS,
  GET_KERJASAMA_FAILED,
  PUT_KERJASAMA_SHOW_LOADING,
  PUT_KERJASAMA_SHOW_SUCCESS,
  PUT_KERJASAMA_SHOW_FAILED,
  PUT_KERJASAMA_HIDE_LOADING,
  PUT_KERJASAMA_HIDE_SUCCESS,
  PUT_KERJASAMA_HIDE_FAILED
}
