import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../global/global.css'
import kerjaSamaAction from '../../actions/kerjaSama'
import {
  Card,
  notification,
  Button,
  Row,
  Col,
  Breadcrumb,
  Typography
} from 'antd'
import {CheckCircleFilled} from '@ant-design/icons'
import { UItable, UISearch } from '../../components'

const { Text } = Typography

class ListKerjaSama extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initial: '',
      orderBy: '',
      size: 10,
      page: 1,
      search: '',
      sekola: 1,
      loadingButton: [false]
    }
  }

  componentDidMount() {
    const {orderBy, size, page, sekola, search} = this.state
    const body = {
      token: this.props.token,
      sort: orderBy,
      size: size,
      page: page,
      search: search,
      cat: sekola
    }
    this.props.handleDataKerjasama(body)
  }

  handleSearch = key => {
    const {orderBy, size, page, sekola} = this.state
    this.setState({
      search: key,
      page: 1
    })
    const body = {
      token: this.props.token,
      sort: orderBy,
      size: size,
      page: page,
      search: key,
      cat: sekola
    }
    this.props.handleDataKerjasama(body)
  }

  handleTableChange = (page, filter, sorter) => { 
    const {size, search, sekola} = this.state

    let sorterField = ""
    if (sorter.field === "univName") {
      sorterField = "univ_name";
    } else {
      sorterField = "is_rank_by_pibsl";
    }

    let sort = "";
    if (sorter.order === "ascend") {
      sort = `${sorterField},asc`;
    } else if (sorter.order === "descend") {
      sort = `${sorterField},desc`;
    }
    const body = {
      token: this.props.token,
      sort: sort,
      size: size,
      page: page.current,
      search: search,
      cat: sekola
    }
    this.setState({
      page: page.current,
      orderBy: sort
    }, () => {
      this.props.handleDataKerjasama(body)
    })
  }

  setSekola = val => {
    const { orderBy, size, search } = this.state
    this.setState({
      page: 1
    })
    const body = {
      token: this.props.token,
      sort: orderBy,
      size: size,
      page: 1,
      search: search,
      cat: val
    }
    this.setState({
      sekola: val
    })
    this.props.handleDataKerjasama(body)
  }

  handleUpdate = (univId, status, index) => {
    let temp = this.state.loadingButton
    temp[index] = true
    this.setState({
      loadingButton: temp
    })
    const body = {
      token: this.props.token,
      sort: '',
      size: 10,
      page: 1,
      search: '',
      cat: this.state.sekola
    }
    if (status === 'show') {
      this.props.handleShow(this.props.token, univId, this.props.userId).then(res => {
        if (res.payload.data.data.Status === 'OK') {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Update Status Kerjasama Success',
          });
          temp[index] = false
          this.setState({
            search: '',
            page: 1,
            orderBy: '',
            loadingButton: temp
          }, () => {
            this.props.handleDataKerjasama(body)
          })
        }else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Update Status Kerjasama Failed'
          });
          temp[index] = false
          this.setState({
            loadingButton: temp
          })
        }
      })
    }else {
      this.props.handleHide(this.props.token, univId, this.props.userId).then(res => {
        if (res.payload.data.data.Status === 'OK') {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Update Status Kerjasama Success',
          });
          temp[index] = false
          this.setState({
            search: '',
            page: 1,
            orderBy: '',
            loadingButton: temp
          }, () => {
            this.props.handleDataKerjasama(body)
          })
        }else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Update Status Kerjasama Failed'
          });
          temp[index] = false
          this.setState({
            loadingButton: temp
          })
        }
      })
    }
  }


  render() {

    const columnsTable = [
      {
        title: 'Nama Institusi',
        dataIndex: 'univName',
        key: 'univName',
        sorter: true,
        ellipsis: true,
        width: '40%' ,
        render: univName => <Text style={{ color: '#EF712B', fontWeight: 600 }}>{univName}</Text>,
      },
      {
        title: 'Status Tampil',
        dataIndex: 'isRankByPibsl',
        key: 'id',
        sorter: true,
        align: 'center',
        render: (isRankByPibsl) => 
        <>
          {isRankByPibsl ? (
            <CheckCircleFilled style={{ color: '#21296C', fontSize: '30px'}} />
          ) : (
            <>
            </>
          ) }
        </>
      },
      {
        title: 'Action',
        dataIndex: '',
        key: '',
        width: '20%' ,
        render: (record, row, index) => 
        <>
          {record.isRankByPibsl ? (
            <Button
              type="primary"
              className='updateButton'
              onClick={() => this.handleUpdate(record.univId, 'hide', index)}
              loading={this.state.loadingButton[index]}
              size={'small'}
            >
              Sembunyikan
            </Button>
          ) : (
            <Button
              type="primary"
              className='buttonUpload'
              onClick={() => this.handleUpdate(record.univId, 'show', index)}
              loading={this.state.loadingButton[index]}
              size={'small'}
            >
              Tampilkan
            </Button>
          )}
        </>
      },
    ]
    
    const {page, sekola} = this.state
    const { dataKerjasama } = this.props
    return (
      <div className='root'>
        <Breadcrumb className="breadcrumb_style">
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/dashboard`)}>Pengaturan Umum</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className='pathNow_style'>
            Tampilan Kerjasama
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card className='bodyCard_style'>
          <Row gutter={[ 16, 0]} style={{marginBottom: '40px'}}>
            <Col span={this.props.isMobile ? 6 : 4}>
              <Button block onClick={() => this.setSekola(1)} className={`${sekola === 1 ? 'updateButton' : 'nonActiveButton'}`}>Negeri</Button>
            </Col>
            <Col span={this.props.isMobile ? 6 : 4}>
              <Button block onClick={() => this.setSekola(2)} className={`${sekola === 2 ? 'updateButton' : 'nonActiveButton'}`}>Swasta</Button>
            </Col>
          </Row>
          <Row>
            <Col span={this.props.isMobile ? 12 : 8}>
              <UISearch placeholder='Search' handleSearch={key => this.handleSearch(key)} />
            </Col>
          </Row>
          <Card 
            bordered={false} 
            style={{minHeight: '300px'}}>
            <UItable
              dataTable={dataKerjasama.data}
              columns={columnsTable}
              withRows={false}
              isMobile={this.props.isMobile}
              loading={dataKerjasama.status}
              page={page}
              totalData={dataKerjasama.totalData}
              handleTableChange={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter)}
            />
          </Card>
        </Card>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return{
    handleDataKerjasama: (param) => dispatch(kerjaSamaAction.getDataKerjasama(param)),
    handleShow: (token, univId, userId) => dispatch(kerjaSamaAction.handleShow(token, univId, userId)),
    handleHide: (token, univId, userId) => dispatch(kerjaSamaAction.handleHide(token, univId, userId)),
  }
}

const mapStateToProps = (state) => {
  return {
    dataKerjasama: state.kerjasama,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ListKerjaSama)
