import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../global/global.css'
import { Card, Breadcrumb, Button, Col, Row, Form, Input, Modal, notification } from 'antd'
import impianAction from '../../actions/impian'
import {CaretRightOutlined} from '@ant-design/icons'
import ImagePreviewComponent from '../../components/imagePreview'
import {GlobalVideoCheck} from '../../global/checkingVideo'

class ImpianDetail extends Component {
  formRef = React.createRef()
  constructor(props) {
    super(props);
    this.state = {
      inital: '',
      loadingSubmit: false,
      dataVid: '',
      modalVid: false,
      idPage: null
    }
  }

  componentDidMount() {
    let id = this.props.location.pathname.split('/')[2]
    this.setState({
      idPage: id
    })
    this.props.handleData(this.props.token, id)
  }

  playVid = val => {
    this.setState({
      dataVid: val,
      modalVid: true
    })
  }

  handleClose = () => {
    this.setState({
      dataVid: '',
      modalVid: false
    })
  }

  checkFinish = val => {
    const { token, dataDetail } = this.props
    this.setState({
      loadingSubmit: true
    })
    this.props.handleEdit(token, dataDetail.data.visionDetId, val).then(res =>{
      if (res.payload.status !== 'error') {
        if (res.payload.data.data.Status === 'OK') {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Update Impian Anak-Anak Kami Success',
          });
          this.setState({
            loadingSubmit: false
          })
          this.props.handleData(token, this.state.idPage)
        }else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Update Impian Anak-Anak Kami Failed'
          });
          this.setState({
            loadingSubmit: false
          })
        }
      }else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: 'Update Impian Anak-Anak Kami Failed'
        });
        this.setState({
          loadingSubmit: false
        })
      }
    })
  }


  onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };


  componentDidUpdate(prevProps) {
    const { dataDetail } = this.props
    if (
      dataDetail.status !==
      prevProps.dataDetail.status &&
      dataDetail.status === 'success'
      ) {
        let temp = {
          visionDetId: dataDetail.data.visionDetId ,
          visionDetStudentClass: dataDetail.data.visionDetStudentClass ,
          visionDetStudentDesc: dataDetail.data.visionDetStudentDesc ,
          visionDetStudentMajor: dataDetail.data.visionDetStudentMajor ,
          visionDetStudentName: dataDetail.data.visionDetStudentName ,
          visionDetStudentSchoolYear: dataDetail.data.visionDetStudentSchoolYear ,
          visionDetStudentYoutubeUrl: dataDetail.data.visionDetStudentYoutubeUrl,
          visionDetStudentYoutubeUrlEmbed: dataDetail.data.visionDetStudentYoutubeUrlEmbed ,
          visionDetStudentYoutubeUrlImage: dataDetail.data.visionDetStudentYoutubeUrlImage

        }
        this.formRef.current.setFieldsValue(temp)
    }
  }

  render() {
    const { loadingSubmit, dataVid, modalVid } = this.state
    const { dataDetail } = this.props
    return(
      <div className='root'>
        <Breadcrumb className="breadcrumb_style" >
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/dashboard`)}>Pengaturan Umum </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/galeri-impian`)}>Galeri Impian Anak-Anak Kami </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className='pathNow_style'>
            Detail
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card
          loading={dataDetail.status === 'loading'}
          className='bodyCard_style'
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form
                onFinish={values => this.checkFinish(values)}
                onFinishFailed={event => this.onFinishFailed(event)}
                ref={this.formRef}
              >
                <Row gutter={[32, 0]}>
                  <Col span={12}>
                    <Row gutter={this.props.isMobile ? [4, 16] : [10, 16]}>
                      <Col span={23}>
                        <Form.Item
                          className='input_form'
                          name='visionDetStudentName'
                          rules={[{
                            required: true,
                            message: 'Please Input Full Name Student!'
                          }]}
                        >
                          <Input
                            maxLength={256}
                            size="large"
                            className='input_style'
                            placeholder='Nama Lengkap Siswa'/>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <span className="mandatoryLarge">&#8727;</span>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          className='input_form'
                          name='visionDetStudentMajor'
                          rules={[{
                            required: true,
                            message: 'Please Input Major!'
                          }]}
                        >
                          <Input
                            maxLength={30}
                            size="large"
                            className='input_style'
                            placeholder='Jurusan' />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <span className="mandatoryLarge">&#8727;</span>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          className='input_form'
                          name='visionDetStudentDesc'
                          rules={[{
                            required: true,
                            message: 'Please Input Description!'
                          }]}
                        >
                          <Input.TextArea
                            size="large"
                            rows={6}
                            className='input_style'
                            placeholder='Deskripsi' />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <span className="mandatoryLarge">&#8727;</span>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row gutter={this.props.isMobile ? [4, 16] : [10, 16]}>
                      <Col span={23}>
                        <Form.Item
                          className='input_form'
                          name='visionDetStudentClass'
                          rules={[{
                            required: true,
                            message: 'Please Input Class'
                          }]}
                        >
                          <Input
                            maxLength={15}
                            size="large"
                            className='input_style'
                            placeholder='Kelas' />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <span className="mandatoryLarge">&#8727;</span>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          className='input_form'
                          name='visionDetStudentSchoolYear'
                          rules={[{
                            required: true,
                            message: 'Please Input Year!'
                          }]}
                        >
                          <Input
                            maxLength={5}
                            size="large"
                            rows={8}
                            className='input_style'
                            placeholder='Tahun Ajaran' />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <span className="mandatoryLarge">&#8727;</span>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          className='input_form'
                          name='visionDetStudentYoutubeUrl'
                          rules={[{
                            required: true,
                            message: 'Please input URL youtube!'
                          },
                          {
                            pattern:   /^http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/ , 
                            message: 'Invalid youtube URL!' 
                          }]}
                        >
                          <Input
                            size="large"
                            rows={8}
                            className='input_style'
                            placeholder='URL Youtube' />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <span className="mandatoryLarge">&#8727;</span>
                      </Col>
                      <Col span={23}>
                        <div className='gradientCard'>
                          <CaretRightOutlined style={{fontSize: this.props.isMobile ? '35px' : '50px'}} className='playButton'
                            onClick={() => this.playVid(dataDetail.data.visionDetStudentYoutubeUrlEmbed)}
                          />
                          <ImagePreviewComponent
                            isMobile={this.props.isMobile}
                            styles={{ width: '100%' }}
                            uri={dataDetail.data.visionDetStudentYoutubeUrlImage}
                            imageBorder={'6px'}
                          />
                          {/* <img
                            alt="example"
                            src={dataDetail.data.visionDetStudentYoutubeUrlImage}
                            width='100%'
                            style={{borderRadius: '6px'}}
                          /> */}
                        </div>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          style={{
                            textAlign: 'right',
                            marginBottom: '0px'
                          }}
                        >
                          <Button
                            type="primary"
                            htmlType='submit'
                            className='updateButton'
                            loading={loadingSubmit}
                            size={'small'}
                          >
                            Submit
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card>
        <Modal
          visible={modalVid}
          centered
          className={this.props.isMobile ? 'cardVideoMobile' : 'cardVideo'}
          footer={null}
          width={this.props.isMobile ? '100%' : '75%'}
          onCancel={() => this.handleClose()}
        >
          {GlobalVideoCheck.checking(dataVid) ? (
            <iframe
              style={{ width: '-webkit-fill-available', height: this.props.isMobile ? '280px' : '500px' }}
              id="modalVideo"
              title='modalVideo'
              src={dataVid}
              frameBorder="0"
              allow="accelerometer;
              autoplay;
              encrypted-media;
              gyroscope;
              picture-in-picture"
              allowFullScreen="allowfullscreen"
            />
            ) : (
              <span className='data_text_detail'>
                Url Video Tidak Valid/Error
              </span>
            )
          }
      </Modal>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return{
    handleData: (token, id) => dispatch(impianAction.getDataDetail(token, id)),
    handleEdit:  (token, id, body) => dispatch(impianAction.handleEdit(token, id, body))
  }
}

const mapStateToProps = (state) => {
  return {
    dataDetail: state.impianDetail,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ImpianDetail)
