import React, {Component} from 'react'
import {connect} from 'react-redux'
import '../../global/global.css'
import getGallery from '../../actions/gallery'
import lov from '../../actions/lov'
import moment from 'moment'
import {
  Card,
  notification,
  Button,
  Row,
  Col,
  Breadcrumb,
  Typography,
  Popconfirm
} from 'antd'
import {UItable, UISearch, UIModalGallery} from '../../components'

const {Text} = Typography

class listGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initial: '',
      orderBy: '',
      size: 10,
      page: 1,
      search: '',
      visibleInput: false,
      loadingSubmit: false,
      loadingButton: [false],
      initData: {
        univName: undefined,
      },
      isSelected: undefined,
      idSelect: 0
    }
  }

  componentDidMount() {
    const {orderBy, size, page, search} = this.state
    this.props.handleDataGallery(this.props.token, orderBy, size, page, search)
    this.props.handleLOVSchedule(this.props.token , undefined ? '' : 1)
    this.props.handleLOVInstansi(this.props.token, '')
    this.props.handleActive(this.props.token)
  }

  handleChangeEventName = value => {
    this.props.handleLOVInstansi(this.props.token, value);
 }

  handleSearch = key => {
    const {orderBy, size} = this.state
    this.setState({
      search: key,
      page: 1
    })
    this.props.handleDataGallery(this.props.token, orderBy, size, 1, key)
  }

  handleInputSubmit = (values) => {
    this.setState({
      loadingSubmit: true
    })
    let tempReg= {}
    if (this.state.isSelected) {
      tempReg = {
        univRegId: {
          univRegId: this.state.idSelect
        }
      }
    }else {
      tempReg = {
        univRegId: {},
        bwGalleryUnivName: values.univName
      }
    }
    let body = {
      bwGalleryDate: values.dayPresentasi ? moment(values.dayPresentasi).format('YYYY-MM-DD') : '',
      bwGalleryDay: values.dayPresentasi ? moment(values.dayPresentasi).format('dddd') : '',
      bwGallerySessionName: values.session,
      bwGallerySessionStart: values.timeStart ? moment(values.timeStart).format('HH:mm:ss') : '',
      bwGallerySessionFinish: values.timeFinish ? moment(values.timeFinish).format('HH:mm:ss') : '',
      bwGalleryDescription: values.deskripsi,
      bwGallerySpeaker: values.speaker,
      bwGallerySpeakerDesc: values.speakerDesc,
      bwGalleryMedia: values.media,
      isOnline: values.isOnline,
      bwGalleryUrl: values.UrlLink,
      createdBy: this.props.userId
    }
    const sendBody = {
      ...tempReg,
      ...body
    }
    this.props.handlePostGallery(this.props.token, sendBody).then(res => {
      if (res.payload.status !== 'error') {
        if (res.payload.data.data.Status === 'OK') {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Upload Gallery Success',
          });
          this.setState({
            search: '',
            page: 1,
            orderBy: '',
            loadingSubmit: false,
          }, () => {
            this.handleRegisClose()
            this.props.handleDataGallery(this.props.token, '', 10, 1, '')
          })
        } else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Upload Gallery Failed'
          });
          this.setState({
            loadingSubmit: false,
          }, () => {
            this.handleRegisClose()
          })
        }
      } else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: 'Upload Gallery Failed'
        });
        this.setState({
          loadingSubmit: false
        }, () => {
          this.handleRegisClose()
        })
      }
    })
  }

  onSelect = data => {
    const dataLOV = this.props.dataLOVInstansi.data
    let tempData = {}
    dataLOV.map(res => {
      if (res.id === data) {
        tempData = {
          univName: res.name
        }
      }
    })
    this.setState({
      isSelected: true,
      initData: tempData,
      idSelect: data
    })
    return tempData
  }

  onChangeSchedule = (idInst, val) => {
    this.props.handleLOVSession(this.props.token, idInst, val)
  }

  handleRegisClose = () => {
    let tempInit = {
      univName: undefined
    }
    this.setState({
      visibleInput: false,
      initData: tempInit,
      idSelect: 0,
      isSelected: undefined,
    })
    return tempInit
  }

  handleReset = () => {
    let tempInit = {
        univName: undefined
    }
    this.setState({
      initData: tempInit,
      idSelect: 0,
      isSelected: undefined,
    })
    return tempInit
  }

  openModal = () => {
    this.setState({
      visibleInput: true
    })
  }


  handleDelete = (index,id) => {
    let temp = this.state.loadingButton
    temp[index] = true
    this.setState({
      loadingButton: temp
    })
    const body = {
      id: id,
      userId: this.props.userId
    }

    this.props.handleDelete(this.props.token, body).then(res => {
      if (res.payload.data.data) {
        notification.success({
          placement: 'bottomRight',
          message: 'Success',
          description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Delete Record Success',
        });
        temp[index] = false
        this.setState({
          search: '',
          page: 1,
          orderBy: 'last_modified_by,desc',
          loadingButton: temp
        }, () => {
            this.props.handleDataGallery(this.props.token, '', 10, 1, '')
        })
      }
    }).catch(err => {
      if(err){
        console.log('err : ',err.response)
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: err.response.data ? err.response.data.message : 'Delete Record Failed'
        });
        temp[index] = false
        this.setState({
          loadingButton: temp
        })
      }
    })
  }

  handleTableChange = (page, filter, sorter) => {
    const {size, search} = this.state

    let sorterField = ""
    if (sorter.field === "univRegName") {
      sorterField = "d.univ_reg_name";
    } else if (sorter.field === "bwUnivSpeaker") {
      sorterField = "b.bw_univ_speaker";
    } else if (sorter.field === "bwScheduleDate") {
      sorterField = "e.bw_schedule_date";
    } else {
      sorterField = "f.bw_session_name";
    }

    let orderBy = "";
    if (sorter.order === "ascend") {
      orderBy = `${sorterField},asc`;
    } else if (sorter.order === "descend") {
      orderBy = `${sorterField},desc`;
    }
    this.setState({
      page: page.current,
      orderBy: orderBy
    }, () => {
      this.props.handleDataGallery(this.props.token, orderBy, size, page.current, search)
    })
  }

  render() {
    const columnsTable = [
      {
        title: 'Nama Institusi',
        dataIndex: 'bwInstitution',
        key: 'bwInstitution',
        sorter: true,
        render: bwInstitution => <Text style={{ color: '#222222', fontWeight: 600}}>{bwInstitution}</Text>,
      },
      {
        title: 'Narasumber',
        dataIndex: 'bwNarasumber',
        key: 'bwNarasumber',
        sorter: true
      },
      {
        title: 'Tanggal',
        dataIndex: 'bwScheduleDate',
        key: 'bwScheduleDate',
        sorter: true
      },
      {
        title: 'Sesi',
        dataIndex: 'bwSession',
        key: 'bwSession',
        sorter: true
      },
      {
        title: 'Action',
        dataIndex: '',
        key: '',
        width: '20%' ,
        render: (record, row, index) =>
        <>
          <Popconfirm
            placement="bottomLeft"
            title={'Yakin ingin menghapus data ini?'}
            onConfirm={() => this.handleDelete(index,record.id)}
            okText="Yes"
            cancelText="No">
            <Button
              type='primary'
              className='deleteButton2'
              loading={this.state.loadingButton[index]}
              size={'small'}
            >
              Delete
            </Button>
          </Popconfirm>
        </>
      },
    ]
    const {
      dataGallery,
      dataLOVScheduleGallery,
      dataLOVSessionGallery,
      dataLOVInstansi,
      dataActive
    } = this.props
    const {
      visibleInput,
      page,
      loadingSubmit,
      initData,
      isSelected
    } = this.state
    return (
      <div className='root'>
        <Breadcrumb className="breadcrumb_style">
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/dashboard`)}>BIDikTi Week </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className='pathNow_style'>
            Daftar Galeri
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card className='bodyCard_style'>
          <Row>
            <Col span={8}>
              <UISearch placeholder='Search' handleSearch={key => this.handleSearch(key)}/>
            </Col>
            <Col span={16} style={{textAlign: 'right'}}>
              <Button onClick={() => this.openModal()} className='updateButton'>Tambah Galeri</Button>
            </Col>
          </Row>
          <Card bordered={false} loading={dataGallery.loading} style={{minHeight: '300px'}}>
            <UItable
              // rowSelection={rowSelection}
              dataTable={dataGallery.data}
              columns={columnsTable}
              isMobile={this.props.isMobile}
              totalData={dataGallery.totalData}
              withRows={false}
              loading={dataGallery.status}
              rowSelect={dataGallery}
              page={page}
              handleTableChange={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter)}
            />
          </Card>
        </Card>
        <UIModalGallery
          galleryOf={'listGallery'}
          isMobile={this.props.isMobile}
          visibleInput={visibleInput}
          title={'Tambah Galeri'}
          initData={initData}
          isSelected={isSelected}
          listSchedule={dataLOVScheduleGallery.data}
          handleChangeEventName={value => this.handleChangeEventName(value)}
          onSelect={value => this.onSelect(value)}
          listSession={dataLOVSessionGallery.data}
          listInstitusi={dataLOVInstansi.data}
          dataActive={dataActive.data}
          loading={dataLOVSessionGallery.status}
          loadingSubmit={loadingSubmit}
          onFinish={(value) => this.handleInputSubmit(value)}
          handleReset={() => this.handleReset()}
          handleClose={() => this.handleRegisClose()}
        />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return{
    handleDataGallery: (token, sort, size, page, search) => dispatch(getGallery.getDataGallery(token, sort, size, page, search)),
    handleLOVSchedule: (token, id) => dispatch(lov.getLOVScheduleGallery(token, id)),
    handleLOVSession: (token, idInstansi, id) => dispatch(lov.getLOVSessionGallery(token, idInstansi, id)),
    handleLOVInstansi: (token, search) => dispatch(getGallery.getDataInstitusi(token, search)),
    handleActive: (token) => dispatch(getGallery.getDataActive(token)),
    handlePostGallery: (token, body) => dispatch(getGallery.inputGallery(token, body)),
    handleDelete: (token, id) => dispatch(getGallery.deleteGallery(token, id)),
  }
}

const mapStateToProps = (state) => {
  return {
    dataGallery: state.gallery,
    dataLOVScheduleGallery: state.lovScheduleGallery,
    dataLOVSessionGallery: state.lovSessionGallery,
    dataLOVInstansi: state.geleryInstitusion,
    dataActive: state.galeryActive
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(listGallery)

