import { get, post } from 'axios'
import config from '../services/config'
const { BASE_URL_LOGIN, TIMEOUT } = config

const getListSubscriber = (token, sort, size, page, search) => {
  return get(`${BASE_URL_LOGIN}be/api/subscribersListAdmin?sort=${sort}&size=${size}&page=${page - 1}&search=${search}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.Data.map(res => {
      return {
        ...res,
      }
    })
    return {
      data: normalizeData,
      totalData: response.data.totalData,
      status: 'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const sendMailNews = (token, body) => {
  return post(`${BASE_URL_LOGIN}be/api/sendEmailSubscriber`,
  body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status: 'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const uploadBanner = (token, formData) => {
  return post(`${BASE_URL_LOGIN}be/api/upload/headerEmail`, 
     formData,
     {
        headers: {
           'Content-Type': 'multipart/form-data',
           Authorization: `Bearer ${token}`,
        },
        timeout: TIMEOUT
     }
  ).then( response => {
     return {
        data: response,
        totalData: 0,
        status: "success",
        error: ""
     }
  }).catch( error => {
     return {
        data: [],
        totalData: 0,
        status: "error",
        error
     }
  })
}

export default {
  getListSubscriber,
  sendMailNews,
  uploadBanner
}
