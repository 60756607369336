import React, { Component } from "react";
import { connect } from 'react-redux'
import {
   Breadcrumb, Card, Row, Col, Button,
   InputNumber, notification, Popconfirm
} from "antd";
import '../../global/global.css'
import daftarAcara from '../../actions/daftarAcara'
import { UIModalParticipantDetail } from '../../components'
import ImagePreviewComponent from '../../components/imagePreview'

class DaftarPersetujuanAcaraDetail extends Component {
   constructor(props) {
      super(props);
      this.state = {
         id: null,
         loyolaKuota: 0,
         generalKuota: 0,
         loading: false,
         modal: false,
         loyolaActive: true,
         page: 1,
         orderBy: '',
         size: 10,
         search: ''
      }
   }

   componentDidMount() {
      let id = this.props.location.pathname.split('/')[2];
      this.setState({ id }, () => this.props.handleDataDetail(this.props.token, id));
   }

   directToParent() {
      this.props.history.push('/daftar-persetujuan-acara');
   }

   updateKuota() {
      this.setState({
         loading: true,
      })
      let body = {
         "univEventLoyolaQuota": this.state.loyolaKuota,
         "univEventNonLoyolaQuota": this.state.generalKuota,
         "usersId": sessionStorage.getItem('userId')
      }
      this.props.handleUpdate(this.props.token, this.state.id, body).then(res => {
         if (res.payload.status !== 'error') {
            if (res.payload.data.status === 200) {
               notification.success({
                  placement: 'bottomRight',
                  message: 'Success',
                  description: res.payload.data.data.Message,
               });
               this.setState({
                  loading: false
               }, () => {
                  this.directToParent();
               })
            } else {
               notification.error({
                  placement: 'bottomRight',
                  message: 'Error',
                  description: res.payload.data.data.Message
               });
               this.setState({
                  loading: false
               })
            }
         } else {
            notification.error({
               placement: 'bottomRight',
               message: 'Error',
               description: `Update Failed`
            });
            this.setState({
               loading: false
            })
         }
      })
   }

   approvalEvent(type) {
      this.setState({
         loading: true,
         type
      })
      let body = {
         "usersId": sessionStorage.getItem('userId')
      }
      this.props[type === 'Reject' ? 'handleReject' : 'handleApprove'](this.props.token, this.state.id, body).then(res => {
         if (res.payload.status !== 'error') {
            if (res.payload.data.status === 200) {
               notification.success({
                  placement: 'bottomRight',
                  message: 'Success',
                  description: `${type} Success`,
               });
               this.setState({
                  loading: false
               }, () => {
                  this.directToParent();
               })
            } else {
               notification.error({
                  placement: 'bottomRight',
                  message: 'Error',
                  description: `${type} Failed`
               });
               this.setState({
                  loading: false
               })
            }
         } else {
            notification.error({
               placement: 'bottomRight',
               message: 'Error',
               description: `${type} Failed`
            });
            this.setState({
               loading: false
            })
         }
      })
   }

   shareEvent() {
      this.setState({
         loading: true
      })
      this.props.handleShare(this.props.token, this.state.id).then(res => {
         if (res.payload.status !== 'error') {
            if (res.payload.data.status === 200) {
               notification.success({
                  placement: 'bottomRight',
                  message: 'Success',
                  description: `Share Event Success`,
               });
               this.setState({
                  loading: false
               }, () => {
                  this.directToParent();
               })
            } else {
               notification.error({
                  placement: 'bottomRight',
                  message: 'Error',
                  description: `Share Event Failed`
               });
               this.setState({
                  loading: false
               })
            }
         } else {
            notification.error({
               placement: 'bottomRight',
               message: 'Error',
               description: `Share Event Failed`
            });
            this.setState({
               loading: false
            })
         }
      })
   }

   changeButton = (val) => {
      this.setState({
         loyolaActive: val,
         page: 1,
         orderBy: '',
         search: '',
      })
      if (val) {
         this.props.handleDataParticipantList(this.props.token, this.state.id, 1, '', '10', 1, '');
      } else {
         this.props.handleDataParticipantList(this.props.token, this.state.id, 2, '', '10', 1, '');
      }
   }

   handleTableChange = (page, filter, sorter, from) => {
      const { size, search } = this.state

      let sorterField = ""
      if (sorter.field === "univEventParticipantName") {
         sorterField = "participant_name";
      } else if (sorter.field === "univEventParticipantEmail") {
         sorterField = "u.users_email";
      } else {
         sorterField = "r.role_name";
      }

      let orderBy = "";
      if (sorter.order === "ascend") {
         orderBy = `${sorterField},asc`;
      } else if (sorter.order === "descend") {
         orderBy = `${sorterField},desc`;
      }

      this.setState({
         page: page.current,
         orderBy: orderBy
      }, () => {
         if (from === 'loyola') {
            this.props.handleDataParticipantList(this.props.token, this.state.id, 1, orderBy, size, page.current, search)
         } else {
            this.props.handleDataParticipantList(this.props.token, this.state.id, 2, orderBy, size, page.current, search)
         }
      })
   }

   handleSearch = key => {
      const { loyolaActive, page, size, orderBy } = this.state
      this.setState({
         search: key
      })
      if (loyolaActive) {
         this.props.handleDataParticipantList(this.props.token, this.state.id, 1, orderBy, size, page, key);
      } else {
         this.props.handleDataParticipantList(this.props.token, this.state.id, 2, orderBy, size, page, key);
      }
   }

   render() {
      const { dataDetail, detailStatus, loadingDetail } = this.props;
      const columnsTableLoyola = [
         {
            title: 'Nama',
            dataIndex: 'univEventParticipantName',
            key: 'univEventParticipantName',
            sorter: true,
         },
         {
            title: 'Email',
            dataIndex: 'univEventParticipantEmail',
            key: 'univEventParticipantEmail',
            sorter: true,
         },
         {
            title: 'Status',
            dataIndex: 'univEventParticipantRole',
            key: 'univEventParticipantRole',
            sorter: true,
         },
      ];

      const columnsTablePublic = [
         {
            title: 'Nama',
            dataIndex: 'univEventParticipantName',
            key: 'univEventParticipantName',
            sorter: true,
         },
         {
            title: 'Email',
            dataIndex: 'univEventParticipantEmail',
            key: 'univEventParticipantEmail',
            sorter: true,
         }
      ];

      const { dataParticipantList, isMobile } = this.props
      const {
         loyolaActive,
         page
      } = this.state
      
      return (
         <div className="root">
            <Breadcrumb className="breadcrumb_style">
               <Breadcrumb.Item>
                  <span style={{ cursor: 'pointer' }}>Universitas</span>
               </Breadcrumb.Item>
               <Breadcrumb.Item>
                  <span style={{ cursor: 'pointer' }} onClick={() => this.props.history.push(`/daftar-persetujuan-acara`)}>
                     Daftar Persetujuan Acara
                  </span>
               </Breadcrumb.Item>
               <Breadcrumb.Item className='pathNow_style'>
                  Detail Daftar Persetujuan Acara
               </Breadcrumb.Item>
            </Breadcrumb>
            {dataDetail && dataDetail.Data && detailStatus === 200 && loadingDetail === 'success' &&
               <Card
                  loading={false}
                  className="bodyCard_style"
               >
                  <Row gutter={[24, 10]}>
                     <Col span={isMobile ? 12 : 4}>
                        <ImagePreviewComponent
                          styles={{ width: isMobile ? '100%' : '90%' }}
                          isMobile={isMobile}
                          uri={dataDetail.Data.univLogoPath}
                        />
                        {/* <img 
                           src={dataDetail.Data.univLogoPath} 
                           width="90%" alt="Logo Institusi" 
                        /> */}
                     </Col>
                     <Col span={isMobile ? 24 : 20}>
                        <Row>
                           <Col span={12}>
                              <Row gutter={[0, 10]}>
                                 <Col span={24}>
                                    <span className="header_text_detail">Nama Institusi</span>
                                 </Col>
                                 <Col span={24}>
                                    <span className="text_detail_institusi">{dataDetail.Data.univName}</span>
                                 </Col>
                                 <Col span={24}>
                                    <span className="header_text_detail">Nama Acara</span>
                                 </Col>
                                 <Col span={24}>
                                    <span className="data_text_detail">{dataDetail.Data.univEventName}</span>
                                 </Col>
                                 <Col span={24}>
                                    <span className="header_text_detail">Hari / Tanggal Acara</span>
                                 </Col>
                                 <Col span={24}>
                                    <span className="data_text_detail">{dataDetail.Data.univEventDayAndDate}</span>
                                 </Col>
                                 <Col span={24}>
                                    <span className="header_text_detail">Deskripsi Acara</span>
                                 </Col>
                                 <Col span={24}>
                                    <pre className='text_detail_deskripsi_acara' style={{ whiteSpace: "pre-wrap" }}>
                                       {dataDetail.Data.univEventDesc ? dataDetail.Data.univEventDesc.replace('\\n', '\r\n') : '-'}
                                    </pre>
                                 </Col>
                              </Row>
                           </Col>
                           <Col span={12}>
                              <Row gutter={[0, 10]}>
                                 <Col span={24}>
                                    <span className="header_text_detail">Banner</span>
                                 </Col>
                                 <Col span={isMobile ? 24 : 15}>
                                    <ImagePreviewComponent
                                       styles={{ width: isMobile ? '100%' : 200 }}
                                       isMobile={isMobile}
                                       uri={dataDetail.Data.univEventImgPath}
                                    />
                                    {/* <img 
                                       src={dataDetail.Data.univEventImgPath} 
                                       style={{ width: 200 }} 
                                       alt="banner" 
                                    /> */}
                                 </Col>
                                 <Col span={24}>
                                    <span className="header_text_detail">Jam</span>
                                 </Col>
                                 <Col span={24}>
                                    <span className="data_text_detail">{dataDetail.Data.univEventTime}</span>
                                 </Col>
                                 <Col span={24}>
                                    <span className="header_text_detail">Kuota</span>
                                 </Col>
                                 <Col span={12}>
                                    <span className="data_text_detail">Loyola</span>
                                 </Col>
                                 <Col span={12} style={{ textAlign: 'right' }}>
                                    {dataDetail.Data.univEventStatus === 'New' ?
                                       <InputNumber min={0} className="input_style" onChange={(value) => this.setState({ loyolaKuota: value })} value={this.state.loyolaKuota} autoFocus />
                                       :
                                       (dataDetail.Data.univEventStatus === 'On Approval' ?
                                          <span className="data_text_detail">{dataDetail.Data.univEventLoyolaQuota}</span>
                                          : (dataDetail.Data.univEventStatus === 'Approved' ?
                                             <>
                                                <span className="data_text_detail" style={{ color: '#EF712B' }}>{dataDetail.Data.univEventLoyolaQuotaUsed}</span>
                                                <span className="data_text_detail"> / {dataDetail.Data.univEventLoyolaQuota}</span>
                                             </>
                                             : <span className="data_text_detail" style={{ color: '#EF712B' }}>-</span>
                                          )
                                       )
                                    }
                                 </Col>
                                 <Col span={12}>
                                    <span className="data_text_detail">Umum</span>
                                 </Col>
                                 <Col span={12} style={{ textAlign: 'right' }}>
                                    {dataDetail.Data.univEventStatus === 'New' ?
                                       <InputNumber min={0} className="input_style" onChange={(value) => this.setState({ generalKuota: value })} value={this.state.generalKuota} />
                                       :
                                       (dataDetail.Data.univEventStatus === 'On Approval' ?
                                          <span className="data_text_detail">{dataDetail.Data.univEventNonLoyolaQuota}</span>
                                          : (dataDetail.Data.univEventStatus === 'Approved' ?
                                             <>
                                                <span className="data_text_detail" style={{ color: '#EF712B' }}>{dataDetail.Data.univEventNonLoyolaQuotaUsed ? dataDetail.Data.univEventNonLoyolaQuotaUsed : 0}</span>
                                                <span className="data_text_detail"> / {dataDetail.Data.univEventNonLoyolaQuota}</span>
                                             </>
                                             : <span className="data_text_detail" style={{ color: '#EF712B' }}>-</span>
                                          )
                                       )
                                    }
                                 </Col>
                                 {dataDetail.Data.univEventStatus === 'Approved' &&
                                    <Col span={12} offset={12} style={{ textAlign: 'right' }}>
                                       <Button className="updateButton" onClick={() => {
                                          this.setState({ modal: true }, () => {
                                             this.props.handleDataParticipantList(this.props.token, this.state.id, 1, '', '10', 1, '');
                                          })
                                       }}>Info</Button>
                                    </Col>
                                 }
                                 <Col span={24}>
                                    <span className="header_text_detail">Setting Presentasi</span>
                                 </Col>
                                 <Col span={24}>
                                    <span className="data_text_detail">{dataDetail.Data.univEventIsOnline}</span>
                                 </Col>
                                 <Col span={24}>
                                    <span className="header_text_detail">Media</span>
                                 </Col>
                                 <Col span={24}>
                                    <span className="data_text_detail">{dataDetail.Data.univEventMedia}</span>
                                 </Col>
                                 {dataDetail.Data.univEventMediaUrl &&
                                    <Col span={24}>
                                       <a
                                          href={dataDetail.Data.univEventMediaUrl}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                       >{dataDetail.Data.univEventMediaUrl}</a>
                                    </Col>
                                 }
                                 {dataDetail.Data.univEventMediaJoinId &&
                                    <>
                                       <Col span={6}>
                                          <span className="header_text_detail">Username</span>
                                       </Col>
                                       <Col span={18}>
                                          <span className="data_text_detail">{dataDetail.Data.univEventMediaJoinId}</span>
                                       </Col>
                                    </>
                                 }
                                 {dataDetail.Data.univEventMediaPwd &&
                                    <>
                                       <Col span={6}>
                                          <span className="header_text_detail">Password</span>
                                       </Col>
                                       <Col span={18}>
                                          <span className="data_text_detail">{dataDetail.Data.univEventMediaPwd}</span>
                                       </Col>
                                    </>
                                 }
                              </Row>
                           </Col>
                        </Row>
                        <Row>
                           <Col span={22} style={{ textAlign: "right", marginTop: 40 }}>
                              {dataDetail.Data.univEventStatus === 'New' &&
                                 <>
                                    <Button className="rejectButton" onClick={() => this.setState({ loyolaKuota: 0, generalKuota: 0 })}>Reset</Button>
                                    <Popconfirm
                                       placement="bottomRight"
                                       title={(
                                          <span>
                                             Apakah anda yakin akan mengupdate Kuota tersebut ?<br></br>
                                             <strong>Kuota Loyola {this.state.loyolaKuota}<br></br></strong>
                                             <strong>Kuota Umum {this.state.generalKuota}<br></br></strong>
                                          </span>
                                       )}
                                       onConfirm={() => this.updateKuota()}
                                       okText="Yes"
                                       cancelText="No"
                                    >
                                       <Button className="updateButton" style={{ marginRight: 20 }} loading={this.state.loading}>Update</Button>
                                    </Popconfirm>
                                 </>
                              }
                              {dataDetail.Data.univEventStatus === 'Approved' &&
                                 <>
                                    <Popconfirm
                                       placement="bottomRight"
                                       title={(
                                          <span>
                                             Apakah anda yakin akan membagikan Event tersebut ?
                                          </span>
                                       )}
                                       onConfirm={() => this.shareEvent()}
                                       okText="Yes"
                                       cancelText="No"
                                    >
                                       <Button className="updateButton" loading={this.state.loading}>Share</Button>
                                    </Popconfirm>
                                 </>
                              }
                              {dataDetail.Data.univEventStatus === 'On Approval' &&
                                 <>
                                    <Popconfirm
                                       placement="bottomRight"
                                       title={(
                                          <span>
                                             Apakah anda yakin ingin menolak event tersebut ?
                                          </span>
                                       )}
                                       onConfirm={() => this.approvalEvent('Reject')}
                                       okText="Yes"
                                       cancelText="No"
                                    >
                                       <Button className="rejectButton" loading={this.state.loading && this.state.type === 'Reject'}>Reject</Button>
                                    </Popconfirm>

                                    <Popconfirm
                                       placement="bottomRight"
                                       title={(
                                          <span>
                                             Apakah anda yakin ingin menyetujui event tersebut ?
                                          </span>
                                       )}
                                       onConfirm={() => this.approvalEvent('Approve')}
                                       okText="Yes"
                                       cancelText="No"
                                    >
                                       <Button className="approveButton" loading={this.state.loading && this.state.type === 'Approve'}>Approve</Button>
                                    </Popconfirm>
                                 </>
                              }
                           </Col>
                        </Row>
                     </Col>
                  </Row>
               </Card>
            }
            <UIModalParticipantDetail
               visible={this.state.modal}
               isMobile={this.props.isMobile}
               handleBack={() => this.setState({ modal: false })}
               dataParticipantLoyola={dataParticipantList.data}
               dataParticipantPublic={dataParticipantList.data}
               columnsLoyola={columnsTableLoyola}
               columnsPublic={columnsTablePublic}
               loadingLoyola={dataParticipantList.status}
               loadingPublic={dataParticipantList.status}
               changeButton={val => this.changeButton(val)}
               loyolaActive={loyolaActive}
               totalDataLoyola={dataParticipantList.totalData}
               handleTableChangeLoyola={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter, 'loyola')}
               page={page}
               handleSearch={key => this.handleSearch(key)}
               totalDataPublic={dataParticipantList.totalData}
               handleTableChangePublic={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter, 'public')}
            />
         </div>
      )
   }
}

const mapDispatchToProps = dispatch => {
   return {
      handleDataDetail: (token, id) => dispatch(daftarAcara.getDataDetailListEventUniversity(token, id)),
      handleUpdate: (token, id, body) => dispatch(daftarAcara.updateDataEventUniversity(token, id, body)),
      handleReject: (token, id, body) => dispatch(daftarAcara.rejectDataEventUniversity(token, id, body)),
      handleApprove: (token, id, body) => dispatch(daftarAcara.approveDataEventUniversity(token, id, body)),
      handleShare: (token, id) => dispatch(daftarAcara.shareDataEventUniversity(token, id)),
      handleDataParticipantList: (token, id, group, sort, size, page, search) => dispatch(daftarAcara.getDataEventParticipant(token, id, group, sort, size, page, search)),
   }
}

const mapStateToProps = (state) => {
   return {
      loadingDetail: state.detailEventUniversity.status,
      dataDetail: state.detailEventUniversity.data.data,
      detailStatus: state.detailEventUniversity.data.status,
      loadingUpdate: state.updateEventUniversity.status,
      dataUpdate: state.updateEventUniversity.data.data,
      updateStatus: state.updateEventUniversity.data.status,
      dataParticipantList: state.getEventParticipant,
   }
};

export default connect(mapStateToProps, mapDispatchToProps)(DaftarPersetujuanAcaraDetail);