const GET_GALLERY_LOADING = 'GET_GALLERY_LOADING'
const GET_GALLERY_SUCCESS = 'GET_GALLERY_SUCCESS'
const GET_GALLERY_FAILED = 'GET_GALLERY_FAILED'
const POST_GALLERY_LOADING = 'POST_GALLERY_LOADING'
const POST_GALLERY_SUCCESS = 'POST_GALLERY_SUCCESS'
const POST_GALLERY_FAILED = 'POST_GALLERY_FAILED'
const PUT_GALLERY_LOADING = 'PUT_GALLERY_LOADING'
const PUT_GALLERY_SUCCESS = 'PUT_GALLERY_SUCCESS'
const PUT_GALLERY_FAILED = 'PUT_GALLERY_FAILED'
const GET_ACTIVE_LOADING = 'GET_ACTIVE_LOADING'
const GET_ACTIVE_SUCCESS = 'GET_ACTIVE_SUCCESS'
const GET_ACTIVE_FAILED = 'GET_ACTIVE_FAILED'
const GET_INSTITUSION_LOADING = 'GET_INSTITUSION_LOADING'
const GET_INSTITUSION_SUCCESS = 'GET_INSTITUSION_SUCCESS'
const GET_INSTITUSION_FAILED = 'GET_INSTITUSION_FAILED'

export {
  GET_GALLERY_LOADING,
  GET_GALLERY_SUCCESS,
  GET_GALLERY_FAILED,
  POST_GALLERY_LOADING,
  POST_GALLERY_SUCCESS,
  POST_GALLERY_FAILED,
  PUT_GALLERY_LOADING,
  PUT_GALLERY_SUCCESS,
  PUT_GALLERY_FAILED,
  GET_ACTIVE_LOADING,
  GET_ACTIVE_SUCCESS,
  GET_ACTIVE_FAILED,
  GET_INSTITUSION_LOADING,
  GET_INSTITUSION_SUCCESS,
  GET_INSTITUSION_FAILED,
}
