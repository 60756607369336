import types from '../constants'
import dashboardUser from "../api/dashboardUser";

const getData = (token) => dispatch => {
  dispatch({
    type: types.GET_DASHBOARD_USER_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: undefined,
      totalData: 0
    }
  })
  return dashboardUser.getData(token).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.GET_DASHBOARD_USER_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_DASHBOARD_USER_SUCCESS,
        payload: result
      })
    }
  })
}

export default {
  getData
}