import types from '../constants'
import headerFooter from "../api/headerFooter";

const getData = () => dispatch => {
  dispatch({
    type: types.GET_HEADERFOOTER_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: undefined,
      totalData: 0
    }
  })
  return headerFooter.getData().then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.GET_HEADERFOOTER_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_HEADERFOOTER_SUCCESS,
        payload: result
      })
    }
  })
}

const handleEdit = (token, userId, body) => dispatch => {
  dispatch({
    type: types.PUT_HEADERFOOTER_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return headerFooter.handleEdit(token, userId, body).then( result => {
    if (result.status === 'error') {
      return{
        type: types.PUT_HEADERFOOTER_FAILED,
        payload: result
      }
    }else {
      return {
        type: types.PUT_HEADERFOOTER_SUCCESS,
        payload: result,
      }
    }
  })
}

export default {
  getData,
  handleEdit
}