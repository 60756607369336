import types from '../constants'
import lateRegister from '../api/lateRegister'

const getDataLateRegister = (token, sort, size, page, search) => dispatch => {
  dispatch({
    type: types.GET_LATE_REGISTER_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: [],
      totalData: 0,
    }
  })
  return lateRegister.getLateRegister(token, sort, size, page, search).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_LATE_REGISTER_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_LATE_REGISTER_SUCCESS,
        payload: result
      })
    }
  })
}

const rejectLateRegister = (token, body) => dispatch => {
  dispatch({
    type: types.REJECT_LATE_REGISTER_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return lateRegister.rejectLateRegister(token, body).then( result => {
    if (result.status === 'error') {
      return{
        type: types.REJECT_LATE_REGISTER_SUCCESS,
        payload: result
      }
    }else {
      return {
        type: types.REJECT_LATE_REGISTER_FAILED,
        payload: result,
      }
    }
  })
}

export default {
  getDataLateRegister,
  rejectLateRegister,
}
