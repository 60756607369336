import types from '../constants'
import daftarAcara from '../api/daftarAcara'

const getDataListEventUniversity = (token, sort, size, page, search) => dispatch => {
  dispatch({
    type: types.GET_DAFTAR_ACARA_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return daftarAcara.getListEventUniversity(token, sort, size, page, search).then(result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_DAFTAR_ACARA_FAILED,
        payload: result
      })
    } else {
      return dispatch({
        type: types.GET_DAFTAR_ACARA_SUCCESS,
        payload: result
      })
    }
  })
}

const getDataDetailListEventUniversity = (token, id) => dispatch => {
  dispatch({
    type: types.GET_DETAIL_DAFTAR_ACARA_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return daftarAcara.getDetailEventUniversity(token, id).then(result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_DETAIL_DAFTAR_ACARA_FAILED,
        payload: result
      })
    } else {
      return dispatch({
        type: types.GET_DETAIL_DAFTAR_ACARA_SUCCESS,
        payload: result
      })
    }
  })
}

const updateDataEventUniversity = (token, id, body) => dispatch => {
  dispatch({
    type: types.UPDATE_DAFTAR_ACARA_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return daftarAcara.updateEventUniversity(token, id, body).then(result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.UPDATE_DAFTAR_ACARA_FAILED,
        payload: result
      })
    } else {
      return dispatch({
        type: types.UPDATE_DAFTAR_ACARA_SUCCESS,
        payload: result
      })
    }
  })
}

const approveDataEventUniversity = (token, id, body) => dispatch => {
  dispatch({
    type: types.APPROVE_DAFTAR_ACARA_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return daftarAcara.approveEventUniversity(token, id, body).then(result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.APPROVE_DAFTAR_ACARA_FAILED,
        payload: result
      })
    } else {
      return dispatch({
        type: types.APPROVE_DAFTAR_ACARA_SUCCESS,
        payload: result
      })
    }
  })
}

const rejectDataEventUniversity = (token, id, body) => dispatch => {
  dispatch({
    type: types.REJECT_DAFTAR_ACARA_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return daftarAcara.rejectEventUniversity(token, id, body).then(result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.REJECT_DAFTAR_ACARA_FAILED,
        payload: result
      })
    } else {
      return dispatch({
        type: types.REJECT_DAFTAR_ACARA_SUCCESS,
        payload: result
      })
    }
  })
}

const shareDataEventUniversity = (token, id) => dispatch => {
  dispatch({
    type: types.SHARE_DAFTAR_ACARA_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return daftarAcara.shareEventUniversity(token, id).then(result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.SHARE_DAFTAR_ACARA_FAILED,
        payload: result
      })
    } else {
      return dispatch({
        type: types.SHARE_DAFTAR_ACARA_SUCCESS,
        payload: result
      })
    }
  })
}

const getDataEventParticipant = (token, id, group, sort, size, page, search) => dispatch => {
  dispatch({
    type: types.GET_PARTICIPANT_DAFTAR_ACARA_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return daftarAcara.getEventParticipant(token, id, group, sort, size, page, search).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_PARTICIPANT_DAFTAR_ACARA_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_PARTICIPANT_DAFTAR_ACARA_SUCCESS,
        payload: result
      })
    }
  })
}

export default {
  getDataListEventUniversity,
  getDataDetailListEventUniversity,
  updateDataEventUniversity,
  approveDataEventUniversity,
  rejectDataEventUniversity,
  shareDataEventUniversity,
  getDataEventParticipant
}
