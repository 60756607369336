import * as approval from './approval'
import * as dashboardUser from './dashboardUser'
import * as dashboardBeasiswa from './dashboardBeasiswa'
import * as presentation from './presentation'
import * as lateRegister from  './lateRegister'
import * as lov from './lov'
import * as gallery from './gallery'
import * as manageBidiktiWeek from './manageBidiktiWeek'
import * as kerjasama from './kerjasama'
import * as homepage from './homepage'
import * as headerFooter from './headerFooter'
import * as faq from './faq'
import * as settingLifeSharing from './settingLifeSharing'
import * as impian from './impian'
import * as masterKota from './masterKota'
import * as masterProvinsi from './masterProvinsi'
import * as masterNegara from './masterNegara'
import * as masterUang from './masterUang'
import * as admin from './admin';
import * as beasiswa from './beasiswa';
import * as lifeSharing from './lifeSharing';
import * as lifeSharingGallery from './lifeSharingGallery';
import * as daftarAcaraInUniv from './daftarAcara';
import * as daftarPersetujuanGaleri from './daftarPersetujuanGaleri';
import * as daftarGaleri from './daftarGaleri';
import * as subscribe from './subscribe';
import * as profileUniversitas from './profileUniversitas';
import * as daftarPersetujuanKonten from './daftarPersetujuanKonten';
import * as dashboardUniversitas from './dashboardUniversitas';
import * as dashboardBidiktiWeek from './dashboardBidiktiWeek';
import * as logging from './logging'

export default {
  ...approval,
  ...dashboardUser,
  ...dashboardBeasiswa,
  ...presentation,
  ...lateRegister,
  ...lov,
  ...gallery,
  ...manageBidiktiWeek,
  ...kerjasama,
  ...homepage,
  ...headerFooter,
  ...faq,
  ...settingLifeSharing,
  ...impian,
  ...masterKota,
  ...masterProvinsi,
  ...masterNegara,
  ...masterUang,
  ...admin,
  ...beasiswa,
  ...lifeSharing,
  ...lifeSharingGallery,
  ...daftarAcaraInUniv,
  ...daftarPersetujuanGaleri,
  ...daftarGaleri,
  ...subscribe,
  ...profileUniversitas,
  ...daftarPersetujuanKonten,
  ...dashboardUniversitas,
  ...dashboardBidiktiWeek,
  ...logging
}
