import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import {AppAdmin} from "./App";
import * as serviceWorker from "./serviceWorker";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import reducer from "./reducers";
import thunk from "redux-thunk";
import { composeWithDevTools } from 'redux-devtools-extension';

const composeEnhancers = composeWithDevTools({ realtime: true});
export const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));

ReactDOM.render(
  // <React.StrictMode>
    <Provider store={store}>
      <AppAdmin />
    </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
