import types from '../constants'

const initialState = {
  status: '',
  data: []
}

export default function postDaftarGaleri(state = initialState, action) {
  switch (action.type) {
    case types.POST_DAFTAR_GALERI_LOADING:
      return {
        ...state,
        ...action.payload
      }
    case types.POST_DAFTAR_GALERI_SUCCESS:
      return {
        isSuccess: true,
        ...state,
        ...action.payload
      }
    case types.POST_DAFTAR_GALERI_FAILED:
      return {
        isSuccess: false,
        ...state,
        ...action.payload
      }
    default:
      return { ...initialState, ...state }
  }
}
