import React from 'react'

import {
  Modal,
  Form,
  Row,
  Col,
  Typography,
  Input,
  Select,
  Button,
} from 'antd'

import {EyeInvisibleOutlined} from '@ant-design/icons'

const {Title} = Typography
const {Option} = Select

const modalCreate = props => {

  const [form] = Form.useForm()
  if (props.visible === true) {
    form.resetFields();
    form.setFieldsValue(props.initialValues)
  }

  const handleClose = () => {
    form.resetFields();
    props.close()
  }

  const checkFinish = values => {
    props.checkFinish(values)
  }

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const handleResetForm = () => {
    form.resetFields();
    if (props.onReset) {
      props.onReset();
    }
  };

  const defaultForm = [
    {
      name: 'formDefault',
      rules: [
        {
          required: true,
          message: 'Please input form default!'
        },
      ],
      placeholder: 'Form'
    }
  ]


  const formItem = props.formItem || defaultForm

  const proseccesType = data => {
    return (
      data.type === 'LOV' ?
        <Select
          placeholder={data.placeholder}
          disabled={data.disabled}
          className="select"
          onChange={data.onChange}
          // onChange={(event) => handleChange(event)}
        >
          {
            data.dataLOV ?
              data.dataLOV.map((item, index) => (
                  <Option key={item.id + 'LOV' + index} value={item.id}>{item.name}</Option>
                )
              ) : (
                <></>
              )
          }
        </Select>
        :
        data.type === 'password' ?
          <Input.Password
            placeholder={data.placeholder}
            suffix={<EyeInvisibleOutlined
              color={data.passowrdColor}/>}
          />
          :
          data.type === 'textArea' ?
            <Input.TextArea
              maxLength={data.maxLength}
              placeholder={data.placeholder}
              size="large"
              rows={data.rowsText}
              className='input_style'
            />
            : data.type === 'input' ?
            <Input
              maxLength={data.maxLength}
              placeholder={data.placeholder}
              size="large"
              className='input_style'
            />
            : <></>
    )
  }

  return (
    <Modal
      visible={props.visible}
      footer={null}
      className={props.isMobile ? 'modalMobile' : 'modalImage'}
      width={props.isMobile ? '100%' : 633}
      centered
      onCancel={() => handleClose()}
      destroyOnClose={true}
    >
      <Row>
        <Col span={24}>
          <div className='headerForm'>
            <Title className='titleModalGalery' level={4}>{props.title}</Title>
          </div>
          <div style={{width: props.widthDivider}} className='dividerGalery'/>
        </Col>
        <Col span={24}>
          <Form
            form={form}
            onFinish={values => checkFinish(values)}
            onFinishFailed={onFinishFailed}
            initialValues={props.initialValues}
          >
            <Row gutter={[10, 0]}>
              {formItem.map((val, index) => {
                return (
                  <>
                    <Col span={val.colNumber} key={val.name + props.title + index}>
                      <Form.Item
                        // key={val.name + props.title + index}
                        name={val.name}
                        rules={val.rules}
                      >
                        {proseccesType(val)}
                      </Form.Item>
                    </Col>
                    {val.type !== 'blank'
                      ? (
                        <Col span={1}>
                          <span className="mandatoryLarge">&#8727;</span>
                        </Col>
                      )
                      : (
                        <></>
                      )
                    }
                  </>
                )
              })
              }
              <Col span={23}>
                <Form.Item
                  style={{
                    textAlign: 'right',
                    marginBottom: '0px'
                  }}
                >
                  <Button
                    type="link"
                    className='resetButton'
                    size={'small'}
                    onClick={() => handleResetForm()}
                  >
                    Reset
                  </Button>
                  <Button
                    type="primary"
                    htmlType='submit'
                    className='updateButton'
                    loading={props.loadingSubmit}
                    size={'small'}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Modal>
  )
}

export const UIModalCreate = modalCreate
