import React, { Component } from 'react'
import { connect } from 'react-redux'
import { post } from 'axios'
import config from '../../services/config'
import urlBidikti from '../../global/assets/BIDikTi.png'
import urlLoyola from '../../global/assets/logo.png'
import { Row, Col, Input, Button, notification } from 'antd'
import {
  Link
} from "react-router-dom";
import {version} from '../../../package.json'


// const { Title } = Typography
const { BASE_URL_LOGIN } = config

class LandingPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initial: '',
      username: '',
      password: '',
      loading: false,
    }
  }

  handleFinish = () => {
    const { username, password } = this.state
    this.setState({
      loading: true
    })
    post(`${BASE_URL_LOGIN}auth/login`, {
      username: username,
      password: password,
    })
    .then(res => {
      const roleGroup = res.data.data.roleGroup
      if (res.data.data.isAdmin && !res.data.data.isDeleted) {
        notification.success({
          placement: 'bottomRight',
          message: 'Success',
          description: "Berhasil Login"
        });
        if (roleGroup === "Superadmin" || roleGroup === "Admin" || roleGroup === "Admin Dashboard" ) {
          sessionStorage.setItem('adminLyl', 'loyola')
          sessionStorage.setItem('token', res.data.auth.Authorization)
          sessionStorage.setItem('userRole', res.data.data.role)
          sessionStorage.setItem('name', res.data.data.userData[0].userFullname)
          sessionStorage.setItem("userId", res.data.data.userId)
          sessionStorage.setItem('isLogin', true)
          this.props.onLogin();
          this.props.history.push('/dashboard');
        } else if (roleGroup === "Admin Universitas") {
          sessionStorage.setItem('adminLyl', 'univ')
          sessionStorage.setItem('token', res.data.auth.Authorization)
          sessionStorage.setItem('userRole', res.data.data.role)
          sessionStorage.setItem('name', res.data.data.userData[0].userFullname)
          sessionStorage.setItem('univId', res.data.data.userData[1].univId)
          sessionStorage.setItem("userId", res.data.data.userId)
          sessionStorage.setItem('logo', res.data.data.userData[0].univLogo)
          sessionStorage.setItem('isLogin', true)
          this.props.onLogin();
          this.props.history.push('/profile-universitas');
        }
      }
      else if ( !res.data.data.isAdmin ) {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: 'Mohon Maaf, akun login ini terdaftar bukan sebagai "Admin"!',
        });
      } else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: 'Username atau password anda salah',
        });
      }
      this.setState({
        loading: false
      })
    })
    .catch(error => {
      notification.error({
        placement: 'bottomRight',
        message: 'Error',
        description: error.data
          ? error.data.Message
          : 'Username atau Password salah!',
      });
      this.setState({
        loading: false
      })
    })
  }

  handleChange = (val) => {
    const temp = val.target
    this.setState({
      [temp.name]: val.target.value
    })
  }
  handleRedirectDetail = () => {
    this.props.history.push(`/ForgetPassword/`)
   }

  render() {

    const versionVal = 'Versi '+version
    return(
      <div className="container_Body">
        <Row>
          <Col span={this.props.isMobile ? 14 : 16}>
            <div className="background_left">
              <Row justify='center'>
                <Col span={24} style={{marginBottom: '25px'}} >
                  <img height={this.props.isMobile ? 50 : 100} alt="loyolaLogo" width={this.props.isMobile ? 50 : 100} src={urlLoyola} style={{marginRight: '25px'}}/>
                  <img height={this.props.isMobile ? 50 : 100} alt="BIDikTiLogo" width={this.props.isMobile ? 50 : 100} src={urlBidikti} />
                </Col>
                <Col span={24} >
                  <span className="text_login_content">Admin Page <br></br> Bursa Informasi Pendidikan Tinggi <br></br> SMA Kolese Loyola</span>
                </Col>
                <Col span={24} className='containerVersion'>
                  <span className='versionText'>
                    {versionVal}
                  </span>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={this.props.isMobile ? 10 : 8}>
            <div className="background_right">
              <Row justify='center'>
                <Col span={16} style={{ textAlign: 'center', marginBottom: '10px'}}>
                  <span className="text_login">Login</span>
                  <div className="divider_login"></div>
                </Col>
                <Col span={24} style={{ marginBottom: '15px' }}>
                  <Input
                    name='username'
                    placeholder="Username"
                    className="input_style_login"
                    onChange={val => this.handleChange(val)}
                    onPressEnter={() => this.handleFinish()}
                  />
                </Col>
                <Col span={24} style={{ marginBottom: '5px' }}>
                  <Input
                    name='password'
                    type='password'
                    placeholder="Password"
                    className="input_style_login"
                    onChange={val => this.handleChange(val)}
                    onPressEnter={() => this.handleFinish()}
                  />
                </Col>
                <Col span={24} style={{ textAlign: 'right', marginBottom: '30px'}}>
                  <Link onClick={() => this.handleRedirectDetail()} className="lupa_password_style">Lupa Password</Link>
                </Col>
                <Col span={24} style={{ textAlign: 'center'}}>
                  <Button
                    className="updateButton"
                    onClick={() => this.handleFinish()}
                    loading={this.state.loading}
                  >
                    Login
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return{
    // handleDataHome: () => dispatch(getHome.getDataHome())
  }
}

const mapStateToProps = (state) => {
  return {
    // dataHome: state.home,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage)
