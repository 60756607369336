import React, {Component} from 'react';
import {connect} from 'react-redux';
import '../../../global/global.css';
import lifeSharingGallery from '../../../actions/lifeSharingGallery';
import {
  Card, Button, Row, Col, Breadcrumb,
  Typography, notification, Popconfirm
} from 'antd'
import {UItable, UISearch, UIModalGallery} from '../../../components';
import lov from "../../../actions/lov";

const {Text} = Typography

class LifeSharingGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      param: {
        search: "",
        sort: "",
        size: 10,
        page: 1,
        token: sessionStorage.getItem("token"),
      },
      visible: false,
      loadingDelete : false,
    }
  }

  componentDidMount() {
    this.props.handleDataLifeSharingGallery(this.state.param);
    this.props.handleLOVLifeSharingType(this.state.param.token);
  }

  handleRedirectDetail = id => {
    this.props.history.push(`/life-sharing-gallery-detail/${id}`)
  }

  handleSearch = key => {
    const {param} = this.state;
    this.setState({
      param: {
        ...param,
        search: key,
        page: 1
      }
    });
    this.props.handleDataLifeSharingGallery({...param, search: key, page: 1});
  }

  handleClose = () => {
    this.setState({visible: false})
  }

  openModal = () => {
    this.setState({
      visible: true
    })
  }

  handleTableChange = (page, filter, sorter) => {
    const {param} = this.state;

    let sorterField = ""
    if (sorter.field === "sharingEventSpeaker") {
      sorterField = "sharing_event_speaker";
    } else if (sorter.field === "sharingTypeName") {
      sorterField = "sharing_type_name";
    } else if (sorter.field === "sharingEventDay") {
      sorterField = "sharing_event_day";
    } else if (sorter.field === "sharingEventDate") {
      sorterField = "sharing_event_date";
    } else {
      sorterField = "is_online";
    }

    let sortByValue = "";
    if (sorter.order === "ascend") {
      sortByValue = `${sorterField},asc`;
    } else if (sorter.order === "descend") {
      sortByValue = `${sorterField},desc`;
    }

    this.setState({
      param: {
        ...param,
        page: page.current,
        sort: sortByValue
      }
    });

    this.props.handleDataLifeSharingGallery({...param, page: page.current, sort: sortByValue});
  }

  onChangeCategoryLifeSharing = value => {
    this.setState({sharingTypeId: value}, () => {
      this.props.handleLOVLifeSharingSpeaker(this.state.param.token, value);
    })
  }

  onChangeInterviewees = value => {
    const {sharingTypeId} = this.state;
    const param = {
      sharingTypeId,
      speaker: value
    };
    this.setState({speaker: value}, () => {
      this.props.handleLOVLifeSharingDate(this.state.param.token, param);
    });
  }

  onChangeDayDatePresentation = value => {
    const {sharingTypeId, speaker} = this.state;
    const param = {
      sharingTypeId,
      speaker,
      eventDate: value
    };
    this.setState({eventDate: value}, () => {
      this.props.handleLOVLifeSharingTime(this.state.param.token, param);
    })
  }

  handleCreateLifeSharingGallery = (token, body) => {
    this.props.handleCreateLifeSharingGallery(token, body).then(res => {
      if (res.payload.data.status === 200) {
        notification.success({
          placement: 'bottomRight',
          message: 'Success',
          description: "Berhasil Upload Life Sharing Gallery.",
        });
        this.setState({visible: false}, () => {
          this.props.handleDataLifeSharingGallery(this.state.param);
        })
      } else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: res.payload.error.response.data.message ? res.payload.error.response.data.message : "Gagal Upload Life Sharing Gallery.",
        });
      }
    })
  }

  onFinish = values => {
    const body = {
      sharingEventId: values.eventId,
      usersId: this.props.userId,
      sharingGalleryUrl: values.linkYoutube
    };
    this.handleCreateLifeSharingGallery(this.state.param.token, body);
  }

  confirm = (record) => {
    this.handleDelete(record);
  }

  cancel = () => {
  }

  handleDelete = (id) => {
    this.setState({
      loadingDelete: true,
    })
    let body = {
      userId: sessionStorage.getItem('userId')
    }
    this.props.handleDeleteLifeSharingGallery(this.props.token, body, id).then(res => {
      if (res.payload.status !== 'error') {
        if (res.payload.data.status === 200) {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Delete Gallery Success',
          });
          setTimeout(() => {
            this.props.history.push(`/life-sharing-Gallery`)
          },2000);
          this.setState({
            loadingDelete: false,
          }, () => {
            this.props.handleDataLifeSharingGallery(this.state.param);
          })
        }else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Delete Gallery Failed'
          });
          this.setState({
            loadingDelete: false,
          })
        }
      }else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: res.payload.error.response.data.message ? res.payload.error.response.data.message : 'Delete Gallery Failed'
        });
        this.setState({
          loadingDelete: false,
        })
      }
    })
  }

  render() {
    const {
      dataLifeSharingGallery, dataLOVLifeSharingType, dataLOVLifeSharingSpeaker,
      dataLOVLifeSharingDate, dataLOVLifeSharingTime, dataCreateLifeSharingGallery
    } = this.props;
    const {param, visible} = this.state;
    const columnsTable = [
      {
        title: 'Narasumber',
        dataIndex: 'sharingEventSpeaker',
        key: 'sharingEventSpeaker',
        sorter: true,
        render: name => <Text style={{color: '#EF712B', cursor: 'pointer', fontWeight: 600}}>{name}</Text>,
        onCell: record => {
          return {
            onClick: () => {
              this.handleRedirectDetail(record.id)
            }
          }
        }
      },
      {
        title: 'Kategori',
        dataIndex: 'sharingTypeName',
        key: 'sharingTypeName',
        sorter: true
      },
      {
        title: 'Hari',
        dataIndex: 'sharingEventDay',
        key: 'sharingEventDay',
        sorter: true
      },
      {
        title: 'Tanggal',
        dataIndex: 'sharingEventDate',
        key: 'sharingEventDate',
        sorter: true
      },
      {
        title: 'Presentasi',
        dataIndex: 'isOnline',
        key: 'isOnline',
        sorter: true,
        render: isOnline => {
          if (isOnline) {
            return (
              <Text style={{color: '#EF712B', cursor: 'pointer', fontWeight: 600}}>Online</Text>
            )
          } else {
            return (
              <Text style={{color: '#11174C', cursor: 'pointer', fontWeight: 600}}>Offline</Text>
            )
          }
        }
      },
      {
        title: 'Action',
        dataIndex: '',
        key: '',
        render: record =>
          <Popconfirm
            title="Are you sure delete this?"
            onConfirm={() => this.confirm(record.id ? record.id : '')}
            onCancel={() => this.cancel()}
            okText="Yes"
            cancelText="No"
          >
            <Button
              loading={this.state.loadingDelete}
              type='link'
              style={{color: '#e22837', cursor: 'pointer', fontWeight: 600}}
            >
              Delete
            </Button>
          </Popconfirm>
      },
    ];

    return (
      <div className='root'>
        <Breadcrumb className="breadcrumb_style">
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/dashboard`)}>Life Sharing </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className='pathNow_style'>
            Daftar Galeri
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card className='bodyCard_style'>
          <Row>
            <Col span={8}>
              <UISearch placeholder='Search' handleSearch={key => this.handleSearch(key)}/>
            </Col>
            <Col span={16} style={{textAlign: 'right'}}>
              <Button onClick={() => this.openModal()} className='updateButton'>Tambah Galeri</Button>
            </Col>
          </Row>
          <Card
            bordered={false}
            loading={dataLifeSharingGallery.loading}
            style={{minHeight: '300px'}}>
            <UItable
              rowSelection={false}
              dataTable={dataLifeSharingGallery.data}
              columns={columnsTable}
              withRows={false}
              isMobile={this.props.isMobile}
              totalData={dataLifeSharingGallery.totalData}
              loading={dataLifeSharingGallery.status}
              page={param.page}
              handleTableChange={this.handleTableChange}
            />
          </Card>
        </Card>
        <UIModalGallery
          visibleInput={visible}
          isMobile={this.props.isMobile}
          galleryOf={'lifeSharing'}
          title={'Tambah Galeri'}
          handleClose={() => this.handleClose()}
          lovCategoryLifeSharing={dataLOVLifeSharingType.data}
          onChangeCategoryLifeSharing={this.onChangeCategoryLifeSharing}
          lovInterviewees={dataLOVLifeSharingSpeaker.data}
          onChangeInterviewees={this.onChangeInterviewees}
          lovDayDatePresentation={dataLOVLifeSharingDate.data}
          onChangeDayDatePresentation={this.onChangeDayDatePresentation}
          lovTime={dataLOVLifeSharingTime.data}
          onFinish={this.onFinish}
          loadingSubmit={dataCreateLifeSharingGallery.status === "loading"}
        />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleDataLifeSharingGallery: param => dispatch(lifeSharingGallery.getData(param)),
    handleLOVLifeSharingType: token => dispatch(lov.getLOVLifeSharingType(token)),
    handleLOVLifeSharingSpeaker: (token, typeId) => dispatch(lov.getLOVLifeSharingSpeaker(token, typeId)),
    handleLOVLifeSharingDate: (token, param) => dispatch(lov.getLOVLifeSharingDate(token, param)),
    handleLOVLifeSharingTime: (token, param) => dispatch(lov.getLOVLifeSharingTime(token, param)),
    handleCreateLifeSharingGallery: (token, body) => dispatch(lifeSharingGallery.postLifeSharingGallery(token, body)),
    handleDeleteLifeSharingGallery: (token, body, id) => dispatch(lifeSharingGallery.eraseLifeSharingGallery(token, body, id)),
  }
}

const mapStateToProps = state => {
  return {
    dataLifeSharingGallery: state.lifeSharingGallery,
    dataLOVLifeSharingType: state.lovLifeSharingType,
    dataLOVLifeSharingSpeaker: state.lovLifeSharingSpeaker,
    dataLOVLifeSharingDate: state.lovLifeSharingDate,
    dataLOVLifeSharingTime: state.lovLifeSharingTime,
    dataCreateLifeSharingGallery: state.postLifeSharingGallery
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LifeSharingGallery)
