import types from '../constants'
import lov from '../api/lov'

const getLOVSchedule = (token) => dispatch => {
  dispatch({
    type: types.GET_SCHEDULE_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return lov.getDataSchedule(token).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_SCHEDULE_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_SCHEDULE_SUCCESS,
        payload: result
      })
    }
  })
}

const getLOVScheduleGallery = (token, id) => dispatch => {
  dispatch({
    type: types.GET_SCHEDULE_GALLERY_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return lov.getDataScheduleGallery(token, id).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_SCHEDULE_GALLERY_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_SCHEDULE_GALLERY_SUCCESS,
        payload: result
      })
    }
  })
}

const getLOVSession = (token, id) => dispatch => {
  dispatch({
    type: types.GET_SESSION_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return lov.getDataSession(token, id).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_SESSION_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_SESSION_SUCCESS,
        payload: result
      })
    }
  })
}

const getLOVSessionGallery = (token,idInstansi, id) => dispatch => {
  dispatch({
    type: types.GET_SESSION_GALLERY_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return lov.getDataSessionGallery(token,idInstansi, id).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_SESSION_GALLERY_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_SESSION_GALLERY_SUCCESS,
        payload: result
      })
    }
  })
}

const getLOVInstansi = (token, search, size, isKedutaan) => dispatch => {
  dispatch({
    type: types.GET_INSTANSI_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return lov.getDataInstansi(token, search, size, isKedutaan).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_INSTANSI_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_INSTANSI_SUCCESS,
        payload: result
      })
    }
  })
}

const getDataLOVInstansi = (token, search, isKedutaan) => dispatch => {
  dispatch({
    type: types.GET_LOV_INSTANSI_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return lov.getDataLOVInstansi(token, search, isKedutaan).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_LOV_INSTANSI_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_LOV_INSTANSI_SUCCESS,
        payload: result
      })
    }
  })
}

const getLOVFAQCat = (token) => dispatch => {
  dispatch({
    type: types.GET_FAQCAT_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return lov.getLOVFAQCat(token).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_FAQCAT_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_FAQCAT_SUCCESS,
        payload: result
      })
    }
  })
}

const getLOVCountry = () => dispatch => {
  dispatch({
    type: types.GET_COUNTRY_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return lov.getLOVCountry().then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_COUNTRY_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_COUNTRY_SUCCESS,
        payload: result
      })
    }
  })
}

const getLOVProvince = (id) => dispatch => {
  dispatch({
    type: types.GET_PROVINCE_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return lov.getLOVProvince(id).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_PROVINCE_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_PROVINCE_SUCCESS,
        payload: result
      })
    }
  })
}


const getLOVCity = (id) => dispatch => {
  dispatch({
    type: types.GET_CITY_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return lov.getLOVCity(id).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_CITY_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_CITY_SUCCESS,
        payload: result
      })
    }
  })
}

const getLOVLifeSharingCategory = (token) => dispatch => {
  dispatch({
    type: types.GET_LIFE_SHARING_CAT_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return lov.getLOVLifeSharingCategory(token).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_LIFE_SHARING_CAT_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_LIFE_SHARING_CAT_SUCCESS,
        payload: result
      })
    }
  })
}

const getLOVLifeSharingType = (token) => dispatch => {
  dispatch({
    type: types.GET_LOV_LIFE_SHARING_TYPE_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return lov.getLOVLifeSharingType(token).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_LOV_LIFE_SHARING_TYPE_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_LOV_LIFE_SHARING_TYPE_SUCCESS,
        payload: result
      })
    }
  })
}

const getLOVLifeSharingSpeaker = (token, typeId) => dispatch => {
  dispatch({
    type: types.GET_LOV_LIFE_SHARING_SPEAKER_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return lov.getLOVLifeSharingSpeaker(token, typeId).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_LOV_LIFE_SHARING_SPEAKER_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_LOV_LIFE_SHARING_SPEAKER_SUCCESS,
        payload: result
      })
    }
  })
}

const getLOVLifeSharingDate = (token, param) => dispatch => {
  dispatch({
    type: types.GET_LOV_LIFE_SHARING_DATE_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return lov.getLOVLifeSharingDate(token, param).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_LOV_LIFE_SHARING_DATE_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_LOV_LIFE_SHARING_DATE_SUCCESS,
        payload: result
      })
    }
  })
}

const getLOVLifeSharingTime = (token, param) => dispatch => {
  dispatch({
    type: types.GET_LOV_LIFE_SHARING_TIME_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return lov.getLOVLifeSharingTime(token, param).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_LOV_LIFE_SHARING_TIME_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_LOV_LIFE_SHARING_TIME_SUCCESS,
        payload: result
      })
    }
  })
}

const getLOVEventNameByUniv = (token, univId, search) => dispatch => {
  dispatch({
    type: types.GET_LOV_EVENT_NAME_BY_UNIV_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return lov.getLOVEventNameByUniv(token, univId, search).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_LOV_EVENT_NAME_BY_UNIV_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_LOV_EVENT_NAME_BY_UNIV_SUCCESS,
        payload: result
      })
    }
  })
}

const getLOVDateByEvent = (token, univEventId) => dispatch => {
  dispatch({
    type: types.GET_LOV_DATE_BY_EVENT_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return lov.getLOVDateByEvent(token, univEventId).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_LOV_DATE_BY_EVENT_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_LOV_DATE_BY_EVENT_SUCCESS,
        payload: result
      })
    }
  })
}

const getLOVUniversitasCategori = (token) => dispatch => {
  dispatch({
    type: types.GET_LOV_UNIVERSITAS_CATEGORI_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return lov.getLOVUniversitasCategori(token).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_LOV_UNIVERSITAS_CATEGORI_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_LOV_UNIVERSITAS_CATEGORI_SUCCESS,
        payload: result
      })
    }
  })
}


export default {
  getLOVSession,
  getLOVSchedule,
  getLOVInstansi,
  getLOVFAQCat,
  getLOVCountry,
  getLOVProvince,
  getLOVCity,
  getLOVLifeSharingCategory,
  getLOVSessionGallery,
  getLOVScheduleGallery,
  getDataLOVInstansi,
  getLOVLifeSharingType,
  getLOVLifeSharingSpeaker,
  getLOVLifeSharingDate,
  getLOVLifeSharingTime,
  getLOVEventNameByUniv,
  getLOVDateByEvent,
  getLOVUniversitasCategori,
}
