import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../global/global.css'
import subscribe from '../../actions/subscribe'
import {
  Card,
  Row,
  Col,
  Breadcrumb,
  Typography,
  Button,
  notification
} from 'antd'
import { UISearch, UItable, UIModalSendNews } from '../../components'

const { Text } = Typography

class Subscriber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initial: '',
      selectedRowKeys: [],
      orderBy: '',
      size: 10,
      page: 1,
      search: '',
      modal: false,
      loading: false
    }
  }

  componentDidMount() {
    const { orderBy, size, page, search } = this.state
    this.props.handleDataListSubscriber(this.props.token, orderBy, size, page, search)
  }

  handleTableChange = (page, filter, sorter) => {
    const { size, search } = this.state

    let sorterField = "";
    if (sorter.field === "bwSubscribeName") {
      sorterField = "bw_subscribe_name";
    } else if (sorter.field === "bwSubscribeEmail") {
      sorterField = "bw_subscribe_email";
    } else if (sorter.field === "isSubscribe") {
      sorterField = "status_subscribe";
    }

    let orderBy = "";
    if (sorter.order === "ascend") {
      orderBy = `${sorterField},asc`;
    } else if (sorter.order === "descend") {
      orderBy = `${sorterField},desc`;
    }

    this.setState({
      page: page.current,
      orderBy: orderBy
    }, () => {
      this.props.handleDataListSubscriber(this.props.token, orderBy, size, page.current, search)
    })
  }

  handleSearch = key => {
    const { orderBy, size } = this.state
    this.setState({
      search: key,
      page: 1
    })
    this.props.handleDataListSubscriber(this.props.token, orderBy, size, 1, key)
  }

  handleSendDataMailNews = (token, body) => {
    const { orderBy, size, page, search } = this.state;
    this.props.handleSendDataMailNews(token, body).then( res => {
      if ( 
        res.payload &&
        res.payload.data &&
        res.payload.data.data &&
        res.payload.data.data.Status ?
        res.payload.data.data.Status === "OK" : false
      ) {
        notification.success({
          placement: 'bottomRight',
          message: 'Success',
          description: 
            res.payload &&
            res.payload.data &&
            res.payload.data.data &&
            res.payload.data.data.Message ?
            res.payload.data.data.Message : 'Berhasil mengirim email',
        });
        this.setState({ modal: false}, () => {
          this.props.handleDataListSubscriber(this.props.token, orderBy, size, page, search);
        });
      } else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: 
            res.payload &&
            res.payload.data &&
            res.payload.data.data &&
            res.payload.data.data.Message ?
            res.payload.data.data.Message : 'Gagal mengirim email ke subscriber',
        });
      }
    });
  }

  handleSendNews = values => {
    this.setState({ loading: true }, () => {
      this.handleSendDataMailNews(this.props.token, values);
    })
  }

  render() {
    const columnsTable = [
      {
        title: 'Nama',
        dataIndex: 'bwSubscribeName',
        key: 'bwSubscribeName',
        sorter: true,
        render: bwSubscribeName => <Text style={{ color: '#EF712B', fontWeight: 600 }}>{bwSubscribeName}</Text>,
      },
      {
        title: 'Email',
        dataIndex: 'bwSubscribeEmail',
        key: 'bwSubscribeEmail',
        sorter: true,
      },
      {
        title: 'Status Subscribe',
        dataIndex: 'isSubscribe',
        key: 'isSubscribe',
        sorter: true,
        render: isSubscribe => <Text style={isSubscribe === 'active' ? { color: '#EF712B', fontWeight: 600 } : { color: '#11184c', fontWeight: 600 }}>{isSubscribe.toUpperCase()}</Text>,
      },
    ]
    const { listDataSubscriber, dataMailNews } = this.props
    const { page } = this.state
    return (
      <div className='root'>
        <Breadcrumb className="breadcrumb_style">
          <Breadcrumb.Item>
            <span style={{ cursor: 'pointer' }} onClick={() => this.props.history.push(`/dashboard`)}>BIDikTi Week </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className='pathNow_style'>
            Subscriber
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card className='bodyCard_style'>
          <Row>
            <Col span={8}>
              <UISearch placeholder='Search' handleSearch={key => this.handleSearch(key)} />
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button className="updateButton" onClick={() => this.setState({ modal: true })}>Kirim Berita</Button>
            </Col>
          </Row>
          <Card bordered={false} style={{ minHeight: '300px' }}>
            <UItable
              // rowSelection={rowSelection}
              dataTable={listDataSubscriber.data}
              columns={columnsTable}
              withRows={false}
              isMobile={this.props.isMobile}
              loading={listDataSubscriber.status}
              page={page}
              totalData={listDataSubscriber.totalData}
              handleTableChange={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter)}
            />
          </Card>
        </Card>
        <UIModalSendNews
          visibleModal={this.state.modal}
          isMobile={this.props.isMobile}
          title={'Kirim Berita BIDikTi Week'}
          loading={dataMailNews.status === "loading"}
          onFinish={(value) => this.handleSendNews(value)}
          close={() => this.setState({ modal: false })}
          tokenUser={this.props.token}
        />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleDataListSubscriber: (token, sort, size, page, search) => dispatch(subscribe.getDataListSubscriber(token, sort, size, page, search)),
    handleSendDataMailNews: (token, body) => dispatch(subscribe.sendDataMailNews(token, body)),
  }
}

const mapStateToProps = (state) => {
  return {
    listDataSubscriber: state.getListSubscriber,
    dataMailNews: state.sendMailNews,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscriber)
