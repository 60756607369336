import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../global/global.css'
import getAdminApproval from '../../../actions/manageAdmin';
import {
  Card,
  Row,
  Col,
  Breadcrumb,
  Typography
} from 'antd'
import { UItable, UISearch, UIModalCreateAdmin } from '../../../components'

const { Text } = Typography

class ApprovalAdminUniv extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initial: '',
      orderBy: 'univ_approval_status',
      size: 10,
      page: 1,
      search: '',
      visibleRegis: false,
      loadingSubmit: false,
      loading: false,
    }
  }

  componentDidMount() {
    const {orderBy, size, page, search } = this.state
    this.props.handleDataAdminApproval(this.props.token, orderBy, size, page, search)
  }

  handleRedirectDetail = id => {
    this.props.history.push(`/approval-admin-univ-detail/${id}`)
  }

  handleSearch = key => {
    const {orderBy, size} = this.state
    this.setState({
      search: key,
      page: 1
    })
    this.props.handleDataAdminApproval(this.props.token, orderBy, size,1, key)
  }

  handleRegisClose = () => {
    this.setState({
      visibleRegis: false
    })
  }

  openModal = () => {
    this.setState({
      visibleRegis: true
    })
  }

  handleTableChange = (page, filter, sorter) => {
    const { size, search} = this.state

    let sorterField = ""
    if ( sorter.field === "univName" ) {
      sorterField = "univ_name";
    }else if ( sorter.field === "univEmail" ) {
      sorterField = "univ_email";
    }else if ( sorter.field === "univWebsite" ) {
      sorterField = "univ_website";
    } else {
      sorterField = "univ_approval_status";
    }

    let orderBy = "univ_approval_status";
    if (sorter.order === "ascend" ) {
      orderBy = `${sorterField},asc`;
    } else if (sorter.order === "descend") {
      orderBy = `${sorterField},desc`;
    }
    this.setState({
      page: page.current,
      orderBy: orderBy
    }, ()=> {
      this.props.handleDataAdminApproval(this.props.token, orderBy, size, page.current, search)
    })
  }

  onSearch = (e) => {
    // this.props.handleLOVInstansi(this.props.token, e, 10, this.state.isKedutaan)
  }

  render() {

    const columnsTable = [
      {
        title: 'Universitas',
        dataIndex: 'univName',
        key: 'univName',
        ellipsis: true,
        sorter: true,
        render: name => <Text style={{ color: '#EF712B', cursor: 'pointer', fontWeight: 600}}>{name}</Text>,
        onCell: record => {
          return{
            onClick: () => {
              this.handleRedirectDetail(record.univId ? record.univId : '')
            }
          }
        }
      },
      {
        title: 'Email',
        dataIndex: 'univEmail',
        key: 'univEmail',
        sorter: true,
        onCell: record => {
          return{
            onClick: () => {
              this.handleRedirectDetail(record.univId ? record.univId : '')
            }
          }
        }
      },
      {
        title: 'Website Universitas',
        dataIndex: 'univWebsite',
        key: 'univWebsite',
        sorter: true,
        onCell: record => {
          return{
            onClick: () => {
              this.handleRedirectDetail(record.univId ? record.univId : '')
            }
          }
        }
      },
      {
        title: 'Status',
        dataIndex: 'univApprovalStatus',
        key: 'univApprovalStatus',
        sorter: true,
        render: status => {
          if (status === 'On Approval') {
            return (
              <Text style={{color: '#2094E9', fontWeight: 600}}>{status}</Text>
            )
          } else if (status === 'New') {
            return (
              <Text style={{color: '#EF712B', fontWeight: 600}}>{status}</Text>
            )
          } else {
            return (
              <Text style={{color: '#E22828', fontWeight: 600}}>{status}</Text>
            )
          }
        },
        onCell: record => {
          return{
            onClick: () => {
              this.handleRedirectDetail(record.univId ? record.univId : '')
            }
          }
        }
      },
    ]
    const {
      dataAdminApproval,
    } = this.props

    const {
      visibleRegis,
      page,
      loadingSubmit
    } = this.state

    return (
      <div className='root'>
        <Breadcrumb className="breadcrumb_style">
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/dashboard`)}>Kelola Admin </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className='pathNow_style'>
            Persetujuan Admin Universitas
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card className='bodyCard_style'>
          <Row>
            <Col span={8}>
              <UISearch placeholder='Search' handleSearch={key => this.handleSearch(key)} />
            </Col>
          </Row>
          <Card
            bordered={false}
            loading={dataAdminApproval.loading}
            style={{minHeight: '300px'}}>
            <UItable
              dataTable={dataAdminApproval.data}
              columns={columnsTable}
              withRows={false}
              isMobile={this.props.isMobile}
              totalData={dataAdminApproval.totalData}
              loading={dataAdminApproval.status}
              page={page}
              handleTableChange={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter)}
            />
          </Card>
        </Card>
        <UIModalCreateAdmin
          visibleRegis={visibleRegis}
          isMobile={this.props.isMobile}
          title={'Create User Admin Loyola'}
          radioChange={val => this.radioChange(val)}
          onSearch={(e) => this.onSearch(e)}
          loading={false}
          loadingSubmit={loadingSubmit}
          close={() => this.handleRegisClose()}
        />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return{
    handleDataAdminApproval: (token, sort, size, page, search) => dispatch(getAdminApproval.getDataAdminApproval(token, sort, size, page, search)),
  }
}

const mapStateToProps = (state) => {
  return {
    dataAdminApproval: state.manageAdminApproval,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalAdminUniv)
