import React from 'react'
import {Row, Col, Modal, Card, Empty, Button} from 'antd'
import {CloseOutlined} from "@ant-design/icons";
import '../../global/global.css'

const ModalListEntranceCore = props => {
  const dataEntrance = props.dataEntrance;
  return (
    <Modal
      visible={props.visible}
      width={props.isMobile ? '100%' : "40%"}
      bodyStyle={{padding: 0}}
      className={props.isMobile ? 'modalMobile' : 'modalImage'}
      centered
      onCancel={() => props.handleBack()}
      footer={null}
      closable
      maskClosable
      style={{borderRadius: '10px'}}
      closeIcon={<CloseOutlined style={{color: '#565353'}}/>}
    >
      <div className="head_modal_center">
        <span className='title_list_entrance'>List Jalur Masuk</span>
        <div className='dividerModal'/>
      </div>
      <Card bordered={false} style={{minHeight: '300px'}}>
        {dataEntrance.data.length > 0 ? dataEntrance.data.map(res => {
          return (
            <Card className='list_entrance_card'>
              <Row>
                <Col span={10}>
                  <span className='data_text_detail'>{res.univEntName}</span>
                </Col>
                { res.univEntPath
                  ? res.univEntPath.includes('/null')
                    ? (<></>)
                    : (
                      <Col span={14} style={{ textAlign: 'right'}}>
                        <Button
                          onClick={() => window.open(res.univEntPath)}
                          type="primary"
                          className='buttonUploadForm'
                          // loading={loadingUpload}
                        >
                          Lihat Form
                        </Button>
                      </Col>
                    )
                  : (<></>)}
              </Row>
              <br/>
              <span className='text_detail_institusi'>
                <a
                  target="_blank"
                  href={`${res.univEntUrl}`}
                  rel="noopener noreferrer"
                  className="linkUrl"
                >
                  {res.univEntUrl ? res.univEntUrl : '-'}
                </a>
              </span>
              <br/>
              <span className='header_text_detail'>
                {res.univReceiveUpload=== true
                ? `Terima Upload Form dari Calon Mahasiswa`
                  : `Tidak Menerima Upload Form dari Calon Mahasiswa`}
              </span>
            </Card>
          )
        }) : (
          <Card className='list_entrance_card'>
            <Empty/>
          </Card>
        )}
      </Card>
    </Modal>
  )
}

export const UIModalListEntrance = ModalListEntranceCore;
