import { get, post, put } from 'axios'
import config from '../services/config'
import moment from 'moment'
const { BASE_URL_LOGIN, TIMEOUT } = config

const getGallery = (token, sort, size, page, search) => {
  return get(`${BASE_URL_LOGIN}be/api/bwGalleryListAdmin?sort=${sort}&size=${size}&page=${page - 1}&search=${search}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.Data.map(res =>{
      return {
        bwScheduleDate: moment(res.bwScheduleDate).format('YYYY-MM-DD'),
        bwSession: res.bwSession,
        bwGalleryId: res.bwGalleryId,
        id: res.bwGalleryId,
        bwNarasumber: res.bwNarasumber,
        bwInstitution: res.bwInstitution,
        bwGalleryUrl: res.bwGalleryUrl,
        status: res.status,
      }
    })
    return {
      data: normalizeData,
      totalData: response.data.totalData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      totalData: 0,
      error,
      data: []
    }
  })
}

const getActiveAdmin = (token) => {
  return get(`${BASE_URL_LOGIN}be/api/bwActiveAdmin`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = {
      bwId: response.data.Data.bwId,
      bwThemeEng: response.data.Data.bwThemeEng,
      bwThemeInd: response.data.Data.bwThemeInd,
    }
    return {
      data: normalizeData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      totalData: 0,
      error,
      data: []
    }
  })
}

const getInstitutionAdmin = (token, search) => {
  return get(`${BASE_URL_LOGIN}be/api/bwInstitutionAdmin?search=${search}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.Data.map(res =>{
      return {
        bwUnivId: res.univRegId,
        id: res.univRegId,
        name: res.univRegName,
      }
    })
    return {
      data: normalizeData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      totalData: 0,
      error,
      data: []
    }
  })
}

const postGallery = (token, body) => {
  return post(`${BASE_URL_LOGIN}be/api/bwGalleryAdmin`,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}


const putDeleteGallery = (token, body) => {
  // let url = `https://demo5436246.mockable.io/listApapun`
  let url = `${BASE_URL_LOGIN}be/api/deleteBwGalleryAdmin?bwGalleryId=${body.id}&usersId=${body.userId}`
  return put(url,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

export default {
  getGallery,
  getActiveAdmin,
  getInstitutionAdmin,
  postGallery,
  putDeleteGallery
}
