import React, { useState, useEffect } from 'react'
import { Table, Row, Col } from 'antd'

const TableCore = (props) => {
  const [dafaultRow, setDefaultRow] = useState(props.rowSelect)
  const [isMobile, setIsMobile] = useState(props.isMobile)

  useEffect(() => {
    setDefaultRow(props.rowSelect);
  }, [props.rowSelect]);
  const defaultColums = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'name',
      dataIndex: 'name',
      key: 'name'
    },
  ]

  useEffect(() => {
    setIsMobile(props.isMobile)
  }, [props.isMobile])

  const handleTableChange = (page, filters, sorter) => {
    props.handleTableChange(page, filters, sorter)
  }

  const onSelectChange = selectedRowKeys => {
    props.checkedBox(selectedRowKeys)
    setDefaultRow(selectedRowKeys)
  };
  const dataTable = props.dataTable || []
  const pagination = {
    defaultCurrent: 1,
    current: props.page,
    pageSize: 10,
    showSizeChanger: false,
    size: 'small',
    total: props.totalData
  }
  // const className = props.className || ''
  const columns = props.columns || defaultColums
  const loading = props.loading === 'loading' ? true : false
  const rowSelection = {
    selectedRowKeys: dafaultRow,
    onChange: onSelectChange,
    hideSelectAll: true
  };

  const withRows = props.withRows === false ? props.withRows : true
  const scroll = isMobile ? { x: 800} : false;
  return (
    <Row>
      <Col span={24} style={{ overflow: 'auto' }}>
        {withRows ? (
          <Table
            dataSource={dataTable}
            columns={columns}
            loading={loading}
            pagination={pagination}
            rowSelection={rowSelection}
            rowKey={record => record.id}
            onChange={(page, filters, sorter) => handleTableChange(page, filters, sorter)}
            scroll={scroll}
          />
        ) : (
            <Table
              dataSource={dataTable}
              columns={columns}
              loading={loading}
              pagination={pagination}
              rowKey={record => record.id}
              onChange={(page, filters, sorter) => handleTableChange(page, filters, sorter)}
              scroll={scroll}
            />
          )
        }
      </Col>
    </Row>
  )
}

export const UItable = TableCore
