import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Typography } from 'antd'
import { UIUniversitasDashboard, UIBidiktiWeekDashboard } from '../../components';
import UserDashboard from './user'
import BeasiswaDashboard from './beasiswa'

const { Title } = Typography

class Home extends Component {
  componentDidMount() {
  }


  render() {
    return (
      <div style={{ marginBottom: 30 }}>
        <Row justify='center'>
          <Col span={20}>
            <Row gutter={16} className="home_title">
              <Col span={24}>
                <Title>Dashboard</Title>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={ this.props.isMobile ? [0,20] : [20,20]} style={{padding: 20}}>
          <Col xs={24} xl={12}>
            <UserDashboard {...this.props}/>
          </Col>
          <Col xs={24} xl={12}>
            <UIUniversitasDashboard token={this.props.token} />
          </Col>
          <Col xs={24}>
            <UIBidiktiWeekDashboard token={this.props.token} />
          </Col>
          <Col span={24}>
            <BeasiswaDashboard {...this.props}/>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // handleDataHome: () => dispatch(getHome.getDataHome())
  }
}

const mapStateToProps = (state) => {
  return {
    // dataHome: state.home,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Home)
