import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../global/global.css'
import { Card, Breadcrumb, Button, Col, Row } from 'antd'
import faqAction from '../../actions/faq'

class FaqDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inital: '',
      lang: 'ind'
    }
  }

  componentDidMount() {
    let id = this.props.location.pathname.split('/')[2]
    this.props.handleData(this.props.token, id)
  }

  setLaguage = lang => {
    this.setState({
      lang: lang
    })
  }

  render() {
    const { lang } = this.state
    const { dataDetail } = this.props
    return(
      <div className='root'>
        <Breadcrumb className="breadcrumb_style" >
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/dashboard`)}>Pengaturan Umum </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/faq`)}>List FAQ </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className='pathNow_style'>
            Detail FAQ
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card 
          loading={dataDetail.status === 'loading'} 
          className='bodyCard_style'
        >
          <Row gutter={[10, 8]}>
            <Col span={24}>
              <Col span={24}>
                <Row>
                  <Col span={this.props.isMobile ? 8 : 4}>
                    <Button onClick={() => this.setLaguage('ind')} className={`${lang === 'ind' ? 'updateButton' : 'nonActiveButton'}`}>B. Indonesia</Button>
                  </Col>
                  <Col span={this.props.isMobile ? 8 :4}>
                    <Button onClick={() => this.setLaguage('eng')} className={`${lang === 'eng' ? 'updateButton' : 'nonActiveButton'}`}>B. Inggris</Button>
                  </Col>
                </Row>
              </Col>
              <Col span={24} style={{marginTop: '20px'}}>
                <span className='header_text_detail'>
                  Kategori
                </span>
              </Col>
              <Col span={24}>
                <span className='text_detail_FAQ'>
                  {dataDetail.data.faqCategoryName}
                </span>
              </Col>
              <Col span={24}>
                <span className='header_text_detail'>
                  Pertanyaan
                </span>
              </Col>
              <Col span={24}>
                <span className='text_detail_FAQ'>
                  {lang === 'ind' ? dataDetail.data.faqQuestionInd : dataDetail.data.faqQuestionEng}
                </span>
              </Col>
              <Col span={24}>
                <span className='header_text_detail'>
                  Jawaban
                </span>
              </Col>
              <Col span={24}>
                <span className='text_detail_FAQ'>
                  {lang === 'ind' ? dataDetail.data.faqAnswerInd : dataDetail.data.faqAnswerEng}
                </span>
              </Col>
            </Col>
          </Row>
        </Card>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return{
    handleData: (token, id) => dispatch(faqAction.getDataDetail(token, id)),
  }
}

const mapStateToProps = (state) => {
  return {
    dataDetail: state.faqDetail,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FaqDetail)
