import types from '../../constants';

const initialState = {
	status: '',
	data: []
}

export default function postFormProfileUniversitas(state = initialState, action) {
	switch (action.type) {
    case types.POST_FORM_PROFILE_UNIVERSITAS_LOADING: 
    return {
      ...state,
      ...action.payload
    }
    case types.POST_FORM_PROFILE_UNIVERSITAS_SUCCESS: 
    return {
      isSuccess: true,
      ...state,
      ...action.payload
    }
    case types.POST_FORM_PROFILE_UNIVERSITAK_FAILED: 
    return {
      isSuccess: false,
      ...state,
      ...action.payload
    }
    default:
      return { ...initialState, ...state }
    }
}