import React, { useState } from "react";
import { Button, Col, Form, Input, notification, Popconfirm, Row, Divider } from "antd";
import {
  FacebookOutlined,
  GlobalOutlined,
  InstagramOutlined,
  MailOutlined,
  PhoneOutlined,
  TwitterOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { post } from "axios";
import config from "../../services/config";
import ImagePreviewComponent from '../../components/imagePreview'


const { BASE_URL_LOGIN, TIMEOUT } = config;

const DetailAdminApprovalUnivCore = (props) => {
  const [form] = Form.useForm();

  const dataDetail = props.dataDetail ? props.dataDetail : [];
  const dataBanner = dataDetail.DataBanner ? dataDetail.DataBanner : [];
  const dataIg = dataDetail.univIg ? dataDetail.univIg : "";
  const dataFb = dataDetail.univFacebook ? dataDetail.univFacebook : "";
  const dataTw = dataDetail.univTwitter ? dataDetail.univTwitter : "";
  const univRegId = dataDetail.univRegId ? dataDetail.univRegId : ''
  const namaPicHumas = dataDetail.univHumasPic ? dataDetail.univHumasPic : null;
  const nomorHpHumas = dataDetail.univHumasPhone
    ? dataDetail.univHumasPhone
    : null;
  const humasEmail = dataDetail.univHumasEmail
    ? dataDetail.univHumasEmail
    : null;
  const fileNameCert = dataDetail.univCertificateFileName
    ? dataDetail.univCertificateFileName
    : null;
  const fileNamePicPhoto = dataDetail.univHumasFileName
    ? dataDetail.univHumasFileName
    : null;
  const filePathCert = dataDetail.univCertificatePath
    ? dataDetail.univCertificatePath
    : null;
  const filePathPicPhoto = dataDetail.univHumasPath
    ? dataDetail.univHumasPath
    : null;

  const [loadingUpload, setLoadingUpload] = useState(false);
  const [loadingUpload2, setLoadingUpload2] = useState(false);
  const [namaPic, setNamaPic] = useState(namaPicHumas);
  const [nomorHumas, setNomorHumas] = useState(nomorHpHumas);
  const [emailHumas, setEmailHumas] = useState(humasEmail);
  const [fotoFileName, setFotoFileName] = useState(fileNamePicPhoto);
  const [fotoFilePath, setFotoFilePath] = useState(filePathPicPhoto);
  const [certFileName, setCertFileName] = useState(fileNameCert);
  const [certFilePath, setCertFilePath] = useState(filePathCert);

  let checkCertEmpty = filePathCert === null
    ? true
    : filePathCert.split("/").pop() === "null";

  let bodyTemp = {};
  let photoPath;
  let certPath;

  const onFinish = (value) => {
    bodyTemp = {
      univHumasPic: value.picName,
      univHumasPhone: value.picPhone,
      univHumasEmail: value.picEmail,
    };

    const tempPhotoPath = fotoFilePath !== null ? fotoFilePath.startsWith("univ/") : false;

    if (tempPhotoPath) {
      photoPath = fotoFilePath;
    } else {
      photoPath = fotoFilePath !== null ? fotoFilePath.split("loyola-bidikti/")[1] : null;
    }

    const tempCertificatePath = certFilePath ? certFilePath.startsWith("univ/") : false;

    if (tempCertificatePath) {
      certPath = certFilePath;
    } else {
      certPath = certFilePath ? certFilePath.split("loyola-bidikti/")[1] : null;
    }

    const finishUpdatebody = {
      ...bodyTemp,
      univHumasImgName: fotoFileName,
      univHumasImgPath: photoPath,
      univCertificateName: certFileName,
      univCertificatePath: certPath,
      usersId: sessionStorage.getItem("userId"),
    };
    props.handleFinish(finishUpdatebody);
    // console.log('1',finishUpdatebody);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const fileInputCert = React.createRef();

  const openFileBrowserCert = () => {
    fileInputCert.current.click();
  };

  const uploadAssignmentLetterCert = (data) => {
    setLoadingUpload2(true);
    post(`${BASE_URL_LOGIN}be/api/uploadSertifikatKerjasama`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.token}`,
      },
      timeout: TIMEOUT,
    })
      .then((res) => {
        if (res && res.data.Status ? res.data.Status === "ACCEPTED" : false) {
          notification.success({
            placement: "bottomRight",
            message: "Success",
            description: res.data.Message ? res.data.Message : "upload success",
          });
          setCertFilePath(res.data.FilePath ? res.data.FilePath : "");
          setCertFileName(res.data.FileName ? res.data.FileName : "");
        } else {
          notification.error({
            placement: "bottomRight",
            message: "Error",
            description: res.data.Message ? res.data.Message : "upload Failed",
          });
        }
        setLoadingUpload2(false);
      })
      .catch(() => {
        notification.error({
          placement: "bottomRight",
          message: "Error",
          description: "Upload Failed",
        });
        setLoadingUpload2(false);
      });
  };

  const fileHandlerCert = (event) => {
    if (event.target.files.length > 1) {
      notification.error({
        placement: "bottomRight",
        message: "Error",
        description: "To Many FIle",
      });
    } else {
      if (event.target.files.length) {
        const file = event.target.files;
        let filename = null;
        const formData = new FormData();
        for (let i = 0; i < file.length; i++) {
          filename = filename ? filename + ", " + file[i].name : file[i].name;
          formData.append(`file`, file[i]);
        }
        // setCertFileName(filename);
        formData.append(`univRegId`, univRegId)
        uploadAssignmentLetterCert(formData);
      }
    }
  };

  const fileInput = React.createRef();

  const openFileBrowser = () => {
    fileInput.current.click();
  };

  const uploadAssignmentLetter = (data) => {
    setLoadingUpload(true);
    post(`${BASE_URL_LOGIN}be/api/uploadHumasPicImg`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.token}`,
      },
      timeout: TIMEOUT,
    })
      .then((res) => {
        if (res && res.data.Status ? res.data.Status === "ACCEPTED" : false) {
          notification.success({
            placement: "bottomRight",
            message: "Success",
            description: res.data.Message ? res.data.Message : "upload success",
          });
          setFotoFilePath(res.data.FilePath ? res.data.FilePath : "");
          setFotoFileName(res.data.FileName ? res.data.FileName : "");
        } else {
          notification.error({
            placement: "bottomRight",
            message: "Error",
            description: res.data.Message ? res.data.Message : "upload Failed",
          });
        }
        setLoadingUpload(false);
      })
      .catch(() => {
        notification.error({
          placement: "bottomRight",
          message: "Error",
          description: "Upload Failed",
        });
        setLoadingUpload(false);
      });
  };

  const fileHandler = (event) => {
    if (event.target.files.length > 1) {
      notification.error({
        placement: "bottomRight",
        message: "Error",
        description: "To Many FIle",
      });
    } else {
      if (event.target.files.length) {
        const file = event.target.files;
        let filename = null;
        const formData = new FormData();
        for (let i = 0; i < file.length; i++) {
          filename = filename ? filename + ", " + file[i].name : file[i].name;
          // setFotoFileName(filename);
          formData.append(`file`, file[i]);
        }
        formData.append(`univRegId`, univRegId)
        uploadAssignmentLetter(formData);
      }
    }
  };

  function confirm() {
    props.handleReject();
  }

  function cancel() { }

  return (
    <>
      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          picName: namaPic,
          picPhone: nomorHumas,
          picEmail: emailHumas,
        }}
      >
        <Row gutter={[10, 10]}>
          <Col span={props.isMobile ? 12 : 4}>
            <ImagePreviewComponent
              isMobile={props.isMobile}
              styles={{ width: props.isMobile ? '100%' : "90%" }}
              uri={dataDetail.univLogoPath}
            />
            {/* <img alt="logo" width="90%" src={dataDetail.univLogoPath} /> */}
          </Col>
          {props.isMobile && 
            <Col span={12}></Col>
          }
          <Col span={props.isMobile ? 12 : 10}>
            <Col span={24}>
              <span className="header_text_detail">Nama Universitas</span>
            </Col>
            <Col span={24}>
              <span className="text_detail_institusi">
                {dataDetail.univName ? dataDetail.univName : "-"}
              </span>
            </Col>
            <Col span={24}>
              <span className="header_text_detail">Jenis Universitas</span>
            </Col>
            <Col span={24}>
              <span className="data_text_detail">
                {dataDetail.univCatName ? dataDetail.univCatName : "-"}
              </span>
            </Col>
            <Col span={24}>
              <span className="header_text_detail">Banner</span>
            </Col>
            <Col span={24}>
              {dataBanner.map((res, index) => {
                return (
                  <Row gutter={[10,10]}>
                    <Col span={props.isMobile ? 24 :12}>
                      <ImagePreviewComponent
                        isMobile={props.isMobile}
                        styles={{ width: props.isMobile ? '100%' : 130 }}
                        uri={res.univBannerPath}
                      />
                      {/* <img alt="logo" width={130} src={res.univBannerPath} /> */}
                    </Col>
                    <Col span={props.isMobile ? 24 : 12}>
                        <span
                          className="header_text_detail"
                          style={{ marginLeft: "10px" }}
                        >
                          {res.univBannerPath.split("/")[5]}
                        </span>
                        <br />
                    </Col>
                  </Row>
                );
              })}
            </Col>
            <Col span={24}>
              <span className="header_text_detail">Lokasi Universitas</span>
            </Col>
            <Col span={24}>
              <span className="data_text_detail">
                {dataDetail.univAddress ? dataDetail.univAddress : "-"}
              </span>
            </Col>
            <Col span={24}>
              <span className="header_text_detail">Humas</span>
            </Col>
            <Col span={24}>
              <Form.Item name="picName" style={{ marginBottom: 0 }}>
                <Input
                  // defaultValue={namaPicHumas}
                  // value={namaPic}
                  maxLength={125}
                  onChange={(val) => setNamaPic(val.target.value)}
                  placeholder="Nama Lengkap Humas"
                  className="input_style"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="picPhone"
                style={{ marginBottom: 0 }}
                rules={[
                  {
                    pattern: /^[0-9]*$/gm,
                    message: `Please Input number correctly`,
                  },
                ]}
              >
                <Input
                  // value={nomorHumas}
                  maxLength={15}
                  onChange={(val) => setNomorHumas(val.target.value)}
                  placeholder="No. Telephone Humas"
                  className="input_style"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="picEmail"
                style={{ marginBottom: 0 }}
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                ]}
              >
                <Input
                  maxLength={125}
                  // value={emailHumas}
                  // onChange={(val) => setEmailHumas(val.target.value)}
                  placeholder="Email Humas"
                  className="input_style"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={12}>
                  <span className="header_text_detail">Foto </span>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <input
                    type="file"
                    hidden
                    onChange={fileHandler}
                    ref={fileInput}
                  />
                  <Button
                    disabled={props.fromLate}
                    onClick={openFileBrowser}
                    type="primary"
                    className="buttonUpload"
                    loading={loadingUpload}
                  >
                    Upload
                  </Button>
                </Col>
                <Col span={24}>
                  <span
                    style={{
                      marginRight: 15,
                    }}
                  >
                    {fotoFileName
                      ? fotoFileName
                      : // .length > 20 ? fileName.substr(0,20)+ '...' : fileName
                      ""}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={12}>
                  <span className="mandatory">* </span>
                  <span className="header_text_detail">
                    Sertifikat Kerjasama BiDikTi Platform{" "}
                  </span>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <input
                    type="file"
                    hidden
                    onChange={fileHandlerCert}
                    ref={fileInputCert}
                  />
                  <Button
                    disabled={props.fromLate}
                    onClick={openFileBrowserCert}
                    type="primary"
                    className="buttonUpload"
                    loading={loadingUpload2}
                  >
                    Upload
                  </Button>
                </Col>
                <Col span={24}>
                  <span
                    style={{
                      marginRight: 15,
                    }}
                  >
                    {certFileName ? certFileName : ""}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={12}>
                  <span className="header_text_detail">Jalur Masuk </span>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <Button
                    onClick={() => props.handleShowList()}
                    type="primary"
                    className="buttonUpload"
                  // loading={loadingUpload}
                  >
                    Detail
                  </Button>
                </Col>
              </Row>
            </Col>
          </Col>
          <Col span={ props.isMobile ? 12 : 10}>
            <Col span={24}>
              <span className="header_text_detail">Tautan Tentang Kami</span>
            </Col>
            <Col span={24}>
              <span className="text_detail_institusi">
                <a
                  target="_blank"
                  href={`${dataDetail.univAboutUsUrl}`}
                  rel="noopener noreferrer"
                  className="linkUrl"
                >
                  {dataDetail.univAboutUsUrl ? dataDetail.univAboutUsUrl : "-"}
                </a>
              </span>
            </Col>
            <Col span={24}>
              <span className="header_text_detail">Tautan Jenjang</span>
            </Col>
            <Col span={24}>
              <span className="text_detail_institusi">
                <a
                  target="_blank"
                  href={`${dataDetail.univStageUrl}`}
                  rel="noopener noreferrer"
                  className="linkUrl"
                >
                  {dataDetail.univStageUrl ? dataDetail.univStageUrl : "-"}
                </a>
              </span>
            </Col>
            <Col span={24}>
              <span className="header_text_detail">
                Tautan Fakultas / Program
              </span>
            </Col>
            <Col span={24}>
              <span className="text_detail_institusi">
                <a
                  target="_blank"
                  href={`${dataDetail.univFacultyUrl}`}
                  rel="noopener noreferrer"
                  className="linkUrl"
                >
                  {dataDetail.univFacultyUrl ? dataDetail.univFacultyUrl : "-"}
                </a>
              </span>
            </Col>
            <Col span={24}>
              <span className="data_text_detail">Sosial Media</span>
            </Col>
            <Col span={24}>
              <div className="ulMediaSosial">
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href={`https://api.whatsapp.com/send?phone=62${dataDetail.univWa}`}
                      rel="noopener noreferrer"
                      className="listLink"
                    >
                      <WhatsAppOutlined
                        className="iconStyle"
                        style={{ paddingRight: "10px" }}
                      />
                      {dataDetail.univWa
                        ? dataDetail.univWa.length > 40
                          ? `${dataDetail.univWa.substr(0, 40)}...`
                          : dataDetail.univWa
                        : "-"}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`tel:${dataDetail.univPhone}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="listLink"
                    >
                      <PhoneOutlined style={{ paddingRight: "10px" }} />
                      {dataDetail.univPhone
                        ? dataDetail.univPhone.length > 40
                          ? `${dataDetail.univPhone.substr(0, 40)}...`
                          : dataDetail.univPhone
                        : "-"}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${dataDetail.univEmail}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="listLink"
                    >
                      <MailOutlined style={{ paddingRight: "10px" }} />
                      {dataDetail.univEmail
                        ? dataDetail.univEmail.length > 40
                          ? `${dataDetail.univEmail.substr(0, 40)}...`
                          : dataDetail.univEmail
                        : "-"}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${dataDetail.univWebsite}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="listLink"
                    >
                      <GlobalOutlined style={{ paddingRight: "10px" }} />
                      {dataDetail.univWebsite
                        ? dataDetail.univWebsite.length > 30
                          ? `${dataDetail.univWebsite.substr(0, 40)}...`
                          : dataDetail.univWebsite
                        : "-"}
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        dataIg.includes("http", 0)
                          ? `${dataIg}`
                          : `https://www.instagram.com/${dataIg}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="listLink"
                    >
                      <InstagramOutlined style={{ paddingRight: "10px" }} />
                      {dataDetail.univIg
                        ? dataDetail.univIg.length > 30
                          ? `${dataDetail.univIg.substr(0, 40)}...`
                          : dataDetail.univIg
                        : "-"}
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        dataFb.includes("http", 0)
                          ? `${dataFb}`
                          : `https://www.facebook.com/${dataFb}/?ref=br_rs`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="listLink"
                    >
                      <FacebookOutlined style={{ paddingRight: "10px" }} />
                      {dataDetail.univFacebook
                        ? dataDetail.univFacebook.length > 20
                          ? `${dataDetail.univFacebook.substr(0, 40)}...`
                          : dataDetail.univFacebook
                        : "-"}
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        dataTw.includes("http", 0)
                          ? `${dataTw}`
                          : `https://twitter.com/${dataTw}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="listLink"
                    >
                      <TwitterOutlined style={{ paddingRight: "10px" }} />
                      {dataDetail.univTwitter
                        ? dataDetail.univTwitter.length > 40
                          ? `${dataDetail.univTwitter.substr(0, 40)}...`
                          : dataDetail.univTwitter
                        : "-"}
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <span className="data_text_detail">
              <span style={{ color: "#ff3838" }}>* </span>NB: Sebelum Approve,
              mohon upload sertifikat kerjasama terlebih dahulu. <br /> Lalu
              klik tombol Update Data
            </span>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Button
              htmlType="submit"
              // onClick={() => handleFinishUpdate()}
              className="updateButton"
              loading={props.loadingUpate}
            >
              Update Data
            </Button>
            <Divider type="vertical" />
            <Popconfirm
              title="Are you sure reject this?"
              onConfirm={confirm}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button
                // onClick={() => props.handleShare()}
                className="deleteButton2"
                loading={props.loadingReject}
              >
                Reject
              </Button>
            </Popconfirm>
            <Divider type="vertical" />
            {filePathCert !== null ? (
              checkCertEmpty !== true ? (
                <Button
                  onClick={() => props.handleApprove()}
                  className="approveButton"
                  loading={props.loadingApprove}
                >
                  Approve
                </Button>
              ) : (
                  <Button
                    className="approveButton"
                    disabled
                  >
                    Approve
                  </Button>
                )
            ) : (
                <Button
                  className="approveButton"
                  disabled
                >
                  Approve
                </Button>
              )}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export const UIDetailAdminApprovalUniv = DetailAdminApprovalUnivCore;
