import { get, post } from 'axios'
import config from '../services/config'
const { BASE_URL_LOGIN, TIMEOUT } = config

const getPresentation = (token, sort, size, page, search) => {
  return get(`${BASE_URL_LOGIN}be/api/bwEventPublished?sort=${sort}&size=${size}&page=${page - 1}&search=${search}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.Data.map(res =>{
      return {
        bwScheduleDate: res.bwScheduleDate,
        bwScheduleDay: res.bwScheduleDay,
        bwSessionName: res.bwSessionName,
        bwUnivId: res.bwUnivId,
        id: res.bwUnivId,
        bwUnivSpeaker: res.bwUnivSpeaker,
        univRegName: res.univRegName,
        isOnline: res.isOnline,
      }
    })
    return {
      data: normalizeData,
      totalData: response.data.totalData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const postSendEmail = (token, id) => {
  return post(`${BASE_URL_LOGIN}be/api/sendMailForPaticipant?bwUnivId=${id}`,{}, 
  {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    timeout: TIMEOUT
  }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const getPresentationDetail = (token, id) => {
  return get(`${BASE_URL_LOGIN}be/api/bwEventDetailAdmin?bwUnivId=${id}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = {
      bwScheduleId: response.data.Data.bwScheduleId,
      bwSessionId: response.data.Data.bwSessionId,
      bwUnivLoyolaQuota: response.data.Data.bwUnivLoyolaQuota,
      bwUnivMedia: response.data.Data.bwUnivMedia,
      bwUnivMediaJoinId: response.data.Data.bwUnivMediaJoinId,
      bwUnivMediaJoinPassword: response.data.Data.bwUnivMediaJoinPassword,
      bwUnivMediaJoinUrl: response.data.Data.bwUnivMediaJoinUrl,
      bwUnivOtherQuota: response.data.Data.bwUnivOtherQuota,
      bwUnivPicName: response.data.Data.bwUnivPicName,
      bwUnivPicWa: response.data.Data.bwUnivPicWa,
      bwUnivSpeaker: response.data.Data.bwUnivSpeaker,
      bwUnivSpeakerDesc: response.data.Data.bwUnivSpeakerDesc,
      isOnline: response.data.Data.isOnline,
      lastModifiedBy: response.data.Data.lastModifiedBy,
      univRegAddress: response.data.Data.univRegAddress,
      univRegName: response.data.Data.univRegName,
      univRegPhone: response.data.Data.univRegPhone,
      logoPath: response.data.Data.logoPath,
      sisaQuotaUmum: response.data.Data.sisaQuotaUmum,
      sisaQuotaLoyola: response.data.Data.sisaQuotaLoyola,
      bwScheduleDay: response.data.Data.bwScheduleDay,
      bwScheduleDate: response.data.Data.bwScheduleDate,
      bwSessionName: response.data.Data.bwSessionName
    }
    return {
      data: normalizeData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const getParticipantListLoyola = (token, param) => {
  return get(`${BASE_URL_LOGIN}be/api/bwParticipantlist/loyola?bwUnivId=${param.id}&sort=${param.sort}&size=${param.size}&page=${param.page - 1}&search=${param.search}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.Data.map(res =>{
      return {
        bwParticipantEmail: res.bwParticipantEmail,
        bwParticipantRole: res.bwParticipantRole,
        bwParticipantName: res.bwParticipantName,
        bwParticipantId: res.bwParticipantId,
        id: res.bwParticipantId,
      }
    })
    return {
      data: normalizeData,
      totalData: response.data.totalData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const getParticipantListPublic = (token, param) => {
  return get(`${BASE_URL_LOGIN}be/api/bwParticipantlist/umum?bwUnivId=${param.id}&sort=${param.sort}&size=${param.size}&page=${param.page - 1}&search=${param.search}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.Data.map(res =>{
      return {
        bwParticipantEmail: res.bwParticipantEmail,
        bwParticipantName: res.bwParticipantName,
        bwParticipantId: res.bwParticipantId,
        id: res.bwParticipantId,
      }
    })
    return {
      data: normalizeData,
      totalData: response.data.totalData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}



export default {
  getPresentation,
  getPresentationDetail,
  getParticipantListLoyola,
  getParticipantListPublic,
  postSendEmail
}
