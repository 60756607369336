import React from 'react'

import {
  Modal,
  Form,
  Row,
  Col,
  Typography,
  Input,
  Button,
  notification,
} from 'antd'
import { post } from 'axios'
import config from '../../services/config'

const { BASE_URL_LOGIN, TIMEOUT } = config
const { Title } = Typography
const { TextArea } = Input

const ModalSendNews = props => {

  const [loadingUpload, setLoadingUpload] = React.useState(false);
  const [fileName, setFileName] = React.useState(null);
  const [filePath, setFilePath] = React.useState(null);
  const [fileBanner, setFileBanner] = React.useState(null);
  const [form] = Form.useForm();

  React.useEffect(() => {
    setFilePath(null);
    setFileName(null);
    form.resetFields();
  }, [props.visibleModal]);

  const handleResetForm = () => {
    form.resetFields();
    setFileName(null)
    setFilePath(null)
    fileInput.current.value = "";
  };

  const fileInput = React.createRef();

  const openFileBrowser = () => {
    fileInput.current.click();
  };

  const uploadAssignmentLetter = (data) => {
    let token = props.tokenUser;
    let isSuccess = false;
    setLoadingUpload(true);
    post(`${BASE_URL_LOGIN}be/api/upload/headerEmail`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }).then(res => {
      if (res && res.status ? res.data.Status === 'OK' : false) {
        notification.success({
          placement: 'bottomRight',
          message: 'Success',
          description: res.data.Message ? res.data.Message : 'upload success',
        });
        setFilePath(res.data.headerEmail ? res.data.headerEmail : "")
        isSuccess = true;
      } else {
        notification.error({
          placement: 'bottomRight',
          message: 'Failed',
          description: res.data.Message ? res.data.Message : 'upload error',
        });
      }
      setLoadingUpload(false);
    }).catch(() => {
      notification.error({
        placement: 'bottomRight',
        message: 'Error',
        description: 'Upload Failed'
      });
      setLoadingUpload(false);
      setFileName(null);
      setFileBanner(null);
    });

    if ( !isSuccess ) {
      fileInput.current.value = "";
    }
  }

  const fileHandler = event => {
      const file = event.target.files[0];
      const fileNameValue = file.name;
      const extFile = fileNameValue.split('.').pop();
      if ( extFile.toLowerCase() === "png" || extFile.toLowerCase() === "jpg" || extFile.toLowerCase() === "jpeg" ) {
        setFileBanner(file);
        setFileName(fileNameValue);
        let formData = new FormData();
        formData.append('file', file);
        uploadAssignmentLetter(formData);
      } else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: 'Format file harus png, jpg, atau jpeg!',
        });
        fileInput.current.value = "";
      }
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const checkFinish = (values) => {
    if ( fileBanner && fileName ) {
      const tempFinish = {
        "subject": values.subject,
        "content": values.content,
        "emailHeader": filePath,
        "button": values.label,
        "url": values.url
      }
      props.onFinish(tempFinish);
    } else {
      notification.warning({
        placement: 'bottomRight',
        message: 'Warning',
        description: 'Upload konten header terlebih dahulu!',
      });
    }
  };

  return (

    <Modal
      visible={props.visibleModal}
      footer={null}
      title='none'
      className={props.isMobile ? 'modal_regisMobile' :'modal_regis'}
      width={props.isMobile ? '100%' : 633}
      centered
      onCancel={() => props.close()}
      destroyOnClose={true}
    >
      <Row>
        <Col span={24}>
          <div className='headerForm'>
            <Title className='titleModal' level={4}>{props.title}</Title>
          </div>
          <div className='dividerRegis' />
        </Col>
        <Col span={24}>
          <Form
            form={form}
            onFinish={values => checkFinish(values)}
            onFinishFailed={onFinishFailed}
          >
            <Row gutter={[10, 0]}>
              <Col span={23}>
                <Form.Item
                  name='subject'
                  rules={[
                    { required: true, message: "Please input subject/judul!" }
                  ]}
                >
                  <Input 
                    disabled={props.fromLate} 
                    size="large" 
                    className='input_style'
                    placeholder={"Subject/Judul"} 
                    />
                </Form.Item>
              </Col>
              <Col span={1}>
                <span className="mandatoryLarge">&#8727;</span>
              </Col>
              <Col span={23}>
                <Form.Item>
                  <Row>
                    <Col span={14} style={{ margin: 'auto' }}>
                      <span className='logoInstitusi'>
                        <span className="mandatory">* </span>
                        {`Konten Header (1 Gambar)`}
                      </span>
                    </Col>
                    <Col span={10} style={{ textAlign: 'right' }}>
                      <span
                        style={{
                          marginRight: 15
                        }}
                      >
                        {fileName ? (
                          fileName.length > 30 ? fileName.substr(0, 30) + '...' : fileName
                        ) : ''
                        }
                      </span>
                      <input
                        type="file"
                        hidden
                        onChange={fileHandler}
                        ref={fileInput}
                        accept="image/*"
                      />
                      <Button
                        onClick={openFileBrowser}
                        type="primary"
                        className='buttonUpload'
                        loading={loadingUpload}
                      >
                        Upload
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
              <Col span={23}>
                <Form.Item
                  name='content'
                  rules={[
                    { required: true, message: "Please input content!" }
                  ]}
                >
                  <TextArea
                    disabled={props.fromLate}
                    maxLength={256}
                    size="large"
                    className='input_style'
                    placeholder={"Konten"}
                    rows={14}
                  />
                </Form.Item>
              </Col>
              <Col span={1}>
                <span className="mandatoryLarge">&#8727;</span>
              </Col>
              <Col span={23}>
                <Form.Item
                  name='label'
                  rules={[
                    { required: true, message: "Plase input label tombol!" }
                  ]}
                >
                  <Input 
                    disabled={props.fromLate} 
                    size="large" 
                    className='input_style' 
                    placeholder={"Label Tombol"} 
                  />
                </Form.Item>
              </Col>
              <Col span={1}>
                <span className="mandatoryLarge">&#8727;</span>
              </Col>
              <Col span={23}>
                <Form.Item
                  name='url'
                  rules={[
                    { required: true, message: "Please input url button!" },
                    { type: "url", message: "Please enter a valid button url!" }
                  ]}
                >
                  <Input 
                    disabled={props.fromLate} 
                    size="large" 
                    className='input_style' 
                    placeholder={"URL Tombol"} 
                  />
                </Form.Item>
              </Col>
              <Col span={1}>
                <span className="mandatoryLarge">&#8727;</span>
              </Col>
              <Col span={24}>
                <Form.Item
                  style={{
                    textAlign: 'center',
                    marginBottom: '0px'
                  }}
                >
                  <Button
                    type="link"
                    className='resetButton'
                    size={'small'}
                    onClick={() => handleResetForm()}
                  >
                    Reset
                  </Button>
                  <Button
                    type="primary"
                    htmlType='submit'
                    className='updateButton'
                    loading={props.loading}
                    size={'small'}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
              <span   
                className="data_text_detail" 
                style={{ 
                  fontSize: "12px", 
                  position: "absolute",
                  right: 0,
                  bottom: -20
                }}
              >
                *Dikirim ke semua User Active
              </span>
            </Row>
          </Form>
        </Col>
      </Row>
    </Modal>
  )
}

export const UIModalSendNews = ModalSendNews
