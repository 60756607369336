const GET_LIFE_SHARING_GALLERY_LOADING = 'GET_LIFE_SHARING_GALLERY_LOADING';
const GET_LIFE_SHARING_GALLERY_SUCCESS = 'GET_LIFE_SHARING_GALLERY_SUCCESS';
const GET_LIFE_SHARING_GALLERY_FAILED = 'GET_LIFE_SHARING_GALLERY_FAILED';
const GET_DETAIL_LIFE_SHARING_GALLERY_LOADING = 'GET_DETAIL_LIFE_SHARING_GALLERY_LOADING';
const GET_DETAIL_LIFE_SHARING_GALLERY_SUCCESS = 'GET_DETAIL_LIFE_SHARING_GALLERY_SUCCESS';
const GET_DETAIL_LIFE_SHARING_GALLERY_FAILED = 'GET_DETAIL_LIFE_SHARING_GALLERY_FAILED';
const POST_LIFE_SHARING_GALLERY_LOADING = 'POST_LIFE_SHARING_GALLERY_LOADING';
const POST_LIFE_SHARING_GALLERY_SUCCESS = 'POST_LIFE_SHARING_GALLERY_SUCCESS';
const POST_LIFE_SHARING_GALLERY_FAILED = 'POST_LIFE_SHARING_GALLERY_FAILED';
const DELETE_LIFE_SHARING_GALLERY_LOADING = 'DELETE_LIFE_SHARING_GALLERY_LOADING';
const DELETE_LIFE_SHARING_GALLERY_SUCCESS = 'DELETE_LIFE_SHARING_GALLERY_SUCCESS';
const DELETE_LIFE_SHARING_GALLERY_FAILED = 'DELETE_LIFE_SHARING_GALLERY_FAILED';
const PUT_URL_LIFE_SHARING_GALLERY_LOADING = 'PUT_URL_LIFE_SHARING_GALLERY_LOADING';
const PUT_URL_LIFE_SHARING_GALLERY_SUCCESS = 'PUT_URL_LIFE_SHARING_GALLERY_SUCCESS';
const PUT_URL_LIFE_SHARING_GALLERY_FAILED = 'PUT_URL_LIFE_SHARING_GALLERY_FAILED';

export {
  GET_LIFE_SHARING_GALLERY_LOADING,
  GET_LIFE_SHARING_GALLERY_SUCCESS,
  GET_LIFE_SHARING_GALLERY_FAILED,
  GET_DETAIL_LIFE_SHARING_GALLERY_LOADING,
  GET_DETAIL_LIFE_SHARING_GALLERY_SUCCESS,
  GET_DETAIL_LIFE_SHARING_GALLERY_FAILED,
  POST_LIFE_SHARING_GALLERY_LOADING,
  POST_LIFE_SHARING_GALLERY_SUCCESS,
  POST_LIFE_SHARING_GALLERY_FAILED,
  DELETE_LIFE_SHARING_GALLERY_LOADING,
  DELETE_LIFE_SHARING_GALLERY_SUCCESS,
  DELETE_LIFE_SHARING_GALLERY_FAILED,
  PUT_URL_LIFE_SHARING_GALLERY_LOADING,
  PUT_URL_LIFE_SHARING_GALLERY_SUCCESS,
  PUT_URL_LIFE_SHARING_GALLERY_FAILED,
}
