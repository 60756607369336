import React, { Component } from "react";
import "../../global/global.css";
import { connect } from "react-redux";
import masterNegaraAction from "../../actions/masterNegara";
import {GlobalFunction} from '../../global/function'
import {
   Breadcrumb, Card, Row, Col, Button,
   Popconfirm, notification
} from "antd";
import {
   UItable, UISearch, UIModalCreate
} from "../../components";

class MasterNegara extends Component {
   constructor(props) {
      super(props);
      this.state = {
         filter: "isDeleted=false",
         orderBy: "createdOn desc",
         top: 10,
         skip: 0,
         visibleCreate: false,
         loading: false,
         initialData: {
            countryName: undefined,
            countryCode: undefined
         },
         dataEdit: {
            countryName: undefined,
            countryCode: undefined
         },
         isEdit: false,
         countryId: undefined
      }
   }

   componentDidMount() {
      const { filter, orderBy, top, skip } = this.state;
      const body = {
         token: this.props.token,
         filter,
         orderBy,
         top,
         skip
      };

      this.props.handleData(body);
   }

   handleTableChange = (page, filterBy, sorter) => {
      const { top, filter } = this.state;

      let sorterField = ""
      if (sorter.field === "countryName") {
         sorterField = "countryName";
      } else {
         sorterField = "countryCode";
      }

      let orderBy = "";
      if (sorter.order === "ascend") {
         orderBy = `${sorterField} asc`;
      } else if (sorter.order === "descend") {
         orderBy = `${sorterField} desc`;
      } else {
         orderBy = `createdOn desc`;
      }
      const body = {
         token: this.props.token,
         filter,
         orderBy,
         top,
         skip: page.current - 1
      }
      this.setState({
         skip: page.current - 1,
         orderBy
      }, () => {
         this.props.handleData(body)
      })
   }

   handleSearch = key => {
      const { orderBy, top } = this.state;
      const encodeKey = GlobalFunction.searchEncode(key)
      const value = `isDeleted=false AND ( LOWER(countryName) LIKE LOWER('%25${encodeKey}%25')  OR LOWER(countryCode) LIKE LOWER('%25${encodeKey}%25') )`;
      this.setState({
         filter: value,
         skip: 0
      });
      const body = {
         token: this.props.token,
         filter: value,
         orderBy,
         top,
         skip: 0,
      }
      this.props.handleData(body)
   }

   openModal = () => {
      this.setState({
         visibleCreate: true,
         isEdit: false,
         initialData: {
            countryName: undefined,
            countryCode: undefined
         }
      })
   }

   handleCloseModal = () => {
      this.setState({ visibleCreate: false })
   }

   handleEditClick = data => {
      this.setState({
         countryId: data.countryId,
         visibleCreate: true,
         isEdit: true,
         dataEdit: {
            countryName: data.countryName,
            countryCode: data.countryCode
         },
         initialData: {
            countryName: data.countryName,
            countryCode: data.countryCode
         }
      })
   }

   handleSubmitCreate = (token, body) => {
      this.props.handleCreate(token, body).then(res => {
         if (res.payload.data.status === 200) {
            notification.success({
               placement: 'bottomRight',
               message: 'Success',
               description: "Berhasil menambahkan negara.",
            });
            this.setState({
               filter: "isDeleted=false",
               orderBy: "createdOn desc",
               top: 10,
               skip: 0,
               visibleCreate: false
            }, () => {
               const body = {
                  filter: "isDeleted=false",
                  orderBy: "createdOn desc",
                  top: 10,
                  skip: 0,
                  token: token
               }
               this.props.handleData(body);
            })
         } else {
            notification.error({
               placement: 'bottomRight',
               message: 'Error',
               description: res.payload.error.response.data.message ? res.payload.error.response.data.message : "Gagal menambahkan negara",
            });
         }
      })
   }

   handleSubmitEdit = (token, body, id) => {
      this.props.handleEdit(token, body, id).then(res => {
         if (res.payload.data.status === 200) {
            notification.success({
               placement: 'bottomRight',
               message: 'Success',
               description: "Berhasil mengubah negara.",
            });
            this.setState({
               filter: "isDeleted=false",
               orderBy: "createdOn desc",
               top: 10,
               skip: 0,
               visibleCreate: false
            }, () => {
               const body = {
                  filter: "isDeleted=false",
                  orderBy: "createdOn desc",
                  top: 10,
                  skip: 0,
                  token: token
               }
               this.props.handleData(body);
            })
         } else {
            notification.error({
               placement: 'bottomRight',
               message: 'Error',
               description: res.payload.error.response.data.message ? res.payload.error.response.data.message : "Gagal mengubah negara",
            });
         }
      });
   }

   resetInitial = () => {
      let dataInitial = {
         countryId: undefined,
         provinceName: undefined
      }
      if (this.state.isEdit) {
         dataInitial = {
            countryName : this.state.dataEdit.countryName,
            countryCode: this.state.dataEdit.countryCode
         }
      }
      this.setState({
         initialData: dataInitial
      })
   }

   handleFinish = values => {
      this.setState({ 
         initialData: {
            countryName: values.countryName,
            countryCode: values.countryCode
         }
      });
      
      const body = {
         countryName: values.countryName,
         countryCode: values.countryCode,
         userId: this.props.userId
      }

      if (this.state.isEdit) {
         this.handleSubmitEdit(this.props.token, body, this.state.countryId)
      } else {
         this.handleSubmitCreate(this.props.token, body);
      }
   }

   handleOnDelete = data => {
      const { token, userId } = this.props;
      const body = {
         userId
      };
      this.props.handleDelete(token, body, data.countryId).then(res => {
         if (res.payload.data.status === 200) {
            notification.success({
               placement: 'bottomRight',
               message: 'Success',
               description: "Berhasil menghapus negara.",
            });
            this.setState({
               filter: "isDeleted=false",
               orderBy: "createdOn desc",
               top: 10,
               skip: 0,
               visibleCreate: false
            }, () => {
               const body = {
                  filter: "isDeleted=false",
                  orderBy: "createdOn desc",
                  top: 10,
                  skip: 0,
                  token: token
               }
               this.props.handleData(body);
            })
         } else {
            notification.error({
               placement: 'bottomRight',
               message: 'Error',
               description: res.payload.error.response.data && res.payload.error.response.data.message ? res.payload.error.response.data.message : "Gagal menghapus negara.",
            })
         }
      })
   }

   render() {
      const { skip, visibleCreate, loading, initialData, isEdit } = this.state
      const { dataMasterNegara, dataCreateMasterNegara, dataPutMasterNegara } = this.props;
      const text = 'Apakah anda yakin menghapus Negara ini ?';
      const columnsTable = [
         {
            title: "Nama Negara",
            dataIndex: 'countryName',
            key: 'countryName',
            sorter: true,
            ellipsis: true
         },
         {
            title: "Kode Telepon",
            dataIndex: 'countryCode',
            key: 'countryCode',
            sorter: true,
            ellipsis: true
         },
         {
            title: 'Action',
            dataIndex: '',
            key: '',
            render: (data) => <>
               <Button
                  onClick={() => this.handleEditClick(data)}
                  type='link'
                  style={{ color: '#EF712B', cursor: 'pointer', fontWeight: 600 }}
               >
                  Edit
               </Button>
               <Popconfirm
                  placement="bottomRight"
                  title={text}
                  onConfirm={() => this.handleOnDelete(data)}
                  okText="Yes"
                  cancelText="No"
               >
                  <Button
                     type='link'
                     style={{ color: '#E22828', cursor: 'pointer', fontWeight: 600 }}
                  >
                     Delete
                  </Button>
               </Popconfirm>
            </>
         },
      ];
      const formItem = [
         {
            name: 'countryName',
            type: 'input',
            colNumber: 23,
            maxLength: 50,
            rules: [
               {
                  required: true,
                  message: 'Please input country!'
               }
            ],
            placeholder: 'Nama Negara'
         },
         {
            name: 'countryCode',
            type: 'input',
            colNumber: 11,
            maxLength: 25,
            rules: [
               {
                  required: true,
                  message: "Please input country code!"
               },
               {
                  pattern: /^(?:[+\d].*\d|\d)$/,
                  message: "Please input a valid country code!"
               }
            ],
            placeholder: "Kode Telepon"
         }
      ]

      return (
         <div className="root">
            <Breadcrumb className="breadcrumb_style">
               <Breadcrumb.Item>
                  <span style={{ cursor: 'pointer' }}>Master</span>
               </Breadcrumb.Item>
               <Breadcrumb.Item className='pathNow_style'>
                  Negara
            </Breadcrumb.Item>
            </Breadcrumb>
            <Card className="bodyCard_style">
               <Row>
                  <Col span={8}>
                     <UISearch placeholder='Search' handleSearch={key => this.handleSearch(key)} />
                  </Col>
                  <Col span={16} style={{ textAlign: 'right' }}>
                     <Button className='updateButton' onClick={() => this.openModal()}>Tambah Negara</Button>
                  </Col>
               </Row>
               <Card
                  bordered={false}
                  loading={dataMasterNegara.loading}
                  style={{ minHeight: '300px' }}>
                  <UItable
                     dataTable={dataMasterNegara.data}
                     columns={columnsTable}
                     withRows={false}
                     isMobile={this.props.isMobile}
                     loading={dataMasterNegara.status}
                     page={skip + 1}
                     totalData={dataMasterNegara.totalData}
                     handleTableChange={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter)}
                  />
               </Card>
            </Card>
            <UIModalCreate
               formItem={formItem}
               isMobile={this.props.isMobile}
               visible={visibleCreate}
               loading={loading}
               initialValues={initialData}
               title={isEdit ? 'Edit Negara' : 'Tambah Negara'}
               widthDivider='250px'
               close={() => this.handleCloseModal()}
               onReset={() => this.resetInitial()}
               checkFinish={values => this.handleFinish(values)}
               loadingSubmit={isEdit ? dataPutMasterNegara.status === "loading" : dataCreateMasterNegara.status === "loading"}
            />
         </div>
      )
   }
}

const mapDispatchToProps = dispatch => {
   return {
      handleData: (param) => dispatch(masterNegaraAction.getData(param)),
      handleCreate: (token, body) => dispatch(masterNegaraAction.createNegara(token, body)),
      handleEdit: (token, body, id) => dispatch(masterNegaraAction.editNegara(token, body, id)),
      handleDelete: (token, body, id) => dispatch(masterNegaraAction.deleteNegara(token, body, id))
   }
}

const mapStateToProps = (state) => {
   return {
      dataMasterNegara: state.masterNegara,
      dataCreateMasterNegara: state.createMasterNegara,
      dataPutMasterNegara: state.putMasterNegara,
   }
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterNegara)
