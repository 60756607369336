import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../global/global.css'
import { Card, Breadcrumb, notification, Button, Col, Row, Input, Form } from 'antd'
import lifeSharingAction from '../../actions/settingLifeSharing'

class LifeSharing extends Component {
  formRef = React.createRef()
  constructor(props) {
    super(props);
    this.state = {
      inital: '',
      loadingSubmit: false
    }
  }

  componentDidMount() {
    this.props.handleData(this.props.token)
  }

  componentDidUpdate(prevProps) {
    const { dataLife } = this.props
    if (
      dataLife.status !==
      prevProps.dataLife.status &&
      dataLife.status === 'success'
    ) {
      let temp = {
        scCarSubtitleEng: dataLife.data.scCarSubtitleEng,
        scCarSubtitleInd: dataLife.data.scCarSubtitleInd,
        scFutSubtitleEng: dataLife.data.scFutSubtitleEng,
        scFutSubtitleInd: dataLife.data.scFutSubtitleInd,
        scFamSubtitleEng: dataLife.data.scFamSubtitleEng,
        scFamSubtitleInd: dataLife.data.scFamSubtitleInd,
        scEduSubtitleEng: dataLife.data.scEduSubtitleEng,
        scEduSubtitleInd: dataLife.data.scEduSubtitleInd,
        scDescEng: dataLife.data.scDescEng,
        scDescInd: dataLife.data.scDescInd,
        scSubtitleEng: dataLife.data.scSubtitleEng,
        scSubtitleInd: dataLife.data.scSubtitleInd,

      }
      this.formRef.current.setFieldsValue(temp)
    }
  }

  onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  checkFinish = val => {
    const { token, userId } = this.props
    this.setState({
      loadingSubmit: true
    })
    this.props.handleEdit(token, userId, val).then(res => {
      if (res.payload.status !== 'error') {
        if (res.payload.data.data.Status === 'OK') {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Update Life Sharing Success',
          });
          this.setState({
            loadingSubmit: false
          })
          this.props.handleData(token)
        } else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Update Life Sharing Failed'
          });
          this.setState({
            loadingSubmit: false
          })
        }
      } else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: 'Update Life Sharing Failed'
        });
        this.setState({
          loadingSubmit: false
        })
      }
    })
  }


  render() {
    const { loadingSubmit } = this.state
    return (
      <div className='root'>
        <Breadcrumb className="breadcrumb_style" >
          <Breadcrumb.Item>
            <span style={{ cursor: 'pointer' }} onClick={() => this.props.history.push(`/dashboard`)}>Pengaturan Umum </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className='pathNow_style'>
            Life Sharing
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card
          // loading={dataDetail.status === 'loading'}
          className='bodyCard_style'
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form
                onFinish={values => this.checkFinish(values)}
                onFinishFailed={event => this.onFinishFailed(event)}
                ref={this.formRef}
              >
                <Row gutter={[32, 0]}>
                  <Col span={12}>
                    <Row style={{ marginBottom: '20px' }}>
                      <Col span={24}>
                        <span className='headTextLang'>
                          Bahasa Indonesia
                        </span>
                      </Col>
                    </Row>
                    <Row gutter={this.props.isMobile ? [4,16] : [10, 16]}>
                      <Col span={24}>
                        <span className='data_text_detail'>
                          Life Sharing
                        </span>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          className='input_form'
                          name='scSubtitleInd'
                          rules={[{
                            required: true,
                            message: 'Please Input Sub Judul (ind)!'
                          }]}
                        >
                          <Input
                            maxLength={256}
                            size="large"
                            className='input_style'
                            placeholder={"Sub Judul (Bahasa Indonesia)"} />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <span className="mandatoryLarge">&#8727;</span>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          className='input_form'
                          name='scDescInd'
                          rules={[{
                            required: true,
                            message: 'Please Input Description (ind)!'
                          }]}
                        >
                          <Input.TextArea
                            size="large"
                            rows={8}
                            className='input_style'
                            placeholder={"Deskripsi (Bahasa Indonesia)"} />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <span className="mandatoryLarge">&#8727;</span>
                      </Col>
                      <Col span={24}>
                        <span className='data_text_detail'>
                          Education
                        </span>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          className='input_form'
                          name='scEduSubtitleInd'
                          rules={[{
                            required: true,
                            message: 'Please Input Sub Judul (ind)!'
                          }]}
                        >
                          <Input.TextArea
                            maxLength={256}
                            size="large"
                            rows={6}
                            className='input_style'
                            placeholder={"Sub Judul (Bahasa Indonesia)"} />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <span className="mandatoryLarge">&#8727;</span>
                      </Col>
                      <Col span={24}>
                        <span className='data_text_detail'>
                          Career
                        </span>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          className='input_form'
                          name='scCarSubtitleInd'
                          rules={[{
                            required: true,
                            message: 'Please Input Sub Judul (ind)!'
                          }]}
                        >
                          <Input.TextArea
                            maxLength={256}
                            size="large"
                            rows={6}
                            className='input_style'
                            placeholder={"Sub Judul (Bahasa Indonesia)"} />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <span className="mandatoryLarge">&#8727;</span>
                      </Col>
                      <Col span={24}>
                        <span className='data_text_detail'>
                          {`Parenting & Family`}
                        </span>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          className='input_form'
                          name='scFamSubtitleInd'
                          rules={[{
                            required: true,
                            message: 'Please Input Sub Judul (ind)!'
                          }]}
                        >
                          <Input.TextArea
                            maxLength={256}
                            size="large"
                            rows={6}
                            className='input_style'
                            placeholder={"Sub Judul (Bahasa Indonesia)"} />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <span className="mandatoryLarge">&#8727;</span>
                      </Col>
                      <Col span={24}>
                        <span className='data_text_detail'>
                          Into The Future
                        </span>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          className='input_form'
                          name='scFutSubtitleInd'
                          rules={[{
                            required: true,
                            message: 'Please Input Sub Judul (ind)!'
                          }]}
                        >
                          <Input.TextArea
                            maxLength={256}
                            size="large"
                            rows={6}
                            className='input_style'
                            placeholder={"Sub Judul (Bahasa Indonesia)"} />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <span className="mandatoryLarge">&#8727;</span>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row style={{ marginBottom: '20px' }}>
                      <Col span={24}>
                        <span className='headTextLang'>
                          Bahasa Inggris
                        </span>
                      </Col>
                    </Row>
                    <Row gutter={this.props.isMobile ? [4,16] : [10, 16]}>
                      <Col span={24}>
                        <span className='data_text_detail'>
                          &nbsp;
                        </span>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          className='input_form'
                          name='scSubtitleEng'
                          rules={[{
                            required: true,
                            message: 'Please Input Sub Judul (Eng)!'
                          }]}
                        >
                          <Input
                            maxLength={256}
                            size="large"
                            className='input_style'
                            placeholder={"Sub Judul (Bahasa Inggris)"} />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <span className="mandatoryLarge">&#8727;</span>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          className='input_form'
                          name='scDescEng'
                          rules={[{
                            required: true,
                            message: 'Please Input Description (Eng)!'
                          }]}
                        >
                          <Input.TextArea
                            size="large"
                            rows={8}
                            className='input_style'
                            placeholder={"Deskripsi (Bahasa Inggris)"} />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <span className="mandatoryLarge">&#8727;</span>
                      </Col>
                      <Col span={24}>
                        <span className='data_text_detail'>
                          &nbsp;
                        </span>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          className='input_form'
                          name='scEduSubtitleEng'
                          rules={[{
                            required: true,
                            message: 'Please Input Sub Judul (Eng)!'
                          }]}
                        >
                          <Input.TextArea
                            maxLength={256}
                            size="large"
                            rows={6}
                            className='input_style'
                            placeholder={"Sub Judul (Bahasa Inggris)"} />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <span className="mandatoryLarge">&#8727;</span>
                      </Col>
                      <Col span={24}>
                        <span className='data_text_detail'>
                          &nbsp;
                        </span>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          className='input_form'
                          name='scCarSubtitleEng'
                          rules={[{
                            required: true,
                            message: 'Please Input Sub Judul (Eng)!'
                          }]}
                        >
                          <Input.TextArea
                            maxLength={256}
                            size="large"
                            rows={6}
                            className='input_style'
                            placeholder={"Sub Judul (Bahasa Inggris)"} />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <span className="mandatoryLarge">&#8727;</span>
                      </Col>
                      <Col span={24}>
                        <span className='data_text_detail'>
                          &nbsp;
                        </span>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          className='input_form'
                          name='scFamSubtitleEng'
                          rules={[{
                            required: true,
                            message: 'Please Input Sub Judul (Eng)!'
                          }]}
                        >
                          <Input.TextArea
                            maxLength={256}
                            size="large"
                            rows={6}
                            className='input_style'
                            placeholder={"Sub Judul (Bahasa Inggris)"} />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <span className="mandatoryLarge">&#8727;</span>
                      </Col>
                      <Col span={24}>
                        <span className='data_text_detail'>
                          &nbsp;
                        </span>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          className='input_form'
                          name='scFutSubtitleEng'
                          rules={[{
                            required: true,
                            message: 'Please Input Sub Judul (Eng)!'
                          }]}
                        >
                          <Input.TextArea
                            maxLength={256}
                            size="large"
                            rows={6}
                            className='input_style'
                            placeholder={"Sub Judul (Bahasa Inggris)"} />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <span className="mandatoryLarge">&#8727;</span>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          style={{
                            textAlign: 'right',
                            marginBottom: '0px'
                          }}
                        >
                          <Button
                            type="primary"
                            htmlType='submit'
                            className='updateButton'
                            loading={loadingSubmit}
                            size={'small'}
                          >
                            Submit
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleData: (token) => dispatch(lifeSharingAction.getData(token)),
    handleEdit: (token, userId, body) => dispatch(lifeSharingAction.handleEdit(token, userId, body)),
  }
}

const mapStateToProps = (state) => {
  return {
    dataLife: state.settingLifeSharing,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LifeSharing)
