import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../global/global.css'
import { UIContentDetail, UIModalParticipantDetail } from '../../components'
import { Card, notification, Breadcrumb } from 'antd'
import getPresentation from '../../actions/presentation'

class ListPresentationDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initial: '',
      id: '',
      visiblePartisipan: false,
      loyolaActive: true,
      page: 1,
      size: 10,
      search: '',
      sort: '',
      loadingPost: false
    }
  }

  componentDidMount() {
    let id = this.props.location.pathname.split('/')[2]
    this.setState({
      id: id
    })
    this.props.handleDataDetail(this.props.token, id)
  }

  openPartisipan = () => {
    const { id, search, sort, page, size } = this.state;
    this.setState({
      visiblePartisipan: true,
    })
    const param = {
      id,
      search,
      sort,
      page,
      size
    }
    this.props.handleDataParticipantListLoyola(this.props.token, param);
  }

  closeModal = () => {
    this.setState({
      visiblePartisipan: false
    })
  }

  changeButton = (val) => {
    this.setState({
      loyolaActive: val,
      page: 1,
      sort: '',
      search: '',
    }, () => {
      const param = {
        id: this.state.id,
        search: this.state.search,
        sort: this.state.sort,
        size: this.state.size,
        page: this.state.page
      }
      if (val) {
        this.props.handleDataParticipantListLoyola(this.props.token, param);
      } else {
        this.props.handleDataParticipantListPublic(this.props.token, param);
      }
    })
  }

  handleTableChange = (page, filter, sorter, from) => {
    const { id, size, search } =this.state

    let sorterField = ""
    if ( sorter.field === "bwParticipantName" ) {
      if (from === 'loyola') {
        sorterField = "s.student_name,k.kekl_name,t.t_edu_staff_name,f.emp_child_name";
      }else {
        sorterField = "s.nonloyola_name,f.univ_reg_name";
      }
    }else if ( sorter.field === "bwParticipantEmail" ) {
      sorterField = "c.users_email";
    }else {
      sorterField = "d.role_name";
    }

    let sort = "";
    if (sorter.order === "ascend" ) {
      sort = `${sorterField},asc`;
    } else if (sorter.order === "descend") {
      sort = `${sorterField},desc`;
    }

    this.setState({
      page: page.current,
      sort
    });
    const param = {
      id,
      search,
      sort,
      size,
      page: page.current
    }
    if (from === 'loyola') {
      this.props.handleDataParticipantListLoyola(this.props.token, param)
    }else {
      this.props.handleDataParticipantListPublic(this.props.token, param)
    }
  }

  handleSearch = key => {
    const {loyolaActive, page, size, sort, id} = this.state
    this.setState({
      search: key
    })
    const param = {
      id,
      search: key,
      sort,
      size,
      page
    }
    if (loyolaActive) {
      this.props.handleDataParticipantListLoyola(this.props.token, param);
    }else {
      this.props.handleDataParticipantListPublic(this.props.token, param);
    }
  }

  handleShare = () => {
    this.setState({
      loadingPost: true
    })
    this.props.handleSendEmail(this.props.token, this.state.id).then(res => {
      if (res.payload.status !== 'error') {
        if (res.payload.data.data.Status === 'OK') {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Send Email Success',
          });
          this.setState({
            search: '',
            page: 1,
            orderBy: '',
            loadingPost: false,
          })
        }else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Send Email Failed'
          });
          this.setState({
            loadingPost: false,
          })
        }
      }else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: 'Send Email Failed'
        });
        this.setState({
          loadingPost: false,
        })
      }
    })
  }

  render() {
    const columnsTableLoyola = [
      {
        title: 'Nama',
        dataIndex: 'bwParticipantName',
        key: 'bwParticipantName',
        sorter: true,
      },
      {
        title: 'Email',
        dataIndex: 'bwParticipantEmail',
        key: 'bwParticipantEmail',
        sorter: true,
      },
      {
        title: 'Status',
        dataIndex: 'bwParticipantRole',
        key: 'bwParticipantRole',
        sorter: true,
      },
    ];

    const columnsTablePublic = [
      {
        title: 'Nama',
        dataIndex: 'bwParticipantName',
        key: 'bwParticipantName',
        sorter: true,
      },
      {
        title: 'Email',
        dataIndex: 'bwParticipantEmail',
        key: 'bwParticipantEmail',
        sorter: true,
      },
    ];

    const { dataDetail, dataParticipantListLoyola, dataParticipantListPublic } = this.props
    const {
      visiblePartisipan,
      loyolaActive,
      page,
      loadingPost
     } = this.state
    return(
      <div className='root'>
        <Breadcrumb className="breadcrumb_style" >
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/dashboard`)}>BIDikTi Week </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/list-presentation`)}>Daftar Presentasi </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className='pathNow_style'>
            Detail Presentasi
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card loading={dataDetail.status === 'loading'} className='bodyCard_style'>
          <UIContentDetail
            from={'presentation'}
            dataDetail={dataDetail.data}
            isMobile={this.props.isMobile}
            handleOpenModalInfo={() => this.openPartisipan()}
            handleShare={() => this.handleShare()}
            loadingPost={loadingPost}
          />
        </Card>
        <UIModalParticipantDetail
          isMobile={this.props.isMobile}
          visible={visiblePartisipan}
          handleBack={() => this.closeModal()}
          dataParticipantLoyola={dataParticipantListLoyola.data}
          dataParticipantPublic={dataParticipantListPublic.data}
          columnsLoyola={columnsTableLoyola}
          columnsPublic={columnsTablePublic}
          loadingLoyola={dataParticipantListLoyola.status}
          loadingPublic={dataParticipantListPublic.status}
          changeButton={val => this.changeButton(val)}
          loyolaActive={loyolaActive}
          totalDataLoyola={dataParticipantListLoyola.totalData}
          handleTableChangeLoyola={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter, 'loyola')}
          page={page}
          handleSearch={key => this.handleSearch(key)}
          totalDataPublic={dataParticipantListPublic.totalData}
          handleTableChangePublic={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter, 'public')}
        />
      </div>
    )
  }
}
const mapDispatchToProps = dispatch => {
  return{
    // handleDataHome: () => dispatch(getHome.getDataHome())
    handleDataDetail: (token, id) => dispatch(getPresentation.getDataDetailPresentation(token, id)),
    handleDataParticipantListLoyola: (token, param) => dispatch(getPresentation.getDataParticipantListLoyola(token, param)),
    handleDataParticipantListPublic: (token, param) => dispatch(getPresentation.getDataParticipantListPublic(token, param)),
    handleSendEmail: (token, id) => dispatch(getPresentation.postSendEmail(token, id)),
  }
}

const mapStateToProps = (state) => {
  return {
    // dataHome: state.home,
    dataDetail: state.detailPresentation,
    dataParticipantListLoyola: state.participantListLoyola,
    dataParticipantListPublic: state.participantListPublic,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ListPresentationDetail)
