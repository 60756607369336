import React, { Component } from "react";
import { connect } from "react-redux";
import "../../global/global.css";
import DaftarAcaraInUniv from "../../actions/DaftarAcaraInUniv";
import {
  Card,
  Button,
  Row,
  Col,
  Breadcrumb,
  Typography,
} from "antd";
import { UItable, UISearch } from "../../components";

const { Text } = Typography;

class AdminUnivDaftarAcara extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderBy: '',
      size: 10,
      page: 1,
      search: '',
      visibleRegis: false,
      loadingSubmit: false,
      initial: undefined,
      univId: undefined,
    };
  }

  componentDidMount() {
    const idUniv = sessionStorage.getItem('univId');
    this.setState({
      univId: idUniv,
    })
    const { orderBy, size, page, search } = this.state;
    this.props.handleDataDaftarAcara(this.props.token, idUniv, orderBy, size, page, search);
  }

  handleRedirectDetail = (id) => {
    this.props.history.push(`/daftar-acara-universitas-detail/${id}`);
  };

  handleRedirectTambah = () => {
    this.props.history.push(`/daftar-acara-universitas-tambah`);
  };

  handleTableChange = (page, filter, sorter) => {
    const { size, search, univId } = this.state
    let sorterField
    if (sorter.field === "univEventName") {
      sorterField = "univ_event_name";
    } else if (sorter.field === "univEventDay") {
      sorterField = "univ_event_day";
    } else if (sorter.field === "univIsOnline") {
      sorterField = "is_online";
    } else if (sorter.field === "univEventApprovalStatus") {
      sorterField = "univ_event_approval";
    } else {
      sorterField = "univ_event_date";
    }

    let orderBy = "";
    if (sorter.order === "ascend") {
      orderBy = `${sorterField},asc`;
    } else if (sorter.order === "descend") {
      orderBy = `${sorterField},desc`;
    }

    this.setState({
      page: page.current,
      orderBy: orderBy
    }, () => {
      this.props.handleDataDaftarAcara(this.props.token, univId, orderBy, size, page.current, search)
    })
  }

  handleSearch = key => {
    const { orderBy, size, univId } = this.state
    this.setState({
      search: key,
      page: 1
    })
    this.props.handleDataDaftarAcara(this.props.token, univId, orderBy, size, 1, key)
  }

  render() {
    const columnsTable = [
      {
        title: "Nama Acara",
        dataIndex: "univEventName",
        key: "univEventName",
        sorter: true,
        render: (name) => (
          <Text
            style={{ color: "#EF712B", cursor: "pointer", fontWeight: 600 }}
          >
            {name}
          </Text>
        ),
        onCell: (record) => {
          return {
            onClick: () => {
              this.handleRedirectDetail(record.univEventId ? record.univEventId : '');
            },
          };
        },
      },
      {
        title: "Hari",
        dataIndex: "univEventDay",
        key: "univEventDay",
        sorter: true,
        onCell: (record) => {
          return {
            onClick: () => {
              this.handleRedirectDetail(record.univEventId ? record.univEventId : '');
            },
          };
        },
      },
      {
        title: "Tanggal",
        dataIndex: "univEventDate",
        key: "univEventDate",
        sorter: true,
        onCell: (record) => {
          return {
            onClick: () => {
              this.handleRedirectDetail(record.univEventId ? record.univEventId : '');
            },
          };
        },
      },
      {
        title: "Presentasi",
        dataIndex: "univIsOnline",
        key: "univIsOnline",
        sorter: true,
        render: (status) => {
          if (status === "Online") {
            return (
              <Text
                style={{ color: "#EF712B", cursor: "pointer", fontWeight: 600 }}
              >
                {status}
              </Text>
            );
          } else {
            return (
              <Text
                style={{ color: "#11174C", cursor: "pointer", fontWeight: 600 }}
              >
                {status}
              </Text>
            );
          }
        },
        onCell: (record) => {
          return {
            onClick: () => {
              this.handleRedirectDetail(record.univEventId ? record.univEventId : "");
            },
          };
        },
      },
      {
        title: "Status",
        dataIndex: "univEventApprovalStatus",
        key: "univEventApprovalStatus",
        sorter: true,
        render: status => {
          if (status === "On Approval") {
            return <Text style={{ color: '#21296C', fontWeight: 600 }}>{status}</Text>
          } else if (status === "Approved") {
            return <Text style={{ color: '#6BC733', fontWeight: 600 }}>{status}</Text>
          } else if (status === "New") {
            return <Text style={{ color: '#EF712B', fontWeight: 600 }}>{status}</Text>
          } else {
            return <Text style={{ color: '#E22828', fontWeight: 600 }}>{status}</Text>
          }
        }
      },
    ];

    const {
      dataDaftarAcara,
    } = this.props

    const {
      page,
    } = this.state

    return (
      <div className="root">
        <Breadcrumb className="breadcrumb_style">
          <Breadcrumb.Item className="pathNow_style">
            Daftar Acara
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card className="bodyCard_style">
          <Row>
            <Col span={8}>
              <UISearch
                placeholder="Search"
                handleSearch={key => this.handleSearch(key)}
              />
            </Col>
            <Col span={16} style={{ textAlign: "right" }}>
              <Button
                onClick={() => this.handleRedirectTambah()}
                className="updateButton"
              >
                Tambah Acara
              </Button>
            </Col>
          </Row>
          <Card
            bordered={false}
            loading={dataDaftarAcara.loading}
            style={{ minHeight: "300px" }}
          >
            <UItable
              dataTable={dataDaftarAcara ? dataDaftarAcara.data : []}
              columns={columnsTable}
              withRows={false}
              isMobile={this.props.isMobile}
              totalData={dataDaftarAcara.totalData}
              loading={dataDaftarAcara.status}
              page={page}
              handleTableChange={(pageNow, filters, sorter) =>
                this.handleTableChange(pageNow, filters, sorter)
              }
            />
          </Card>
        </Card>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleDataDaftarAcara: (token, id, sort, size, page, search) => dispatch(DaftarAcaraInUniv.getDataDaftarAcara(token, id, sort, size, page, search)),
  };
};

const mapStateToProps = (state) => {
  return {
    dataDaftarAcara: state.getDaftarAcara,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminUnivDaftarAcara);
