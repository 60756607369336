import types from "../constants";

const initialState = {
   status: "loading",
   data: []
}

export default function rejectDaftarPersetujuanGaleri(state = initialState, action) {
   switch (action.type) {
      case types.REJECT_DAFTAR_PERSETUJUAN_GALERI_LOADING:
         return {
            ...state,
            ...action.payload
         }
      case types.REJECT_DAFTAR_PERSETUJUAN_GALERI_SUCCESS:
         return {
            isSuccess: true,
            ...state,
            ...action.payload
         }
      case types.REJECT_DAFTAR_PERSETUJUAN_GALERI_FAILED:
         return {
            isSuccess: false,
            ...state,
            ...action.payload
         }
      default:
         return { ...initialState, ...state }
   }
}