import Axios, { get, put, post  } from 'axios'
import config from '../services/config'
const { BASE_URL_LOGIN, BASE_URL_NOAUTH, TIMEOUT } = config

const getData = (param) => {
  return get(`${BASE_URL_LOGIN}be/api/visionListAdmin?sort=${param.sort}&size=${param.size}&page=${param.page - 1}&search=${param.search}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${param.token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.Data.map(res =>{
      return {
        button: res.button,
        visionDetStudentDesc: res.visionDetStudentDesc,
        visionDetStudentName: res.visionDetStudentName,
        visionDetStudentYoutubeUrlEmbed: res.visionDetStudentYoutubeUrlEmbed,
        visionDetStudentYoutubeUrlImage: res.visionDetStudentYoutubeUrlImage,
        visionDetId: res.visionDetId,
        id: res.visionDetId
      }
    })
    return {
      data: normalizeData,
      totalData: response.data.totalData ? response.data.totalData : 10,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      totalData: 0,
      error,
      data: []
    }
  })
}

const getDataDetail = (token, id) => {
  return get(`${BASE_URL_NOAUTH}api/bStudentVisionDetail/${id}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const data = response.data ? response.data.Data : undefined
    const normalizeData = 
      {
        visionDetId: data ? data.visionDetId : '',
        visionDetStudentClass: data ? data.visionDetStudentClass : '',
        visionDetStudentDesc: data ? data.visionDetStudentDesc : '',
        visionDetStudentMajor: data ? data.visionDetStudentMajor : '',
        visionDetStudentName: data ? data.visionDetStudentName : '',
        visionDetStudentSchoolYear: data ? data.visionDetStudentSchoolYear : '',
        visionDetStudentYoutubeUrl: data ? data.visionDetStudentYoutubeUrl : '',
        visionDetStudentYoutubeUrlEmbed: data ? data.visionDetStudentYoutubeUrlEmbed : '',
        visionDetStudentYoutubeUrlImage: data ? data.visionDetStudentYoutubeUrlImage : '',
      }
    return {
      data: normalizeData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: undefined
    }
  })
}

const handleCreate = (token, body) => {
  return post(`${BASE_URL_LOGIN}be/api/createVisionAdmin`,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: '',
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: [],
    }
  })
}

const handleEdit = (token, id, body) => {
  return put(`${BASE_URL_LOGIN}be/api/updateVisionAdmin/${id}`,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: '',
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: [],
    }
  })
}

const handleShow = (token, id) => {
  return put(`${BASE_URL_LOGIN}be/api/updateDisplayedVisionAdmin/${id}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: '',
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: [],
    }
  })
}

const handleHide = (token, id) => {
  return put(`${BASE_URL_LOGIN}be/api/updateHideVisionAdmin/${id}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: '',
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: [],
    }
  })
}



const handleDelete = (token, id) => {
  // let url = 'https://demo5436246.mockable.io/listApapun'
  let url = `${BASE_URL_LOGIN}be/api/deleteVisionAdmin?id=${id}`
  return Axios.delete(url,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: '',
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: [],
    }
  })
}

export default {
  getData,
  getDataDetail,
  handleCreate,
  handleEdit,
  handleShow,
  handleHide,
  handleDelete
}