import React, { Component } from "react";
import { 
   Breadcrumb, Card, Row, Col, Button, 
   Popconfirm, notification, Table, Typography
} from "antd";
import {
   TwitterOutlined, WhatsAppOutlined, PhoneOutlined,
   MailOutlined, GlobalOutlined, InstagramOutlined,
   FacebookOutlined
 } from "@ant-design/icons";
import "../../global/global.css";
import { connect } from "react-redux";
import ImagePreviewComponent from '../../components/imagePreview'
import daftarPersetujuanKonten from "../../actions/daftarPersetujuanKonten";

const { Text } = Typography;
class DaftarPersetujuanKontenDetail extends Component {
   constructor(props) {
      super(props);
      this.state = {
         univId: "",
         param: {
            status: "",
            sort: ""
         }
      }
   }

   componentDidMount() {
      this.setState({ 
         univId: this.props.match.params.univId,
         param: {
            ...this.state.param,
            status: this.props.match.params.status,
         }
      }, () => {
         this.props.handleGetDetail(this.props.token , this.state.univId, this.state.param.status);
         this.props.handleGetJalurMasuk(this.props.token, this.state.univId, this.state.param);
      });
   } 

   handleRedirectToDaftarPersetujuanKonten = () => {
      this.props.history.push("/daftar-persetujuan-konten");
   }

   handleApprove = (token, univId, body) => {
      this.props.handleApprove(token, univId, body).then( res => {
         if ( res.payload.data ? res.payload.data.Status === "OK" : false ) {
            notification.success({
               placement: 'bottomRight',
               message: 'Success',
               description: res.payload.data ? res.payload.data.Message : "Perubahan Konten Berhasil di Approve.",
            });
            this.handleRedirectToDaftarPersetujuanKonten();
         } else {
            notification.error({
               placement: 'bottomRight',
               message: 'Error',
               description: res.payload.data ? res.payload.data.Message : "Perubahan Konten Gagal di Approve.",
            });
         }
      });
   }

   handleReject = (token, univId, body) => {
      this.props.handleReject(token, univId, body).then( res => {
         if ( res.payload.data ? res.payload.data.Status === "OK" : false ) {
            notification.success({
               placement: 'bottomRight',
               message: 'Success',
               description: res.payload.data ? res.payload.data.Message : "Perubahan Konten Berhasil di Reject.",
            });
            this.handleRedirectToDaftarPersetujuanKonten();
         } else {
            notification.error({
               placement: 'bottomRight',
               message: 'Error',
               description: res.payload.data ? res.payload.data.Message : "Perubahan Konten Gagal di Reject.",
            });
         }
      });
   }

   handleOnApprove = () => {
      const body = {
         userId: this.props.userId
      }
      this.handleApprove(this.props.token, this.state.univId, body);
   }

   handleOnReject = () => {
      const body = {
         userId: this.props.userId
      }
      this.handleReject(this.props.token, this.state.univId, body);
   }

   handleTableChange = (page, filters, sorter) => {
      let sortByValue = "";
      
      if ( sorter.order === "ascend" ) {
         sortByValue = `${sorter.field},asc`;
      } else if ( sorter.order === "descend" ) {
         sortByValue = `${sorter.field},desc`;
      } else {
         sortByValue = "";
      }

      this.setState({
         param: {
            ...this.state.param,
            sort: sortByValue
         }
      }, () => {
         this.props.handleGetJalurMasuk(this.props.token, this.state.univId, this.state.param);
      })
   }

   render() {
      const { dataDetail, dataJalurMasuk, dataApprove, dataReject } = this.props;
      const { param } = this.state;
      const columnsTable = [
         {
            title: "Nama",
            dataIndex: "univEntName",
            key: "univEntName",
            sorter: true,
            align: "center",
            render: namaUniversitas => <Text style={{ color: '#EF712B', fontWeight: 600 }}>{namaUniversitas}</Text>
         }, 
         {
            title: "Tautan",
            dataIndex: "univEntUrl",
            key: "univEntUrl",
            sorter: true
         },
         {
            title: "Terima Form",
            dataIndex: "isReceiveUpload",
            key: "isReceiveUpload",
            sorter: true,
            align: "center",
            render: isReceiveUpload => {
               if ( isReceiveUpload ) {
                  return ( <Text>Ya</Text> )
               } else {
                  return ( <Text>Tidak</Text> )
               }
            }
         },
         {
            title: "Action",
            dataIndex: "",
            key: "",
            align: "center",
            render: record => {
               if ( record.univEntFormulirName ) {
                  return (
                     <a 
                        href={record.univEntFormulirPath }
                        donwload={ record.univEntFormulirName }
                     >
                        <Button className="donwloadButton">Download</Button>
                     </a>
                  )
               } else {
                  return (
                     <Button className="donwloadButton" disabled="true">Download</Button>
                  )
               }
            }
         }
      ];
      const {isMobile} = this.props
      return (
         <div className="root">
            <Breadcrumb className="breadcrumb_style">
               <Breadcrumb.Item>
                  <span style={{ cursor: 'pointer' }}>Universitas</span>
               </Breadcrumb.Item>
               <Breadcrumb.Item>
                  <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/daftar-persetujuan-konten`)}>
                     Daftar Persetujuan Konten
                  </span>
               </Breadcrumb.Item>
               <Breadcrumb.Item className='pathNow_style'>
                  Detail Daftar Persetujuan Konten
               </Breadcrumb.Item>
            </Breadcrumb>
            <Card 
               loading={dataDetail.status === "loading"} 
               className="bodyCard_style"
            >
               <Row gutter={[24, 10]}>
                  <Col span={isMobile ? 12 : 4} style={{textAlign: "center"}}>
                     <ImagePreviewComponent
                        styles={{ width: isMobile ? '100%' : '90%' }}
                        isMobile={isMobile}
                        uri={dataDetail.data.resUnivLogo
                           && dataDetail.data.resUnivLogo.univUploadFilePath}
                     />
                     {/* <img 
                        src={ dataDetail.data.resUnivLogo
                              ? dataDetail.data.resUnivLogo.univUploadFilePath
                              : "" } 
                        alt="Logo Universitas"
                        width="90%"
                     /> */}
                  </Col>
                  <Col span={isMobile ? 24 : 20}>
                     <Row gutter={[10, 0]}>
                        <Col span={12}>
                           <Row gutter={[0, 10]}>
                              <Col span={24}>
                                 <span className="header_text_detail">Nama Universitas</span>
                              </Col>
                              <Col span={24}>
                                 <span className="text_detail_institusi">
                                    { dataDetail.data.univName
                                       ? dataDetail.data.univName
                                       : "-" }
                                 </span>
                              </Col>
                              <Col span={24}>
                                 <span className="header_text_detail">Jenis Universitas</span>
                              </Col>
                              <Col span={24}>
                                 <span className="data_text_detail">
                                    { dataDetail.data.univCatName
                                       ? dataDetail.data.univCatName
                                       : "-" }
                                 </span>
                              </Col>
                              <Col span={24}>
                                 <span className="header_text_detail">Banner</span>
                              </Col>
                              <Col span={24}>
                                 { dataDetail.data.resUnivBanners
                                    ? dataDetail.data.resUnivBanners.map( (res, index) => (
                                       <Row key={index} gutter={[16, 16]} align="middle">
                                          <Col span={isMobile ? 24 : 12}>
                                             <ImagePreviewComponent
                                                isMobile={isMobile}
                                                styles={{ width: '100%' }}
                                                uri={res.univUploadFilePath}
                                             />
                                             {/* <img 
                                                src={res.univUploadFilePath}
                                                alt="Banner Universitas"
                                                width="100%"
                                             /> */}
                                          </Col>
                                          <Col span={isMobile ? 24 : 12} style={{textAlign: "center"}}>
                                             <span className="header_text_detail">
                                                {res.univUploadFileName}
                                             </span>
                                          </Col>
                                       </Row>
                                    ))
                                    : "-" }
                              </Col>
                              <Col span={24}>
                                 <span className="header_text_detail">Lokasi Universitas</span>
                              </Col>
                              <Col span={20}>
                                 <span className="data_text_detail">
                                    { dataDetail.data.univCompleteAddress
                                       ? dataDetail.data.univCompleteAddress
                                       : "-" }
                                 </span>
                              </Col>
                              <Col span={24}>
                                 <span className="header_text_detail">Tautan Tentang Kami</span>
                              </Col>
                              <Col span={24}>
                                 <a 
                                    className="text_detail_link_jalur_masuk"
                                    href={ dataDetail.data.univAboutUsUrl
                                          ? dataDetail.data.univAboutUsUrl
                                          : "" }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                 >
                                    { dataDetail.data.univAboutUsUrl
                                       ? dataDetail.data.univAboutUsUrl
                                       : "-" }
                                 </a>
                              </Col>
                              <Col span={24}>
                                 <span className="header_text_detail">Tautan Jenjang</span>
                              </Col>
                              <Col span={24}>
                                 <a 
                                    className="text_detail_link_jalur_masuk"
                                    href={ dataDetail.data.univStageUrl
                                          ? dataDetail.data.univStageUrl
                                          : "" }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                 >
                                    { dataDetail.data.univStageUrl
                                       ? dataDetail.data.univStageUrl
                                       : "-" }
                                 </a>
                              </Col>
                              <Col span={24}>
                                 <span className="header_text_detail">Tautan Fakultas / Program</span>
                              </Col>
                              <Col span={24}>
                                 <a 
                                    className="text_detail_link_jalur_masuk"
                                    href={ dataDetail.data.univFacultyUrl
                                          ? dataDetail.data.univFacultyUrl
                                          : "" }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                 >
                                    { dataDetail.data.univFacultyUrl
                                       ? dataDetail.data.univFacultyUrl
                                       : "-" }
                                 </a>
                              </Col>
                           </Row>
                        </Col>
                        <Col span={12}>
                           <Row gutter={[0, 10]}>
                              <Col span={24}>
                                 <span className="header_text_detail">Nama PIC Humas</span>
                              </Col>
                              <Col span={24}>
                                 <span className="data_text_detail">
                                    { dataDetail.data.univHumasPic
                                       ? dataDetail.data.univHumasPic
                                       : "-" }
                                 </span>
                              </Col>
                              <Col span={24}>
                                 <Row gutter={[10, 0]} align="middle">
                                    <Col span={isMobile ? 24 : 12}>
                                       <ImagePreviewComponent
                                          styles={{ width: '100%' }}
                                          isMobile={isMobile}
                                          uri={dataDetail.data.resUnivHumas
                                             && dataDetail.data.resUnivHumas.univUploadFilePath}
                                       />
                                       {/* <img 
                                          src={ dataDetail.data.resUnivHumas
                                                ? dataDetail.data.resUnivHumas.univUploadFilePath
                                                : "-" }
                                          alt="Foto Profile Humas"
                                          width="100%"
                                       /> */}
                                    </Col>
                                    <Col span={isMobile ? 24 : 12} style={{textAlign: "center"}}>
                                       <span className="header_text_detail">
                                          { dataDetail.data.resUnivHumas
                                             ? dataDetail.data.resUnivHumas.univUploadFileName
                                             : "-" }
                                       </span>
                                    </Col>
                                 </Row>
                              </Col>
                              <Col span={24}>
                                 <span className="header_text_detail">Sertifikat</span>
                              </Col>
                              <Col span={24}>
                                 <Row gutter={[10, 0]} align="middle">
                                    <Col span={isMobile ? 24 : 12}>
                                       <ImagePreviewComponent
                                          styles={{ width: '100%' }}
                                          isMobile={isMobile}
                                          uri={dataDetail.data.resUnivCertificate
                                             && dataDetail.data.resUnivCertificate.univUploadFilePath}
                                       />
                                       {/* <img 
                                          src={ dataDetail.data.resUnivCertificate
                                                ? dataDetail.data.resUnivCertificate.univUploadFilePath
                                                : "-" }
                                          alt="Foto Profile Humas"
                                          width="100%"
                                       /> */}
                                    </Col>
                                    <Col span={isMobile ? 24 : 12} style={{textAlign: "center"}}>
                                       <span className="header_text_detail">
                                          { dataDetail.data.resUnivCertificate
                                             ? dataDetail.data.resUnivCertificate.univUploadFileName
                                             : "-" }
                                       </span>
                                    </Col>
                                 </Row>
                              </Col>
                              <Col span={24}>
                                 <span className="header_text_detail">No. Telepon Humas</span>
                              </Col>
                              <Col span={24}>
                                 <span className="data_text_detail">
                                    { dataDetail.data.univHumasPhone
                                       ? dataDetail.data.univHumasPhone
                                       : "-" }
                                 </span>
                              </Col>
                              <Col span={24}>
                                 <span className="header_text_detail">Email Humas</span>
                              </Col>
                              <Col span={24}>
                                 <span className="data_text_detail">
                                    { dataDetail.data.univHumasEmail
                                       ? dataDetail.data.univHumasEmail
                                       : "-" }
                                 </span>
                              </Col>
                              <Col span={24}>
                                 <span className="header_text_detail">Social Media</span>
                              </Col>
                              <Col span={24}>
                                 <div className='ulMediaSosial'>
                                    <ul>
                                       <li>
                                          <a 
                                             target="_blank" 
                                             className="listLink"
                                             href={ dataDetail.data.univWa 
                                                   ? `https://api.whatsapp.com/send?phone=62${dataDetail.data.univWa}` 
                                                   : "" }
                                             rel="noopener noreferrer"
                                          >
                                             <WhatsAppOutlined className='iconStyle'/>&nbsp;
                                             { dataDetail.data.univWa 
                                                ? dataDetail.data.univWa.length > 20 
                                                   ? `${dataDetail.data.univWa.substr(0, 20)}...` 
                                                   : dataDetail.data.univWa
                                                : '-' }
                                          </a>
                                       </li>
                                       <li>
                                          <a 
                                             target="_blank" 
                                             className="listLink"
                                             href={ dataDetail.data.univPhone 
                                                   ? `tel:${dataDetail.data.univPhone}` 
                                                   : "" }
                                             rel="noopener noreferrer"
                                          >
                                             <PhoneOutlined/>&nbsp;
                                             { dataDetail.data.univPhone
                                                ? dataDetail.data.univPhone.length > 20
                                                   ? `${dataDetail.data.univPhone.substr(0, 20)}...`
                                                   : dataDetail.data.univPhone
                                                : "-"}
                                          </a>
                                       </li>
                                       <li>
                                          <a 
                                             target="_blank" 
                                             className="listLink"
                                             href={ dataDetail.data.univEmail 
                                                   ? `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${dataDetail.data.univEmail}` 
                                                   : "" }
                                             rel="noopener noreferrer"
                                          >
                                             <MailOutlined/>&nbsp;
                                             { dataDetail.data.univEmail
                                                ? dataDetail.data.univEmail.length > 30
                                                   ? `${dataDetail.data.univEmail.substr(0, 20)}...`
                                                   : dataDetail.data.univEmail
                                                : '-'}
                                          </a>
                                       </li>
                                       <li>
                                          <a 
                                             target="_blank" 
                                             className="listLink"
                                             href={ dataDetail.data.univWebsite 
                                                   ? dataDetail.data.univWebsite 
                                                   : "" }
                                             rel="noopener noreferrer"
                                          >
                                             <GlobalOutlined/>&nbsp;
                                             { dataDetail.data.univWebsite
                                                ? dataDetail.data.univWebsite.length > 30
                                                   ? `${dataDetail.data.univWebsite.substr(0, 20)}...`
                                                   : dataDetail.data.univWebsite
                                                : '-'}
                                          </a>
                                       </li>
                                       <li>
                                          <a 
                                             target="_blank" 
                                             className="listLink"
                                             href={ dataDetail.data.univIg 
                                                   ? dataDetail.data.univIg.includes('http', 0)
                                                      ? dataDetail.data.univIg
                                                      : `https://www.instagram.com/${dataDetail.data.univIg}` 
                                                   : "-" }
                                             rel="noopener noreferrer"
                                          >
                                             <InstagramOutlined />&nbsp;
                                             { dataDetail.data.univIg
                                             ? dataDetail.data.univIg.length > 30
                                                ? `${dataDetail.data.univIg.substr(0, 20)}...`
                                                : dataDetail.data.univIg
                                             : '-'}
                                          </a>
                                       </li>
                                       <li>
                                          <a 
                                             target="_blank" 
                                             className="listLink"
                                             href={ dataDetail.data.univFacebook
                                                   ?  dataDetail.data.univFacebook.includes('http', 0)
                                                      ? `${dataDetail.data.univFacebook}`
                                                      : `https://www.facebook.com/${dataDetail.data.univFacebook}/?ref=br_rs`
                                                   : "" }
                                             rel="noopener noreferrer"
                                          >
                                             <FacebookOutlined />&nbsp;
                                             { dataDetail.data.univFacebook
                                                ? dataDetail.data.univFacebook.length > 30
                                                   ? `${dataDetail.data.univFacebook.substr(0, 20)}...`
                                                   : dataDetail.data.univFacebook
                                                : '-' }
                                          </a>
                                       </li>
                                       <li>
                                          <a 
                                             target="_blank" 
                                             className="listLink"
                                             href={ dataDetail.data.univTwitter 
                                                   ? dataDetail.data.univTwitter.includes('http', 0)
                                                      ? `${dataDetail.data.univTwitter}`
                                                      : `https://twitter.com/${dataDetail.data.univTwitter}`
                                                   : "" }
                                             rel="noopener noreferrer"
                                          >
                                             <TwitterOutlined />&nbsp;
                                             { dataDetail.data.univTwitter
                                                ? dataDetail.data.univTwitter.length > 20
                                                   ? `${dataDetail.data.univTwitter.substr(0, 20)}...`
                                                   : dataDetail.data.univTwitter
                                                : '-' }
                                          </a>
                                       </li>
                                    </ul>
                                 </div>
                              </Col>
                           </Row>
                        </Col>
                        <Col span={24}>
                           <Table
                              dataSource={dataJalurMasuk.data}
                              columns={columnsTable}
                              loading={dataJalurMasuk.status === "loading"}
                              pagination= {false}
                              rowKey={record => record.id}
                              onChange={(page, filters, sorter) => this.handleTableChange(page, filters, sorter)}
                           />
                        </Col>
                     </Row>
                     { param.status === "On Approval" &&
                        <Row>
                           <Col span={24} style={{textAlign: "right", marginTop: 40}}>
                              <Popconfirm 
                                 title="Anda yakin ingin approve ini？" 
                                 okText="Ya" 
                                 cancelText="Tidak"
                                 onConfirm={this.handleOnApprove}
                              >
                                 <Button 
                                    className="approveButton"
                                    loading={dataApprove.status === "loading"}
                                 >
                                    Approve
                                 </Button>
                              </Popconfirm>
                              <Popconfirm 
                                 title="Anda yakin ingin reject ini？" 
                                 okText="Ya" 
                                 cancelText="Tidak"
                                 onConfirm={this.handleOnReject}
                              >
                                 <Button 
                                    className="rejectButton" 
                                    style={{marginLeft: 15}}
                                    loading={dataReject.status === "loading"}
                                 >
                                    Reject
                                 </Button>
                              </Popconfirm>
                           </Col>
                        </Row>
                     }
                  </Col>
               </Row>
            </Card>
         </div>
      )
   }
}

const mapStateToProps = state => (
   {
      dataDetail: state.getDetailDaftarPersetujuanKonten,
      dataJalurMasuk: state.getJalurMasukDaftarPersetujuanKonten,
      dataApprove: state.approveDaftarPersetujuanKonten,
      dataReject: state.rejectDaftarPersetujuanKonten,
   }
)

const mapDispatchToProps = dispatch => (
   {
      handleGetDetail: (token, univId, status) => dispatch(daftarPersetujuanKonten.getDetail(token, univId, status)),
      handleGetJalurMasuk: (token, univId, status) => dispatch(daftarPersetujuanKonten.getJalurMasuk(token, univId, status)),
      handleApprove: (token, univId, body) => dispatch(daftarPersetujuanKonten.approveDaftarPersetujuanKonten(token, univId, body)),
      handleReject: (token, univId, body) => dispatch(daftarPersetujuanKonten.rejectDaftarPersetujuanKonten(token, univId, body)),
   }
)

export default connect(mapStateToProps, mapDispatchToProps)(DaftarPersetujuanKontenDetail);