import React, { Component } from "react";
import { connect } from "react-redux";
import "../../global/global.css";
import {
  UIModalParticipantDetail,
  UIDetailAdminUnivDaftarAcara,
} from "../../components";
import { Card, Breadcrumb } from "antd";
import getDetailAcara from "../../actions/DaftarAcaraInUniv"

class AdminUnivDaftarAcaraDeatil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initial: "",
      id: "",
      visiblePartisipan: false,
      loyolaActive: true,
      page: 1,
      sort: "",
      size: 10,
      search: "",
      loadingPost: false,
      univEventId: '',
      roleGroupId: 1,
    };
  }

  componentDidMount() {
    let id = this.props.location.pathname.split('/')[2]
    this.setState({
      id: id,
      univEventId: id,
    })
    this.props.handleDataDetailAcara(this.props.token, id)
  }

  openPartisipan = () => {
    const { roleGroupId, univEventId, search, sort, page, size } = this.state;
    this.setState({
      visiblePartisipan: true,
    });
    this.props.handleDataParticipantList(this.props.token, roleGroupId, univEventId, sort, size, page, search);
  };

  closeModal = () => {
    this.setState({
      visiblePartisipan: false,
    });
  };

  changeButton = (val) => {
    this.setState({
      loyolaActive: val,
      page: 1,
      sort: '',
      search: '',
    })
    if (val) {
      this.props.handleDataParticipantList(this.props.token, 1, this.state.univEventId, this.state.sort, 10, 1, '');
    }else {
      this.props.handleDataParticipantList(this.props.token, 2, this.state.univEventId, this.state.sort, 10, 1, '');
    }
  }

  handleTableChange = (page, filter, sorter, from) => {
    const { size, search} =this.state

    let sorterField = ""
    if (sorter.field === "univEventParticipantName") {
      sorterField = "participant_name";
   } else if (sorter.field === "univEventParticipantEmail") {
      sorterField = "u.users_email";
   } else {
      sorterField = "r.role_name";
   }

    let orderBy = "";
    if (sorter.order === "ascend" ) {
      orderBy = `${sorterField},asc`;
    } else if (sorter.order === "descend") {
      orderBy = `${sorterField},desc`;
    }

    this.setState({
      page: page.current,
      sort: orderBy
    }, ()=> {
      if (from === 'loyola') {
        this.props.handleDataParticipantList(this.props.token, 1, this.state.univEventId, orderBy, size, page.current, search);
      }else {
        this.props.handleDataParticipantList(this.props.token, 2, this.state.univEventId, orderBy, size, page.current, search);
      }
    })
  }

  handleSearch = key => {
    const {loyolaActive, page, size, sort} = this.state
    this.setState({
      search: key
    })
    if (loyolaActive) {
      this.props.handleDataParticipantList(this.props.token, 1, this.state.univEventId, sort, size, page, key);
    }else {
      this.props.handleDataParticipantList(this.props.token, 2, this.state.univEventId, sort, size, page, key);
    }
  }

  render() {
    const columnsTableLoyola = [
      {
        title: "Nama",
        dataIndex: "univEventParticipantName",
        key: "univEventParticipantName",
        sorter: true,
      },
      {
        title: "Email",
        dataIndex: "univEventParticipantEmail",
        key: "univEventParticipantEmail",
        sorter: true,
      },
      {
        title: "Status",
        dataIndex: "univEventParticipantRole",
        key: "univEventParticipantRole",
        sorter: true,
      },
    ];

    const columnsTablePublic = [
      {
        title: "Nama",
        dataIndex: "univEventParticipantName",
        key: "univEventParticipantName",
        sorter: true,
      },
      {
        title: "Email",
        dataIndex: "univEventParticipantEmail",
        key: "univEventParticipantEmail",
        sorter: true,
      },
      {
        title: "Status",
        dataIndex: "univEventParticipantRole",
        key: "univEventParticipantRole",
        sorter: true,
      },
    ];

    const { visiblePartisipan, loyolaActive, page } = this.state;
    const { dataDetail, dataParticipantList } = this.props
    return (
      <div className="root">
        <Breadcrumb className="breadcrumb_style">
          <Breadcrumb.Item>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => this.props.history.push(`/daftar-acara-universitas`)}
            >
              Daftar Acara{" "}
            </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="pathNow_style">
            Detail Acara
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card
          loading={dataDetail.status === 'loading'}
          className="bodyCard_style"
        >
          <UIDetailAdminUnivDaftarAcara
            from={"not-presentation"}
            isMobile={this.props.isMobile}
            dataDetail={dataDetail ? dataDetail.data : []}
            handleOpenModalInfo={() => this.openPartisipan()}
          />
        </Card>
        <UIModalParticipantDetail
          isMobile={this.props.isMobile}
          visible={visiblePartisipan}
          handleBack={() => this.closeModal()}
          dataParticipantLoyola={dataParticipantList ? dataParticipantList.data : []}
          dataParticipantPublic={dataParticipantList ? dataParticipantList.data : []}
          columnsLoyola={columnsTableLoyola}
          columnsPublic={columnsTablePublic}
          loadingLoyola={dataParticipantList.status}
          loadingPublic={dataParticipantList.status}
          changeButton={(val) => this.changeButton(val)}
          loyolaActive={loyolaActive}
          totalDataLoyola={dataParticipantList.totalData}
          handleTableChangeLoyola={(pageNow, filters, sorter) =>
            this.handleTableChange(pageNow, filters, sorter, "loyola")
          }
          page={page}
          handleSearch={(key) => this.handleSearch(key)}
          // totalDataPublic={dataParticipantList.totalData}
          handleTableChangePublic={(pageNow, filters, sorter) =>
            this.handleTableChange(pageNow, filters, sorter, "public")
          }
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleDataDetailAcara: (token, id) => dispatch(getDetailAcara.getDataDetailDaftarAcara(token, id)),
    handleDataParticipantList: (token, roleGroupId, univEventId, search, sort, page, size) =>
      dispatch(getDetailAcara.getDataAcaraParticipant(token, roleGroupId, univEventId, search, sort, page, size)),
  };
};

const mapStateToProps = (state) => {
  return {
    dataDetail: state.getDetailDaftarAcara,
    dataParticipantList: state.getParticipantList,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminUnivDaftarAcaraDeatil);
