import types from "../constants";
import lsEvent from "../api/lifeSharingEvent"

const getDataEvent = (token, sort, size, page, search) => dispatch => {
  dispatch({
    type: types.GET_EVENT_LIFE_SHARING_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return lsEvent.getEvent(token, sort, size, page, search).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_EVENT_LIFE_SHARING_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_EVENT_LIFE_SHARING_SUCCESS,
        payload: result
      })
    }
  })
}

const getDataEventDetail = (token, id) => dispatch => {
  dispatch({
    type: types.GET_EVENT_DETAIL_LIFE_SHARING_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return lsEvent.getEventDetail(token, id).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_EVENT_DETAIL_LIFE_SHARING_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_EVENT_DETAIL_LIFE_SHARING_SUCCESS,
        payload: result
      })
    }
  })
}

const getDataEventParticipant = (token, id, group, sort, size, page, search) => dispatch => {
  dispatch({
    type: types.GET_EVENT_PARTICIPANT_LIFE_SHARING_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return lsEvent.getEventParticipant(token, id, group, sort, size, page, search).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_EVENT_PARTICIPANT_LIFE_SHARING_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_EVENT_PARTICIPANT_LIFE_SHARING_SUCCESS,
        payload: result
      })
    }
  })
}

const shareEvent = (token, id) => dispatch => {
  dispatch({
    type: types.POST_EMAIL_LIFE_SHARING_EVENT_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return lsEvent.postEmail(token, id).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.POST_EMAIL_LIFE_SHARING_EVENT_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.POST_EMAIL_LIFE_SHARING_EVENT_SUCCESS,
        payload: result
      })
    }
  })
}

const getDataLOVSharingType = (token) => dispatch => {
  dispatch({
    type: types.GET_LOV_LIFE_SHARING_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return lsEvent.getLOVSharingType(token).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_LOV_LIFE_SHARING_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_LOV_LIFE_SHARING_SUCCESS,
        payload: result
      })
    }
  })
}

const createLSEvent = (token, body) => dispatch => {
  dispatch({
    type: types.POST_LIFE_SHARING_EVENT_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return lsEvent.postEventLifeSharing(token, body).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.POST_LIFE_SHARING_EVENT_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.POST_LIFE_SHARING_EVENT_SUCCESS,
        payload: result
      })
    }
  })
}

const deleteDataEvent = (token, id, body) => dispatch => {
  dispatch({
    type: types.PUT_DELETE_LIFE_SHARING_EVENT_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return lsEvent.deleteEvent(token, id, body).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.PUT_DELETE_LIFE_SHARING_EVENT_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.PUT_DELETE_LIFE_SHARING_EVENT_SUCCESS,
        payload: result
      })
    }
  })
}

export default {
  getDataEvent,
  getDataEventDetail,
  getDataEventParticipant,
  shareEvent,
  getDataLOVSharingType,
  createLSEvent,
  deleteDataEvent,
}
