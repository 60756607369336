import types from '../constants'

const initialState = {
  status: 'loading',
  data: []
}

export default function manageAdminUniv(state = initialState, action) {
  switch (action.type) {
    case types.GET_ADMIN_UNIV_LOADING:
      return {
        ...state,
        ...action.payload
      }
    case types.GET_ADMIN_UNIV_SUCCESS:
      return {
        isSuccess: true,
        ...state,
        ...action.payload
      }
    case types.GET_ADMIN_UNIV_FAILED:
      return {
        isSuccess: false,
        ...state,
        ...action.payload
      }
    default:
      return { ...initialState, ...state }
  }
}
