const GlobalVideoCheck = {
    checking(url) {
        if (url.includes('https://www.youtube.com/embed/', 0)) {
            return true
        }else {
            return false
        }
    }
}
 
export {GlobalVideoCheck};