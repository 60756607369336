import React from 'react'
import { Row, Col, Modal, Card } from 'antd'
import { CloseOutlined } from "@ant-design/icons";
import '../../global/global.css'
import { UItable, UISearch } from '../index'

const ModalParticipantCore = props => {
  const [tab, setTab] = React.useState(true);
  return (
    <>
      <Modal
        visible={props.visible}
        width={props.isMobile ? '100%' : "60%"}
        className={props.isMobile ? 'modalMobile' : 'modalImage'}
        bodyStyle={{ padding: 0 }}
        centered
        onCancel={() => props.handleBack()}
        footer={null}
        closable
        maskClosable
        style={{ borderRadius: '25px' }}
        closeIcon={<CloseOutlined style={{ color: '#fff' }} />}
      >
        <>
          <Row className="modalHead">
            <h1 className="modalTitle">Daftar Peserta</h1>
            <Col span={24}>
              <button className={tab ? "buttonModeFocus" : "buttonMode"} autoFocus onClick={() => {
                props.changeButton(true);
                setTab(true);
              }}>Loyola</button>
              <button className={!tab ? "buttonModeFocus" : "buttonMode"} onClick={() => {
                props.changeButton(false);
                setTab(false);
              }}>Umum</button>
            </Col>
          </Row>
          <Card bordered={false} style={{ minHeight: '300px' }}>
            <UISearch width={'30%'} placeholder='Search' handleSearch={key => props.handleSearch(key)} />
            {props.loyolaActive ? (
              <UItable
                dataTable={props.dataParticipantLoyola}
                withRows={false}
                isMobile={props.isMobile}
                columns={props.columnsLoyola}
                loading={props.loadingLoyola}
                page={props.page}
                totalData={props.totalDataLoyola}
                handleTableChange={props.handleTableChangeLoyola}
              />
            ) : (
                <UItable
                  dataTable={props.dataParticipantPublic}
                  withRows={false}
                  isMobile={props.isMobile}
                  columns={props.columnsPublic}
                  loading={props.loadingPublic}
                  page={props.page}
                  totalData={props.totalDataPublic}
                  handleTableChange={props.handleTableChangePublic}
                />
              )}
          </Card>
        </>
      </Modal>
    </>
  )
}

export const UIModalParticipantDetail = ModalParticipantCore;
