import types from '../constants'

const initialState = {
	status: 'loading',
	data: []
}

export default function lovSession(state = initialState, action) {
  switch (action.type) {
    case types.GET_SESSION_LOADING: 
    return {
      ...state,
      ...action.payload
    }
    case types.GET_SESSION_SUCCESS: 
    return {
      isSuccess: true,
      ...state,
      ...action.payload
    }
    case types.GET_SESSION_FAILED: 
    return {
      isSuccess: false,
      ...state,
      ...action.payload
    }
    default:
      return { ...initialState, ...state }
    }
}