import { get, put } from 'axios'
import config from '../services/config'
const { BASE_URL_LOGIN, TIMEOUT } = config

const getDataKerjasama = (param) => {
  return get(`${BASE_URL_LOGIN}be/api/univPibslListAdmin?sort=${param.sort}&size=${param.size}&page=${param.page - 1}&search=${param.search}&univCatId=${param.cat}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${param.token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.Data.map(res =>{
      return {
        univName: res.univName,
        isRankByPibsl: res.isRankByPibsl,
        univId: res.univId,
        id: res.univId
      }
    })
    return {
      data: normalizeData,
      totalData: response.data.TotalData ? response.data.TotalData : 10,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      totalData: 0,
      error,
      data: []
    }
  })
}

const showKerjasama = (token, univId, userId) => {
  return put(`${BASE_URL_LOGIN}be/api/updateDisplayedPibslAdmin?univId=${univId}&usersId=${userId}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: '',
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: [],
    }
  })
}

const hideKerjasama = (token, univId, userId) => {
  return put(`${BASE_URL_LOGIN}be/api/updateHidePibslAdmin?univId=${univId}&usersId=${userId}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: '',
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: [],
    }
  })
}

export default {
  getDataKerjasama,
  showKerjasama,
  hideKerjasama
}