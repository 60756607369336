import React from "react";
// import { Layout, Typography, Menu } from 'antd'
import { withRouter } from "react-router-dom";
import './index.css'
const HeaderComponent = (props) => {

  const doLogout = () => {
    sessionStorage.clear()
    props.history.push("/")
    props.logout()
  }
  return (
    <div className='header'>
      <span>Hi, </span>
      <span className="bold_text" style={{marginRight: props.isMobile ? '10px' : '20px'}}>{props.name}</span>
      <span> | </span>
      <span className="bold_text" style={{marginLeft: props.isMobile ? '10px' : '20px', cursor:'pointer'}} onClick={() => doLogout()}>Logout</span>
    </div>
  )
};

export default withRouter(HeaderComponent);
