import types from '../constants'
import admin from '../api/admin'

const getDataAdmin = (token, sort, size, page, search) => dispatch => {
  dispatch({
    type: types.GET_ADMIN_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return admin.getAdmin(token, sort, size, page, search).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_ADMIN_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_ADMIN_SUCCESS,
        payload: result
      })
    }
  })
}

const getDataDetailAdmin = (token, id) => dispatch => {
  dispatch({
    type: types.GET_ADMIN_DETAIL_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return admin.getAdminDetail(token, id).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_ADMIN_DETAIL_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_ADMIN_DETAIL_SUCCESS,
        payload: result
      })
    }
  })
}

const regisAdmin = (token, body) => dispatch => {
  dispatch({
    type: types.POST_MANAGE_ADMIN_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return admin.postAdmin(token, body).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.POST_MANAGE_ADMIN_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.POST_MANAGE_ADMIN_SUCCESS,
        payload: result
      })
    }
  })
}

const updateAdmin = (token, body, id) => dispatch => {
  dispatch({
    type: types.PUT_MANAGE_ADMIN_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return admin.editAdmin(token, body, id).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.PUT_MANAGE_ADMIN_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.PUT_MANAGE_ADMIN_SUCCESS,
        payload: result
      })
    }
  })
}

const getDataAdminApproval = (token, sort, size, page, search) => dispatch => {
  dispatch({
    type: types.GET_ADMIN_APPROVAL_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return admin.getAdminApproval(token, sort, size, page, search).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_ADMIN_APPROVAL_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_ADMIN_APPROVAL_SUCCESS,
        payload: result
      })
    }
  })
}
const getDataDetailAdminApproval = (token, id) => dispatch => {
  dispatch({
    type: types.GET_ADMIN_APPROVAL_DETAIL_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return admin.getAdminApprovalDetail(token, id).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_ADMIN_APPROVAL_DETAIL_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_ADMIN_APPROVAL_DETAIL_SUCCESS,
        payload: result
      })
    }
  })
}

const getUnivListEntrance = (token, id) => dispatch => {
  dispatch({
    type: types.GET_ENTRANCE_LIST_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return admin.getEntranceList(token, id).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_ENTRANCE_LIST_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_ENTRANCE_LIST_SUCCESS,
        payload: result
      })
    }
  })
}

const updateAdminApproval = (token, body, id) => dispatch => {
  dispatch({
    type: types.PUT_ADMIN_APPROVAL_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return admin.editAdminApproval(token, body, id).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.PUT_ADMIN_APPROVAL_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.PUT_ADMIN_APPROVAL_SUCCESS,
        payload: result
      })
    }
  })
}

const createAdminApproval = (token, body, id) => dispatch => {
  dispatch({
    type: types.PUT_APPROVE_ADMIN_APPROVAL_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return admin.createAdminApproval(token, body, id).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.PUT_APPROVE_ADMIN_APPROVAL_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.PUT_APPROVE_ADMIN_APPROVAL_SUCCESS,
        payload: result
      })
    }
  })
}

const rejectAdminApproval = (token, body, id) => dispatch => {
  dispatch({
    type: types.PUT_REJECT_ADMIN_APPROVAL_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return admin.deleteAdminApproval(token, body, id).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.PUT_REJECT_ADMIN_APPROVAL_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.PUT_REJECT_ADMIN_APPROVAL_SUCCESS,
        payload: result
      })
    }
  })
}

const getDataAdminUniv = (token, sort, size, page, search) => dispatch => {
  dispatch({
    type: types.GET_ADMIN_UNIV_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return admin.getAdminUniv(token, sort, size, page, search).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_ADMIN_UNIV_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_ADMIN_UNIV_SUCCESS,
        payload: result
      })
    }
  })
}

export default {
  getDataAdmin,
  getDataDetailAdmin,
  regisAdmin,
  updateAdmin,
  getDataAdminApproval,
  getDataDetailAdminApproval,
  getUnivListEntrance,
  updateAdminApproval,
  createAdminApproval,
  rejectAdminApproval,
  getDataAdminUniv
}
