import React, { useState, useEffect } from 'react'

import {
	Modal,
	Form,
	Row,
	Col,
	Typography,
	Input,
	Select,
	Button,
	Radio,
	DatePicker,
	TimePicker, notification,
} from 'antd'
import { post } from 'axios'
import config from '../../services/config'
import moment from "moment";

const { BASE_URL_LOGIN, TIMEOUT } = config
const { Title } = Typography
const { TextArea } = Input
const { Option } = Select

const ModalLifeSharingEvent = props => {

	const [loadingUpload, setLoadingUpload] = useState(false);
	const [fileName, setFileName] = useState(null);
	const [filePath, setFilePath] = useState(null);
	const [presentasi, setPresentasi] = useState(true);
	const [form] = Form.useForm();
	// if (props.visibleRegis === false) {
	//   form.resetFields();
	// }

	useEffect(() => {
		setFilePath(null);
		setFileName(null);
		form.resetFields();
	}, [props.visibleRegis]);

	const handleResetForm = () => {
		form.resetFields();
		setFileName(null)
		setFilePath(null)
	};

	const fileInput = React.createRef();

	const openFileBrowser = () => {
		fileInput.current.click();
	};

	const uploadAssignmentLetter = (data) => {
		let token = props.tokenUser;
		setLoadingUpload(true);
		post(`${BASE_URL_LOGIN}be/api/uploadSharingEventAdmin`, data, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
			timeout: TIMEOUT
		}).then(res => {
			if (res && res.status ? res.data.Status === "ACCEPTED" : false) {
				notification.success({
					placement: 'bottomRight',
					message: 'Success',
					description: res.data.Message ? res.data.Message : 'upload success',
				});
				setFilePath(res.data.FilePath ? res.data.FilePath : "")
				setFileName(res.data.FileName ? res.data.FileName : "")
			} else {
				notification.error({
					placement: 'bottomRight',
					message: 'Error',
					description: res.data.Message ? res.data.Message : 'upload Failed',
				});
			}
			setLoadingUpload(false);
		}).catch(() => {
			notification.error({
				placement: 'bottomRight',
				message: 'Error',
				description: 'Upload Failed'
			});
			setLoadingUpload(false);
		});
	}

	const fileHandler = event => {
		if (event.target.files.length) {
			const file = event.target.files[0];
			const fileNameValue = file.name;
			// setFileName(fileNameValue);
			const formData = new FormData();
			formData.append('file', file);
			uploadAssignmentLetter(formData);
		}
	};

	const onFinishFailed = errorInfo => {
		console.log('Failed:', errorInfo);
	};

	const checkFinish = (values) => {
		const tempFinish = {
			sharingTypeId: values.kategori,
			sharingEventSpeaker: values.narasumber,
			sharingEventKeklGradYear: values.angkatan ? moment(values.angkatan).format('YYYY') : '',
			sharingEventDesc: values.deskripsi,
			sharingEventDay: values.date ? moment(values.date).format('dddd') : '',
			sharingEventDate: values.date ? moment(values.date).format('YYYY-MM-DD') : '',
			sharingEventTime: values.time ? moment(values.time).format("HH:mm:ss") : '',
			sharingEventLoyolaQuota: parseInt(values.kuotaLoyola),
			sharingEventOtherQuota: parseInt(values.kuotaUmum),
			sharingEventThumbnail: filePath,
			isOnline: presentasi,
			sharingEventMedia: presentasi === true ? values.aplikasi : values.place,
			sharingEventMediaUrl: presentasi === true ? values.urlApp : null,
			sharingEventMediaJoinId: presentasi === true ? values.username : null,
			sharingEventMediaJoinPwd: presentasi === true ? values.password : null,
			userId: sessionStorage.getItem("userId"),
		}
		// console.log(tempFinish);
		props.onFinish(tempFinish);
	};

	const handleRadioChange = val => {
		setPresentasi(val.target.value);
		let typePresent = val.target.value;

		if (typePresent) {
			form.setFieldsValue({
				place: null,
			})
		} else {
			form.setFieldsValue({
				aplikasi: null,
				username: null,
				password: null,
				urlApp: null,
			})
		}
	}

	function disabledDate(current) {
		return current && current <= moment();
	}

	function disabledYear(current) {
		return current && current >= moment();
	}

	const format = 'dddd, YYYY-MM-DD';

	return (

        <Modal
            visible={props.visibleRegis}
            footer={null}
            title='none'
            className={props.isMobile ? 'modal_regisMobile' :'modal_regis'}
            width={props.isMobile ? '100%' : 633}
            centered
            onCancel={() => props.close()}
            destroyOnClose={true}
        >
            <Row gutter={[10, 0]}>
                <Col span={24}>
                    <div className='headerForm'>
                        <Title className='titleModal' level={4}>{props.title}</Title>
                    </div>
                    <div className='dividerRegis' />
                </Col>
                <Col span={24}>
                    <Form
                        form={form}
                        onFinish={values => checkFinish(values)}
                        onFinishFailed={onFinishFailed}
                    >
                        <Row gutter={[10, 0]}>
                            <Col span={23}>
                                <Form.Item
                                    name='kategori'
                                    rules={[{
                                        required: true,
                                        message: 'Please select category!'
                                    }]}
                                >
                                    <Select
                                        placeholder="Kategori Life Sharing"
                                        className="select"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            props.listLSCat ?
                                                props.listLSCat.map((item, index) =>
                                                (
                                                    <Option key={item.id + 'Institusi' + index}
                                                        value={item.id}>{item.sharingTypeName}</Option>
                                                )
                                                ) :
                                                (
                                                    <></>
                                                )
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={1}>
                                <span className="mandatoryLarge">&#8727;</span>
                            </Col>
                            <Col span={23}>
                                <Form.Item
                                    name="banner"
                                    rules={[{
                                        required: true,
                                        message: 'Please upload image banner!'
                                    }]}
                                >
                                    <Row>
                                        <Col span={14} style={{ margin: 'auto' }}>
                                            <span className='mandatory'>* </span>
                                            <span className='logoInstitusi'>Banner (1 JPG/PNG & Max 1 MB)</span>
                                        </Col>
                                        <Col span={10} style={{ textAlign: 'right' }}>
                                            <span
                                                style={{
                                                    marginRight: 15
                                                }}
                                            >
                                                {fileName ? (
                                                    fileName.length > 30 ? fileName.substr(0, 30) + '...' : fileName
                                                ) : ''
                                                }
                                            </span>
                                            <input
                                                type="file"
                                                hidden
                                                onChange={fileHandler}
                                                ref={fileInput}
                                            />
                                            <Button
                                                onClick={openFileBrowser}
                                                type="primary"
                                                className='buttonUpload'
                                                loading={loadingUpload}
                                            >
                                                Upload
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col span={23}>
                                <Form.Item
                                    name='narasumber'
                                    rules={[{
                                        required: true,
                                        message: 'Please input speaker full name!'
                                    }]}
                                >
                                    <Input disabled={props.fromLate} size="large" className='input_style'
                                        placeholder={"Nama Lengkap Narasumber"} />
                                </Form.Item>
                            </Col>
                            <Col span={1}>
                                <span className="mandatoryLarge">&#8727;</span>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    name='angkatan'
                                    rules={[{
                                        required: true,
                                        message: 'Please select grad year!'
                                    }]}
                                >
                                    <DatePicker
                                        placeholder="Angkatan / Tahun Lulus KEKL"
                                        className="select"
                                        picker="year"
                                        disabledDate={disabledYear}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={1}>
                                <span className="mandatoryLarge">&#8727;</span>
                            </Col>
                            <Col span={23}>
                                <Form.Item
                                    name='deskripsi'
                                    rules={[{
                                        required: true,
                                        message: 'Please input description!'
                                    }]}
                                >
                                    <TextArea
                                        disabled={props.fromLate}
                                        maxLength={256}
                                        size="large"
                                        className='input_style'
                                        placeholder={"Deskripsi Acara / Narasumber"}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={1}>
                                <span className="mandatoryLarge">&#8727;</span>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    name='date'
                                    rules={[{
                                        required: true,
                                        message: 'Please select date!'
                                    }]}
                                >
                                    <DatePicker
                                        format={format}
                                        placeholder="Hari / Tanggal Presentasi"
                                        className="select"
                                        disabledDate={disabledDate}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={1}>
                                <span className="mandatoryLarge">&#8727;</span>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    name='time'
                                    rules={[{
                                        required: true,
                                        message: 'Please pick time!'
                                    }]}
                                >
                                    <TimePicker
                                        placeholder="Waktu Presentasi"
                                        className="select"
                                        showSearch
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={1}>
                                <span className="mandatoryLarge">&#8727;</span>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    name='kuotaLoyola'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input this section!',
                                        },
                                        {
                                            pattern: /^[1-9]\d*$/gm,
                                            message: `Please input number correctly`,
                                        },
                                    ]}
                                >
                                    <Input disabled={props.fromLate} size="large" maxLength={5} className='input_style'
                                        placeholder={"Kuota Loyola"} />
                                </Form.Item>
                            </Col>
                            <Col span={1}>
                                <span className="mandatoryLarge">&#8727;</span>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    name='kuotaUmum'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input this section!',
                                        },
                                        {
                                            pattern: /^[1-9]\d*$/gm,
                                            message: `Please input number correctly`,
                                        },
                                    ]}
                                >
                                    <Input disabled={props.fromLate} size="large" maxLength={5} className='input_style'
                                        placeholder={"Kuota Umum"} />
                                </Form.Item>
                            </Col>
                            <Col span={1}>
                                <span className="mandatoryLarge">&#8727;</span>
                            </Col>
                            <Col span={23}>
                                <Form.Item
                                    name='settingPresentasi'
                                >
                                    <span className='mandatory'>* </span>
                                    <span className='logoInstitusi'>Setting: </span>
                                    <Radio.Group
                                        className="radio_style"
                                        onChange={handleRadioChange}>
                                        <Radio value={true}>Online</Radio>
                                        <Radio value={false}>Offline</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            {
                                presentasi === true ? (
                                    <>
                                        <Col span={11}>
                                            <Form.Item
                                                name='aplikasi'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input apps name!'
                                                    },
                                                ]}
                                            >
                                                <Input disabled={props.fromLate} maxLength={256} size="large"
                                                    className='input_style'
                                                    placeholder={"Nama Aplikasi Daring"} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={1}>
                                            <span className="mandatoryLarge">&#8727;</span>
                                        </Col>
                                        <Col span={11}>
                                            <Form.Item
                                                name='urlApp'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input Link / URL!'
                                                    },
                                                    {
                                                        type: "url",
                                                        message: 'Please input valid Link / URL!'
                                                    },
                                                ]}
                                            >
                                                <Input disabled={props.fromLate} maxLength={256} size="large"
                                                    className='input_style'
                                                    placeholder={"URL Room"} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={1}>
                                            <span className="mandatoryLarge">&#8727;</span>
                                        </Col>
                                        <Col span={11}>
                                            <Form.Item
                                                name='username'
                                            >
                                                <Input disabled={props.fromLate} maxLength={256} size="large"
                                                    className='input_style'
                                                    placeholder={"Username"} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={1}>
                                        </Col>
                                        <Col span={11}>
                                            <Form.Item
                                                name='password'
                                                hasFeedback
                                                rules={[
                                                    {
                                                        min: 5,
                                                        message: "Password can't less than 5.",
                                                    },
                                                ]}
                                            >
                                                <Input.Password className='input_style' placeholder={"Password"} />
                                            </Form.Item>
                                        </Col>
                                    </>
                                ) : (
                                        <>
                                            <Col span={23}>
                                                <Form.Item
                                                    name='place'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input place name!'
                                                        },
                                                    ]}
                                                >
                                                    <Input disabled={props.fromLate} maxLength={256} size="large"
                                                        className='input_style'
                                                        placeholder={"Nama Tempat/Lokasi"} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={1}>
                                                <span className="mandatoryLarge">&#8727;</span>
                                            </Col>
                                        </>
                                    )
                            }
                            <Col span={24}>
                                <Form.Item
                                    style={{
                                        textAlign: 'right',
                                        marginBottom: '0px'
                                    }}
                                >
                                    <Button
                                        type="link"
                                        className='resetButton'
                                        size={'small'}
                                        onClick={() => handleResetForm()}
                                    >
                                        Reset
                                    </Button>
                                    <Button
                                        type="primary"
                                        htmlType='submit'
                                        className='updateButton'
                                        loading={props.loadingSubmit}
                                        size={'small'}
                                    >
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Modal>
    )
}

export const UIModalLifeSharingEvent = ModalLifeSharingEvent
