import types from '../../constants'

const initialState = {
	status: 'loading',
	data: []
}

export default function getDetailDaftarPersetujuanKonten(state = initialState, action) {
	switch (action.type) {
    case types.GET_DETAIL_DAFTAR_PERSETUJUAN_KONTEN_LOADING: 
    return {
      ...state,
      ...action.payload
    }
    case types.GET_DETAIL_DAFTAR_PERSETUJUAN_KONTEN_SUCCESS: 
    return {
      isSuccess: true,
      ...state,
      ...action.payload
    }
    case types.GET_DETAIL_DAFTAR_PERSETUJUAN_KONTEN_FAILED: 
    return {
      isSuccess: false,
      ...state,
      ...action.payload
    }
    default:
      return { ...initialState, ...state }
    }
}