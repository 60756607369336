import types from "../constants";
import daftarPersetujuanKonten from "../api/daftarPersetujuanKonten";

const getData = (token, param) => dispatch => {
   dispatch({
      type: types.GET_DAFTAR_PERSETUJUAN_KONTEN_LOADING,
      payload: {
         data: [],
         totalData: 0,
         status: "loading",
         error: ""
      }
   })
   return daftarPersetujuanKonten.getData(token, param).then(result => {
      if (result.status === "error") {
         return dispatch({
            type: types.GET_DAFTAR_PERSETUJUAN_KONTEN_FAILED,
            payload: result
         });
      } else {
         return dispatch({
            type: types.GET_DAFTAR_PERSETUJUAN_KONTEN_SUCCESS,
            payload: result
         });
      }
   })
}

const getDetail = (token, univId, param) => dispatch => {
   dispatch({
      type: types.GET_DETAIL_DAFTAR_PERSETUJUAN_KONTEN_LOADING,
      payload: {
         data: [],
         totalData: 0,
         status: "loading",
         error: ""
      }
   })
   return daftarPersetujuanKonten.getDetail(token, univId, param).then(result => {
      if (result.status === "error") {
         return dispatch({
            type: types.GET_DETAIL_DAFTAR_PERSETUJUAN_KONTEN_FAILED,
            payload: result
         });
      } else {
         return dispatch({
            type: types.GET_DETAIL_DAFTAR_PERSETUJUAN_KONTEN_SUCCESS,
            payload: result
         });
      }
   })
}

const getJalurMasuk = (token, univId, status) => dispatch => {
   dispatch({
      type: types.GET_JALUR_MASUK_DAFTAR_PERSETUJUAN_KONTEN_LOADING,
      payload: {
         data: [],
         totalData: 0,
         status: "loading",
         error: ""
      }
   })
   return daftarPersetujuanKonten.getJalurMasuk(token, univId, status).then(result => {
      if (result.status === "error") {
         return dispatch({
            type: types.GET_JALUR_MASUK_DAFTAR_PERSETUJUAN_KONTEN_FAILED,
            payload: result
         });
      } else {
         return dispatch({
            type: types.GET_JALUR_MASUK_DAFTAR_PERSETUJUAN_KONTEN_SUCCESS,
            payload: result
         });
      }
   })
}

const approveDaftarPersetujuanKonten = (token, univId, body) => dispatch => {
   dispatch({
      type: types.APPROVE_DAFTAR_PERSETUJUAN_KONTEN_LOADING,
      payload: {
         data: [],
         totalData: 0,
         status: "loading",
         error: ""
      }
   })
   return daftarPersetujuanKonten.approveDaftarPersetujuanKonten(token, univId, body).then(result => {
      if (result.status === "error") {
         return dispatch({
            type: types.APPROVE_DAFTAR_PERSETUJUAN_KONTEN_FAILED,
            payload: result
         });
      } else {
         return dispatch({
            type: types.APPROVE_DAFTAR_PERSETUJUAN_KONTEN_SUCCESS,
            payload: result
         });
      }
   })
}

const rejectDaftarPersetujuanKonten = (token, univId, body) => dispatch => {
   dispatch({
      type: types.REJECT_DAFTAR_PERSETUJUAN_KONTEN_LOADING,
      payload: {
         data: [],
         totalData: 0,
         status: "loading",
         error: ""
      }
   })
   return daftarPersetujuanKonten.rejectDaftarPersetujuanKonten(token, univId, body).then(result => {
      if (result.status === "error") {
         return dispatch({
            type: types.REJECT_DAFTAR_PERSETUJUAN_KONTEN_FAILED,
            payload: result
         });
      } else {
         return dispatch({
            type: types.REJECT_DAFTAR_PERSETUJUAN_KONTEN_SUCCESS,
            payload: result
         });
      }
   })
}

export default {
   getData,
   getDetail,
   getJalurMasuk,
   approveDaftarPersetujuanKonten,
   rejectDaftarPersetujuanKonten
}