import types from '../constants'
import manageBidiktiWeek from '../api/manageBidiktiWeek'

const getDataManageBidiktiWeek = (token, sort, size, page, search) => dispatch => {
  dispatch({
    type: types.GET_MANAGE_BIDIKTI_WEEK_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: [],
      totalData: 0
    }
  })
  return manageBidiktiWeek.getManageBidiktiWeek(token, sort, size, page, search).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.GET_MANAGE_BIDIKTI_WEEK_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_MANAGE_BIDIKTI_WEEK_SUCCESS,
        payload: result
      })
    }
  })
}

const getDataDetailManageBidiktiWeek = (token, id) => dispatch => {
  dispatch({
    type: types.GET_MANAGE_BIDIKTI_WEEK_DETAIL_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return manageBidiktiWeek.getManageBidiktiWeekDetail(token, id).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.GET_MANAGE_BIDIKTI_WEEK_DETAIL_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_MANAGE_BIDIKTI_WEEK_DETAIL_SUCCESS,
        payload: result
      })
    }
  })
}

const getDataSessionBW = (token, sort, size, page, search, id) => dispatch => {
  dispatch({
    type: types.GET_SESION_BW_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return manageBidiktiWeek.getDataSessionBW(token, sort, size, page, search, id).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.GET_SESION_BW_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_SESION_BW_SUCCESS,
        payload: result
      })
    }
  })
}

const getDataScheduleBW = (token, sort, size, page, search, id) => dispatch => {
  dispatch({
    type: types.GET_SCHEDULLE_BW_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return manageBidiktiWeek.getDataScheduleBW(token, sort, size, page, search, id).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.GET_SCHEDULLE_BW_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_SCHEDULLE_BW_SUCCESS,
        payload: result
      })
    }
  })
}

const createBW = (token, body) => dispatch => {
  dispatch({
    type: types.POST_MANAGE_BW_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return manageBidiktiWeek.createBW(token, body).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.POST_MANAGE_BW_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.POST_MANAGE_BW_SUCCESS,
        payload: result
      })
    }
  })
}

const createSchedule = (token, body) => dispatch => {
  dispatch({
    type: types.POST_SCHEDULE_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return manageBidiktiWeek.createSchedule(token, body).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.POST_SCHEDULE_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.POST_SCHEDULE_SUCCESS,
        payload: result
      })
    }
  })
}

const createSesi = (token, body) => dispatch => {
  dispatch({
    type: types.POST_SESION_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return manageBidiktiWeek.createSesi(token, body).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.POST_SESION_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.POST_SESION_SUCCESS,
        payload: result
      })
    }
  })
}

const updateBW = (token, body, id) => dispatch => {
  dispatch({
    type: types.PUT_MANAGE_BW_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return manageBidiktiWeek.updateBW(token, body, id).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.PUT_MANAGE_BW_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.PUT_MANAGE_BW_SUCCESS,
        payload: result
      })
    }
  })
}

const updateSesi = (token, body, id) => dispatch => {
  dispatch({
    type: types.PUT_SESION_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return manageBidiktiWeek.updateSesi(token, body, id).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.PUT_SESION_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.PUT_SESION_SUCCESS,
        payload: result
      })
    }
  })
}

const deleteSchedule = (token, body, id) => dispatch => {
  dispatch({
    type: types.DELETE_SCHEDULE_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return manageBidiktiWeek.deleteSchedule(token, body, id).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.DELETE_SCHEDULE_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.DELETE_SCHEDULE_SUCCESS,
        payload: result
      })
    }
  })
}

const deleteSesi = (token, body, id) => dispatch => {
  dispatch({
    type: types.DELETE_SESION_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return manageBidiktiWeek.deleteSesi(token, body, id).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.DELETE_SESION_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.DELETE_SESION_SUCCESS,
        payload: result
      })
    }
  })
}

export default {
  getDataManageBidiktiWeek,
  getDataDetailManageBidiktiWeek,
  getDataSessionBW,
  getDataScheduleBW,
  createBW,
  createSchedule,
  createSesi,
  updateBW,
  deleteSchedule,
  deleteSesi,
  updateSesi
}
