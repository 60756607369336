const GET_PROFILE_UNIVERSITAS_LOADING = 'GET_PROFILE_UNIVERSITAS_LOADING';
const GET_PROFILE_UNIVERSITAS_SUCCESS = 'GET_PROFILE_UNIVERSITAS_SUCCESS';
const GET_PROFILE_UNIVERSITAS_FAILED = 'GET_PROFILE_UNIVERSITAS_FAILED';
const GET_DETAIL_PROFILE_UNIVERSITAS_LOADING = 'GET_DETAIL_PROFILE_UNIVERSITAS_LOADING';
const GET_DETAIL_PROFILE_UNIVERSITAS_SUCCESS = 'GET_DETAIL_PROFILE_UNIVERSITAS_SUCCESS';
const GET_DETAIL_PROFILE_UNIVERSITAS_FAILED = 'GET_DETAIL_PROFILE_UNIVERSITAS_FAILED';
const GET_PROFILE_UNIVERSITAS_JALUR_MASUK_LOADING = 'GET_PROFILE_UNIVERSITAS_JALUR_MASUK_LOADING';
const GET_PROFILE_UNIVERSITAS_JALUR_MASUK_SUCCESS = 'GET_PROFILE_UNIVERSITAS_JALUR_MASUK_SUCCESS';
const GET_PROFILE_UNIVERSITAS_JALUR_MASUK_FAILED = 'GET_PROFILE_UNIVERSITAS_JALUR_MASUK_FAILED';
const GET_PROFILE_UNIVERSITAS_DETAIL_JALUR_MASUK_LOADING = 'GET_PROFILE_UNIVERSITAS_DETAIL_JALUR_MASUK_LOADING';
const GET_PROFILE_UNIVERSITAS_DETAIL_JALUR_MASUK_SUCCESS = 'GET_PROFILE_UNIVERSITAS_DETAIL_JALUR_MASUK_SUCCESS';
const GET_PROFILE_UNIVERSITAS_DETAIL_JALUR_MASUK_FAILED = 'GET_PROFILE_UNIVERSITAS_DETAIL_JALUR_MASUK_FAILED';
const POST_LOGO_PROFILE_UNIVERSITAS_LOADING = 'POST_LOGO_PROFILE_UNIVERSITAS_LOADING';
const POST_LOGO_PROFILE_UNIVERSITAS_SUCCESS = 'POST_LOGO_PROFILE_UNIVERSITAS_SUCCESS';
const POST_LOGO_PROFILE_UNIVERSITAS_FAILED = 'POST_LOGO_PROFILE_UNIVERSITAS_FAILED';
const POST_BANNER_PROFILE_UNIVERSITAS_LOADING = 'POST_BANNER_PROFILE_UNIVERSITAS_LOADING';
const POST_BANNER_PROFILE_UNIVERSITAS_SUCCESS = 'POST_BANNER_PROFILE_UNIVERSITAS_SUCCESS';
const POST_BANNER_PROFILE_UNIVERSITAS_FAILED = 'POST_BANNER_PROFILE_UNIVERSITAS_FAILED';
const POST_HUMAS_PROFILE_UNIVERSITAS_LOADING = 'POST_HUMAS_PROFILE_UNIVERSITAS_LOADING';
const POST_HUMAS_PROFILE_UNIVERSITAS_SUCCESS = 'POST_HUMAS_PROFILE_UNIVERSITAS_SUCCESS';
const POST_HUMAS_PROFILE_UNIVERSITAS_FAILED = 'POST_HUMAS_PROFILE_UNIVERSITAS_FAILED';
const POST_FORM_PROFILE_UNIVERSITAS_LOADING = 'POST_FORM_PROFILE_UNIVERSITAS_LOADING';
const POST_FORM_PROFILE_UNIVERSITAS_SUCCESS = 'POST_FORM_PROFILE_UNIVERSITAS_SUCCESS';
const POST_FORM_PROFILE_UNIVERSITAS_FAILED = 'POST_FORM_PROFILE_UNIVERSITAS_FAILED';
const POST_JALUR_MASUK_PROFILE_UNIVERSITAS_LOADING = 'POST_JALUR_MASUK_PROFILE_UNIVERSITAS_LOADING';
const POST_JALUR_MASUK_PROFILE_UNIVERSITAS_SUCCESS = 'POST_JALUR_MASUK_PROFILE_UNIVERSITAS_SUCCESS';
const POST_JALUR_MASUK_PROFILE_UNIVERSITAS_FAILED = 'POST_JALUR_MASUK_PROFILE_UNIVERSITAS_FAILED';
const PUT_EDIT_PROFILE_UNIVERSITAS_LOADING = 'PUT_EDIT_PROFILE_UNIVERSITAS_LOADING';
const PUT_EDIT_PROFILE_UNIVERSITAS_SUCCESS = 'PUT_EDIT_PROFILE_UNIVERSITAS_SUCCESS';
const PUT_EDIT_PROFILE_UNIVERSITAS_FAILED = 'PUT_EDIT_PROFILE_UNIVERSITAS_FAILED';

export {
  GET_PROFILE_UNIVERSITAS_LOADING,
  GET_PROFILE_UNIVERSITAS_SUCCESS,
  GET_PROFILE_UNIVERSITAS_FAILED,
  GET_DETAIL_PROFILE_UNIVERSITAS_LOADING,
  GET_DETAIL_PROFILE_UNIVERSITAS_SUCCESS,
  GET_DETAIL_PROFILE_UNIVERSITAS_FAILED,
  GET_PROFILE_UNIVERSITAS_JALUR_MASUK_LOADING,
  GET_PROFILE_UNIVERSITAS_JALUR_MASUK_SUCCESS,
  GET_PROFILE_UNIVERSITAS_JALUR_MASUK_FAILED,
  GET_PROFILE_UNIVERSITAS_DETAIL_JALUR_MASUK_LOADING,
  GET_PROFILE_UNIVERSITAS_DETAIL_JALUR_MASUK_SUCCESS,
  GET_PROFILE_UNIVERSITAS_DETAIL_JALUR_MASUK_FAILED,
  POST_LOGO_PROFILE_UNIVERSITAS_LOADING,
  POST_LOGO_PROFILE_UNIVERSITAS_SUCCESS,
  POST_LOGO_PROFILE_UNIVERSITAS_FAILED,
  POST_BANNER_PROFILE_UNIVERSITAS_LOADING,
  POST_BANNER_PROFILE_UNIVERSITAS_SUCCESS,
  POST_BANNER_PROFILE_UNIVERSITAS_FAILED,
  POST_HUMAS_PROFILE_UNIVERSITAS_LOADING,
  POST_HUMAS_PROFILE_UNIVERSITAS_SUCCESS,
  POST_HUMAS_PROFILE_UNIVERSITAS_FAILED,
  POST_FORM_PROFILE_UNIVERSITAS_LOADING,
  POST_FORM_PROFILE_UNIVERSITAS_SUCCESS,
  POST_FORM_PROFILE_UNIVERSITAS_FAILED,
  POST_JALUR_MASUK_PROFILE_UNIVERSITAS_LOADING,
  POST_JALUR_MASUK_PROFILE_UNIVERSITAS_SUCCESS,
  POST_JALUR_MASUK_PROFILE_UNIVERSITAS_FAILED,
  PUT_EDIT_PROFILE_UNIVERSITAS_LOADING,
  PUT_EDIT_PROFILE_UNIVERSITAS_SUCCESS,
  PUT_EDIT_PROFILE_UNIVERSITAS_FAILED,
}
