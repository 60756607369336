import React, { Component } from "react";
import "../../global/global.css";
import { connect } from "react-redux";
import {
   Breadcrumb, Card, Row, Col, Button,
   Typography, notification, Select
} from "antd";
import {
   UItable, UISearch, UIModalCreateDaftarGaleri
} from "../../components";
import daftarGaleri from "../../actions/daftarGaleri";
import lov from "../../actions/lov";
import moment from "moment";


const { Text } = Typography;
const { Option } = Select;

class DaftarGaleri extends Component {
   constructor(props) {
      super(props);
      this.state = {
         visible: false,
         param: {
            univId: sessionStorage.getItem("univId"),
            sort: "",
            search: "",
            page: 1,
            size: 10
         },
         institusiName: sessionStorage.getItem("name"),
         fileName: [],
         formData: null,
         isGambar: false,
         isVideo: false,
         dataSelected: {},
         idSelected: undefined,
         isSelected: false,
         initData: {
            univGalleryEventDesc: undefined,
            univGalleryEventName: undefined,
            univGalleryEventDate: undefined,
            univGalleryEventTime: undefined,
            isOnline: undefined
         }
      }
   }

   componentDidMount() {
      this.props.handleData(this.props.token, this.state.param);
      this.props.handleLOVEventNameByUniv(this.props.token, this.state.param.univId, '');
   }

   handleTableChange = (page, filter, sorter) => {
      let sortBy = "";
      if ( sorter.field === "univEventName") {
         sortBy = "ue.univ_gallery_event_name";
      } else if ( sorter.field === "univEventDay" ) {
         sortBy = "ue.univ_gallery_event_day"
      } else if ( sorter.field === "univEventDate") {
         sortBy = "ue.univ_gallery_event_date"
      } else {
         sortBy = "ue.is_online"
      }

      let sort = "";
      if ( sorter.order === "ascend" ) {
         sort = `${sortBy},asc`;
      } else if ( sorter.order === "descend" ) {
         sort = `${sortBy},desc`;
      }

      this.setState({
         param: {
            ...this.state.param,
            sort,
            page: page.current
         }
      }, () => {
         this.props.handleData(this.props.token, this.state.param);
      })
   }

   handleSearch = key => {
      this.setState({
         param: {
            ...this.state.param,
            search: key,
            page: 1
         }
      }, () => {
         this.props.handleData(this.props.token, this.state.param);
      });
   }

   handleOpen = () => {
      this.setState({ visible: true });
   }

   handleClose = () => {
    this.setState({ 
      visible: false,
      isGambar: false,
      isVideo: false,
      listImageGaleri: [],
      fileName: [],
      isSelected: false,
      initData: {
        univGalleryEventDesc: undefined,
        univGalleryEventName: undefined,
        univGalleryEventDate: undefined,
        univGalleryEventTime: undefined,
        isOnline: undefined
      }
    });
   }

   handleRedirectDetail = id => {
      this.props.history.push(`/daftar-galeri-detail/${id}`);
   }

   handleChangeEventName = value => {
      this.props.handleLOVEventNameByUniv(this.props.token, this.state.param.univId, value);
   }

   handleChangeGambar = checked => {
      this.setState({ isGambar: checked });
   }
   
   handleChangeVideo = checked => {
      this.setState({ isVideo: checked });
   }

   handleChangeInputFile = (event) => {
      const files = event.target.files;
      let fileName = [];
      for ( let i = 0; i < files.length; i++ ) {
         fileName.push({name: files[i].name});
      }
      const formData = new FormData();
      for ( let i = 0; i < files.length; i++ ) {
         formData.append("file", files[i]);
      }
      this.setState({ fileName });
      this.handleUploadGaleri(this.props.token, formData);
   }

   handleUploadGaleri = (token, formData) => {
      this.props.handleUploadGaleri(token, formData).then( res => {
         if ( 
            res.payload.data.data ?
            res.payload.data.data.Status === "OK" : false 
         ) {
            notification.success({
               placement: 'bottomRight',
               message: 'Success',
               description: res.payload.data.data.Message ? res.payload.data.data.Message : "berhasil upload galeri.",
            });
            this.setState({ listImageGaleri: res.payload.data.data.Data });
         } else {
            notification.error({
               placement: 'bottomRight',
               message: 'Error',
               description: 
                  res.payload.data.data && 
                  res.payload.data.data.Message ? 
                  res.payload.data.data.Message : "gagal upload galeri",
            });
         }
      })
   }

   handleReset = () => {
    this.setState({
      isGambar: false,
      isVideo: false,
      listImageGaleri: [],
      fileName: [],
      isSelected: false,
      initData: {
        univGalleryEventDesc: undefined,
        univGalleryEventName: undefined,
        univGalleryEventDate: undefined,
        univGalleryEventTime: undefined,
        isOnline: undefined
      }
    })
   }

   onSelect = data => {
    const dataLOV = this.props.dataLOVEventNameByUniv.data
    let tempData = {}
    dataLOV.map(res => {
      if (res.id === data) {
        const date = moment(res.univEventDate)
        const time = moment(res.univEventTime, 'HH:mm:ss')
        tempData = {
          univGalleryEventDesc: res.univEventDesc,
          univGalleryEventName: res.univEventName,
          univGalleryEventDate: date,
          univGalleryEventTime: time,
          isOnline: res.isOnline
        }
      }
    })
    this.setState({
      idSelected: data,
      initData: tempData,
      isSelected: true
    })
    return tempData
   }

   handleSubmitDaftarGaleri = (token, body) => {
    this.props.handlePostDaftarGaleri(token, body).then( res => {
      if ( 
        res.payload.data.data &&
        res.payload.data.data.Status ?   
        res.payload.data.data.Status === "OK" : false 
        ) {
        notification.success({
          placement: 'bottomRight',
          message: 'Success',
          description: res.payload.data.data.Message ? res.payload.data.data.Message : "Berhasil menambahkan galeri.",
        });
        this.setState({
          param: {
            ...this.state.param,
            search: "",
            page: 1
          }
        }, () => {
          this.handleClose()
          this.props.handleData(this.props.token, this.state.param);
        })
      } else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: 
            res.payload.error && 
            res.payload.error.response.data.message ? 
            res.payload.error.response.data.message : "Gagal menambahkan galeri.",
        });
      }
    });
   }

   handleFinish = values => {
      let body = {}
      const listImage = this.state.listImageGaleri ? this.state.listImageGaleri.map( res => {
        return res.univGalleryImgPath
      }) : [];
      if (this.state.isSelected) {
        body = {
          userId: this.props.userId,
          univEventId: this.state.idSelected,
          listUnivGalleryImgPath: listImage,
          listUnivGalleryVideoUrl: values.linkYoutube ? values.linkYoutube : []
        }
      }else {
        body = {
          userId: this.props.userId,
          univGalleryEventName: values.univGalleryEventName,
          univGalleryEventDesc: values.univGalleryEventDesc,
          univGalleryEventDay: values.univGalleryEventDate ? moment(values.univGalleryEventDate).format('dddd') : '',
          univGalleryEventDate: values.univGalleryEventDate ? moment(values.univGalleryEventDate).format('YYYY-MM-DD') : '',
          univGalleryEventTime: values.univGalleryEventTime ? moment(values.univGalleryEventTime).format("HH:mm:ss") : '',
          isOnline: values.isOnline,
          univId: this.props.univId,
          listUnivGalleryImgPath: listImage,
          listUnivGalleryVideoUrl: values.linkYoutube ? values.linkYoutube : []
        }

      }
      this.handleSubmitDaftarGaleri(this.props.token, body);
   }

   render() {
      const { 
         visible, param, fileName, isVideo, isGambar,
         institusiName, initData, isSelected
      } = this.state;
      const { 
         dataDaftarGaleri, dataLOVEventNameByUniv, dataLOVDateByEvent, dataPostUploadGaleri,
         dataPostDaftarGaleri 
      } = this.props;

      const optionsSearch = dataLOVEventNameByUniv.data.map((res, index) => (
        <Option key={index} value={res.univEventId}>{res.result}</Option>
      ))

      const columnsTable = [
         {
            title: "Nama Acara",
            dataIndex: 'univEventName',
            key: 'univEventName',
            sorter: true,
            render: namaAcara => (
               <Text style={{ color: '#EF712B', fontWeight: 600, cursor: 'pointer' }}>{namaAcara}</Text>
            ),
            onCell: record => {
               return {
                  onClick: () => {
                     this.handleRedirectDetail(record.id)
                  }
               }
            }
         },
         {
            title: "Hari",
            dataIndex: "univEventDay",
            key: "univEventDay",
            sorter: true
         },
         {
            title: "Tanggal",
            dataIndex: "univEventDate",
            key: "univEventDate",
            sorter: true
         },
         {
            title: "Presentasi",
            dataIndex: "univIsOnline",
            key: "univIsOnline",
            sorter: true,
            render: univIsOnline => {
               if ( univIsOnline ) {
                  return <Text style={{color: '#EF712B', cursor: 'pointer', fontWeight: 600}}>Online</Text>
               } else {
                  return <Text style={{color: '#11174C', cursor: 'pointer', fontWeight: 600}}>Offline</Text>
               }
            }
         }
      ];

      return (
         <div className="root">
            <Breadcrumb className="breadcrumb_style">
               <Breadcrumb.Item className="pathNow_style">
                  <span style={{ cursor: 'pointer' }}>Daftar Galeri</span>
               </Breadcrumb.Item>
            </Breadcrumb>
            <Card className="bodyCard_style">
               <Row>
                  <Col span={8}>
                     <UISearch placeholder='Search' handleSearch={key => this.handleSearch(key)} />
                  </Col>
                  <Col span={16} style={{ textAlign: 'right' }}>
                     <Button className='updateButton' onClick={() => this.handleOpen()}>Tambah Galeri</Button>
                  </Col>
               </Row>
               <Card
                  bordered={false}
                  loading={false}
                  style={{ minHeight: '300px' }}>
                  <UItable
                    dataTable={dataDaftarGaleri.data}
                    columns={columnsTable}
                    isMobile={this.props.isMobile}
                    loading={dataDaftarGaleri.status}
                    page={param.page}
                    totalData={dataDaftarGaleri.totalData}
                    withRows={false}
                    handleTableChange={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter)}
                  />
               </Card>
            </Card>
            <UIModalCreateDaftarGaleri
               isMobile={this.props.isMobile}
               visible={visible}
               handleClose={() => this.handleClose()}
               institusiName={institusiName}
               optionsSearch={optionsSearch}
               isGambar={isGambar}
               isSelected={isSelected}
               initData={initData}
               handleChangeGambar={checked => this.handleChangeGambar(checked)}
               isVideo={isVideo}
               handleChangeVideo={checked => this.handleChangeVideo(checked)}
               handleChangeEventName={value => this.handleChangeEventName(value)}
               onSelect={value => this.onSelect(value)}
               lovDateByEvent={dataLOVDateByEvent.data}
               handleChangeInputFile={(event) => this.handleChangeInputFile(event)}
               fileName={fileName}
               loadingUpload={dataPostUploadGaleri.status === "loading"}
               handleReset={() => this.handleReset()}
               loadingSubmit={dataPostDaftarGaleri.status === "loading"}
               onFinish={(values) => this.handleFinish(values)}
            />
         </div>
      )
   }
}

const mapDispatchToProps = dispatch => (
   {
      handleData: (token, param) => dispatch(daftarGaleri.getData(token, param)),
      handleLOVEventNameByUniv: (token, univId, search) => dispatch(lov.getLOVEventNameByUniv(token, univId, search)),
      handleLOVDateByEvent: (token, univEventId) => dispatch(lov.getLOVDateByEvent(token, univEventId)),
      handleUploadGaleri: (token, formData) => dispatch(daftarGaleri.uploadGaleri(token, formData)),
      handlePostDaftarGaleri: (token, body) => dispatch(daftarGaleri.postDaftarGaleri(token, body))
   }
);

const mapStateToProps = state => (
   {  
      dataDaftarGaleri: state.daftarGaleri,
      dataLOVEventNameByUniv: state.lovEventNameByUniv,
      dataLOVDateByEvent: state.lovDateByEvent,
      dataPostUploadGaleri: state.postUploadDaftarGaleri,
      dataPostDaftarGaleri: state.postDaftarGaleri
   }
);

export default connect(mapStateToProps, mapDispatchToProps)(DaftarGaleri)