import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../global/global.css'
import getLov from '../../actions/logging'
import { Card, Row, Col, DatePicker, Button, Select, Breadcrumb } from 'antd'

class Logging extends Component {
  constructor(props){
    super(props)
    this.state = {
      initial: '',
      startDate: null,
      endDate: null,
      typeLogging: null
    }
  }

  componentDidMount() {
    this.props.handleLov(this.props.token)
  }
  

  datechange = (val, date) => {
    const {typeLogging} = this.state
    this.setState({
      startDate: date[0],
      endDate: date[1]
    }, () => {
      if (typeLogging) {
        this.handledownload()
      }
    })
  }

  selectChange = (val) => {
    const { 
      startDate,
      endDate
    } = this.state
    this.setState({
      typeLogging: val
    }, () => {
      if (endDate && startDate) {
        this.handledownload()
      }
    })
  }

  handledownload = () => {
    const token = this.props.token
    const { 
      startDate,
      endDate,
      typeLogging
    } = this.state
    this.props.handleDownload(token, typeLogging, startDate, endDate)
  }

  render() {
    const styleBorder = {
      borderBottom: '2px solid #d9d9d9',
      borderTop: 'none',
      borderRight: 'none',
      borderLeft: 'none',
      borderRadius: '0px',
      width: '100%'
    }
    const {isMobile} = this.props
    return (
      <div className='root'>
        <Breadcrumb className="breadcrumb_style">
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/dashboard`)}>Kelola Admin </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className='pathNow_style'>
            Logging
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card className='bodyCard_style'>
          <Card
            bordered={false}
            loading={this.props.lovTypeLogging.status === 'loading'}
            style={{minHeight: '300px'}}>
              <Row gutter={[16, 16]}>
                {
                  !isMobile &&
                  <Col span={4}></Col>
                }
                <Col span={isMobile ? 24 : 8}>
                  <span 
                    className='header_text_detail' 
                    style={{ fontWeight: 'bold'}}
                  >
                    Periode
                  </span>
                </Col>
                <Col span={isMobile ? 24 :8}>
                  <DatePicker.RangePicker 
                    style={styleBorder} 
                    onChange={(val, dateString) => this.datechange(val, dateString)} 
                    allowClear={false}
                  >
                  </DatePicker.RangePicker>
                </Col>
                {
                  !isMobile &&
                  <Col span={4}></Col>
                }
                {
                  !isMobile &&
                  <Col span={4}></Col>
                }
                <Col span={isMobile ? 24 : 8}>
                  <span 
                    className='header_text_detail' 
                    style={{ fontWeight: 'bold'}}
                  >
                    Jenis Logging
                  </span>
                </Col>
                <Col span={isMobile ? 24 : 8}>
                  <Select 
                    style={styleBorder}
                    className='selectCustom'
                    onChange={(val) => this.selectChange(val)}
                    placeholder='Select Logging Type'
                  >
                    {
                      this.props.lovTypeLogging.data ?
                        this.props.lovTypeLogging.data.map(res => (
                          <Select.Option key={res.id} value={res.id}>{res.name}</Select.Option>
                        ))
                        : 
                        (
                          <></>
                        )
                    }
                  </Select>
                </Col>
                {
                  !isMobile &&
                  <Col span={4}></Col>
                }
                <Col span={8}>
                </Col>
                <Col span={8} style={{ textAlign: 'center', marginTop: '50px'}}>
                  <a 
                    target="_blank"
                    href={this.props.dataDownload.data && this.props.dataDownload.data.link}
                    download={this.props.dataDownload.data && this.props.dataDownload.data.link}
                    rel='noopener noreferrer'
                  >
                    <Button 
                      className="updateButton"
                      // onClick={() => this.handledownload()}
                      loading={this.props.dataDownload.isLoading}
                      disabled={
                        this.state.startDate === null || 
                        this.state.endDate === null || 
                        this.state.typeLogging === null ||
                        this.props.dataDownload.status === 'error'
                      }
                    >
                      Download
                    </Button>
                  </a>
                </Col>
                <Col span={8}>
                </Col>
              </Row>
          </Card>
        </Card>
      </div>
    )
  }

}

const mapDispatchToProps = dispatch => {
  return{
    handleLov: (token) => dispatch(getLov.getLOVType(token)),
    handleDownload: (token, type, start, end) => dispatch(getLov.downloadLoging(token, type, start, end))
  }
}

const mapStateToProps = (state) => {
  return {
    lovTypeLogging: state.lovTypeLogging,
    dataDownload: state.downloadLogging
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Logging)