import React from 'react';
import { connect } from 'react-redux';
import { Card, Row, Col, Typography, Divider, Spin, Popover } from 'antd';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import dashboardBidiktiWeek from "../../actions/dashboardBidiktiWeek";
import { InfoCircleOutlined } from '@ant-design/icons'
const infoDetail = (
  <div>
    <span>{`Presentasi yang sedang dan akan berlangsung dalam 1 Tema BIDikTi Week`}</span>
  </div>
)

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const BidiktiWeekDashboard = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [barData, setBarData] = React.useState({});
  const [totalActive, setTotalActive] = React.useState(0);
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const options = {
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
        },
      }],
      xAxes: [{
        barPercentage: 0.6,
      }],
    },
    legend: {
      display: false,
      position: 'bottom',
      fullWidth: false,
      labels: {
        fontSize: 14,
        padding: 30,
        boxWidth: 15
      }
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        color: '#ADA7A7',
        font: {
          weight: 'bold'
        },
      }
    },
    aspectRatio: 4 / 3,
    layout: {
      padding: 32
    },
    elements: {
      line: {
        fill: false
      },
      point: {
        hoverRadius: 7,
        radius: 5
      }
    },
  }

  const getDashboardBidiktiWeek = async (token) => {
    let dataTemporary = {};
    let listTypeUniv = [];
    setLoading(true);
    await props.handleGetDashboardBidiktiWeek(token).then(res => {
      if (res.payload.data && res.payload.data.listDataUnivCat) {
        res.payload.data.listDataUnivCat.forEach(item => {
          listTypeUniv.push({
            label: item.univCategory,
            data: [item.presentationUnivTotal],
            backgroundColor: (
              item.univCategoryId === 1 ? 'rgb(117, 248, 214)' : (
                item.univCategoryId === 2 ? 'rgb(86, 196, 178)' : (
                  item.univCategoryId === 3 ? 'rgb(87, 211, 249)' : 'rgb(93, 121, 246)'
                )
              )
            ),
          })
        });
        dataTemporary.labels = ['Universitas'];
        dataTemporary.datasets = listTypeUniv;
        setBarData(dataTemporary);
        setTotalActive(res.payload.data.presentationActiveTotal)
      }
      setLoading(false);
    }, (error) => {
      console.log('Error : ', error);
      setLoading(false);
    })
  }

  React.useEffect(() => {
    getDashboardBidiktiWeek(props.token);
  }, [setBarData]);

  return (
    <Card className="bodyCard_style">
      <Row gutter={[24, 12]}>
        <Col offset={2} span={20} style={{ textAlign: 'center' }}>
          <Typography.Title level={4}>{`BIDikTi Week`}</Typography.Title>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[24, 12]} style={{ textAlign: 'center' }}>
        <Col offset={2} span={20}>
          <Typography.Text className="dashboardText">{`Jumlah Presentasi Aktif`} <Popover content={infoDetail}><InfoCircleOutlined style={{ marginRight: 10 }} /></Popover> {`${totalActive}`}</Typography.Text>
        </Col>
      </Row>
      <br />
      <Row gutter={[24, 12]} align='middle' justify='center'>
        <Col xs={24} md={12}>
          <Spin spinning={loading}>
            <Bar data={barData} options={options} />
          </Spin>
        </Col>
        <Col xs={24} md={12}>
          <table >
            {
              barData && barData.datasets && barData.datasets.map((res, index) => {
                return (
                  <tr>
                    <td><div style={{ height: 10, width: 10, backgroundColor: res.backgroundColor ? res.backgroundColor : '#ddd', marginRight: 10 }} /></td>
                    <td>{res.label}</td>
                  </tr>
                )
              })
            }
          </table>
        </Col>
      </Row>
      <br />
      <Row gutter={[24, 12]}>
        <Col offset={4} span={16} style={{ textAlign: 'center' }}>
          <Typography.Text className="dashboardText">{`Jumlah Presentasi Aktif Per Kategori Universitas`}</Typography.Text>
        </Col>
      </Row>
    </Card>
  )
}

const mapStateToProps = (state) => {
  return {
    dataBidiktiWeek: state.getDashboardBidiktiWeek,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    handleGetDashboardBidiktiWeek: (token) => dispatch(dashboardBidiktiWeek.getDashboardBidiktiWeek(token)),
  }
}

export const UIBidiktiWeekDashboard = connect(mapStateToProps, mapDispatchToProps)(BidiktiWeekDashboard)
