import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../global/global.css'
import beasiswa from '../../actions/beasiswa'
import {
  Card,
  Row,
  Col,
  Breadcrumb,
  Typography,
  Popconfirm,
  Button,
  notification
} from 'antd'
import { UISearch, UItable } from '../../components'

const { Text } = Typography

class ListBeasiswa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initial: '',
      selectedRowKeys: [],
      orderBy: '',
      size: 10,
      page: 1,
      search: '',
      loadingButton: [false]
    }
  }

  componentDidMount() {
    const { orderBy, size, page, search } = this.state
    this.props.handleDataListBeasiswa(this.props.token, orderBy, size, page, search)
  }

  handleRedirectDetail = id => {
    this.props.history.push(`/beasiswa-detail/${id}`)
  }

  handleTableChange = (page, filter, sorter) => {
    const { size, search } = this.state

    let sorterField = sorter.field;
    let orderBy = "";
    if (sorter.order === "ascend") {
      orderBy = `${sorterField} asc`;
    } else if (sorter.order === "descend") {
      orderBy = `${sorterField} desc`;
    }

    this.setState({
      page: page.current,
      orderBy: orderBy
    }, () => {
      this.props.handleDataListBeasiswa(this.props.token, orderBy, size, page.current, search)
    })
  }

  handleSearch = key => {
    const { orderBy, size } = this.state
    this.setState({
      search: key,
      page: 1
    })
    this.props.handleDataListBeasiswa(this.props.token, orderBy, size, 1, key)
  }

  handleDelete = (index,id) => {
    let temp = this.state.loadingButton
    temp[index] = true
    this.setState({
      loadingButton: temp
    })
    const body = {
      id: id,
      userId: this.props.userId
    }

    this.props.handleDelete(this.props.token, body).then(res => {
      if (res.payload.data.data) {
        notification.success({
          placement: 'bottomRight',
          message: 'Success',
          description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Delete Record Success',
        });
        temp[index] = false
        this.setState({
          search: '',
          page: 1,
          orderBy: '',
          loadingButton: temp
        }, () => {
          this.props.handleDataListBeasiswa(this.props.token, '', 10, 1, '')
        })
      }
    }).catch(err => {
      if(err){
        console.log('err : ',err.response)
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: err.response.data ? err.response.data.message : 'Delete Record Failed'
        });
        temp[index] = false
        this.setState({
          loadingButton: temp
        })
      }
    })
  }

  render() {
    const columnsTable = [
      {
        title: 'Nama Beasiswa',
        dataIndex: 'scholarshipName',
        key: 'scholarshipName',
        sorter: true,
        render: scholarshipName => <Text style={{ color: '#EF712B', cursor: 'pointer', fontWeight: 600 }}>{scholarshipName}</Text>,
        onCell: record => {
          return {
            onClick: () => {
              this.handleRedirectDetail(record.scholarshipId)
            }
          }
        }
      },
      {
        title: 'Provider',
        dataIndex: 'scholarshipProvider',
        key: 'scholarshipProvider',
        sorter: true,
      },
      {
        title: 'Kategori Beasiswa',
        dataIndex: 'scholarshipCat',
        key: 'scholarshipCat',
        sorter: true,
      },
      {
        title: 'Status',
        dataIndex: 'scholarshipStatus',
        key: 'scholarshipStatus',
        sorter: true,
        render: scholarshipStatus => 
          <Text style={
            scholarshipStatus === 'On Approval' ? { color: '#22296c', fontWeight: 600 } :
              (scholarshipStatus === 'Approved' ? { color: '#74ca40', fontWeight: 600 } :
                { color: '#e23530', fontWeight: 600 })
          }>{scholarshipStatus}</Text>,
      },
      {
        title: 'Action',
        dataIndex: '',
        key: '',
        width: '20%' ,
        render: (record, row, index) =>
        <>
          <Popconfirm
            placement="bottomLeft"
            title={'Yakin ingin menghapus data ini?'}
            onConfirm={() => this.handleDelete(index,record.scholarshipId)}
            okText="Yes"
            cancelText="No">
            <Button
              type='primary'
              className='deleteButton2'
              loading={this.state.loadingButton[index]}
              size={'small'}
            >
              Delete
            </Button>
          </Popconfirm>
        </>
      },
    ]
    const { dataBeasiswa } = this.props
    const { page } = this.state
    return (
      <div className='root'>
        <Breadcrumb className="breadcrumb_style">
          <Breadcrumb.Item className='pathNow_style'>
            Beasiswa
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card className='bodyCard_style'>
          <Row>
            <Col span={8}>
              <UISearch placeholder='Search' handleSearch={key => this.handleSearch(key)} />
            </Col>
          </Row>
          <Card bordered={false} style={{ minHeight: '300px' }}>
            <UItable
              // rowSelection={rowSelection}
              dataTable={dataBeasiswa.data}
              columns={columnsTable}
              isMobile={this.props.isMobile}
              withRows={false}
              loading={dataBeasiswa.status}
              page={page}
              totalData={dataBeasiswa.totalData}
              handleTableChange={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter)}
            />
          </Card>
        </Card>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleDataListBeasiswa: (token, sort, size, page, search) => dispatch(beasiswa.getDataListBeasiswa(token, sort, size, page, search)),
    handleDelete: (token, id) => dispatch(beasiswa.deleteDataListBeasiswa(token, id)),
  }
}

const mapStateToProps = (state) => {
  return {
    dataBeasiswa: state.listBeasiswa,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ListBeasiswa)
