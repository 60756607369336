import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../global/global.css'
import getLateRegister from '../../actions/lateRegister'
import getApproval from '../../actions/approval'
import lov from '../../actions/lov'
import {
  Card,
  notification,
  Button,
  Row,
  Col,
  Breadcrumb,
  Typography,
  Popover
} from 'antd'
import { UItable, UISearch, UIModal } from '../../components'

const { Text } = Typography
const dateNow = new Date()

class ListLateRegister extends Component {
  constructor(props) {
    super(props)
    this.state ={
      initial: '',
      selectedRowKeys: [],
      orderBy: '',
      size: 10,
      page: 1,
      search: '',
      loadingReject: false,
      visibleRegis: false,
      dataUniv: undefined,
      isKedutaan: false
    }
  }

  componentDidMount() {
    const {orderBy, size, page, search } = this.state
    this.props.handleDataLateRegister(this.props.token, orderBy, size, page, search)
    this.props.handleLOVSchedule(this.props.token)
    // this.props.handleLOVDataInstansi(this.props.token, '', this.state.isKedutaan)
  }

  // handleRedirectDetail = id => {
  //   this.props.history.push(`/late-register-detail/${id}`)
  // }

  handleSearch = key => {
    const {orderBy, size} = this.state
    this.setState({
      search: key,
      page: 1
    })
    this.props.handleDataLateRegister(this.props.token, orderBy, size, 1, key)
  }

  handleTableChange = (page, filter, sorter) => {
    const { size, search} =this.state

    let sorterField = ""
    if ( sorter.field === "univRegName" ) {
      sorterField = "ur.univ_reg_name";
    }else if ( sorter.field === "bwLateRegPicName" ) {
      sorterField = "bw_late_reg_pic_name";
    } else if ( sorter.field === "bwLateRegPicEmail" ) {
      sorterField = "bw_late_reg_pic_email";
    }else if ( sorter.field === "bwLateRegPicWa" ) {
      sorterField = "bw_late_reg_pic_wa";
    }

    let orderBy = "";
    if (sorter.order === "ascend" ) {
      orderBy = `${sorterField},asc`;
    } else if (sorter.order === "descend") {
      orderBy = `${sorterField},desc`;
    }

    this.setState({
      page: page.current,
      orderBy: orderBy
    }, ()=> {
      this.props.handleDataLateRegister(this.props.token, orderBy, size, page.current, search)
    })
  }

  handleRegisSubmit = (value) => {
    this.setState({
      loadingSubmit: true
    })
    let body = {
      bwScheduleId : {
        bwScheduleId : value.dayPresentasi
      },
      bwSessionId : {
          bwSessionId : value.session
      },
      bwUnivPicName : value.picName,
      bwUnivPicWa: value.picNo,
      bwUnivSpeaker : value.sumberName,
      bwUnivSpeakerDesc : value.descSumber,
      bwUnivLogoPath : this.state.dataUniv.logoPath,
      userId : this.props.userId,
      univRegId : {
        univRegId : value.institusi
      },
      bwLateRegId: this.state.dataUniv.bwLateRegId
    }
    this.props.handlePostUniv(this.props.token, body).then(res => {
      if (res.payload.status !== 'error') {
        if (res.payload.data.data.Status === 'OK') {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Registrasi Event Success',
          });
          this.setState({
            search: '',
            page: 1,
            orderBy: '',
            loadingSubmit: false,
            visibleRegis: false
          }, () => {
            this.props.handleDataLateRegister(this.props.token, '', 10, 1, '')
          })
        }else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Registrasi Event Failed'
          });
          this.setState({
            loadingSubmit: false,
            visibleRegis: false
          })
        }
      }else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: 'Registrasi Event Failed'
        });
        this.setState({
          loadingSubmit: false,
          visibleRegis: false
        })
      }
    })
  }

  handleRegisClose = () => {
    this.setState({
      visibleRegis: false,
      dataUniv: undefined
    })
  }

  openModal = (data) => {
    let temp = undefined
    this.props.handleLOVDataInstansi(this.props.token, '', data.isKedutaan)
    .then(res => {
      if (res.payload.status === 'success') {
        res.payload.data.map(dataPayload => {
          if (data.univRegId === dataPayload.id) {
              return temp = {
                ...data,
                ...dataPayload
              }
            }
            return temp
        })
        this.setState({
          dataUniv: temp,
        }, () => {
          this.setState({
            visibleRegis: true,
            isKedutaan: data.isKedutaan
          })
        })
      }
    })
  }

  onChangeSchedule = val => {
    this.props.handleLOVSession(this.props.token, val)
  }

  onChangeInstitusi = e => {
    // console.log(e)
  }

  radioChange = val => {
  //   this.props.handleLOVDataInstansi(this.props.token, '', this.state.isKedutaan)
  }

  handleReject = (idUniv) => {
    this.setState({
      loadingReject: true
    })
    const body = {
      bwLateRegId: [idUniv],
      lastModifiedBy: this.props.userId
    }
    this.props.handleRejectLateRegister(this.props.token, body).then(res => {
      if (res.payload.data.data.Status === 'OK') {
        notification.success({
          placement: 'bottomRight',
          message: 'Success',
          description: res.payload.data.data.messages ? res.payload.data.data.Message : 'Reject Late Register Success',
        });
        this.setState({
          search: '',
          page: 1,
          orderBy: '',
          loadingReject: false
        }, () => {
          this.props.handleDataLateRegister(this.props.token, '', 10, 1, '')
        })
      }else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Reject Late Register Failed'
        });
        this.setState({
          loadingReject: false
        })
      }
    })
  }

  render() {
    const columnsTable = [
      {
        title: 'Nama Institusi',
        dataIndex: 'univRegName',
        key: 'univRegName',
        sorter: true,
        render: univRegName => <Text style={{ color: '#222222', fontWeight: 600 }}>{univRegName}</Text>,
      },
      {
        title: 'Nama Lengkap PIC',
        dataIndex: 'bwLateRegPicName',
        key: 'bwLateRegPicName',
        sorter: true,
      },
      {
        title: 'Email PIC',
        dataIndex: 'bwLateRegPicEmail',
        key: 'bwLateRegPicEmail',
        sorter: true,
      },
      {
        title: 'No. WA PIC',
        dataIndex: 'bwLateRegPicWa',
        key: 'bwLateRegPicWa',
        sorter: true,
      },
      {
        title: 'Action',
        dataIndex: '',
        key: '',
        render: (record) =>  
        <>
          { 
            record.isPastEvent ?
            (
              <Popover content={`Tanggal pelaksanaan Bidikti Week telah berakhir pada tanggal ${record.bwDurationFinish}`} trigger="click">
                <Button
                  type='link'
                  style={{ color: '#6BC733', cursor: 'pointer', fontWeight: 600 }}
                >
                  Approve
                </Button>
              </Popover>
            ) : 
            (
              <Button
                onClick={() => this.openModal(record)}
                type='link'
                style={{ color: '#6BC733', cursor: 'pointer', fontWeight: 600 }}
              >
                Approve
              </Button>
            )
          }
          <Button
            onClick={() => this.handleReject(record.bwLateRegId)}
            type='link'
            style={{ color: '#E22828', cursor: 'pointer', fontWeight: 600 }}
          >
            Reject
          </Button>
        </>
      },
    ]
    const { 
      dataLateRegister,
      dataLOVSchedule,
      dataLOVSession,
      dataLOVInstansi
    } = this.props
    const { page, visibleRegis, loadingSubmit, dataUniv, isKedutaan } = this.state
    return(
      <div className='root'>
        <Breadcrumb className="breadcrumb_style">
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/dashboard`)}>BIDikTi Week </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className='pathNow_style'>
            Daftar Telat Pendaftaran
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card className='bodyCard_style'>
          <Row>
            <Col span={8}>
              <UISearch placeholder='Search' handleSearch={key => this.handleSearch(key)} />
            </Col>
          </Row>
          <Card bordered={false} loading={dataLateRegister.loading} style={{minHeight: '300px'}}>
            <UItable
              // rowSelection={rowSelection}
              dataTable={dataLateRegister.data}
              totalData={dataLateRegister.totalData}
              columns={columnsTable}
              isMobile={this.props.isMobile}
              loading={dataLateRegister.status}
              page={page}
              withRows={false}
              handleTableChange={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter)}
            />
          </Card>
        </Card>
        <UIModal
           visibleRegis={visibleRegis}
           isMobile={this.props.isMobile}
           title={'Registrasi BIDikTi Week ' + dateNow.getFullYear()}
           listSchedule={dataLOVSchedule.data}
           onChangeSchedule={e => this.onChangeSchedule(e)}
           listSession={dataLOVSession.data}
           listInstitusi={dataLOVInstansi.data}
           onChangeInstitusi={e => this.onChangeInstitusi(e)}
           radioChange={val => this.radioChange(val)}
           dataUniv={dataUniv}
           fromLate={true}
           loading={false}
           isKedutaan={isKedutaan}
           loadingSubmit={loadingSubmit}
           onFinish={(value, filePath) => this.handleRegisSubmit(value, filePath)}
           close={() => this.handleRegisClose()}
        />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return{
    handleDataLateRegister: (token, sort, size, page, search) => dispatch(getLateRegister.getDataLateRegister(token, sort, size, page, search)),
    handleRejectLateRegister: (token, body) => dispatch(getLateRegister.rejectLateRegister(token, body)),
    handleLOVSchedule: (token) => dispatch(lov.getLOVSchedule(token)),
    handleLOVSession: (token, id) => dispatch(lov.getLOVSession(token, id)),
    handleLOVDataInstansi: (token, search, isKedutaan) => dispatch(lov.getDataLOVInstansi(token, search, isKedutaan)),
    handleUpdateApproval: (token, body) => dispatch(getApproval.putApproval(token, body)),
    handlePostUniv: (token, body) => dispatch(getApproval.regisUniv(token, body)),
  }
}

const mapStateToProps = (state) => {
  return {
    dataLateRegister: state.lateRegister,
    dataLOVSchedule: state.lovSchedule,
    dataLOVSession: state.lovSession,
    dataLOVInstansi: state.lovDataInstansi,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ListLateRegister)
