import types from "../constants";
import lifeSharingGallery from "../api/lifeSharingGallery";

const getData = param => dispatch => {
  dispatch({
    type: types.GET_LIFE_SHARING_GALLERY_LOADING,
    payload: {
      data: [],
      totalData: 0,
      status: "loading",
      error: ""
    }
  })
  return lifeSharingGallery.getData(param).then(result => {
    if (result.status === "error") {
      return dispatch({
        type: types.GET_LIFE_SHARING_GALLERY_FAILED,
        payload: result
      });
    } else {
      return dispatch({
        type: types.GET_LIFE_SHARING_GALLERY_SUCCESS,
        payload: result
      });
    }
  })
}

const getDetail = (id, token) => dispatch => {
  dispatch({
    type: types.GET_DETAIL_LIFE_SHARING_GALLERY_LOADING,
    payload: {
      data: [],
      status: "loading",
      error: ""
    }
  })
  return lifeSharingGallery.getDetail(id, token).then(result => {
    if (result.status === "error") {
      return dispatch({
        type: types.GET_DETAIL_LIFE_SHARING_GALLERY_FAILED,
        payload: result
      });
    } else {
      return dispatch({
        type: types.GET_DETAIL_LIFE_SHARING_GALLERY_SUCCESS,
        payload: result
      });
    }
  })
}

const postLifeSharingGallery = (token, body) => dispatch => {
  dispatch({
    type: types.POST_LIFE_SHARING_GALLERY_LOADING,
    payload: {
      data: [],
      status: "loading",
      error: ""
    }
  })
  return lifeSharingGallery.postLifeSharingGallery(token, body).then(result => {
    if (result.status === "error") {
      return dispatch({
        type: types.POST_LIFE_SHARING_GALLERY_FAILED,
        payload: result
      });
    } else {
      return dispatch({
        type: types.POST_LIFE_SHARING_GALLERY_SUCCESS,
        payload: result
      });
    }
  })
}

const eraseLifeSharingGallery = (token, body, id) => dispatch => {
  dispatch({
    type: types.DELETE_LIFE_SHARING_GALLERY_LOADING,
    payload: {
      data: [],
      status: "loading",
      error: ""
    }
  })
  return lifeSharingGallery.deleteLifeSharingGallery(token, body, id).then(result => {
    if (result.status === "error") {
      return dispatch({
        type: types.DELETE_LIFE_SHARING_GALLERY_FAILED,
        payload: result
      });
    } else {
      return dispatch({
        type: types.DELETE_LIFE_SHARING_GALLERY_SUCCESS,
        payload: result
      });
    }
  })
}

const putDetailLifeSharingGallery = (token, body, id) => dispatch => {
  dispatch({
    type: types.PUT_URL_LIFE_SHARING_GALLERY_LOADING,
    payload: {
      data: [],
      status: "loading",
      error: ""
    }
  })
  return lifeSharingGallery.editDetailLifeSharingGallery(token, body, id).then(result => {
    if (result.status === "error") {
      return dispatch({
        type: types.PUT_URL_LIFE_SHARING_GALLERY_FAILED,
        payload: result
      });
    } else {
      return dispatch({
        type: types.PUT_URL_LIFE_SHARING_GALLERY_SUCCESS,
        payload: result
      });
    }
  })
}

export default {
  getData,
  getDetail,
  postLifeSharingGallery,
  eraseLifeSharingGallery,
  putDetailLifeSharingGallery,
}
