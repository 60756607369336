import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../global/global.css'
import { UIContentDetail,  } from '../../components'
import getApproval from '../../actions/approval'
import lov from '../../actions/lov'
import { Card, notification, Breadcrumb } from 'antd'

class ListApprovalDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initial: '',
      id: '',
    }
  }

  componentDidMount() {
    let id = this.props.location.pathname.split('/')[2]
    this.setState({
      id: id
    })
    this.props.handleDataDetail(this.props.token, id)
    this.props.handleLOVSchedule(this.props.token)
  }

  componentDidUpdate(prevProps) {
    const { dataDetail } = this.props
    if (
      dataDetail.status !== 
      prevProps.dataDetail.status &&
      dataDetail.status === 'success'
      ) {
        this.props.handleLOVSession(this.props.token, dataDetail.data.bwScheduleId)
      }
  }

  handleDirect = name => {
    if (name === 'list') {
      this.props.history.push(`/list-approval`)
    }else {
      this.props.history.push(`/dashboard`)
    }
  }

  handleScheduleChange = val => {
    this.props.handleLOVSession(this.props.token, val)
  }

  handleFinish = val => {
    let body = { 
        bwScheduleId: {
            bwScheduleId: val.schedule
        },
        bwSessionId: {
            bwSessionId: val.session
        },
        isOnline: val.valueRadio,
        bwUnivMedia: val.tempat,
        bwUnivMediaJoinId: val.username,
        bwUnivMediaJoinPassword: val.password,
        bwUnivMediaJoinUrl: val.urlRoom,
        bwUnivLoyolaQuota: val.loyolaKouta,
        bwUnivOtherQuota: val.otherKouta,
        lastModifiedBy: this.props.userId
    }
    this.props.handleEditDetail(this.props.token, this.state.id, body).then(res => {
      if (res.payload.status !== 'error') {
        if (res.payload.data.data.Status === 'OK') {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.message ? res.payload.data.data.message : 'Update Approval Success',
          });
        }else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.message ? res.payload.data.data.message : 'Update Approval Failed'
          });
        }
      }else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: 'Update Approval Failed'
        });
      }
    })
  }

  render() {
    const { dataDetail, dataLOVSchedule, dataLOVSession } = this.props
    return(
      <div className='root'>
        <Breadcrumb className="breadcrumb_style" >
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.handleDirect('dashBoard')}>BIDikTi Week </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.handleDirect('list')}>Daftar Persetujuan</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className='pathNow_style'>
            Detail Persetujuan
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card loading={dataDetail.status === 'loading'} className='bodyCard_style'>
          <UIContentDetail 
            isMobile={this.props.isMobile}
            dataDetail={dataDetail.data} 
            dataLOVSchedule={dataLOVSchedule.data} 
            dataLOVSession={dataLOVSession.data}
            handleScheduleChange={val => this.handleScheduleChange(val)} 
            from={'approval'} 
            handleFinish={val => this.handleFinish(val)}
          />
        </Card>
        
      </div>
    )
  }
}
const mapDispatchToProps = dispatch => {
  return{
    handleDataDetail: (token, id) => dispatch(getApproval.getDataDetailApproval(token, id)),
    handleLOVSchedule: (token) => dispatch(lov.getLOVSchedule(token)),
    handleLOVSession: (token, id) => dispatch(lov.getLOVSession(token, id)),
    handleEditDetail: (token, id, body) => dispatch(getApproval.postEditDetail(token, id, body))
  }
}

const mapStateToProps = (state) => {
  return {
    dataDetail: state.detailApproval,
    dataLOVSchedule: state.lovSchedule,
    dataLOVSession: state.lovSession
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ListApprovalDetail)