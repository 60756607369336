import { get, put } from 'axios'
import config from '../services/config'
const { BASE_URL_LOGIN, TIMEOUT } = config

const getData = (token) => {
  return get(`${BASE_URL_LOGIN}be/api/lifeSharingContentAdmin`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const data = response.data ? response.data.Data : undefined
    const normalizeData = 
      {
        scCarSubtitleEng: data ? data.CareerSharing.scCarSubtitleEng ? data.CareerSharing.scCarSubtitleEng : '' : '',
        scCarSubtitleInd: data ? data.CareerSharing.scCarSubtitleInd ? data.CareerSharing.scCarSubtitleInd : '' : '',
        scFutSubtitleEng: data ? data.FutureSharing.scFutSubtitleEng ? data.FutureSharing.scFutSubtitleEng : '' : '',
        scFutSubtitleInd: data ? data.FutureSharing.scFutSubtitleInd ? data.FutureSharing.scFutSubtitleInd : '' : '',
        scFamSubtitleEng: data ? data.ParentSharing.scFamSubtitleEng ? data.ParentSharing.scFamSubtitleEng : '' : '',
        scFamSubtitleInd: data ? data.ParentSharing.scFamSubtitleInd ? data.ParentSharing.scFamSubtitleInd : '' : '',
        scEduSubtitleEng: data ? data.eduSharing.scEduSubtitleEng ? data.eduSharing.scEduSubtitleEng : '' : '',
        scEduSubtitleInd: data ? data.eduSharing.scEduSubtitleInd ? data.eduSharing.scEduSubtitleInd : '' : '',
        scDescEng: data ? data.lifeSharing.scDescEng ? data.lifeSharing.scDescEng : '' : '',
        scDescInd: data ? data.lifeSharing.scDescInd ? data.lifeSharing.scDescInd : '' : '',
        scSubtitleEng: data ? data.lifeSharing.scSubtitleEng ? data.lifeSharing.scSubtitleEng : '' : '',
        scSubtitleInd: data ? data.lifeSharing.scSubtitleInd ? data.lifeSharing.scSubtitleInd : '' : '',
      }
    return {
      data: normalizeData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      totalData: 0,
      error,
      data: []
    }
  })
}

const handleEdit = (token, userId, body) => {
  return put(`${BASE_URL_LOGIN}be/api/updateLifeSharingContentAdmin?usersId=${userId}`,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: '',
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: [],
    }
  })
}


export default {
  getData,
  handleEdit
}