import React, {Component} from 'react'
import {connect} from 'react-redux'
import '../../global/global.css'
import getApproval from '../../actions/approval'
import lov from '../../actions/lov'
import {
  Card,
  notification,
  Button,
  Row,
  Col,
  Breadcrumb,
  Typography
} from 'antd'
import {UItable, UISearch, UIModal} from '../../components'
import moment from "moment";

const {Text} = Typography
const dateNow = new Date()

class ListApproval extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initial: '',
      selectedRowKeys: [],
      isApprove: false,
      isChecked: false,
      orderBy: '',
      size: 10,
      page: 1,
      search: '',
      visibleRegis: false,
      dataChecked: [],
      loadingReject: false,
      loadingApprove: false,
      loadingSubmit: false,
      isKedutaan: true
    }
  }

  componentDidMount() {
    const {orderBy, size, page, search} = this.state
    this.props.handleDataApproval(this.props.token, orderBy, size, page, search)
    this.props.handleLOVSchedule(this.props.token)
    this.props.handleLOVInstansi(this.props.token, '', 10, true)
    this.props.handleLOVDataInstansi(this.props.token, '', true)
  }

  handleRedirectDetail = id => {
    this.props.history.push(`/approval-detail/${id}`)
  }

  checkedBox = value => {
    let checked = false
    if (value.length > 0) {
      checked = true
    }
    let isApprove = true
    let todayDate = moment().format('YYYY-MM-DD')
    value.map(dataSelect => {
      return this.props.dataApproval.data.map(dataFilter => {
        if (dataSelect === dataFilter.id && dataFilter.status !== 'On Approval') {
          return isApprove = false
        } else if (
          dataSelect === dataFilter.id
          && dataFilter.status === 'On Approval'
          && moment(todayDate, 'YYYY-MM-DD') > moment(dataFilter.bwScheduleDate, 'YYYY-MM-DD')
        ) {
          return isApprove = false
        }
        return false
      })
    })
    this.setState({
      isChecked: checked,
      isApprove: isApprove,
      dataChecked: value
    })
  }

  handleSearch = key => {
    const {orderBy, size} = this.state
    this.setState({
      search: key,
      page: 1
    })
    this.props.handleDataApproval(this.props.token, orderBy, size, 1, key)
  }

  handleRegisSubmit = (value, filePath) => {
    this.setState({
      loadingSubmit: true
    })
    let body = {
      bwScheduleId: {
        bwScheduleId: value.dayPresentasi
      },
      bwSessionId: {
        bwSessionId: value.session
      },
      bwUnivPicName: value.picName,
      bwUnivPicWa: value.picNo,
      bwUnivSpeaker: value.sumberName,
      bwUnivSpeakerDesc: value.descSumber,
      bwUnivLogoPath: filePath,
      userId: this.props.userId,
      bwLateRegId: null
    }
    if (value.institusi !== '-') {
      body = {
        ...body,
        univRegId: {
          univRegId: value.institusi
        }
      }
    } else {
      body = {
        ...body,
        univRegName: value.otheInstitusi,
        univRegAddress: value.alamat,
        univRegPhone: value.noHp
      }
    }
    this.props.handlePostUniv(this.props.token, body).then(res => {
      if (res.payload.status !== 'error') {
        if (res.payload.data.data.Status === 'OK') {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Registrasi Event Success',
          });
          this.setState({
            search: '',
            page: 1,
            orderBy: '',
            isApprove: false,
            isChecked: false,
            dataChecked: [],
            loadingSubmit: false,
            visibleRegis: false
          }, () => {
            this.props.handleDataApproval(this.props.token, '', 10, 1, '')
          })
        } else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Registrasi Event Failed'
          });
          this.setState({
            loadingSubmit: false,
            visibleRegis: false
          })
        }
      } else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: 'Registrasi Event Failed'
        });
        this.setState({
          loadingSubmit: false,
          visibleRegis: false
        })
      }
    })
  }

  handleRegisClose = () => {
    this.setState({
      visibleRegis: false
    })
  }

  openModal = () => {
    this.setState({
      visibleRegis: true
    })
  }

  onChangeSchedule = val => {
    this.props.handleLOVSession(this.props.token, val)
  }

  onChangeInstitusi = e => {
    // console.log(e)
  }

  // radioChange = val => {
  //   this.setState({
  //     isKedutaan: val
  //   })
  //   this.props.handleLOVInstansi(this.props.token, '', 10, val)
  // }

  radioChange = val => {
    this.setState({
      isKedutaan: val
    })
    this.props.handleLOVDataInstansi(this.props.token, '', val)
  }

  handleTableChange = (page, filter, sorter) => {
    const {size, search} = this.state

    let sorterField = ""
    if (sorter.field === "univRegName") {
      sorterField = "ur.univ_reg_name";
    } else if (sorter.field === "bwUnivSpeaker") {
      sorterField = "bw_univ_speaker";
    } else if (sorter.field === "bwScheduleDay") {
      sorterField = "bwsc.bw_schedule_day";
    } else if (sorter.field === "bwScheduleDate") {
      sorterField = "bwsc.bw_schedule_date";
    } else if (sorter.field === "bwSessionName") {
      sorterField = "bwse.bw_session_name";
    } else {
      sorterField = "bw_univ_approval_status";
    }

    let orderBy = "";
    if (sorter.order === "ascend") {
      orderBy = `${sorterField},asc`;
    } else if (sorter.order === "descend") {
      orderBy = `${sorterField},desc`;
    }

    this.setState({
      page: page.current,
      orderBy: orderBy
    }, () => {
      this.props.handleDataApproval(this.props.token, orderBy, size, page.current, search)
    })
  }

  handleApprove = () => {
    this.setState({
      loadingApprove: true
    })
    const body = {
      bwUnivId: this.state.dataChecked,
      lastModifiedBy: this.props.userId
    }
    this.props.handleUpdateApproval(this.props.token, body).then(res => {
      if (res.payload.status !== 'error') {
        if (res.payload.data.data.Status === 'OK') {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Approve Event Success',
          });
          this.setState({
            search: '',
            page: 1,
            orderBy: '',
            isApprove: false,
            isChecked: false,
            dataChecked: [],
            loadingApprove: false
          }, () => {
            this.props.handleDataApproval(this.props.token, '', 10, 1, '')
          })
        } else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Approve Event Failed'
          });
          this.setState({
            loadingApprove: false
          })
        }
      } else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: 'Approve Event Failed'
        });
        this.setState({
          loadingApprove: false
        })
      }
    })
  }

  handleReject = () => {
    this.setState({
      loadingReject: true
    })
    const body = {
      bwUnivId: this.state.dataChecked,
      lastModifiedBy: this.props.userId
    }
    this.props.handleRejectApproval(this.props.token, body).then(res => {
      if (res.payload.status !== 'error') {
        if (res.payload.data.data.Status === 'OK') {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Reject Event Success',
          });
          this.setState({
            search: '',
            page: 1,
            orderBy: '',
            isApprove: false,
            isChecked: false,
            dataChecked: [],
            loadingReject: false
          }, () => {
            this.props.handleDataApproval(this.props.token, '', 10, 1, '')
          })
        } else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Reject Event Failed'
          });
          this.setState({
            loadingReject: false
          })
        }
      } else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: 'Reject Event Failed'
        });
        this.setState({
          loadingReject: false
        })
      }
    })
  }

  onSearch = (e) => {
    // this.props.handleLOVInstansi(this.props.token, e, 10, this.state.isKedutaan)
    this.props.handleLOVDataInstansi(this.props.token, e, this.state.isKedutaan)
  }

  render() {
    const columnsTable = [
      {
        title: 'Nama Institusi',
        dataIndex: 'univRegName',
        key: 'univRegName',
        sorter: true,
        render: univRegName => <Text
          style={{color: '#EF712B', cursor: 'pointer', fontWeight: 600}}>{univRegName}</Text>,
        onCell: record => {
          return {
            onClick: () => {
              this.handleRedirectDetail(record.bwUnivId,)
            }
          }
        }
      },
      {
        title: 'Narasumber',
        dataIndex: 'bwUnivSpeaker',
        key: 'bwUnivSpeaker',
        sorter: true
      },
      {
        title: 'Hari',
        dataIndex: 'bwScheduleDay',
        key: 'bwScheduleDay',
        sorter: true
      },
      {
        title: 'Tanggal',
        dataIndex: 'bwScheduleDate',
        key: 'bwScheduleDate',
        sorter: true
      },
      {
        title: 'Sesi',
        dataIndex: 'bwSessionName',
        key: 'bwSessionName',
        sorter: true
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        render: status => {
          if (status === 'On Approval') {
            return (
              <Text style={{color: '#2094E9', fontWeight: 600}}>{status}</Text>
            )
          } else if (status === 'New') {
            return (
              <Text style={{color: '#EF712B', fontWeight: 600}}>{status}</Text>
            )
          } else {
            return (
              <Text style={{color: '#E22828', fontWeight: 600}}>{status}</Text>
            )
          }
        }
      },
    ]
    const {
      dataApproval,
      dataLOVSchedule,
      dataLOVSession,
      dataLOVInstansiNoSize,
    } = this.props
    const {
      isChecked,
      isApprove,
      visibleRegis,
      page,
      loadingReject,
      loadingApprove,
      dataChecked,
      loadingSubmit
    } = this.state
    return (
      <div className='root'>
        <Breadcrumb className="breadcrumb_style">
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/dashboard`)}>BIDikTi Week </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className='pathNow_style'>
            Daftar Persetujuan
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card className='bodyCard_style'>
          <Row>
            <Col span={8}>
              <UISearch placeholder='Search' handleSearch={key => this.handleSearch(key)}/>
            </Col>
            <Col span={16} style={{textAlign: 'right'}}>
              {isChecked ?
                (
                  <div>
                    <Button
                      onClick={() => this.handleReject()}
                      loading={loadingReject}
                      className='rejectButton'
                    >
                      Reject
                    </Button>
                    <Button
                      onClick={() => this.handleApprove()}
                      loading={loadingApprove}
                      disabled={!isApprove}
                      className='approveButton'
                    >
                      Approve
                    </Button>
                  </div>
                ) : (
                  <Button onClick={() => this.openModal()} className='updateButton'>Daftar BIDikTi Week</Button>
                )
              }
            </Col>
          </Row>
          <Card bordered={false} loading={dataApproval.loading} style={{minHeight: '300px'}}>
            <UItable
              // rowSelection={rowSelection}
              dataTable={dataApproval.data}
              columns={columnsTable}
              isMobile={this.props.isMobile}
              totalData={dataApproval.totalData}
              checkedBox={value => this.checkedBox(value)}
              loading={dataApproval.status}
              rowSelect={dataChecked}
              page={page}
              handleTableChange={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter)}
            />
          </Card>
        </Card>
        <UIModal
          visibleRegis={visibleRegis}
          isMobile={this.props.isMobile}
          title={'Registrasi BIDikTi Week ' + dateNow.getFullYear()}
          listSchedule={dataLOVSchedule.data}
          onChangeSchedule={e => this.onChangeSchedule(e)}
          listSession={dataLOVSession.data}
          // listInstitusi={dataLOVInstansi.data}
          listInstitusi={dataLOVInstansiNoSize.data}
          onChangeInstitusi={e => this.onChangeInstitusi(e)}
          radioChange={val => this.radioChange(val)}
          onSearch={(e) => this.onSearch(e)}
          dataUniv={null}
          loading={false}
          isKedutaan={true}
          loadingSubmit={loadingSubmit}
          onFinish={(value, filePath) => this.handleRegisSubmit(value, filePath)}
          close={() => this.handleRegisClose()}
        />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleDataApproval: (token, sort, size, page, search) => dispatch(getApproval.getDataApproval(token, sort, size, page, search)),
    handleLOVSchedule: (token) => dispatch(lov.getLOVSchedule(token)),
    handleLOVSession: (token, id) => dispatch(lov.getLOVSession(token, id)),
    handleLOVInstansi: (token, search, size, isKedutaan) => dispatch(lov.getLOVInstansi(token, search, size, isKedutaan)),
    handleUpdateApproval: (token, body) => dispatch(getApproval.putApproval(token, body)),
    handleRejectApproval: (token, body) => dispatch(getApproval.rejectApproval(token, body)),
    handlePostUniv: (token, body) => dispatch(getApproval.regisUniv(token, body)),
    handleLOVDataInstansi: (token, search, isKedutaan) => dispatch(lov.getDataLOVInstansi(token, search, isKedutaan)),
  }
}

const mapStateToProps = (state) => {
  return {
    dataApproval: state.approval,
    dataLOVSchedule: state.lovSchedule,
    dataLOVSession: state.lovSession,
    dataLOVInstansi: state.lovInstansi,
    dataLOVInstansiNoSize: state.lovDataInstansi,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ListApproval)
