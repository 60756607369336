import React, { Component } from "react";
import "../../global/global.css";
import { connect } from "react-redux";
import masterKotaAction from "../../actions/masterKota";
import {GlobalFunction} from '../../global/function'
import lov from "../../actions/lov";
import {
   Breadcrumb, Card, Row, Col, Button,
   Popconfirm, notification
} from "antd";
import {
   UItable, UISearch, UIModalCreate
} from "../../components";

class MasterKota extends Component {
   constructor(props) {
      super(props);
      this.state = {
         filter: "isDeleted=false",
         orderBy: "createdOn desc",
         top: 10,
         skip: 0,
         visibleCreate: false,
         loading: false,
         initialData: {
            countryId: undefined,
            provinceId: undefined,
            cityName: undefined
         },
         tempInitialData: {
            countryId: undefined,
            provinceId: undefined,
            cityName: undefined
         },
         isEdit: false,
         countryId: undefined,
         cityId: undefined
      }
   }

   componentDidMount() {
      const { filter, orderBy, top, skip } = this.state;
      const body = {
         token: this.props.token,
         filter,
         orderBy,
         top,
         skip
      };

      this.props.handleData(body);
      this.props.handleLOVCountry();
   }

   handleTableChange = (page, filterBy, sorter) => {
      const { top, filter } = this.state;

      let sorterField = ""
      if (sorter.field === "cityName") {
         sorterField = "cityName";
      } else if (sorter.field === "provinceName" ) {
         sorterField = "provinceId.provinceName";
      } else {
         sorterField = "provinceId.countryId.countryName";
      }

      let orderBy = "";
      if (sorter.order === "ascend") {
         orderBy = `${sorterField} asc`;
      } else if (sorter.order === "descend") {
         orderBy = `${sorterField} desc`;
      } else {
         orderBy = `createdOn desc`;
      }
      const body = {
         token: this.props.token,
         filter,
         orderBy,
         top,
         skip: page.current - 1
      }
      this.setState({
         skip: page.current - 1,
         orderBy
      }, () => {
         this.props.handleData(body)
      })
   }

   handleSearch = key => {
      const { orderBy, top } = this.state;
      const encodeKey = GlobalFunction.searchEncode(key)
      const value = `isDeleted=false AND ( LOWER(cityName) LIKE LOWER('%25${encodeKey}%25') OR LOWER(provinceId.provinceName) LIKE LOWER('%25${encodeKey}%25')  OR LOWER(provinceId.countryId.countryName) LIKE LOWER('%25${encodeKey}%25') )`;
      this.setState({
         filter: value,
         skip: 0
      });
      const body = {
         token: this.props.token,
         filter: value,
         orderBy,
         top,
         skip: 0,
      }
      this.props.handleData(body)
   }

   openModal = () => {
      this.setState({
         visibleCreate: true,
         isEdit: false,
         initialData: {
            countryId: undefined,
            provinceId: undefined,
            cityName: undefined
         },
         tempInitialData: {
            countryId: undefined,
            provinceId: undefined,
            cityName: undefined
         }
      })
   }

   handleCloseModal = () => {
      this.setState({ visibleCreate: false })
   }

   handleEditClick = data => {
      this.props.handleLOVProvince(data.countryId);
      this.setState({
         cityId: data.cityId,
         visibleCreate: true,
         isEdit: true,
         initialData: {
            countryId: data.countryId,
            provinceId: data.provinceId,
            cityName: data.cityName
         },
         tempInitialData: {
            countryId: data.countryId,
            provinceId: data.provinceId,
            cityName: data.cityName
         }
      })
   }

   onReset = () => {
      const { isEdit, tempInitialData} = this.state;
      if ( isEdit ) {
         this.setState({ initialData: tempInitialData })
         this.props.handleLOVProvince(this.state.tempInitialData.countryId);
      } else {
         this.setState({
            initialData: {
               countryId: undefined,
               provinceId: undefined,
               cityName: undefined
            }
         })
      }
   }

   handleSubmitCreate = (token, body) => {
      this.props.handleCreate(token, body).then(res => {
         if ( res.payload.data.status === 200 ) {
            notification.success({
               placement: 'bottomRight',
               message: 'Success',
               description: "Berhasil menambahkan kota.",
            });
            this.setState({
               filter: "isDeleted=false",
               orderBy: "createdOn desc",
               top: 10,
               skip: 0,
               visibleCreate: false
            }, () => {
               const body = {
                  filter: "isDeleted=false",
                  orderBy: "createdOn desc",
                  top: 10,
                  skip: 0,
                  token: token
               }
               this.props.handleData(body);
            })
         } else {
            notification.error({
               placement: 'bottomRight',
               message: 'Error',
               description: res.payload.error.response.data.message ? res.payload.error.response.data.message : "Gagal menambahkan kota.",
            });
         }
      })
   }

   handleSubmitEdit = (token, body, id) => {
      this.props.handleEdit(token, body, id).then( res => {
         if ( res.payload.data.status === 200 ) {
            notification.success({
               placement: 'bottomRight',
               message: 'Success',
               description: "Berhasil mengubah kota.",
            });
            this.setState({
               filter: "isDeleted=false",
               orderBy: "createdOn desc",
               top: 10,
               skip: 0,
               visibleCreate: false
            }, () => {
               const body = {
                  filter: "isDeleted=false",
                  orderBy: "createdOn desc",
                  top: 10,
                  skip: 0,
                  token: token
               }
               this.props.handleData(body);
            })
         } else {
            notification.error({
               placement: 'bottomRight',
               message: 'Error',
               description: res.payload.error.response.data.message ? res.payload.error.response.data.message : "Gagal mengubah kota",
            });
            this.setState({ initialData: this.state.tempInitialData })
            this.props.handleLOVProvince(this.state.tempInitialData.countryId);
         }
      });
   }

   handleFinish = values => {
      this.setState({ 
         initialData: {
            countryId: values.countryId,
            provinceId: values.provinceId,
            cityName: values.cityName,
         }  
      })
      const body = {
         provinceId: values.provinceId,
         cityName: values.cityName,
         userId: this.props.userId
      }

      if (this.state.isEdit) {
         this.handleSubmitEdit(this.props.token, body, this.state.cityId)
      } else {
         this.handleSubmitCreate(this.props.token ,body);
      }
   }

   handleOnDelete = data => {
      const { token, userId } = this.props;
      const body = {
         userId
      };
      this.props.handleDelete(token, body, data.cityId).then( res => {
         if ( res.payload.data.status === 200 ) {
            notification.success({
               placement: 'bottomRight',
               message: 'Success',
               description: "Berhasil menghapus kota.",
            });
            this.setState({
               filter: "isDeleted=false",
               orderBy: "createdOn desc",
               top: 10,
               skip: 0,
               visibleCreate: false
            }, () => {
               const body = {
                  filter: "isDeleted=false",
                  orderBy: "createdOn desc",
                  top: 10,
                  skip: 0,
                  token: token
               }
               this.props.handleData(body);
            })
         } else {
            notification.error({
               placement: 'bottomRight',
               message: 'Error',
               description: res.payload.error.response.data && res.payload.error.response.data.message ? res.payload.error.response.data.message : "Gagal menghapus kota.",
            })
         }
      })
   }

   render() {
      const { skip, visibleCreate, loading, initialData, isEdit } = this.state
      const { dataMasterKota, dataCreateMasterKota, dataPutMasterKota } = this.props;
      const text = 'Apakah anda yakin menghapus Kota ini ?';
      const columnsTable = [
         {
            title: "Kota",
            dataIndex: 'cityName',
            key: 'cityName',
            sorter: true,
            ellipsis: true
         },
         {
            title: "Provinsi",
            dataIndex: 'provinceName',
            key: 'provinceName',
            sorter: true,
            ellipsis: true
         },
         {
            title: "Negara",
            dataIndex: 'countryName',
            key: 'countryName',
            sorter: true,
            ellipsis: true
         },
         {
            title: 'Action',
            dataIndex: '',
            key: '',
            render: (data) => <>
               <Button
                  onClick={() => this.handleEditClick(data)}
                  type='link'
                  style={{ color: '#EF712B', cursor: 'pointer', fontWeight: 600 }}
               >
                  Edit
               </Button>
               <Popconfirm
                  placement="bottomRight"
                  title={text}
                  onConfirm={() => this.handleOnDelete(data)}
                  okText="Yes"
                  cancelText="No"
               >
                  <Button
                     type='link'
                     style={{ color: '#E22828', cursor: 'pointer', fontWeight: 600 }}
                  >
                     Delete
                  </Button>
               </Popconfirm>
            </>
         },
      ];
      const formItem = [
         {
            name: 'countryId',
            type: 'LOV',
            colNumber: 23,
            dataLOV: this.props.dataLOVCountry.data,
            rules: [
               {
                  required: true,
                  message: 'Please select country!'
               }
            ],
            placeholder: 'Negara',
            onChange: value => {
               this.setState({
                  countryId: value,
                  initialData: {
                     ...initialData,
                     countryId: value,
                     provinceId: undefined
                  }
               });
               this.props.handleLOVProvince(value);
            }
         },
         {
            name: 'provinceId',
            type: 'LOV',
            colNumber: 23,
            dataLOV: this.props.dataLOVProvince.data,
            rules: [
               {
                  required: true,
                  message: "Please select province!"
               }
            ],
            placeholder: "Provinsi"
         },
         {
            name: 'cityName',
            type: 'input',
            maxLength: 125,
            colNumber: 23,
            rules: [
               {
                  required: true,
                  message: 'Please input city!'
               }
            ],
            placeholder: 'Kota'
         }
      ]

      return (
         <div className="root">
            <Breadcrumb className="breadcrumb_style">
               <Breadcrumb.Item>
                  <span style={{ cursor: 'pointer' }}>Master</span>
               </Breadcrumb.Item>
               <Breadcrumb.Item className='pathNow_style'>
                  Kota
            </Breadcrumb.Item>
            </Breadcrumb>
            <Card className="bodyCard_style">
               <Row>
                  <Col span={8}>
                     <UISearch placeholder='Search' handleSearch={key => this.handleSearch(key)}/>
                  </Col>
                  <Col span={16} style={{ textAlign: 'right' }}>
                     <Button className='updateButton' onClick={() => this.openModal()}>Tambah Kota</Button>
                  </Col>
               </Row>
               <Card
                  bordered={false}
                  loading={dataMasterKota.loading}
                  style={{ minHeight: '300px' }}>
                  <UItable
                     dataTable={dataMasterKota.data}
                     columns={columnsTable}
                     withRows={false}
                     isMobile={this.props.isMobile}
                     loading={dataMasterKota.status}
                     page={skip+1}
                     totalData={dataMasterKota.totalData}
                     handleTableChange={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter)}
                  />
               </Card>
            </Card>
            <UIModalCreate
               formItem={formItem}
               visible={visibleCreate}
               loading={loading}
               isMobile={this.props.isMobile}
               initialValues={initialData}
               title={isEdit ? 'Edit Kota' : 'Tambah Kota'}
               widthDivider='250px'
               close={() => this.handleCloseModal()}
               checkFinish={values => this.handleFinish(values)}
               loadingSubmit={false}
               onReset={() => this.onReset()}
               loadingSubmit={isEdit ? dataPutMasterKota.status === "loading" : dataCreateMasterKota.status === "loading"}
            />
         </div>
      )
   }
}

const mapDispatchToProps = dispatch => {
   return {
      handleData: (param) => dispatch(masterKotaAction.getData(param)),
      handleLOVCountry: () => dispatch(lov.getLOVCountry()),
      handleLOVProvince: (id) => dispatch(lov.getLOVProvince(id)),
      handleCreate: (token, body) => dispatch(masterKotaAction.createKota(token, body)),
      handleEdit: (token, body, id) => dispatch(masterKotaAction.editKota(token, body, id)),
      handleDelete: (token, body, id) => dispatch(masterKotaAction.deleteKota(token, body, id))
   }
}

const mapStateToProps = (state) => {
   return {
      dataMasterKota: state.masterKota,
      dataLOVCountry: state.lovCountry,
      dataLOVProvince: state.lovProvince,
      dataPutMasterKota: state.putMasterKota,
      dataCreateMasterKota: state.createMasterKota,
   }
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterKota)
