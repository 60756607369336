import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../global/global.css'
import {
  Card,
  Button,
  Row,
  Col,
  Breadcrumb,
  Typography
} from 'antd'
import { UItable, UISearch } from '../../components'

const { Text } = Typography

class ListUpdateSiswa extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initial: '',
      orderBy: '',
      size: 10,
      page: 1,
      search: '',
      rowSelect: []
    }
  }

  handleSearch = key => {

  }


  handleTableChange = (page, filter, sorter) => { 

  }

  handleFinish = values => {
  }

  checkedBox = data => {
  }

  handleUpdate = () => {
    
  }

  render() {
    const { rowSelect, page } = this.state
    const columnsTable = [
      {
        title: 'NISN',
        dataIndex: 'nisn',
        key: 'nisn',
        sorter: true,
        render: nisn => <Text style={{ color: '#EF712B', fontWeight: 600 }}>{nisn}</Text>,
      },
      {
        title: 'Nama',
        dataIndex: 'nama',
        key: 'nama',
        sorter: true,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        sorter: true,
      },
      {
        title: 'Username',
        dataIndex: 'username',
        key: 'username',
        sorter: true,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        render: status => <Text style={{ color: '#EF712B', fontWeight: 600 }}>{status}</Text>,
      },
    ]
    return(
      <div className='root'>
        <Breadcrumb className="breadcrumb_style">
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/dashboard`)}>Pengaturan Umum</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className='pathNow_style'>
            Update Siswa
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card className='bodyCard_style'>
          <Row>
            <Col span={8}>
              <UISearch placeholder='Search' handleSearch={key => this.handleSearch(key)} />
            </Col>
            <Col span={16} style={{ textAlign: 'right'}}>
              <Button onClick={() => this.handleUpdate()} className='updateButton'>Update Status</Button>
            </Col>
          </Row>
          <Card 
            bordered={false} 
            // loading={dataManageBidiktiWeek.loading} 
            style={{minHeight: '300px'}}>
            <UItable
              // dataTable={dataFAQ.data}
              rowSelect={rowSelect}
              isMobile={this.props.isMobile}
              columns={columnsTable}
              checkedBox={data => this.checkedBox(data)}
              // loading={dataFAQ.status}
              page={page}
              // totalData={dataFAQ.totalData}
              handleTableChange={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter)}
            />
          </Card>
        </Card>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return{
    // handleDataApproval: (token, sort, size, page, search) => dispatch(getApproval.getDataApproval(token, sort, size, page, search)),
    // handleLOVSchedule: (token) => dispatch(lov.getLOVSchedule(token)),
    // handleLOVSession: (token, id) => dispatch(lov.getLOVSession(token, id)),
    // handleLOVInstansi: (token, search, size, isKedutaan) => dispatch(lov.getLOVInstansi(token, search, size, isKedutaan)),
    // handleUpdateApproval: (token, body) => dispatch(getApproval.putApproval(token, body)),
    // handleRejectApproval: (token, body) => dispatch(getApproval.rejectApproval(token, body)),
    // handlePostUniv: (token, body) => dispatch(getApproval.regisUniv(token, body)),
  }
}

const mapStateToProps = (state) => {
  return {
    // dataApproval: state.approval,
    // dataLOVSchedule: state.lovSchedule,
    // dataLOVSession: state.lovSession,
    // dataLOVInstansi: state.lovInstansi,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ListUpdateSiswa)