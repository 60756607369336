import {get, post, put} from 'axios'
import config from '../services/config'

const {BASE_URL_LOGIN, TIMEOUT} = config

const getEvent = (token, sort, size, page, search) => {
  return get(`${BASE_URL_LOGIN}be/api/sharingEventListAdmin?sort=${sort}&size=${size}&page=${page-1}&search=${search}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.items.map(res => {
      return {
        sharingEventSpeaker: res.sharingEventSpeaker,
        sharingTypeId: res.sharingType.sharingTypeId,
        sharingTypeName: res.sharingType.sharingTypeName,
        sharingEventDay: res.sharingEventDay,
        sharingEventDate: res.sharingEventDate,
        isOnline: res.isOnline,
        sharingEventId: res.sharingEventId,
        id: res.sharingEventId,
      }
    })
    return {
      data: normalizeData,
      totalData: response.data.totalItems,
      status: 'success',
      error: 'fail'
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const getEventDetail = (token, id) => {
  return get(`${BASE_URL_LOGIN}be/api/sharingEventDetailAdmin/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = {
      sharingEventSpeaker: response.data.sharingEventSpeaker,
      sharingEventKeklGradYear: response.data.sharingEventKeklGradYear,
      sharingEventDesc: response.data.sharingEventDesc,
      sharingEventThumbnail: response.data.sharingEventThumbnail,
      sharingEventDay: response.data.sharingEventDay,
      sharingEventDate: response.data.sharingEventDate,
      sharingEventTime: response.data.sharingEventTime,
      sharingEventMedia: response.data.sharingEventMedia,
      sharingEventMediaJoinId: response.data.sharingEventMediaJoinId,
      sharingEventMediaJoinPwd: response.data.sharingEventMediaJoinPwd,
      sharingEventReminderLoyolaQuota: response.data.sharingEventReminderLoyolaQuota,
      sharingEventLoyolaQuota: response.data.sharingEventLoyolaQuota,
      sharingEventReminderOtherQuota: response.data.sharingEventReminderOtherQuota,
      sharingEventOtherQuota: response.data.sharingEventOtherQuota,
      sharingEventMediaUrl: response.data.sharingEventMediaUrl,
      sharingEventPublishStatus: response.data.sharingEventPublishStatus,
      id: response.data.sharingEventId,
      isOnline: response.data.isOnline,
      isDeleted: response.data.isDeleted,
    }
    return {
      data: normalizeData,
      status: 'success',
      error: 'fail'
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const getEventParticipant = (token, id, group, sort, size, page, search) => {
  return get(`${BASE_URL_LOGIN}be/api/participantSharingEventAdmin?eventId=${id}&group=${group}&sort=${sort}&search=${search}&page=${page - 1}&size=${size}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.items.map(res => {
      return {
        sharingParticipantName: res.sharingParticipantName,
        email: res.email,
        roleName: res.roleName,
        userId: res.userId,
        roleGroupName: res.roleGroupName,
        id: res.userId,
      }
    })
    return {
      data: normalizeData,
      totalData: response.data.totalItems,
      status: 'success',
      error: 'fail'
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const postEmail = (token, id) => {
  return post(`${BASE_URL_LOGIN}be/api/sendEmailSharingAdmin/${id}`, {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      totalData: 0,
      error,
      data: []
    }
  })
}

const getLOVSharingType = (token) => {
  return get(`${BASE_URL_LOGIN}be/api/sharingType`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.items.map(res => {
      return {
        sharingTypeId: res.sharingTypeId,
        sharingTypeName: res.sharingTypeName,
        id: res.sharingTypeId,
      }
    })
    return {
      data: normalizeData,
      totalData: response.data.totalItems,
      status: 'success',
      error: 'fail'
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const postEventLifeSharing = (token, body) => {
  return post(`${BASE_URL_LOGIN}be/api/submitSharingEventAdmin`, body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      totalData: 0,
      error,
      data: []
    }
  })
}

const deleteEvent = (token, id, body) => {
  return put(`${BASE_URL_LOGIN}be/api/deleteSharingEventAdmin/${id}`, body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      totalData: 0,
      error,
      data: []
    }
  })
}


export default {
  getEvent,
  getEventDetail,
  getEventParticipant,
  postEmail,
  getLOVSharingType,
  postEventLifeSharing,
  deleteEvent,
}
