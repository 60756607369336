import types from '../constants'
import daftarAcara from '../api/roleUnivDaftarAcara'

const getDataDaftarAcara = (token, id, sort, size, page, search) => dispatch => {
  dispatch({
    type: types.GET_DAFTAR_ACARA_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return daftarAcara.getDaftarAcara(token, id, sort, size, page, search).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_DAFTAR_ACARA_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_DAFTAR_ACARA_SUCCESS,
        payload: result
      })
    }
  })
}

const getDataDetailDaftarAcara = (token, id) => dispatch => {
  dispatch({
    type: types.GET_DAFTAR_ACARA_DETAIL_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return daftarAcara.getDaftarAcaraDetail(token, id).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_DAFTAR_ACARA_DETAIL_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_DAFTAR_ACARA_DETAIL_SUCCESS,
        payload: result
      })
    }
  })
}

const getDataAcaraParticipant = (token, roleGroupId, univEventId, sort, size, page, search) => dispatch => {
  dispatch({
    type: types.GET_ACARA_PARTICIPANT_LIST_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return daftarAcara.getAcaraParticipantList(token, roleGroupId, univEventId, sort, size, page, search).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_ACARA_PARTICIPANT_LIST_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_ACARA_PARTICIPANT_LIST_SUCCESS,
        payload: result
      })
    }
  })
}

const postDataEvent = (token, body) => dispatch => {
  dispatch({
    type: types.POST_DAFTAR_ACARA_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return daftarAcara.postEvent(token, body).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.POST_DAFTAR_ACARA_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.POST_DAFTAR_ACARA_SUCCESS,
        payload: result
      })
    }
  })
}

export default {
  getDataDaftarAcara,
  getDataDetailDaftarAcara,
  getDataAcaraParticipant,
  postDataEvent,
}
