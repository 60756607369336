import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../global/global.css'
import getManageBidiktiWeek from '../../actions/manageBidiktiWeek'
import { Card, notification, Breadcrumb, Popconfirm, Button, Typography } from 'antd'
import moment from 'moment'
import { UIContentTemaBidiktiDetail, UIModalEditDetail, UIModalSessionSchedule } from '../../components'

const { Text } = Typography

class ManageBidiktiWeekDetail extends Component{
  constructor(props) {
    super(props);
    this.state = {
      initial: '',
      id: '',
      page: 1,
      orderBy: '',
      size: 10,
      search: '',
      modalEdit: false,
      visibleSchedule: false,
      visibleSession: false,
      visibleAddSchedule: false,
      visibleAddSesi: false,
      loading: false,
      loadingUpate: false,
      listImage: [],
      loadingDelete: false,
      dataEditSesi: {
        bwSessionName: undefined,
        bwSessionTimeFinish: undefined,
        bwSessionTimeStart: undefined,
        isWeekend: undefined,
        id: undefined,
      }
    }
  }

  componentDidMount() {
    let id = this.props.location.pathname.split('/')[2]
    this.setState({
      id: id
    })
    this.props.handleDataDetail(this.props.token, id).then(res=> {
      if (res.payload.data.Message === "Successfull") {
        this.setState({
          listImage: res.payload.data.listDataBwImage
        })
      }
    })
  }

  openModal = (name) => {
    if (name === 'tema') {
      this.setState({
        modalEdit: true
      })
    }else if (name === 'sesi') {
      this.props.handleDataSesion(this.props.token, '', 10, 1, '', this.state.id)
      this.setState({
        visibleSession: true,
        orderBy: '',
        page: 1,
        search: '',
      })
    }else {
      this.props.handleDataSchedule(this.props.token, '', 10, 1, '', this.state.id)
      this.setState({
        visibleSchedule: true,
        orderBy: '',
        page: 1,
        search: '',
      })
    }
  }

  closeModal = () => {
    this.setState({
      modalEdit: false,
      visibleSchedule: false,
      visibleSession: false,
    })
  }

  closeModalCreate = () => {
    this.setState({
      visibleAddSchedule: false,
      visibleAddSesi: false,
      dataEditSesi: {
        bwSessionName: undefined,
        bwSessionTimeFinish: undefined,
        bwSessionTimeStart: undefined,
        isWeekend: undefined,
        id: undefined,
      }
    })
  }

  handleCreate = (name, value) => {
    let body = {
      ...value,
      lastModifiedBy: this.props.userId,
      createdBy: this.props.userId,
    }
    if (value.isEdit) {
      body = {
        ...value,
        lastModifiedBy: this.props.userId,
      }
      this.handleDataEdit(body)
    }else {
      if (name === 'schedule') {
        this.handleAddSchedule(body)
      }else {
        this.handleAddSesi(body)
      }
    }
  }

  handleDataEdit = value => {
    this.setState({
      loading: true
    })
    let end = moment(value.bwSessionTimeFinish).format('HH:mm')
    let start = moment(value.bwSessionTimeStart).format('HH:mm')
    let temp = {
      bwSessionName: value.bwSessionName,
      bwSessionTimeFinish: end + ':00',
      bwSessionTimeStart: start + ':00',
      isWeekend: value.isWeekend ? value.isWeekend : false,
      lastModifiedBy: this.props.userId,
    }

    this.props.handleUpdateSesi(this.props.token, temp, value.id).then(res => {
      if (res.payload.status !== 'error') {
        if (res.payload.data.data.Status === 'OK') {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Create Session Success',
          });
          this.setState({
            loading: false,
            visibleAddSesi: false,
            search: '',
            orderBy: '',
            page: 1,
            dataEditSesi: {
              bwSessionName: undefined,
              bwSessionTimeFinish: undefined,
              bwSessionTimeStart: undefined,
              isWeekend: undefined,
              id: undefined,
            }
          })
          this.props.handleDataSesion(this.props.token, '', 10, 1, '', this.state.id)
        }else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Create Session Failed'
          });
          this.setState({
            loading: false,
          })
        }
      }else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: 'Create Session Failed'
        });
        this.setState({
          loading: false,
        })
      }
    })
  }

  handleAddSchedule = value => {
    this.setState({
      loading: true
    })
    this.props.handleCreateSchedule(this.props.token, value).then(res => {
      if (res.payload.status !== 'error') {
        if (res.payload.data.data.Status === 'OK') {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Create Schedulle Success',
          });
          this.setState({
            loading: false,
            visibleAddSchedule: false,
            search: '',
            orderBy: '',
            page: 1
          })
          this.props.handleDataSchedule(this.props.token, '', 10, 1, '', this.state.id)
        }else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Create Schedulle Failed'
          });
          this.setState({
            loading: false,
          })
        }
      }else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: 'Create Schedulle Failed'
        });
        this.setState({
          loading: false,
        })
      }
    })
  }

  handleAddSesi = value => {
    this.setState({
      loading: true
    })
    let end = moment(value.bwSessionTimeFinish).format('HH:mm')
    let start = moment(value.bwSessionTimeStart).format('HH:mm')
    let temp = {
      bwSessionName: value.bwSessionName,
      bwSessionTimeFinish: end + ':00',
      bwSessionTimeStart: start + ':00',
      isWeekend: value.isWeekend ? value.isWeekend : false,
      lastModifiedBy: this.props.userId,
      createdBy: this.props.userId,
    }
    this.props.handleCreateSesi(this.props.token, temp).then(res => {
      if (res.payload.status !== 'error') {
        if (res.payload.data.data.Status === 'OK') {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Create Session Success',
          });
          this.setState({
            loading: false,
            visibleAddSesi: false,
            search: '',
            orderBy: '',
            page: 1,
            dataEditSesi: {
              bwSessionName: undefined,
              bwSessionTimeFinish: undefined,
              bwSessionTimeStart: undefined,
              isWeekend: undefined,
              id: undefined,
            }
          })
          this.props.handleDataSesion(this.props.token, '', 10, 1, '', this.state.id)
        }else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Create Session Failed'
          });
          this.setState({
            loading: false,
          })
        }
      }else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: 'Create Session Failed'
        });
        this.setState({
          loading: false,
        })
      }
    })
  }

  handleSearch = (key, name) => {
    const {orderBy, size, id} = this.state
    this.setState({
      search: key
    })
    if (name ==='sesi') {
      this.props.handleDataSesion(this.props.token, orderBy, size, 1, key, id)
    }else {
      this.props.handleDataSchedule(this.props.token, orderBy, size, 1, key, id)
    }
  }

  handleTable = (page, filter, sorter, name) => {
    const {size, search, id} = this.state
    let sorterField = ""
    if ( sorter.field === "bwScheduleDay" ) {
      sorterField = "bw_schedule_day";
    }else if ( sorter.field === "bwScheduleDate" ) {
      sorterField = "bw_schedule_date";
    }else if ( sorter.field === "bwSessionName" ) {
      sorterField = "bw_session_name";
    }else if ( sorter.field === "bwSessionTimeStartFinish" ) {
      sorterField = "bw_session_time_start";
    }else {
      sorterField = "is_weekend";
    }

    let orderBy = "";
    if (sorter.order === "ascend") {
      orderBy = `${sorterField},asc`;
    } else if (sorter.order === "descend") {
      orderBy = `${sorterField},desc`;
    }

    this.setState({
      page: page.current,
      orderBy: orderBy
    }, () => {
      if (name ==='sesi') {
        this.props.handleDataSesion(this.props.token, orderBy, size, page.current, search, id)
      }else {
        this.props.handleDataSchedule(this.props.token, orderBy, size, page.current, search, id)
      }
    })
  }

  handleDelete = (id, name) => {
    const body = {
      lastModifiedBy: this.props.userId
    }
    if (name === 'sesi') {
      this.handleDeleteDataSesi(body, id.id)
    }else {
      this.handleDeleteDataSchedule(body, id)
    }
  }

  handleDeleteDataSchedule = (body, id) => {
    this.setState({
      loadingDelete: true
    })
    this.props.handleDeleteSchedule(this.props.token, body, id).then(res => {
      if (res.payload.status !== 'error') {
        if (res.payload.data.data.Status === 'OK') {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Delete Schedulle Success',
          });
          this.setState({
            loadingDelete: false,
            search: '',
            orderBy: '',
            page: 1
          })
          this.props.handleDataSchedule(this.props.token, '', 10, 1, '', this.state.id)
        }else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Delete Schedulle Failed'
          });
          this.setState({
            loadingDelete: false,
          })
        }
      }else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: 'Delete Schedulle Failed'
        });
        this.setState({
          loadingDelete: false,
        })
      }
    })
  }

  handleDeleteDataSesi = (body, id) => {
    this.setState({
      loadingDelete: true
    })

    this.props.handleDeleteSesi(this.props.token, body, id).then(res => {
      if (res.payload.status !== 'error') {
        if (res.payload.data.data.Status === 'OK') {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Delete Session Success',
          });
          this.setState({
            loadingDelete: false,
            search: '',
            orderBy: '',
            page: 1
          })
          this.props.handleDataSesion(this.props.token, '', 10, 1, '', this.state.id)
        }else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Delete Session Failed'
          });
          this.setState({
            loadingDelete: false,
          })
        }
      }else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: 'Delete Session Failed'
        });
        this.setState({
          loadingDelete: false,
        })
      }
    })
  }

  handleEdit = data => {
    const parseString = data.bwSessionTimeStartFinish.split('-')
    const start = parseString[0].trim()
    const end = parseString[1].trim()
    const body = {
      bwSessionName: data.bwSessionName,
      bwSessionTimeFinish: end !== "NaN:NaN" ? moment(end, "HH:mm:ss") : null,
      bwSessionTimeStart: start !== "NaN:NaN" ? moment(start, "HH:mm:ss") : null,
      isWeekend: data.status === "Weekend" ? true : false,
      id: data.bwSessionId,
      isEdit: true
    }
    this.setState({
      dataEditSesi: body,
      visibleAddSesi: true
    })
  }

  handleSubmitEdit = (data) => {
    const body = {
      ...data,
      bidiktiWeekImage: this.state.listImage,
      lastModifiedBy: this.props.userId,
    }
    this.setState({
      loadingUpate: true
    })
    this.props.handleUpdateBW(this.props.token, body, this.state.id).then(res => {
      if (res.payload.status !== 'error') {
        if (res.payload.data.data.Status === 'OK') {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Update BIDikTi Week Success',
          });
          this.props.handleDataDetail(this.props.token, this.state.id).then(response=> {
            if (response.payload.data.Message === "Successfull") {
              this.setState({
                listImage: response.payload.data.listDataBwImage
              })
            }
          })
          this.setState({
            loadingUpate: false,
            modalEdit: false,
            dataEditSesi: {
              bwSessionName: undefined,
              bwSessionTimeFinish: undefined,
              bwSessionTimeStart: undefined,
              isWeekend: undefined,
              id: undefined,
            }
          })
        }else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Update BIDikTi Week Failed'
          });
          this.setState({
            loadingUpate: false,
          })
        }
      }else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: 'Update BIDikTi Week Failed'
        });
        this.setState({
          loadingUpate: false,
        })
      }
    })
  }

  handleAdd = name => {
    if (name === 'sesi') {
      this.setState({
        visibleAddSesi: true
      })
    }else {
      this.setState({
        visibleAddSchedule: true
      })
    }
  }

  handleSaveImage = data => (
    this.setState({
      listImage: data
    })
  )

  render() {
    const text = 'Apakah anda yakin menghapus data ini ?'
    const columnsSchedule = [
      {
        title: 'Hari',
        dataIndex: 'bwScheduleDay',
        key: 'bwScheduleDay',
        sorter: true,
        render: bwScheduleDay => <Text style={{ color: '#EF712B', fontWeight: 600 }}>{bwScheduleDay}</Text>,
      },
      {
        title: 'Tanggal Presentasi',
        dataIndex: 'bwScheduleDate',
        key: 'bwScheduleDate',
        sorter: true,
      },
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (record) =>
          <Popconfirm
            placement="bottomRight"
            title={text}
            onConfirm={() => this.handleDelete(record, 'schedule')}
            okText="Yes"
            cancelText="No"
            disabled={dataDetail.data.bwStatus === 'Non Active'}
          >
            <Button
              type='link'
              loading={loadingDelete}
              style={{ color: '#E22828', cursor: 'pointer', fontWeight: 600 }}
              disabled={dataDetail.data.bwStatus === 'Non Active'}
            >
              Delete
            </Button>
          </Popconfirm>
      },
    ]
    const columnsSesi = [
      {
        title: 'Nama Sesi',
        dataIndex: 'bwSessionName',
        key: 'bwSessionName',
        sorter: true,
        render: bwSessionName => <Text style={{ color: '#EF712B', fontWeight: 600 }}>{bwSessionName}</Text>,
      },
      {
        title: 'Start - Finish',
        dataIndex: 'bwSessionTimeStartFinish',
        key: 'bwSessionTimeStartFinish',
        sorter: true,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        render: status => status === 'Weekend' ? (
          <Text style={{ color: '#EF712B', fontWeight: 600 }}>{status}</Text>
        ) : (
          <Text style={{ color: '#11174C', fontWeight: 600 }}>{status}</Text>
        )
      },
      {
        title: 'Action',
        dataIndex: '',
        key: '',
        render: (record) => <>
          <Button
            onClick={() => this.handleEdit(record)}
            type='link'
            style={{ color: '#EF712B', fontWeight: 600 }}
            disabled={dataDetail.data.bwStatus === 'Non Active'}
          >
            Edit
          </Button>
          <Popconfirm
            placement="bottomRight"
            title={text}
            onConfirm={() => this.handleDelete(record, 'sesi')}
            okText="Yes"
            cancelText="No"
            disabled={dataDetail.data.bwStatus === 'Non Active'}
          >
            <Button
              type='link'
              loading={loadingDelete}
              style={{ color: '#E22828', cursor: 'pointer', fontWeight: 600 }}
              disabled={dataDetail.data.bwStatus === 'Non Active'}
            >
              Delete
            </Button>
          </Popconfirm>
        </>
      },
    ]
    const { dataDetail, dataSesion, dataSchedule } = this.props
    const {
      modalEdit,
      visibleSchedule,
      visibleSession,
      page,
      visibleAddSchedule,
      visibleAddSesi,
      loading,
      loadingUpate,
      loadingDelete,
      dataEditSesi
    } = this.state
    return(
      <div className='root'>
        <Breadcrumb className="breadcrumb_style" >
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/dashboard`)}>BIDikTi Week </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/manage-bidikti-week`)}>Kelola BIDikTi Week </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className='pathNow_style'>
            Detail Tema BIDikTi Week
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card loading={dataDetail.status === 'loading'} className='bodyCard_style'>
          <UIContentTemaBidiktiDetail
            from={'manageBidiktiWeek'}
            dataDetail={dataDetail.data}
            isMobile={this.props.isMobile}
            token={this.props.token}
            saveImage={data => this.handleSaveImage(data)}
            openModal={(name) => this.openModal(name)}
          />
        </Card>
        <UIModalEditDetail
          isMobile={this.props.isMobile}
          dataDetail={dataDetail.data}
          visible={modalEdit}
          loading={loadingUpate}
          handleEditBW={data => this.handleSubmitEdit(data)}
          token={this.props.token}
          handleBack={ () => this.closeModal()}
        />
        <UIModalSessionSchedule
          isMobile={this.props.isMobile}
          title={'Tanggal Presentasi'}
          visible={visibleSchedule}
          handleSearch={(key) => this.handleSearch(key, 'schedule')}
          handleBack={() => this.closeModal()}
          dataTable={dataSchedule.data}
          columns={columnsSchedule}
          loading={dataSchedule.status}
          page={page}
          from={'schedule'}
          handleOpenAdd={(from) => this.handleAdd(from)}
          totalData={dataSchedule.totalData}
          handleTableChange={(pageTable, filter, sort) => this.handleTable(pageTable, filter, sort, 'schedule')}
          buttonDisable={dataDetail.data.bwStatus === 'Non Active'}
        />
        <UIModalSessionSchedule
          isMobile={this.props.isMobile}
          title={'Sesi'}
          from={'sesi'}
          handleOpenAdd={(from) => this.handleAdd(from)}
          visible={visibleSession}
          handleSearch={(key) => this.handleSearch(key, 'sesi')}
          handleBack={() => this.closeModal()}
          dataTable={dataSesion.data}
          columns={columnsSesi}
          loading={dataSesion.status}
          page={page}
          totalData={dataSesion.totalData}
          handleTableChange={(pageTable, filter, sort) => this.handleTable(pageTable, filter, sort, 'sesi')}
          buttonDisable={dataDetail.data.bwStatus === 'Non Active'}
        />
        <UIModalSessionSchedule
          title={'Schedule'}
          isMobile={this.props.isMobile}
          dataDetail={dataDetail.data}
          from={'schedule'}
          visibleSchedule={visibleAddSchedule}
          handleBack={() => this.closeModalCreate()}
          loading={loading}
          handleCreate={(name, value) => this.handleCreate(name, value)}
        />
        <UIModalSessionSchedule
          title={'Sesi'}
          isMobile={this.props.isMobile}
          from={'sesi'}
          dataEditSesi={dataEditSesi}
          visibleSesi={visibleAddSesi}
          loading={loading}
          handleBack={() => this.closeModalCreate()}
          handleCreate={(name, value) => this.handleCreate(name, value)}
        />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return{
    handleDataSesion: (token, sort, size, page, search, id) => dispatch(getManageBidiktiWeek.getDataSessionBW(token, sort, size, page, search, id)),
    handleDataSchedule: (token, sort, size, page, search, id) => dispatch(getManageBidiktiWeek.getDataScheduleBW(token, sort, size, page, search, id)),
    handleDataDetail: (token, id) => dispatch(getManageBidiktiWeek.getDataDetailManageBidiktiWeek(token, id)),
    handleCreateSchedule: (token, body) => dispatch(getManageBidiktiWeek.createSchedule(token, body)),
    handleCreateSesi: (token, body) => dispatch(getManageBidiktiWeek.createSesi(token, body)),
    handleUpdateBW: (token, body, id) => dispatch(getManageBidiktiWeek.updateBW(token, body, id)),
    handleUpdateSesi: (token, body, id) => dispatch(getManageBidiktiWeek.updateSesi(token, body, id)),
    handleDeleteSchedule: (token, body, id) => dispatch(getManageBidiktiWeek.deleteSchedule(token, body, id)),
    handleDeleteSesi: (token, body, id) => dispatch(getManageBidiktiWeek.deleteSesi(token, body, id)),
  }
}

const mapStateToProps = (state) => {
  return {
    dataSesion: state.bwSesion,
    dataSchedule: state.bwSchedule,
    dataDetail: state.detailManageBidiktiWeek,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageBidiktiWeekDetail)
