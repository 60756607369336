const GET_SCHEDULE_LOADING = 'GET_SCHEDULE_LOADING'
const GET_SCHEDULE_SUCCESS = 'GET_SCHEDULE_SUCCESS'
const GET_SCHEDULE_FAILED = 'GET_SCHEDULE_FAILED'
const GET_SESSION_LOADING = 'GET_SESSION_LOADING'
const GET_SESSION_SUCCESS = 'GET_SESSION_SUCCESS'
const GET_SESSION_FAILED = 'GET_SESSION_FAILED'
const GET_INSTANSI_LOADING = 'GET_INSTANSI_LOADING'
const GET_INSTANSI_SUCCESS = 'GET_INSTANSI_SUCCESS'
const GET_INSTANSI_FAILED = 'GET_INSTANSI_FAILED'
const GET_COUNTRY_LOADING = 'GET_COUNTRY_LOADING'
const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS'
const GET_COUNTRY_FAILED = 'GET_COUNTRY_FAILED'
const GET_PROVINCE_LOADING = 'GET_PROVINCE_LOADING'
const GET_PROVINCE_SUCCESS = 'GET_PROVINCE_SUCCESS'
const GET_PROVINCE_FAILED = 'GET_PROVINCE_FAILED'
const GET_CITY_LOADING = 'GET_CITY_LOADING'
const GET_CITY_SUCCESS = 'GET_CITY_SUCCESS'
const GET_CITY_FAILED = 'GET_CITY_FAILED'
const GET_LIFE_SHARING_CAT_LOADING = 'GET_LIFE_SHARING_CAT_LOADING'
const GET_LIFE_SHARING_CAT_SUCCESS = 'GET_LIFE_SHARING_CAT_SUCCESS'
const GET_LIFE_SHARING_CAT_FAILED = 'GET_LIFE_SHARING_CAT_FAILED'
const GET_SCHEDULE_GALLERY_LOADING = 'GET_SCHEDULE_GALLERY_LOADING'
const GET_SCHEDULE_GALLERY_SUCCESS = 'GET_SCHEDULE_GALLERY_SUCCESS'
const GET_SCHEDULE_GALLERY_FAILED = 'GET_SCHEDULE_GALLERY_FAILED'
const GET_SESSION_GALLERY_LOADING = 'GET_SESSION_GALLERY_LOADING'
const GET_SESSION_GALLERY_SUCCESS = 'GET_SESSION_GALLERY_SUCCESS'
const GET_SESSION_GALLERY_FAILED = 'GET_SESSION_GALLERY_FAILED'
const GET_LOV_INSTANSI_LOADING = 'GET_LOV_INSTANSI_LOADING'
const GET_LOV_INSTANSI_SUCCESS = 'GET_LOV_INSTANSI_SUCCESS'
const GET_LOV_INSTANSI_FAILED = 'GET_LOV_INSTANSI_FAILED'
const GET_LOV_LIFE_SHARING_TYPE_LOADING = 'GET_LOV_LIFE_SHARING_TYPE_LOADING'
const GET_LOV_LIFE_SHARING_TYPE_SUCCESS = 'GET_LOV_LIFE_SHARING_TYPE_SUCCESS'
const GET_LOV_LIFE_SHARING_TYPE_FAILED = 'GET_LOV_LIFE_SHARING_TYPE_FAILED'
const GET_LOV_LIFE_SHARING_SPEAKER_LOADING = 'GET_LOV_LIFE_SHARING_SPEAKER_LOADING'
const GET_LOV_LIFE_SHARING_SPEAKER_SUCCESS = 'GET_LOV_LIFE_SHARING_SPEAKER_SUCCESS'
const GET_LOV_LIFE_SHARING_SPEAKER_FAILED = 'GET_LOV_LIFE_SHARING_SPEAKER_FAILED'
const GET_LOV_LIFE_SHARING_DATE_LOADING = 'GET_LOV_LIFE_SHARING_DATE_LOADING'
const GET_LOV_LIFE_SHARING_DATE_SUCCESS = 'GET_LOV_LIFE_SHARING_DATE_SUCCESS'
const GET_LOV_LIFE_SHARING_DATE_FAILED = 'GET_LOV_LIFE_SHARING_DATE_FAILED'
const GET_LOV_LIFE_SHARING_TIME_LOADING = 'GET_LOV_LIFE_SHARING_TIME_LOADING'
const GET_LOV_LIFE_SHARING_TIME_SUCCESS = 'GET_LOV_LIFE_SHARING_TIME_SUCCESS'
const GET_LOV_LIFE_SHARING_TIME_FAILED = 'GET_LOV_LIFE_SHARING_TIME_FAILED'
const GET_LOV_EVENT_NAME_BY_UNIV_LOADING = 'GET_LOV_EVENT_NAME_BY_UNIV_LOADING';
const GET_LOV_EVENT_NAME_BY_UNIV_SUCCESS = 'GET_LOV_EVENT_NAME_BY_UNIV_SUCCESS';
const GET_LOV_EVENT_NAME_BY_UNIV_FAILED = 'GET_LOV_EVENT_NAME_BY_UNIV_FAILED';
const GET_LOV_DATE_BY_EVENT_LOADING = 'GET_LOV_DATE_BY_EVENT_LOADING';
const GET_LOV_DATE_BY_EVENT_SUCCESS = 'GET_LOV_DATE_BY_EVENT_SUCCESS';
const GET_LOV_DATE_BY_EVENT_FAILED = 'GET_LOV_DATE_BY_EVENT_FAILED';
const GET_LOV_UNIVERSITAS_CATEGORI_LOADING = 'GET_LOV_UNIVERSITAS_CATEGORI_LOADING';
const GET_LOV_UNIVERSITAS_CATEGORI_SUCCESS = 'GET_LOV_UNIVERSITAS_CATEGORI_SUCCESS';
const GET_LOV_UNIVERSITAS_CATEGORI_FAILED = 'GET_LOV_UNIVERSITAS_CATEGORI_FAILED';

export {
  GET_SCHEDULE_LOADING,
  GET_SCHEDULE_SUCCESS,
  GET_SCHEDULE_FAILED,
  GET_SESSION_LOADING,
  GET_SESSION_SUCCESS,
  GET_SESSION_FAILED,
  GET_INSTANSI_LOADING,
  GET_INSTANSI_SUCCESS,
  GET_INSTANSI_FAILED,
  GET_COUNTRY_LOADING,
  GET_COUNTRY_SUCCESS,
  GET_COUNTRY_FAILED,
  GET_PROVINCE_LOADING,
  GET_PROVINCE_SUCCESS,
  GET_PROVINCE_FAILED,
  GET_CITY_LOADING,
  GET_CITY_SUCCESS,
  GET_CITY_FAILED,
  GET_LIFE_SHARING_CAT_LOADING,
  GET_LIFE_SHARING_CAT_SUCCESS,
  GET_LIFE_SHARING_CAT_FAILED,
  GET_SCHEDULE_GALLERY_LOADING,
  GET_SCHEDULE_GALLERY_SUCCESS,
  GET_SCHEDULE_GALLERY_FAILED,
  GET_SESSION_GALLERY_LOADING,
  GET_SESSION_GALLERY_SUCCESS,
  GET_SESSION_GALLERY_FAILED,
  GET_LOV_INSTANSI_LOADING,
  GET_LOV_INSTANSI_SUCCESS,
  GET_LOV_INSTANSI_FAILED,
  GET_LOV_LIFE_SHARING_TYPE_LOADING,
  GET_LOV_LIFE_SHARING_TYPE_SUCCESS,
  GET_LOV_LIFE_SHARING_TYPE_FAILED,
  GET_LOV_LIFE_SHARING_SPEAKER_LOADING,
  GET_LOV_LIFE_SHARING_SPEAKER_SUCCESS,
  GET_LOV_LIFE_SHARING_SPEAKER_FAILED,
  GET_LOV_LIFE_SHARING_DATE_LOADING,
  GET_LOV_LIFE_SHARING_DATE_SUCCESS,
  GET_LOV_LIFE_SHARING_DATE_FAILED,
  GET_LOV_LIFE_SHARING_TIME_LOADING,
  GET_LOV_LIFE_SHARING_TIME_SUCCESS,
  GET_LOV_LIFE_SHARING_TIME_FAILED,
  GET_LOV_EVENT_NAME_BY_UNIV_LOADING,
  GET_LOV_EVENT_NAME_BY_UNIV_SUCCESS,
  GET_LOV_EVENT_NAME_BY_UNIV_FAILED,
  GET_LOV_DATE_BY_EVENT_LOADING,
  GET_LOV_DATE_BY_EVENT_SUCCESS,
  GET_LOV_DATE_BY_EVENT_FAILED,
  GET_LOV_UNIVERSITAS_CATEGORI_LOADING,
  GET_LOV_UNIVERSITAS_CATEGORI_SUCCESS,
  GET_LOV_UNIVERSITAS_CATEGORI_FAILED,
}
