import React, { useState } from "react";
import { Layout, Menu } from 'antd'
import { withRouter, NavLink } from "react-router-dom";
import logoUrl from '../../global/assets/logoHeader.png'
import {
  AppstoreFilled,
  UserOutlined,
  IdcardOutlined,
  BankOutlined,
  CommentOutlined,
  FileTextOutlined,
  SettingOutlined,
  HomeFilled,
  FileTextFilled,
  FileImageFilled,
  AccountBookOutlined,
  // ExportOutlined
} from '@ant-design/icons';
import './header.css'
// import Beasiswa from '../../global/assets/beasiswa.svg';
const { Sider } = Layout
const { SubMenu } = Menu;
const SideBarComponent = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const adminLyl = sessionStorage.getItem("adminLyl")
  const userRole = sessionStorage.getItem("userRole")
  const onCollapse = val => {
    setCollapsed(val)
  }

  let href = props.location.pathname;

  return (
    <>
    {props.isMobile ? (
        <>
          <div className="logoMobile">
            <img src={logoUrl} alt="logoHeader" className='logoHeader' />
            {collapsed === false
            ? 
              <span className="textLogo">Bursa Informasi Pendidikan Tinggi</span>
            : <></>}
          </div>
          {adminLyl === 'loyola' ? (
              // Menu Role Admin
              <Menu
                theme="dark"
                // defaultSelectedKeys={"/dashboard"}
                mode="horizontal"
                defaultSelectedKeys={[href]}
                // defaultOpenKeys={['sub1']}
                // selectedKeys={['/'+href]}
              >
                <Menu.Item key="/dashboard" icon={<AppstoreFilled />}>
                  <NavLink to="/dashboard">
                    Dashboard
                  </NavLink>
                </Menu.Item>
                <SubMenu key="sub2" icon={<BankOutlined />} title="Universitas">
                  <Menu.Item key="/daftar-persetujuan-konten">
                    <NavLink to="/daftar-persetujuan-konten" style={{ color: '#FFF' }}>
                      Daftar Persetujuan Konten
                      </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/daftar-persetujuan-acara">
                    <NavLink to="/daftar-persetujuan-acara" style={{ color: '#FFF' }}>
                      Daftar Persetujuan Acara
                      </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/daftar-persetujuan-galeri">
                    <NavLink to="/daftar-persetujuan-galeri" style={{ color: '#FFF' }}>
                      Daftar Persetujuan Galeri
                      </NavLink>
                  </Menu.Item>
                  {/* <Menu.Item key="/beasiswa">
                      <NavLink to="/beasiswa" style={{ color: '#FFF' }}>
                        Beasiswa
                      </NavLink>
                    </Menu.Item> */}
                </SubMenu>
                <SubMenu key="sub1" icon={<IdcardOutlined />} title="BIDikTi Week">
                  <Menu.Item key="/manage-bidikti-week">
                    <NavLink to="/manage-bidikti-week" style={{ color: '#FFF' }}>
                      Kelola BIDikTi Week
                      </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/list-presentation">
                    <NavLink to="/list-presentation" style={{ color: '#FFF' }}>
                      Daftar Presentasi
                      </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/list-approval">
                    <NavLink to="/list-approval" style={{ color: '#FFF' }}>
                      Daftar Persetujuan
                      </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/list-late-register">
                    <NavLink to="/list-late-register" style={{ color: '#FFF' }}>
                      Daftar Telat Pendaftaran
                      </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/list-gallery">
                    <NavLink to="/list-gallery" style={{ color: '#FFF' }}>
                      Daftar Galeri
                      </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/subscriber">
                    <NavLink to="/subscriber" style={{ color: '#FFF' }}>
                      Subscriber
                      </NavLink>
                  </Menu.Item>
                </SubMenu>
                <Menu.Item key="/beasiswa" to="/beasiswa" icon={<AccountBookOutlined />}>
                  <NavLink to="/beasiswa">
                    Beasiswa
                  </NavLink>
                </Menu.Item>
                <SubMenu key="sub3" icon={<CommentOutlined />} title="Life Sharing">
                  <Menu.Item key="/life-sharing-event">
                    <NavLink to="/life-sharing-event" style={{ color: '#FFF' }}>
                      Daftar Acara
                      </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/life-sharing-gallery">
                    <NavLink to="/life-sharing-gallery" style={{ color: '#FFF' }}>
                      Daftar Galeri
                      </NavLink>
                  </Menu.Item>
                </SubMenu>
                <SubMenu key="sub4" icon={<FileTextOutlined />} title="Master">
                  <Menu.Item key="/negara">
                    <NavLink to="/negara" style={{ color: '#FFF' }}>
                      Negara
                      </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/provinsi">
                    <NavLink to="/provinsi" style={{ color: '#FFF' }}>
                      Provinsi
                      </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/kota">
                    <NavLink to="/kota" style={{ color: '#FFF' }}>
                      Kota
                      </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/mata-uang">
                    <NavLink to="/mata-uang" style={{ color: '#FFF' }}>
                      Mata Uang
                      </NavLink>
                  </Menu.Item>
                </SubMenu>
                <SubMenu key="sub5" icon={<UserOutlined />} title="Kelola Admin">
                  {
                    userRole==='Superadmin' &&
                    <Menu.Item key="/admin">
                      <NavLink to="/admin" style={{ color: '#FFF' }}>
                        Admin
                      </NavLink>
                    </Menu.Item>
                  }
                  <Menu.Item key="/admin-univ">
                    <NavLink to="/admin-univ" style={{ color: '#FFF' }}>
                      Admin Universitas
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/approval-admin-univ">
                    <NavLink to="/approval-admin-univ" style={{ color: '#FFF' }}>
                      Persetujuan Admin Universitas
                    </NavLink>
                  </Menu.Item>
                  {
                    userRole==='Superadmin' &&
                    <Menu.Item key="/logging">
                      <NavLink to="/logging" style={{ color: '#FFF' }}>
                        Logging
                      </NavLink>
                    </Menu.Item>
                  }
                </SubMenu>
                <SubMenu key="sub6" icon={<SettingOutlined />} title="Pengaturan Umum">
                  <Menu.Item key="/homepage">
                    <NavLink to="/homepage" style={{ color: '#FFF' }}>
                      Homepage
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/galeri-impian">
                    <NavLink to="/galeri-impian" style={{ color: '#FFF' }}>
                      Galeri Impian Anak - Anak Kami
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/tampilan-kerjasama">
                    <NavLink to="/tampilan-kerjasama" style={{ color: '#FFF' }}>
                      Tampilan Kerjasama
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/life-sharing">
                    <NavLink to="/life-sharing" style={{ color: '#FFF' }}>
                      Life Sharing
                    </NavLink>
                  </Menu.Item>
                  {/* <Menu.Item key="/update-siswa">
                    <NavLink to="/update-siswa" style={{ color: '#FFF' }}>
                      Update Siswa
                      </NavLink>
                  </Menu.Item> */}
                  <Menu.Item key="/header-footer">
                    <NavLink to="/header-footer" style={{ color: '#FFF' }}>
                      Header & Footer
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/faq">
                    <NavLink to="/faq" style={{ color: '#FFF' }}>
                      FAQ
                    </NavLink>
                  </Menu.Item>
                </SubMenu>
                
                {/* <Menu.Item key="/export" to="/export" icon={<ExportOutlined />}>
                  <NavLink to="/export">
                    Export
                  </NavLink>
                </Menu.Item> */}
              </Menu>
            ) : (
              // Menu Role Admin Universitas
              <Menu
                theme="dark"
                // defaultSelectedKeys={"/profile-universitas"}
                mode="horizontal"
                defaultSelectedKeys={[href]}
                // selectedKeys={['/'+href]}
              >
                <Menu.Item key="/profile-universitas" icon={<HomeFilled />}>
                  <NavLink to="/profile-universitas">
                    Profil Universitas
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="/daftar-acara-universitas" icon={<FileTextFilled />}>
                  <NavLink to="/daftar-acara-universitas">
                    Daftar Acara
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="/daftar-galeri" icon={<FileImageFilled />}>
                  <NavLink to="/daftar-galeri">
                    Daftar Galeri
                  </NavLink>
                </Menu.Item>
              </Menu>
            )
          }
        </>
        
      ) : (
      <Sider className="navbarAdmin" style={{ height: '100vh', position: 'fixed', left: 0 }} collapsible collapsed={collapsed} onCollapse={onCollapse}>
        <div className="logo">
          <img src={logoUrl} alt="logoHeader" className='logoHeader' />
          {collapsed === false
          ? <span className="textLogo">Bursa Informasi Pendidikan Tinggi</span>
          : <></>}
        </div>
          {adminLyl === 'loyola' ? (
              // Menu Role Admin
              <Menu
                theme="dark"
                // defaultSelectedKeys={"/dashboard"}
                mode="inline"
                defaultSelectedKeys={[href]}
                // defaultOpenKeys={['sub1']}
                // selectedKeys={['/'+href]}
              >
                <Menu.Item key="/dashboard" icon={<AppstoreFilled />}>
                  <NavLink to="/dashboard">
                    Dashboard
                  </NavLink>
                </Menu.Item>
                <SubMenu key="sub2" icon={<BankOutlined />} title="Universitas">
                  <Menu.Item key="/daftar-persetujuan-konten">
                    <NavLink to="/daftar-persetujuan-konten" style={{ color: '#FFF' }}>
                      Daftar Persetujuan Konten
                      </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/daftar-persetujuan-acara">
                    <NavLink to="/daftar-persetujuan-acara" style={{ color: '#FFF' }}>
                      Daftar Persetujuan Acara
                      </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/daftar-persetujuan-galeri">
                    <NavLink to="/daftar-persetujuan-galeri" style={{ color: '#FFF' }}>
                      Daftar Persetujuan Galeri
                      </NavLink>
                  </Menu.Item>
                  {/* <Menu.Item key="/beasiswa">
                      <NavLink to="/beasiswa" style={{ color: '#FFF' }}>
                        Beasiswa
                      </NavLink>
                    </Menu.Item> */}
                </SubMenu>
                <SubMenu key="sub1" icon={<IdcardOutlined />} title="BIDikTi Week">
                  <Menu.Item key="/manage-bidikti-week">
                    <NavLink to="/manage-bidikti-week" style={{ color: '#FFF' }}>
                      Kelola BIDikTi Week
                      </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/list-presentation">
                    <NavLink to="/list-presentation" style={{ color: '#FFF' }}>
                      Daftar Presentasi
                      </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/list-approval">
                    <NavLink to="/list-approval" style={{ color: '#FFF' }}>
                      Daftar Persetujuan
                      </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/list-late-register">
                    <NavLink to="/list-late-register" style={{ color: '#FFF' }}>
                      Daftar Telat Pendaftaran
                      </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/list-gallery">
                    <NavLink to="/list-gallery" style={{ color: '#FFF' }}>
                      Daftar Galeri
                      </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/subscriber">
                    <NavLink to="/subscriber" style={{ color: '#FFF' }}>
                      Subscriber
                      </NavLink>
                  </Menu.Item>
                </SubMenu>
                <Menu.Item key="/beasiswa" to="/beasiswa" icon={<AccountBookOutlined />}>
                  <NavLink to="/beasiswa">
                    Beasiswa
                  </NavLink>
                </Menu.Item>
                <SubMenu key="sub3" icon={<CommentOutlined />} title="Life Sharing">
                  <Menu.Item key="/life-sharing-event">
                    <NavLink to="/life-sharing-event" style={{ color: '#FFF' }}>
                      Daftar Acara
                      </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/life-sharing-gallery">
                    <NavLink to="/life-sharing-gallery" style={{ color: '#FFF' }}>
                      Daftar Galeri
                      </NavLink>
                  </Menu.Item>
                </SubMenu>
                <SubMenu key="sub4" icon={<FileTextOutlined />} title="Master">
                  <Menu.Item key="/negara">
                    <NavLink to="/negara" style={{ color: '#FFF' }}>
                      Negara
                      </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/provinsi">
                    <NavLink to="/provinsi" style={{ color: '#FFF' }}>
                      Provinsi
                      </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/kota">
                    <NavLink to="/kota" style={{ color: '#FFF' }}>
                      Kota
                      </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/mata-uang">
                    <NavLink to="/mata-uang" style={{ color: '#FFF' }}>
                      Mata Uang
                      </NavLink>
                  </Menu.Item>
                </SubMenu>
                <SubMenu key="sub5" icon={<UserOutlined />} title="Kelola Admin">
                  {
                    userRole==='Superadmin' &&
                    <Menu.Item key="/admin">
                      <NavLink to="/admin" style={{ color: '#FFF' }}>
                        Admin
                      </NavLink>
                    </Menu.Item>
                  }
                  <Menu.Item key="/admin-univ">
                    <NavLink to="/admin-univ" style={{ color: '#FFF' }}>
                      Admin Universitas
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/approval-admin-univ">
                    <NavLink to="/approval-admin-univ" style={{ color: '#FFF' }}>
                      Persetujuan Admin Universitas
                    </NavLink>
                  </Menu.Item>
                  {
                    userRole==='Superadmin' &&
                    <Menu.Item key="/logging">
                      <NavLink to="/logging" style={{ color: '#FFF' }}>
                        Logging
                      </NavLink>
                    </Menu.Item>
                  }
                </SubMenu>
                <SubMenu key="sub6" icon={<SettingOutlined />} title="Pengaturan Umum">
                  <Menu.Item key="/homepage">
                    <NavLink to="/homepage" style={{ color: '#FFF' }}>
                      Homepage
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/galeri-impian">
                    <NavLink to="/galeri-impian" style={{ color: '#FFF' }}>
                      Galeri Impian Anak - Anak Kami
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/tampilan-kerjasama">
                    <NavLink to="/tampilan-kerjasama" style={{ color: '#FFF' }}>
                      Tampilan Kerjasama
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/life-sharing">
                    <NavLink to="/life-sharing" style={{ color: '#FFF' }}>
                      Life Sharing
                    </NavLink>
                  </Menu.Item>
                  {/* <Menu.Item key="/update-siswa">
                    <NavLink to="/update-siswa" style={{ color: '#FFF' }}>
                      Update Siswa
                      </NavLink>
                  </Menu.Item> */}
                  <Menu.Item key="/header-footer">
                    <NavLink to="/header-footer" style={{ color: '#FFF' }}>
                      Header & Footer
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/faq" style={{ marginBottom: '60px'}}>
                    <NavLink to="/faq" style={{ color: '#FFF' }}>
                      FAQ
                    </NavLink>
                  </Menu.Item>
                </SubMenu>
                
                {/* <Menu.Item key="/export" to="/export" icon={<ExportOutlined />}>
                  <NavLink to="/export">
                    Export
                  </NavLink>
                </Menu.Item> */}
              </Menu>
            ) : (
              // Menu Role Admin Universitas
              <Menu
                theme="dark"
                // defaultSelectedKeys={"/profile-universitas"}
                mode="inline"
                defaultSelectedKeys={[href]}
                // selectedKeys={['/'+href]}
              >
                <Menu.Item key="/profile-universitas" icon={<HomeFilled />}>
                  <NavLink to="/profile-universitas">
                    Profil Universitas
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="/daftar-acara-universitas" icon={<FileTextFilled />}>
                  <NavLink to="/daftar-acara-universitas">
                    Daftar Acara
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="/daftar-galeri" icon={<FileImageFilled />}>
                  <NavLink to="/daftar-galeri">
                    Daftar Galeri
                  </NavLink>
                </Menu.Item>
              </Menu>
            )
          }
        </Sider>
      )}
    </>
  )
};

export default withRouter(SideBarComponent);
