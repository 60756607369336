import React, {Component} from 'react'
import { Route, Redirect, HashRouter} from 'react-router-dom'
import SideBarComponent from './components/SideBar'
import Home from './module/Home/home'
import Login from './module/Login'
import ListApproval from './module/listApproval'
import ListApprovalDetail from './module/listApprovalDetail'
import HeaderComponent from './components/Header'
import ListPresentation from './module/listPresentation'
import ListLateRegister from "./module/ListLateRegister";
import ListPresentationDetail from "./module/listPresentationDetail";
import ListGallery from "./module/listGallery"
import ForgetPassword from "./module/ForgetPassword"
import ManageBidiktiWeek from "./module/manageBidiktiWeek"
import ManageBidiktiWeekDetail from "./module/manageBidiktiWeekDetail"
import ListFAQ from './module/FAQ'
import DetailFAQ from './module/FaqDetail'
import HeaderFooter from './module/HeaderFooter'
import ListUpdateSiswa from './module/UpdateSiswa'
import Admin from "./module/ManageAdmin/Admin"
import AdminDetail from "./module/ManageAdmin/AdminDetail";
import AdminEdit from "./module/ManageAdmin/AdminEdit";
import ApprovalAdminUniv from "./module/ManageAdmin/ApprovalAdminUniv";
import ApprovalAdminUnivDetail from "./module/ManageAdmin/ApprovalAdminUnivDetail";
import LifeSharingGallery from "./module/LifeSharing/lifeSharingGallery";
import LifeSharingGalleryDetail from "./module/LifeSharing/lifeSharingGalleryDetail"
import LifeSharingEvent from "./module/LifeSharing/lifeSharingEvent";
import LifeSharingEventDetail from "./module/LifeSharing/lifeSharingEventDetail"
import LifeSharing from './module/LifeSharing'
import ListKerjaSama from './module/KerjaSama'
import ListImpian from './module/ImpianAnak'
import ImpianDetail from './module/ImpianAnakDetail'
import HomePage from './module/Homepage'
import ListBeasiswa from './module/ListBeasiswa';
import ListBeasiswaDetail from './module/ListBeasiswaDetail';
import MasterKota from './module/MasterKota'
import MasterProvinsi from './module/MasterProvinsi'
import MasterNegara from './module/MasterNegara'
import MasterUang from './module/MasterUang'
import DaftarPersetujuanGaleri from './module/DaftarPersetujuanGaleri'
import DaftarPersetujuanKonten from './module/DaftarPersetujuanKonten'
import DaftarPersetujuanKontenDetail from './module/DaftarPersetujuanKontenDetail'
import DaftarPersetujuanAcara from './module/DaftarPersetujuanAcara'
import DaftarPersetujuanAcaraDetail from './module/DaftarPersetujuanAcaraDetail'
import ProfileUniversitas from './module/ProfileUniversitas';
import ProfileUniversitasDetail from './module/ProfileUniversitasDetail'
import ProfileUnivesitasEdit from './module/ProfileUniversitasEdit'
import AdminUnivDaftarAcara from "./module/AdminUnivDaftarAcara";
import AdminUnivDaftarAcaraDeatil from "./module/AdminUnivDaftarAcaraDetail";
import AdminUnivDaftarAcaraTambah from "./module/AdminUnivDaftarAcaraTambah";
import DaftarGaleri from './module/DaftarGaleri';
import DaftarGaleriDetail from "./module/DaftarGaleriDetail";
import Subscriber from './module/Subscriber';
import Export from './module/Export';
import AdminUniv from './module/ManageAdmin/AdminUniv'
import Logging from './module/Logging'

function PrivateRoute ({ ...props }) {
  if (
    sessionStorage.getItem("isLogin") &&
    sessionStorage.getItem('token') &&
    sessionStorage.getItem('name') &&
    sessionStorage.getItem("userId")
  )
    return <Route { ...props } />
  else
    sessionStorage.clear();
    return <Redirect to="/" />
}

export default class Routes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLogin: false,
      token: sessionStorage.getItem('token'),
      name: sessionStorage.getItem('name'),
      userId: sessionStorage.getItem("userId"),
      univId: sessionStorage.getItem('univId'),
      adminLyl: sessionStorage.getItem("adminLyl")
    }
  }

  componentDidMount() {
    if(sessionStorage.getItem('isLogin')) {
      this.setState({
        isLogin: true,
      })
    }else {
      this.setState({
        isLogin: false
      })
    }
  }

  onLogin = () => {
    this.setState({
      isLogin: true,
    })
  }

  logout = () => {
    this.setState({
      isLogin: false
    });
  };

  render() {
    const { isLogin } = this.state
    const token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : this.state.token
    const name = sessionStorage.getItem('name') ? sessionStorage.getItem('name') : this.state.name
    const userId = sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : this.state.userId
    const univId = sessionStorage.getItem('univId') ? sessionStorage.getItem('univId') : this.state.univId
    const adminLyl = sessionStorage.getItem('adminLyl') ? sessionStorage.getItem('adminLyl') : this.state.adminLyl
    return(
      <HashRouter>
        <React.Fragment>
            {isLogin ?
                !this.props.isMobile && 
                  <SideBarComponent isMobile={this.props.isMobile} {...this.props} />
              :
              <></>
            }
            {!isLogin ?
              <div style={{width: '100%'}}>
                <Route exact path="/" render={props => <Login onLogin={() => this.onLogin()} isMobile={this.props.isMobile}  {...props}/>} />
                <Route path="/ForgetPassword" render={ props => <ForgetPassword isMobile={this.props.isMobile} /> } />
                <PrivateRoute />
              </div>
              :
              <div style={{width: '100%', marginLeft: this.props.isMobile ? '0px' : '200px', overflow: 'auto'}}>
                
                {this.props.isMobile ?
                  <div style={{ background: '#001529'}}>
                    <HeaderComponent logout={this.logout} isMobile={this.props.isMobile} {...this.props} name={name}/>
                    <SideBarComponent isMobile={this.props.isMobile} {...this.props} />
                  </div>
                  :
                  <HeaderComponent isMobile={this.props.isMobile} logout={this.logout} {...this.props} name={name}/>
                }
                {
                  adminLyl === 'loyola' ?
                    (
                      // Role Admin
                      <>
                        <PrivateRoute path="/dashboard" render={ props => <Home {...props}  token={token} userId={userId} isMobile={this.props.isMobile} />}  />
                        <PrivateRoute path="/list-approval" render={ props => <ListApproval {...props} token={token} userId={userId} isMobile={this.props.isMobile} />}  />
                        <PrivateRoute path="/approval-detail/" render={props => <ListApprovalDetail {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/list-presentation" render={props => <ListPresentation {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/presentation-detail/" render={props => <ListPresentationDetail {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/list-late-register" render={props => <ListLateRegister {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/list-gallery" render={props => <ListGallery {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/manage-bidikti-week" render={props => <ManageBidiktiWeek {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/manage-bidikti-week-detail/" render={props => <ManageBidiktiWeekDetail {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/faq" render={props => <ListFAQ {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/faq-detail/" render={props => <DetailFAQ {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/header-footer" render={props => <HeaderFooter {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/update-siswa" render={props => <ListUpdateSiswa {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/admin" render={props => <Admin {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/admin-univ" render={props => <AdminUniv {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/logging" render={props => <Logging {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/admin-detail/" render={props => <AdminDetail {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/admin-edit/" render={props => <AdminEdit {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/approval-admin-univ" render={props => <ApprovalAdminUniv {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/approval-admin-univ-detail" render={props => <ApprovalAdminUnivDetail {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/life-sharing-gallery" render={props => <LifeSharingGallery {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/life-sharing-gallery-detail" render={props => <LifeSharingGalleryDetail {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/life-sharing-event" render={props => <LifeSharingEvent {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/life-sharing-event-detail" render={props => <LifeSharingEventDetail {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/life-sharing" render={props => <LifeSharing {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/tampilan-kerjasama" render={props => <ListKerjaSama {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/galeri-impian" render={props => <ListImpian {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/galeri-impian-detail/" render={props => <ImpianDetail {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/homepage" render={props => <HomePage {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/beasiswa" render={props => <ListBeasiswa {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/beasiswa-detail" render={props => <ListBeasiswaDetail {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/kota" render={props => <MasterKota {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/provinsi" render={props => <MasterProvinsi {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/negara" render={props => <MasterNegara {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/mata-uang" render={props => <MasterUang {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/daftar-persetujuan-galeri" render={props => <DaftarPersetujuanGaleri {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/daftar-persetujuan-konten/" render={props => <DaftarPersetujuanKonten {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/daftar-persetujuan-konten-detail/:univId/:status" render={props => <DaftarPersetujuanKontenDetail {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/daftar-persetujuan-acara" render={props => <DaftarPersetujuanAcara {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/daftar-persetujuan-acara-detail/" render={props => <DaftarPersetujuanAcaraDetail {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/subscriber" render={props => <Subscriber {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/export" render={props => <Export {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                      </>
                    ) :
                    (
                      // Role Admin Universitas
                      <>
                        <PrivateRoute path="/profile-universitas" render={props => <ProfileUniversitas {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/profile-universitas-detail/:dataRes/:status" render={props => <ProfileUniversitasDetail {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/profile-universitas-edit" render={props => <ProfileUnivesitasEdit {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/daftar-acara-universitas" render={props => <AdminUnivDaftarAcara {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/daftar-acara-universitas-detail/" render={props => <AdminUnivDaftarAcaraDeatil {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/daftar-acara-universitas-tambah" render={props => <AdminUnivDaftarAcaraTambah {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                        <PrivateRoute path="/daftar-galeri/" render={props => <DaftarGaleri {...props} token={token} userId={userId} isMobile={this.props.isMobile} univId={univId} />} />
                        <PrivateRoute path="/daftar-galeri-detail/" render={props => <DaftarGaleriDetail {...props} token={token} userId={userId} isMobile={this.props.isMobile} />} />
                      </>
                    )
                }
              </div>
            }
        </React.Fragment>
      </HashRouter>
    )
  }
}
