import types from '../constants'
import logging from '../api/logging'
import {notification} from 'antd'

const getLOVType = (token) => dispatch => {
  dispatch({
    type: types.GET_TYPE_LOGGING_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return logging.getLOVType(token).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_TYPE_LOGGING_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_TYPE_LOGGING_SUCCESS,
        payload: result
      })
    }
  })
}

const downloadLoging = (token, type, start, end) => dispatch => {
  dispatch({
    type: types.GET_DOWNLOAD_LOGGING_LOADING,
    payload: {
      status: 'loading',
      isLoading: true,
      error: '',
      data: []
    }
  })
  return logging.downloadLoging(token, type, start, end).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_DOWNLOAD_LOGGING_FAILED,
        payload: result
      })
      notification.error({
        placement: "bottomRight",
        message: "Error",
        description: 'Get File Download Error',
      });
    }else {
      notification.success({
        placement: "bottomRight",
        message: "Success",
        description: "Get File Download Success",
      });
      return dispatch({
        type: types.GET_DOWNLOAD_LOGGING_SUCCESS,
        payload: result
      })
    }
  })
}

export default {
  getLOVType,
  downloadLoging
}
  