const GET_DAFTAR_GALERI_LOADING = 'GET_DAFTAR_GALERI_LOADING'
const GET_DAFTAR_GALERI_SUCCESS = 'GET_DAFTAR_GALERI_SUCCESS'
const GET_DAFTAR_GALERI_FAILED = 'GET_DAFTAR_GALERI_FAILED'
const POST_UPLOAD_DAFTAR_GALERI_LOADING = 'POST_UPLOAD_DAFTAR_GALERI_LOADING'
const POST_UPLOAD_DAFTAR_GALERI_SUCCESS = 'POST_UPLOAD_DAFTAR_GALERI_SUCCESS'
const POST_UPLOAD_DAFTAR_GALERI_FAILED = 'POST_UPLOAD_DAFTAR_GALERI_FAILED'
const POST_DAFTAR_GALERI_LOADING = 'POST_DAFTAR_GALERI_LOADING'
const POST_DAFTAR_GALERI_SUCCESS = 'POST_DAFTAR_GALERI_SUCCESS'
const POST_DAFTAR_GALERI_FAILED = 'POST_DAFTAR_GALERI_FAILED'
const GET_DETAIL_DAFTAR_GALERI_LOADING = 'GET_DETAIL_DAFTAR_GALERI_LOADING'
const GET_DETAIL_DAFTAR_GALERI_SUCCESS = 'GET_DETAIL_DAFTAR_GALERI_SUCCESS'
const GET_DETAIL_DAFTAR_GALERI_FAILED = 'GET_DETAIL_DAFTAR_GALERI_FAILED'
const PUT_THUMBNAIL_DAFTAR_GALERI_LOADING = 'PUT_THUMBNAIL_DAFTAR_GALERI_LOADING'
const PUT_THUMBNAIL_DAFTAR_GALERI_SUCCESS = 'PUT_THUMBNAIL_DAFTAR_GALERI_SUCCESS'
const PUT_THUMBNAIL_DAFTAR_GALERI_FAILED = 'PUT_THUMBNAIL_DAFTAR_GALERI_FAILED'

export {
  GET_DAFTAR_GALERI_LOADING,
  GET_DAFTAR_GALERI_SUCCESS,
  GET_DAFTAR_GALERI_FAILED,
  POST_UPLOAD_DAFTAR_GALERI_LOADING,
  POST_UPLOAD_DAFTAR_GALERI_SUCCESS,
  POST_UPLOAD_DAFTAR_GALERI_FAILED,
  POST_DAFTAR_GALERI_LOADING,
  POST_DAFTAR_GALERI_SUCCESS,
  POST_DAFTAR_GALERI_FAILED,
  GET_DETAIL_DAFTAR_GALERI_LOADING,
  GET_DETAIL_DAFTAR_GALERI_SUCCESS,
  GET_DETAIL_DAFTAR_GALERI_FAILED,
  PUT_THUMBNAIL_DAFTAR_GALERI_LOADING,
  PUT_THUMBNAIL_DAFTAR_GALERI_SUCCESS,
  PUT_THUMBNAIL_DAFTAR_GALERI_FAILED,
}
