import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../global/global.css'
import daftarAcara from '../../actions/daftarAcara'
import {
   Card,
   Row,
   Col,
   Breadcrumb,
   Typography,
} from 'antd'
import { UISearch, UItable } from '../../components'

const { Text } = Typography

class DaftarPersetujuanAcara extends Component {
   constructor(props) {
      super(props);
      this.state = {
         initial: '',
         selectedRowKeys: [],
         orderBy: '',
         size: 10,
         page: 1,
         search: '',
      }
   }

   componentDidMount() {
      const { orderBy, size, page, search } = this.state
      this.props.handleDataListEventUniversity(this.props.token, orderBy, size, page, search)
   }

   handleRedirectDetail = (id, status) => {
      this.props.history.push({
         pathname: `/daftar-persetujuan-acara-detail/${id}`,
         state: {
            status
         }
      })
   }

   handleTableChange = (page, filter, sorter) => {
      const { size, search } = this.state

      let sorterField = "";
      if (sorter.field === "univName") {
         sorterField = "u.univ_name";
      } else if (sorter.field === "eventName") {
         sorterField = "univ_event_name";
      } else if (sorter.field === "univDate") {
         sorterField = "univ_event_date";
      } else if (sorter.field === "univStatus") {
         sorterField = "univ_event_approval";
      }

      let orderBy = "";
      if (sorter.order === "ascend") {
         orderBy = `${sorterField},asc`;
      } else if (sorter.order === "descend") {
         orderBy = `${sorterField},desc`;
      }

      this.setState({
         page: page.current,
         orderBy: orderBy
      }, () => {
         this.props.handleDataListEventUniversity(this.props.token, orderBy, size, page.current, search)
      })
   }

   handleSearch = key => {
      const { orderBy, size } = this.state
      this.setState({
         search: key,
         page: 1
      })
      this.props.handleDataListEventUniversity(this.props.token, orderBy, size, 1, key)
   }

   render() {
      const columnsTable = [
         {
            title: 'Universitas',
            dataIndex: 'univName',
            key: 'univName',
            sorter: true,
            render: univName => <Text style={{ color: '#EF712B', cursor: 'pointer', fontWeight: 600 }}>{univName}</Text>,
            onCell: record => {
               return {
                  onClick: () => {
                     this.handleRedirectDetail(record.univEventId, record.univStatus)
                  }
               }
            }
         },
         {
            title: 'Nama Acara',
            dataIndex: 'eventName',
            key: 'eventName',
            sorter: true,
         },
         {
            title: 'Tanggal',
            dataIndex: 'univDate',
            key: 'univDate',
            sorter: true,
         },
         {
            title: 'Status',
            dataIndex: 'univStatus',
            key: 'univStatus',
            sorter: true,
            render: univStatus => {
               return (
                  <Text style={
                     univStatus === 'New' ? { color: '#EF712B', fontWeight: 600 } :
                        (univStatus === 'On Approval' ? { color: '#22296c', fontWeight: 600 } :
                           (univStatus === 'Approved' ? { color: '#6bc733', fontWeight: 600 } :
                              { color: '#e23530', fontWeight: 600 }))
                  }>{univStatus}</Text>
               )
            },
         },
      ]
      const { dataEventUniversity } = this.props
      const { page } = this.state
      return (
         <div className='root'>
            <Breadcrumb className="breadcrumb_style">
               <Breadcrumb.Item>
                  <span>Universitas</span>
               </Breadcrumb.Item>
               <Breadcrumb.Item className='pathNow_style'>
                  Daftar Persetujuan Acara
          </Breadcrumb.Item>
            </Breadcrumb>
            <Card className='bodyCard_style'>
               <Row>
                  <Col span={8}>
                     <UISearch placeholder='Search' handleSearch={key => this.handleSearch(key)} />
                  </Col>
               </Row>
               <Card bordered={false} loading={dataEventUniversity.loading} style={{ minHeight: '300px' }}>
                  <UItable
                     dataTable={dataEventUniversity.data}
                     columns={columnsTable}
                     withRows={false}
                     isMobile={this.props.isMobile}
                     loading={dataEventUniversity.status}
                     page={page}
                     totalData={dataEventUniversity.totalData}
                     handleTableChange={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter)}
                  />
               </Card>
            </Card>
         </div>
      )
   }
}

const mapDispatchToProps = dispatch => {
   return {
      handleDataListEventUniversity: (token, sort, size, page, search) => dispatch(daftarAcara.getDataListEventUniversity(token, sort, size, page, search))
   }
}

const mapStateToProps = (state) => {
   return {
      dataEventUniversity: state.listEventUniversity
   }
};

export default connect(mapStateToProps, mapDispatchToProps)(DaftarPersetujuanAcara)
