import React, { useState } from "react";

import ImagePreviewComponent from '../../components/imagePreview'
import { Select, Row, Col, Input, InputNumber, Radio, Button, Popconfirm } from "antd";

const DetailContentCore = (props) => {
  const dataDetail = props.dataDetail;
  const [valueRadio, setRadio] = useState(dataDetail.isOnline);
  const [loyolaKouta, setLoyolaKouta] = useState(dataDetail.bwUnivLoyolaQuota);
  const [otherKouta, setOtherKouta] = useState(dataDetail.bwUnivOtherQuota);
  const [lovSchedule, setSchedule] = useState(dataDetail.bwScheduleId);
  const [lovSession, setSession] = useState(dataDetail.bwSessionId);
  const [urlRoom, setUrlName] = useState(dataDetail.bwUnivMediaJoinUrl);
  const [username, setUsername] = useState(dataDetail.bwUnivMediaJoinId);
  const [password, setPassword] = useState(dataDetail.bwUnivMediaJoinPassword);
  const [tempat, setTempat] = useState(dataDetail.bwUnivMedia);

  // const [visible, setVisible] = useState(false)

  // const showModal = () => {
  //   setVisible(true)
  // }

  // const handleBack = () => {
  //   setVisible(false);
  // };

  const defaultSchedule = `${dataDetail.bwScheduleDay}, ${dataDetail.bwScheduleDate}`;

  const handleRadioChange = (val) => {
    setRadio(val.target.value);
  };

  const handleScheduleChange = (val) => {
    props.handleScheduleChange(val);
    setSchedule(val);
    setSession(null);
  };

  const handleScheduleSession = (val) => {
    setSession(val);
  };

  const handleReset = () => {
    setRadio(null);
    setLoyolaKouta(0);
    setOtherKouta(0);
    setSchedule(null);
    setSession(null);
    setUrlName(null);
    setUsername(null);
    setPassword(null);
    setTempat(null);
  };

  const handleFinish = () => {
    const finishBody = {
      valueRadio: valueRadio,
      schedule: lovSchedule,
      session: lovSession,
      loyolaKouta: loyolaKouta,
      otherKouta: otherKouta,
      tempat: tempat,
      urlRoom: urlRoom,
      username: username,
      password: password,
    };
    props.handleFinish(finishBody);
  };

  return (
    <Row gutter={[10, 10]}>
      <Col span={props.isMobile ? 12 :4}>
        <ImagePreviewComponent
          styles={{ width: props.isMobile ? '100%' : "90%" }}
          isMobile={props.isMobile}
          uri={dataDetail.logoPath}
        />
        {/* <img alt="logo" width="90%" src={dataDetail.logoPath} /> */}
      </Col>
      {props.isMobile &&
        <Col span={12}>
        </Col>
      }
      <Col span={props.isMobile ? 12 : 10}>
        <Col span={23}>
          <span className="header_text_detail">Nama Institusi</span>
        </Col>
        <Col span={23}>
          <span className="text_detail_institusi">
            {dataDetail.univRegName ? dataDetail.univRegName : "-"}
          </span>
        </Col>
        <Col span={24}>
          <span className="header_text_detail">Nomor Telepon Institusi</span>
        </Col>
        <Col span={24}>
          <span className="data_text_detail">
            {dataDetail.univRegPhone ? dataDetail.univRegPhone : "-"}
          </span>
        </Col>
        <Col span={24}>
          <span className="header_text_detail">Alamat Institusi</span>
        </Col>
        <Col span={23}>
          <span className="data_text_detail">
            {dataDetail.univRegAddress ? dataDetail.univRegAddress : "-"}
          </span>
        </Col>
        <Col span={24}>
          <span className="header_text_detail">Nama PIC Institusi</span>
        </Col>
        <Col span={24}>
          <span className="data_text_detail">
            {dataDetail.bwUnivPicName ? dataDetail.bwUnivPicName : "-"}
          </span>
        </Col>
        <Col span={24}>
          <span className="header_text_detail">
            Nomor WhatsApp PIC Institusi
          </span>
        </Col>
        <Col span={24}>
          <span className="data_text_detail">
            {dataDetail.bwUnivPicWa ? dataDetail.bwUnivPicWa : "-"}
          </span>
        </Col>
        <Col span={24}>
          <span className="header_text_detail">Nama Narasumber</span>
        </Col>
        <Col span={24}>
          <span className="data_text_detail">
            {dataDetail.bwUnivSpeaker ? dataDetail.bwUnivSpeaker : "-"}
          </span>
        </Col>
        <Col span={24}>
          <span className="header_text_detail">Deskripsi Narasumber</span>
        </Col>
        <Col span={24}>
          <span className="data_text_detail">
            {dataDetail.bwUnivSpeakerDesc ? dataDetail.bwUnivSpeakerDesc : "-"}
          </span>
        </Col>
      </Col>
      <Col span={props.isMobile ? 12 : 10}>
        <Row gutter={[10, 15]}>
          <Col span={24}>
            <span className="header_text_detail">Hari/Tanggal Presentasi</span>
          </Col>
          {props.from === "approval" ? (
            <>
              <Col span={22}>
                <Select
                  className="select"
                  placeholder="Select Day"
                  defaultValue={defaultSchedule}
                  // value={lovSchedule}
                  onChange={handleScheduleChange}
                >
                  {props.dataLOVSchedule ? (
                    props.dataLOVSchedule.map((item, index) => (
                      <Select.Option
                        key={item.id + "schedule" + index}
                        value={item.id}
                      >
                        {item.name}
                      </Select.Option>
                    ))
                  ) : (
                      <></>
                    )}
                </Select>
              </Col>
              <Col span={1}>
                <span className="mandatoryLarge">&#8727;</span>
              </Col>
            </>
          ) : (
              <Col span={22}>
                <span className="data_text_detail">
                  {dataDetail.bwScheduleDay}, {dataDetail.bwScheduleDate}
                </span>
              </Col>
            )}
          <Col span={24}>
            <span className="header_text_detail">Sesi</span>
          </Col>
          {props.from === "approval" ? (
            <>
              <Col span={22}>
                <Select
                  className="select"
                  placeholder="Select Session"
                  value={lovSession}
                  onChange={handleScheduleSession}
                >
                  {props.dataLOVSession ? (
                    props.dataLOVSession.map((item, index) => (
                      <Select.Option
                        key={item.id + "session" + index}
                        value={item.id}
                      >
                        {item.name}
                      </Select.Option>
                    ))
                  ) : (
                      <></>
                    )}
                </Select>
              </Col>
              <Col span={1}>
                <span className="mandatoryLarge">&#8727;</span>
              </Col>
            </>
          ) : (
              <Col span={22}>
                <span className="data_text_detail">
                  {dataDetail.bwSessionName}
                </span>
              </Col>
            )}
          <Col span={24}>
            <span className="header_text_detail">Kuota</span>
          </Col>
          <Col span={24}>
            {props.from === "approval" ? (
              <Row gutter={[10, 8]}>
                <Col span={10}>
                  <span className="data_text_detail">Loyola</span>
                </Col>
                <Col span={12}>
                  <InputNumber
                    value={loyolaKouta}
                    min={0}
                    maxLength={32}
                    onChange={(val) => setLoyolaKouta(val)}
                    className="input_style"
                  />
                </Col>
                <Col span={1}>
                  <span className="mandatoryLarge">&#8727;</span>
                </Col>
                <Col span={10}>
                  <span className="data_text_detail">Umum</span>
                </Col>
                <Col span={12}>
                  <InputNumber
                    value={otherKouta}
                    min={0}
                    maxLength={32}
                    onChange={(val) => setOtherKouta(val)}
                    className="input_style"
                  />
                </Col>
                <Col span={1}>
                  <span className="mandatoryLarge">&#8727;</span>
                </Col>
              </Row>
            ) : (
                <Row gutter={[0, 8]}>
                  <Col span={10}>
                    <span className="data_text_detail">Loyola</span>
                  </Col>
                  <Col style={{ textAlign: "right" }} span={10}>
                    <span className="text_detail_institusi">
                      {dataDetail.sisaQuotaLoyola}
                    </span>
                  &nbsp;
                  <span className="header_text_detail">/</span>&nbsp;
                  <span className="header_text_detail">
                      {dataDetail.bwUnivLoyolaQuota}
                    </span>
                  </Col>
                  <Col span={10}>
                    <span className="data_text_detail">Umum</span>
                  </Col>
                  <Col style={{ textAlign: "right" }} span={10}>
                    <span className="text_detail_institusi">
                      {dataDetail.sisaQuotaUmum}
                    </span>
                  &nbsp;
                  <span className="header_text_detail">/</span>&nbsp;
                  <span className="header_text_detail">
                      {dataDetail.bwUnivOtherQuota}
                    </span>
                  </Col>
                  <Col span={20} style={{ textAlign: "right" }}>
                    <Button
                      onClick={() => props.handleOpenModalInfo()}
                      className="updateButton"
                    >
                      Info
                  </Button>
                  </Col>
                </Row>
              )}
          </Col>
          <Col span={24}>
            <span className="header_text_detail">Setting Presentasi</span>
          </Col>
          <Col span={22}>
            {props.from === "approval" ? (
              <Radio.Group
                onChange={(val) => handleRadioChange(val)}
                value={valueRadio}
              >
                <Radio value={true}>Online</Radio>
                <Radio value={false}>Offline</Radio>
              </Radio.Group>
            ) : (
                dataDetail.isOnline
                  ?
                  <span className="data_text_detail">Online</span>
                  :
                  <span className="data_text_detail">Offline</span>
              )}
          </Col>
          {props.from === "presentation" ? (
            <Col span={24}>
              <Row gutter={[0, 10]}>
                <Col span={24}>
                  <span className="header_text_detail">Media</span>
                </Col>
                <Col span={24}>
                  <span className="data_text_detail">
                    {dataDetail.bwUnivMedia ? dataDetail.bwUnivMedia : "-"}
                  </span>
                </Col>
                <Col span={24}>
                  <span className="data_text_detail">
                    {dataDetail.bwUnivMediaJoinUrl
                      ? dataDetail.bwUnivMediaJoinUrl
                      : "-"}
                  </span>
                </Col>
                <Col span={24}>
                  <Row gutter={[0, 8]}>
                    <Col span={props.isMobile ? 24 : 8}>Username</Col>
                    <Col span={props.isMobile ? 24 : 16}>
                      <span className="data_text_detail">
                        {dataDetail.bwUnivMediaJoinId
                          ? dataDetail.bwUnivMediaJoinId
                          : "-"}
                      </span>
                    </Col>
                    <Col span={props.isMobile ? 24 : 8}>password</Col>
                    <Col span={props.isMobile ? 24 : 16}>
                      <span className="data_text_detail">
                        {dataDetail.bwUnivMediaJoinPassword
                          ? dataDetail.bwUnivMediaJoinPassword
                          : "-"}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          ) : valueRadio === true ? (
            <Row gutter={[10, 10]}>
              <Col span={24}>
                <span className="header_text_detail">Media</span>
              </Col>
              <Col span={22}>
                <Input
                  maxLength={150}
                  value={tempat}
                  onChange={(val) => setTempat(val.target.value)}
                  placeholder="Nama Aplikasi"
                  className="input_style"
                  size="large"
                />
              </Col>
              <Col span={1}>
                <span className="mandatoryLarge">&#8727;</span>
              </Col>
              <Col span={22}>
                <Input
                  maxLength={256}
                  value={urlRoom}
                  onChange={(val) => setUrlName(val.target.value)}
                  placeholder="URL Room"
                  className="input_style"
                  size="large"
                />
              </Col>
              <Col span={1}>
                <span className="mandatoryLarge">&#8727;</span>
              </Col>
              <Col span={22}>
                <Input
                  maxLength={256}
                  value={username}
                  onChange={(val) => setUsername(val.target.value)}
                  placeholder="Username"
                  className="input_style"
                  size="large"
                />
              </Col>
              <Col span={22}>
                <Input
                  maxLength={256}
                  value={password}
                  onChange={(val) => setPassword(val.target.value)}
                  placeholder="Password"
                  className="input_style"
                  size="large"
                />
              </Col>
            </Row>
          ) : (
                <>
                  <Col span={24}>
                    <span className="header_text_detail">Tempat</span>
                  </Col>
                  <Col span={22}>
                    <Input
                      maxLength={150}
                      value={tempat}
                      onChange={(val) => setTempat(val.target.value)}
                      placeholder="Nama Tempat"
                      className="input_style"
                      size="large"
                    />
                  </Col>
                  <Col span={1}>
                    <span className="mandatoryLarge">&#8727;</span>
                  </Col>
                </>
              )}
        </Row>
      </Col>
      {props.from === "approval" ? (
        <Col span={23} style={{ textAlign: "right" }}>
          <Button onClick={handleReset} type="link" className="resetButton">
            Reset
          </Button>
          <Button onClick={handleFinish} className="updateButton">
            Update
          </Button>
        </Col>
      ) : (
          <Col span={22} style={{ textAlign: "right" }}>
            <Popconfirm
              title="Anda yakin akan membagikan Email ke Participant?"
              onConfirm={() => props.handleShare()}
              okText="Ya"
              cancelText="Tidak"
            >
              <Button
                className="updateButton"
                loading={props.loadingPost}
              >
                Share
              </Button>
            </Popconfirm>
          </Col>
        )}
    </Row>
  );
};

export const UIContentDetail = DetailContentCore;
