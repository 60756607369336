import types from '../constants'
import profileUniversitas from '../api/profileUniversitas';

const getProfileUniversitas = (token, univId, param) => dispatch => {
  dispatch({
    type: types.GET_PROFILE_UNIVERSITAS_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return profileUniversitas.getProfileUniversitas(token, univId, param).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.GET_PROFILE_UNIVERSITAS_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_PROFILE_UNIVERSITAS_SUCCESS,
        payload: result
      })
    }
  })
}

const getDetailProfileUniversitas = (token, univId, dataRes) => dispatch => {
  dispatch({
    type: types.GET_DETAIL_PROFILE_UNIVERSITAS_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: {}
    }
  })
  return profileUniversitas.getDetailProfileUniversitas(token, univId, dataRes).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.GET_DETAIL_PROFILE_UNIVERSITAS_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_DETAIL_PROFILE_UNIVERSITAS_SUCCESS,
        payload: result
      })
    }
  })
}

const getJalurMasuk = (token, univId, param) => dispatch => {
  dispatch({
    type: types.GET_PROFILE_UNIVERSITAS_JALUR_MASUK_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return profileUniversitas.getJalurMasuk(token, univId, param).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.GET_PROFILE_UNIVERSITAS_JALUR_MASUK_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_PROFILE_UNIVERSITAS_JALUR_MASUK_SUCCESS,
        payload: result
      })
    }
  })
}

const getDetailJalurMasuk = (token, univEntId, param) => dispatch => {
  dispatch({
    type: types.GET_PROFILE_UNIVERSITAS_DETAIL_JALUR_MASUK_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return profileUniversitas.getDetailJalurMasuk(token, univEntId, param).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.GET_PROFILE_UNIVERSITAS_DETAIL_JALUR_MASUK_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_PROFILE_UNIVERSITAS_DETAIL_JALUR_MASUK_SUCCESS,
        payload: result
      })
    }
  })
}

const postReplaceImageLogoBannerHumas = (token, formData) => dispatch => {
  dispatch({
    type: types.POST_LOGO_PROFILE_UNIVERSITAS_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return profileUniversitas.postReplaceImageLogoBannerHumas(token, formData).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.POST_LOGO_PROFILE_UNIVERSITAS_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.POST_LOGO_PROFILE_UNIVERSITAS_SUCCESS,
        payload: result
      })
    }
  })
};

const postUploadLogo = (token, formData) => dispatch => {
  dispatch({
    type: types.POST_LOGO_PROFILE_UNIVERSITAS_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return profileUniversitas.postUploadLogo(token, formData).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.POST_LOGO_PROFILE_UNIVERSITAS_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.POST_LOGO_PROFILE_UNIVERSITAS_SUCCESS,
        payload: result
      })
    }
  })
};

const postUploadBanner = (token, formData) => dispatch => {
  dispatch({
    type: types.POST_BANNER_PROFILE_UNIVERSITAS_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return profileUniversitas.postUploadBanner(token, formData).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.POST_BANNER_PROFILE_UNIVERSITAS_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.POST_BANNER_PROFILE_UNIVERSITAS_SUCCESS,
        payload: result
      })
    }
  })
};

const postUploadHumas = (token, formData) => dispatch => {
  dispatch({
    type: types.POST_HUMAS_PROFILE_UNIVERSITAS_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return profileUniversitas.postUploadHumas(token, formData).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.POST_HUMAS_PROFILE_UNIVERSITAS_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.POST_HUMAS_PROFILE_UNIVERSITAS_SUCCESS,
        payload: result
      })
    }
  })
};

const postUploadForm = (token, formData) => dispatch => {
  dispatch({
    type: types.POST_FORM_PROFILE_UNIVERSITAS_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return profileUniversitas.postUploadForm(token, formData).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.POST_FORM_PROFILE_UNIVERSITAS_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.POST_FORM_PROFILE_UNIVERSITAS_SUCCESS,
        payload: result
      })
    }
  })
};

const postJalurMasuk = (token, body) => dispatch => {
  dispatch({
    type: types.POST_JALUR_MASUK_PROFILE_UNIVERSITAS_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return profileUniversitas.postJalurMasuk(token, body).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.POST_JALUR_MASUK_PROFILE_UNIVERSITAS_FAILED,
        payload: result
      })
    } else {
      return dispatch({
        type: types.POST_JALUR_MASUK_PROFILE_UNIVERSITAS_SUCCESS,
        payload: result
      })
    }
  })
};

const putEditProfileUniversitas = (token, univId, body) => dispatch => {
  dispatch({
    type: types.PUT_EDIT_PROFILE_UNIVERSITAS_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return profileUniversitas.putEditProfileUniversitas(token, univId, body).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.PUT_EDIT_PROFILE_UNIVERSITAS_FAILED,
        payload: result
      })
    } else {
      return dispatch({
        type: types.PUT_EDIT_PROFILE_UNIVERSITAS_SUCCESS,
        payload: result
      })
    }
  })
};

export default {
  getProfileUniversitas,
  getDetailProfileUniversitas,
  getJalurMasuk,
  getDetailJalurMasuk,
  postReplaceImageLogoBannerHumas,
  postUploadLogo,
  postUploadBanner,
  postUploadHumas,
  postUploadForm,
  postJalurMasuk,
  putEditProfileUniversitas,
}
