import types from "../constants";
import daftarPersetujuanGaleri from "../api/daftarPersetujuanGaleri";

const getData = (token, param) => dispatch => {
   dispatch({
      type: types.GET_DAFTAR_PERSETUJUAN_GALERI_LOADING,
      payload: {
         data: [],
         totalData: 0,
         status: "loading",
         error: ""
      }
   })
   return daftarPersetujuanGaleri.getData(token, param).then(result => {
      if (result.status === "error") {
         return dispatch({
            type: types.GET_DAFTAR_PERSETUJUAN_GALERI_FAILED,
            payload: result
         });
      } else {
         return dispatch({
            type: types.GET_DAFTAR_PERSETUJUAN_GALERI_SUCCESS,
            payload: result
         });
      }
   })
}

const approveDaftarPersetujuanGaleri = (token, body, param) => dispatch => {
   dispatch({
      type: types.APPROVE_DAFTAR_PERSETUJUAN_GALERI_LOADING,
      payload: {
         data: [],
         totalData: 0,
         status: "loading",
         error: ""
      }
   })
   return daftarPersetujuanGaleri.approveDaftarPersetujuanGaleri(token, body, param).then(result => {
      if (result.status === "error") {
         return dispatch({
            type: types.APPROVE_DAFTAR_PERSETUJUAN_GALERI_FAILED,
            payload: result
         });
      } else {
         return dispatch({
            type: types.APPROVE_DAFTAR_PERSETUJUAN_GALERI_SUCCESS,
            payload: result
         });
      }
   })
}

const rejectDaftarPersetujuanGaleri = (token, body, param) => dispatch => {
   dispatch({
      type: types.REJECT_DAFTAR_PERSETUJUAN_GALERI_LOADING,
      payload: {
         data: [],
         totalData: 0,
         status: "loading",
         error: ""
      }
   })
   return daftarPersetujuanGaleri.rejectDaftarPersetujuanGaleri(token, body, param).then(result => {
      if (result.status === "error") {
         return dispatch({
            type: types.REJECT_DAFTAR_PERSETUJUAN_GALERI_FAILED,
            payload: result
         });
      } else {
         return dispatch({
            type: types.REJECT_DAFTAR_PERSETUJUAN_GALERI_SUCCESS,
            payload: result
         });
      }
   })
}

const deleteDaftarPersetujuanGaleri = (token, body, param) => dispatch => {
   dispatch({
      type: types.DELETE_DAFTAR_PERSETUJUAN_GALERI_LOADING,
      payload: {
         data: [],
         totalData: 0,
         status: "loading",
         error: ""
      }
   })
   return daftarPersetujuanGaleri.deleteDaftarPersetujuanGaleri(token, body, param).then(result => {
      if (result.status === "error") {
         return dispatch({
            type: types.DELETE_DAFTAR_PERSETUJUAN_GALERI_FAILED,
            payload: result
         });
      } else {
         return dispatch({
            type: types.DELETE_DAFTAR_PERSETUJUAN_GALERI_SUCCESS,
            payload: result
         });
      }
   })
}

export default {
   getData,
   approveDaftarPersetujuanGaleri,
   rejectDaftarPersetujuanGaleri,
   deleteDaftarPersetujuanGaleri
}