import { get, put } from 'axios'
import config from '../services/config'
const { BASE_URL_LOGIN, TIMEOUT } = config

const getListBeasiswa = (token, sort, size, page, search) => {
  return get(`${BASE_URL_LOGIN}be/api/scholarshipListAdmin?sort=${sort}&pageSize=${size}&pageNo=${page - 1}&search=${search}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.items.map(res => {
      return {
        ...res
      }
    })
    return {
      data: normalizeData,
      totalData: response.data.totalItems,
      status: 'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const getDetailBeasiswa = (token, id) => {
  return get(`${BASE_URL_LOGIN}be/api/scholarshipDetailAdmin/${id}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status: 'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const approveBeasiswa = (token, id) => {
  return put(`${BASE_URL_LOGIN}be/api/approveScholarshipAdmin/${id}`, 
  {
    userId: sessionStorage.getItem("userId")
  },
  {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    timeout: TIMEOUT
  }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const rejectBeasiswa = (token, id) => {
  return put(`${BASE_URL_LOGIN}be/api/rejectScholarshipAdmin/${id}`, 
  {
    userId: sessionStorage.getItem("userId")
  },
  {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    timeout: TIMEOUT
  }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}


const deleteDataListBeasiswa = (token, body) => {
  // let url = `https://demo5436246.mockable.io/listApapun`
  let url = `${BASE_URL_LOGIN}be/api/deleteBeasiswaAdmin?scholarshipId=${body.id}&usersId=${body.userId}`
  return put(url, 
  {
    userId: sessionStorage.getItem("userId")
  },
  {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    timeout: TIMEOUT
  }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

export default {
  getListBeasiswa,
  getDetailBeasiswa,
  approveBeasiswa,
  rejectBeasiswa,
  deleteDataListBeasiswa
}
