const dotenv = require('dotenv');

dotenv.config();

const env = process.env.NODE_ENV

// console.log('env config: ', env)

const options = {
	development: {
		BASE_URL: "https://bidikti-admin.yayasanloyola.org/",
		BASE_URL_LOGIN: "https://yayasanloyola.org:9115/",
		BASE_URL_NOAUTH: "https://yayasanloyola.org:9112/",
		COPYRIGHT: '20200716',
		VERSION: '0.0.2',
		TIMEOUT: 30000,
	},
	production: {
		BASE_URL: "https://bidikti-admin.yayasanloyola.org/",
		BASE_URL_LOGIN: "https://yayasanloyola.org:9115/",
		BASE_URL_NOAUTH: "https://yayasanloyola.org:9112/",
		COPYRIGHT: '20200716',
		VERSION: '0.0.2',
		TIMEOUT: 30000,
	},
}
const config = options[env]

export default {
	...config
}
