import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../global/global.css'
import getManageBidiktiWeek from '../../actions/manageBidiktiWeek'
import {
  Card,
  notification,
  Button,
  Row,
  Col,
  Breadcrumb,
  Typography,
} from 'antd'
import { UISearch, UItable, UIModalCreateBW } from '../../components'

const { Text } = Typography

class manageBidiktiWeek extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initial: '',
      selectedRowKeys: [],
      orderBy: '',
      size: 10,
      page: 1,
      search: '',
      visibleCreate: false,
      activeButton: 'tema',
      bodyCreate: null,
      loading: false,
    }
  }

  componentDidMount() {
    const { orderBy, size, page, search } = this.state
    this.props.handleDataManageBidiktiWeek(this.props.token, orderBy, size, page, search)
  }

  handleRedirectDetail = id => {
    this.props.history.push(`/manage-bidikti-week-detail/${id}`)
  }

  handleTableChange = (page, filter, sorter) => {
    const { size, search } = this.state

    let sorterField = ""
    if (sorter.field === "bwThemeInd") {
      sorterField = "bw_theme_ind";
    } else if (sorter.field === "bwUnivRegisDuration") {
      sorterField = "bw_univ_reg_start";
    } else if (sorter.field === "bwDuration") {
      sorterField = "bw_duration_start";
    } else {
      sorterField = "is_deleted";
    }

    let orderBy = "";
    if (sorter.order === "ascend") {
      orderBy = `${sorterField},asc`;
    } else if (sorter.order === "descend") {
      orderBy = `${sorterField},desc`;
    }

    this.setState({
      page: page.current,
      orderBy: orderBy
    }, () => {
      this.props.handleDataManageBidiktiWeek(this.props.token, orderBy, size, page.current, search)
    })
  }

  handleSearch = key => {
    const { orderBy, size } = this.state
    this.setState({
      search: key,
      page: 1
    })
    this.props.handleDataManageBidiktiWeek(this.props.token, orderBy, size, 1, key)
  }

  openModal = () => {
    this.setState({
      visibleCreate: true
    })
  }

  changeButton = (name) => {
    if (name === 'tema') {
      this.setState({
        activeButton: 'tema'
      })
    } else if (name === 'sesi') {
      this.setState({
        activeButton: 'sesi'
      })
    } else {
      this.setState({
        activeButton: 'schedule'
      })
    }
  }

  closeModal = () => {
    this.setState({
      visibleCreate: false,
      bodyCreate: null,
      activeButton: 'tema'
    })
  }

  handleSaveBody = (value, name) => {
    let { bodyCreate } = this.state
    let tempDirection = ''
    if (name === 'tema') {
      tempDirection = 'schedule'
    } else {
      tempDirection = 'sesi'
    }

    if (bodyCreate !== null) {
      bodyCreate = {
        ...bodyCreate,
        ...value,
        createdBy: this.props.userId,
        lastModifiedBy: this.props.userId
      }
    } else {
      bodyCreate = {
        ...value
      }
    }
    this.setState({
      bodyCreate: bodyCreate,
      activeButton: tempDirection
    })
    if (name === 'sesi') {
      this.handleCreateBW(bodyCreate)
    }
  }

  handleCreateBW = body => {
    this.setState({
      loading: true
    })
    this.props.handleCreateBW(this.props.token, body).then(res => {
      if (res.payload.status !== 'error') {
        if (res.payload.data.data.Status === 'OK') {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Create BIDikTi Week Success',
          });
          this.setState({
            search: '',
            page: 1,
            orderBy: '',
            loading: false,
            visibleCreate: false,
            bodyCreate: null
          })
          this.props.handleDataManageBidiktiWeek(this.props.token, '', 10, 1, '')
        } else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Create BIDikTi Week Failed'
          });
          this.setState({
            loading: false,
          })
        }
      } else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: 'Create BIDikTi Week Failed'
        });
        this.setState({
          loading: false,
        })
      }
    })
  }

  render() {
    const columnsTable = [
      {
        title: 'Tema',
        dataIndex: 'bwThemeInd',
        key: 'bwThemeInd',
        sorter: true,
        render: bwThemeInd => <Text style={{ color: '#EF712B', cursor: 'pointer', fontWeight: 600 }}>{bwThemeInd}</Text>,
        onCell: record => {
          return {
            onClick: () => {
              this.handleRedirectDetail(record.bwId,)
            }
          }
        }
      },
      {
        title: 'Durasi Pendaftaran',
        dataIndex: 'bwUnivRegisDuration',
        key: 'bwUnivRegisDuration',
        sorter: true,
      },
      {
        title: 'Durasi Pelaksanaan',
        dataIndex: 'bwDuration',
        key: 'bwDuration',
        sorter: true,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        render: status => {
          if (status === 'Active') {
            return (
              <Text style={{ color: '#EF712B', cursor: 'pointer', fontWeight: 600 }}>{status}</Text>
            )
          } else {
            return (
              <Text style={{ color: '#11174C', cursor: 'pointer', fontWeight: 600 }}>{status}</Text>
            )
          }
        }
      },
    ]
    const { dataManageBidiktiWeek } = this.props
    const { page, visibleCreate, activeButton, loading } = this.state
    return (
      <div className='root'>
        <Breadcrumb className="breadcrumb_style">
          <Breadcrumb.Item>
            <span style={{ cursor: 'pointer' }} onClick={() => this.props.history.push(`/dashboard`)}>BIDikTi Week </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className='pathNow_style'>
            Kelola BIDikTi Week
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card className='bodyCard_style'>
          <Row gutter={[10, 16]} style={{ paddingBottom: 20 }}>
            <Col span={24}>
              <span className='text_detail_tema'>
                Riwayat Tema BIDikTi Week
              </span>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <UISearch placeholder='Search' handleSearch={key => this.handleSearch(key)} />
            </Col>
            <Col span={this.props.isMobile ? 12 : 6}></Col>
            <Col span={6} style={{ textAlign: 'right' }}>
              <Button onClick={() => this.openModal()} className='updateButton'>Daftar Tema BIDikTi Week</Button>
            </Col>
          </Row>
          <Card bordered={false} loading={dataManageBidiktiWeek.loading} style={{ minHeight: '300px' }}>
            <UItable
              // rowSelection={rowSelection}
              dataTable={dataManageBidiktiWeek.data}
              columns={columnsTable}
              withRows={false}
              isMobile={this.props.isMobile}
              loading={dataManageBidiktiWeek.status}
              page={page}
              totalData={dataManageBidiktiWeek.totalData}
              handleTableChange={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter)}
            />
          </Card>
        </Card>
        <UIModalCreateBW
          isMobile={this.props.isMobile}
          visible={visibleCreate}
          dataNaration={dataManageBidiktiWeek.dataNaration !== undefined ? dataManageBidiktiWeek.dataNaration : {}}
          changeButton={(name) => this.changeButton(name)}
          handleBack={() => this.closeModal()}
          saveBody={(body, name) => this.handleSaveBody(body, name)}
          activeButton={activeButton}
          token={this.props.token}
          loading={loading}
        />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleDataManageBidiktiWeek: (token, sort, size, page, search) => dispatch(getManageBidiktiWeek.getDataManageBidiktiWeek(token, sort, size, page, search)),
    handleCreateBW: (token, body) => dispatch(getManageBidiktiWeek.createBW(token, body))
  }
}

const mapStateToProps = (state) => {
  return {
    dataManageBidiktiWeek: state.manageBidiktiWeek,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(manageBidiktiWeek)
