const GET_UANG_LOADING = 'GET_UANG_LOADING';
const GET_UANG_SUCCESS = 'GET_UANG_SUCCESS';
const GET_UANG_FAILED = 'GET_UANG_FAILED';
const CREATE_UANG_LOADING = 'CREATE_UANG_LOADING';
const CREATE_UANG_SUCCESS = 'CREATE_UANG_SUCCESS';
const CREATE_UANG_FAILED = 'CREATE_UANG_FAILED';
const PUT_UANG_LOADING = 'PUT_UANG_LOADING';
const PUT_UANG_SUCCESS = 'PUT_UANG_SUCCESS';
const PUT_UANG_FAILED = 'PUT_UANG_FAILED';
const DELETE_UANG_LOADING = 'DELETE_UANG_LOADING';
const DELETE_UANG_SUCCESS = 'DELETE_UANG_SUCCESS';
const DELETE_UANG_FAILED = 'DELETE_UANG_FAILED';

export {
   GET_UANG_LOADING,
   GET_UANG_SUCCESS,
   GET_UANG_FAILED,
   CREATE_UANG_LOADING,
   CREATE_UANG_SUCCESS,
   CREATE_UANG_FAILED,
   PUT_UANG_LOADING,
   PUT_UANG_SUCCESS,
   PUT_UANG_FAILED,
   DELETE_UANG_LOADING,
   DELETE_UANG_SUCCESS,
   DELETE_UANG_FAILED
}