import { get } from "axios";
import config from "../services/config";

const { BASE_URL_LOGIN, TIMEOUT } = config;

const getDashboardListUniversitas = (token, id) => {
   return get(`${BASE_URL_LOGIN}be/api/dashboard/university?univCatId=${id}`,
      {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
         timeout: TIMEOUT
      }
   ).then(response => {
      const normalizedData = response.data.Data.map((res) => (
         { ...res }
      ))
      return {
         data: normalizedData[0],
         totalData: response.data.TotalData,
         status: "success",
         error: ""
      }
   }).catch(error => {
      return {
         data: [],
         totalData: 0,
         status: "error",
         error
      }
   })
}

export default {
   getDashboardListUniversitas
}