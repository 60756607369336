import types from '../constants'
import presentation from '../api/presentation'

const getDataPresentation = (token, sort, size, page, search) => dispatch => {
  dispatch({
    type: types.GET_PRESENTATION_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return presentation.getPresentation(token, sort, size, page, search).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_PRESENTATION_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_PRESENTATION_SUCCESS,
        payload: result
      })
    }
  })
}

const getDataDetailPresentation = (token, id) => dispatch => {
  dispatch({
    type: types.GET_PRESENTATION_DETAIL_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return presentation.getPresentationDetail(token, id).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_PRESENTATION_DETAIL_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_PRESENTATION_DETAIL_SUCCESS,
        payload: result
      })
    }
  })
}

const getDataParticipantListLoyola = (token, param) => dispatch => {
  dispatch({
    type: types.GET_PARTICIPANT_LIST_LOYOLA_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return presentation.getParticipantListLoyola(token, param).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_PARTICIPANT_LIST_LOYOLA_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_PARTICIPANT_LIST_LOYOLA_SUCCESS,
        payload: result
      })
    }
  })
}

const getDataParticipantListPublic = (token, param) => dispatch => {
  dispatch({
    type: types.GET_PARTICIPANT_LIST_PUBLIC_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return presentation.getParticipantListPublic(token, param).then( result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_PARTICIPANT_LIST_PUBLIC_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.GET_PARTICIPANT_LIST_PUBLIC_SUCCESS,
        payload: result
      })
    }
  })
}

const postSendEmail = (token, id) => dispatch => {
  dispatch({
    type: types.POST_SEND_EMAIL_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return presentation.postSendEmail(token, id).then( result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.POST_SEND_EMAIL_FAILED,
        payload: result
      })
    }else {
      return dispatch({
        type: types.POST_SEND_EMAIL_SUCCESS,
        payload: result
      })
    }
  })
}



export default {
  getDataPresentation,
  getDataDetailPresentation,
  getDataParticipantListLoyola,
  getDataParticipantListPublic,
  postSendEmail
}
