import React, {useState, useEffect} from 'react'
import {Row, Col, Modal, Card, Button, DatePicker, Input, TimePicker, Checkbox, Typography} from 'antd'
import { CloseOutlined} from "@ant-design/icons";
import '../../global/global.css'
import { UItable, UISearch } from '../index'
import moment from 'moment'

const { Text } = Typography

const ModalSessionSchedule = props => {
  const [schedule, setSchedule] = useState({bwScheduleDate: undefined, bwScheduleDay: ''})
  const [sesi, setSesi] = useState(props.dataEditSesi)
  useEffect(() => {
    setSesi(props.dataEditSesi)
  }, [props.dataEditSesi]);

  useEffect(() => {
    setSchedule({
      bwScheduleDate: undefined,
      bwScheduleDay: ''
    })
  }, [props.visibleSchedule])

  const format = 'dddd, YYYY-MM-DD'

  const formatTime = 'HH:mm'

  const disabledDateSchedule = date => {
    if ( date ) {
      const start = props.dataDetail.bwDurationStart && date.diff(moment(props.dataDetail.bwDurationStart), 'h') < 0
      const end = props.dataDetail.bwDurationFinish && date.diff(moment(props.dataDetail.bwDurationFinish), 'h') > 24
      return start + end;
    }
  }

  const handleCreate = (name) => {
    if (name === 'sesi') {
      props.handleCreate(name, sesi)
    }else {
      props.handleCreate(name, schedule)
    }
  }

  const handleDateChange = (date, dateString) => {
    let tempName = dateString.split(',')
    setSchedule({
      bwScheduleDay: tempName[0],
      bwScheduleDate: tempName[1]
    })
  }

  const handleSesi = (value, dateString, name) => {
    let temp = sesi
    if (name ==='name') {
      temp = {
        ...temp,
        bwSessionName: value.target.value
      }
    }else if (name === 'start') {
      const endTemp = `${moment(value, "HH:mm:ss").hours()}:${moment(value, "HH:mm:ss").minutes() + 1}`
      temp = {
        ...temp,
        bwSessionTimeStart: dateString !== "NaN:NaN" ? moment(dateString, "HH:mm:ss") : null,
        bwSessionTimeFinish: endTemp !== "NaN:NaN" ? moment(endTemp, "HH:mm:ss") : null
      }
    }else if (name === 'end') {
      temp = {
        ...temp,
        bwSessionTimeFinish: dateString !== "NaN:NaN" ? moment(dateString, "HH:mm:ss") : null
      }
    }else {
      temp = {
        ...temp,
        isWeekend: value.target.checked
      }
    }
    setSesi(temp)
  }

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
 }

  const disabledHours = () => {
    const hours = moment(sesi.bwSessionTimeStart, "HH:mm:ss").hours();
    return range(0, hours)
  }

  const disabledTime = (val) => {
    const temp = moment(sesi.bwSessionTimeStart, "HH:mm:ss").hours()
    if (val === temp ) {
      const time = moment(sesi.bwSessionTimeStart, "HH:mm:ss").minute();
      return range(0, time + 1)
    }
  }

  return(
    <>
      <Modal
        visible={props.visible}
        width={props.isMobile ? '100%' : "50%"}
        className={props.isMobile ? 'modalMobile' : 'modalImage'}
        bodyStyle={{padding: 0}}
        centered
        onCancel={() => props.handleBack()}
        footer={null}
        closable
        destroyOnClose={true}
        maskClosable
        style={{borderRadius: '25px'}}
        closeIcon={<CloseOutlined style={{ color: '#fff' }} />}
      >
        <>
          <Row className="modalHeadEdit">
            <Col span={24}>
              <h1 className="modalTitleEdit">{props.title}</h1>
              <div className='dividerGalery' />
            </Col>
          </Row>
          <Card bordered={false} style={{minHeight: '200px'}}>
            <Row>
              <Col span={12}>
                <UISearch width={'70%'} placeholder='Search' handleSearch={key => props.handleSearch(key)} />
              </Col>
              <Col span={12} style={{textAlign: 'right'}}>
                <Button disabled={props.buttonDisable} onClick={() => props.handleOpenAdd(props.from)} className='updateButton'>Tambah Data</Button>
              </Col>
            </Row>
              <UItable
                dataTable={props.dataTable}
                withRows={false}
                isMobile={props.isMobile}
                columns={props.columns}
                loading={props.loading}
                page={props.page}
                totalData={props.totalData}
                handleTableChange={props.handleTableChange}
              />
          </Card>
        </>
      </Modal>
      <Modal
        visible={props.visibleSchedule}
        width={props.isMobile ? '100%' : "50%"}
        className={props.isMobile ? 'modalMobile' : 'modalImage'}
        bodyStyle={{padding: 0}}
        centered
        onCancel={() => props.handleBack()}
        footer={null}
        closable
        destroyOnClose={true}
        maskClosable
        style={{borderRadius: '25px'}}
        closeIcon={<CloseOutlined style={{ color: '#fff' }} />}
      >
        <>
          <Row className="modalHeadEdit">
            <Col span={24}>
              <h1 className="modalTitleEdit">{props.title}</h1>
              <div className='dividerGalery' />
            </Col>
          </Row>
          <Card bordered={false} style={{minHeight: '200px'}}>
            <Row gutter={[0,24]}>
              <Col span={24}>
                <Text className='textSchedule'>Tanggal Presentasi</Text>
              </Col>
              <Col span={24}>
                <DatePicker
                  format={format}
                  disabledDate={date => disabledDateSchedule(date)}
                  onChange={(value, string) => handleDateChange(value, string)}
                  placeholder='Tanggal Presentasi'
                  style={{ width: '80%'}}
                />
              </Col>
              <Col span={24} style={{textAlign: 'right'}}>
                <Button
                  loading={props.loading}
                  onClick={() => handleCreate(props.from)}
                  disabled={schedule.bwScheduleDate === undefined}
                  className='updateButton'>Submit</Button>
              </Col>
            </Row>
          </Card>
        </>
      </Modal>
      <Modal
       visible={props.visibleSesi}
       width={props.isMobile ? '100%' : "50%"}
       className={props.isMobile ? 'modalMobile' : 'modalImage'}
       bodyStyle={{padding: 0}}
       centered
       onCancel={() => props.handleBack()}
       footer={null}
       closable
       destroyOnClose={true}
       maskClosable
       style={{borderRadius: '25px'}}
       closeIcon={<CloseOutlined style={{ color: '#fff' }} />}
      >
        <>
          <Row className="modalHeadEdit">
            <Col span={24}>
              <h1 className="modalTitleEdit">{props.title}</h1>
              <div className='dividerGalery' />
            </Col>
          </Row>
          <Card bordered={false} style={{minHeight: '200px'}}>
            <Row gutter={[14,24]}>
              <Col span={24}>
                <Input value={sesi ? sesi.bwSessionName : undefined} onChange={val => handleSesi(val, '', 'name')} className='input_style_BW' placeholder='Nama Sesi' />
              </Col>
              <Col span={12}>
                <TimePicker
                  showNow={false}
                  allowClear={false}
                  style={{width: '100%'}}
                  value={sesi ? sesi.bwSessionTimeStart : undefined}
                  onChange={(time, timeString) => handleSesi(time, timeString, 'start')}
                  format={formatTime} placeholder='Waktu Start'
                />
              </Col>
              <Col span={12}>
                <TimePicker
                  showNow={false}
                  allowClear={false}
                  style={{width: '100%'}}
                  disabledHours={() => disabledHours()}
                  disabledMinutes={(current) => disabledTime(current)}
                  onChange={(time, timeString) => handleSesi(time, timeString, 'end')}
                  value={sesi ? sesi.bwSessionTimeFinish : undefined}
                  format={formatTime} placeholder='Waktu Finish'
                />
              </Col>
              <Col span={6}>
                <Checkbox checked={sesi ? sesi.isWeekend: false} onChange={(value) => handleSesi(value, '', 'checkBox')}>
                  Weekend
                </Checkbox>
              </Col>
              <Col span={24} style={{textAlign: 'right'}}>
                <Button
                  disabled={sesi
                    ? sesi.bwSessionName === undefined
                    || sesi.bwSessionName === ''
                    || sesi.bwSessionTimeStart === undefined
                    || sesi.bwSessionTimeFinish === undefined
                    : false}
                  loading={props.loading}
                  onClick={() => handleCreate(props.from)}
                  className='updateButton'
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Card>
        </>
      </Modal>
    </>
  )
}

export const UIModalSessionSchedule = ModalSessionSchedule;
