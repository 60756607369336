const GET_PRESENTATION_LOADING = 'GET_PRESENTATION_LOADING'
const GET_PRESENTATION_SUCCESS = 'GET_PRESENTATION_SUCCESS'
const GET_PRESENTATION_FAILED = 'GET_PRESENTATION_FAILED'
const GET_PRESENTATION_DETAIL_FAILED = 'GET_PRESENTATION_DETAIL_FAILED'
const GET_PRESENTATION_DETAIL_SUCCESS = 'GET_PRESENTATION_DETAIL_SUCCESS'
const GET_PRESENTATION_DETAIL_LOADING = 'GET_PRESENTATION_DETAIL_LOADING'
const GET_PARTICIPANT_LIST_LOYOLA_LOADING = 'GET_PARTICIPANT_LIST_LOYOLA_LOADING'
const GET_PARTICIPANT_LIST_LOYOLA_FAILED = 'GET_PARTICIPANT_LIST_LOYOLA_FAILED'
const GET_PARTICIPANT_LIST_LOYOLA_SUCCESS = 'GET_PARTICIPANT_LIST_LOYOLA_SUCCESS'
const GET_PARTICIPANT_LIST_PUBLIC_LOADING = 'GET_PARTICIPANT_LIST_PUBLIC_LOADING'
const GET_PARTICIPANT_LIST_PUBLIC_FAILED = 'GET_PARTICIPANT_LIST_PUBLIC_FAILED'
const GET_PARTICIPANT_LIST_PUBLIC_SUCCESS = 'GET_PARTICIPANT_LIST_PUBLIC_SUCCESS'
const POST_SEND_EMAIL_LOADING = 'POST_SEND_EMAIL_LOADING'
const POST_SEND_EMAIL_SUCCESS = 'POST_SEND_EMAIL_SUCCESS'
const POST_SEND_EMAIL_FAILED = 'POST_SEND_EMAIL_FAILED'

export {
  GET_PRESENTATION_LOADING,
  GET_PRESENTATION_SUCCESS,
  GET_PRESENTATION_FAILED,
  GET_PRESENTATION_DETAIL_FAILED,
  GET_PRESENTATION_DETAIL_SUCCESS,
  GET_PRESENTATION_DETAIL_LOADING,
  GET_PARTICIPANT_LIST_LOYOLA_LOADING,
  GET_PARTICIPANT_LIST_LOYOLA_FAILED,
  GET_PARTICIPANT_LIST_LOYOLA_SUCCESS,
  GET_PARTICIPANT_LIST_PUBLIC_LOADING,
  GET_PARTICIPANT_LIST_PUBLIC_FAILED,
  GET_PARTICIPANT_LIST_PUBLIC_SUCCESS,
  POST_SEND_EMAIL_LOADING,
  POST_SEND_EMAIL_SUCCESS,
  POST_SEND_EMAIL_FAILED,
}
