const GET_LATE_REGISTER_LOADING = 'GET_LATE_REGISTER_LOADING'
const GET_LATE_REGISTER_SUCCESS = 'GET_LATE_REGISTER_SUCCESS'
const GET_LATE_REGISTER_FAILED = 'GET_LATE_REGISTER_FAILED'
const REJECT_LATE_REGISTER_LOADING = 'REJECT_LATE_REGISTER_LOADING'
const REJECT_LATE_REGISTER_FAILED = 'REJECT_LATE_REGISTER_FAILED'
const REJECT_LATE_REGISTER_SUCCESS = 'REJECT_LATE_REGISTER_SUCCESS'

export {
  GET_LATE_REGISTER_LOADING,
  GET_LATE_REGISTER_SUCCESS,
  GET_LATE_REGISTER_FAILED,
  REJECT_LATE_REGISTER_LOADING,
  REJECT_LATE_REGISTER_FAILED,
  REJECT_LATE_REGISTER_SUCCESS,
}
