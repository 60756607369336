import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../../global/global.css'
import getAdmin from '../../../actions/manageAdmin'
import {
  Card,
  notification,
  Button,
  Row,
  Col,
  Breadcrumb,
  Typography
} from 'antd'
import { UItable, UISearch, UIModalCreateAdmin } from '../../../components'
import moment from "moment";

const { Text } = Typography

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderBy: '',
      size: 10,
      page: 1,
      search: '',
      visibleRegis: false,
      loadingSubmit: false,
      initial: undefined,
    }
  }

  componentDidMount() {
    const {orderBy, size, page, search } = this.state
    this.props.handleDataAdmin(this.props.token, orderBy, size, page, search)
  }

  handleRedirectDetail = id => {
    this.props.history.push(`/admin-detail/${id}`)
  }

  handleRegisSubmit = (value) => {
    this.setState({
      loadingSubmit: true
    })
    let body = {
      adminName: value.nama,
      adminIdentity: value.ktp,
      roleId: value.role,
      adminGender: value.gender,
      adminDateOfBirth: value.date ? moment(value.date).format('YYYY-MM-DD') : null,
      adminPosition: value.jabatan,
      adminPhone: value.phone,
      adminAddress: value.address,
      adminLastEdu: value.lastEducation,
      adminLastPlaceOfEdu: value.lastEducationPlace,
      adminEmail: value.email,
      adminUsername: value.username,
      adminPassword: value.password,
      usersId: sessionStorage.getItem('userId'),
    }
    // console.log(body);
    this.props.handlePostAdmin(this.props.token, body).then(res => {
      // console.log('res submit', res)
      if (res.payload.status !== 'error') {
        if (res.payload.data.data.Status === 'OK') {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Registrasi Admin Success',
          });
          this.setState({
            search: '',
            page: 1,
            orderBy: '',
            loadingSubmit: false,
            visibleRegis: false
          }, () => {
            this.props.handleDataAdmin(this.props.token, '', 10, 1, '')
          })
        }else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Registrasi Admin Failed'
          });
          this.setState({
            loadingSubmit: false,
            visibleRegis: false
          })
        }
      }else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: res.payload.error.message ? res.payload.error.message : 'Registrasi Admin Failed'
        });
        this.setState({
          loadingSubmit: false,
          visibleRegis: false
        })
      }
    })
   }

  handleRegisClose = () => {
    this.setState({
      visibleRegis: false
    })
  }

  openModal = () => {
    this.setState({
      visibleRegis: true
    })
  }

  openPageEdit = record => {
    let id = record.adminId;
    this.props.history.push(`/admin-edit/${id}`)
  }

  handleTableChange = (page, filter, sorter) => {
    const { size, search} = this.state
    let sorterField
    if ( sorter.field === "adminName" ) {
      sorterField = "administrator_name";
    }else if ( sorter.field === "adminUserName" ) {
      sorterField = "u.users_username";
    } else if ( sorter.field === "adminRoleName" ) {
      sorterField = "r.role_name";
    }else if ( sorter.field === "adminStatus" ) {
      sorterField = "is_deleted";
    }

    let orderBy = "";
    if (sorter.order === "ascend" ) {
      orderBy = `${sorterField},asc`;
    } else if (sorter.order === "descend") {
      orderBy = `${sorterField},desc`;
    }

    this.setState({
      page: page.current,
      orderBy: orderBy
    }, ()=> {
      this.props.handleDataAdmin(this.props.token, orderBy, size, page.current, search)
    })
  }

  handleSearch = key => {
    const {orderBy, size} = this.state
    this.setState({
      search: key,
      page: 1
    })
    this.props.handleDataAdmin(this.props.token, orderBy, size, 1, key)
  }

  render() {
    const columnsTable = [
      {
        title: 'Name',
        dataIndex: 'adminName',
        key: 'adminName',
        sorter: true,
        ellipsis: true,
        render: name => <Text style={{ color: '#EF712B', cursor: 'pointer', fontWeight: 600}}>{name}</Text>,
        onCell: record => {
          return{
            onClick: () => {
              this.handleRedirectDetail(record.adminId ? record.adminId : '')
            }
          }
        }
      },
      {
        title: 'Username',
        dataIndex: 'adminUserName',
        key: 'adminUserName',
        sorter: true,
        onCell: record => {
          return{
            onClick: () => {
              this.handleRedirectDetail(record.adminId ? record.adminId : '')
            }
          }
        }
      },
      {
        title: 'Role',
        dataIndex: 'adminRoleName',
        key: 'adminRoleName',
        sorter: true,
        ellipsis: true,
        onCell: record => {
          return{
            onClick: () => {
              this.handleRedirectDetail(record.adminId ? record.adminId : '')
            }
          }
        }
      },
      {
        title: 'Active',
        dataIndex: 'adminStatus',
        key: 'adminStatus',
        sorter: true,
        render: status => {
          if (status === 'Active') {
            return (
              <Text style={{color: '#EF712B', cursor: 'pointer', fontWeight: 600}}>{status}</Text>
            )
          } else {
            return (
              <Text style={{color: '#11174C', cursor: 'pointer', fontWeight: 600}}>{status}</Text>
            )
          }
        },
        onCell: record => {
          return{
            onClick: () => {
              this.handleRedirectDetail(record.adminId ? record.adminId : '')
            }
          }
        }
      },
      {
        title: 'Action',
        dataIndex: '',
        key: '',
        render: record =>
          <Button
            onClick={() => this.openPageEdit(record)}
            type='link'
            style={{ color: '#EF712B', cursor: 'pointer', fontWeight: 600 }}
          >
            Edit
          </Button>
      },
    ]
    const {
      dataAdmin,
    } = this.props

    const {
      visibleRegis,
      page,
      loadingSubmit,
    } = this.state

    return (
      <div className='root'>
        <Breadcrumb className="breadcrumb_style">
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/dashboard`)}>Kelola Admin </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className='pathNow_style'>
            Admin
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card className='bodyCard_style'>
          <Row>
            <Col span={8}>
              <UISearch placeholder='Search' handleSearch={key => this.handleSearch(key)} />
            </Col>
            <Col span={16} style={{ textAlign: 'right' }}>
              <Button onClick={ () => this.openModal()} className='updateButton'>Create User Admin Loyola</Button>
            </Col>
          </Row>
          <Card
            bordered={false}
            loading={dataAdmin.loading}
            style={{minHeight: '300px'}}>
            <UItable
              dataTable={dataAdmin.data}
              columns={columnsTable}
              withRows={false}
              isMobile={this.props.isMobile}
              totalData={dataAdmin.totalData}
              loading={dataAdmin.status}
              page={page}
              handleTableChange={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter)}
            />
          </Card>
        </Card>
        <UIModalCreateAdmin
          isMobile={this.props.isMobile}
          visibleRegis={visibleRegis}
          title={'Create User Admin Loyola'}
          loading={false}
          loadingSubmit={loadingSubmit}
          close={() => this.handleRegisClose()}
          onFinish={(value) => this.handleRegisSubmit(value)}
        />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return{
    handleDataAdmin: (token, sort, size, page, search) => dispatch(getAdmin.getDataAdmin(token, sort, size, page, search)),
    handlePostAdmin: (token, body) => dispatch(getAdmin.regisAdmin(token, body)),
  }
}

const mapStateToProps = (state) => {
  return {
    dataAdmin: state.manageAdmin,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin)
