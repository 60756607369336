import React, {Component} from 'react'
import {connect} from 'react-redux'
import '../../../global/global.css'
import {UIContentDetailAdmin} from '../../../components'
import {Card, notification, Breadcrumb} from 'antd'
import getAdmin from "../../../actions/manageAdmin";

class AdminEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initial: '',
      id: '',
      loadingEdit: false,
    }
  }

  componentDidMount() {
    let id = this.props.location.pathname.split('/')[2]
    this.setState({
      id: id
    })
    this.props.handleDataDetail(this.props.token, id)
  }

  handleFinish = value => {
    this.setState({
      loadingEdit: true,
    })
    this.props.handleEditAdmin(this.props.token, value, this.state.id).then(res => {
      if (res.payload.status !== 'error') {
        if (res.payload.data.data.Status === 'OK') {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Registrasi Admin Success',
          });
          setTimeout(() => {
            this.props.history.push(`/admin`)
          },  this.state.loadingEdit ? this.state.loadingEdit : 1000);
          this.setState({
            loadingEdit: false,
          }, () => {
            this.props.handleDataDetail(this.props.token, this.state.id)
          })
        }else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Registrasi Admin Failed'
          });
          this.setState({
            loadingEdit: false,
          })
        }
      }else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: res.payload.error.message ? res.payload.error.message : 'Registrasi Admin Failed'
        });
        this.setState({
          loadingEdit: false,
        })
      }
    })
  }

  render() {
    const { dataDetail } = this.props;
    const { loadingEdit } = this.state;
    return (
      <div className='root'>
        <Breadcrumb className="breadcrumb_style">
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/dashboard`)}>Kelola Admin </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/admin`)}>Admin </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className='pathNow_style'>
            Edit Admin
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card
          loading={dataDetail.status === 'loading'}
          className='bodyCard_style'>
          <UIContentDetailAdmin
            dataDetail={dataDetail.data ? dataDetail.data : []}
            isMobile={this.props.isMobile}
            isForm={true}
            handleFinish={(value) => this.handleFinish(value)}
            loadingPost={loadingEdit}
          />
        </Card>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // handleDataHome: () => dispatch(getHome.getDataHome())
    handleDataDetail: (token, id) => dispatch(getAdmin.getDataDetailAdmin(token, id)),
    // handleDataParticipantListLoyola: (token, sort, size, page, search) => dispatch(getPresentation.getDataParticipantListLoyola(token, sort, size, page, search)),
    // handleDataParticipantListPublic: (token, sort, size, page, search) => dispatch(getPresentation.getDataParticipantListPublic(token, sort, size, page, search)),
    // handleSendEmail: (token, id) => dispatch(getPresentation.postSendEmail(token, id)),
    handleEditAdmin: (token, body, id) => dispatch(getAdmin.updateAdmin(token, body, id)),
  }
}

const mapStateToProps = (state) => {
  return {
    // dataHome: state.home,
    dataDetail: state.detailAdmin,
    // dataParticipantListLoyola: state.participantListLoyola,
    // dataParticipantListPublic: state.participantListPublic,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminEdit)
