import React, {useState, useEffect} from 'react'
import { post } from 'axios'
import {
  Row,
  Col,
  Modal,
  Card,
  Input,
  Button,
  Checkbox,
  Typography,
  DatePicker,
  TimePicker,
  Form,
  notification,
  Popconfirm
} from 'antd'
import moment from 'moment'
import { CloseOutlined, PlusCircleOutlined} from "@ant-design/icons";
import '../../global/global.css'
import config from '../../services/config'
const { BASE_URL_LOGIN, TIMEOUT } = config

const { TextArea } = Input
const { Text } = Typography
const { RangePicker } = DatePicker

const ModalCreateBW = props => {
  const [form] = Form.useForm()

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const [dateRegis, setDateRegis] = useState([])
  const [dateEvent, setDateEvent] = useState([])
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [filePath, setFilePath] = useState(null)
  const [listSchedule, setListSchedule] = useState([{id: 1}])
  const [listSesi, setListSesi] = useState([{id: 1, endTime: undefined}])
  const [theme, setTheme] = useState({ind: '', eng: ''})
  const [validateThemeName, setValidateThemeName] = useState({
    themeNameInd: true,
    themeNameEng: true
  })
  const [validateFieldSchedule, setValidateFieldSchedule] = useState([{
    presentationDate: true
  }]);
  const [validateFieldSession, setValidateFieldSession] = useState([{
    sessionName: true,
    timeStart: true,
    timeFinish: true,
  }])

  useEffect(() => {
    setFilePath(null);
    setFileName(null);
    setTheme({ind: '', eng: ''})
    setListSchedule([{id: 1}])
    setListSesi([{id: 1, endTime: undefined}])
    form.resetFields();
    setDateRegis([])
    setDateEvent([])
  }, [props.visible]);

  const disabledDate = current => {
    const start = dateRegis[0] && current.diff(moment(dateRegis[0]), 'h') < 0
    return start
  }
  const disabledDateSchedule = current => {
    const start = dateEvent[0] && current.diff(moment(dateEvent[0]), 'h') < 0
    let disabled = []
    if (dateEvent.length > 1) {
       const end = dateEvent[1] && current.diff(moment(dateEvent[1]), 'h') > 0
       disabled = start + end
    }else {
      disabled = start
    }
    return disabled
  }

  const format = 'dddd, YYYY-MM-DD'

  const formatTime = 'HH:mm'

  const handleAddSchedule = () => {
    let temp = listSchedule
    temp.push({
      id: temp.length + 1
    })
    setListSchedule([...temp])

    let tempValidate = validateFieldSchedule;
    tempValidate.push({
      presentationDate: true
    });
    setValidateFieldSchedule([...tempValidate]);
  }

  const handleRemoveSchedule = index => {
    let temp = listSchedule
    temp.splice(index, 1)
    setListSchedule([...temp])

    let tempValidate = validateFieldSchedule;
    tempValidate.splice(index, 1);
    setValidateFieldSchedule([...tempValidate]);
  }

  const handleAddSesion = () => {
    let temp = listSesi
    temp.push({
      id: temp.length + 1
    })
    setListSesi([...temp])

    let tempValidate = validateFieldSession;
    tempValidate.push({
      sessionName: true,
      timeStart: true,
      timeFinish: true
    })
    setValidateFieldSession([...tempValidate]);
  }

  const handleRemoveSesi = index => {
    let temp = listSesi
    temp.splice(index, 1)
    setListSesi([...temp])

    let tempValidate = validateFieldSession;
    tempValidate.splice(index, 1);
    setValidateFieldSession([...tempValidate]);
  }

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
       result.push(i);
    }
    return result;
 }

  const disabledHours = (index) => {
    const hours = moment(listSesi[index].startTime, "HH:mm:ss").hours();
    return range(0, hours)
  }

  const disabledTime = (val ,index) => {
    const temp = moment(listSesi[index].startTime, "HH:mm:ss").hours()
    if (val === temp ) {
      const time = moment(listSesi[index].startTime, "HH:mm:ss").minute();
      return range(0, time + 1)
    }
 }

  const onChangeFinishTime = (time, timeString, index) => {
    let temp = listSesi
    temp[index] = {
      ...temp[index],
      endTime:  moment(timeString, "HH:mm:ss")
    }
    setListSesi([...temp])

    let tempValidate = validateFieldSession;
    tempValidate[index] = {
      ...tempValidate[index],
      endTime: timeString.length ? true : false
    }
    setValidateFieldSession([...tempValidate]);
  }

  const onChangeStartTime = (time, timeString, index) => {
    let temp = listSesi
    let tempEnd = `${moment(time, "HH:mm:ss").hours()}:${moment(time, "HH:mm:ss").minutes() + 1}`
    temp[index] = {
      ...temp[index],
      startTime: timeString,
      endTime: tempEnd !== "NaN:NaN" ? moment(tempEnd, "HH:mm:ss") : null
    }
    setListSesi([...temp])

    let tempValidate = validateFieldSession;
    tempValidate[index] = {
      ...tempValidate[index],
      timeStart: timeString.length ? true : false,
      timeFinish: timeString.length ? true : false,
    }
    setValidateFieldSession([...tempValidate]);
  }

  const sesiNameChage = (val, index) => {
    let temp = listSesi
    temp[index]={
      ...temp[index],
      bwSessionName: val.target.value
    }
    setListSesi([...temp])

    let tempValidate = validateFieldSession;
    tempValidate[index] = {
      ...tempValidate[index],
      sessionName: val.target.value.length ? true : false
    }
    setValidateFieldSession([...tempValidate]);
  }

  const weekEndChecked = (value, index) => {
    let temp = listSesi
    temp[index] = {
      ...temp[index],
      isWeekend: value.target.checked
    }
    setListSesi([...temp])
  }

  const handleDateChange = (date, dateString, index) => {
    let temp = listSchedule
    temp[index] ={
      id: temp[index].id,
      date: date,
      dateString: dateString
    }

    let tempValidate = validateFieldSchedule;
    tempValidate[index] = {
      presentationDate: dateString.length ? true : false
    }
    setValidateFieldSchedule([...tempValidate]);
  }

  const fileInput = React.createRef();

  const openFileBrowser = () => {
    fileInput.current.click();
  };

  const uploadAssignmentLetter = (data) => {
    setLoadingUpload(true);
    post(`${BASE_URL_LOGIN}be/api/upload/banner`, data,  {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${props.token}`,
      },
      timeout: TIMEOUT
    }).then( res => {
      if ( res && res.status ? res.data.Status === "OK" : false ) {
        notification.success({
          placement: 'bottomRight',
          message: 'Success',
          description: res.data.Message ? res.data.Message : 'upload success',
        });
        setFilePath(res.data ? res.data.Data : "")
      } else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: res.data.Message ? res.data.Message : 'upload Failed',
        });
      }
      setLoadingUpload(false);
    }).catch(() => {
      notification.error({
        placement: 'bottomRight',
        message: 'Error',
        description: 'Upload Failed'
      });
      setLoadingUpload(false);
    });
  }

  const fileHandler = event => {
    if (event.target.files.length > 3) {
      notification.error({
        placement: 'bottomRight',
        message: 'Error',
        description: 'To Many FIle'
      });
    }else {
      if (event.target.files.length) {
        const file = event.target.files;
        let filename = null
        const formData = new FormData();
        for (let i = 0; i < file.length; i++) {
          filename = filename ? filename + ', ' + file[i].name : file[i].name
          setFileName(filename)
          formData.append(`file`, file[i])
        }
        uploadAssignmentLetter(formData);
      }
    }
  };

  const disabledDateRegis = current => {
    const end = dateEvent[1] && current.diff(moment(dateEvent[1]), 'h') > 0
    return end
  }

  const handleChangeDate = date => {
    if(dateEvent.length > 0) {
      if (dateEvent[0].diff(date[0], 'h') < 0) {
        let temp = [date[0], dateEvent[1]]
        form.setFieldsValue({
          startEndDateEvent: temp
        })
      }
    }
    if (date === null) {
      setDateRegis([])
    }else {
      setDateRegis(date)
    }
  }

  const handleChangeDateEvent = date => {
    if(dateRegis.length > 0) {
      if (dateRegis[1].diff(date[1], 'h') > 0) {
        let temp = [dateRegis[0], date[1]]
        form.setFieldsValue({
          startEndDateRegis: temp
        })
      }
    }
    if (date === null) {
      setDateEvent([])
    }else {
      setDateEvent(date)
    }
  }

  const checkFinish = (values, name) => {
    let body = {}
    if (name === 'tema') {
      let tempImage = []
      filePath.map(res => {
        return tempImage.push({
          bwImgPath: res.bwImgPath
        })
      })
      body = {
        bwThemeInd : theme.ind,
        bwThemeEng : theme.eng,
        bwDescInd : values.descThemeId,
        bwDescEng : values.descThemeEng,
        bwDurationStart : values.startEndDateEvent ? moment(values.startEndDateEvent[0]).format('YYYY-MM-DD') : null,
        bwDurationFinish : values.startEndDateEvent ?  moment(values.startEndDateEvent[1]).format('YYYY-MM-DD') : null,
        bwNarationDescInd : values.narationDescId,
        bwNarationDescEng : values.narationDescEng,
        bwNarationTitleInd : values.narationId,
        bwNarationTitleEng : values.narationEng,
        bwUnivRegStart : values.startEndDateRegis ? moment(values.startEndDateRegis[0]).format('YYYY-MM-DD') : null,
        bwUnivRegFinish : values.startEndDateRegis ? moment(values.startEndDateRegis[1]).format('YYYY-MM-DD') : null,
        bidiktiWeekImage : tempImage,
      }
      props.saveBody(body, name)
    }else if (name === 'schedule') {
      let tempValidate = values.map( res => {
        return {
          presentationDate: res.dateString ? true : false
        }
      })
      setValidateFieldSchedule([...tempValidate]);

      let isValidate = true;
      for ( let i = 0; i < tempValidate.length; i++ ) {
        if ( !tempValidate[i].presentationDate ) {
          isValidate = false;
          break;
        }
      }

      if ( isValidate ) {
        let tempSchedule = []
        values.map(res=> {
          let tempName = res.dateString.split(',')
          return tempSchedule.push({
            bwScheduleDay: tempName[0],
            bwScheduleDate: tempName[1]
          })
        })
        body = {
          bwThemeInd : theme.ind,
          bwThemeEng : theme.eng,
          bidiktiWeekSchedule: tempSchedule,
        }
        props.saveBody(body, name)
      }
    }else {
      let tempSesi= []
      values.map(res => {
        return tempSesi.push({
          bwSessionName: res.bwSessionName,
          bwSessionTimeStart: res.startTime + ':00',
          bwSessionTimeFinish: moment(res.endTime).format('HH:mm') + ':00',
          isWeekend: res.isWeekend ? res.isWeekend : false
        })
      })
      body = {
        bwThemeInd : theme.ind,
        bwThemeEng : theme.eng,
        bidiktiWeekSession: tempSesi
      }

      let tempValidateSession = values.map( res => {
        return {
          sessionName: res.bwSessionName ? true : false,
          timeStart: res.startTime ? true : false,
          timeFinish: res.endTime ? true : false
        }
      });
      setValidateFieldSession([...tempValidateSession]);

      let validateSession = true;
      for ( let i = 0; i < tempValidateSession.length; i++ ) {
        if ( 
          !tempValidateSession[i].sessionName ||
          !tempValidateSession[i].timeStart ||
          !tempValidateSession[i].timeFinish 
        ) {
          validateSession = false;
          break;
        }
      }

      let tempValidateThemeName = {
        themeNameInd: theme.ind ? true : false,
        themeNameEng: theme.eng ? true : false
      };
      setValidateThemeName({...tempValidateThemeName});

      let validateThemeName = true;
      if ( !tempValidateThemeName.themeNameInd || !tempValidateThemeName.themeNameEng ) {
        validateThemeName = false;
      }

      let isValidate = validateSession && validateThemeName;
      if ( isValidate ) {
        props.saveBody(body, name)
      }
    }
  }

  const changeTheme = (value, name) => {
    if (name === 'ind') {
      setTheme({
        ind: value.target.value,
        eng: theme.eng,
      })

      let tempValidate = validateThemeName;
      tempValidate.themeNameInd = value.target.value ? true : false;
      setValidateThemeName({...tempValidate});
    }else {
      setTheme({
        ind: theme.ind,
        eng: value.target.value
      })

      let tempValidate = validateThemeName;
      tempValidate.themeNameEng = value.target.value ? true : false;
      setValidateThemeName({...tempValidate});
    }
  }

  const text = (
    <span>
      Apakah anda yakin menyimpan data <b>BIDikTi</b> Week ini?<br></br>
      Info: menyimpan data <b>BIDikTi Week</b> ini akan mengganti tampilan pada menu <b>BIDikTi Week</b> web user.
    </span>
  )

  return(
    <>
      <Modal
        visible={props.visible}
        width={props.isMobile ? '100%' : "50%"}
        bodyStyle={{padding: 0}}
        className={props.isMobile ? 'modalMobile' : 'modalImage'}
        centered
        onCancel={() => props.handleBack()}
        footer={null}
        destroyOnClose={true}
        closable
        maskClosable
        style={{borderRadius: '25px'}}
        closeIcon={<CloseOutlined style={{ color: '#fff' }} />}
      >
        <>
          <Row className="modalHeadEdit">
            <Col span={24}>
              <h1 className="modalTitleEdit">Register Tema BIDikTi Week</h1>
              <div className='dividerGalery' />
            </Col>
            <Col span={23}>
              <Form.Item
                style={{marginBottom: 0}}
                validateStatus={validateThemeName.themeNameInd ? "success" : "error"}
                help={validateThemeName.themeNameInd ? "" : "Please input theme name BIDikTi Week (Bahasa Indonesia)"}
              >
                <Input
                  name='themeId'
                  placeholder="Nama Tema BIDikTi Week (Bahasa Indonesia)"
                  className="input_style_modal"
                  value={theme.ind}
                  onChange={val => changeTheme(val, 'ind')}
                />
              </Form.Item>
            </Col>
            <Col span={1} style={{ paddingLeft: '5px'}}>
              <span className="mandatoryLarge">&#8727;</span>
            </Col>
            <Col span={23} style={{ margin: '15px 0'}}>
              <Form.Item
                style={{marginBottom: 0}}
                validateStatus={validateThemeName.themeNameEng ? "success" : "error"}
                help={validateThemeName.themeNameEng ? "" : "Please input theme name BIDikTi Week (Bahasa Inggris)"}
              >
                <Input
                  name='themeEng'
                  placeholder="Nama Tema BIDikTi Week (Bahasa Inggris)"
                  className="input_style_modal"
                  value={theme.eng}
                  onChange={val => changeTheme(val, 'eng')}
                />
              </Form.Item>
            </Col>
            <Col span={1} style={{ margin: '15px 0', paddingLeft: '5px'}}>
              <span className="mandatoryLarge">&#8727;</span>
            </Col>
            <Col span={1} style={{ margin: '15px 0', paddingLeft: '5px'}}>
              <span className="mandatoryLarge">&#8727;</span>
            </Col>
            <Col span={24}>
              <button className={props.activeButton === 'tema' ? 'activeButton' : 'buttonMode'} disabled={true}>Data Tema BIDikTi Week</button>
              <button className={props.activeButton === 'schedule' ? 'activeButton' : 'buttonMode'} disabled={true}>Tanggal Presentasi</button>
              <button className={props.activeButton === 'sesi' ? 'activeButton' : 'buttonMode'} disabled={true}>Sesi</button>
            </Col>
          </Row>
          <Card bordered={false} style={{minHeight: '150px'}}>
            {props.activeButton === 'tema' ? (
              <Row>
                <Col span={24}>
                  <Form
                    form={form}
                    onFinish={values => checkFinish(values,'tema')}
                    onFinishFailed={onFinishFailed}
                    initialValues={{
                      narationId: props.dataNaration.bwNarationTitleInd,
                      narationEng: props.dataNaration.bwNarationTitleEng,
                      narationDescId: props.dataNaration.bwNarationDescInd,
                      narationDescEng: props.dataNaration.bwNarationDescEng,
                    }}
                  >
                    <Row gutter={[10,0]}>
                      <Col span={23} style={{ marginBottom: '20px'}}>
                        <Row>
                          <Col span={10}>
                            <Text className='headerFormText'>
                              <span className="mandatory">* </span>
                              Banner BIDikTi Week
                            </Text>
                            <Text className='maxUpload'>(Max. 3 Image)</Text>
                          </Col>
                          <Col span={13} style={{ textAlign: 'right'}}>
                            <input
                              type="file"
                              multiple
                              hidden
                              onChange={fileHandler}
                              ref={fileInput}
                            />
                            <Button
                              disabled={props.fromLate}
                              onClick={openFileBrowser}
                              type="primary"
                              className='buttonUpload'
                              loading={loadingUpload}
                            >
                              Upload
                            </Button>
                          </Col>
                          <Col span={23}>
                            <span
                              style={{
                                marginRight: 15
                              }}
                            >
                                { fileName ? (
                                  fileName
                                  // .length > 20 ? fileName.substr(0,20)+ '...' : fileName
                                  ) : ('')
                                }
                            </span>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={11}>
                        <Text className='headerFormText'>
                          <span className="mandatory">* </span>
                          Durasi Pendaftaran
                        </Text>
                        <Form.Item
                          name='startEndDateRegis'
                          rules={[{
                            required: true,
                            message: 'Please input date!'
                          }]}
                        >
                          <RangePicker
                            allowClear={false}
                            placeholder={['Tanggal Start', 'Tanggal Finish']}
                            onCalendarChange={(valueMoment) => handleChangeDate(valueMoment)}
                            disabledDate={ date => disabledDateRegis(date)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                      </Col>
                      <Col span={11}>
                        <Text className='headerFormText'>
                          <span className="mandatory">* </span>
                          Durasi Pelaksanaan
                        </Text>
                        <Form.Item
                          name='startEndDateEvent'
                          rules={[{
                            required: true,
                            message: 'Please input date!'
                          }]}
                        >
                          <RangePicker
                            allowClear={false}
                            placeholder={['Tanggal Start', 'Tanggal Finish']}
                            onCalendarChange={(valueMoment) => handleChangeDateEvent(valueMoment)}
                            disabledDate={ date => disabledDate(date)}/>
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          name='descThemeId'
                          rules={[{
                            required: true,
                            message: 'Please input theme description!'
                          }]}
                        >
                          <TextArea
                            maxLength={256}
                            size="large"
                            rows={4}
                            className='input_style'
                            placeholder={"Deskripsi Tema BIDikTi Week (Bahasa Indonesia)"}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <span className="mandatoryLarge">&#8727;</span>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          name='descThemeEng'
                          rules={[{
                            required: true,
                            message: 'Please input theme description!'
                          }]}
                        >
                          <TextArea
                            maxLength={256}
                            size="large"
                            rows={4}
                            className='input_style'
                            placeholder={"Deskripsi Tema BIDikTi Week (Bahasa Inggris)"}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <span className="mandatoryLarge">&#8727;</span>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          name='narationId'
                          rules={[{
                            required: true,
                            message: 'Please input naration theme!'
                          }]}
                        >
                          <TextArea
                            maxLength={256}
                            size="large"
                            rows={2}
                            className='input_style'
                            placeholder={"Narasi Judul Pendaftaran Universitas dan Sekolah Kedinasan (Bahasa Indonesia)"}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          name='narationEng'
                          rules={[{
                            required: true,
                            message: 'Please input naration theme!'
                          }]}
                        >
                          <TextArea
                            maxLength={256}
                            size="large"
                            rows={2}
                            className='input_style'
                            placeholder={"Narasi Judul Pendaftaran Universitas dan Sekolah Kedinasan (Bahasa Inggris)"}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          name='narationDescId'
                          rules={[{
                            required: true,
                            message: 'Please input naration description!'
                          }]}
                        >
                          <TextArea
                            maxLength={256}
                            size="large"
                            rows={4}
                            className='input_style'
                            placeholder={"Narasi Deskripsi Pendaftaran Universitas dan Sekolah Kedinasan (Bahasa Indonesia)"}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          name='narationDescEng'
                          rules={[{
                            required: true,
                            message: 'Please input naration description!'
                          }]}
                        >
                          <TextArea
                            maxLength={256}
                            size="large"
                            rows={4}
                            className='input_style'
                            placeholder={"Narasi Deskripsi Pendaftaran Universitas dan Sekolah Kedinasan (Bahasa Inggris)"}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          style={{
                            textAlign: 'right',
                            marginBottom: '0px'
                          }}
                        >
                          <Button
                            type="primary"
                            htmlType='submit'
                            className='updateButton'
                            size={'small'}
                          >
                            Next
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            ) : props.activeButton === 'schedule' ? (
              <Row>
                <Col span={24}>
                  {listSchedule.map((res, index) =>
                    (
                      <Row key={index+'schedule'} gutter={[0,4]} align="top">
                        <Col span={12} style={{paddingTop: 8}}>
                          <Text className='textSchedule'>
                            <span className="mandatory">* </span>
                            Tanggal Presentasi {index + 1}:
                          </Text>
                        </Col>
                        <Col span={10} style={{ textAlign: 'right'}}>
                          <Form.Item  
                            style={{marginBottom: 0}}
                            validateStatus={validateFieldSchedule[index] && validateFieldSchedule[index].presentationDate  ? "success" : "error"} 
                            help={validateFieldSchedule[index] && validateFieldSchedule[index].presentationDate ? "" : "Please input presentation date!"}
                          >
                            <DatePicker
                              format={format}
                              disabledDate={date => disabledDateSchedule(date)}
                              onChange={(value, string) => handleDateChange(value, string, index)}
                              placeholder='Tanggal Presentasi'
                              style={{ width: '80%'}}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={2} style={{margin: '5px auto',textAlign: 'center', color: '#797979'}}>
                          <CloseOutlined onClick={() => handleRemoveSchedule(index)} style={{ fontSize: '16px'}} />
                        </Col>
                      </Row>
                    )
                  )}
                </Col>
                <Col span={24} style={{ marginTop: '20px'}}>
                  <Row>
                    <Col span={22}>
                      <div className='dividerPlus'/>
                    </Col>
                    <Col span={2} style={{margin: 'auto',textAlign: 'center', color: '#ef712b'}}>
                      <PlusCircleOutlined onClick={() => handleAddSchedule()} style={{ fontSize: '30px'}}/>
                    </Col>
                  </Row>
                  <Col
                    span={24}
                    style={{
                      textAlign: 'right',
                      marginBottom: '0px',
                      marginTop: '20px'
                    }}
                  >
                      <Button
                        type="primary"
                        onClick={() => checkFinish(listSchedule, 'schedule')}
                        className='updateButton'
                        size={'small'}
                      >
                        Next
                      </Button>
                  </Col>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col span={24}>
                  {listSesi.map((res, index) =>
                    (
                      <Row key={index+'sesi'} gutter={[10,0]} align="top">
                        <Col span={21}>
                          <Form.Item 
                            style={{marginBottom: 0}}
                            validateStatus={validateFieldSession[index] && validateFieldSession[index].sessionName ? "success" : "error"} 
                            help={validateFieldSession[index] && validateFieldSession[index].sessionName ? "" : "Please input session name" }
                          >
                            <Input 
                              className='input_style_BW' 
                              placeholder='Nama Sesi' 
                              onChange={value => sesiNameChage(value, index)} 
                            />
                          </Form.Item>
                        </Col>
                        <Col span={1}>
                          <span className="mandatoryLarge">&#8727;</span>
                        </Col>
                        <Col span={2} style={{margin: '5px auto',textAlign: 'center', color: '#797979'}}>
                          <CloseOutlined onClick={() => handleRemoveSesi(index)} style={{ fontSize: '16px'}} />
                        </Col>
                        <Col span={10}>
                          <Form.Item
                            style={{marginBottom: 0}}
                            validateStatus={validateFieldSession[index] && validateFieldSession[index].timeStart ? "success" : "error"} 
                            help={validateFieldSession[index] && validateFieldSession[index].timeStart ? "" : "Please input session name" }
                          >
                            <TimePicker
                              showNow={false}
                              style={{width: '100%'}}
                              onChange={(time, timeString) => onChangeStartTime(time, timeString, index)}
                              format={formatTime} placeholder='Waktu Start'
                            />
                          </Form.Item>
                        </Col>
                        <Col span={1}>
                          <span className="mandatoryLarge">&#8727;</span>
                        </Col>
                        <Col span={10}>
                          <Form.Item
                            style={{marginBottom: 0}}
                            validateStatus={validateFieldSession[index] && validateFieldSession[index].timeFinish ? "success" : "error"} 
                            help={validateFieldSession[index] && validateFieldSession[index].timeFinish ? "" : "Please input session name" }
                          >
                            <TimePicker
                              showNow={false}
                              allowClear={false}
                              style={{width: '100%'}}
                              disabledHours={() => disabledHours(index)}
                              disabledMinutes={(current) => disabledTime(current, index)}
                              onChange={(time, timeString) => onChangeFinishTime(time, timeString, index)}
                              value={res.endTime}
                              format={formatTime} placeholder='Waktu Finish' />
                          </Form.Item>
                        </Col>
                        <Col span={1}>
                          <span className="mandatoryLarge">&#8727;</span>
                        </Col>
                        <Col span={1}>
                          <span className="mandatoryLarge">&#8727;</span>
                        </Col>
                        <Col span={6}>
                          <Checkbox onChange={(value) => weekEndChecked(value,index)} style={{marginBottom: 25}}>
                            Weekend
                          </Checkbox>
                        </Col>
                      </Row>
                    )
                  )}
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={22}>
                      <div className='dividerPlus'/>
                    </Col>
                    <Col span={2} style={{margin: 'auto',textAlign: 'center', color: '#ef712b'}}>
                      <PlusCircleOutlined onClick={() => handleAddSesion()} style={{ fontSize: '30px'}}/>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        textAlign: 'right',
                        marginBottom: '0px',
                        marginTop: '20px'
                      }}
                    >
                      <Popconfirm
                        placement="bottomRight"
                        title={text}
                        onConfirm={() => checkFinish(listSesi, 'sesi')}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          type="primary"
                          loading={props.loading}
                          className='updateButton'
                          size={'small'}
                        >
                          Submit
                        </Button>
                      </Popconfirm>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Card>
        </>
      </Modal>
    </>
  )
}

export const UIModalCreateBW = ModalCreateBW;
