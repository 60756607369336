import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../global/global.css'
import { Card, Breadcrumb, notification, Button, Col, Row, Input, Select, Form } from 'antd'
import headerFooterAction from '../../actions/headerFooter'
import lovAction from '../../actions/lov'
import { post } from 'axios'
import config from '../../services/config'
import ImagePreviewComponent from '../../components/imagePreview'

const { BASE_URL_LOGIN, TIMEOUT } = config
const fileInput = React.createRef();
const { Option } = Select

class HeaderFooter extends Component {
  formRef = React.createRef()
  constructor(props) {
    super(props);
    this.state = {
      inital: '',
      loadingLoyola: false,
      loadingBidikti: false,
      loadingSubmit: false,
      name: ''
    }
  }

  componentDidMount() {
    this.props.handleData()
    this.props.handleCoutry()
  }

  componentDidUpdate(prevProps) {
    const { dataHeadFoot } = this.props
    if (
      dataHeadFoot.status !==
      prevProps.dataHeadFoot.status &&
      dataHeadFoot.status === 'success'
      ) {
        let temp = {
          countryId: dataHeadFoot.data.footCountryId,
          provinceId: dataHeadFoot.data.footProvinceId,
          cityId: dataHeadFoot.data.footCitId,
          loyolaInfoLogoName: dataHeadFoot.data.footLogoLoyolaName,
          loyolaInfoBidiktiName: dataHeadFoot.data.footLogoBidiktiName,
          loyolaInfoWa: dataHeadFoot.data.headInfoWa,
          loyolaInfoYoutubeUrl: dataHeadFoot.data.footUrlYoutube,
          loyolaInfoIg: dataHeadFoot.data.footIg,
          loyolaInfoEmail: dataHeadFoot.data.footInfoEmail,
          loyolaInfoIgUrl: dataHeadFoot.data.footUrlIg,
          loyolaInfoTelpSatu: dataHeadFoot.data.footTelp1,
          loyolaInfoTelpDua: dataHeadFoot.data.footTelp2,
          loyolaInfoAddress: dataHeadFoot.data.footAddress,
          loyolaInfoPostalCode: dataHeadFoot.data.footPostalCode,
          loyolaInfoLong: dataHeadFoot.data.footLang,
          loyolaInfoLat: dataHeadFoot.data.footLat
        }
        this.handleGetProvince(dataHeadFoot.data.footCountryId)
        this.handleGetCity(dataHeadFoot.data.footProvinceId)
        this.formRef.current.setFieldsValue(temp)
      }
  }

  handleChange = (e, name) => {
    let temp = {}

    if (name === 'country') {
      this.handleGetProvince(e)
      temp = {
        provinceId: undefined,
        cityId: undefined
      }
    }else if (name === 'provinsi') {
      this.handleGetCity(e)
      temp = {
        cityId: undefined
      }
    }
    this.formRef.current.setFieldsValue(temp)
  }

  handleGetProvince = id => {
    this.props.handleProvince(id)
  }

  handleGetCity = id => {
    this.props.handleCity(id)
  }

  fileHandler = (event) => {
    if (event.target.files.length) {
      const file = event.target.files;
      const formData = new FormData();
      for (let i = 0; i < file.length; i++) {
        formData.append(`file`, file[i])
      }
      this.uploadAssignmentLetter(formData);
    }
  };

  uploadAssignmentLetter = (data) => {
    const { name } = this.state
    this.setLoadingUpload(true, name);
    let url = ''
    if (name === 'loyola') {
      url = 'uploadLogoLoyola'
    }else {
      url = 'uploadLogoBidikti'
    }
    post(`${BASE_URL_LOGIN}be/api/${url}`, data,  {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.token}`,
      },
      timeout: TIMEOUT
    }).then( res => {
      if ( res && res.status ? res.status === 200 : false ) {
        if ( res.data.Status === 'ACCEPTED' ) {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: 'Upload Logo Success',
          });
        }
      this.setLoadingUpload(false, name);
      }
    }).catch(() => {
      notification.error({
        placement: 'bottomRight',
        message: 'Error',
        description: 'Upload Logo Failed'
      });
      this.setLoadingUpload(false, name);
    });
  }

  setLoadingUpload = (val, name) => {
    if (name === 'loyola') {
      this.setState({
        loadingLoyola: val
      })
    }else {
      this.setState({
        loadingBidikti: val
      })
    }
  }

  openFileBrowser = (name) => {
    this.setState({
      name: name
    })
    fileInput.current.click();
  };

  onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  checkFinish = val => {
    const body = {
      ...val,
      cityId: {
        cityId: val.cityId
      },
      provinceId: {
        provinceId: val.provinceId
      },
      countryId: {
        countryId: val.countryId
      },
    }
    this.props.handleEditData(this.props.token, this.props.userId, body).then(res => {
      if (res.payload.status !== 'error') {
        if (res.payload.data.data.Status === 'OK') {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Update Header Footer Success',
          });
          this.setState({
            loadingSubmit: false
          })
          this.props.handleData()
        }else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Update Header Footer Failed'
          });
          this.setState({
            loadingSubmit: false
          })
        }
      }else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: 'Update Header Footer Failed'
        });
        this.setState({
          loadingSubmit: false
        })
      }
    })
  }


  render() {
    const { loadingSubmit } = this.state
    const { dataHeadFoot, dataCountry, dataProvince, dataCity } = this.props
    return(
      <div className='root'>
        <Breadcrumb className="breadcrumb_style" >
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/dashboard`)}>Pengaturan Umum </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className='pathNow_style'>
            Header & Footer
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card
          loading={dataHeadFoot.status === 'loading'}
          className='bodyCard_style'
        >
          <Row gutter={[10, 8]}>
            <Col span={12}>
              <Col span={24}>
                <span className='headText'>
                  Header
                </span>
              </Col>
              <Col span={24} style={{marginTop: '20px'}}>
                <Row gutter={[ 0, 16]}>
                  <Col span={this.props.isMobile ? 24 :12} >
                    <span className="mandatory">* </span>
                    <span className='logoInstitusi'>
                      Logo Loyola
                    </span>
                  </Col>
                  <Col span={this.props.isMobile ? 24 :12}>
                    <Col span={24}>
                      <ImagePreviewComponent
                        isMobile={this.props.isMobile}
                        styles={{ width: this.props.isMobile ? '100%' : 110 }}
                        uri={dataHeadFoot.data && dataHeadFoot.data.headLogoLoyolaPath}
                      />
                      {/* <img width={110} src={ dataHeadFoot.data && dataHeadFoot.data.headLogoLoyolaPath} alt={'logoLyl'}/> */}
                    </Col>
                    <Col span={24}>
                      <input
                        type="file"
                        hidden
                        onChange={event => this.fileHandler(event)}
                        ref={fileInput}
                      />
                      {/* <Button
                        onClick={() => this.openFileBrowser('loyola')}
                        type="primary"
                        className='buttonUpload'
                        loading={loadingLoyola}
                      >
                        Upload
                      </Button> */}
                    </Col>
                    </Col>
                  <Col span={this.props.isMobile ? 24 : 12}>
                    <span className="mandatory">* </span>
                    <span className='logoInstitusi'>
                      Logo BIDikTi
                    </span>
                  </Col>
                  <Col span={this.props.isMobile ? 24 :12}>
                    <Col span={24}>
                      <ImagePreviewComponent
                        isMobile={this.props.isMobile}
                        styles={{ width: this.props.isMobile ? '100%' : 110 }}
                        uri={dataHeadFoot.data && dataHeadFoot.data.footLogoBidiktiPath}
                      />
                      {/* <img width={110} src={ dataHeadFoot.data && dataHeadFoot.data.footLogoBidiktiPath} alt={'logoLyl'}/> */}
                    </Col>
                    <Col span={24}>
                      <input
                        type="file"
                        hidden
                        onChange={event => this.fileHandler(event)}
                        ref={fileInput}
                      />
                      {/* <Button
                        onClick={() => this.openFileBrowser('bidikti')}
                        type="primary"
                        className='buttonUpload'
                        loading={loadingBidikti}
                      >
                        Upload
                      </Button> */}
                    </Col>
                  </Col>
                </Row>
              </Col>
            </Col>
            <Col span={12}>
              <Col span={24}>
                <span className='headText'>
                  Footer
                </span>
              </Col>
              <Col span={24} style={{marginTop: '20px'}}>
                <Form
                  onFinish={values => this.checkFinish(values)}
                  onFinishFailed={event => this.onFinishFailed(event)}
                  ref={this.formRef}
                >
                  <Row gutter={this.props.isMobile ? [4,16] : [10, 16]}>
                    <Col span={23}>
                      <Form.Item
                        name='loyolaInfoLogoName'
                        rules={[{
                          required: true,
                          message: 'Please Input Institution Name!'
                        }]}
                      >
                        <Input
                          maxLength={256}
                          size="large"
                          className='input_style'
                          placeholder={"Nama Institusi"}/>
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <span className="mandatoryLarge">&#8727;</span>
                    </Col>
                    <Col span={23}>
                      <Form.Item
                        name='loyolaInfoBidiktiName'
                        rules={[{
                          required: true,
                          message: 'Please Input BIDikTi Name!'
                        }]}
                      >
                        <Input
                          maxLength={256}
                          size="large"
                          className='input_style'
                          placeholder={"Nama BIDikTi"}/>
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <span className="mandatoryLarge">&#8727;</span>
                    </Col>
                    <Col span={this.props.isMobile ? 23 : 11}>
                      <Form.Item
                        name='loyolaInfoWa'
                        rules={[{
                          required: true,
                          message: 'Please Input WhatsApp Number!'
                        }]}
                      >
                        <Input
                          maxLength={15}
                          size="large"
                          className='input_style'
                          placeholder={"WhatsApp"}/>
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <span className="mandatoryLarge">&#8727;</span>
                    </Col>
                    <Col span={this.props.isMobile ? 23 :11}>
                      <Form.Item
                        name='loyolaInfoYoutubeUrl'
                        rules={[{
                          required: true,
                          message: 'Please Input Youtube Channel!'
                        }]}
                      >
                        <Input
                          size="large"
                          className='input_style'
                          placeholder={"Youtube"}/>
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <span className="mandatoryLarge">&#8727;</span>
                    </Col>
                    <Col span={this.props.isMobile ? 23 : 11}>
                      <Form.Item
                        name='loyolaInfoIg'
                        rules={[{
                          required: true,
                          message: 'Please Input Instagram ID!'
                        }]}
                      >
                        <Input
                          maxLength={256}
                          size="large"
                          className='input_style'
                          placeholder={"Instagram ID"}/>
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <span className="mandatoryLarge">&#8727;</span>
                    </Col>
                    <Col span={this.props.isMobile ? 23 : 11}>
                      <Form.Item
                        name='loyolaInfoEmail'
                        rules={[{
                          required: true,
                          message: 'Please Input Email!'
                        }]}
                      >
                        <Input
                          maxLength={125}
                          size="large"
                          className='input_style'
                          placeholder={"Email"}/>
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <span className="mandatoryLarge">&#8727;</span>
                    </Col>
                    <Col span={23}>
                      <Form.Item
                        name='loyolaInfoIgUrl'
                        rules={[{
                          required: true,
                          message: 'Please Input Instagram URL!'
                        }]}
                      >
                        <Input
                          maxLength={256}
                          size="large"
                          className='input_style'
                          placeholder={"Instagram URL"}/>
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <span className="mandatoryLarge">&#8727;</span>
                    </Col>
                    <Col span={this.props.isMobile ? 23 : 11}>
                      <Form.Item
                        name='loyolaInfoTelpSatu'
                        rules={[{
                          required: true,
                          message: 'Please Input Phone Number!'
                        }]}
                      >
                        <Input
                          maxLength={16}
                          size="large"
                          className='input_style'
                          placeholder={"No. Telepon 1"}/>
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <span className="mandatoryLarge">&#8727;</span>
                    </Col>
                    <Col span={this.props.isMobile ? 23 : 11}>
                      <Form.Item
                        name='loyolaInfoTelpDua'
                        rules={[{
                          required: true,
                          message: 'Please Input Phone Number!'
                        }]}
                      >
                        <Input
                          maxLength={16}
                          size="large"
                          className='input_style'
                          placeholder={"No. Telepon 2"}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <span className="mandatoryLarge">&#8727;</span>
                    </Col>
                    <Col span={23}>
                      <Form.Item
                        name='loyolaInfoAddress'
                        rules={[{
                          required: true,
                          message: 'Please Input Address!'
                        }]}
                      >
                        <Input.TextArea
                          maxLength={256}
                          rows={4}
                          size="large"
                          className='input_style'
                          placeholder={"Alamat"}/>
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <span className="mandatoryLarge">&#8727;</span>
                    </Col>
                    <Col span={this.props.isMobile ? 23 : 11}>
                      <Form.Item
                        name='countryId'
                        rules={[{
                          required: true,
                          message: 'Please Select Country!'
                        }]}
                      >
                       <Select
                          className="select"
                          placeholder="Negara"
                          onChange={e => this.handleChange(e, 'country')}
                        >
                          {
                            dataCountry ?
                            dataCountry.data.map( (item, index ) =>
                                (
                                <Option key={item.id + 'country' + index} value={item.id}>{item.name}</Option>
                                )
                              ) :
                              (
                                <></>
                              )
                          }
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <span className="mandatoryLarge">&#8727;</span>
                    </Col>
                    <Col span={this.props.isMobile ? 23 : 11}>
                      <Form.Item
                        name='provinceId'
                        rules={[{
                          required: true,
                          message: 'Please Select Province!'
                        }]}
                      >
                       <Select
                          className="select"
                          placeholder="Provinsi"
                          onChange={e => this.handleChange(e, 'provinsi')}
                        >
                          {
                            dataProvince ?
                            dataProvince.data.map( (item, index ) =>
                                (
                                <Option key={item.id + 'province' + index} value={item.id}>{item.name}</Option>
                                )
                              ) :
                              (
                                <></>
                              )
                          }
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <span className="mandatoryLarge">&#8727;</span>
                    </Col>
                    <Col span={this.props.isMobile ? 23 : 11}>
                      <Form.Item
                        name='cityId'
                        rules={[{
                          required: true,
                          message: 'Please Select City!'
                        }]}
                      >
                       <Select
                          className="select"
                          placeholder="Kota"
                          onChange={e => this.handleChange(e, 'kota')}
                        >
                          {
                            dataCity ?
                            dataCity.data.map( (item, index ) =>
                                (
                                <Option key={item.id + 'city' + index} value={item.id}>{item.name}</Option>
                                )
                              ) :
                              (
                                <></>
                              )
                          }
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <span className="mandatoryLarge">&#8727;</span>
                    </Col>
                    <Col span={this.props.isMobile ? 23 : 11}>
                      <Form.Item
                        name='loyolaInfoPostalCode'
                        rules={[{
                          required: true,
                          message: 'Please Input Postal Code!'
                        }]}
                      >
                        <Input
                          maxLength={15}
                          size="large"
                          className='input_style'
                          maxLength={15}
                          placeholder={"Kode Pos"}/>
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <span className="mandatoryLarge">&#8727;</span>
                    </Col>
                    <Col span={this.props.isMobile ? 23 : 11}>
                      <Form.Item
                        name='loyolaInfoLong'
                        rules={[{
                          required: true,
                          message: 'Please Input Longitude!'
                        }]}
                      >
                        <Input
                          size="large"
                          className='input_style'
                          placeholder={"Longitude"}/>
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <span className="mandatoryLarge">&#8727;</span>
                    </Col>
                    <Col span={this.props.isMobile ? 23 : 11}>
                      <Form.Item
                        name='loyolaInfoLat'
                        rules={[{
                          required: true,
                          message: 'Please Input Latitude!'
                        }]}
                      >
                        <Input
                          size="large"
                          className='input_style'
                          placeholder={"Latitude"}/>
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <span className="mandatoryLarge">&#8727;</span>
                    </Col>
                    <Col span={23}>
                      <Form.Item
                        style={{
                          textAlign: 'right',
                          marginBottom: '0px'
                        }}
                      >
                        <Button
                          type="primary"
                          htmlType='submit'
                          className='updateButton'
                          loading={loadingSubmit}
                          size={'small'}
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Col>
          </Row>
        </Card>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return{
    handleData: () => dispatch(headerFooterAction.getData()),
    handleCoutry: () => dispatch(lovAction.getLOVCountry()),
    handleProvince: id => dispatch(lovAction.getLOVProvince(id)),
    handleCity: id => dispatch(lovAction.getLOVCity(id)),
    handleEditData: (token, userId, body) => dispatch(headerFooterAction.handleEdit(token, userId, body))
  }
}

const mapStateToProps = (state) => {
  return {
    dataHeadFoot: state.headerFooter,
    dataCountry: state.lovCountry,
    dataProvince: state.lovProvince,
    dataCity: state.lovCity
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderFooter)
