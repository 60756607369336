import types from '../../constants'

const initialState = {
	status: 'loading',
	data: []
}

export default function getDashboardListUniversitas(state = initialState, action) {
	switch (action.type) {
    case types.GET_DASHBOARD_LIST_UNIVERSITAS_LOADING: 
    return {
      ...state,
      ...action.payload
    }
    case types.GET_DASHBOARD_LIST_UNIVERSITAS_SUCCESS: 
    return {
      isSuccess: true,
      ...state,
      ...action.payload
    }
    case types.GET_DASHBOARD_LIST_UNIVERSITAS_FAILED: 
    return {
      isSuccess: false,
      ...state,
      ...action.payload
    }
    default:
      return { ...initialState, ...state }
    }
}