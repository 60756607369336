import { get, put, post } from 'axios'
import config from '../services/config'
const { BASE_URL_LOGIN, TIMEOUT } = config

const getData = (param) => {
  return get(`${BASE_URL_LOGIN}be/api/faqListAdmin?sort=${param.sort}&size=${param.size}&page=${param.page - 1}&search=${param.search}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${param.token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.Data.map(res =>{
      return {
        faqCategoryName: res.faqCategoryName,
        faqId: res.faqId,
        faqQuestionInd: res.faqQuestionInd,
        faqQuestionEng: res.faqQuestionEng,
        faqAnswerInd: res.faqAnswerInd,
        faqAnswerEng: res.faqAnswerEng,
        faqCategoryId: res.faqCategoryId,
        id: res.faqId
      }
    })
    return {
      data: normalizeData,
      totalData: response.data.TotalData ? response.data.TotalData : 0,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      totalData: 0,
      error,
      data: []
    }
  })
}

const getDataDetail = (token, id) => {
  return get(`${BASE_URL_LOGIN}be/api/faqDetailAdmin?faqId=${id}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const data = response.data ? response.data.Data : undefined
    const normalizeData = 
      {
        faqAnswerEng: data ? data.faqAnswerEng : '',
        faqAnswerInd: data ? data.faqAnswerInd : '',
        faqCategoryId: data ? data.faqCategoryId : '',
        faqCategoryName: data ? data.faqCategoryName : '',
        faqId: data ? data.faqId : '',
        faqQuestionEng: data ? data.faqQuestionEng : '',
        faqQuestionInd: data ? data.faqQuestionInd : '',
      }
    return {
      data: normalizeData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: undefined
    }
  })
}

const createFAQ = (token, userId, body) => {
  return post(`${BASE_URL_LOGIN}be/api/faqAdmin?usersId=${userId}`,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: '',
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: [],
    }
  })
}

const editFAQ = (token, userId, id, body) => {
  return put(`${BASE_URL_LOGIN}be/api/updateFaqAdmin?usersId=${userId}&faqId=${id}`,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: '',
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: [],
    }
  })
}


const deleteFAQ = (token, userId, id) => {
  return put(`${BASE_URL_LOGIN}be/api/deleteFaqAdmin?usersId=${userId}&faqId=${id}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: '',
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: [],
    }
  })
}

export default {
  getData,
  getDataDetail,
  createFAQ,
  editFAQ,
  deleteFAQ,
}