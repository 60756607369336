import React from 'react';
import { connect } from 'react-redux';
import { Card, Row, Col, Typography, Divider, Select, Empty, Spin, Popover } from 'antd';
import lov from "../../actions/lov";
import dashboardUniversitas from "../../actions/dashboardUniversitas";
import { InfoCircleOutlined } from '@ant-design/icons';

const UniversitasDashboard = (props) => {
  const [loading, setLoading] = React.useState(false);
  const infoDetail = (
    <div>
      <span>{`Jumlah Pengunjung Profil Universitas`}</span>
    </div>
  )

  React.useEffect(() => {
    props.handleGetLOVUniversitasCategori(props.token);
    getListUniversitas(props.token, 1);
  }, []);

  const getListUniversitas = async (token, id) => {
    setLoading(true);
    await props.handleGetDashboardListUniversitas(token, id).then(res => {
      setLoading(false);
    }, (error) => {
      console.log('Error : ', error);
      setLoading(false);
    })
  }

  const handleChange = (value) => {
    getListUniversitas(props.token, value);
  }

  const { listUniversitas } = props;
  return (
    <Row gutter={[24, 0]} style={{ height: '100%' }}>
      <Col span={24} style={{ textAlign: 'center' }}>
        <Card className="bodyCard_style">
          <Row gutter={[24, 12]}>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Typography.Title level={4}>{`Universitas`}</Typography.Title>
              <Typography.Text className="dashboardText">{listUniversitas.data.eventPeriod || `-`}</Typography.Text>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[24, 12]}>
            <Col offset={3} span={18}>
              <Select
                placeholder={"Jenis Universitas"}
                className="select"
                onChange={handleChange}
                bordered={false}
                style={{ borderBottom: '1px solid #072a6f' }}
                defaultValue={1}
              >
                {
                  props.dataLOVUniversitasCategori.data &&
                  props.dataLOVUniversitasCategori.data.map((item) => (
                    <Select.Option key={item.id} value={item.id}>{item.univCatName}</Select.Option>
                  ))
                }
              </Select>
            </Col>
          </Row>
          <br />
          <Spin spinning={loading}>
            <Row gutter={[24, 12]}>
              <Col span={24}>
                <table style={{ width: '100%', borderBottom: '2px solid #072a6f' }}>
                  <thead style={{ borderBottom: '2px solid #072a6f' }}>
                    <tr>
                      <th style={{ textAlign: 'left', color: '#072a6f', padding: '3px 0 3px' }}>{`Universitas`}</th>
                      <th style={{ textAlign: 'right', color: '#072a6f', padding: '3px 0 3px' }}>{`Jumlah Acara Aktif`}</th>
                      <th style={{ textAlign: 'right', color: '#072a6f', padding: '3px 0 3px' }}>{`Dilihat`} <Popover content={infoDetail}><InfoCircleOutlined /></Popover></th>
                    </tr>
                  </thead>
                  <tbody>
                    {listUniversitas.data.listDataUniv ? listUniversitas.data.listDataUniv.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td style={{ textAlign: 'left', verticalAlign: 'top', padding: '3px 0 3px' }}>{item.univName}</td>
                          <td style={{ textAlign: 'right', verticalAlign: 'top', padding: '3px 0 3px' }}>{item.univEventActive}</td>
                          <td style={{ textAlign: 'right', verticalAlign: 'top', padding: '3px 0 3px' }}>{item.numberOfViews}</td>
                        </tr>
                      )
                    })
                      :
                      <tr>
                        <td colSpan={3}>
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </Col>
            </Row>
          </Spin>
        </Card>
      </Col>
    </Row>
  )
}

const mapStateToProps = (state) => {
  return {
    dataLOVUniversitasCategori: state.lovUniversitasCategori,
    listUniversitas: state.getDashboardListUniversitas,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    handleGetLOVUniversitasCategori: (token) => dispatch(lov.getLOVUniversitasCategori(token)),
    handleGetDashboardListUniversitas: (token, id) => dispatch(dashboardUniversitas.getDashboardListUniversitas(token, id)),
  }
}

export const UIUniversitasDashboard = connect(mapStateToProps, mapDispatchToProps)(UniversitasDashboard)
