import React from "react";
import { Row, Col, Button, Input, Radio, DatePicker, Select, Form } from "antd";
import Moment from "moment";

const { TextArea } = Input;

const DetailContentAdminCore = (props) => {
  const dataDetail = props.dataDetail;
  let tempDate = Moment(dataDetail.adminDateofBirth);

  const [form] = Form.useForm();

  const nama = dataDetail.adminName;
  const identitas = dataDetail.adminIdentity;
  const address = dataDetail.adminAddress;
  const gender = dataDetail.adminGenderCode;
  const birth = tempDate;
  const email = dataDetail.adminEmail;
  const phone = dataDetail.adminPhone;
  const lastEduCode = dataDetail.adminLastEduCode;
  const lastEduPlace = dataDetail.adminLastEduPlace;
  const position = dataDetail.adminPosition;
  const roleId = dataDetail.adminRoleId;
  const username = dataDetail.adminUserName;
  const statusCode = dataDetail.adminStatusCode;

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (value) => {
    const tempDate = Moment(value.adminBirthDate).format("YYYY-MM-DD");
    const finishBody = {
      adminName: value.adminName,
      adminIdentity: value.adminIdentity,
      roleId: value.adminRole,
      adminGender: value.adminGender,
      adminDateOfBirth: tempDate,
      adminPosition: value.adminPosition,
      adminPhone: value.adminPhone,
      adminAddress: value.adminAddress,
      adminLastEdu: value.adminLastEdu,
      adminLastPlaceOfEdu: value.adminEduPlace,
      adminEmail: value.adminEmail,
      adminUsername: value.adminUsername,
      usersId: sessionStorage.getItem("userId"),
      isActive: value.adminStatus,
    };
    props.handleFinish(finishBody);
  };

  return (
    <>
      {props.isForm ? (
        <Form
          form={form}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            adminName: nama,
            adminIdentity: identitas,
            adminAddress: address,
            adminGender: gender,
            adminBirthDate: birth,
            adminEmail: email,
            adminPhone: phone,
            adminLastEdu: lastEduCode,
            adminEduPlace: lastEduPlace,
            adminPosition: position,
            adminRole: roleId,
            adminUsername: username,
            adminStatus: statusCode,
          }}
        >
          <Row gutter={[10, 5]}>
            <Col span={12}>
              <Col span={24}>
                <span className="mandatory">* </span>
                <span className="data_text_detail">Nama Lengkap</span>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="adminName"
                  style={{ marginBottom: 0 }}
                  rules={[{ required: true, message: "Input Full Name!" }]}
                >
                  <Input
                    maxLength={256}
                    size="large"
                    className="input_style"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <span className="mandatory">* </span>
                <span className="data_text_detail">Nomor Identitas / KTP</span>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="adminIdentity"
                  style={{ marginBottom: 0 }}
                  rules={[
                    {
                      required: true,
                      message: "Input Admin Identity Number!"
                    },
                    {
                      pattern: /^[0-9]*$/gm,
                      message: `Please Input identity number correctly`,
                    },
                  ]}
                >
                  <Input
                    maxLength={16}
                    size="large"
                    className="input_style"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <span className="mandatory">* </span>
                <span className="data_text_detail">Alamat</span>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="adminAddress"
                  style={{ marginBottom: 0 }}
                  rules={[{ required: true, message: "Input Admin Address!" }]}
                >
                  <TextArea
                    className="input_style"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="adminGender"
                  rules={[
                    { required: true, message: "please choose one!" },
                  ]}
                  style={{ marginBottom: 0 }}
                >
                  <Radio.Group
                    className="radio_style"
                  >
                    <span
                      className="logoInstitusi"
                      style={{ marginRight: "10px", fontWeight: "700" }}
                    >
                      <span className="mandatory">* </span>
                    Gender:{" "}
                    </span>
                    <br />
                    <Radio value={1}>Pria</Radio>
                    <Radio value={2}>Wanita</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={24}>
                <span className="mandatory">* </span>
                <span className="data_text_detail">Tanggal lahir</span>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="adminBirthDate"
                  style={{ marginBottom: 0 }}
                  rules={[{ required: true, message: "Input Admin Birth date!" }]}
                >
                  <DatePicker
                    className="input_style"
                    allowClear={false}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <span className="mandatory">* </span>
                <span className="data_text_detail">Email</span>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="adminEmail"
                  style={{ marginBottom: 0 }}
                  rules={[
                    {
                      required: true,
                      message: "Please Input Admin Email!",
                    },
                    {
                      type: "email",
                      message: "Please Input a valid email!",
                    },
                  ]}
                >
                  <Input
                    maxLength={50}
                    size="large"
                    className="input_style"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <span className="mandatory">* </span>
                <span className="data_text_detail">No. Telp</span>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="adminPhone"
                  style={{ marginBottom: 0 }}
                  rules={[
                    {
                      required: true,
                      message: "Please Input phone number!",
                    },
                    {
                      pattern: /^[0-9]*$/gm,
                      message: `please input correctly`,
                    },
                    {
                      min: 10,
                      message: "Phone number must be minimum 10 characters.",
                    },
                  ]}
                >
                  <Input
                    maxLength={15}
                    size="large"
                    className="input_style"
                  />
                </Form.Item>
              </Col>
            </Col>
            <Col span={12}>
              <Col span={24}>
                <span className="mandatory">* </span>
                <span className="data_text_detail">Pendidikan Terakhir</span>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="adminLastEdu"
                  style={{ marginBottom: 0 }}
                  rules={[
                    { required: true, message: "Please Select one!" },
                  ]}
                >
                  <Select
                    className="select"
                  >
                    <Select.Option value={1}>SD</Select.Option>
                    <Select.Option value={2}>SMP</Select.Option>
                    <Select.Option value={3}>SMA</Select.Option>
                    <Select.Option value={4}>D3</Select.Option>
                    <Select.Option value={5}>D4</Select.Option>
                    <Select.Option value={6}>S1</Select.Option>
                    <Select.Option value={7}>S2</Select.Option>
                    <Select.Option value={8}>S3</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <span className="mandatory">* </span>
                <span className="data_text_detail">
                  Tempat Pendidikan Terakhir
                </span>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="adminEduPlace"
                  style={{ marginBottom: 0 }}
                  rules={[
                    { required: true, message: "Please input this section!" },
                  ]}
                >
                  <Input
                    maxLength={256}
                    size="large"
                    className="input_style"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <span className="mandatory">* </span>
                <span className="data_text_detail">Jabatan</span>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="adminPosition"
                  style={{ marginBottom: 0 }}
                  rules={[
                    { required: true, message: "Please input this section!" },
                  ]}
                >
                  <Input
                    maxLength={256}
                    size="large"
                    className="input_style"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <span className="mandatory">* </span>
                <span className="data_text_detail">Role</span>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="adminRole"
                  style={{ marginBottom: 0 }}
                  rules={[
                    { required: true, message: "Please select this section!" },
                  ]}
                >
                  <Select
                    className="select"
                  >
                    <Select.Option value={8}>Super Admin</Select.Option>
                    <Select.Option value={9}>Admin Loyola (PIBSL)</Select.Option>
                    <Select.Option value={10}>
                      Eksekutif Yayasan & Sekolah
                  </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <span className="mandatory">* </span>
                <span className="data_text_detail">Username</span>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="adminUsername"
                  style={{ marginBottom: 0 }}
                  rules={[
                    { required: true, message: "Please input this section!" },
                  ]}
                >
                  <Input
                    maxLength={15}
                    size="large"
                    className="input_style"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <span className="mandatory">* </span>
                <span className="data_text_detail">Status</span>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="adminStatus"
                  style={{ marginBottom: 0 }}
                  rules={[
                    { required: true, message: "Please select this section!" },
                  ]}
                >
                  <Select
                    className="select"
                  >
                    <Select.Option value={false}>Active</Select.Option>
                    <Select.Option value={true}>Non-Active</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Col>
            <Col span={22} style={{ textAlign: "right" }}>
              <Button
                className="updateButton"
                htmlType="submit"
                loading={props.loadingPost}
              >
                Update user
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
          <Row gutter={[0, 10]}>
            <Col span={12}>
              <Col span={24}>
                <span className="header_text_detail">Nama Lengkap</span>
              </Col>
              <Col span={24}>
                <span className="text_detail_institusi">
                  {dataDetail.adminName ? dataDetail.adminName : "-"}
                </span>
              </Col>
              <Col span={24}>
                <span className="header_text_detail">
                  Nomor Identitas / KTP
              </span>
              </Col>
              <Col span={24}>
                <span className="data_text_detail">
                  {dataDetail.adminIdentity ? dataDetail.adminIdentity : "-"}
                </span>
              </Col>
              <Col span={24}>
                <span className="header_text_detail">Alamat</span>
              </Col>
              <Col span={24}>
                <span className="data_text_detail">
                  {dataDetail.adminAddress ? dataDetail.adminAddress : "-"}
                </span>
              </Col>
              <Col span={24}>
                <span className="header_text_detail">Jenis Kelamin</span>
              </Col>
              <Col span={24}>
                <span className="data_text_detail">
                  {dataDetail.adminGender ? dataDetail.adminGender : "-"}
                </span>
              </Col>
              <Col span={24}>
                <span className="header_text_detail">Tanggal Lahir</span>
              </Col>
              <Col span={24}>
                <span className="data_text_detail">
                  {dataDetail.adminDateofBirth
                    ? dataDetail.adminDateofBirth
                    : "-"}
                </span>
              </Col>
              <Col span={24}>
                <span className="header_text_detail">Email</span>
              </Col>
              <Col span={24}>
                <span className="data_text_detail">
                  {dataDetail.adminEmail ? dataDetail.adminEmail : "-"}
                </span>
              </Col>
              <Col span={24}>
                <span className="header_text_detail">No. Telp</span>
              </Col>
              <Col span={24}>
                <span className="data_text_detail">
                  {dataDetail.adminPhone ? dataDetail.adminPhone : "-"}
                </span>
              </Col>
            </Col>
            <Col span={12}>
              <Col span={24}>
                <span className="header_text_detail">Pendidikan Terakhir</span>
              </Col>
              <Col span={24}>
                <span className="data_text_detail">
                  {dataDetail.adminLastEdu ? dataDetail.adminLastEdu : "-"}
                </span>
              </Col>
              <Col span={24}>
                <span className="header_text_detail">
                  Tempat Pendidikan Terakhir
              </span>
              </Col>
              <Col span={24}>
                <span className="data_text_detail">
                  {dataDetail.adminLastEduPlace
                    ? dataDetail.adminLastEduPlace
                    : "-"}
                </span>
              </Col>
              <Col span={24}>
                <span className="header_text_detail">Jabatan</span>
              </Col>
              <Col span={24}>
                <span className="data_text_detail">
                  {dataDetail.adminPosition ? dataDetail.adminPosition : "-"}
                </span>
              </Col>
              <Col span={24}>
                <span className="header_text_detail">Role</span>
              </Col>
              <Col span={24}>
                <span className="data_text_detail">
                  {dataDetail.adminRoleName ? dataDetail.adminRoleName : "-"}
                </span>
              </Col>
              <Col span={24}>
                <span className="header_text_detail">Username</span>
              </Col>
              <Col span={24}>
                <span className="data_text_detail">
                  {dataDetail.adminUserName ? dataDetail.adminUserName : "-"}
                </span>
              </Col>
              <Col span={24}>
                <span className="header_text_detail">Status</span>
              </Col>
              <Col span={24}>
                <span className="data_text_detail">
                  {dataDetail.adminStatus ? dataDetail.adminStatus : "-"}
                </span>
              </Col>
            </Col>
          </Row>
        )}
    </>
  );
};

export const UIContentDetailAdmin = DetailContentAdminCore;
