import types from '../../constants'

const initialState = {
  status: '',
  data: []
}

export default function sendMailNews(state = initialState, action) {
  switch (action.type) {
    case types.SEND_MAIL_NEWS_LOADING:
      return {
        ...state,
        ...action.payload
      }
    case types.SEND_MAIL_NEWS_SUCCESS:
      return {
        isSuccess: true,
        ...state,
        ...action.payload
      }
    case types.SEND_MAIL_NEWS_FAILED:
      return {
        isSuccess: false,
        ...state,
        ...action.payload
      }
    default:
      return { ...initialState, ...state }
  }
}
