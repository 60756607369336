import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../global/global.css'
import { Row, Col, Card, Breadcrumb, Button, notification, Popconfirm } from 'antd'
import beasiswa from '../../actions/beasiswa'
import WhatsappLogo from '../../global/assets/whatsapp.svg';
import MailLogo from '../../global/assets/email.svg';
import moment from 'moment';

class ListBeasiswaDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: '',
      type: ''
    }
  }

  componentDidMount() {
    let id = this.props.location.pathname.split('/')[2]
    this.setState({
      id: id
    })
    this.props.handleDataDetail(this.props.token, id)
  }

  handleApproval = (type) => {
    this.setState({
      loading: true,
      type
    })
    this.props[type === 'Approve' ? 'handleApproveBeasiswa' : 'handleRejectBeasiswa'](this.props.token, this.state.id).then(res => {
      if (res.payload.status !== 'error') {
        if (res.payload.data.status === 200) {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: `${type} Beasiswa Success`,
          });
          this.setState({
            loading: false
          }, () => {
            this.props.history.push(`/beasiswa/`)
          })
        } else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: `${type} Beasiswa Failed`
          });
          this.setState({
            loading: false
          })
        }
      } else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: `${type} Beasiswa Failed`
        });
        this.setState({
          loading: false
        })
      }
    })
  }

  render() {
    const { dataDetail, detailStatus, loadingDetail } = this.props;
    return (
      <div className='root'>
        <Breadcrumb className="breadcrumb_style" >
          <Breadcrumb.Item>
            <span style={{ cursor: 'pointer' }} onClick={() => this.props.history.push(`/beasiswa`)}>Beasiswa </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className='pathNow_style'>
            Detail Beasiswa
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card loading={loadingDetail === 'loading'} className='bodyCard_style'>
          {dataDetail && detailStatus &&
            <Row gutter={[0, 10]}>
              <Col span={12}>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Nama Beasiswa
                </span>
                </Col>
                <Col span={24}>
                  <span className='text_detail_institusi'>
                    {dataDetail.scholarshipName ? dataDetail.scholarshipName : '-'}
                  </span>
                </Col>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Nama Lembaga Pemberi Beasiswa
                </span>
                </Col>
                <Col span={24}>
                  <span className='data_text_detail'>
                    {dataDetail.scholarshipProvider ? dataDetail.scholarshipProvider : '-'}
                  </span>
                </Col>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Tautan Pendaftaran Beasiswa
                  </span>
                </Col>
                <Col span={24}>
                  <span className='text_detail_institusi'>
                    {dataDetail.scholarshipOnlineRegisUrl ? (<a href={dataDetail.scholarshipOnlineRegisUrl} className="text_detail_link_jalur_masuk" target="_blank" rel="noopener noreferrer">{dataDetail.scholarshipOnlineRegisUrl}</a>) : "-"}
                  </span>
                </Col>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Domisili Pemberi Beasiswa
                </span>
                </Col>
                <Col span={24}>
                  <pre className='data_text_detail' style={{whiteSpace: "pre-wrap"}}>
                    {dataDetail.scholarshipAddress ? dataDetail.scholarshipAddress.replace('\\n', '\r\n') : '-'}
                  </pre>
                </Col>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Kategori
                </span>
                </Col>
                <Col span={24}>
                  <span className='data_text_detail'>
                    {dataDetail.scholarshipProviderCategory ? dataDetail.scholarshipProviderCategory : '-'}
                  </span>
                </Col>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Status Pemberi Beasiswa
                </span>
                </Col>
                <Col span={24}>
                  <span className='data_text_detail'>
                    {dataDetail.scholarshipKeklStatus ? dataDetail.scholarshipKeklStatus : '-'}
                  </span>
                </Col>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Email
                </span>
                </Col>
                <Col span={24}>
                  <span className='data_text_detail'>
                    {dataDetail.scholarshipProviderEmail ? dataDetail.scholarshipProviderEmail : '-'}
                  </span>
                </Col>
                <Col span={24}>
                  <span className='header_text_detail'>
                    No. Telepon
                </span>
                </Col>
                <Col span={24}>
                  <span className='data_text_detail'>
                    {dataDetail.scholarshipProviderPhone ? dataDetail.scholarshipProviderPhone : '-'}
                  </span>
                </Col>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Beasiswa Diperuntukan Bagi
                </span>
                </Col>
                <Col span={24}>
                  {dataDetail.scholarshipFor.length > 0 ? dataDetail.scholarshipFor.map((item, index) => {
                    return (
                      <>
                        <span className='data_text_detail'>
                          - {item.roleName}
                        </span><br />
                      </>
                    )
                  })
                    :
                    <span className='data_text_detail'>
                      -
                  </span>
                  }
                </Col>
              </Col>
              <Col span={12}>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Nilai Beasiswa
                </span>
                </Col>
                <Col span={24}>
                  <span className='data_text_detail'>
                    {dataDetail.currencyName ? dataDetail.currencyName : '-'} {dataDetail.scholarshipValue ? Intl.NumberFormat(['ban', 'id']).format(dataDetail.scholarshipValue) : 0}
                  </span>
                </Col>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Sifat Beasiswa
                </span>
                </Col>
                <Col span={24}>
                  <span className='data_text_detail'>
                    {dataDetail.scholarshipCategory ? dataDetail.scholarshipCategory : '-'}
                  </span>
                </Col>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Keterangan
                </span>
                </Col>
                <Col span={24}>
                  <span className='data_text_detail'>
                    {dataDetail.scholarshipDesc ? dataDetail.scholarshipDesc : '-'}
                  </span>
                </Col>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Periode Pemberian Beasiswa
                </span>
                </Col>
                <Col span={24}>
                  <span className='data_text_detail'>
                    {dataDetail.scholarshipPeriod ? dataDetail.scholarshipPeriod : '-'}
                  </span>
                </Col>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Bentuk Beasiswa
                </span>
                </Col>
                <Col span={24}>
                  <span className='data_text_detail'>
                    {dataDetail.scholarshipFormat ? dataDetail.scholarshipFormat : '-'}
                  </span>
                </Col>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Persyaratan Kelayakan
                </span>
                </Col>
                <Col span={24}>
                  <pre className='data_text_detail' style={{whiteSpace: "pre-wrap"}}>
                    {dataDetail.scholarshipTerm ? dataDetail.scholarshipTerm.replace('\\n', '\r\n') : '-'}
                  </pre>
                </Col>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Cara Pendaftaran
                </span>
                </Col>
                <Col span={24}>
                  <pre className='data_text_detail' style={{whiteSpace: "pre-wrap"}}>
                    {dataDetail.scholarshipHowToRegister ? dataDetail.scholarshipHowToRegister.replace('\\n', '\r\n') : '-'}
                  </pre>
                </Col>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Batas Waktu Pendaftaran
                </span>
                </Col>
                <Col span={24}>
                  <span className='text_detail_institusi'>
                    {dataDetail.scholarshipDeadline ? moment(dataDetail.scholarshipDeadline).format('DD MMMM YYYY') : '-'}
                  </span>
                </Col>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Waktu Pengumuman
                </span>
                </Col>
                <Col span={24}>
                  <span className='text_detail_institusi'>
                    {dataDetail.scholarshipAnnouncement ? moment(dataDetail.scholarshipAnnouncement).format('DD MMMM YYYY') : '-'}
                  </span>
                </Col>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Tautan Pengumuman
                </span>
                </Col>
                <Col span={24}>
                  <span className='text_detail_institusi'>
                    {dataDetail.scholarshipAnnouncementUrl ? <a href={dataDetail.scholarshipAnnouncementUrl} className="text_detail_link_jalur_masuk" target="_blank" rel="noopener noreferrer">{dataDetail.scholarshipAnnouncementUrl}</a> : '-'}
                  </span>
                </Col>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Tautan Unduh Formulir
                </span>
                </Col>
                <Col span={24}>
                  <span className='text_detail_institusi'>
                    {dataDetail.scholarshipOffDownFormUrl ? <a href={dataDetail.scholarshipOffDownFormUrl} className="text_detail_link_jalur_masuk" target="_blank" rel="noopener noreferrer">{dataDetail.scholarshipOffDownFormUrl}</a> : '-'}
                  </span>
                </Col>
                <Col span={24}>
                  <span className='header_text_detail'>
                    Tautan Unggah Formulir
                </span>
                </Col>
                <Col span={24}>
                  <span className='text_detail_institusi'>
                    {dataDetail.scholarshipOnUploadFormUrl ? <a href={dataDetail.scholarshipOnUploadFormUrl} className="text_detail_link_jalur_masuk" target="_blank" rel="noopener noreferrer">{dataDetail.scholarshipOnUploadFormUrl}</a> : '-'}
                  </span>
                </Col>
                <br />
                <Col span={24}>
                  <span className='data_text_detail'>
                    Kontak Narahubung Pendaftaran
                </span>
                </Col>
                <Col span={24}>
                  <img src={WhatsappLogo} alt="Whatsapp logo" className="svg-content" />
                  <span className='data_text_detail' style={{ fontSize: 'unset' }}>
                    {dataDetail.scholarshipContactWa ? dataDetail.scholarshipContactWa : '-'}
                  </span>
                </Col>
                <Col span={24}>
                  <img src={MailLogo} alt="Mail logo" className="svg-content" />
                  <span className='data_text_detail' style={{ fontSize: 'unset' }}>
                    {dataDetail.scholarshipContactEmail ? dataDetail.scholarshipContactEmail : '-'}
                  </span>
                </Col>
                <br />
                {
                  (dataDetail.scholarshipApprovalStatus === 1) &&
                  <Col span={24}>
                    <Popconfirm
                      placement="bottomRight"
                      title={(
                        <span>
                          Apakah anda yakin untuk melakukan reject ?
                        </span>
                      )}
                      onConfirm={() => this.handleApproval('Reject')}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        loading={this.state.loading && this.state.type === 'Reject'}
                        // disabled={dataDetail.scholarshipApprovalStatus}
                        className='rejectButton'
                      >
                        Reject
                      </Button>
                    </Popconfirm>
                    <Popconfirm
                      placement="bottomRight"
                      title={(
                        <>
                          <span>
                            Apakah anda yakin untuk melakukan approval ?
                        </span>
                          <br></br>
                          <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                            <label style={{ color: 'red' }}>*</label>
                            {`Sebagai informasi proses approval akan mem-publish`}
                            <br></br>
                            {`informasi beasiswa ini di web user.`}
                          </span>
                        </>
                      )}
                      onConfirm={() => this.handleApproval('Approve')}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        loading={this.state.loading && this.state.type === 'Approve'}
                        // disabled={dataDetail.scholarshipApprovalStatus}
                        className='approveButton'
                      >
                        Approve
                      </Button>
                    </Popconfirm>
                  </Col>
                }
              </Col>
            </Row>
          }
        </Card>
      </div>
    )
  }
}
const mapDispatchToProps = dispatch => {
  return {
    handleDataDetail: (token, id) => dispatch(beasiswa.getDataDetailBeasiswa(token, id)),
    handleApproveBeasiswa: (token, id) => dispatch(beasiswa.approveDataBeasiswa(token, id)),
    handleRejectBeasiswa: (token, id) => dispatch(beasiswa.rejectDataBeasiswa(token, id)),
  }
}

const mapStateToProps = (state) => {
  return {
    loadingDetail: state.detailBeasiswa.status,
    dataDetail: state.detailBeasiswa.data.data,
    detailStatus: state.detailBeasiswa.data.status
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ListBeasiswaDetail)
