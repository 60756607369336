import React from "react";

import {
  Modal,
  Form,
  Row,
  Col,
  Typography,
  Input,
  Select,
  Button,
  DatePicker,
  Radio,
} from "antd";

import moment from "moment";

const { Title } = Typography;
const { TextArea } = Input;

const ModalCreateAdmin = (props) => {
  const [form] = Form.useForm();
  if (props.visibleRegis === false) {
    form.resetFields();
  }

  const handleResetForm = () => {
    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function onChangeDate(date, dateString) {
    // console.log(date, dateString);
  }

  function handleRadioChange(value) {
    // console.log(value);
  }

  const checkFinish = (values) => {
    props.onFinish(values);
  };

  function disableDate(current) {
    return current && current >= moment();
  }

  return (
    <Modal
      visible={props.visibleRegis}
      footer={null}
      className={props.isMobile ? 'modalMobile' : 'modalImage'}
      width={props.isMobile ? '100%' : 633}
      centered
      onCancel={props.close}
    >
      <Row>
        <Col span={24}>
          <div className="headerForm">
            <Title className="titleModalGalery" level={4}>
              {props.title}
            </Title>
          </div>
          <div className="dividerGalery" />
        </Col>
        <Col span={24}>
          <Form
            form={form}
            onFinish={(values) => checkFinish(values)}
            onFinishFailed={onFinishFailed}
          >
            <Row gutter={[10, 0]}>
              <Col span={23}>
                <Form.Item
                  name="nama"
                  rules={[
                    {
                      required: true,
                      message: "Please input full name!",
                    },
                  ]}
                >
                  <Input
                    maxLength={256}
                    size="large"
                    className="input_style"
                    placeholder={"Nama Lengkap"}
                  />
                </Form.Item>
              </Col>
              <Col span={1}>
                <span className="mandatoryLarge">&#8727;</span>
              </Col>
              <Col span={11}>
                <Form.Item
                  name="ktp"
                  rules={[
                    {
                      required: true,
                      message: "Please input KTP Number!",
                    },
                    {
                      pattern: /^[0-9]*$/gm,
                      message: `Please Input identity number correctly`,
                    },
                  ]}
                >
                  <Input
                    size="large"
                    maxLength={16}
                    className="input_style"
                    placeholder={"No. KTP"}
                  />
                </Form.Item>
              </Col>
              <Col span={1}>
                <span className="mandatoryLarge">&#8727;</span>
              </Col>
              <Col span={11}>
                <Form.Item
                  name="role"
                  rules={[
                    {
                      required: true,
                      message: "Please select role!",
                    },
                  ]}
                >
                  <Select className="select" placeholder="Role">
                    <Select.Option value={8}>Super Admin</Select.Option>
                    <Select.Option value={9}>
                      Admin Loyola (PIBSL)
                    </Select.Option>
                    <Select.Option value={10}>
                      Eksekutif Yayasan & Sekolah
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={1}>
                <span className="mandatoryLarge">&#8727;</span>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="gender"
                  rules={[
                    {
                      required: true,
                      message: "Please select gender!",
                    },
                  ]}
                >
                  <Radio.Group
                    className="radio_style"
                    onChange={(val) => handleRadioChange(val.target.value)}
                  >
                    <span
                      className="logoInstitusi"
                      style={{ marginRight: "10px", fontWeight: "700" }}
                    >
                      <span className="mandatory">* </span>
                      Gender:{" "}
                    </span>
                    <Radio value={1}>Pria</Radio>
                    <Radio value={2}>Wanita</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  name="date"
                  size="large"
                  rules={[
                    {
                      required: true,
                      message: "Please select date!",
                    },
                  ]}
                >
                  <DatePicker
                    onChange={onChangeDate}
                    placeholder={"Tanggal Lahir"}
                    style={{ width: "100%" }}
                    disabledDate={disableDate}
                  />
                </Form.Item>
              </Col>
              <Col span={1}>
                <span className="mandatoryLarge">&#8727;</span>
              </Col>
              <Col span={11}>
                <Form.Item
                  name="jabatan"
                  rules={[
                    {
                      required: true,
                      message: "Please input this section!",
                    },
                  ]}
                >
                  <Input
                    maxLength={256}
                    size="large"
                    className="input_style"
                    placeholder={"Jabatan"}
                  />
                </Form.Item>
              </Col>
              <Col span={1}>
                <span className="mandatoryLarge">&#8727;</span>
              </Col>
              <Col span={11}>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input this section!",
                    },
                    {
                      pattern: /^[0-9]*$/gm,
                      message: `please input correctly`,
                    },
                    {
                      min: 10,
                      message: "Phone number must be minimum 10 characters.",
                    },
                  ]}
                >
                  <Input
                    maxLength={15}
                    size="large"
                    className="input_style"
                    placeholder={"No. Telp"}
                  />
                </Form.Item>
              </Col>
              <Col span={1}>
                <span className="mandatoryLarge">&#8727;</span>
              </Col>
              <Col span={23}>
                <Form.Item
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: "Please input this section!",
                    },
                  ]}
                >
                  <TextArea
                    size="large"
                    className="input_style"
                    placeholder={"Deskripsi Alamat Lengkap"}
                  />
                </Form.Item>
              </Col>
              <Col span={1}>
                <span className="mandatoryLarge">&#8727;</span>
              </Col>
              <Col span={11}>
                <Form.Item
                  name="lastEducation"
                  rules={[
                    {
                      required: true,
                      message: "Please select last Education!",
                    },
                  ]}
                >
                  <Select className="select" placeholder="Pendidikan Terakhir">
                    <Select.Option value="1">SD</Select.Option>
                    <Select.Option value="2">SMP</Select.Option>
                    <Select.Option value="3">SMA</Select.Option>
                    <Select.Option value="4">D3</Select.Option>
                    <Select.Option value="5">D4</Select.Option>
                    <Select.Option value="6">S1</Select.Option>
                    <Select.Option value="7">S2</Select.Option>
                    <Select.Option value="8">S3</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={1}>
                <span className="mandatoryLarge">&#8727;</span>
              </Col>
              <Col span={11}>
                <Form.Item
                  name="lastEducationPlace"
                  rules={[
                    {
                      required: true,
                      message: "Please input this section!",
                    },
                  ]}
                >
                  <Input
                    maxLength={256}
                    size="large"
                    className="input_style"
                    placeholder={"Lembaga Pendidikan Terakhir"}
                  />
                </Form.Item>
              </Col>
              <Col span={1}>
                <span className="mandatoryLarge">&#8727;</span>
              </Col>
              <Col span={11}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input this section!",
                    },
                    {
                      type: "email",
                      message: "Please Input a valid email!",
                    },
                  ]}
                >
                  <Input
                    maxLength={50}
                    size="large"
                    className="input_style"
                    placeholder={"Email"}
                  />
                </Form.Item>
              </Col>
              <Col span={1}>
                <span className="mandatoryLarge">&#8727;</span>
              </Col>
              <Col span={11}>
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input this section!",
                    }
                  ]}
                >
                  <Input
                    maxLength={15}
                    size="large"
                    className="input_style"
                    placeholder={"Username"}
                  />
                </Form.Item>
              </Col>
              <Col span={1}>
                <span className="mandatoryLarge">&#8727;</span>
              </Col>
              <Col span={11}>
                <Form.Item
                  name="password"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please input this section!",
                    },
                    {
                      min: 5,
                      message: "Password can't less than 5.",
                    },
                  ]}
                >
                  <Input.Password
                    className="input_style"
                    placeholder={"Password"}
                  />
                </Form.Item>
              </Col>
              <Col span={1}>
                <span className="mandatoryLarge">&#8727;</span>
              </Col>
              <Col span={11}>
                <Form.Item
                  name=""
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please input this section!",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject("Password do not match");
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    className="input_style"
                    placeholder={"Confirm Password"}
                  />
                </Form.Item>
              </Col>
              <Col span={1}>
                <span className="mandatoryLarge">&#8727;</span>
              </Col>
              <Col span={24}>
                <Form.Item
                  style={{
                    textAlign: "right",
                    marginBottom: "0px",
                  }}
                >
                  <Button
                    type="link"
                    className="resetButton"
                    size={"small"}
                    onClick={() => handleResetForm()}
                  >
                    Reset
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="updateButton"
                    loading={props.loadingSubmit}
                    size={"small"}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export const UIModalCreateAdmin = ModalCreateAdmin;
