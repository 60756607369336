import types from "../constants";
import masterProvinsi from "../api/masterProvinsi";

const getData = param => dispatch => {
   dispatch({
      type: types.GET_PROVINSI_LOADING,
      payload: {
         data: [],
         totalData: 0,
         status: "loading",
         error: ""
      }
   })
   return masterProvinsi.getData(param).then( result => {
      if ( result.status === "error" ) {
         return dispatch({
            type: types.GET_PROVINSI_FAILED,
            payload: result
         });
      } else {
         return dispatch({
            type: types.GET_PROVINSI_SUCCESS,
            payload: result
         });
      }
   })
}

const createProvinsi = (token, body) => dispatch => {
   dispatch({
      type: types.CREATE_PROVINSI_LOADING,
      payload: {
         status: 'loading',
         error: '',
         data: []
      }
   })
   return masterProvinsi.createProvinsi(token, body).then(result => {
      if (result.status === "error") {
         return dispatch({
            type: types.CREATE_PROVINSI_FAILED,
            payload: result
         })
      } else {
         return dispatch({
            type: types.CREATE_PROVINSI_SUCCESS,
            payload: result
         })
      }
   })
}

const editProvinsi = (token, body, id) => dispatch => {
   dispatch({
      type: types.PUT_PROVINSI_LOADING,
      payload: {
         status: "loading",
         error: "",
         data: []
      }
   })
   return masterProvinsi.editProvinsi(token, body, id).then(result => {
      if (result.status === "error") {
         return dispatch({
            type: types.PUT_PROVINSI_FAILED,
            payload: result
         });
      } else {
         return dispatch({
            type: types.PUT_PROVINSI_SUCCESS,
            payload: result
         });
      }
   })
}

const deleteProvinsi = (token, body, id) => dispatch => {
   dispatch({
      type: types.DELETE_PROVINSI_LOADING,
      payload: {
         data: [],
         status: "loading",
         error: ""
      }
   })
   return masterProvinsi.deleteProvinsi(token, body, id).then(result => {
      if (result.status === "error") {
         return dispatch({
            type: types.DELETE_PROVINSI_FAILED,
            payload: result
         });
      } else {
         return dispatch({
            type: types.DELETE_PROVINSI_SUCCESS,
            payload: result
         });
      }
   })
}

export default {
   getData,
   createProvinsi,
   editProvinsi,
   deleteProvinsi
}