import {get, post, put} from "axios";
import config from "../services/config";

const {BASE_URL_LOGIN, TIMEOUT} = config;

const getData = param => {
  return get(`${BASE_URL_LOGIN}be/api/sharingGalleryListAdmin?sort=${param.sort}&page=${param.page - 1}&size=${param.size}&search=${param.search}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${param.token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.items.map(res => {
      return {
        id: res.sharingEventId,
        sharingEventSpeaker: res.sharingEventSpeaker,
        sharingTypeName: res.sharingType.sharingTypeName,
        sharingEventDay: res.sharingEventDay,
        sharingEventDate: res.sharingEventDate,
        sharingGalleryId: res.sharingGalleryId,
        isOnline: res.isOnline
      }
    })
    return {
      data: normalizeData,
      totalData: response.data.totalItems,
      status: "success",
      error: ""
    }
  }).catch(error => {
    return {
      data: [],
      totalData: 0,
      status: "error",
      error
    }
  })
}

const getDetail = (id, token) => {
  return get(`${BASE_URL_LOGIN}be/api/detailSharingGalleryAdmin/${id}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = {
      id: response.data.sharingEventId,
      sharingEventSpeaker: response.data.sharingEventSpeaker,
      sharingEventDesc: response.data.sharingEventDesc,
      detailSharingGallery: response.data.detailSharingGallery,
      sharingEventKeklGradYear: response.data.sharingEventKeklGradYear
    }
    return {
      data: normalizeData,
      status: "success",
      error: ""
    }
  }).catch(error => {
    return {
      data: [],
      status: "error",
      error
    }
  });
}

const postLifeSharingGallery = (token, body) => {
  return post(`${BASE_URL_LOGIN}be/api/submitSharingGalleryAdmin`,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status: "success",
      error: ""
    }
  }).catch(error => {
    return {
      data: [],
      status: "error",
      error
    }
  });
}

const deleteLifeSharingGallery = (token, body, id) => {
  return put(`${BASE_URL_LOGIN}be/api/deleteSharingGalleryAdmin/${id}`,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status: "success",
      error: ""
    }
  }).catch(error => {
    return {
      data: [],
      status: "error",
      error
    }
  });
}

const editDetailLifeSharingGallery = (token, body, id) => {
  return put(`${BASE_URL_LOGIN}be/api/updateUrlSharingGalleryAdmin?sharingGalleryId=${id}`,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status: "success",
      error: ""
    }
  }).catch(error => {
    return {
      data: [],
      status: "error",
      error
    }
  });
}

export default {
  getData,
  getDetail,
  postLifeSharingGallery,
  deleteLifeSharingGallery,
  editDetailLifeSharingGallery,
}
