import React, { Component, createRef } from "react";
import { 
   Breadcrumb, Card, Form, Row, Col,
   Radio, Input, Select, Button,
   Typography, Modal, Table, message, notification
} from "antd";
import "../../global/global.css";
import { connect } from "react-redux";
import lov from "../../actions/lov";
import profileUniversitas from "../../actions/profileUniversitas";
import ImagePreviewComponent from '../../components/imagePreview'
import {
   TwitterOutlined, WhatsAppOutlined, PhoneOutlined,
   MailOutlined, GlobalOutlined, InstagramOutlined,
   FacebookOutlined
} from "@ant-design/icons";

const { TextArea } = Input;
const { Option } = Select;
const { Text, Title } = Typography;

class EditUniversitasDetail extends Component {
   constructor(props) {
      super(props);
      this.state = {
         visibleJalurMasuk: false,
         visibleSertifikat: false,
         univId: sessionStorage.getItem("univId"),
         dataRes: "univ",
         initialData: {
            univCatId: null,
            univName: null,
            countryId: null,
            provinceId: null,
            cityId: null,
            univPostalCode: null,
            univAddress: null,
            univAboutUsUrl: null,
            univStageUrl: null,
            univFacultyUrl: null,
            univWa: null,
            univPhone: null,
            univEmail: null,
            univWebsite: null,
            univIg: null,
            univFacebook: null,
            univTwitter: null,
            univHumasPic: null,
            univHumasPhone: null,
            univHumasEmail: null
         },
         countryId: null,
         provinceId: null,
         cityId: null,
         paramJalurMasuk: {
            status: "Approved",
            sort: ""
         },
         tempDataBanner: null,
         tempJalurMasuk: null,
         univEntFormulirName: null,
         univEntFormulirPath: null,
         resUnivLogo: null,
         resUnivBanners: null,
         resUnivHumas: null,
         univRegId: null,
         resUnivCertificate: null
      }
   }

   async componentDidMount() {
      this.props.handleGetLOVUniversitasCategori(this.props.token);
      this.props.handleGetLOVCountry();
      await this.props.handleGetDetailProfileUniversitas(this.props.token, this.state.univId, this.state.dataRes);   
      await this.props.handleGetJalurMasuk(this.props.token, this.state.univId, this.state.paramJalurMasuk);
      this.handleSetInitialData();
      this.props.handleGetLOVProvince(this.props.dataProfileUniversitas.data.univCountryId);
      this.props.handleGetLOVCity(this.props.dataProfileUniversitas.data.univProvinceId);
      this.setState({ 
         tempJalurMasuk: this.props.dataJalurMasuk.data,
         lastJalurMasuk: this.props.dataJalurMasuk.lastJalurMasuk
      });
   }

   formEdit = createRef();
   formTambahJalurMasuk = createRef();
   inputFileLogo = createRef();
   inputFileBanner = createRef();
   inputFileBannerChange = createRef()
   inputFileHumas = createRef();
   inputFileForm = createRef();

   handleCloseJalurMasuk = () => {
      this.setState({ visibleJalurMasuk: false })
   }
   
   handleOpenJalurMasuk = () => {
      this.setState({ 
         visibleJalurMasuk: true,
         univEntFormulirPath: null,
         univEntFormulirName: null
      });
   }

   handleCloseSertifikat = () => {
      this.setState({ visibleSertifikat: false });
   }

   handleOpenSertifikat = (dataSertifikat) => {
      this.setState({ 
         visibleSertifikat: true,
         dataSertifikat
      })
 }
   handleSetInitialData = () => {
      const dataProfileUniversitas = this.props.dataProfileUniversitas.data;
      if (Object.keys(dataProfileUniversitas).length !== 0 && dataProfileUniversitas.constructor === Object) {

         this.formEdit.current.setFieldsValue({
            univCatId: {
               univCatId : dataProfileUniversitas.univCatId
            },
            univName: dataProfileUniversitas.univName,
            countryId: dataProfileUniversitas.univCountryId,
            provinceId: dataProfileUniversitas.univProvinceId,
            cityId: dataProfileUniversitas.univCityId,
            univPostalCode: dataProfileUniversitas.univPostalCode,
            univAddress: dataProfileUniversitas.univAddress,
            univAboutUsUrl: dataProfileUniversitas.univAboutUsUrl,
            univStageUrl: dataProfileUniversitas.univStageUrl,
            univFacultyUrl: dataProfileUniversitas.univFacultyUrl,
            univWa: dataProfileUniversitas.univWa,
            univPhone: dataProfileUniversitas.univPhone,
            univEmail: dataProfileUniversitas.univEmail,
            univWebsite: dataProfileUniversitas.univWebsite,
            univIg: dataProfileUniversitas.univIg,
            univFacebook: dataProfileUniversitas.univFacebook,
            univTwitter: dataProfileUniversitas.univTwitter,
            univHumasPic: dataProfileUniversitas.univHumasPic,
            univHumasPhone: dataProfileUniversitas.univHumasPhone,
            univHumasEmail: dataProfileUniversitas.univHumasEmail
         });
         this.setState({
            resUnivLogo: {
               univUploadFileId: dataProfileUniversitas.resUnivLogo.univUploadFileId,
               fileTypeId: dataProfileUniversitas.resUnivLogo.fileTypeId,
               univUploadFileName: dataProfileUniversitas.resUnivLogo.univUploadFileName,
               univUploadFilePath: dataProfileUniversitas.resUnivLogo.univUploadName,
               imagePath: dataProfileUniversitas.resUnivLogo.univUploadFilePath,
               isEdit: false
            }, 
            resUnivBanners: dataProfileUniversitas.resUnivBanners.map( res => (
               {
                  univUploadFileId: res.univUploadFileId,
                  fileTypeId: res.fileTypeId,
                  bannerName: res.banners,
                  univUploadFileName: res.univUploadFileName,
                  univUploadFilePath: res.univUploadName,
                  imagePath: res.univUploadFilePath,
                  isEdit: false
               }
            )),
            resUnivHumas: {
               univUploadFileId: dataProfileUniversitas.resUnivHumas.univUploadFileId,
               fileTypeId: 3,
               univUploadFileName: dataProfileUniversitas.resUnivHumas.univUploadFileName,
               univUploadFilePath: dataProfileUniversitas.resUnivHumas.univUploadName,
               imagePath: dataProfileUniversitas.resUnivHumas.univUploadFilePath,
               isEdit: false
            },
            univRegId : dataProfileUniversitas.univRegId,
            resUnivCertificate :  {
               univUploadFileId: dataProfileUniversitas.resUnivCertificate.univUploadFileId,
               fileTypeId: 3,
               univUploadFileName: dataProfileUniversitas.resUnivCertificate.univUploadFileName,
               univUploadFilePath: dataProfileUniversitas.resUnivCertificate.univUploadName,
               imagePath: dataProfileUniversitas.resUnivCertificate.univUploadFilePath,
               isEdit: false
            }
         })
      }
   }

   handleChangeCountry = value => {
      this.props.handleGetLOVProvince(value);
      this.formEdit.current.setFieldsValue({
         provinceId: null,
         cityId: null
      });
   }

   handleChangeProvince = value => {
      this.props.handleGetLOVCity(value);
      this.formEdit.current.setFieldsValue({
         cityId: null
      });
   }

   handleResetFormEdit = () => {
      this.handleSetInitialData();
      this.props.handleGetLOVProvince(this.props.dataProfileUniversitas.data.univCountryId);
      this.props.handleGetLOVCity(this.props.dataProfileUniversitas.data.univProvinceId);
   } 

   handleTableChangeJalurMasuk = (page, filters, sorter) => {
      let tempJalurMasuk = this.state.tempJalurMasuk;
      
      if ( sorter.field === "univEntName" ) {
         if ( sorter.order === "ascend" ) {
            tempJalurMasuk.sort((a,b) => (a.univEntName > b.univEntName) ? 1 : ((b.univEntName > a.univEntName) ? -1 : 0)); 
         } else if ( sorter.order === "descend" ) {
            tempJalurMasuk.sort((a,b) => (a.univEntName < b.univEntName) ? 1 : ((b.univEntName < a.univEntName) ? -1 : 0)); 
         } else {
            tempJalurMasuk.sort((a,b) => (a.univEntName > b.univEntName) ? 1 : ((b.univEntName > a.univEntName) ? -1 : 0)); 
         }
      } else if ( sorter.field === "univEntUrl" ) {
         if ( sorter.order === "ascend" ) {
            tempJalurMasuk.sort((a,b) => (a.univEntUrl > b.univEntUrl) ? 1 : ((b.univEntUrl > a.univEntUrl) ? -1 : 0)); 
         } else if ( sorter.order === "descend" ) {
            tempJalurMasuk.sort((a,b) => (a.univEntUrl < b.univEntUrl) ? 1 : ((b.univEntUrl < a.univEntUrl) ? -1 : 0)); 
         } else {
            tempJalurMasuk.sort((a,b) => (a.univEntName > b.univEntName) ? 1 : ((b.univEntName > a.univEntName) ? -1 : 0)); 
         }
      } else {
         if ( sorter.order === "ascend" ) {
            tempJalurMasuk.sort((a,b) => (a.isReceiveUpload.toString() > b.isReceiveUpload.toString()) ? 1 : ((b.isReceiveUpload.toString() > a.isReceiveUpload.toString()) ? -1 : 0)); 
         } else if ( sorter.order === "descend" ) {
            tempJalurMasuk.sort((a,b) => (a.isReceiveUpload.toString() < b.isReceiveUpload.toString()) ? 1 : ((b.isReceiveUpload.toString() < a.isReceiveUpload.toString()) ? -1 : 0)); 
         } else {
            tempJalurMasuk.sort((a,b) => (a.univEntName > b.univEntName) ? 1 : ((b.univEntName > a.univEntName) ? -1 : 0)); 
         }
      }

      this.setState({ tempJalurMasuk });
   }

   handlePostReplaceImageLogoBannerHumas = (token ,formData, type) => {
      this.props.handlePostReplaceImageLogoBannerHumas(token, formData).then( res => {
         if ( res.payload.data ? res.payload.data.Status === "ACCEPTED" : false ) {
            message.success( res.payload.data.Message ? res.payload.data.Message : 'Upload foto berhasil' );
            if ( type === "resUnivBanners" ) {
               const { resUnivBanners, tempDataBanner } = this.state;
               let tempResUnivBanners = []; 
               for ( let i = 0; i < resUnivBanners.length; i++ ) {
                  if ( resUnivBanners[i].univUploadFileId === tempDataBanner.univUploadFileId ) {
                     tempResUnivBanners.push({
                        univUploadFileId: resUnivBanners[i].univUploadFileId,
                        fileTypeId: res.payload.data.FileType,
                        univUploadFileName: res.payload.data.FileName,
                        univUploadFilePath: res.payload.data.FilePath,
                        isEdit: res.payload.data.isEdit,
                        banners: res.payload.data.banners,
                        imagePath: res.payload.data.fileLink,
                     })
                  } else {
                     tempResUnivBanners.push(resUnivBanners[i]);
                  }
               }
               this.setState({ resUnivBanners: tempResUnivBanners });
            } else {
               this.setState({
                  [type]: {
                     univUploadFileId: this.state[type].univUploadFileId,
                     fileTypeId: res.payload.data.FileType,
                     univUploadFileName: res.payload.data.FileName,
                     univUploadFilePath: res.payload.data.FilePath,
                     isEdit: res.payload.data.isEdit
                  }
               });
            }
         } else {
            message.error("Upload foto gagal");
         }
      });
   }

   handlePostUploadLogo = (token ,formData) => {
      this.props.handlePostUploadLogo(token, formData).then( res => {
         if ( res.payload.data ? res.payload.data.Status === "ACCEPTED" : false ) {
            message.success( res.payload.data.Message ? res.payload.data.Message : 'Upload foto berhasil' );
            this.setState({
               resUnivLogo: {
                  univUploadFileId: null,
                  fileTypeId: 1,
                  univUploadFileName: res.payload.data.FileName,
                  univUploadFilePath: res.payload.data.FilePath,
                  isEdit: false
               }
            })
         } else {
            message.error("Upload foto gagal");
         }
      });
   }

   handlePostUploadBanner = (token ,formData, bannerName) => {
      this.props.handlePostUploadBanner(token, formData).then( res => {
         if ( res.payload.data ? res.payload.data.Status === "ACCEPTED" : false ) {
            message.success( res.payload.data.Message ? res.payload.data.Message : "Upload foto berhasil");
            const { resUnivBanners } = this.state;
            resUnivBanners.push({
               univUploadFileId: null,
               fileTypeId: 2,
               univUploadFileName: res.payload.data.FileName,
               univUploadFilePath: res.payload.data.FilePath,
               banners: bannerName,
               isEdit: false,
               imagePath: res.payload.data.fileLink,
            });
            this.setState({ resUnivBanners });
         } else {
            message.error("Upload foto gagal");
         }
      });
   }

   handlePostUploadHumas = (token ,formData) => {
      this.props.handlePostUploadHumas(token, formData).then( res => {
         if ( res.payload.data ? res.payload.data.Status === "ACCEPTED" : false ) {
            message.success( res.payload.data.Message ? res.payload.data.Message : "Upload foto berhasil");
            this.setState({
               resUnivHumas: {
                  univUploadFileId: null,
                  fileTypeId: 3,
                  univUploadFileName: res.payload.data.FileName,
                  univUploadFilePath: res.payload.data.FilePath,
                  isEdit: false
               }
            })
         } else {
            message.error("Upload foto gagal");
         }
      });
   }

   handlePostUploadForm = (token ,formData) => {
      this.props.handlePostUploadForm(token, formData).then( res => {
         if ( res.payload.data.Status === "ACCEPTED" ) {
            message.success("Upload document berhasil");
            this.setState({ 
               univEntFormulirName: res.payload.data.FileName,
               univEntFormulirPath: res.payload.data.FilePath
            })
         } else {
            message.error("Upload document gagal");
         }
      });
   }

   handleClickEditLogo = () => {
      this.inputFileLogo.current.click();
   }

   handleChangeLogo = event => {
      const { token } = this.props;
      const { resUnivLogo } = this.state;
      const files = event.target.files;
      const formData = new FormData();

      if ( resUnivLogo.univUploadFileId ) {
         formData.append("file", files[0]);
         formData.append("fileName", resUnivLogo.univUploadFileName);
         formData.append("fileType", resUnivLogo.fileTypeId);
         formData.append(`univRegId`, this.state.univRegId)
         this.handlePostReplaceImageLogoBannerHumas(token, formData, "resUnivLogo");
      } else {
         formData.append("file", files[0]);
         formData.append("fileType", 1);
         formData.append(`univRegId`, this.state.univRegId)
         this.handlePostUploadLogo(token, formData);
      }
   }

   handleClickAddBanner = () => {
      this.inputFileBanner.current.click();
   }

   handleChangeAddBanner = (event) => {
      const files = event.target.files;
      const formData = new FormData();
      const index = this.state.resUnivBanners.length + 1
      const bannerName = `banner_${index}`
      formData.append(`banner`, bannerName)
      formData.append("file", files[0]);
      formData.append(`univRegId`, this.state.univRegId)
      formData.append("fileType", 2);
      this.handlePostUploadBanner(this.props.token, formData, bannerName);
   }

   handleClickEditBanner = (data) => {
      this.inputFileBannerChange.current.click();
      this.setState({ tempDataBanner: data });
   }

   handleChangeBanner = (event) => {
      const { token } = this.props;
      const { tempDataBanner } = this.state;
      const files = event.target.files;
      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append(`banner`, tempDataBanner.bannerName)
      formData.append("fileName", tempDataBanner.univUploadFileName);
      formData.append("fileType", tempDataBanner.fileTypeId);
      formData.append(`univRegId`, this.state.univRegId)
      this.handlePostReplaceImageLogoBannerHumas(token, formData, "resUnivBanners");
   }

   handleClickEditHumas = () => {
      this.inputFileHumas.current.click();
   }

   handleChangeHumas = event => {
      const { token } = this.props;
      const { resUnivHumas } = this.state;
      const files = event.target.files;
      const formData = new FormData();

      if ( resUnivHumas.univUploadFileId ) {
         formData.append("file", files[0]);
         formData.append("fileName", resUnivHumas.univUploadFileName);
         formData.append("fileType", resUnivHumas.fileTypeId);
         formData.append(`univRegId`, this.state.univRegId)
         this.handlePostReplaceImageLogoBannerHumas(token, formData, "resUnivHumas");
      } else {
         formData.append("file", files[0]);
         formData.append("fileType", 3);
         formData.append(`univRegId`, this.state.univRegId)
         this.handlePostUploadHumas(token, formData);
      }
   }

   handleClickForm = () => {
      this.inputFileForm.current.click();
   }

   handleChangeForm = event => {
      const files = event.target.files;
      const formData = new FormData();

      formData.append("file", files[0]);
      formData.append(`univRegId`, this.state.univRegId)
      this.handlePostUploadForm(this.props.token, formData);
   }

   handleChangeResetJalurMasuk = event => {
      if ( event.target.value ) {
         this.setState({ lastJalurMasuk: "Jalur Masuk 1" });
      } else {
         this.props.handleGetJalurMasuk(this.props.token, this.state.univId, this.state.paramJalurMasuk);
         this.setState({ lastJalurMasuk: this.props.dataJalurMasuk.lastJalurMasuk })
      }
   }

   handlePostJalurMasuk = (token, body, isReset) => {
      this.props.handlePostJalurMasuk(token, body).then( async res => {
         if ( res.payload.status === "success" ) {
            notification.success({
               placement: 'bottomRight',
               message: 'Success',
               description: res.payload.message ? res.payload.message : "Berhasil menambahkan jalur masuk" 
            });
            await this.props.handleGetJalurMasuk(this.props.token, this.state.univId, this.state.paramJalurMasuk);
            if ( isReset ) {
               let tempJalurMasuk = [];
               tempJalurMasuk.push(res.payload.data); 
               this.setState({ 
                  tempJalurMasuk,
                  visibleJalurMasuk: false,
                  lastJalurMasuk: this.props.dataJalurMasuk.lastJalurMasuk,
                  univEntFormulirName: null,
                  univEntFormulirPath: null
               });
            } else {
               let tempJalurMasuk = [...this.state.tempJalurMasuk];
               tempJalurMasuk.push(res.payload.data); 
               this.setState({ 
                  tempJalurMasuk,
                  visibleJalurMasuk: false,
                  lastJalurMasuk: this.props.dataJalurMasuk.lastJalurMasuk,
                  univEntFormulirName: null,
                  univEntFormulirPath: null
               });
            }
         } else {
            notification.error({
               placement: 'bottomRight',
               message: 'Error',
               description:  "Gagal menambahkan jalur masuk.",
            });
         }
      })
   }

   onFinishTambahJalurMasuk = values => {
      const { univId, univEntFormulirName, univEntFormulirPath, lastJalurMasuk } = this.state;
      const body = {
         univId: univId,
         univEntName: lastJalurMasuk,
         univEntUrl: values.univEntUrl,
         univEntFormulirName: univEntFormulirName,
         univEntFormulirPath: univEntFormulirPath,
         isReceiveUpload: values.isReceiveUpload,
         usersId: this.props.userId
      }

      this.handlePostJalurMasuk(this.props.token, body, values.resetJalurMasuk);
   }

   handlePutEditProfileUniversitas = (token, univId, body) => {
      this.props.handlePutEditProfileUniversitas(token, univId, body).then( res => {
         if ( res.payload.data ? res.payload.data.status === 200 : false ) {
            notification.success({
               placement: 'bottomRight',
               message: 'Success',
               description: "Berhasil mengedit profile universitas" 
            });
            this.props.history.push("/profile-universitas")
         } else {
            notification.error({
               placement: 'bottomRight',
               message: 'Error',
               description:  "Gagal mengedit profile universitas",
            });
         }
      });
   }

   onFinishEditProfileUniversitas = values => {
      const body = {
         ...values,
         usersId: {
            userId: this.props.userId
         },
         countryId:{
            countryId: values.countryId
         },
         provinceId:{
            provinceId: values.provinceId
         },
         cityId:{
            cityId: values.cityId
         },
         resUnivLogo: this.state.resUnivLogo,
         resUnivHumas: this.state.resUnivHumas,
         resUnivBanners: this.state.resUnivBanners
      }
      this.handlePutEditProfileUniversitas(this.props.token, this.state.univId, body);
   }

   render() {
      const { 
         visibleJalurMasuk, tempJalurMasuk, lastJalurMasuk, 
         resUnivBanners, dataSertifikat, visibleSertifikat,
         univEntFormulirName, resUnivLogo, resUnivCertificate
      } = this.state;
      const {
         dataProfileUniversitas, dataLOVUniversitasCategori, dataLOVCountry,
         dataLOVProvince, dataLOVCity, dataJalurMasuk, dataPutEditProfileUniversitas,
         dataPostJalurMasuk, dataPostFormProfileUniversitas
      } = this.props;
      const columnsTableJalurMasuk = [
         {
            title: "Nama",
            dataIndex: "univEntName",
            key: "univEntName",
            sorter: true,
            render: namaUniversitas => <Text style={{ color: '#EF712B', fontWeight: 600 }}>{namaUniversitas}</Text>
         }, 
         {
            title: "Tautan",
            dataIndex: "univEntUrl",
            key: "univEntUrl",
            sorter: true
         },
         {
            title: "Terima Form",
            dataIndex: "isReceiveUpload",
            key: "isReceiveUpload",
            sorter: true,
            align: "center",
            render: isReceiveUpload => {
               if ( isReceiveUpload ) {
                  return ( <Text>Ya</Text> )
               } else {
                  return ( <Text>Tidak</Text> )
               }
            }
         }
      ];
      return (
         <div className="root">
            <Breadcrumb className="breadcrumb_style">
               <Breadcrumb.Item>
                  <span style={{ cursor: 'pointer' }} onClick={() => this.props.history.push(`/profile-universitas`)}>Profil Universitas</span>
               </Breadcrumb.Item>
               <Breadcrumb.Item className="pathNow_style">
                  <span>
                     Edit Profil Universitas
                  </span>
               </Breadcrumb.Item>
            </Breadcrumb>
            <Card 
               loading={dataProfileUniversitas.status === "loading" } 
               className="bodyCard_style"
            >
               <Form 
                  ref={this.formEdit}
                  onFinish={this.onFinishEditProfileUniversitas}
               >
                  <Row gutter={[40, 10]}>
                     <Col span={12}>
                        <Row gutter={[4, 10]}>
                           <Col span={24}>
                              <span className="mandatoryLarge">&#8727; </span>
                              <span className="data_text_detail">Jenis Universitas</span>
                           </Col>
                           <Col span={24}>
                              <Form.Item
                                 name={["univCatId","univCatId"]}
                                 rules={[{ required: true, message: 'please select university type!' }]}
                                 style={{marginBottom: 0}}
                              >
                                 <Radio.Group>
                                    { dataLOVUniversitasCategori.data 
                                       && dataLOVUniversitasCategori.data.map( (res, index) => (
                                          <Radio style={{display: "block"}} key={index} value={res.univCatId}>{res.univCatName}</Radio>
                                       ))
                                    }
                                 </Radio.Group>
                              </Form.Item>
                           </Col>
                           <Col span={23}>
                              <Form.Item
                                 name="univName"
                                 rules={[{ required: true, message: 'Please input unversity name' }]}
                                 style={{marginBottom: 0}}
                              >
                                 <Input 
                                    maxLength={256}
                                    placeholder="Nama Universitas" 
                                    className="input_style" 
                                    size="large"
                                 />
                              </Form.Item>
                           </Col>
                           <Col span={1}>
                              <span className="mandatoryLarge">&#8727;</span>
                           </Col>
                           <Col span={24}>
                              <span className="data_text_detail">Logo</span>
                           </Col>
                           <Col span={24}>
                              <Row gutter={[16, 16]} align="middle">
                                 <Col span={this.props.isMobile ? 24 : 8}>
                                    <ImagePreviewComponent
                                       isMobile={this.props.isMobile}
                                       styles={{ width: '100%' }}
                                       uri={resUnivLogo 
                                          && resUnivLogo.imagePath}
                                    />
                                    {/* <img 
                                       src={ resUnivLogo 
                                             ? resUnivLogo.imagePath+'?'+new Date().getTime()
                                             : "" 
                                          } 
                                       alt="Logo"
                                       width="100%"
                                    /> */}
                                 </Col>
                                 <Col span={this.props.isMobile ? 12 : 8} style={{textAlign: "center"}}>
                                    <span className="header_text_detail">
                                       { resUnivLogo
                                          ? resUnivLogo.univUploadFileName
                                          : ""
                                       }
                                    </span>
                                 </Col>
                                 <Col span={this.props.isMobile ? 12 : 8} style={{textAlign: "center"}}>
                                    <input type="file" hidden ref={this.inputFileLogo} onChange={this.handleChangeLogo}/>
                                    <Text onClick={this.handleClickEditLogo} style={{ color: '#EF712B', fontWeight: 600, cursor: "pointer" }}>Edit</Text>
                                 </Col>
                              </Row>
                           </Col>
                           <Col span={12}>
                              <span className="data_text_detail">Banner</span>
                           </Col>
                           { resUnivBanners 
                              && resUnivBanners.length < 3
                              && ( <Col span={12} style={{textAlign:"right"}}>
                                 <input type="file" hidden ref={this.inputFileBanner} onChange={this.handleChangeAddBanner} />
                                 <Button onClick={this.handleClickAddBanner} className="donwloadButton">Tambah</Button>
                              </Col> ) 
                           }
                           <Col span={24}>
                              { resUnivBanners &&
                                 resUnivBanners.map( (res, index) => (
                                    <Row key={index} gutter={[16, 16]} align="middle">
                                       <Col span={this.props.isMobile ? 24 : 8}>
                                          <ImagePreviewComponent
                                             isMobile={this.props.isMobile}
                                             styles={{ width: '100%' }}
                                             uri={res.imagePath}
                                          />
                                          {/* <img 
                                             src={res.imagePath+'?'+new Date().getTime()} 
                                             alt="Logo"
                                             width="100%"
                                          /> */}
                                       </Col>
                                       <Col span={this.props.isMobile ? 12 : 8} style={{textAlign: "center"}}>
                                          <span className="header_text_detail">
                                             {res.univUploadFileName}
                                          </span>
                                       </Col>
                                       <Col span={this.props.isMobile ? 12 : 8} style={{textAlign: "center"}}>
                                          <input type="file" hidden ref={this.inputFileBannerChange} onChange={this.handleChangeBanner} />
                                          <Text onClick={() => this.handleClickEditBanner(res)} style={{ color: '#EF712B', fontWeight: 600, cursor: "pointer" }}>Edit</Text>
                                       </Col>
                                    </Row>
                                 ))
                              }
                           </Col>
                           <Col span={24}>
                              <span className="mandatoryLarge">&#8727; </span>
                              <span className="data_text_detail">Lokasi Universitas (Kampus Utama)</span>
                           </Col>
                           <Col span={12}>
                              <Form.Item
                                 name="countryId"
                                 rules={[{ required: true, message: 'Please select country!' }]}
                                 style={{marginBottom: 0}}
                              >
                                 <Select placeholder="Negara" className='select' onChange={this.handleChangeCountry}>
                                    { dataLOVCountry.data 
                                       && dataLOVCountry.data.map((res, index) => (
                                          <Option key={index} value={res.id}>{res.name}</Option>
                                       ))
                                    }
                                 </Select>
                              </Form.Item>
                           </Col>
                           <Col span={12}>
                              <Form.Item
                                 name="provinceId"
                                 rules={[{ required: true, message: 'Please select province!' }]}
                                 style={{marginBottom: 0}}
                              >
                                 <Select placeholder="Provinsi" className='select' onChange={this.handleChangeProvince}>
                                    { dataLOVProvince.data 
                                       && dataLOVProvince.data.map((res, index) => (
                                          <Option key={index} value={res.id}>{res.name}</Option>
                                       ))
                                    }
                                 </Select>
                              </Form.Item>
                           </Col>
                           <Col span={12}>
                              <Form.Item
                                 name="cityId"
                                 rules={[{ required: true, message: 'Plase select city' }]}
                                 style={{marginBottom: 0}}
                              >
                                 <Select placeholder="Kota" className='select'>
                                    { dataLOVCity.data 
                                       && dataLOVCity.data.map((res, index) => (
                                          <Option key={index} value={res.id}>{res.name}</Option>
                                       ))
                                    }
                                 </Select>
                              </Form.Item>
                           </Col>
                           <Col span={12}>
                              <Form.Item
                                 name="univPostalCode"
                                 rules={[
                                    { required: true, message: 'Plase input postal code' },
                                    { pattern: /^\d+$/, message: 'Mohon masukan kode pos yang valid!' }
                                 ]}
                                 style={{marginBottom: 0}}
                              >
                                 <Input 
                                    maxLength={15}
                                    placeholder="Kode Pos" 
                                    className='input_style' 
                                    size="large"
                                 />
                              </Form.Item>
                           </Col>
                           <Col span={24}>
                              <Form.Item
                                 name="univAddress"
                                 rules={[{ required: true, message: 'Plase input address' }]}
                                 style={{marginBottom: 0}}
                              >
                                 <TextArea placeholder="Alamat" className='input_style'/>
                              </Form.Item>
                           </Col>
                        </Row>
                     </Col>
                     <Col span={12}>
                        <Row gutter={[4, 10]}>
                           <Col span={24}>
                              <span className="mandatoryLarge">&#8727; </span>
                              <span className="data_text_detail">Tautan</span>
                           </Col>
                           <Col span={24}>
                              <Form.Item
                                 name="univAboutUsUrl"
                                 rules={[
                                    { required: true, message: 'Please input link about us!' },
                                    { type: "url", message: 'Please input a valid link about us!' }
                                 ]}
                                 style={{marginBottom: 0}}
                              >
                                 <Input
                                    placeholder="Tentang Kami (Tautan)" 
                                    className='input_style' 
                                    size="large"
                                 />
                              </Form.Item>
                           </Col>
                           <Col span={24}>
                              <Form.Item
                                 name="univStageUrl"
                                 rules={[
                                    { required: true, message: 'Please input link stage' },
                                    { type: "url", message: 'Please input a valid link stage!' }
                                 ]}
                                 style={{marginBottom: 0}}
                              >
                                 <Input 
                                    placeholder="Jenjang (Tautan)" 
                                    className='input_style' 
                                    size="large"
                                 />
                              </Form.Item>
                           </Col>
                           <Col span={24}>
                              <Form.Item
                                 name="univFacultyUrl"
                                 rules={[
                                    { required: true, message: 'Please input link faculty / program' },
                                    { type: "url", message: 'Please input a valid link faculty / program!' }
                                 ]}
                                 style={{marginBottom: 0}}
                              >
                                 <Input 
                                    placeholder="Fakultas / Program (Tautan)" 
                                    className='input_style' 
                                    size="large"
                                 />
                              </Form.Item>
                           </Col>
                           <Col span={24}>
                              <span className="data_text_detail">Social Media</span>
                           </Col>
                           
                           <Col span={23}>
                              <Form.Item
                                 name="univWa"
                                 rules={[
                                    { pattern: /^\d+$/, message: 'Plese input a valid whatsapp!' }
                                 ]}
                                 style={{marginBottom: 0}}
                              >
                                 <Input 
                                    maxLength={15}
                                    placeholder="WhatsApp" 
                                    className='input_style_prefix' 
                                    size="middle" 
                                    prefix={<WhatsAppOutlined />}
                                 />
                              </Form.Item>
                           </Col>
                           <Col span={23}>
                              <Form.Item
                                 name="univPhone"
                                 rules={[
                                    { required: true, message: 'Please input phone' },
                                    { pattern: /^\d+$/, message: 'Please input a valid phone!' }
                                 ]}
                                 style={{marginBottom: 0}}
                              >
                                 <Input 
                                    maxLength={15}
                                    placeholder="Phone" 
                                    className='input_style_prefix' 
                                    size="middle" 
                                    prefix={<PhoneOutlined />}
                                 />
                              </Form.Item>
                           </Col>
                           <Col span={1}>
                              <span className="mandatoryLarge">&#8727;</span>
                           </Col>
                           <Col span={23}>
                              <Form.Item
                                 name="univEmail"
                                 rules={[
                                    { required: true, message: 'Please input email' },
                                    { type: "email", message: 'Please input a valid email!' },
                                 ]}
                                 style={{marginBottom: 0}}
                              >
                                 <Input 
                                    maxLength={125}
                                    placeholder="Email" 
                                    className='input_style_prefix' 
                                    size="middle" 
                                    prefix={<MailOutlined />}
                                 />
                              </Form.Item>
                           </Col>
                           <Col span={1}>
                              <span className="mandatoryLarge">&#8727;</span>
                           </Col>
                           <Col span={23}>
                              <Form.Item
                                 name="univWebsite"
                                 rules={[
                                    { required: true, message: 'Please input website' },
                                    { type: "url", message: 'Please input a valid website!' },
                                 ]}
                                 style={{marginBottom: 0}}
                              >
                              <Input 
                                 maxLength={256}
                                 placeholder="Website"
                                 className='input_style_prefix' 
                                 size="middle" 
                                 prefix={<GlobalOutlined />}
                              />
                              </Form.Item>
                           </Col>
                           <Col span={1}>
                              <span className="mandatoryLarge">&#8727;</span>
                           </Col>
                           <Col span={23}>
                              <Form.Item
                                 name="univIg"
                                 rules={[
                                    { type: "url", message: "Please input a valid URL Ig!"}
                                 ]}
                                 style={{marginBottom: 0}}
                              >
                                 <Input 
                                    maxLength={100}
                                    placeholder="Instagram" 
                                    className='input_style_prefix' 
                                    size="middle" 
                                    prefix={<InstagramOutlined />}
                                 />
                              </Form.Item>
                           </Col>
                           <Col span={23}>
                              <Form.Item
                                 name="univFacebook"
                                 rules={[
                                    { type: "url", message: "Please input a valid URL facebook!"}
                                 ]}
                                 style={{marginBottom: 0}}
                              >
                                 <Input 
                                    maxLength={125}
                                    placeholder="Facebook" 
                                    className='input_style_prefix' 
                                    size="middle" 
                                    prefix={<FacebookOutlined />}
                                 />
                              </Form.Item>
                           </Col>
                           <Col span={23}>
                              <Form.Item
                                 name="univTwitter"
                                 rules={[
                                    { type: "url", message: "Please input a valid URL twitter!"}
                                 ]}
                                 style={{marginBottom: 0}}
                              >
                                 <Input 
                                    maxLength={125}
                                    placeholder="Twitter" 
                                    className='input_style_prefix' 
                                    size="middle" 
                                    prefix={<TwitterOutlined />}
                                 />
                              </Form.Item>
                           </Col>
                           <Col span={24}>
                              <span className="mandatoryLarge">&#8727; </span>
                              <span className="data_text_detail">Humas</span>
                           </Col>
                           <Col span={24}>
                              <Form.Item
                                 name="univHumasPic"
                                 rules={[{ required: true, message: 'Please input full name pic humas' }]}
                                 style={{marginBottom: 0}}
                              >
                                 <Input 
                                    maxLength={125}
                                    placeholder="Nama Lengkap PIC Humas" 
                                    className='input_style' 
                                    size="large"
                                 />
                              </Form.Item>
                           </Col>
                           <Col span={24}>
                              <Form.Item
                                 name="univHumasPhone"
                                 rules={[
                                    { required: true, message: 'Please input telephone humas!' },
                                    { pattern: /^\d+$/, message: 'Please input a valid telephone humas!' }
                                 ]}
                                 style={{marginBottom: 0}}
                              >
                                 <Input 
                                    maxLength={15}
                                    placeholder="No. Telepon Humas" 
                                    className='input_style' 
                                    size="large"
                                 />
                              </Form.Item>
                           </Col>
                           <Col span={24}>
                              <Form.Item
                                 name="univHumasEmail"
                                 rules={[
                                    { required: true, message: 'Please input email humas' },
                                    { type: "email", message: 'Please input email humas yang valid!' }
                                 ]}
                                 style={{marginBottom: 0}}
                              >
                                 <Input 
                                    maxLength={125}
                                    placeholder="Email Humas" 
                                    className='input_style' 
                                    size="large"
                                 />
                              </Form.Item>
                           </Col>
                           <Col span={24}>
                              <Row>
                                 <Col span={12}>
                                    <span className="header_text_detail">Foto Humas</span>
                                 </Col>
                                 <Col span={12} style={{textAlign: "right"}}>
                                    <input type="file" hidden ref={this.inputFileHumas} onChange={this.handleChangeHumas} />
                                    <Button onClick={this.handleClickEditHumas} className="donwloadButton">Upload</Button>
                                 </Col>
                              </Row>
                           </Col>
                           <Col span={24}>
                              <Row>
                                 <Col span={12}>
                                    <span className="header_text_detail">Sertifikat Kerjasama BIDikTi Platform</span>
                                 </Col>
                                 <Col span={12} style={{textAlign: "right"}}>
                                    <Button onClick={() => this.handleOpenSertifikat(resUnivCertificate ? resUnivCertificate.imagePath : '') } className="donwloadButton">Lihat</Button>
                                 </Col>
                              </Row>
                           </Col>
                        </Row>
                     </Col>
                     <Col span={24}>
                        <Row gutter={[10, 10]}>
                           <Col span={12}>
                              <span className="data_text_detail">Jalur Masuk</span>
                           </Col>
                           <Col span={12} style={{textAlign: "right"}}>
                              <Button className="updateButton" onClick={this.handleOpenJalurMasuk}>Manage Jalur Masuk</Button>
                           </Col>
                           <Col span={24}>
                              <Table
                                 dataSource={tempJalurMasuk}
                                 columns={columnsTableJalurMasuk}
                                 loading={dataJalurMasuk.status === "loading"}
                                 pagination= {false}
                                 rowKey={record => record.id}
                                 onChange={(page, filters, sorter) => this.handleTableChangeJalurMasuk(page, filters, sorter)}
                              />
                           </Col>
                           <Col span={24} style={{textAlign: "right"}}>
                              <span className="data_text_detail" style={{fontSize: "12px"}}>*Data jalur masuk berstatus on approval tidak ditampilkan di halaman ini setelah user berpindah halaman</span>
                           </Col>
                           <Col span={24} style={{textAlign: "right"}}>
                              <span className="data_text_detail" style={{fontSize: "12px"}}>*Sebelum di-approve oleh pihak Loyola, data yang telah diedit akan kembali seperti semula</span>
                           </Col>
                           <Col span={24} style={{textAlign: "right", marginTop: 30}}>
                              <Form.Item>
                                 <Button className="resetButton" type="link" onClick={this.handleResetFormEdit}>Reset</Button>
                                 <Button 
                                    className="updateButton" 
                                    loading={dataPutEditProfileUniversitas.status === "loading"} 
                                    htmlType="submit"
                                 >
                                    Submit
                                 </Button>
                              </Form.Item>
                           </Col>
                        </Row>
                     </Col>
                  </Row>
               </Form>
            </Card>
            <Modal
               visible={visibleJalurMasuk}
               footer={null}
               className={this.props.isMobile ? 'modalMobile' : 'modalImage'}
               width={this.props.isMobile ? '100%' : 633}
               centered
               onCancel={() => this.handleCloseJalurMasuk()}
               destroyOnClose={true}
            >
               <Row gutter={[10, 10]}>
                  <Col  span={24}>
                     <div className='headerForm'>
                        <Title className='titleModalGalery' level={4}>Tambah Jalur Masuk</Title>
                     </div>
                     <div style={{ width: 250}} className='dividerGalery'/>
                  </Col>
                  <Col span={24}>
                     <Form ref={this.formTambahJalurMasuk} onFinish={this.onFinishTambahJalurMasuk}>
                        <Row gutter={[10, 10]}>
                           <Col span={24}>
                              <span className="mandatoryLarge">&#8727; </span>
                              <span className="data_text_detail">Reset Jalur Masuk Kembali ke 1?</span>
                           </Col>
                           <Col span={24}>
                              <Form.Item
                                 name="resetJalurMasuk"
                                 rules={[{ required: true, message: 'Please select an item!' }]}
                                 style={{marginBottom: 0}}
                              >
                                 <Radio.Group onChange={this.handleChangeResetJalurMasuk}>
                                    <Radio style={{display: "block"}} value={true}>Ya</Radio>
                                    <Radio style={{display: "block"}} value={false}>Tidak</Radio>
                                 </Radio.Group>
                              </Form.Item>
                           </Col>
                           <Col span={24}>
                              <span className="data_text_detail">{lastJalurMasuk}</span>
                           </Col>
                           <Col span={23}>
                              <Form.Item
                                 name="univEntUrl"
                                 rules={[
                                    { required: true, message: 'Please input link entrance!' },
                                    { type: "url", message: 'Please input a valid link entrance!' }
                                 ]}
                                 style={{marginBottom: 0}}
                              >
                                 <Input 
                                    
                                    placeholder="Tautan Jalur Masuk" 
                                    className='input_style' 
                                    size="large"
                                 />
                              </Form.Item>
                           </Col>
                           <Col span={1}>
                              <span className="mandatoryLarge">&#8727;</span>
                           </Col>
                           <Col span={12}>
                              <span className="header_text_detail">Form Pendaftaran {lastJalurMasuk}</span>
                           </Col>
                           <Col span={12} style={{textAlign: "right"}}>
                              <span className="header_text_detail">{univEntFormulirName} </span>
                              <input type="file" hidden ref={this.inputFileForm} onChange={this.handleChangeForm}/>
                              <Button onClick={this.handleClickForm} className="donwloadButton" loading={dataPostFormProfileUniversitas.status === "loading"}>Upload</Button>
                           </Col>
                           <Col span={14}>
                              <span className="mandatoryLarge">&#8727; </span>
                              <span className="header_text_detail">Terima Upload Form dari Calon Mahasiswa?</span>
                           </Col>
                           <Col span={10} style={{textAlign: "right"}}>
                              <Form.Item
                                 name="isReceiveUpload"
                                 rules={[{ required: true, message: 'Please select an item!' }]}
                                 style={{marginBottom: 0}}
                              >
                                 <Radio.Group>
                                    <Radio value={true}>Ya</Radio>
                                    <Radio value={false}>Tidak</Radio>
                                 </Radio.Group>
                              </Form.Item>
                           </Col>
                           <Col span={24} style={{textAlign: "center"}}>
                              <Form.Item style={{marginBottom: 0}}>
                                 <Button 
                                    htmlType="submit" 
                                    className="updateButton"
                                    loading={dataPostJalurMasuk.status === "loading"}
                                 >
                                    Submit
                                 </Button>
                              </Form.Item>
                           </Col>
                        </Row>
                     </Form>
                  </Col>
               </Row>
            </Modal>
            <Modal
               visible={visibleSertifikat}
               centered
               className={this.props.isMobile ? 'cardVideoMobile' : 'cardVideo'}
               footer={null}
               width={this.props.isMobile ? '100%' : '50%'}
               onCancel={() => this.handleCloseSertifikat()}
               destroyOnClose
            >
               <ImagePreviewComponent
                  isMobile={this.props.isMobile}
                  styles={{ width: '100%' }}
                  uri={dataSertifikat}
               />
               {/* <img
                  style={{ width: '100%' }}
                  src={dataSertifikat}
                  alt="Sertifikat"
               /> */}
            </Modal>
         </div>
      )
   }
}

const mapStateToProps = state => (
   {
      dataLOVUniversitasCategori: state.lovUniversitasCategori,
      dataProfileUniversitas: state.getDetailProfileUniversitas,
      dataLOVCountry: state.lovCountry,
      dataLOVProvince: state.lovProvince,
      dataLOVCity: state.lovCity,
      dataJalurMasuk: state.getProfileUniversitasJalurMasuk,
      dataPostLogoProfileUniversitas: state.postLogoProfileUniversitas,
      dataPostBannerProfileUniversitas: state.postBannerProfileUniversitas,
      dataPostHumasProfileUniversitas: state.postHumasProfileUniversitas,
      dataPostFormProfileUniversitas: state.postFormProfileUniversitas,
      dataPostJalurMasuk: state.postJalurMasukProfileUniversitas,
      dataPutEditProfileUniversitas: state.putEditProfileUniversitas
   }
);

const mapDispatchToProps = dispatch => (
   {
      handleGetLOVUniversitasCategori: (token) => dispatch(lov.getLOVUniversitasCategori(token)),
      handleGetLOVCountry: () => dispatch(lov.getLOVCountry()),
      handleGetLOVProvince: (countryId) => dispatch(lov.getLOVProvince(countryId)),
      handleGetLOVCity: (provinceId) => dispatch(lov.getLOVCity(provinceId)),
      handleGetDetailProfileUniversitas: (token, univId, dataRes) => dispatch(profileUniversitas.getDetailProfileUniversitas(token, univId, dataRes)),
      handleGetJalurMasuk: (token, univId, param) => dispatch(profileUniversitas.getJalurMasuk(token, univId, param)),
      handlePostReplaceImageLogoBannerHumas: (token, formData) => dispatch(profileUniversitas.postReplaceImageLogoBannerHumas(token, formData)),
      handlePostUploadLogo: (token, formData) => dispatch(profileUniversitas.postUploadLogo(token, formData)),
      handlePostUploadBanner: (token, formData) => dispatch(profileUniversitas.postUploadBanner(token, formData)),
      handlePostUploadHumas: (token, formData) => dispatch(profileUniversitas.postUploadHumas(token, formData)),
      handlePostUploadForm: (token, formData) => dispatch(profileUniversitas.postUploadForm(token, formData)),
      handlePostJalurMasuk: (token, body) => dispatch(profileUniversitas.postJalurMasuk(token, body)),
      handlePutEditProfileUniversitas: (token, univId, body) => dispatch(profileUniversitas.putEditProfileUniversitas(token, univId, body))
   }
);

export default connect(mapStateToProps, mapDispatchToProps)(EditUniversitasDetail);
