import { get, put } from "axios";
import config from "../services/config";

const { BASE_URL_LOGIN, TIMEOUT } = config;

const getData = (token, param) => {
   return get(`${BASE_URL_LOGIN}be/api/univListAdmin?search=${param.search}&sort=${param.sort}&size=${param.size}&page=${param.page-1}`,
      {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
         timeout: TIMEOUT
      }
   ).then( response => {
      const normalizedData = response.data.Data.map( (res, index) => (
         {
            ...res,
            id: index
         }
      ))
      return {
         data: normalizedData,
         totalData: response.data.totalData,
         status: "success",
         error: ""
      }
   }).catch( error => {
      return {
         data: [],
         totalData: 0,
         status: "error",
         error
      }
   })
}

const getDetail = (token, univId, status) => {
   let api = `${BASE_URL_LOGIN}be/api/detailNotPublishAdminUniv/${univId}`;
   if ( status === "Approved" ) {
      api = `${BASE_URL_LOGIN}be/api/detailPublishAdminUniv/${univId}`;
   } 
   return get(
      api,
      {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
         timeout: TIMEOUT
      }
   ).then( response => {
      return {
         data: response.data,
         status: "success",
         error: ""
      }
   }).catch( error => {
      return {
         data: [],
         status: "error",
         error
      }
   })
}

const getJalurMasuk = (token, univId, param) => {
   return get(`${BASE_URL_LOGIN}be/api/detailEntAdminUniv/${univId}?status=${param.status}&sort=${param.sort}`,
      {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
         timeout: TIMEOUT
      }
   ).then( response => {
      return {
         data: response.data.items,
         status: "success",
         error: ""
      }
   }).catch( error => {
      return {
         data: [],
         status: "error",
         error
      }
   })
}

const rejectDaftarPersetujuanKonten = (token, univId, body) => {
   return put(`${BASE_URL_LOGIN}be/api/rejectUnivContentAdmin?univId=${univId}`,
      body,
      { 
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
         timeout: TIMEOUT
      }
   ).then( response => {
      return {
         data: response.data,
         totalData: 0,
         status: "success",
         error: ""
      }
   }).catch( error => {
      return {
         data: [],
         totalData: 0,
         status: "error",
         error
      }
   })
}

const approveDaftarPersetujuanKonten = (token, univId, body) => {
   return put(`${BASE_URL_LOGIN}be/api/approveUnivContentAdmin?univId=${univId}`,
      body,
      { 
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
         timeout: TIMEOUT
      }
   ).then( response => {
      return {
         data: response.data,
         totalData: 0,
         status: "success",
         error: ""
      }
   }).catch( error => {
      return {
         data: [],
         totalData: 0,
         status: "error",
         error
      }
   })
}

export default {
   getData,
   getDetail,
   getJalurMasuk,
   approveDaftarPersetujuanKonten,
   rejectDaftarPersetujuanKonten
}