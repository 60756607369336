const GET_NEGARA_LOADING = 'GET_NEGARA_LOADING';
const GET_NEGARA_SUCCESS = 'GET_NEGARA_SUCCESS';
const GET_NEGARA_FAILED = 'GET_NEGARA_FAILED';
const CREATE_NEGARA_LOADING = 'CREATE_NEGARA_LOADING';
const CREATE_NEGARA_SUCCESS = 'CREATE_NEGARA_SUCCESS';
const CREATE_NEGARA_FAILED = 'CREATE_NEGARA_FAILED';
const PUT_NEGARA_LOADING = 'PUT_NEGARA_LOADING';
const PUT_NEGARA_SUCCESS = 'PUT_NEGARA_SUCCESS';
const PUT_NEGARA_FAILED = 'PUT_NEGARA_FAILED';
const DELETE_NEGARA_LOADING = 'DELETE_NEGARA_LOADING';
const DELETE_NEGARA_SUCCESS = 'DELETE_NEGARA_SUCCESS';
const DELETE_NEGARA_FAILED = 'DELETE_NEGARA_FAILED';

export {
   GET_NEGARA_LOADING,
   GET_NEGARA_SUCCESS,
   GET_NEGARA_FAILED,
   CREATE_NEGARA_LOADING,
   CREATE_NEGARA_SUCCESS,
   CREATE_NEGARA_FAILED,
   PUT_NEGARA_LOADING,
   PUT_NEGARA_SUCCESS,
   PUT_NEGARA_FAILED,
   DELETE_NEGARA_LOADING,
   DELETE_NEGARA_SUCCESS,
   DELETE_NEGARA_FAILED
}