import React, { Component } from "react";
import { 
   Breadcrumb, Card, Row, Col, Button,
   Typography, Modal, Table
} from "antd";
import {
   TwitterOutlined, WhatsAppOutlined, PhoneOutlined,
   MailOutlined, GlobalOutlined, InstagramOutlined,
   FacebookOutlined
 } from "@ant-design/icons";
import { 
   UItable, UISearch
} from "../../components";
import "../../global/global.css";
import { connect } from "react-redux";
import profileUniversitas from "../../actions/profileUniversitas"; 
import ImagePreviewComponent from '../../components/imagePreview'

const { Text, Title } = Typography;

class ProfileUniversitasDetail extends Component {
   constructor(props) {
      super(props);
      this.state = {
         visibleDetail: false,
         dataRes: null,
         univId: sessionStorage.getItem("univId"),
         paramJalurMasuk: {
            status: null,
            sort: ""
         },
         paramDetailJalurMasuk: {
            search: "",
            sort: "",
            size: 10,
            page: 1
         },
         univEntId: null,
         isReceiveUpload: false
      }
   }

   componentDidMount() {
      const { dataRes, status } = this.props.match.params;
      this.setState({ 
         dataRes, 
         paramJalurMasuk: {
            ...this.state.paramJalurMasuk,
            status
         } 
      }, () => {
         this.props.handleGetDetailProfileUniversitas(this.props.token, this.state.univId, this.state.dataRes);
         this.props.handleGetJalurMasuk(this.props.token, this.state.univId, this.state.paramJalurMasuk);
      });
   } 

   handleClose = () => {
      this.setState({ visibleDetail: false });
   }

   handleOpen = (id, isReceiveUpload) => {
      this.setState({ 
         visibleDetail: true,
         paramDetailJalurMasuk: {
            search: "",
            sort: "",
            size: 10,
            page: 1,
         },
         univEntId: id,
         isReceiveUpload
      }, () => {
         this.props.handleGetDetailJalurMasuk(this.props.token, this.state.univEntId, this.state.paramDetailJalurMasuk);
      });
   }

   handleTableChangeJalurMasuk = (page, filters, sorter) => {
      let sortByValue = "";
      
      if ( sorter.order === "ascend" ) {
         sortByValue = `${sorter.field},asc`;
      } else if ( sorter.order === "descend" ) {
         sortByValue = `${sorter.field},desc`;
      } else {
         sortByValue = "";
      }

      this.setState({
         paramJalurMasuk: {
            ...this.state.paramJalurMasuk,
            sort: sortByValue
         }
      }, () => {
         this.props.handleGetJalurMasuk(this.props.token, this.state.univId, this.state.paramJalurMasuk);
      })
   }

   handleTableChangeDetailJalurMasuk = (page, filters, sorter) => {
      let sortByValue = "";

      if ( sorter.order === "ascend" ) {
         sortByValue = `${sorter.field},asc`
      } else if ( sorter.order === "descend" ) {
         sortByValue = `${sorter.field},desc`
      } else {
         sortByValue = ""
      }

      this.setState({
         paramDetailJalurMasuk: {
            ...this.state.paramDetailJalurMasuk,
            sort: sortByValue,
            page: page.current
         }
      }, () => {
         this.props.handleGetDetailJalurMasuk(this.props.token, this.state.univEntId, this.state.paramDetailJalurMasuk);
      })
   }

   handleSearchDetailJalurMasuk = key => {
      this.setState({
         paramDetailJalurMasuk: {
            ...this.state.paramDetailJalurMasuk,
            search: key,
            page: 1
         }
      }, () => {
         this.props.handleGetDetailJalurMasuk(this.props.token, this.state.univEntId, this.state.paramDetailJalurMasuk);
      })
   }

   handleClickEdit = () => {
      this.props.history.push("/profile-universitas-edit")
   }

   render() {
      const { 
         dataDetailProfileUniversitas, dataJalurMasuk, dataDetailJalurMasuk 
      } = this.props;
      const { 
         visibleDetail, paramDetailJalurMasuk, isReceiveUpload
      } = this.state;
      const { status } = this.props.match.params;

      const columnsTableJalurMasuk = status === "Approved" ? [
         {
            title: "Nama",
            dataIndex: "univEntName",
            key: "univEntName",
            sorter: true,
            render: namaUniversitas => <Text style={{ color: '#EF712B', fontWeight: 600 }}>{namaUniversitas}</Text>
         }, 
         {
            title: "Tautan",
            dataIndex: "univEntUrl",
            key: "univEntUrl",
            sorter: true
         },
         {
            title: "Terima Form",
            dataIndex: "isReceiveUpload",
            key: "isReceiveUpload",
            sorter: true,
            align: "center",
            render: isReceiveUpload => {
               if ( isReceiveUpload ) {
                  return ( <Text>Ya</Text> )
               } else {
                  return ( <Text>Tidak</Text> )
               }
            }
         },
         {
            title: "Action",
            dataIndex: "",
            key: "",
            render: record => <Button className="updateButton" onClick={() => this.handleOpen(record.id, record.isReceiveUpload)}>Detail</Button>
         }
      ] : [
         {
            title: "Nama",
            dataIndex: "univEntName",
            key: "univEntName",
            sorter: true,
            align: "center",
            render: namaUniversitas => <Text style={{ color: '#EF712B', fontWeight: 600 }}>{namaUniversitas}</Text>
         }, 
         {
            title: "Tautan",
            dataIndex: "univEntUrl",
            key: "univEntUrl",
            sorter: true
         },
         {
            title: "Terima Form",
            dataIndex: "isReceiveUpload",
            key: "isReceiveUpload",
            sorter: true,
            align: "center",
            render: isReceiveUpload => {
               if ( isReceiveUpload ) {
                  return ( <Text>Ya</Text> )
               } else {
                  return ( <Text>Tidak</Text> )
               }
            }
         },
         {
            title: "Action",
            dataIndex: "",
            key: "",
            align: "center",
            render: record => {
               if ( record.univEntFormulirName ) {
                  return (
                     <a 
                        href={record.univEntFormulirPath }
                        donwload={ record.univEntFormulirName }
                     >
                        <Button className="donwloadButton">Download</Button>
                     </a>
                  )
               } else {
                  return (
                     <Button className="donwloadButton" disabled>Download</Button>
                  )
               }
            } 
         }
      ];

      const columnsTableDetailJalurMasuk = [
         {
            title: "Nama",
            dataIndex: "fullName",
            key: "fullName",
            sorter: true,
            render: fullName => <Text style={{ color: '#EF712B', fontWeight: 600 }}>{fullName}</Text>
         }, 
         {
            title: "Action",
            dataIndex: "",
            key: "",
            align: "center",
            render: record => (
               <a href={record.uploadedFormPath} donwload={record.uploadedFormName}>
                  <Button className="donwloadButton">Download</Button>
               </a>
            )
         }
      ];

      const imageHumas = dataDetailProfileUniversitas.data.resUnivHumas && dataDetailProfileUniversitas.data.resUnivHumas.univUploadFilePath
      const imageCer = dataDetailProfileUniversitas.data.resUnivCertificate && dataDetailProfileUniversitas.data.resUnivCertificate.univUploadFilePath 
      const imageLogo = dataDetailProfileUniversitas.data.resUnivLogo && dataDetailProfileUniversitas.data.resUnivLogo.univUploadFilePath

      return (
         <div className="root">
            <Breadcrumb className="breadcrumb_style">
               <Breadcrumb.Item>
                  <span style={{ cursor: 'pointer' }} onClick={() => this.props.history.push(`/profile-universitas`)}>Profil Universitas</span>
               </Breadcrumb.Item>
               <Breadcrumb.Item className="pathNow_style">
                  <span>
                     Detail Profil Universitas
                  </span>
               </Breadcrumb.Item>
            </Breadcrumb>
            <Card 
               loading={dataDetailProfileUniversitas.status === "loading"} 
               className="bodyCard_style"
               style={{padding: '10px'}}
            >
               <Row gutter={this.props.isMobile ? [12, 12] :  [24, 0]}>
                  <Col span={this.props.isMobile ? 12 : 4} style={{textAlign: "center"}}>
                     <ImagePreviewComponent
                        isMobile={this.props.isMobile}
                        styles={{ width: '100%' }}
                        uri={imageLogo}
                     />
                     {/* <img 
                        src={ dataDetailProfileUniversitas.data.resUnivLogo ? dataDetailProfileUniversitas.data.resUnivLogo.univUploadFilePath : "" } 
                        alt="Logo Universitas"
                        width="90%"
                     /> */}
                  </Col>
                  <Col span={this.props.isMobile ? 24 : 20}>
                     <Row gutter={[10,10]}>
                        <Col span={12}>
                           <Row gutter={[0, 10]}>
                              <Col span={24}>
                                 <span className="header_text_detail">Nama Universitas</span>
                              </Col>
                              <Col span={24}>
                                 <span className="text_detail_institusi">
                                    {dataDetailProfileUniversitas.data.univName ? dataDetailProfileUniversitas.data.univName : "-" }
                                 </span>
                              </Col>
                              <Col span={24}>
                                 <span className="header_text_detail">Jenis Universitas</span>
                              </Col>
                              <Col span={24}>
                                 <span className="data_text_detail">
                                    {dataDetailProfileUniversitas.data.univCatName ? dataDetailProfileUniversitas.data.univCatName : "-" }
                                 </span>
                              </Col>
                              <Col span={24}>
                                 <span className="header_text_detail">Banner</span>
                              </Col>
                              <Col span={24}>
                                    { dataDetailProfileUniversitas.data.resUnivBanners &&
                                       dataDetailProfileUniversitas.data.resUnivBanners.length > 0 ? 
                                          <Row gutter={[16, 16]} align="middle">
                                             {dataDetailProfileUniversitas.data.resUnivBanners.map( (res, index) => (
                                                <React.Fragment key={index}>
                                                   <Col span={this.props.isMobile ? 24 : 12}>
                                                      <ImagePreviewComponent
                                                         styles={{ width: '100%' }}
                                                         isMobile={this.props.isMobile}
                                                         uri={res.univUploadFilePath}
                                                      />
                                                      {/* <img 
                                                         src={res.univUploadFilePath} 
                                                         alt="Banner"
                                                         width="100%"
                                                      /> */}
                                                   </Col>
                                                   <Col span={this.props.isMobile ? 24 : 12}>
                                                      <span className="header_text_detail">
                                                         {res.univUploadName}
                                                      </span>
                                                   </Col>   
                                                </React.Fragment>
                                             )) 
                                             }
                                          </Row>
                                       :
                                       (
                                          <span className="data_text_detail">
                                             Banner Tidak Ditemukan
                                          </span>
                                       )
                                    }
                              </Col>
                              <Col span={24}>
                                 <span className="header_text_detail">Lokasi Universitas</span>
                              </Col>
                              <Col span={20}>
                                 <span className="data_text_detail">
                                 {dataDetailProfileUniversitas.data.univCompleteAddress ? dataDetailProfileUniversitas.data.univCompleteAddress : "-" }
                                 </span>
                              </Col>
                              <Col span={24}>
                                 <span className="header_text_detail">Tautan Tentang Kami</span>
                              </Col>
                              <Col span={24}>
                                 <a 
                                    className="text_detail_link_jalur_masuk"
                                    href={dataDetailProfileUniversitas.data.univAboutUsUrl ? dataDetailProfileUniversitas.data.univAboutUsUrl : "" }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                 >
                                    {dataDetailProfileUniversitas.data.univAboutUsUrl ? dataDetailProfileUniversitas.data.univAboutUsUrl : "-" }
                                 </a>
                              </Col>
                              <Col span={24}>
                                 <span className="header_text_detail">Tautan Jenjang</span>
                              </Col>
                              <Col span={24}>
                                 <a 
                                    className="text_detail_link_jalur_masuk"
                                    href={dataDetailProfileUniversitas.data.univStageUrl ?   dataDetailProfileUniversitas.data.univStageUrl : "" }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                 >
                                    {dataDetailProfileUniversitas.data.univStageUrl ?   dataDetailProfileUniversitas.data.univStageUrl : "-" }
                                 </a>
                              </Col>
                              <Col span={24}>
                                 <span className="header_text_detail">Tautan Fakultas / Program</span>
                              </Col>
                              <Col span={24}>
                                 <a 
                                    className="text_detail_link_jalur_masuk"
                                    href={dataDetailProfileUniversitas.data.univFacultyUrl ?   dataDetailProfileUniversitas.data.univFacultyUrl : "" }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                 >
                                    {dataDetailProfileUniversitas.data.univFacultyUrl ?   dataDetailProfileUniversitas.data.univFacultyUrl : "-" }
                                 </a>
                              </Col>
                           </Row>
                        </Col>
                        <Col span={12}>
                           <Row gutter={[0, 10]}>
                              <Col span={24}>
                                 <span className="data_text_detail">Social Media</span>
                              </Col>
                              <Col span={24}>
                                 <div className='ulMediaSosial'>
                                    <ul>
                                       <li>
                                          <a 
                                             target="_blank" 
                                             className="listLink"
                                             href={ dataDetailProfileUniversitas.data.univWa 
                                                   ? `https://api.whatsapp.com/send?phone=62${dataDetailProfileUniversitas.data.univWa}` 
                                                   : "" }
                                             rel="noopener noreferrer"
                                          >
                                             <WhatsAppOutlined className='iconStyle'/>&nbsp;
                                             { dataDetailProfileUniversitas.data.univWa 
                                                ? dataDetailProfileUniversitas.data.univWa.length > 40 
                                                   ? `${dataDetailProfileUniversitas.data.univWa.substr(0, 40)}...` 
                                                   : dataDetailProfileUniversitas.data.univWa
                                                : '-' }
                                          </a>
                                       </li>
                                       <li>
                                          <a 
                                             target="_blank" 
                                             className="listLink"
                                             href={ dataDetailProfileUniversitas.data.univPhone 
                                                   ? `tel:${dataDetailProfileUniversitas.data.univPhone}` 
                                                   : "" }
                                             rel="noopener noreferrer"
                                          >
                                             <PhoneOutlined/>&nbsp;
                                             { dataDetailProfileUniversitas.data.univPhone
                                                ? dataDetailProfileUniversitas.data.univPhone.length > 40
                                                   ? `${dataDetailProfileUniversitas.data.univPhone.substr(0, 40)}...`
                                                   : dataDetailProfileUniversitas.data.univPhone
                                                : "-"}
                                          </a>
                                       </li>
                                       <li>
                                          <a 
                                             target="_blank" 
                                             className="listLink"
                                             href={ dataDetailProfileUniversitas.data.univEmail 
                                                   ? `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${dataDetailProfileUniversitas.data.univEmail}` 
                                                   : "" }
                                             rel="noopener noreferrer"
                                          >
                                             <MailOutlined/>&nbsp;
                                             { dataDetailProfileUniversitas.data.univEmail
                                                ? dataDetailProfileUniversitas.data.univEmail.length > 40
                                                   ? `${dataDetailProfileUniversitas.data.univEmail.substr(0, 40)}...`
                                                   : dataDetailProfileUniversitas.data.univEmail
                                                : '-'}
                                          </a>
                                       </li>
                                       <li>
                                          <a 
                                             target="_blank" 
                                             className="listLink"
                                             href={ dataDetailProfileUniversitas.data.univWebsite 
                                                   ? dataDetailProfileUniversitas.data.univWebsite 
                                                   : "" }
                                             rel="noopener noreferrer"
                                          >
                                             <GlobalOutlined/>&nbsp;
                                             { dataDetailProfileUniversitas.data.univWebsite
                                                ? dataDetailProfileUniversitas.data.univWebsite.length > 40
                                                   ? `${dataDetailProfileUniversitas.data.univWebsite.substr(0, 40)}...`
                                                   : dataDetailProfileUniversitas.data.univWebsite
                                                : '-'}
                                          </a>
                                       </li>
                                       <li>
                                          <a 
                                             target="_blank" 
                                             className="listLink"
                                             href={ dataDetailProfileUniversitas.data.univIg 
                                                   ? dataDetailProfileUniversitas.data.univIg.includes('http', 0)
                                                      ? dataDetailProfileUniversitas.data.univIg
                                                      : `https://www.instagram.com/${dataDetailProfileUniversitas.data.univIg}` 
                                                   : "-" }
                                             rel="noopener noreferrer"
                                          >
                                             <InstagramOutlined />&nbsp;
                                             { dataDetailProfileUniversitas.data.univIg
                                             ? dataDetailProfileUniversitas.data.univIg.length > 40
                                                ? `${dataDetailProfileUniversitas.data.univIg.substr(0, 40)}...`
                                                : dataDetailProfileUniversitas.data.univIg
                                             : '-'}
                                          </a>
                                       </li>
                                       <li>
                                          <a 
                                             target="_blank" 
                                             className="listLink"
                                             href={ dataDetailProfileUniversitas.data.univFacebook
                                                   ?  dataDetailProfileUniversitas.data.univFacebook.includes('http', 0)
                                                      ? `${dataDetailProfileUniversitas.data.univFacebook}`
                                                      : `https://www.facebook.com/${dataDetailProfileUniversitas.data.univFacebook}/?ref=br_rs`
                                                   : "" }
                                             rel="noopener noreferrer"
                                          >
                                             <FacebookOutlined />&nbsp;
                                             { dataDetailProfileUniversitas.data.univFacebook
                                                ? dataDetailProfileUniversitas.data.univFacebook.length > 40
                                                   ? `${dataDetailProfileUniversitas.data.univFacebook.substr(0, 40)}...`
                                                   : dataDetailProfileUniversitas.data.univFacebook
                                                : '-' }
                                          </a>
                                       </li>
                                       <li>
                                          <a 
                                             target="_blank" 
                                             className="listLink"
                                             href={ dataDetailProfileUniversitas.data.univTwitter 
                                                   ? dataDetailProfileUniversitas.data.univTwitter.includes('http', 0)
                                                      ? `${dataDetailProfileUniversitas.data.univTwitter}`
                                                      : `https://twitter.com/${dataDetailProfileUniversitas.data.univTwitter}`
                                                   : "" }
                                             rel="noopener noreferrer"
                                          >
                                             <TwitterOutlined />&nbsp;
                                             { dataDetailProfileUniversitas.data.univTwitter
                                                ? dataDetailProfileUniversitas.data.univTwitter.length > 40
                                                   ? `${dataDetailProfileUniversitas.data.univTwitter.substr(0, 40)}...`
                                                   : dataDetailProfileUniversitas.data.univTwitter
                                                : '-' }
                                          </a>
                                       </li>
                                    </ul>
                                 </div>
                              </Col>
                              <Col span={24}>
                                 <span className="header_text_detail">Nama PIC Humas</span>
                              </Col>
                              <Col span={24}>
                                 <span className="data_text_detail">
                                    { dataDetailProfileUniversitas.data.univHumasPic
                                       ? dataDetailProfileUniversitas.data.univHumasPic
                                       : "-" }
                                 </span>
                              </Col>
                              <Col span={24}>
                                 <Row gutter={[16, 0]} align="middle">
                                    <Col span={this.props.isMobile ? 24 : 12}>
                                       <ImagePreviewComponent
                                          styles={{ width: '100%' }}
                                          isMobile={this.props.isMobile}
                                          uri={imageHumas}
                                       />
                                       {/* <img 
                                          src={ dataDetailProfileUniversitas.data.resUnivHumas 
                                                ? dataDetailProfileUniversitas.data.resUnivHumas.univUploadFilePath 
                                                : "" } 
                                          alt="Profile"
                                          width="100%"
                                       /> */}
                                    </Col>
                                    <Col span={this.props.isMobile ? 24 : 12}>
                                    <span className="header_text_detail">
                                       { dataDetailProfileUniversitas.data.resUnivHumas
                                          ? dataDetailProfileUniversitas.data.resUnivHumas.univUploadFileName
                                          : "-" }
                                    </span>
                                    </Col>
                                 </Row>
                              </Col>
                              <Col span={24}>
                                 <span className="header_text_detail">Sertifikat</span>
                              </Col>
                              <Col span={24}>
                                 <Row gutter={[16, 0]} align="middle">
                                    <Col span={this.props.isMobile ? 24 : 12}>

                                       <ImagePreviewComponent
                                          styles={{ width: '100%' }}
                                          isMobile={this.props.isMobile}
                                          uri={imageCer}
                                       />
                                       {/* <img 
                                          src={ dataDetailProfileUniversitas.data.resUnivCertificate 
                                                ? dataDetailProfileUniversitas.data.resUnivCertificate.univUploadFilePath 
                                                : "" } 
                                          alt="Profile"
                                          width="100%"
                                       /> */}
                                    </Col>
                                    <Col span={this.props.isMobile ? 24 : 12}>
                                    <span className="header_text_detail">
                                       { dataDetailProfileUniversitas.data.resUnivCertificate
                                          ? dataDetailProfileUniversitas.data.resUnivCertificate.univUploadFileName
                                          : "-" }
                                    </span>
                                    </Col>
                                 </Row>
                              </Col>
                              <Col span={24}>
                                 <span className="header_text_detail">No. Telepon Humas</span>
                              </Col>
                              <Col span={24}>
                                 <span className="data_text_detail">
                                    { dataDetailProfileUniversitas.data.univHumasPhone 
                                       ? dataDetailProfileUniversitas.data.univHumasPhone
                                       : "-" }
                                 </span>
                              </Col>
                              <Col span={24}>
                                 <span className="header_text_detail">Email Humas</span>
                              </Col>
                              <Col span={24}>
                                 <span className="data_text_detail">
                                    { dataDetailProfileUniversitas.data.univHumasEmail 
                                       ? dataDetailProfileUniversitas.data.univHumasEmail 
                                       : dataDetailProfileUniversitas.data.univHumasEmail }
                                 </span>
                              </Col>
                           </Row>
                        </Col>
                        
                     </Row>
                     
                  </Col>
                  <Col span={24}>
                     <Row gutter={[0, 10]}>
                        <Col span={24}>
                           <span className="data_text_detail">Jalur Masuk</span>
                        </Col>
                        <Col span={24}>
                        <Table
                           dataSource={dataJalurMasuk.data}
                           columns={columnsTableJalurMasuk}
                           loading={dataJalurMasuk.status === "loading"}
                           pagination= {false}
                           rowKey={record => record.id}
                           onChange={(page, filters, sorter) => this.handleTableChangeJalurMasuk(page, filters, sorter)}
                        />
                        </Col>
                        { status === "Approved" &&
                           <Col span={24} style={{textAlign: "right"}}>
                              <span className="data_text_detail" style={{fontSize: "12px"}}>*Data jalur masuk berstatus on approval tidak ditampilkan di detail published</span>
                           </Col>
                        }
                     </Row>
                  </Col>
                  { status === "Approved" && 
                     <Col span={24} style={{textAlign: "right", marginTop: 40}}>
                        <Button onClick={this.handleClickEdit} className="updateButton">Edit</Button>
                     </Col>
                  }
               </Row>
            </Card>
            <Modal
               visible={visibleDetail}
               footer={null}
               className={this.props.isMobile ? 'modalMobile' : 'modalImage'}
               width={this.props.isMobile ? '100%' : 633}
               centered
               onCancel={this.handleClose}
               destroyOnClose={true}
            >
               <Row gutter={[8, 8]}>
                  <Col span={24}>
                     <div className='headerForm'>
                        <Title className='titleModalGalery' level={4}>Detail Jalur Masuk</Title>
                     </div>
                     <div style={{ width: 250}} className='dividerGalery'/>
                  </Col>
                  <Col span={24}>
                     <span className="header_text_detail">Nama</span>
                  </Col>
                  <Col span={24}>
                     <span className="data_text_detail">
                        { dataDetailJalurMasuk.data.univEntName 
                           ? dataDetailJalurMasuk.data.univEntName
                           : "-" }
                     </span>
                  </Col>
                  <Col span={24}>
                     <span className="header_text_detail">Keterangan</span>
                  </Col>
                  <Col span={17}>
                     <span className="data_text_detail">
                        { isReceiveUpload
                           ? "Terima Upload Form dari Calon Mahasiswa"
                           : "Tidak Terima Upload Form dari Calon Mahasiswa" }
                     </span>
                  </Col>
                  <Col span={7} style={{textAlign: "center"}}>
                     { dataDetailJalurMasuk.data.univEntFormulirName
                        ? <a 
                           href={ dataDetailJalurMasuk.data.univEntFormulirPath }
                           donwload={ dataDetailJalurMasuk.data.univEntFormulirName }
                        >
                           <Button className="donwloadButton">Download</Button>
                        </a> 
                        : <Button className="donwloadButton" disabled>Download</Button> }
                  </Col>
                  <Col span={8}>
                     <UISearch placeholder='Search' handleSearch={key => this.handleSearchDetailJalurMasuk(key)}/>
                  </Col>
                  <Col span={24}>
                     <UItable
                        dataTable={dataDetailJalurMasuk.data.resDetailUserUnivEnt}
                        columns={columnsTableDetailJalurMasuk}
                        withRows={false}
                        isMobile={this.props.isMobile}
                        loading={dataDetailJalurMasuk.status}
                        page={paramDetailJalurMasuk.page}
                        totalData={dataDetailJalurMasuk.totalData}
                        handleTableChange={(pageNow, filters, sorter) => this.handleTableChangeDetailJalurMasuk(pageNow, filters, sorter)}
                     />
                  </Col>
               </Row>
            </Modal>
         </div>
      )
   }
}

const mapStateToProps = state => (
   {
      dataDetailProfileUniversitas: state.getDetailProfileUniversitas,
      dataJalurMasuk: state.getProfileUniversitasJalurMasuk,
      dataDetailJalurMasuk: state.getProfileUniversitasDetailJalurMasuk
   }
)

const mapDispatchToProps = dispatch => (
   {
      handleGetDetailProfileUniversitas: (token, univId, dataRes) => dispatch(profileUniversitas.getDetailProfileUniversitas(token, univId, dataRes)),
      handleGetJalurMasuk: (token, univId, param) => dispatch(profileUniversitas.getJalurMasuk(token, univId, param)),
      handleGetDetailJalurMasuk: (token, univEntId, param) => dispatch(profileUniversitas.getDetailJalurMasuk(token, univEntId, param))
   }
)

export default connect(mapStateToProps, mapDispatchToProps)(ProfileUniversitasDetail);