const GET_DAFTAR_PERSETUJUAN_KONTEN_LOADING = 'GET_DAFTAR_PERSETUJUAN_KONTEN_LOADING';
const GET_DAFTAR_PERSETUJUAN_KONTEN_SUCCESS = 'GET_DAFTAR_PERSETUJUAN_KONTEN_SUCCESS';
const GET_DAFTAR_PERSETUJUAN_KONTEN_FAILED = 'GET_DAFTAR_PERSETUJUAN_KONTEN_FAILED';
const GET_DETAIL_DAFTAR_PERSETUJUAN_KONTEN_LOADING = 'GET_DETAIL_DAFTAR_PERSETUJUAN_KONTEN_LOADING';
const GET_DETAIL_DAFTAR_PERSETUJUAN_KONTEN_SUCCESS = 'GET_DETAIL_DAFTAR_PERSETUJUAN_KONTEN_SUCCESS';
const GET_DETAIL_DAFTAR_PERSETUJUAN_KONTEN_FAILED = 'GET_DETAIL_DAFTAR_PERSETUJUAN_KONTEN_FAILED';
const GET_JALUR_MASUK_DAFTAR_PERSETUJUAN_KONTEN_LOADING = 'GET_JALUR_MASUK_DAFTAR_PERSETUJUAN_KONTEN_LOADING';
const GET_JALUR_MASUK_DAFTAR_PERSETUJUAN_KONTEN_SUCCESS = 'GET_JALUR_MASUK_DAFTAR_PERSETUJUAN_KONTEN_SUCCESS';
const GET_JALUR_MASUK_DAFTAR_PERSETUJUAN_KONTEN_FAILED = 'GET_JALUR_MASUK_DAFTAR_PERSETUJUAN_KONTEN_FAILED';
const APPROVE_DAFTAR_PERSETUJUAN_KONTEN_LOADING = 'APPROVE_DAFTAR_PERSETUJUAN_KONTEN_LOADING';
const APPROVE_DAFTAR_PERSETUJUAN_KONTEN_SUCCESS = 'APPROVE_DAFTAR_PERSETUJUAN_KONTEN_SUCCESS';
const APPROVE_DAFTAR_PERSETUJUAN_KONTEN_FAILED = 'APPROVE_DAFTAR_PERSETUJUAN_KONTEN_FAILED';
const REJECT_DAFTAR_PERSETUJUAN_KONTEN_LOADING = 'REJECT_DAFTAR_PERSETUJUAN_KONTEN_LOADING';
const REJECT_DAFTAR_PERSETUJUAN_KONTEN_SUCCESS = 'REJECT_DAFTAR_PERSETUJUAN_KONTEN_SUCCESS';
const REJECT_DAFTAR_PERSETUJUAN_KONTEN_FAILED = 'REJECT_DAFTAR_PERSETUJUAN_KONTEN_FAILED';

export {
  GET_DAFTAR_PERSETUJUAN_KONTEN_LOADING,
  GET_DAFTAR_PERSETUJUAN_KONTEN_SUCCESS,
  GET_DAFTAR_PERSETUJUAN_KONTEN_FAILED,
  GET_DETAIL_DAFTAR_PERSETUJUAN_KONTEN_LOADING,
  GET_DETAIL_DAFTAR_PERSETUJUAN_KONTEN_SUCCESS,
  GET_DETAIL_DAFTAR_PERSETUJUAN_KONTEN_FAILED,
  GET_JALUR_MASUK_DAFTAR_PERSETUJUAN_KONTEN_LOADING,
  GET_JALUR_MASUK_DAFTAR_PERSETUJUAN_KONTEN_SUCCESS,
  GET_JALUR_MASUK_DAFTAR_PERSETUJUAN_KONTEN_FAILED,
  APPROVE_DAFTAR_PERSETUJUAN_KONTEN_LOADING,
  APPROVE_DAFTAR_PERSETUJUAN_KONTEN_SUCCESS,
  APPROVE_DAFTAR_PERSETUJUAN_KONTEN_FAILED,
  REJECT_DAFTAR_PERSETUJUAN_KONTEN_LOADING,
  REJECT_DAFTAR_PERSETUJUAN_KONTEN_SUCCESS,
  REJECT_DAFTAR_PERSETUJUAN_KONTEN_FAILED,
}
