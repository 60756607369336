import React, { useState } from 'react'
import { Row, Col, Button, notification, Popconfirm, Divider } from 'antd'
import { post } from 'axios'
import config from '../../services/config'
import ImagePreviewComponent from '../../components/imagePreview'
const { BASE_URL_LOGIN, TIMEOUT } = config

const DetailContentTemaBidiktiCore = props => {
  const dataDetail = props.dataDetail;
  const [laguage, setLaguage] = useState('id')
  const [listImage, setListImage] = useState(dataDetail.listDataBwImage)
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [imageChange, setChangeImage] = useState(null)

  const handleEditImage = (id, index) => {
    setChangeImage({ id: id, index: index })
    openFileBrowser()
  }

  const fileInput = React.createRef();

  const openFileBrowser = () => {
    fileInput.current.click();
  };

  const fileHandler = event => {
    if (event.target.files.length) {
      const file = event.target.files;
      const formData = new FormData();
      for (let i = 0; i < file.length; i++) {
        formData.append(`file`, file[i])
      }
      uploadAssignmentLetter(formData);
    }
  };

  const uploadAssignmentLetter = (data) => {
    setLoadingUpload(true);
    post(`${BASE_URL_LOGIN}be/api/upload/oneBanner`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${props.token}`,
      },
      timeout: TIMEOUT
    }).then(res => {
      if (res && res.data && res.data.Status ? res.data.Status === "OK" : false) {
        changeImage(res.data)
        notification.success({
          placement: 'bottomRight',
          message: 'Succes',
          description: res && res.data && res.data.Message ? res.data.Message : "Edit Banner Success"
        });
      } else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: res && res.data && res.data.Message ? res.data.Message : "Error Upload"
        });
      }
      setLoadingUpload(false);
    }).catch(() => {
      notification.error({
        placement: 'bottomRight',
        message: 'Error',
        description: 'Upload Failed'
      });
      setLoadingUpload(false);
    });
  }

  const changeImage = (data) => {
    let temp = listImage
    if (imageChange.index === null) {
      temp.push({
        bwImgId: imageChange.id,
        bwImg: data.linkBanner,
        bwImgPath: data.filePath
      })
    }else {
      temp[imageChange.index] = {
        bwImgId: imageChange.id,
        bwImg: data.linkBanner,
        bwImgPath: data.filePath
      }
    }
    setListImage([...temp])
    props.saveImage(temp)
  }

  const handleDeleteImage = index => {
    let temp = listImage
    temp.splice(index, 1)
    setListImage([...temp])
    props.saveImage(temp)
  }

  const text = 'Apakah anda yakin menghapus banner ini ?'

  return (
    <Row gutter={[10, 8]}>
      <Col span={12}>
        <Col span={24}>
          <Button onClick={() => setLaguage('id')} className={`${laguage === 'id' ? 'updateButton' : 'nonActiveButton'}`}>B. Indonesia</Button>
          <Divider type="vertical" />
          <Button onClick={() => setLaguage('eng')} className={`${laguage === 'eng' ? 'updateButton' : 'nonActiveButton'}`}>B. Inggris</Button>
        </Col>
        <Col span={24}>
          <span className='header_text_detail'>
            Nama Tema BIDikTi Week
          </span>
        </Col>
        <Col span={24}>
          <span className='text_detail_institusi'>
            {laguage === 'id' ? dataDetail.bwThemeInd : dataDetail.bwThemeEng}
          </span>
        </Col>
        <Col span={24}>
          <span className='header_text_detail'>
            Deskripsi Tema BIDikTi Week
          </span>
        </Col>
        <Col span={24}>
          <span className='data_text_detail'>
            {laguage === 'id' ? dataDetail.bwDescInd : dataDetail.bwDescEng}
          </span>
        </Col>
        <Col span={24}>
          <span className='header_text_detail'>
            Narasi Judul Pendaftaran Universitas dan Sekolah Kedinasan
          </span>
        </Col>
        <Col span={24}>
          <span className='data_text_detail'>
            {laguage === 'id' ? dataDetail.bwNarationTitleInd : dataDetail.bwNarationTitleEng}
          </span>
        </Col>
        <Col span={24}>
          <span className='header_text_detail'>
            Narasi Deskripsi Pendaftaran Universitas dan Sekolah Kedinasan
          </span>
        </Col>
        <Col span={24}>
          <span className='data_text_detail'>
            {laguage === 'id' ? dataDetail.bwNarationDescInd : dataDetail.bwNarationDescEng}
          </span>
        </Col>
        <Col span={24}>
          <span className='header_text_detail'>
            Durasi Pendaftaran
          </span>
        </Col>
        <Col span={24}>
          <span className='data_text_detail'>
            {`${dataDetail.bwUnivRegStart} s/d ${dataDetail.bwUnivRegFinish}`}
          </span>
        </Col>
        <Col span={24}>
          <span className='header_text_detail'>
            Durasi Pelaksanaan
          </span>
        </Col>
        <Col span={24}>
          <span className='data_text_detail'>
            {`${dataDetail.bwDurationStart} s/d ${dataDetail.bwDurationFinish}`}
          </span>
        </Col>
      </Col>
      <Col span={12}>
        <Col span={24}>
          <span className='header_text_detail' style={{ color: 'white' }}>
            -
          </span>
        </Col>
        <Col span={24}>
          <span className='header_text_detail'>
            Status
          </span>
        </Col>
        <Col span={24}>
          <span className='text_detail_institusi'>
            {dataDetail.bwStatus}
          </span>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={8}>
              <span className='data_text_detail'>
                Tanggal presentasi
              </span>
            </Col>
            <Col span={14} style={{ textAlign: 'right' }}>
              <Button onClick={() => props.openModal('schedule')} className='updateButton'>Detail</Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={8}>
              <span className='data_text_detail'>
                Sesi
              </span>
            </Col>
            <Col span={14} style={{ textAlign: 'right' }}>
              <Button onClick={() => props.openModal('sesi')} className='updateButton'>Detail</Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={8}>
              <span className='data_text_detail'>
                Banner *
              </span>
            </Col>
            {listImage.length < 3 ? (
            <Col span={14} style={{ textAlign: 'right' }}>
              <input
                type="file"
                hidden
                onChange={fileHandler}
                ref={fileInput}
              />
              <Button type="link" disabled={dataDetail.bwStatus === 'Non Active'} loading={loadingUpload} className='updateButton' onClick={() => handleEditImage(null, null)}>Tambah</Button>
            </Col>
            ) : (
              <></>
            )}
          </Row>
        </Col>
        {listImage ?
          listImage.map((res, index) => (
            <Col key={index + 'image'} span={24}>
              <Row>
                <Col span={props.isMobile ? 24 : 8}>
                  <ImagePreviewComponent
                    isMobile={props.isMobile}
                    styles={{ 
                      width: props.isMobile ? '100%' : 160, 
                      height: props.isMobile ? '100%' : 100 
                    }}
                    uri={res.bwImg}
                  />
                  {/* <img height={100} width={160} src={res.bwImg+'?'+new Date().getTime()} alt={'banner' + index} /> */}
                </Col>
                {dataDetail.bwStatus === 'Active' &&
                  <Col offset={props.isMobile ? 0 : 6} span={ props.isMobile ? 24 : 8} style={{ textAlign: 'right' }}>
                    <input
                      type="file"
                      hidden
                      onChange={fileHandler}
                      ref={fileInput}
                    />
                    <Button type="link" disabled={dataDetail.bwStatus === 'Non Active'} loading={loadingUpload} className='editButton' onClick={() => handleEditImage(res.bwImgId, index)}>Edit</Button>
                    <Popconfirm
                      placement="bottomRight"
                      title={text}
                      onConfirm={() => handleDeleteImage(index)}
                      okText="Yes"
                      cancelText="No"
                      disabled={dataDetail.bwStatus === 'Non Active'}
                    >
                      <Button type="link" disabled={dataDetail.bwStatus === 'Non Active'} className='deleteButton'>Delete</Button>
                    </Popconfirm>
                  </Col>
                }
              </Row>
            </Col>
          )) : <></>
        }
        {dataDetail.bwStatus === 'Active' &&
          <div>
            <Col span={24} style={{textAlign: "right"}}>
              <span className="data_text_detail" style={{fontSize: "12px"}}>*Data banner akan berubah setelah user klik tombol Submit di popup Edit Tema BIDikTi Week </span>
            </Col>
            <Col span={22} style={{ textAlign: 'right' }}>
              <Button disabled={dataDetail.bwStatus === 'Non Active'} onClick={() => props.openModal('tema')} className='updateButton'>Edit</Button>
            </Col>
          </div>
        }
      </Col>
    </Row >
  )
}


export const UIContentTemaBidiktiDetail = DetailContentTemaBidiktiCore
