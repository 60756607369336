import {get, put} from 'axios'
import config from '../services/config'
const { BASE_URL_LOGIN, TIMEOUT } = config
const getLateRegister = (token, sort, size, page, search) => {
  return get(`${BASE_URL_LOGIN}be/api/bwLateRegisterAdminList?sort=${sort}&size=${size}&page=${page - 1}&search=${search}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.Data.map(res =>{
      return {
        univRegId: res.univRegId,
        id: res.bwLateRegId,
        bwLateRegId: res.bwLateRegId,
        bwLateRegPicWa: res.bwLateRegPicWa,
        bwLateRegPicEmail: res.bwLateRegPicEmail,
        isKedutaan: res.isKedutaan,
        bwLateRegPicName: res.bwLateRegPicName,
        univRegName: res.univRegName,
        status: res.status,
        isPastEvent: res.isPastEvent,
        bwDurationFinish: res.bwDurationFinish
      }
    })
    return {
      data: normalizeData,
      totalData: response.data.totalData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      totalData: 0,
      error,
      data: []
    }
  })
}

const rejectLateRegister = (token, body) => {
  return put(`${BASE_URL_LOGIN}be/api/bwAdminRejectedLateRegister`,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: '',
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: [],
    }
  })
}

export default {
  getLateRegister,
  rejectLateRegister,
}
