import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Typography, Button, Card, Spin, Divider, Popover } from 'antd'
import dashboardBeasiswa from '../../../actions/dashboardBeasiswa'
import { HorizontalBar, Pie } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { InfoCircleOutlined, ReloadOutlined } from '@ant-design/icons'
const { Title, Text } = Typography
const screenWidth = window.innerWidth
const infoDetail = (
  <div>
    <span>{`Beasiswa yang sedang berlangsung`}</span>
  </div>
)

const infoKategori = (
  <div>
    <span>{`Jumlah Kategori yang akan dan sedang berlangsung`}</span>
  </div>
)

class beasiswaDashboard extends Component {
  componentDidMount() {
    this.getData()
  }

  getData() {
    this.props.handleDataDashboarBeasiwa(this.props.token)
  }

  render() {
    const { dashboardBeasiswa } = this.props
    const dataTemp = dashboardBeasiswa.data && dashboardBeasiswa.data.dataPie &&
      dashboardBeasiswa.data.dataPie.datasets &&
      dashboardBeasiswa.data.dataPie.datasets[0] &&
      dashboardBeasiswa.data.dataPie.datasets[0].data ?
      dashboardBeasiswa.data.dataPie.datasets[0].data : []
    const colorTemp = dashboardBeasiswa.data && dashboardBeasiswa.data.dataPie &&
      dashboardBeasiswa.data && dashboardBeasiswa.data.dataPie &&
      dashboardBeasiswa.data.dataPie.datasets[0] &&
      dashboardBeasiswa.data.dataPie.datasets[0].backgroundColor
    const labelTemp = dashboardBeasiswa.data && dashboardBeasiswa.data.dataPie &&
      dashboardBeasiswa.data.dataPie.labels

    const options = {
      legend: {
        display: false,
        labels: {
          padding: 10,
          boxWidth: 10
        }
      },
      plugins: {
        datalabels: {
          anchor: 'center',
          color: '#ADA7A7',
          font: {
            weight: 'bold'
          }
        }
      },

      // Core options
      aspectRatio: 5 / 3,
      layout: {
        padding: {
          top: 20,
          right: 16,
          bottom: 0,
          left: 8
        }
      },
      elements: {
        line: {
          fill: false
        },
        point: {
          hoverRadius: 7,
          radius: 5
        }
      }
    }

    const optionsBar = {
      scales: {
        xAxes: [{
          ticks: {
            beginAtZero: true,
          },
        }]
      },
      legend: {
        display: false,
        labels: {
          padding: 0,
          boxWidth: 0
        }
      },
      plugins: {
        datalabels: {
          anchor: 'end',
          color: '#ADA7A7',
          font: {
            weight: 'bold'
          },
          align: 'end'
        }
      },
      // Core options
      aspectRatio: 4 / 3,
      layout: {
        padding: screenWidth < 900 ? 20 : 32
      },
      elements: {
        line: {
          fill: false
        },
        point: {
          hoverRadius: 7,
          radius: 5
        }
      },

    }

    if (dashboardBeasiswa && dashboardBeasiswa.status == 'loading') {
      return (
        <div className="home-body">
          <Card>
            <Row justify='center' align='middle'>
              <Col>
                <Spin />
              </Col>
            </Row>
          </Card>
        </div>
      )
    }

    return (
      <div className="home-body">
        <Card className="bodyCard_style">
          {
            dashboardBeasiswa && dashboardBeasiswa.status == 'success' ?
              <Row justify='center'>
                <Row gutter={[24, 12]}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <Title level={4}>{`Beasiswa & Life Sharing`}</Title>
                  </Col>
                </Row>
                <Divider />
                <Col xs={24} xl={6}>
                  <Row gutter={[0, 15]}>
                    <Col span={24} style={{ textAlign: 'center' }}>
                      <div style={{ color: '#072A6F', fontWeight: 'bold' }}>Jumlah Beasiswa Aktif <Popover content={infoDetail}><InfoCircleOutlined /></Popover></div>
                    </Col>
                    <Col span={24}>
                      <Pie
                        data={dashboardBeasiswa.data && dashboardBeasiswa.data.dataPie}
                        options={options}
                      />
                    </Col>
                    <Col span={24}>
                      <table >
                        {
                          dataTemp && dataTemp.map((res, index) => {
                            return (
                              <tr>
                                <td><div style={{ height: 10, width: 10, backgroundColor: colorTemp[index] ? colorTemp[index] : '#ddd', borderRadius: 10 }} /></td>
                                <td><div style={{ marginLeft: 20 }}>{labelTemp[index]}</div></td>
                                {/* <td>{res}</td> */}
                              </tr>
                            )
                          })
                        }

                      </table>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} xl={18}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <div style={{ color: '#072A6F', fontWeight: 'bold' }}>Kategori Life Sharing <Popover content={infoKategori}><InfoCircleOutlined /></Popover></div>
                  </Col>
                  <HorizontalBar
                    data={dashboardBeasiswa.data && dashboardBeasiswa.data.dataBar}
                    options={optionsBar}
                  />
                </Col>
              </Row> :
              <Row justify='center' align='middle'>
                <Col span={24} style={{ textAlign: 'center', margin: 10 }}>
                  <ReloadOutlined style={{ fontSize: 64 }} />
                </Col>
                <Col>
                  {/* <Button
                    type='text'
                    disabled={true}
                  onClick={()=> this.getData()}
                >Reload</Button> */}
                </Col>
              </Row>
          }
        </Card>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleDataDashboarBeasiwa: (token) => dispatch(dashboardBeasiswa.getData(token))
  }
}

const mapStateToProps = (state) => {
  return {
    dashboardBeasiswa: state.dashboardBeasiswa,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(beasiswaDashboard)