import types from '../constants';

const initialState = {
   status: '',
   data: []
}

export default function putMasterNegara(state = initialState, action) {
   switch (action.type) {
      case types.PUT_NEGARA_LOADING:
         return {
            ...state,
            ...action.payload
         }
      case types.PUT_NEGARA_SUCCESS:
         return {
            isSuccess: true,
            ...state,
            ...action.payload
         }
      case types.PUT_NEGARA_FAILED:
         return {
            isSuccess: false,
            ...state,
            ...action.payload
         }
      default:
         return { ...initialState, ...state }
   }
}