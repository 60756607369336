import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../global/global.css'
import getPresentation from '../../actions/presentation'
import {
  Card,
  Row,
  Col,
  Breadcrumb,
  Typography,
} from 'antd'
import {UISearch, UItable} from '../../components'

const { Text } = Typography

class listPresentation extends Component{
  constructor(props) {
    super(props);
    this.state ={
      initial: '',
      selectedRowKeys: [],
      orderBy: '',
      size: 10,
      page: 1,
      search: '',
    }
  }

  componentDidMount() {
    const {orderBy, size, page, search } = this.state
    this.props.handleDataPresentation(this.props.token, orderBy, size, page, search)
  }

  handleRedirectDetail = id => {
    this.props.history.push(`/presentation-detail/${id}`)
  }

  handleTableChange = (page, filter, sorter) => {
    const { size, search} =this.state

    let sorterField = ""
    if ( sorter.field === "univRegName" ) {
      sorterField = "e.univ_reg_name";
    }else if ( sorter.field === "bwUnivSpeaker" ) {
      sorterField = "bw_univ_speaker";
    } else if ( sorter.field === "bwScheduleDay" ) {
      sorterField = "d.bw_schedule_day";
    }else if ( sorter.field === "bwScheduleDate" ) {
      sorterField = "d.bw_schedule_date";
    }else if ( sorter.field === "bwSessionName" ) {
      sorterField = "c.bw_session_name";
    }else {
      sorterField = "is_online";
    }

    let orderBy = "";
    if (sorter.order === "ascend" ) {
      orderBy = `${sorterField},asc`;
    } else if (sorter.order === "descend") {
      orderBy = `${sorterField},desc`;
    }

    this.setState({
      page: page.current,
      orderBy: orderBy
    }, ()=> {
      this.props.handleDataPresentation(this.props.token, orderBy, size, page.current, search)
    })
  }

  handleSearch = key => {
    const {orderBy, size} = this.state
    this.setState({
      search: key,
      page: 1
    })
    this.props.handleDataPresentation(this.props.token, orderBy, size, 1, key)
  }

  render() {
    const columnsTable = [
      {
        title: 'Nama Institusi',
        dataIndex: 'univRegName',
        key: 'univRegName',
        sorter: true,
        render: univRegName => <Text style={{ color: '#EF712B', cursor: 'pointer', fontWeight: 600}}>{univRegName}</Text>,
        onCell: record => {
          return{
            onClick: () => {
              this.handleRedirectDetail(record.bwUnivId,)
            }
          }
        }
      },
      {
        title: 'Narasumber',
        dataIndex: 'bwUnivSpeaker',
        key: 'bwUnivSpeaker',
        sorter: true,
      },
      {
        title: 'Hari',
        dataIndex: 'bwScheduleDay',
        key: 'bwScheduleDay',
        sorter: true,
      },
      {
        title: 'Tanggal',
        dataIndex: 'bwScheduleDate',
        key: 'bwScheduleDate',
        sorter: true,
      },
      {
        title: 'Sesi',
        dataIndex: 'bwSessionName',
        key: 'bwSessionName',
        sorter: true,
      },
      {
        title: 'Presentasi',
        dataIndex: 'isOnline',
        key: 'isOnline',
        sorter: true,
        render: isOnline => {
          if (isOnline) {
            return(
              <Text style={{ color: '#EF712B', cursor: 'pointer', fontWeight: 600 }}>Online</Text>
            )
          } else {
            return(
              <Text style={{ color: '#11174C', cursor: 'pointer', fontWeight: 600 }}>Offline</Text>
              )
          }
        }
      },
    ]
    const { dataPresentation } = this.props
    const { page } = this.state
    return (
      <div className='root'>
        <Breadcrumb className="breadcrumb_style">
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/dashboard`)}>BIDikTi Week </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className='pathNow_style'>
            Daftar Presentasi
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card className='bodyCard_style'>
          <Row>
            <Col span={8}>
              <UISearch placeholder='Search' handleSearch={key => this.handleSearch(key)} />
            </Col>
          </Row>
          <Card bordered={false} loading={dataPresentation.loading} style={{minHeight: '300px'}}>
            <UItable
              // rowSelection={rowSelection}
              dataTable={dataPresentation.data}
              columns={columnsTable}
              isMobile={this.props.isMobile}
              withRows={false}
              loading={dataPresentation.status}
              page={page}
              totalData={dataPresentation.totalData}
              handleTableChange={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter)}
            />
          </Card>
        </Card>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return{
    handleDataPresentation: (token, sort, size, page, search) => dispatch(getPresentation.getDataPresentation(token, sort, size, page, search))
  }
}

const mapStateToProps = (state) => {
  return {
    dataPresentation: state.presentation,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(listPresentation)
