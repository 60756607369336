import React, {Component} from 'react';
import {connect} from 'react-redux';
import '../../../global/global.css';
import getLifeSharing from '../../../actions/lifeSharingEvent'
import {
  Card,
  notification,
  Button,
  Row,
  Col,
  Breadcrumb,
  Typography, Popconfirm
} from 'antd'
import {UItable, UISearch, UIModalLifeSharingEvent} from '../../../components'

const {Text} = Typography

class LifeSharingEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initial: '',
      orderBy: '',
      size: 10,
      page: 1,
      search: '',
      visibleRegis: false,
      loadingSubmit: false,
      loadingDelete: false,
    }
  }

  componentDidMount() {
    const {orderBy, size, page, search} = this.state;
    this.props.handleLOVLifeSharingType(this.props.token);
    this.props.handleDataList(this.props.token, orderBy, size, page, search)
  }

  handleRedirectDetail = id => {
    this.props.history.push(`/life-sharing-event-detail/${id}`)
  }

  handleSearch = key => {
    const {orderBy, size} = this.state
    this.setState({
      search: key,
      page: 1
    })
    this.props.handleDataList(this.props.token, orderBy, size, 1, key)
  }

  handleRegisSubmit = (value) => {
    this.setState({
      loadingSubmit: true
    })
    this.props.handlePostEvent(this.props.token, value).then(res => {
      if (res.payload.status !== 'error') {
        if (res.payload.data.status === 200) {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Registrasi Event Success',
          });
          setTimeout(() => {
            this.props.history.push(`/life-sharing-event`)
          },2000);
          this.setState({
            search: '',
            page: 1,
            orderBy: '',
            loadingSubmit: false,
            visibleRegis: false
          }, () => {
            this.props.handleDataList(this.props.token, '', 10, 1, '')
          })
        } else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Registrasi Event Failed'
          });
          this.setState({
            loadingSubmit: false,
            visibleRegis: false
          })
        }
      } else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: 'Registrasi Event Failed'
        });
        this.setState({
          loadingSubmit: false,
          visibleRegis: false
        })
      }
    })
  }

  handleRegisClose = () => {
    this.setState({
      visibleRegis: false
    })
  }

  openModal = () => {
    this.setState({
      visibleRegis: true
    })
  }

  onChangeSchedule = val => {
    // this.props.handleLOVSession(this.props.token, val)
  }

  handleTableChange = (page, filter, sorter) => {
    const {size, search} = this.state
    let sorterField
    if (sorter.field === "sharingEventSpeaker") {
      sorterField = "sharing_event_speaker";
    } else if (sorter.field === "sharingTypeName") {
      sorterField = "st.sharing_type_name";
    } else if (sorter.field === "sharingEventDay") {
      sorterField = "sharing_event_day";
    } else if (sorter.field === "sharingEventDate") {
      sorterField = "sharing_event_date";
    } else {
      sorterField = "is_online";
    }

    let orderBy = "";
    if (sorter.order === "ascend") {
      orderBy = `${sorterField},asc`;
    } else if (sorter.order === "descend") {
      orderBy = `${sorterField},desc`;
    }

    this.setState({
      page: page.current,
      orderBy: orderBy
    }, () => {
      this.props.handleDataList(this.props.token, orderBy, size, page.current, search)
    })
  }

  onSearch = (e) => {
    // this.props.handleLOVInstansi(this.props.token, e, 10, this.state.isKedutaan)

  }

  confirm = (record) => {
    this.handleDelete(record);
  }

  cancel = () => {
  }

  handleDelete = (id) => {
    this.setState({
      loadingDelete: true,
    })
    let body = {
      userId: sessionStorage.getItem('userId')
    }
    this.props.handleDelete(this.props.token, id, body).then(res => {
      if (res.payload.status !== 'error') {
        if (res.payload.data.status === 200) {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Delete Event Success',
          });
          setTimeout(() => {
            this.props.history.push(`/life-sharing-event`)
          },2000);
          this.setState({
            search: '',
            page: 1,
            orderBy: '',
            loadingDelete: false,
          }, () => {
            this.props.handleDataList(this.props.token, '', 10, 1, '')
          })
        }else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Delete Event Failed'
          });
          this.setState({
            loadingDelete: false,
          })
        }
      }else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: res.payload.error.response.data.message ? res.payload.error.response.data.message : 'Delete Event Failed'
        });
        this.setState({
          loadingDelete: false,
        })
      }
    })
  }

  render() {

    const columnsTable = [
      {
        title: 'Narasumber',
        dataIndex: 'sharingEventSpeaker',
        key: 'sharingEventSpeaker',
        sorter: true,
        render: name => <Text style={{color: '#EF712B', cursor: 'pointer', fontWeight: 600}}>{name}</Text>,
        onCell: record => {
          return {
            onClick: () => {
              this.handleRedirectDetail(record.sharingEventId ? record.sharingEventId : '')
            }
          }
        }
      },
      {
        title: 'Kategori',
        dataIndex: 'sharingTypeName',
        key: 'sharingTypeName',
        sorter: true
      },
      {
        title: 'Hari',
        dataIndex: 'sharingEventDay',
        key: 'sharingEventDay',
        sorter: true
      },
      {
        title: 'Tanggal',
        dataIndex: 'sharingEventDate',
        key: 'sharingEventDate',
        sorter: true
      },
      {
        title: 'Presentasi',
        dataIndex: 'isOnline',
        key: 'isOnline',
        sorter: true,
        render: status => {
          if (status === true) {
            return (
              <Text style={{color: '#EF712B', cursor: 'pointer', fontWeight: 600}}>Online</Text>
            )
          } else {
            return (
              <Text style={{color: '#11174C', cursor: 'pointer', fontWeight: 600}}>Offline</Text>
            )
          }
        }
      },
      {
        title: 'Action',
        dataIndex: '',
        key: '',
        render: record =>
          <Popconfirm
            title="Are you sure delete this?"
            onConfirm={() => this.confirm(record.sharingEventId ? record.sharingEventId : '')}
            onCancel={() => this.cancel()}
            okText="Yes"
            cancelText="No"
          >
            <Button
              loading={this.state.loadingDelete}
              type='link'
              style={{ color: '#e22837', cursor: 'pointer', fontWeight: 600 }}
            >
              Delete
            </Button>
          </Popconfirm>
      },
    ]

    const {
      dataLOVLifeSharingType,
      dataListEvent,
    } = this.props

    const {
      visibleRegis,
      page,
      loadingSubmit,
    } = this.state

    return (
      <div className='root'>
        <Breadcrumb className="breadcrumb_style">
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/dashboard`)}>Life Sharing </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className='pathNow_style'>
            Daftar Acara
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card className='bodyCard_style'>
          <Row>
            <Col span={8}>
              <UISearch placeholder='Search' handleSearch={key => this.handleSearch(key)}/>
            </Col>
            <Col span={16} style={{textAlign: 'right'}}>
              <Button onClick={() => this.openModal()} className='updateButton'>Tambah Acara</Button>
            </Col>
          </Row>
          <Card
            bordered={false}
            // loading={dataListEvent.status}
            style={{minHeight: '300px'}}>
            <UItable
              dataTable={dataListEvent.data}
              columns={columnsTable}
              withRows={false}
              isMobile={this.props.isMobile}
              totalData={dataListEvent.totalData}
              loading={dataListEvent.status}
              page={page}
              handleTableChange={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter)}
            />
          </Card>
        </Card>
        <UIModalLifeSharingEvent
          visibleRegis={visibleRegis}
          title={'Tambah Acara Life Sharing'}
          isMobile={this.props.isMobile}
          onChangeSchedule={e => this.onChangeSchedule(e)}
          onChangeInstitusi={e => this.onChangeInstitusi(e)}
          radioChange={val => this.radioChange(val)}
          onSearch={(e) => this.onSearch(e)}
          loading={false}
          loadingSubmit={loadingSubmit}
          onFinish={(value) => this.handleRegisSubmit(value)}
          close={() => this.handleRegisClose()}
          listLSCat={dataLOVLifeSharingType.data}
          tokenUser={this.props.token}
        />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handlePostEvent: (token, body) => dispatch(getLifeSharing.createLSEvent(token, body)),
    handleLOVLifeSharingType: (token) => dispatch(getLifeSharing.getDataLOVSharingType(token)),
    handleDataList: (token, sort, size, page, search) => dispatch(getLifeSharing.getDataEvent(token, sort, size, page, search)),
    handleDelete: (token, id, body) => dispatch(getLifeSharing.deleteDataEvent(token, id, body)),
  }
}

const mapStateToProps = (state) => {
  return {
    dataLOVLifeSharingType: state.getLOVSharingType,
    dataListEvent: state.getLifeSharingEvent,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LifeSharingEvent)
