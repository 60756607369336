const GET_SETTING_LIFE_SHARING_LOADING = 'GET_SETTING_LIFE_SHARING_LOADING'
const GET_SETTING_LIFE_SHARING_SUCCESS = 'GET_SETTING_LIFE_SHARING_SUCCESS'
const GET_SETTING_LIFE_SHARING_FAILED = 'GET_SETTING_LIFE_SHARING_FAILED'
const PUT_SETTING_LIFE_SHARING_LOADING = 'PUT_SETTING_LIFE_SHARING_LOADING'
const PUT_SETTING_LIFE_SHARING_SUCCESS = 'PUT_SETTING_LIFE_SHARING_SUCCESS'
const PUT_SETTING_LIFE_SHARING_FAILED = 'PUT_SETTING_LIFE_SHARING_FAILED'

export {
  GET_SETTING_LIFE_SHARING_LOADING,
  GET_SETTING_LIFE_SHARING_SUCCESS,
  GET_SETTING_LIFE_SHARING_FAILED,
  PUT_SETTING_LIFE_SHARING_LOADING,
  PUT_SETTING_LIFE_SHARING_SUCCESS,
  PUT_SETTING_LIFE_SHARING_FAILED,
}
