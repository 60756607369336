import React from "react";
import { Row, Col, Button, Popconfirm } from "antd";
import ImagePreviewComponent from '../../components/imagePreview'

const DetailContentLSEventCore = (props) => {
  const dataDetail = props.dataDetail;

  return (
    <Row gutter={[10, 10]}>
      <Col span={12}>
        <Col span={24}>
          <span className="header_text_detail">Narasumber</span>
        </Col>
        <Col span={24}>
          <span className="text_detail_institusi">
            {dataDetail.sharingEventSpeaker
              ? dataDetail.sharingEventSpeaker
              : "-"}
          </span>
        </Col>
        <Col span={24}>
          <span className="header_text_detail">Tahun Lulus KEKL</span>
        </Col>
        <Col span={24}>
          <span className="data_text_detail">
            {dataDetail.sharingEventKeklGradYear
              ? dataDetail.sharingEventKeklGradYear
              : "-"}
          </span>
        </Col>
        <Col span={24}>
          <span className="header_text_detail">Banner</span>
        </Col>
        <Col span={props.isMobile ? 24 : 8}>
          <ImagePreviewComponent
            isMobile={props.isMobile}
            styles={{ width: props.isMobile ? '100%' : 130 }}
            uri={dataDetail.sharingEventThumbnail}
          />
        {/* <img
          alt="logo"
          width={130}
          src={dataDetail.sharingEventThumbnail}
        /> */}
        </Col>
        <Col span={24}>
          <span className="header_text_detail">
            Deskripsi Acara / Narasumber
          </span>
        </Col>
        <Col span={24}>
          <span className="data_text_detail">
            {dataDetail.sharingEventDesc ? dataDetail.sharingEventDesc : "-"}
          </span>
        </Col>
      </Col>
      <Col span={12}>
        <Col span={24}>
          <span className="header_text_detail">Hari/Tanggal Presentasi</span>
        </Col>
        <Col span={24}>
          <span className="data_text_detail">
            {dataDetail.sharingEventDay}, {dataDetail.sharingEventDate}
          </span>
        </Col>
        <Col span={24}>
          <span className="header_text_detail">Waktu</span>
        </Col>
        <Col span={24}>
          <span className="data_text_detail">
            {dataDetail.sharingEventTime}
          </span>
        </Col>
        <Col span={24}>
          <span className="header_text_detail">Kuota</span>
        </Col>
        <Row gutter={[0, 8]}>
          <Col span={10}>
            <span className="data_text_detail">Loyola</span>
          </Col>
          <Col style={{ textAlign: "right" }} span={10}>
            <span className="header_text_detail">
              {dataDetail.sharingEventReminderLoyolaQuota}
            </span>
            &nbsp;
            <span className="header_text_detail">/</span>&nbsp;
            <span className="text_detail_institusi">
              {dataDetail.sharingEventLoyolaQuota}
            </span>
          </Col>
          <Col span={10}>
            <span className="data_text_detail">Umum</span>
          </Col>
          <Col style={{ textAlign: "right" }} span={10}>
            <span className="header_text_detail">
              {dataDetail.sharingEventReminderOtherQuota}
            </span>
            &nbsp;
            <span className="header_text_detail">/</span>&nbsp;
            <span className="text_detail_institusi">
              {dataDetail.sharingEventOtherQuota}
            </span>
          </Col>
          <Col span={20} style={{ textAlign: "right" }}>
            <Button
              onClick={() => props.handleOpenModalInfo()}
              className="updateButton"
            >
              Info
            </Button>
          </Col>
        </Row>
        <Col span={24}>
          <span className="header_text_detail">Setting Presentasi</span>
        </Col>
        {dataDetail.isOnline ? (
          <>
            <Col span={24}>
              <span className="data_text_detail">Online</span>
            </Col>
            <Col span={24}>
              <span className="header_text_detail">Media</span>
            </Col>
            <Col span={24}>
              <span className="data_text_detail">
                {dataDetail.sharingEventMedia
                  ? dataDetail.sharingEventMedia
                  : "-"}
              </span>
            </Col>
            <Col span={24}>
              <span className="data_text_detail">
                {dataDetail.sharingEventMediaUrl
                  ? dataDetail.sharingEventMediaUrl
                  : "-"}
              </span>
            </Col>
            <Col span={24}>
              <Row gutter={[0, 8]}>
                <Col span={8}>Username</Col>
                <Col span={16}>
                  <span className="data_text_detail">
                    {dataDetail.sharingEventMediaJoinId
                      ? dataDetail.sharingEventMediaJoinId
                      : "-"}
                  </span>
                </Col>
                <Col span={8}>password</Col>
                <Col span={16}>
                  <span className="data_text_detail">
                    {dataDetail.sharingEventMediaJoinPwd
                      ? dataDetail.sharingEventMediaJoinPwd
                      : "-"}
                  </span>
                </Col>
              </Row>
            </Col>
          </>
        ) : (
            <>
              <Col span={24}>
                <span className="data_text_detail">Offline</span>
              </Col>
              <Col span={24}>
                <span className="header_text_detail">Tempat</span>
              </Col>
              <Col span={24}>
                <span className="data_text_detail">
                  {dataDetail.sharingEventMedia
                    ? dataDetail.sharingEventMedia
                    : "-"}
                </span>
              </Col>
            </>
          )}
        <Row gutter={[0, 10]}>
          <Col span={22} style={{ textAlign: "right" }}>
            <Popconfirm
              title="Anda yakin akan membagikan Email ke Participant?"
              onConfirm={() => props.handleShare()}
              okText="Ya"
              cancelText="Tidak"
            >
              <Button
                className="updateButton"
                loading={props.loadingPost}
              >
                Share
            </Button>
            </Popconfirm>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export const UIContentDetailLSEvent = DetailContentLSEventCore;
