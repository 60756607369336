import types from '../constants'
import beasiswa from '../api/beasiswa'

const getDataListBeasiswa = (token, sort, size, page, search) => dispatch => {
  dispatch({
    type: types.GET_LIST_BEASISWA_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return beasiswa.getListBeasiswa(token, sort, size, page, search).then(result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_LIST_BEASISWA_FAILED,
        payload: result
      })
    } else {
      return dispatch({
        type: types.GET_LIST_BEASISWA_SUCCESS,
        payload: result
      })
    }
  })
}

const getDataDetailBeasiswa = (token, id) => dispatch => {
  dispatch({
    type: types.GET_BEASISWA_DETAIL_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return beasiswa.getDetailBeasiswa(token, id).then(result => {
    if (result.status === 'error') {
      dispatch({
        type: types.GET_BEASISWA_DETAIL_FAILED,
        payload: result
      })
    } else {
      return dispatch({
        type: types.GET_BEASISWA_DETAIL_SUCCESS,
        payload: result
      })
    }
  })
}

const approveDataBeasiswa = (token, id) => dispatch => {
  dispatch({
    type: types.APPROVE_BEASISWA_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return beasiswa.approveBeasiswa(token, id).then(result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.APPROVE_BEASISWA_FAILED,
        payload: result
      })
    } else {
      return dispatch({
        type: types.APPROVE_BEASISWA_SUCCESS,
        payload: result
      })
    }
  })
}

const rejectDataBeasiswa = (token, id) => dispatch => {
  dispatch({
    type: types.REJECT_BEASISWA_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return beasiswa.rejectBeasiswa(token, id).then(result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.REJECT_BEASISWA_FAILED,
        payload: result
      })
    } else {
      return dispatch({
        type: types.REJECT_BEASISWA_SUCCESS,
        payload: result
      })
    }
  })
}


const deleteDataListBeasiswa = (token, id) => dispatch => {
  dispatch({
    type: types.DELETE_LIST_BEASISWA_LOADING,
    payload: {
      status: 'loading',
      error: '',
      data: []
    }
  })
  return beasiswa.deleteDataListBeasiswa(token, id).then(result => {
    if (result.status === 'error') {
      return dispatch({
        type: types.DELETE_LIST_BEASISWA_FAILED,
        payload: result
      })
    } else {
      return dispatch({
        type: types.DELETE_LIST_BEASISWA_SUCCESS,
        payload: result
      })
    }
  })
}



export default {
  getDataListBeasiswa,
  getDataDetailBeasiswa,
  approveDataBeasiswa,
  rejectDataBeasiswa,
  deleteDataListBeasiswa
}
