import {get, post, put} from 'axios'
import config from '../services/config'

const {BASE_URL_LOGIN, TIMEOUT} = config

const getAdmin = (token, sort, size, page, search) => {
  return get(`${BASE_URL_LOGIN}be/api/adminUser?search=${search}&sort=${sort}&size=${size}&page=${page-1}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.Data.map(res => {
      return {
        adminName: res.adminName,
        adminRoleId: res.adminRoleId,
        adminUserId: res.adminUserId,
        adminUserName: res.adminUserName,
        adminRoleName: res.adminRoleName,
        adminStatus: res.adminStatus,
        adminId: res.adminId,
        id: res.adminId,
      }
    })
    return {
      data: normalizeData,
      totalData: response.data.TotalData,
      status: 'success',
      error: 'fail'
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const getAdminDetail = (token, id) => {
  return get(`${BASE_URL_LOGIN}be/api/adminDetail?administratorId=${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = {
      adminRoleId: response.data.Data.adminRoleId,
      adminLastEduCode: response.data.Data.adminLastEduCode,
      adminUserId: response.data.Data.adminUserId,
      adminUserName: response.data.Data.adminUserName,
      adminPosition: response.data.Data.adminPosition,
      adminAddress: response.data.Data.adminAddress,
      adminStatusCode: response.data.Data.adminStatusCode,
      adminName: response.data.Data.adminName,
      adminRoleName: response.data.Data.adminRoleName,
      adminPhone: response.data.Data.adminPhone,
      adminDateofBirth: response.data.Data.adminDateofBirth,
      adminLastEdu: response.data.Data.adminLastEdu,
      adminStatus: response.data.Data.adminStatus,
      adminId: response.data.Data.adminId,
      adminLastEduPlace: response.data.Data.adminLastEduPlace,
      adminIdentity: response.data.Data.adminIdentity,
      adminGenderCode: response.data.Data.adminGenderCode,
      adminGender: response.data.Data.adminGender,
      adminEmail: response.data.Data.adminEmail,
      id: response.data.Data.adminId,
    }
    return {
      data: normalizeData,
      status: 'success',
      error: 'fail'
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const postAdmin = (token, body) => {
  return post(`${BASE_URL_LOGIN}be/api/createAdministrator`, body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      totalData: 0,
      error,
      data: []
    }
  })
}

const editAdmin = (token, body, id) => {
  return put(`${BASE_URL_LOGIN}be/api/updateAdminDetail?administratorId=${id}`, body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      totalData: 0,
      error,
      data: []
    }
  })
}

const getAdminApproval = (token, sort, size, page, search) => {
  let url = `${BASE_URL_LOGIN}be/api/registerUnivAdmin?sort=${sort}&size=${size}&page=${page-1}&search=${search}`
  return get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.Data.map(res => {
      return {
        univName: res.univName,
        univId: res.univId,
        univEmail: res.univEmail,
        univWebsite: res.univWebsite,
        univUsername: res.univUsername,
        univApprovalStatus: res.univApprovalStatus,
        id: res.univId,
      }
    })
    return {
      data: normalizeData,
      totalData: response.data.TotalData,
      status: 'success',
      error: 'fail'
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const getAdminApprovalDetail = (token, id) => {
  return get(`${BASE_URL_LOGIN}be/api/registerUnivDetailAdmin?univId=${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = {
      univStageUrl: response.data.Data.univStageUrl,
      univPhone: response.data.Data.univPhone,
      univIg: response.data.Data.univIg,
      univCatName: response.data.Data.univCatName,
      univLogoPath: response.data.Data.univLogoPath,
      univId: response.data.Data.univId,
      univEmail: response.data.Data.univEmail,
      univTwitter: response.data.Data.univTwitter,
      univAddress: response.data.Data.univAddress,
      univAboutUsUrl: response.data.Data.univAboutUsUrl,
      univName: response.data.Data.univName,
      univFacultyUrl: response.data.Data.univFacultyUrl,
      univWa: response.data.Data.univWa,
      univWebsite: response.data.Data.univWebsite,
      univFacebook: response.data.Data.univFacebook,
      univCatId: response.data.Data.univCatId,
      id: response.data.Data.univId,
      DataBanner: response.data.DataBanner,
      univCertificatePath: response.data.Data.univCertificatePath,
      univHumasPic: response.data.Data.univHumasPic,
      univHumasEmail: response.data.Data.univHumasEmail,
      univHumasPhone: response.data.Data.univHumasPhone,
      univHumasPath: response.data.Data.univHumasPath,
      univCertificateFileName: response.data.Data.univCertificateFileName,
      univHumasFileName: response.data.Data.univHumasFileName,
      univRegId: response.data.Data.univRegId
    }
    return {
      data: normalizeData,
      status: 'success',
      error: 'fail'
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const getEntranceList = (token, id) => {
  return get(`${BASE_URL_LOGIN}be/api/entraceUnivDetailAdmin?univId=${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.DataUnivEntrance.map(res => {
      return {
        univReceiveUpload: res.univReceiveUpload,
        univEntId: res.univEntId,
        univEntPath: res.univEntPath,
        univEntName: res.univEntName,
        univEntUrl: res.univEntUrl,
        id: res.univEntId,
      }
    })
    return {
      data: normalizeData,
      status: 'success',
      error: 'fail'
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const editAdminApproval = (token, body, id) => {
  return put(`${BASE_URL_LOGIN}be/api/updateRegUnivAdmin?univId=${id}`, body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      totalData: 0,
      error,
      data: []
    }
  })
}

const createAdminApproval = (token, body, id) => {
  return put(`${BASE_URL_LOGIN}be/api/accountAdminUniversity?univId=${id}`, body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      totalData: 0,
      error,
      data: []
    }
  })
}

const deleteAdminApproval = (token, body, id) => {
  return put(`${BASE_URL_LOGIN}be/api/rejectRegUnivAdmin?univId=${id}`, body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      totalData: 0,
      error,
      data: []
    }
  })
}


const getAdminUniv = (token, sort, size, page, search) => {
  // let url = `https://demo5436246.mockable.io/listApapun?search=${search}&sort=${sort}&size=${size}&page=${page-1}`
  let url = `${BASE_URL_LOGIN}be/api/listUnivAdmin?search=${search}&sort=${sort}&size=${size}&page=${page-1}`
  return get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.Data.map(res => {
      return {
        adminName: res.univName,
        adminEmail: res.usersEmail,
        adminUserName: res.usersName,
        adminId: res.univId,
        id: res.univId,
      }
    })
    return {
      data: normalizeData,
      totalData: response.data.TotalData,
      status: 'success',
      error: 'fail'
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

export default {
  getAdmin,
  getAdminDetail,
  postAdmin,
  editAdmin,
  getAdminApproval,
  getAdminApprovalDetail,
  getEntranceList,
  editAdminApproval,
  createAdminApproval,
  deleteAdminApproval,
  getAdminUniv
}
