import React, { useState, useEffect } from 'react'

import {
  Modal,
  Form,
  Row,
  Col,
  Typography,
  Input,
  Select,
  Button,
  Radio
} from 'antd'
import { post } from 'axios'
import logoUrl from '../../global/assets/logoHeader.png'
import config from '../../services/config'
const { BASE_URL_NOAUTH } = config
const { Title } = Typography
const { TextArea } = Input
const { Option } = Select

const ModalRegis = props => {

  const [loadingUpload, setLoadingUpload] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [filePath, setFilePath] = useState(null)
  const [institusi, setInstitusi] = useState(null)
  const [form] = Form.useForm()
  // if (props.visibleRegis === false) {
  //   form.resetFields();
  // }

  useEffect(() => {
    setFilePath(null);
    setFileName(null);
    initialField()
    onChangeRadio(true);
  }, [props.visibleRegis]);

  const initialField = () => {
    form.resetFields();
  }

  const onChangeRadio = val => {
    props.radioChange(val)
  }

  const handleResetForm = () => {
    initialField()
    setFileName(null)
    setFilePath(null)
    let temp = {
      kedutaan : true,
    }
    form.setFieldsValue(temp);
    onChangeRadio(true)
  };

  const fileInput = React.createRef();

  const openFileBrowser = () => {
    fileInput.current.click();
  };

  const uploadAssignmentLetter = (data, fileNameParam) => {
    setLoadingUpload(true);
    post(`${BASE_URL_NOAUTH}api/upload/logo`, data).then( res => {
      if ( res && res.status ? res.status === 200 : false ) {
        setFilePath(res.data ? res.data.filePath : "")
      }
      setLoadingUpload(false);
    });
  }

  const generateRandomFileName = () => {
    return Math.random()
      .toString(36)
      .substr(2, 9);
  };

  const fileHandler = event => {
    if (event.target.files.length) {
      const file = event.target.files[0];
      const fileNameValue = file.name;
      const fileExtension = fileNameValue.slice(
        fileNameValue.lastIndexOf('.') + 1,
      );

      setFileName(fileNameValue);

      const randomNameFile = `Assignment letter ${generateRandomFileName()}.${fileExtension}`;

      const newFile = new File([file], randomNameFile, { type: file.type });

      const formData = new FormData();
      formData.append('file', newFile);
      uploadAssignmentLetter(formData, newFile.name);
    }
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const handleScheduleChange = e => {
    let temp = {}
    temp = {
      session: undefined
    }
    form.setFieldsValue(temp)
    props.onChangeSchedule(e)
  }

  const checkFinish = (values) => {
    props.onFinish(values, filePath)
  };

  const handleRadioChange = val => {
    setInstitusi(null)
    let temp = {}
    temp = {
      institusi: undefined,
      otheInstitusi: undefined,
      noHp: undefined,
      alamat: undefined,
      picName: undefined,
      picNo: undefined,
      sumberName: undefined,
      descSumber: undefined,
      dayPresentasi: undefined,
      session: undefined
    }
    setFileName(null)
    setFilePath(null)
    form.setFieldsValue(temp)
    onChangeRadio(val.target.value)
  }

  const handleInstitusiChange = e => {
    let temp = {}
    props.listInstitusi.map(res => {
      if (res.id === e && e !== '-') {
        setFileName(res.logoPath)
        setFilePath(res.logoPath)
        temp = {
          noHp: res.phoneNumber,
          alamat: res.alamat
        }
      }
      return temp
    })
    if (e === '-') {
      setFileName(null)
      setFilePath(null)
      temp = {
        noHp: undefined,
        alamat: undefined
      }
    }
    form.setFieldsValue(temp)
    props.onChangeInstitusi(e)
    setInstitusi(e)
  }

  return(

    <Modal
      visible={props.visibleRegis}
      footer={null}
      title='none'
      className={props.isMobile ? 'modal_regisMobile' :'modal_regis'}
      width={props.isMobile ? '100%' : 633}
      centered
      onCancel={() => props.close()}
      destroyOnClose={true}
    >
      <Row>
        <Col span={24}>
          <div className='headerForm'>
            <img alt="logo" src={logoUrl} style={{marginRight: '24px'}}></img>
            <Title className='titleModal' level={4}>{props.title}</Title>
          </div>
          <div className='dividerRegis' />
        </Col>
        <Col span={24} >
          <Form
            form={form}
            onFinish={values => checkFinish(values)}
            onFinishFailed={onFinishFailed}
            initialValues={ props.dataUniv ? {
              kedutaan: props.isKedutaan,
              institusi: props.dataUniv.id,
              noHp: props.dataUniv.phoneNumber,
              alamat: props.dataUniv.alamat,
              picName: props.dataUniv.bwLateRegPicName,
              picNo: props.dataUniv.bwLateRegPicWa
            } : {
              kedutaan: props.isKedutaan
            }}
          >
            <Row gutter={[10, 0]}>
              <Col span={24}>
                <Form.Item
                  name='kedutaan'
                >
                <Radio.Group disabled={props.fromLate} className="radio_style" onChange={handleRadioChange}>
                  <Radio value={true}>Kedutaan</Radio>
                  <Radio value={false}>Universitas / Sekolah Kedinasan</Radio>
                </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={23}>
                <Form.Item
                  name='institusi'
                  rules={[{
                    required: true,
                    message: 'Please Select Institusi!'
                  }]}
                >
                  <Select
                    placeholder="Nama Institusi"
                    className="select"
                    showSearch
                    allowClear
                    disabled={props.fromLate}
                    onSearch={e => props.onSearch(e)}
                    onChange={e => handleInstitusiChange(e)}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {
                      props.listInstitusi ?
                      props.listInstitusi.map( (item, index ) =>
                          (
                          <Option key={item.id + 'Institusi' + index} value={item.id}>{item.name}</Option>
                          )
                        ) :
                        (
                          <></>
                        )
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={1}>
                <span className="mandatoryLarge">&#8727;</span>
              </Col>
              {institusi === '-' ? (
                  <>
                    <Col span={23}>
                    <Form.Item
                      name='otheInstitusi'
                    >
                      <Input 
                        maxLength={256} 
                        size="large" 
                        className='input_style' 
                        placeholder={"Nama Institusi"}
                      />
                    </Form.Item>
                  </Col>
                    <Col span={1}>
                      <span className="mandatoryLarge">&#8727;</span>
                    </Col>
                </>
              ) : (
                <></>
              )}
              <Col span={23}>
                <Form.Item>
                  <Row>
                    <Col span={5} style={{margin: 'auto'}}>
                      <span className="mandatory">* </span>
                      <span className='logoInstitusi'>Logo Institusi</span>
                    </Col>
                    <Col span={19} style={{textAlign: 'right'}}>
                      <span
                        style={{
                          marginRight: 15
                        }}
                      >
                          { fileName ? (
                            fileName.length > 30 ? fileName.substr(0,30)+ '...' : fileName
                            ) : (
                              props.dataUniv ? (
                                props.dataUniv.logoPath ? (
                                  props.dataUniv.logoPath.length > 30 ? props.dataUniv.logoPath.substr(0,30)+ '...' : props.dataUniv.logoPath
                                ) : ''
                              ) : ''
                            )
                          }
                      </span>
                      <input
                        type="file"
                        hidden
                        onChange={fileHandler}
                        ref={fileInput}
                      />
                      <Button
                        disabled={props.fromLate}
                        onClick={openFileBrowser}
                        type="primary"
                        className='buttonUpload'
                        loading={loadingUpload}
                      >
                        Upload
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
              <Col span={23}>
                <Form.Item
                  name='noHp'
                  rules={[
                    { required: true, message: 'Please input telephone' },
                    {
                      pattern: /^[0-9]*$/gm,
                      message: `Please Input number correctly`,
                    },
                 ]}
                >
                  <Input 
                    maxLength={16}
                    disabled={props.fromLate} 
                    size="large" 
                    className='input_style' 
                    placeholder={"Nomor Telepon Institusi"}
                  />
                </Form.Item>
              </Col>
              <Col span={1}>
                <span className="mandatoryLarge">&#8727;</span>
              </Col>
              <Col span={23}>
                <Form.Item
                  name='alamat'
                  rules={[
                    {
                      required: true,
                      message: 'Please input address!'
                    },
                  ]}
                >
                  <TextArea disabled={props.fromLate} maxLength={256} size="large" className='input_style' placeholder={"Alamat Institusi"}/>
                </Form.Item>
              </Col>
              <Col span={1}>
                <span className="mandatoryLarge">&#8727;</span>
              </Col>
              <Col span={23}>
                <Form.Item
                  name='picName'
                  rules={[
                    {
                      required: true,
                      message: 'Please input PIC full name!'
                    },
                  ]}
                >
                  <Input 
                    disabled={props.fromLate} 
                    maxLength={256} size="large" 
                    className='input_style' 
                    placeholder={"Nama Lengkap PIC Institusi"}
                  />
                </Form.Item>
              </Col>
              <Col span={1}>
                <span className="mandatoryLarge">&#8727;</span>
              </Col>
              <Col span={23}>
                <Form.Item
                  name='picNo'
                  rules={[
                    {
                      required: true,
                      message: 'Please input number WA pic!'
                    },
                    {
                      pattern: /^[+\d](?:.*\d)?$/g,
                      message: 'Please input a valid WA number!'
                    },
                    {
                      min: 10,
                      message: "Minimal WA Number 10 digits!"
                    }
                  ]}
                >
                  <Input 
                    disabled={props.fromLate} 
                    maxLength={15} 
                    size="large" 
                    className='input_style' 
                    placeholder={"Nomor WhatsApp PIC Yang Dapat Dihubungi"}
                  />
                </Form.Item>
              </Col>
              <Col span={1}>
                <span className="mandatoryLarge">&#8727;</span>
              </Col>
              <Col span={23}>
                <Form.Item
                  name='sumberName'
                  rules={[
                    {
                      required: true,
                      message: 'Please input speaker full name!'
                    },
                  ]}
                >
                  <Input 
                    maxLength={256} 
                    size="large" 
                    className='input_style' 
                    placeholder={"Nama Lengkap Narasumber dengan Gelar"}
                  />
                </Form.Item>
              </Col>
              <Col span={1}>
                <span className="mandatoryLarge">&#8727;</span>
              </Col>
              <Col span={23}>
                <Form.Item
                  name='descSumber'
                  rules={[
                    {
                      required: true,
                      message: 'Please input speaker name!'
                    },
                  ]}
                >
                  <TextArea
                    size="large" 
                    className='input_style' 
                    placeholder={"Deskripsi Narasumber"}
                  />
                </Form.Item>
              </Col>
              <Col span={1}>
                <span className="mandatoryLarge">&#8727;</span>
              </Col>
              <Col span={11}>
                <Form.Item
                  name='dayPresentasi'
                  rules={[{
                    required: true,
                    message: 'Please input day presentation!'
                  }]}
                >
                  <Select
                    className="select"
                    placeholder="Hari / Tanggal Presentasi"
                    onChange={e => handleScheduleChange(e)}
                  >
                    {
                      props.listSchedule ?
                      props.listSchedule.map( (item, index ) =>
                          (
                          <Option key={item.id + 'customer' + index} value={item.id}>{item.name.replace('00:00:00.0', '')}</Option>
                          )
                        ) :
                        (
                          <></>
                        )
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={1}>
                <span className="mandatoryLarge">&#8727;</span>
              </Col>
              <Col span={11}>
                <Form.Item
                  name='session'
                  rules={[{
                    required: true,
                    message: 'Please input session!'
                  }]}
                >
                  <Select
                    placeholder="Sesi"
                    className="select"
                  >
                    {
                      props.listSession ?
                      props.listSession.map( (item, index ) =>
                          (
                            <Option key={item.id + 'customer' + index} value={item.id}>{item.name}</Option>
                          )
                        ) :
                        (
                          <></>
                        )
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={1}>
                <span className="mandatoryLarge">&#8727;</span>
              </Col>
              <Col span={23}>
                <Form.Item
                  style={{
                    textAlign: 'right',
                    marginBottom: '0px'
                  }}
                >
                  <Button
                    type="link"
                    className='resetButton'
                    size={'small'}
                    onClick={() => handleResetForm()}
                  >
                    Reset
                  </Button>
                  <Button
                    type="primary"
                    htmlType='submit'
                    className='updateButton'
                    loading={props.loadingSubmit}
                    size={'small'}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Modal>
  )
}

export const UIModal = ModalRegis
