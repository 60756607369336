import React, { Component } from "react";
import {
   Breadcrumb, Card, Row, Col, Typography,
   Button, Modal, Popconfirm, notification
} from "antd";
import {
   UItable, UISearch
} from "../../components";
import { connect } from "react-redux";
import daftarPersetujuanGaleri from "../../actions/daftarPersetujuanGaleri";
import { CaretRightOutlined } from '@ant-design/icons'
import ImagePreviewComponent from '../../components/imagePreview'
import {GlobalVideoCheck} from '../../global/checkingVideo'

const { Text } = Typography;

class DaftarPersetujuanGaleri extends Component {
   constructor(props) {
      super(props);
      this.state = {
         isGambar: true,
         param: {
            search: "",
            sort: "",
            size: 10,
            page: 1,
            format: "image"
         },
         visible: false,
         dataGaleri: ''
      }
   }

   componentDidMount() {
      const { param } = this.state;
      const { token, dataDaftarPersetujuanGaleri } = this.props;
      this.props.handleData(token, param);
   }

   handleClickGambar = () => {
      const { param } = this.state;
      const { token } = this.props;
      this.setState({
         isGambar: true,
         param: {
            ...param,
            format: "image",
            page: 1,
            search: ""
         }
      }, () => {
         this.props.handleData(token, this.state.param);
      });
   }

   handleClickVideo = () => {
      const { param } = this.state;
      const { token } = this.props;
      this.setState({
         isGambar: false,
         param: {
            ...param,
            format: "video",
            page: 1,
            search: ""
         }
      }, () => {
         this.props.handleData(token, this.state.param);
      });
   }

   handleSearch = key => {
      const { param } = this.state;
      const { token } = this.props;
      this.setState({
         param: {
            ...param,
            search: key,
            page: 1
         }
      }, () => {
         this.props.handleData(token, this.state.param);
      })
   }

   handleTableChange = (page, filters, sorter) => {
      const { param } = this.state;
      const { token } = this.props;

      let sorterField = ""
      if (sorter.field === "univEventDate") {
         sorterField = "b.univ_gallery_event_date";
      } else {
         sorterField = "status_approval";
      }

      let sort = "";
      if (sorter.order === "ascend") {
         sort = `${sorterField},asc`;
      } else if (sorter.order === "descend") {
         sort = `${sorterField},desc`;
      }

      this.setState({
         param: {
            ...param,
            sort,
            page: page.current
         }
      }, () => {
         this.props.handleData(token, this.state.param);
      });
   }

   handleOpen = linkVideo => {
      this.setState({
         visible: true,
         dataGaleri: linkVideo
      })
   }

   handleClose = () => {
      this.setState({ visible: false, dataGaleri: '' });
   }

   handleApprove = (univGalleryId) => {
      const { token, userId } = this.props;
      const body = {
         userId
      }
      const param = {
         univGalleryId
      }
      this.props.handleApprove(token, body, param).then(res => {
         if (res.payload.data.status === 200) {
            notification.success({
               placement: 'bottomRight',
               message: 'Success',
               description: res.payload.data.data.Message ? res.payload.data.data.Message : "Berhasil approve galeri.",
            });
            this.setState({
               param: {
                  ...this.state.param,
                  search: "",
                  page: 1
               }
            }, () => {
               this.props.handleData(token, this.state.param);
            })
         } else {
            notification.error({
               placement: 'bottomRight',
               message: 'Error',
               description: res.payload.error.response.data.message ? res.payload.error.response.data.message : "Gagal approve galeri.",
            })
         }
      });
   }

   handleReject = univGalleryId => {
      const { token, userId } = this.props;
      const body = {
         userId
      }
      const param = {
         univGalleryId
      }
      this.props.handleReject(token, body, param).then(res => {
         if (res.payload.data.status === 200) {
            notification.success({
               placement: 'bottomRight',
               message: 'Success',
               description: res.payload.data.data.Message ? res.payload.data.data.Message : "Berhasil reject galeri.",
            });
            this.setState({
               param: {
                  ...this.state.param,
                  search: "",
                  page: 1
               }
            }, () => {
               this.props.handleData(token, this.state.param);
            });
         } else {
            notification.error({
               placement: 'bottomRight',
               message: 'Error',
               description: res.payload.error.response.data.message ? res.payload.error.response.data.message : "Gagal reject galeri.",
            })
         }
      });
   }

   handleDelete = univGalleryId => {
      const { token, userId } = this.props;
      const body = {
         "userId": userId
      }
      const param = {
         univGalleryId
      }
      this.props.handleDelete(token, body, param).then(res => {
         if (res.payload.data.status === 200) {
            notification.success({
               placement: 'bottomRight',
               message: 'Success',
               description: res.payload.data.data.Message ? res.payload.data.data.Message : "Berhasil delete galeri.",
            });
            this.setState({
               param: {
                  ...this.state.param,
                  search: "",
                  page: 1
               }
            }, () => {
               this.props.handleData(token, this.state.param);
            });
         } else {
            notification.error({
               placement: 'bottomRight',
               message: 'Error',
               description: res.payload.error.response.data.message ? res.payload.error.response.data.message : "Gagal delete galeri.",
            })
         }
      });
   }

   render() {
      const message = {
         approve: "Anda yakin ingin approve galeri ini?",
         reject: "Anda yakin ingin reject galeri ini?"
      }
      const { isGambar, param, visible, dataGaleri } = this.state;
      const { dataDaftarPersetujuanGaleri, isMobile } = this.props;
      const columnsTable = [
         {
            title: isGambar ? "Daftar Gambar" : "Daftar Video",
            dataIndex: "",
            key: "",
            width: "50%",
            render: record => (
               <Row gutter={[18, 0]}>
                  <Col span={8}>
                     {isGambar ?
                        (
                           <ImagePreviewComponent
                              isMobile={isMobile}
                              styles={{ width: '100%' }}
                              uri={record.univGalleryPathUrl}
                              imageBorder={'6px'}
                              isPreview={true}
                           />
                        ) :
                        (
                           <div className="gradientCard">
                              { !isGambar && (
                                 <CaretRightOutlined
                                    className="playButton"
                                    onClick={() => this.handleOpen(record.univGalleryVideoEmbed)}
                                 />
                              )}
                              <ImagePreviewComponent
                                 isMobile={isMobile}
                                 styles={{ width: '100%' }}
                                 uri={record.univGalleryPathUrl}
                                 imageBorder={'6px'}
                              />
                              {/* <img
                                 alt="galeri"
                                 src={record.univGalleryPathUrl}
                                 width="100%"
                                 style={{ borderRadius: "6px" }}
                              /> */}
                           </div>
                        )
                     }
                  </Col>
                  <Col span={16}>
                     <Row>
                        <Col span={24}>
                           <Text style={{ color: '#EF712B', fontWeight: 600, fontSize: "16px" }}>{record.univName}</Text>
                        </Col>
                        <Col span={24}>
                           <Text>{record.univEventName}</Text>
                        </Col>
                     </Row>
                  </Col>
               </Row>
            ),
         },
         {
            title: "Tanggal",
            dataIndex: "univEventDate",
            key: "univEventDate",
            sorter: true,
         },
         {
            title: "Status",
            dataIndex: "galleryApproval",
            key: "galleryApproval",
            sorter: true,
            render: galleryApproval => {
               if (galleryApproval === "onApproval") {
                  return <Text style={{ color: '#21296C', fontWeight: 600 }}>On Approval</Text>
               } else if (galleryApproval === "approved") {
                  return <Text style={{ color: '#6BC733', fontWeight: 600 }}>Approved</Text>
               } else {
                  return <Text style={{ color: '#E22828', fontWeight: 600 }}>Rejected</Text>
               }
            }
         },
         {
            title: "Action",
            dataIndex: "",
            key: "",
            render: record => {
               if (record.galleryApproval === "onApproval") {
                  return (
                     <>
                        <Popconfirm
                           title={message.approve}
                           okText="Ya"
                           cancelText="Tidak"
                           onConfirm={() => this.handleApprove(record.univGalleryId, record.univEventId)}
                        >
                           <Button
                              type='link'
                              style={{ color: '#6BC733', cursor: 'pointer', fontWeight: 600 }}
                           >
                              Approve
                           </Button>
                        </Popconfirm>
                        <Popconfirm
                           title={message.reject}
                           okText="Ya"
                           cancelText="Tidak"
                           onConfirm={() => this.handleReject(record.univGalleryId)}
                        >
                           <Button
                              type='link'
                              style={{ color: '#E22828', cursor: 'pointer', fontWeight: 600 }}
                           >
                              Reject
                           </Button>
                        </Popconfirm>
                     </>
                  )
               } 
               else if (record.galleryApproval === "approved") {
                  return (
                     <Popconfirm
                        title="Apakah anda yakin ingin delete data galeri ini?"
                        onConfirm={() => this.handleDelete(record.univGalleryId ? record.univGalleryId : '')}
                        okText="Ya"
                        cancelText="Tidak"
                     >
                        <Button
                           disabled={record.galleryApproval !== 'approved'}
                           type='link'
                           style={{ color: '#e22837', cursor: 'pointer', fontWeight: 600 }}
                        >
                           Delete
                     </Button>
                     </Popconfirm>
                  )
               } 
               else {
                  return <Text>-</Text>
               }
            }
         },
      ];
      return (
         <div className="root">
            <Breadcrumb className="breadcrumb_style">
               <Breadcrumb.Item>
                  <span style={{ cursor: 'pointer' }}>Universitas</span>
               </Breadcrumb.Item>
               <Breadcrumb.Item className='pathNow_style'>
                  Daftar Persetujuan Galeri
               </Breadcrumb.Item>
            </Breadcrumb>
            <Card className="bodyCard_style">
               <Row gutter={[0, 16]}>
                  <Col span={24}>
                     <Button
                        className={isGambar ? "updateButton" : "updateButtonOutline"}
                        style={{ marginRight: 20 }}
                        onClick={this.handleClickGambar}
                     >
                        Gambar
                     </Button>
                     <Button
                        className={isGambar ? "updateButtonOutline" : "updateButton"}
                        onClick={this.handleClickVideo}
                     >
                        Video
                     </Button>
                  </Col>
                  <Col span={8}>
                     <UISearch placeholder='Search' handleSearch={key => this.handleSearch(key)} />
                  </Col>
               </Row>
               <Card
                  bordered={false}
                  loading={false}
                  style={{ minHeight: '300px' }}>
                  <UItable
                     dataTable={dataDaftarPersetujuanGaleri.data}
                     columns={columnsTable}
                     withRows={false}
                     loading={dataDaftarPersetujuanGaleri.status}
                     page={param.page}
                     isMobile={this.props.isMobile}
                     totalData={dataDaftarPersetujuanGaleri.totalData}
                     handleTableChange={(page, filters, sorter) => this.handleTableChange(page, filters, sorter)}
                  />
               </Card>
            </Card>
            <Modal
               visible={visible}
               centered
               className={isMobile ? 'cardVideoMobile' : 'cardVideo'}
               footer={null}
               width={isMobile ? '100%' : "75%"}
               onCancel={() => this.handleClose()}
               destroyOnClose
            >
               {GlobalVideoCheck.checking(dataGaleri) ? (
                  <iframe
                     style={{ width: '-webkit-fill-available', height: isMobile ? '280px' : '500px' }}
                     id="modalVideo"
                     title='modalVideo'
                     src={dataGaleri}
                     frameBorder="0"
                     allow="accelerometer;
                     autoplay;
                     encrypted-media;
                     gyroscope;
                     picture-in-picture"
                     allowFullScreen="allowfullscreen"
                  />
               ) : (
                  <span className='data_text_detail'>
                     Url Video Tidak Valid/Error
                  </span>
               )}
            </Modal>
         </div>
      )
   }
}

const mapDispatchToProps = dispatch => (
   {
      handleData: (token, param) => dispatch(daftarPersetujuanGaleri.getData(token, param)),
      handleApprove: (token, body, param) => dispatch(daftarPersetujuanGaleri.approveDaftarPersetujuanGaleri(token, body, param)),
      handleReject: (token, body, param) => dispatch(daftarPersetujuanGaleri.rejectDaftarPersetujuanGaleri(token, body, param)),
      handleDelete: (token, body, param) => dispatch(daftarPersetujuanGaleri.deleteDaftarPersetujuanGaleri(token, body, param))
   }
);

const mapStateToProps = state => (
   {
      dataDaftarPersetujuanGaleri: state.daftarPersetujuanGaleri,
   }
);

export default connect(mapStateToProps, mapDispatchToProps)(DaftarPersetujuanGaleri);