const GET_EVENT_LIFE_SHARING_LOADING = 'GET_EVENT_LIFE_SHARING_LOADING'
const GET_EVENT_LIFE_SHARING_SUCCESS = 'GET_EVENT_LIFE_SHARING_SUCCESS'
const GET_EVENT_LIFE_SHARING_FAILED = 'GET_EVENT_LIFE_SHARING_FAILED'
const GET_EVENT_DETAIL_LIFE_SHARING_LOADING = 'GET_EVENT_DETAIL_LIFE_SHARING_LOADING'
const GET_EVENT_DETAIL_LIFE_SHARING_SUCCESS = 'GET_EVENT_DETAIL_LIFE_SHARING_SUCCESS'
const GET_EVENT_DETAIL_LIFE_SHARING_FAILED = 'GET_EVENT_DETAIL_LIFE_SHARING_FAILED'
const GET_EVENT_PARTICIPANT_LIFE_SHARING_LOADING = 'GET_EVENT_PARTICIPANT_LIFE_SHARING_LOADING'
const GET_EVENT_PARTICIPANT_LIFE_SHARING_SUCCESS = 'GET_EVENT_PARTICIPANT_LIFE_SHARING_SUCCESS'
const GET_EVENT_PARTICIPANT_LIFE_SHARING_FAILED = 'GET_EVENT_PARTICIPANT_LIFE_SHARING_FAILED'
const POST_EMAIL_LIFE_SHARING_EVENT_LOADING = 'POST_EMAIL_LIFE_SHARING_EVENT_LOADING'
const POST_EMAIL_LIFE_SHARING_EVENT_SUCCESS = 'POST_EMAIL_LIFE_SHARING_EVENT_SUCCESS'
const POST_EMAIL_LIFE_SHARING_EVENT_FAILED = 'POST_EMAIL_LIFE_SHARING_EVENT_FAILED'
const GET_LOV_LIFE_SHARING_LOADING = 'GET_LOV_LIFE_SHARING_LOADING'
const GET_LOV_LIFE_SHARING_SUCCESS = 'GET_LOV_LIFE_SHARING_SUCCESS'
const GET_LOV_LIFE_SHARING_FAILED = 'GET_LOV_LIFE_SHARING_FAILED'
const POST_LIFE_SHARING_EVENT_LOADING = 'POST_LIFE_SHARING_EVENT_LOADING'
const POST_LIFE_SHARING_EVENT_SUCCESS = 'POST_LIFE_SHARING_EVENT_SUCCESS'
const POST_LIFE_SHARING_EVENT_FAILED = 'POST_LIFE_SHARING_EVENT_FAILED'
const PUT_DELETE_LIFE_SHARING_EVENT_LOADING = 'PUT_DELETE_LIFE_SHARING_EVENT_LOADING'
const PUT_DELETE_LIFE_SHARING_EVENT_SUCCESS = 'PUT_DELETE_LIFE_SHARING_EVENT_SUCCESS'
const PUT_DELETE_LIFE_SHARING_EVENT_FAILED = 'PUT_DELETE_LIFE_SHARING_EVENT_FAILED'

export {
  GET_EVENT_LIFE_SHARING_LOADING,
  GET_EVENT_LIFE_SHARING_SUCCESS,
  GET_EVENT_LIFE_SHARING_FAILED,
  GET_EVENT_DETAIL_LIFE_SHARING_LOADING,
  GET_EVENT_DETAIL_LIFE_SHARING_SUCCESS,
  GET_EVENT_DETAIL_LIFE_SHARING_FAILED,
  GET_EVENT_PARTICIPANT_LIFE_SHARING_LOADING,
  GET_EVENT_PARTICIPANT_LIFE_SHARING_SUCCESS,
  GET_EVENT_PARTICIPANT_LIFE_SHARING_FAILED,
  POST_EMAIL_LIFE_SHARING_EVENT_LOADING,
  POST_EMAIL_LIFE_SHARING_EVENT_SUCCESS,
  POST_EMAIL_LIFE_SHARING_EVENT_FAILED,
  GET_LOV_LIFE_SHARING_LOADING,
  GET_LOV_LIFE_SHARING_SUCCESS,
  GET_LOV_LIFE_SHARING_FAILED,
  POST_LIFE_SHARING_EVENT_LOADING,
  POST_LIFE_SHARING_EVENT_SUCCESS,
  POST_LIFE_SHARING_EVENT_FAILED,
  PUT_DELETE_LIFE_SHARING_EVENT_LOADING,
  PUT_DELETE_LIFE_SHARING_EVENT_SUCCESS,
  PUT_DELETE_LIFE_SHARING_EVENT_FAILED,
}
