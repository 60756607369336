import React, {useState, useEffect} from 'react'

import {
  Modal,
  Form,
  Row,
  Col,
  Typography,
  Input,
  Select,
  Button,
  DatePicker,
  TimePicker,
  Radio,
} from 'antd'

import moment from 'moment'

const {Title} = Typography
const {Option} = Select

const ModalUploadGallery = props => {
  const [form] = Form.useForm()

  const [valueRadio, setRadio] = useState(null);
  const [sesi, setSesi] = useState({})
	useEffect(() => {
    if (props.visibleInput === true) {
      form.resetFields();
			setRadio(null);
      form.setFieldsValue(props.initData)
    }
  }, [props.visibleInput])

  const handleResetForm = () => {
		const data = props.handleReset();
    form.resetFields();
    setRadio(null);
    form.setFieldsValue(data)
  };

  const onChangeCategoryLifeSharing = value => {
    form.setFieldsValue({
      interviewees: null,
      dayDatePresentation: null,
      eventId: null
    });
    props.onChangeCategoryLifeSharing(value);
  }

  const onChangeInterviewees = value => {
    form.setFieldsValue({
      dayDatePresentation: null,
      eventId: null
    });
    props.onChangeInterviewees(value);
  }

  const onChangeDayDatePresentation = value => {
    form.setFieldsValue({
      eventId: null
    });
    props.onChangeDayDatePresentation(value);
  }

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const checkFinishGalleryBW = (values) => {
    props.onFinish(values)
    // console.log(values);
  };

  const handleChangeEventName = value => {
		props.handleChangeEventName(value);
	}

  const handleInstitusiChange = value => {
		const data = props.onSelect(value)
		form.setFieldsValue(data)
  }

  const handleRadioChange = (val) => {
    setRadio(val.target.value);
  };
  const handleClose = () => {
		form.resetFields();
		const data = props.handleClose()
    form.setFieldsValue(data)
	}

  const handleSesi = (value, dateString, name) => {
    let temp = sesi
    if (name === 'start') {
      const endTemp = `${moment(value, "HH:mm:ss").hours()}:${moment(value, "HH:mm:ss").minutes() + 1}`
      temp = {
        ...temp,
        bwSessionTimeStart: dateString !== "NaN:NaN" ? moment(dateString, "HH:mm:ss") : null,
        bwSessionTimeFinish: endTemp !== "NaN:NaN" ? moment(endTemp, "HH:mm:ss") : null
      }
      form.setFieldsValue({
        timeFinish: undefined
      })
    }else if (name === 'end') {
      temp = {
        ...temp,
        bwSessionTimeFinish: dateString !== "NaN:NaN" ? moment(dateString, "HH:mm:ss") : null
      }
    }
    setSesi(temp)
  }

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
 }

  const disabledHours = () => {
    const hours = moment(sesi.bwSessionTimeStart, "HH:mm:ss").hours();
    return range(0, hours)
  }

  const disabledTime = (val) => {
    const temp = moment(sesi.bwSessionTimeStart, "HH:mm:ss").hours()
    if (val === temp ) {
      const time = moment(sesi.bwSessionTimeStart, "HH:mm:ss").minute();
      return range(0, time + 1)
    }
  }

  return (
    <Modal
      visible={props.visibleInput}
      footer={null}
      className={props.isMobile ? 'modalMobile' : 'modalImage'}
      width={props.isMobile ? '100%' : 633}
      centered
      onCancel={handleClose}
    >
      {props.galleryOf === 'lifeSharing' ? (
        <Row>
          <Col span={24}>
            <div className='headerForm'>
              <Title className='titleModalGalery' level={4}>{props.title}</Title>
            </div>
            <div className='dividerGalery'/>
            <span className='data_text_detail'>
              Life Sharing
            </span>&nbsp;
          </Col>
          <Col span={24}>
            <Form
              form={form}
              onFinish={props.onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Row gutter={[10, 0]}>
                <Col span={23}>
                  <Form.Item
                    name='categoryLifeSharing'
                    rules={[{
                      required: true,
                      message: 'Please input life sharing category!'
                    }]}
                  >
                    <Select
                      placeholder="Kategori Life Sharing"
                      className="select"
                      onChange={onChangeCategoryLifeSharing}
                    >
                      {
                        props.lovCategoryLifeSharing &&
                          props.lovCategoryLifeSharing.map((item) =>
                            (
                              <Option key={item.id} value={item.id}>{item.sharingTypeName}</Option>
                            )
                          )
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <span className="mandatoryLarge">&#8727;</span>
                </Col>
                <Col span={23}>
                  <Form.Item
                    name="interviewees"
                    rules={[{
                      required: true,
                      message: 'Please input narasumber full name!'
                    }]}
                  >
                    <Select
                      placeholder="Nama Lengkap Narasumber"
                      className="select"
                      onChange={onChangeInterviewees}
                    >
                      {
                        props.lovInterviewees &&
                          props.lovInterviewees.map((item, index) =>
                            (
                              <Option key={item.id} value={item.sharingEventSpeaker}>{item.sharingEventSpeaker}</Option>
                            )
                          )
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <span className="mandatoryLarge">&#8727;</span>
                </Col>
                <Col span={11}>
                  <Form.Item
                    name='dayDatePresentation'
                    rules={[{
                      required: true,
                      message: 'Please select day / date of presentation'
                    }]}
                  >
                    <Select
                      className="select"
                      placeholder="Hari / Tanggal Presentasi"
                      onChange={onChangeDayDatePresentation}
                    >
                      {
                        props.lovDayDatePresentation &&
                          props.lovDayDatePresentation.map((item) =>
                            (
                              <Option
                                key={item.id}
                                value={item.sharingEventDate}
                                >
                                  {item.sharingEventDay} / {item.sharingEventDate}
                              </Option>
                            )
                          )
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <span className="mandatoryLarge">&#8727;</span>
                </Col>
                <Col span={11}>
                  <Form.Item
                    name='eventId'
                    rules={[{
                      required: true,
                      message: 'Please select time!'
                    }]}
                  >
                    <Select
                      placeholder="Waktu"
                      className="select"
                    >
                      {
                        props.lovTime &&
                          props.lovTime.map((item) =>
                            (
                              <Option key={item.id} value={item.id}>{item.sharingEventTime}</Option>
                            )
                          )
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <span className="mandatoryLarge">&#8727;</span>
                </Col>
                <Col span={23}>
                  <span className='data_text_detail'>
                    Media:
                  </span>&nbsp;
                </Col>
                <Col span={23}>
                  <Form.Item
                    name="linkYoutube"
                    rules={[
                      { required: true, message: 'Please input URL video youtube!' },
                      { pattern: /^http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/ , 
                        message: 'Invalid youtube URL!' }
                    ]}
                    style={{marginBottom: 0}}
                  >
                    <Input
                      className='input_style_BW'
                      placeholder='Url Video Youtube'
                    />
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <span className="mandatoryLarge">&#8727;</span>
                </Col>
                <Col span={23}>
                  <Form.Item
                    style={{
                      textAlign: 'right',
                      marginBottom: '0px',
                      marginTop: '10px'
                    }}
                  >
                    <Button
                      type="link"
                      className='resetButton'
                      size={'small'}
                      onClick={() => handleResetForm()}
                    >
                      Reset
                    </Button>
                    <Button
                      type="primary"
                      htmlType='submit'
                      className='updateButton'
                      loading={props.loadingSubmit}
                      size={'small'}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col span={24}>
            <div className='headerForm'>
              <Title className='titleModalGalery' level={4}>{props.title}</Title>
            </div>
            <div className='dividerGalery'/>
            <h2>
            <span className='data_text_detail'>
              Tema BIDikTi Week :
            </span>&nbsp;
              <span className='text_detail_institusi'>
              {props.dataActive.bwThemeInd ? props.dataActive.bwThemeInd : '-'}
            </span>
            </h2>
          </Col>
          <Col span={24} style={{marginBottom: '10px'}}>
            <span className='termText'>
              *Apabila institusi belum terdaftar dan tidak ada pada Institusi Terdaftar dapat mengisi nama institusi pada kolom Nama Institusi
            </span>
          </Col>
          <Col span={24}>
            <Form
              form={form}
              onFinish={values => checkFinishGalleryBW(values)}
              onFinishFailed={onFinishFailed}
					    initialValues={props.initData}
            >
              <Row gutter={[10, 0]}>
                <Col span={23}>
                  <Form.Item
                    name='institusi'
                    rules={[{
                      required: false,
                      message: 'Please select Institusi!'
                    }]}
                  >
                    <Select
                      placeholder="Nama Institusi Terdaftar"
                      className="select"
                      onChange={e => handleInstitusiChange(e)}
                      showSearch
									    onSearch={val => handleChangeEventName(val)}
                      notFoundContent={null}
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                    >
                      {
                        props.listInstitusi &&
                        props.listInstitusi.map((item, index) => {
                          return (
                            <Option key={item.id + 'Institusi' + index} value={item.id}>{item.name}</Option>
                          )
                        })
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={23}>
                  <Form.Item
                    name="univName"
                    rules={[
                      {
                        required: true,
                        message: 'Please input Institusi',
                      }
                    ]}
                  >
                    <Input
                      size="large" 
                      placeholder="Nama Institusi"
                      className='input_style' 
                      disabled={props.isSelected}
                    >
                    </Input>
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <span className="mandatoryLarge">&#8727;</span>
                </Col>
                <Col span={23}>
                  <Form.Item
                    name="deskripsi"
                    rules={[
                      {
                        required: true,
                        message: 'Please input deskripsi',
                      }
                    ]}
                  >
                    <Input
                      size="large" 
                      placeholder="Deskripsi Acara"
                      className='input_style' 
                      // disabled={props.isSelected}
                    >
                    </Input>
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <span className="mandatoryLarge">&#8727;</span>
                </Col>
                <Col span={11}>
                  <Form.Item
                    name='dayPresentasi'
                    rules={[{
                      required: true,
                      message: 'Please select day presentation!'
                    }]}
                  >
                    <DatePicker
                      style={{width: '100%'}}
                      placeholder="Hari, Tanggal Persentasi"
                      format={'dddd, YYYY-MM-DD'}
                      // disabled={props.isSelected}
                    />
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <span className="mandatoryLarge">&#8727;</span>
                </Col>
                <Col span={11}>
                  <Form.Item
                    name='session'
                    rules={[{
                      required: true,
                      message: 'Please input session!'
                    }]}
                  >
                    <Input
                      size="large" 
                      placeholder="Nama Sesi"
                      className='input_style' 
                      // disabled={props.isSelected}
                    >
                    </Input>
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <span className="mandatoryLarge">&#8727;</span>
                </Col>
                <Col span={11}>
                  <Form.Item
                    name='timeStart'
                    rules={[{
                      required: true,
                      message: 'Please select day presentation!'
                    }]}
                  >
                    <TimePicker
                      showNow={false}
                      allowClear={false}
                      style={{width: '100%'}}
                      placeholder="Mulai"
                      value={sesi ? sesi.bwSessionTimeStart : undefined}
                      onChange={(time, timeString) => handleSesi(time, timeString, 'start')}
                      format={'HH:mm:ss'}
                    />
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <span className="mandatoryLarge">&#8727;</span>
                </Col>
                <Col span={11}>
                  <Form.Item
                    name='timeFinish'
                    rules={[{
                      required: true,
                      message: 'Please select day presentation!'
                    }]}
                  >
                    <TimePicker
                      showNow={false}
                      allowClear={false}
                      style={{width: '100%'}}
                      placeholder="Selesai"
                      disabledHours={() => disabledHours()}
                      disabledMinutes={(current) => disabledTime(current)}
                      onChange={(time, timeString) => handleSesi(time, timeString, 'end')}
                      value={sesi ? sesi.bwSessionTimeFinish : undefined}
                      format={'HH:mm:ss'}
                    />
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <span className="mandatoryLarge">&#8727;</span>
                </Col>
                <Col span={23}>
                  <Form.Item
                    name="speaker"
                    rules={[
                      {
                        required: true,
                        message: 'Please input pembicara',
                      }
                    ]}
                  >
                    <Input
                      size="large" 
                      placeholder="Pembicara"
                      className='input_style' 
                      // disabled={props.isSelected}
                    >
                    </Input>
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <span className="mandatoryLarge">&#8727;</span>
                </Col>
                <Col span={23}>
                  <Form.Item
                    name="speakerDesc"
                    rules={[
                      {
                        required: true,
                        message: 'Please input deskripsi pembicara',
                      }
                    ]}
                  >
                    <Input
                      size="large" 
                      placeholder="Deskripsi Pembicara"
                      className='input_style' 
                      // disabled={props.isSelected}
                    >
                    </Input>
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <span className="mandatoryLarge">&#8727;</span>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="isOnline"
                    rules={[
                      { required: true, message: "Please select an item!" },
                    ]}
                    style={{ marginBottom: 0 }}
                  >
                    <Radio.Group
                      onChange={(val) => handleRadioChange(val)}
                      value={valueRadio}
                    >
                      <Radio value={true}>Online</Radio>
                      <Radio value={false}>Offline</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={23}>
                  <Form.Item
                    name="media"
                    rules={[
                      {
                        required: true,
                        message: 'Please input media',
                      }
                    ]}
                  >
                    <Input
                      size="large" 
                      placeholder="Media"
                      className='input_style' 
                      // disabled={props.isSelected}
                    >
                    </Input>
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <span className="mandatoryLarge">&#8727;</span>
                </Col>
                <Col span={23}>
                  <Form.Item
                    name='UrlLink'
                    rules={[
                      { required: true, message: 'Please input URL YouTube!' },
                      { pattern: /^http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/ , message: 'Invalid youtube URL!' }
                    ]}
                  >
                    <Input
                      size="large" 
                      className='input_style' 
                      placeholder={"URL Video YouTube"}
                    />
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <span className="mandatoryLarge">&#8727;</span>
                </Col>
                <Col span={23}>
                  <Form.Item
                    style={{
                      textAlign: 'right',
                      marginBottom: '0px'
                    }}
                  >
                    <Button
                      type="link"
                      className='resetButton'
                      size={'small'}
                      onClick={() => handleResetForm()}
                    >
                      Reset
                    </Button>
                    <Button
                      type="primary"
                      htmlType='submit'
                      className='updateButton'
                      loading={props.loadingSubmit}
                      size={'small'}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      )}
    </Modal>
  )

}

export const UIModalGallery = ModalUploadGallery
