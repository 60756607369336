import React, { Component } from "react";
import "../../global/global.css";
import { connect } from "react-redux";
import masterUangAction from "../../actions/masterUang";
import {
   Breadcrumb, Card, Row, Col, Button,
   Popconfirm, notification
} from "antd";
import {
   UItable, UISearch, UIModalCreate
} from "../../components";

class MasterUang extends Component {
   constructor(props) {
      super(props);
      this.state = {
         search: "",
         size: 10,
         page: 0,
         sort: "created_on,desc",
         visibleCreate: false,
         loading: false,
         initialData: {
            currencyName: undefined
         },
         dataEdit: {
            currencyName: undefined
         },
         isEdit: false,
         currencyId: undefined
      }
   }

   componentDidMount() {
      const { search, size, page, sort } = this.state;
      const body = {
         token: this.props.token,
         search,
         size,
         page,
         sort
      };

      this.props.handleData(body);
   }

   handleTableChange = (page, filter, sorter) => {
      const { size, search } = this.state;

      let sorterField = ""
      if (sorter.field === "currencyName") {
         sorterField = "currency_name";
      }

      let sort = "";
      if (sorter.order === "ascend") {
         sort = `${sorterField},asc`;
      } else if (sorter.order === "descend") {
         sort = `${sorterField},desc`;
      } else {
         sort = "created_on,desc"
      }
      const body = {
         token: this.props.token,
         search,
         page: page.current - 1,
         size,
         sort: sort
      }
      this.setState({
         page: page.current - 1,
         sort
      }, () => {
         this.props.handleData(body)
      })
   }

   handleSearch = key => {
      const { sort, size } = this.state;
      this.setState({
         search: key,
         page: 0
      });
      const body = {
         token: this.props.token,
         search: key,
         page: 0,
         size,
         sort
      }
      this.props.handleData(body)
   }

   openModal = () => {
      this.setState({
         visibleCreate: true,
         isEdit: false,
         initialData: {
            currencyName: undefined
         }
      })
   }

   handleCloseModal = () => {
      this.setState({ visibleCreate: false })
   }

   handleEditClick = data => {
      this.setState({
         currencyId: data.currencyId,
         visibleCreate: true,
         isEdit: true,
         initialData: {
            currencyName: data.currencyName
         },
         dataEdit: {
            currencyName: data.currencyName
         }
      })
   }

   handleSubmitCreate = (token, body) => {
      this.props.handleCreate(token, body).then(res => {
         if (res.payload.data.data.Status === "OK") {
            notification.success({
               placement: 'bottomRight',
               message: 'Success',
               description: res.payload.data.data.Message ? res.payload.data.data.Message : "Create Mata Uang Success",
            });
            this.setState({
               search: "",
               page: 0,
               size: 10,
               sort: "created_on,desc",
               visibleCreate: false
            }, () => {
               const body = {
                  search: "",
                  page: 0,
                  size: 10,
                  sort: "created_on,desc",
                  token: token
               }
               this.props.handleData(body);
            })
         } else {
            notification.error({
               placement: 'bottomRight',
               message: 'Error',
               description: res.payload.data.data.Message ? res.payload.data.data.Message : "Create Mata Uang Failed",
            });
         }
      })
   }

   handleSubmitEdit = (token, body, id) => {
      this.props.handleEdit(token, body, id).then(res => {
         if (res.payload.data.data.Status === 'OK') {
            notification.success({
               placement: 'bottomRight',
               message: 'Success',
               description: res.payload.data.data.Message ? res.payload.data.data.Message : "Edit Mata Uang Success",
            });
            this.setState({
               search: "",
               page: 0,
               size: 10,
               sort: "created_on,desc",
               visibleCreate: false
            }, () => {
               const body = {
                  search: "",
                  page: 0,
                  size: 10,
                  sort: "created_on,desc",
                  token: token
               }
               this.props.handleData(body);
            })
         } else {
            notification.error({
               placement: 'bottomRight',
               message: 'Error',
               description: res.payload.data.data.Message ? res.payload.data.data.Message : "Edit Mata Uang Failed",
            });
         }
      });
   }

   resetInitial = () => {
      let dataInitial = {
         currencyName: undefined
      }

      if (this.state.isEdit) {
         dataInitial.currencyName = this.state.dataEdit.currencyName
      }

      this.setState({
         initialData: dataInitial
      })
   }

   handleFinish = values => {
      this.setState({
         initialData: {
            currencyName: values.currencyName
         }
      });

      const body = {
         currencyName: values.currencyName,
         userId: this.props.userId
      }

      if (this.state.isEdit) {
         this.handleSubmitEdit(this.props.token, body, this.state.currencyId)
      } else {
         this.handleSubmitCreate(this.props.token, body);
      }
   }

   handleOnDelete = data => {
      const { token, userId } = this.props;
      const body = {
         userId
      };
      this.props.handleDelete(token, body, data.currencyId).then(res => {
         if (res.payload.data.data.Status === "OK") {
            notification.success({
               placement: 'bottomRight',
               message: 'Success',
               description: res.payload.data.data.Message ? res.payload.data.data.Message : "Berhasil menghapus mata uang.",
            });
            this.setState({
               search: "",
               page: 0,
               size: 10,
               sort: "created_on,desc",
               visibleCreate: false
            }, () => {
               const body = {
                  search: "",
                  page: 0,
                  size: 10,
                  sort: "created_on,desc",
                  token: token
               }
               this.props.handleData(body);
            })
         } else {
            notification.error({
               placement: 'bottomRight',
               message: 'Error',
               description: res.payload.data.data.Message ? res.payload.data.data.Message : "Gagal menghapus mata uang.",
            })
         }
      })
   }

   render() {
      const { page, visibleCreate, loading, initialData, isEdit } = this.state
      const { dataMasterUang, dataCreateMasterUang, dataPutMasterUang } = this.props;
      const text = 'Apakah anda yakin menghapus Mata Uang ini ?';
      const columnsTable = [
         {
            title: "Mata Uang",
            dataIndex: 'currencyName',
            key: 'currencyName',
            sorter: true,
            ellipsis: true
         },
         {
            title: 'Action',
            dataIndex: '',
            key: '',
            render: (data) => <>
               <Button
                  onClick={() => this.handleEditClick(data)}
                  type='link'
                  style={{ color: '#EF712B', cursor: 'pointer', fontWeight: 600 }}
               >
                  Edit
               </Button>
               <Popconfirm
                  placement="bottomRight"
                  title={text}
                  onConfirm={() => this.handleOnDelete(data)}
                  okText="Yes"
                  cancelText="No"
               >
                  <Button
                     type='link'
                     style={{ color: '#E22828', cursor: 'pointer', fontWeight: 600 }}
                  >
                     Delete
                  </Button>
               </Popconfirm>
            </>
         },
      ];
      const formItem = [
         {
            name: 'currencyName',
            type: 'input',
            colNumber: 23,
            maxLength: 25,
            rules: [
               {
                  required: true,
                  message: "Please input currency code!"
               }
            ],
            placeholder: "Kode Mata Uang"
         }
      ]

      return (
         <div className="root">
            <Breadcrumb className="breadcrumb_style">
               <Breadcrumb.Item>
                  <span style={{ cursor: 'pointer' }}>Master</span>
               </Breadcrumb.Item>
               <Breadcrumb.Item className='pathNow_style'>
                  Mata Uang
            </Breadcrumb.Item>
            </Breadcrumb>
            <Card className="bodyCard_style">
               <Row>
                  <Col span={8}>
                     <UISearch placeholder='Search' handleSearch={key => this.handleSearch(key)} />
                  </Col>
                  <Col span={16} style={{ textAlign: 'right' }}>
                     <Button className='updateButton' onClick={() => this.openModal()}>Tambah Mata Uang</Button>
                  </Col>
               </Row>
               <Card
                  bordered={false}
                  loading={dataMasterUang.loading}
                  style={{ minHeight: '300px' }}>
                  <UItable
                     dataTable={dataMasterUang.data}
                     columns={columnsTable}
                     withRows={false}
                     isMobile={this.props.isMobile}
                     loading={dataMasterUang.status}
                     page={page + 1}
                     totalData={dataMasterUang.totalData}
                     handleTableChange={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter)}
                  />
               </Card>
            </Card>
            <UIModalCreate
               formItem={formItem}
               visible={visibleCreate}
               isMobile={this.props.isMobile}
               loading={loading}
               initialValues={initialData}
               title={isEdit ? 'Edit Mata Uang' : 'Tambah Mata Uang'}
               widthDivider='250px'
               close={() => this.handleCloseModal()}
               onReset={() => this.resetInitial()}
               checkFinish={values => this.handleFinish(values)}
               loadingSubmit={isEdit ? dataPutMasterUang.status === "loading" : dataCreateMasterUang.status === "loading"}
            />
         </div>
      )
   }
}

const mapDispatchToProps = dispatch => {
   return {
      handleData: (param) => dispatch(masterUangAction.getData(param)),
      handleCreate: (token, body) => dispatch(masterUangAction.createUang(token, body)),
      handleEdit: (token, body, id) => dispatch(masterUangAction.editUang(token, body, id)),
      handleDelete: (token, body, id) => dispatch(masterUangAction.deleteUang(token, body, id))
   }
}

const mapStateToProps = (state) => {
   return {
      dataMasterUang: state.masterUang,
      dataCreateMasterUang: state.createMasterUang,
      dataPutMasterUang: state.putMasterUang
   }
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterUang)
