import types from "../constants";

const initialState = {
   status: "loading",
   data: []
}

export default function masterKota(state = initialState, action) {
   switch (action.type) {
      case types.GET_PROVINSI_LOADING:
         return {
            ...state,
            ...action.payload
         }
      case types.GET_PROVINSI_SUCCESS:
         return {
            isSuccess: true,
            ...state,
            ...action.payload
         }
      case types.GET_PROVINSI_FAILED:
         return {
            isSuccess: false,
            ...state,
            ...action.payload
         }
      default:
         return { ...initialState, ...state }
   }
}