import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Typography, Button, Card, Spin, Popover } from 'antd'
import dashboardUser from '../../../actions/dashboardUser'
import { Pie } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { InfoCircleOutlined, ReloadOutlined } from '@ant-design/icons'

const { Title } = Typography
const screenWidth = window.screen.width
const infoDetail = (
  <div>
    <span>{`Jumlah user yang membuka aplikasi >= 5 hari dalam kurun waktu 30 hari`}</span>
  </div>
)
class userDashboard extends Component {
  componentDidMount() {
    this.getData()
  }
  
  getData(){
    this.props.handleDataDashboarUser(this.props.token)
  }

  render() {
    const { dashboardUser } = this.props
    const dataTemp = dashboardUser && dashboardUser.data && dashboardUser.data.datasets && dashboardUser.data.datasets[0] && dashboardUser.data.datasets[0].data
    const colorTemp = dashboardUser && dashboardUser.data && dashboardUser.data.datasets && dashboardUser.data.datasets[0] && dashboardUser.data.datasets[0].backgroundColor
    const labelTemp = dashboardUser && dashboardUser.data && dashboardUser.data.labels
    const options = {
      legend: {
        display: false,
        labels:{
          padding: 10,
          boxWidth: 10
        }
      },
      plugins: {
        datalabels: { 
          anchor: 'center',
          formatter: (value, ctx) => {
              let sum = 0;
              let nilai = value ? value : 0
              let dataArr = ctx.chart.data.datasets[0].data;
              dataArr.map(data => {
                  sum += data;
              });
              let percentage = sum > 0 ? (nilai*100 / sum).toFixed(1)+"%" : '0%';
              return percentage;
          },
          color: '#ADA7A7',
          font: {
            weight: 'bold'
          }
        }
      },
    
      // Core options
      aspectRatio: 4 / 3,
      layout: {
        padding: 32
      },
      elements: {
        line: {
          fill: false
        },
        point: {
          hoverRadius: 7,
          radius: 5
        }
      },
    }

    if(dashboardUser && dashboardUser.status == 'loading'){
      return (
          <Row justify='center' align='middle' className="bodyCard_style" style={{height: '100%', paddingBottom: 24, backgroundColor: '#fff'}}>
            <Col style={{justifyContent: 'center', justi: 'center'}}>
              <Spin/>
            </Col>
          </Row>
      )
    }

    return ( 
        <Card className="bodyCard_style" style={{height: '100%'}}>
          {
            dashboardUser && dashboardUser.status == 'success' ? dashboardUser.data &&
            <div>
              <Row gutter={[0,10]}>
                <Col span={12}>
                  <div style={{color: '#072A6F', fontWeight: 'bold'}}>Jumlah User</div>
                </Col>
                <Col span={1}>
                  <div style={{color: '#072A6F'}}>{dashboardUser.data.total ? dashboardUser.data.total : 0}</div>
                </Col>
                <Col span={12}>
                  <div style={{color: '#072A6F', fontWeight: 'bold'}}>Jumlah User Aktif <Popover content={infoDetail}><InfoCircleOutlined/></Popover></div>
                </Col>
                <Col span={1}>                  
                  <div style={{color: '#072A6F'}}>{dashboardUser.data.totalActive ? dashboardUser.data.totalActive : 0}</div>
                </Col>
              </Row>
              <Row justify='center'>
                <Col span={24}>
                    <Row>
                      <Col xs={24} md={12}>
                        <Pie 
                          data={dashboardUser.data}
                          options={options}
                          height={150}
                          width={150}
                        /> 
                      </Col>
                      <Col xs={24} md={12}>
                        <table >
                          <tr>
                            <td><div style={{fontWeight: 'bold'}}>Role</div></td>
                            <td> </td>
                            <td><div style={{fontWeight: 'bold'}}>User</div></td>
                          </tr>
                            {
                              dataTemp && dataTemp.map((res,index) => {
                                return( 
                                  <tr>
                                    <td><div style={{height: 10, width: 10, backgroundColor: colorTemp[index] ? colorTemp[index] : '#ddd', borderRadius: 10}} /></td>
                                    <td>{ labelTemp[index]}</td>
                                    <td align='center'>{res}</td>
                                  </tr>
                                )
                              })
                            }

                          </table>
                      </Col>
                    </Row>
                </Col>
              </Row>
            </div> : 
              <Row justify='center' align='middle' style={{height: '100%', paddingBottom: 24, backgroundColor: '#fff', marginTop: '30%'}}>
                <Col span={24} style={{textAlign: 'center', margin: 10}}>
                  <ReloadOutlined style={{fontSize: 64}} />
                </Col>
                <Col>
                  {/* <Button
                    type='text'
                    disabled={true}
                    onClick={()=> this.getData()}
                  >Reload</Button> */}
                </Col>
              </Row>
          }
        </Card>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return{
    handleDataDashboarUser: (token) => dispatch(dashboardUser.getData(token))
  }
}

const mapStateToProps = (state) => {
  return {
    dashboardUser: state.dashboardUser,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(userDashboard)