const GET_LIST_BEASISWA_LOADING = 'GET_LIST_BEASISWA_LOADING'
const GET_LIST_BEASISWA_SUCCESS = 'GET_LIST_BEASISWA_SUCCESS'
const GET_LIST_BEASISWA_FAILED = 'GET_LIST_BEASISWA_FAILED'
const GET_BEASISWA_DETAIL_FAILED = 'GET_BEASISWA_DETAIL_FAILED'
const GET_BEASISWA_DETAIL_SUCCESS = 'GET_BEASISWA_DETAIL_SUCCESS'
const GET_BEASISWA_DETAIL_LOADING = 'GET_BEASISWA_DETAIL_LOADING'
const APPROVE_BEASISWA_LOADING = 'APPROVE_BEASISWA_LOADING'
const APPROVE_BEASISWA_SUCCESS = 'APPROVE_BEASISWA_SUCCESS'
const APPROVE_BEASISWA_FAILED = 'APPROVE_BEASISWA_FAILED'
const REJECT_BEASISWA_LOADING = 'REJECT_BEASISWA_LOADING'
const REJECT_BEASISWA_SUCCESS = 'REJECT_BEASISWA_SUCCESS'
const REJECT_BEASISWA_FAILED = 'REJECT_BEASISWA_FAILED'
const DELETE_LIST_BEASISWA_LOADING = 'DELETE_LIST_BEASISWA_LOADING'
const DELETE_LIST_BEASISWA_SUCCESS = 'DELETE_LIST_BEASISWA_SUCCESS'
const DELETE_LIST_BEASISWA_FAILED = 'DELETE_LIST_BEASISWA_FAILED'

export {
  GET_LIST_BEASISWA_LOADING,
  GET_LIST_BEASISWA_SUCCESS,
  GET_LIST_BEASISWA_FAILED,
  GET_BEASISWA_DETAIL_FAILED,
  GET_BEASISWA_DETAIL_SUCCESS,
  GET_BEASISWA_DETAIL_LOADING,
  APPROVE_BEASISWA_LOADING,
  APPROVE_BEASISWA_SUCCESS,
  APPROVE_BEASISWA_FAILED,
  REJECT_BEASISWA_LOADING,
  REJECT_BEASISWA_SUCCESS,
  REJECT_BEASISWA_FAILED,
  DELETE_LIST_BEASISWA_LOADING,
  DELETE_LIST_BEASISWA_SUCCESS,
  DELETE_LIST_BEASISWA_FAILED,
}
