import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../global/global.css'
import { Card, Breadcrumb, notification, Button, Col, Row, Input, Form } from 'antd'
import homepageAction from '../../actions/homePage'
import { put } from 'axios'
import config from '../../services/config'
import ImagePreviewComponent from '../../components/imagePreview'
const { BASE_URL_LOGIN, TIMEOUT } = config
const fileInput = React.createRef();


class HomePage extends Component {
  formRef = React.createRef()
  constructor(props) {
    super(props);
    this.state = {
      inital: '',
      loadingSubmit: false,
      listBanner: [],
      loadingUpload: [false],
      indexBanner: undefined
    }
  }

  componentDidMount() {
    this.props.handleDataHome(this.props.token)
  }

  componentDidUpdate(prevProps) {
    const { dataHome } = this.props
    if (
      dataHome.status !==
      prevProps.dataHome.status &&
      dataHome.status === 'success'
    ) {
      this.setState({
        listBanner: dataHome.data.listWelcomeImage
      })
      let temp = {
        subInd: dataHome.data.bidiktiContentSubtitleInd,
        subEng: dataHome.data.bidiktiContentSubtitleEng,
        descIndo: dataHome.data.bidiktiContentInd,
        descEng: dataHome.data.bidiktiContentEng,
        impianInd: dataHome.data.visionInd,
        impianEng: dataHome.data.visionEng
      }
      this.formRef.current.setFieldsValue(temp)
    }
  }


  onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  checkFinish = val => {
    const { token, dataHome } = this.props
    this.setState({
      loadingSubmit: true
    })
    const body = {
      bidiktiContentInd: val.descIndo,
      bidiktiContentEng: val.descEng,
      bidiktiContentSubtitleInd: val.subInd,
      bidiktiContentSubtitleEng: val.subEng,
      visionInd: val.impianInd,
      visionEng: val.impianEng,
      lastModifiedBy: this.props.userId
    }
    this.props.handleEditHome(token, dataHome.data.bidiktiContentId, body).then(res => {
      if (res.payload.status !== 'error') {
        if (res.payload.data.data.Status === 'OK') {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Update Homepage Success',
          });
          this.setState({
            loadingSubmit: false
          })
          this.props.handleDataHome(token)
        } else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Update Homepage Failed'
          });
          this.setState({
            loadingSubmit: false
          })
        }
      } else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: 'Update Homepage Failed'
        });
        this.setState({
          loadingSubmit: false
        })
      }
    })
  }

  openFileBrowser = () => {
    fileInput.current.click();
  };

  handleEditImage = (index) => {
    this.setState({
      indexBanner: index
    })
    this.openFileBrowser()
  }

  fileHandler = (event) => {
    if (event.target.files.length) {
      const file = event.target.files;
      const formData = new FormData();
      for (let i = 0; i < file.length; i++) {
        formData.append(`file`, file[i])
        formData.append('userId', this.props.userId)
      }
      this.uploadAssignmentLetter(formData);
    }
  };

  uploadAssignmentLetter = (data) => {
    const { indexBanner, listBanner } = this.state
    let temp = this.state.loadingUpload
    temp[indexBanner] = true
    this.setState({
      loadingUpload: temp
    })
    put(`${BASE_URL_LOGIN}be/api/upload/welcomeImage/${listBanner[indexBanner].id}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.token}`,
      },
      timeout: TIMEOUT
    }).then(res => {
      if (res.data.Status === 'OK') {
        notification.success({
          placement: 'bottomRight',
          message: 'Success',
          description: 'Upload Banner Success',
        });
        this.changeImage(res.data, indexBanner, listBanner[indexBanner].id)
      }
      temp[indexBanner] = false
      this.setState({
        loadingUpload: temp,
        indexBanner: undefined
      })
    }).catch(() => {
      notification.error({
        placement: 'bottomRight',
        message: 'Error',
        description: 'Upload Banner Failed'
      });
      temp[indexBanner] = false
      this.setState({
        loadingUpload: temp,
        indexBanner: undefined
      })
    });
  }

  changeImage = (data, index, id) => {
    let temp = this.state.listBanner
    temp[index] = {
      id: id,
      imageUrl: data.linkBanner,
    }
    this.setState({
      listBanner: temp,
      indexBanner: undefined
    }, () => this.props.handleDataHome(this.props.token))
  }

  render() {
    const { loadingSubmit, listBanner, loadingUpload } = this.state
    const { dataHome } = this.props
    return (
      <div className='root'>
        <Breadcrumb className="breadcrumb_style" >
          <Breadcrumb.Item>
            <span style={{ cursor: 'pointer' }} onClick={() => this.props.history.push(`/dashboard`)}>Pengaturan Umum </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className='pathNow_style'>
            Homepage
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card
          loading={dataHome.status === 'loading'}
          className='bodyCard_style'
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form
                onFinish={values => this.checkFinish(values)}
                onFinishFailed={event => this.onFinishFailed(event)}
                ref={this.formRef}
              >
                <Row gutter={[32, 0]}>
                  <Col span={12}>
                    <Row gutter={ this.props.isMobile ? [4,16] : [10, 16]}>
                      <Col span={24}>
                        <span className='data_text_detail'>
                          Homepage
                      </span>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          className='input_form'
                          name='subInd'
                          rules={[{
                            required: true,
                            message: 'Please Input Sub Judul (ind)!'
                          }]}
                        >
                          <Input
                            maxLength={500}
                            size="large"
                            className='input_style'
                            placeholder={"Sub Judul (Bahasa Indonesia)"} />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <span className="mandatoryLarge">&#8727;</span>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          className='input_form'
                          name='descIndo'
                          rules={[{
                            required: true,
                            message: 'Please Input Description (ind)!'
                          }]}
                        >
                          <Input.TextArea
                            size="large"
                            rows={8}
                            className='input_style'
                            placeholder={"Deskripsi (Bahasa Indonesia)"} />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <span className="mandatoryLarge">&#8727;</span>
                      </Col>
                      <Col span={24}>
                        <span className='data_text_detail'>
                          Impian Anak-Anak Kami
                        </span>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          className='input_form'
                          name='impianInd'
                          rules={[{
                            required: true,
                            message: 'Please Input Sub Judul (ind)!'
                          }]}
                        >
                          <Input.TextArea
                            size="large"
                            rows={6}
                            className='input_style'
                            placeholder={"Sub Judul (Bahasa Indonesia)"} />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <span className="mandatoryLarge">&#8727;</span>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row gutter={ this.props.isMobile ? [4,16] : [10, 16]}>
                      <Col span={24}>
                        <span className='data_text_detail'>
                          &nbsp;
                        </span>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          className='input_form'
                          name='subEng'
                          rules={[{
                            required: true,
                            message: 'Please Input Sub Judul (Eng)!'
                          }]}
                        >
                          <Input
                            maxLength={500}
                            size="large"
                            className='input_style'
                            placeholder={"Sub Judul (Bahasa Inggris)"} />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <span className="mandatoryLarge">&#8727;</span>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          className='input_form'
                          name='descEng'
                          rules={[{
                            required: true,
                            message: 'Please Input Description (Eng)!'
                          }]}
                        >
                          <Input.TextArea
                            size="large"
                            rows={8}
                            className='input_style'
                            placeholder={"Deskripsi (Bahasa Inggris)"} />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <span className="mandatoryLarge">&#8727;</span>
                      </Col>
                      <Col span={24}>
                        <span className='data_text_detail'>
                          &nbsp;
                        </span>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          className='input_form'
                          name='impianEng'
                          rules={[{
                            required: true,
                            message: 'Please Input Sub Judul (Eng)!'
                          }]}
                        >
                          <Input.TextArea
                            size="large"
                            rows={6}
                            className='input_style'
                            placeholder={"Sub Judul (Bahasa Inggris)"} />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <span className="mandatoryLarge">&#8727;</span>
                      </Col>
                      <Col span={23}>
                        <Form.Item
                          style={{
                            textAlign: 'right',
                            marginBottom: '0px'
                          }}
                        >
                          <Button
                            type="primary"
                            htmlType='submit'
                            className='updateButton'
                            loading={loadingSubmit}
                            size={'small'}
                          >
                            Submit
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col span={24}>
              <span className='data_text_detail'>
                Banner
              </span>
            </Col>
            {
              listBanner &&
              listBanner.map((res, index) => (
                <Col key={index + 'image'} span={24}>
                  <Row>
                    <Col span={this.props.isMobile ? 12 : 8}>
                      <ImagePreviewComponent
                        isMobile={this.props.isMobile}
                        styles={{ 
                          height: this.props.isMobile ? '100%' : 100, 
                          width: this.props.isMobile ? '100%' : 160 
                        }}
                        uri={res.imageUrl}
                      />
                      {/* <img height={100} width={160} src={res.imageUrl+'?'+new Date().getTime()} alt={'banner'+index}/> */}
                    </Col>
                    <Col span={this.props.isMobile ? 4 : 4} style={{ textAlign: 'left', margin: 'auto' }}>
                      <input
                        type="file"
                        hidden
                        onChange={event => this.fileHandler(event)}
                        ref={fileInput}
                      />
                      <Button loading={loadingUpload[index]} className='editButton' onClick={() => this.handleEditImage(index)} type="link">Edit</Button>
                    </Col>
                    <Col span={this.props.isMobile ? 6 : 12}>
                    </Col>
                  </Row>
                </Col>
              ))
            }
          </Row>
        </Card>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleDataHome: (token, id) => dispatch(homepageAction.getDataHomepage(token, id)),
    handleEditHome: (token, contentId, body) => dispatch(homepageAction.handleEdit(token, contentId, body))
  }
}

const mapStateToProps = (state) => {
  return {
    dataHome: state.homepage,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
