import { get, post, put } from 'axios'
import config from '../services/config'
const { BASE_URL_LOGIN, TIMEOUT } = config

const getProfileUniversitas = (token, univId, param) => {
  return get(`${BASE_URL_LOGIN}be/api/profileAdminUniv/${univId}?sort=${param.sort}&search=${param.search}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.items.map((res, index) =>{
      return {
        id: index,
        univId: res.univId,
        univCatName: res.univCatName,
        univName: res.univName,
        univApprovalContent: res.univApprovalContent,
        status: res.status,
        dataRes: res.dataRes
      }
    })
    return {
      data: normalizeData,
      totalData: response.data.totalItems,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const getDetailProfileUniversitas = (token, univId, dataRes) => {
  let api = `${BASE_URL_LOGIN}be/api/detailPublishAdminUniv/${univId}`;
  if ( dataRes === "univ_temp" ) {
    api = `${BASE_URL_LOGIN}be/api/detailNotPublishAdminUniv/${univId}`;
  } 
  return get(
    api,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response.data,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const getJalurMasuk = (token, univId, param) => {
  return get(
    `${BASE_URL_LOGIN}be/api/detailEntAdminUniv/${univId}?status=${param.status}&sort=${param.sort}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = response.data.items.map( res => (
      {
        id: res.univEntId,
        univEntId: res.univEntId,
        univEntName: res.univEntName,   
        isReceiveUpload: res.isReceiveUpload,
        univEntUrl: res.univEntUrl,
        univEntFormulirName: res.univEntFormulirName,
        univEntFormulirPath: res.univEntFormulirPath,
        isDisplayed: res.isDisplayed,
        univEntApprovalStatus: res.univEntApprovalStatus 
      })
    );

    return {
      data: normalizeData,
      status:'success',
      error: '',
      lastJalurMasuk: response.data.lastJalurMasuk
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const getDetailJalurMasuk = (token, univEntId, param) => {
  return get(
    `${BASE_URL_LOGIN}be/api/detEntrancePublisAdminUniv/${univEntId}?sort=${param.sort}&page=${param.page - 1}&size=${param.size}&search=${param.search}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = {
      ...response.data,
      resDetailUserUnivEnt: response.data.resDetailUserUnivEnt.map( res => (
        {
          ...res,
          id: res.uploadedFormId
        }
      ))
    };
    return {
      data: normalizeData,
      status:'success',
      error: '',
      totalData: response.data.resDetailUserUnivEntTotal
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: [],
      totalData: 0
    }
  })
}

const postReplaceImageLogoBannerHumas = (token, formData) => {
  return post(
    `${BASE_URL_LOGIN}be/api/uploadUpdateProfile`,
    formData,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response.data,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const postUploadLogo = (token, formData) => {
  return post(
    `${BASE_URL_LOGIN}be/api/uploadUnivRegisLogo`,
    formData,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response.data,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const postUploadBanner = (token, formData) => {
  return post(
    `${BASE_URL_LOGIN}be/api/uploadUpdateProfile`,
    formData,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response.data,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const postUploadHumas = (token, formData) => {
  return post(
    `${BASE_URL_LOGIN}be/api/uploadHumasPicImg`,
    formData,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response.data,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const postUploadForm = (token, formData) => {
  return post(
    `${BASE_URL_LOGIN}be/api/uploadUnivRegisEntForm`,
    formData,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response.data,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const postJalurMasuk = (token, body) => {
  return post(
    `${BASE_URL_LOGIN}be/api/addNewEntrancePublishAdminUniv`,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = {
        univEntId: response.data.items.univEntId,
        univEntName: response.data.items.univEntName,
        isReceiveUpload: response.data.items.isReceiveUpload,
        univEntUrl: response.data.items.univEntUrl,
        univEntFormulirName: response.data.items.univEntFormulirName,
        univEntFormulirPath: response.data.items.univEntFormulirPath,
        isDisplayed: response.data.items.isDisplayed,
        univEntApprovalStatus: response.data.items.univEntApprovalStatus
      }
    return {
      data: normalizeData,
      message: response.data.Message,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

const putEditProfileUniversitas = (token, univId, body) => {
  return put(
    `${BASE_URL_LOGIN}be/api/editAdminUniv/${univId}`,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: []
    }
  })
}

export default {
  getProfileUniversitas,
  getDetailProfileUniversitas,
  getJalurMasuk,
  getDetailJalurMasuk,
  postReplaceImageLogoBannerHumas,
  postUploadLogo,
  postUploadBanner,
  postUploadHumas,
  postUploadForm,
  postJalurMasuk,
  putEditProfileUniversitas,
}
