import { get, put } from "axios";
import config from "../services/config";


import moment from 'moment'

const { BASE_URL_LOGIN, TIMEOUT } = config;

const getData = (token, param) => {
   return get(`${BASE_URL_LOGIN}be/api/galleryUnivListAdmin?search=${param.search}&format=${param.format}&sort=${param.sort}&size=${param.size}&page=${param.page - 1}`,
      {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
         timeout: TIMEOUT
      }
   ).then( response => {
      const normalizeData = response.data.Data.map( res => {
         return {
            id: res.univGalleryId,
            univGalleryId: res.univGalleryId,
            univName: res.univName,
            univEventId: res.univEventId,
            univEventName: res.univEventName,
            univEventDate: moment(res.univEventDate).format('YYYY-MM-DD'),
            univGalleryPathUrl: res.univGalleryPathUrl,
            univGalleryVideoEmbed: res.univGalleryVideoEmbed,
            galleryApproval: res.galleryApproval,
            idGalleryApproval: res.idGalleryApproval
         }
      })
      return {
         data: normalizeData,
         totalData: response.data.TotalData,
         status: "success",
         error: ""
      }
   }).catch( error => {
      return {
         data: [],
         totalData: 0,
         status: "error",
         error
      }
   })
}

const approveDaftarPersetujuanGaleri = (token, body, param) => {
   return put(`${BASE_URL_LOGIN}be/api/galleryUnivApproveAdmin?univGalleryId=${param.univGalleryId}`,
      body,
      { 
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
         timeout: TIMEOUT
      }
   ).then( response => {
      return {
         data: response,
         totalData: 0,
         status: "success",
         error: ""
      }
   }).catch( error => {
      return {
         data: [],
         totalData: 0,
         status: "error",
         error
      }
   })
}

const rejectDaftarPersetujuanGaleri = (token, body, param) => {
   return put(`${BASE_URL_LOGIN}be/api/galleryUnivRejectAdmin?univGalleryId=${param.univGalleryId}`,
      body,
      { 
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
         timeout: TIMEOUT
      }
   ).then( response => {
      return {
         data: response,
         totalData: 0,
         status: "success",
         error: ""
      }
   }).catch( error => {
      return {
         data: [],
         totalData: 0,
         status: "error",
         error
      }
   })
}

const deleteDaftarPersetujuanGaleri = (token, body, param) => {
   return put(`${BASE_URL_LOGIN}be/api/galleryUnivDeleteAdmin?univGalleryId=${param.univGalleryId}`,
      body,
      { 
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
         timeout: TIMEOUT
      }
   ).then( response => {
      return {
         data: response,
         totalData: 0,
         status: "success",
         error: ""
      }
   }).catch( error => {
      return {
         data: [],
         totalData: 0,
         status: "error",
         error
      }
   })
}

export default {
   getData,
   approveDaftarPersetujuanGaleri,
   rejectDaftarPersetujuanGaleri,
   deleteDaftarPersetujuanGaleri
}