import React from 'react'
import { Form, Input, Row, Col, Typography, Button, Select } from 'antd'
import { EyeInvisibleOutlined } from '@ant-design/icons'

const { Option } = Select

const { Text } = Typography

const FormCore = props => {

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const defaultForm = [
    {
      label: 'Form default',
      name: 'formDefault',
      rules: [
        {
          required: true,
          message: 'Please input form default!'
        },
      ],
      placeholder: 'Form'
    }
  ]
  const buttonClass = props.buttonClass || ''
  const formItem = props.formItem || defaultForm
  const buttonName = props.buttonName || 'default'
  const withRow = props.withRow || false
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const proseccesType = data => {
    return(
      data.type === 'LOV' ? 
        <Select
          placeholder={data.placeholder}
          disabled={data.disabled}
          className="containter_select"
          // onChange={(event) => handleChange(event)}
          >
          {
            data.dataLOV ?
            data.dataLOV.map((item, index) => (
                <Option key={item.id + 'LOV' + index} value={item.id}>{item.value}</Option>  
              )
            ):(
              <></>
            )
          }
        </Select>
      :
      data.type === 'password' ?
        <Input.Password 
          placeholder={data.placeholder}
          suffix={<EyeInvisibleOutlined 
          color={data.passowrdColor}/>}
        />
        : 
        <Input 
          maxLength={data.maxLength} 
          placeholder={data.placeholder}
        /> 
    )
  }

  return(
    <Form
      {...layout}
      onFinish={props.finishForm}
      onFinishFailed={onFinishFailed}
    >
      {withRow ? 
        <Row gutter={20}>
          {formItem.map(val => {
            return (
              <Col span={val.colNumber}>
                <Text>{val.label}</Text>
                <Form.Item 
                  key={val.name}
                  name={val.name}
                  rules={val.rules}
                >
                  {proseccesType(val)}
                </Form.Item>
              </Col>
              )
            })
          }
        </Row> 
        :
        <> 
          {formItem.map(val => {
            return (
              <Form.Item 
                key={val.name}
                label={val.label}
                name={val.name}
                rules={val.rules}
              >
                {proseccesType(val)}
              </Form.Item>
              )
            })
          }
        </>
      }
      <Form.Item className='form_Button'>
        {props.antdButton ? 
          <Button type='primary' htmlType='submit'>
            {buttonName}
          </Button>
          :
          <button className={buttonClass}>
            {buttonName}
          </button>
        }
      </Form.Item>
    </Form>
  )
}


export const UIForm = FormCore 