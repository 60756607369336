const GET_KOTA_LOADING = 'GET_KOTA_LOADING';
const GET_KOTA_SUCCESS = 'GET_KOTA_SUCCESS';
const GET_KOTA_FAILED = 'GET_KOTA_FAILED';
const CREATE_KOTA_LOADING = 'CREATE_KOTA_LOADING';
const CREATE_KOTA_SUCCESS = 'CREATE_KOTA_SUCCESS';
const CREATE_KOTA_FAILED = 'CREATE_KOTA_FAILED';
const PUT_KOTA_LOADING = 'PUT_KOTA_LOADING';
const PUT_KOTA_SUCCESS = 'PUT_KOTA_SUCCESS';
const PUT_KOTA_FAILED = 'PUT_KOTA_FAILED';
const DELETE_KOTA_LOADING = 'DELETE_KOTA_LOADING';
const DELETE_KOTA_SUCCESS = 'DELETE_KOTA_SUCCESS';
const DELETE_KOTA_FAILED = 'DELETE_KOTA_FAILED';

export {
   GET_KOTA_LOADING,
   GET_KOTA_SUCCESS,
   GET_KOTA_FAILED,
   CREATE_KOTA_LOADING,
   CREATE_KOTA_SUCCESS,
   CREATE_KOTA_FAILED,
   PUT_KOTA_LOADING,
   PUT_KOTA_SUCCESS,
   PUT_KOTA_FAILED,
   DELETE_KOTA_LOADING,
   DELETE_KOTA_SUCCESS,
   DELETE_KOTA_FAILED
}