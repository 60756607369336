import React, { createRef, useEffect, useState } from "react";
import {
   Row, Col, Button, Modal, Form, Select, 
   Switch, Input, Typography, DatePicker, TimePicker, Radio
} from "antd";
import "../../global/global.css";
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';

const { Title } = Typography;

function ModalCreateDaftarGaleri(props) {
	const [form] = Form.useForm();
  const [valueRadio, setRadio] = useState(null);

	const inputFile = createRef();
	
	useEffect(() => {
    if (props.visible === true) {
      form.resetFields();
			setRadio(null);
      form.setFieldsValue(props.initData)
    }
  }, [props.visible])

	const onFinish = (values) => {
		props.onFinish(values);
	} 

	const onFinishFailed = error => {
		console.error(error);
	}

	const handleResetForm = () => {
		form.resetFields();
    setRadio(null);
		props.handleReset();
	}

	const handleClose = () => {
		form.resetFields();
		props.handleClose()
	}

	const handleChangeEventName = value => {
		props.handleChangeEventName(value);
	}

	const onSelect = value => {
		const data = props.onSelect(value)
		form.setFieldsValue(data)
	}

	const handleRadioChange = (val) => {
    setRadio(val.target.value);
  };

	const handleClickUpload = () => {
		inputFile.current.click();
	}

	const handleChangeInputFile = (event) => {
		props.handleChangeInputFile(event);
	}

	return (
		<Modal
			visible={props.visible}
			footer={null}
			className={props.isMobile ? 'modalMobile' : 'modalImage'}
			width={props.isMobile ? '100%' : 633}
			centered
			onCancel={() => handleClose()}
			destroyOnClose={true}
		>
			<Row>
				<Col span={24}>
						<div className="headerForm">
							<Title className="titleModalGalery" level={4}>Tambah Galeri</Title>
						</div>
						<div style={{ width: 300}} className='dividerGalery'/>
				</Col>
				<Col span={24}>
				<Form
					form={form}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					initialValues={props.initData}
				>
					<Row gutter={[8, 10]} align="middle">
						<Col span={24}>
							<span className="data_text_detail">Institusi: </span>
							<span className="text_detail_institusi">{props.institusiName}</span>
						</Col>
						<Col span={24}>
							<span className='termText'>
							*Apabila acara belum terdaftar dapat mengisi nama acara pada kolom Nama Acara
							</span>
						</Col>
						<Col span={24}>
							<Form.Item
								style={{marginBottom: 0}}
								name="eventName"
								rules={[
									{
										required: false,
										message: 'Please select event name!',
									}
								]}
							>
								<Select 
									showSearch
									placeholder="Nama Acara - Hari / Tanggal Presentasi" 
									className="select" 
									onChange={(value) => onSelect(value)}
									onSearch={val => handleChangeEventName(val)}
									notFoundContent={null}
									defaultActiveFirstOption={false}
									showArrow={false}
									filterOption={false}
								>
									{props.optionsSearch}
								</Select>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
									style={{marginBottom: 0}}
									name="univGalleryEventName"
									rules={[
										{
											required: true,
											message: 'Please select day / date presentation',
										}
									]}
							>
								<Input
									placeholder="Nama Acara"
									disabled={props.isSelected}
								>
								</Input>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
									style={{marginBottom: 0}}
									name="univGalleryEventDesc"
									rules={[
										{
											required: true,
											message: 'Please select day / date presentation',
										}
									]}
							>
								<Input
									placeholder="Dekripsi Acara"
									disabled={props.isSelected}
								>
								</Input>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								// style={{marginBottom: 0}}
								name="univGalleryEventDate"
								rules={[
									{
										required: true,
										message: 'Please select day / date presentation',
									}
								]}
							>
								<DatePicker
									style={{width: '100%'}}
									placeholder="Tanggal"
									format={'dddd, YYYY-MM-DD'}
									disabled={props.isSelected}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
									// style={{marginBottom: 0}}
									name="univGalleryEventTime"
									rules={[
										{
											required: true,
											message: 'Please select time presentation',
										}
									]}
							>
								<TimePicker
									style={{width: '100%'}}
									placeholder="Jam"
									disabled={props.isSelected}
									format={'HH:mm:ss'}
								/>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								name="isOnline"
								rules={[
									{ required: true, message: "Please select an item!" },
								]}
								style={{ marginBottom: 0 }}
							>
								<Radio.Group
									onChange={(val) => handleRadioChange(val)}
									value={valueRadio}
									disabled={props.isSelected}
								>
									<Radio value={true}>Online</Radio>
									<Radio value={false}>Offline</Radio>
								</Radio.Group>
							</Form.Item>
						</Col>
						<Col span={24}>
							<span className="data_text_detail">Media</span>
						</Col>
						<Col span={3}>
							<span className="header_text_detail">Gambar</span>
						</Col>
						<Col span={21}>
							<Form.Item
								name="isGambar"
								style={{marginBottom: 0}}
							>
								<Switch size="small" onChange={props.handleChangeGambar}/>
							</Form.Item>
						</Col>
							{ props.isGambar && 
								(
									<>
										<Col span={24} style={{textAlign: "right"}}>
											<input
												type="file"
												hidden
												onChange={handleChangeInputFile}
												ref={inputFile}
												multiple
												accept="image/x-png,image/gif,image/jpeg"
											/>
											{ props.fileName &&
												props.fileName.map( (res, index) => {
													return (
														<span key={index}>{res.name}, </span>
													)
												})
											}
											<Button 
												className="donwloadButton" 
												onClick={handleClickUpload} 
												loading={props.loadingUpload}
												style={{ marginLeft: "10px" }}
											>
												Upload
											</Button>
										</Col>
										<Col span={24} style={{textAlign: "right"}}>
											<span style={{color: "#EE0202"}}>*</span>
											<span>Jika lebih dari 1, Pilih sekaligus</span>
										</Col>
									</>
								)
							}
							<Col span={24}>
								<hr/>
							</Col>
							<Col span={3}>
								<span className="header_text_detail">Video</span>
							</Col>
							<Col span={21}>
								<Form.Item
									style={{marginBottom: 0}}
									name="isVideo"
								>
									<Switch size="small" onChange={props.handleChangeVideo}/>
								</Form.Item>
							</Col>
								{props.isVideo && (
									<Col span={24}>
										<Form.List name="linkYoutube">
											{(fields, { add, remove }) => (
												<>
													{fields.map((field, index) => (
														<Row gutter={[8, 10]} key={index}>
															<Col span={22}>
																<Form.Item
																	{...field}
																	name={[field.name]}
																	fieldKey={[field.fieldKey]}
																	style={{ marginBottom: 0 }}
																	rules={[
																		{
																			required: true,
																			message: 'Please input URL video youtube',
																		},
																		{ 
																			pattern: /^http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/ , 
																			message: 'Invalid youtube URL!' 
																		}
																	]}
																>
																<Input 
																	placeholder="URL Video Youtube" 
																	className="input_style"
																	size="large"
																/>
															</Form.Item>
															</Col>
															<Col span={2} style={{textAlign: "center"}}>
																<MinusCircleOutlined onClick={() => remove(field.name)} style={{ fontSize: "28px" }}/>
															</Col>
														</Row>
													))}
													<Row align="middle" gutter={[8, 10]}>
														<Col span={22}>
																<div style={{backgroundColor: "#EF712B", height: "2px"}}/>
														</Col>
														<Col span={2} style={{textAlign: "center"}}>
																<PlusCircleOutlined  onClick={() => add()} style={{ fontSize: "28px", color: "#EF712B" }}/>
														</Col>
													</Row>
												</>
											)}
										</Form.List>
									</Col>
								)
							}
							<Col span={24}>
								<Form.Item
									style={{
										textAlign: 'right',
										marginBottom: '0px'
									}}
								>
									<Button
										type="link"
										className='resetButton'
										size={'small'}
										onClick={handleResetForm}
									>
										Reset
									</Button>
									<Button
										type="primary"
										htmlType='submit'
										className='updateButton'
										loading={props.loadingSubmit}
										size={'small'}
									>
										Submit
									</Button>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Col>
			</Row>
	</Modal>
	)
}

export const UIModalCreateDaftarGaleri = ModalCreateDaftarGaleri;