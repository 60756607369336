import { get, put, post } from "axios";
import config from "../services/config";

const { BASE_URL_LOGIN, TIMEOUT } = config;

const getData = param => {
   return get(`${BASE_URL_LOGIN}be/api/provinceAdmin/readAll?filter=${param.filter}&orderby=${param.orderBy}&top=${param.top}&skip=${param.skip}`,
      {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${param.token}`,
         },
         timeout: TIMEOUT
      }
   ).then( response => {
      const normalizeData = response.data.items.map( res => {
         return {
            id: res.provinceId ,
            provinceId: res.provinceId,
            provinceName: res.provinceName,
            countryId: res.countryId.countryId,
            countryName: res.countryId.countryName
         }
      })
      return {
         data: normalizeData,
         totalData: response.data.totalItems,
         status: "success",
         error: ""
      }
   }).catch( error => {
      return {
         data: [],
         totalData: 0,
         status: "error",
         error
      }
   })
}

const createProvinsi = (token, body) => {
   return post(`${BASE_URL_LOGIN}be/api/submitProvinceAdmin`,
      body,
      {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
         },
         timeout: TIMEOUT
      }
   ).then(response => {
      return {
         data: response,
         status: "success",
         error: ''
      }
   }).catch(error => {
      return {
         data: [],
         status: "error",
         error
      }
   })
}

const editProvinsi = (token, body, id) => {
   return put(`${BASE_URL_LOGIN}be/api/updateProvinceAdmin/${id}`,
      body,
      {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`

         },
         timeout: TIMEOUT
      }
   ).then(response => {
      return {
         data: response,
         status: "success",
         error: ""
      }
   }).catch(error => {
      return {
         data: [],
         status: "error",
         error
      }
   })
}

const deleteProvinsi = (token, body, id) => {
   return put(`${BASE_URL_LOGIN}be/api/deleteProvinceAdmin/${id}`,
      body,
      {
         headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
         },
         timeout: TIMEOUT
      }
   ).then(response => {
      return {
         data: response,
         status: "success",
         error: ""
      }
   }).catch(error => {
      return {
         data: [],
         status: "error",
         error
      }
   })
}

export default {
   getData,
   createProvinsi,
   editProvinsi,
   deleteProvinsi
}