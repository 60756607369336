const GET_FAQ_LOADING = 'GET_FAQ_LOADING'
const GET_FAQ_SUCCESS = 'GET_FAQ_SUCCESS'
const GET_FAQ_FAILED = 'GET_FAQ_FAILED'
const GET_FAQDETAIL_LOADING = 'GET_FAQDETAIL_LOADING'
const GET_FAQDETAIL_SUCCESS = 'GET_FAQDETAIL_SUCCESS'
const GET_FAQDETAIL_FAILED = 'GET_FAQDETAIL_FAILED'
const GET_FAQCAT_LOADING = 'GET_FAQCAT_LOADING'
const GET_FAQCAT_SUCCESS = 'GET_FAQCAT_SUCCESS'
const GET_FAQCAT_FAILED = 'GET_FAQCAT_FAILED'
const CREATE_FAQ_LOADING = 'CREATE_FAQ_LOADING'
const CREATE_FAQ_SUCCESS = 'CREATE_FAQ_SUCCESS'
const CREATE_FAQ_FAILED = 'CREATE_FAQ_FAILED'
const PUT_FAQ_LOADING = 'PUT_FAQ_LOADING'
const PUT_FAQ_SUCCESS = 'PUT_FAQ_SUCCESS'
const PUT_FAQ_FAILED = 'PUT_FAQ_FAILED'
const DELETE_FAQ_LOADING = 'DELETE_FAQ_LOADING'
const DELETE_FAQ_SUCCESS = 'DELETE_FAQ_SUCCESS'
const DELETE_FAQ_FAILED = 'DELETE_FAQ_FAILED'

export {
  GET_FAQ_LOADING,
  GET_FAQ_SUCCESS,
  GET_FAQ_FAILED,
  GET_FAQDETAIL_LOADING,
  GET_FAQDETAIL_SUCCESS,
  GET_FAQDETAIL_FAILED,
  GET_FAQCAT_LOADING,
  GET_FAQCAT_SUCCESS,
  GET_FAQCAT_FAILED,
  CREATE_FAQ_LOADING,
  CREATE_FAQ_SUCCESS,
  CREATE_FAQ_FAILED,
  PUT_FAQ_LOADING,
  PUT_FAQ_SUCCESS,
  PUT_FAQ_FAILED,
  DELETE_FAQ_LOADING,
  DELETE_FAQ_SUCCESS,
  DELETE_FAQ_FAILED,
}
