const GET_PROVINSI_LOADING = 'GET_PROVINSI_LOADING';
const GET_PROVINSI_SUCCESS = 'GET_PROVINSI_SUCCESS';
const GET_PROVINSI_FAILED = 'GET_PROVINSI_FAILED';
const CREATE_PROVINSI_LOADING = 'CREATE_PROVINSI_LOADING';
const CREATE_PROVINSI_SUCCESS = 'CREATE_PROVINSI_SUCCESS';
const CREATE_PROVINSI_FAILED = 'CREATE_PROVINSI_FAILED';
const PUT_PROVINSI_LOADING = 'PUT_PROVINSI_LOADING';
const PUT_PROVINSI_SUCCESS = 'PUT_PROVINSI_SUCCESS';
const PUT_PROVINSI_FAILED = 'PUT_PROVINSI_FAILED';
const DELETE_PROVINSI_LOADING = 'DELETE_PROVINSI_LOADING';
const DELETE_PROVINSI_SUCCESS = 'DELETE_PROVINSI_SUCCESS';
const DELETE_PROVINSI_FAILED = 'DELETE_PROVINSI_FAILED';

export {
   GET_PROVINSI_LOADING,
   GET_PROVINSI_SUCCESS,
   GET_PROVINSI_FAILED,
   CREATE_PROVINSI_LOADING,
   CREATE_PROVINSI_SUCCESS,
   CREATE_PROVINSI_FAILED,
   PUT_PROVINSI_LOADING,
   PUT_PROVINSI_SUCCESS,
   PUT_PROVINSI_FAILED,
   DELETE_PROVINSI_LOADING,
   DELETE_PROVINSI_SUCCESS,
   DELETE_PROVINSI_FAILED
}