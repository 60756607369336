import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../global/global.css'
import impianAction from '../../actions/impian'
import {
  Card,
  notification,
  Button,
  Row,
  Col,
  Breadcrumb,
  Typography,
  Modal,
  Popconfirm
} from 'antd'
import { CaretRightOutlined, CheckCircleFilled } from '@ant-design/icons'
import { UItable, UISearch, UIModalCreate } from '../../components'
import ImagePreviewComponent from '../../components/imagePreview'
import {GlobalVideoCheck} from '../../global/checkingVideo'

const { Text } = Typography

class ListImpian extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initial: '',
      orderBy: 'last_modified_by,desc',
      size: 10,
      page: 1,
      search: '',
      loading: false,
      visibleCreate: false,
      dataVid: '',
      modalVid: false,
      loadingButton: [false]
    }
  }

  componentDidMount() {
    const {orderBy, size, page, search} = this.state
    const body = {
      token: this.props.token,
      sort: orderBy,
      size: size,
      page: page,
      search: search
    }
    this.props.handleData(body)
  }

  handleSearch = key => {
    const {orderBy, size, page} = this.state
    this.setState({
      search: key,
      page: 1
    })
    const body = {
      token: this.props.token,
      sort: orderBy,
      size: size,
      page: page,
      search: key
    }
    this.props.handleData(body)
  }

  openModal = () => {
    this.setState({
      visibleCreate: true
    })
  }

  handleTableChange = (page, filter, sorter) => {
    const {size, search} = this.state

    let sorterField = ""
    if (sorter.field === "button") {
      // sorterField = "a.vision_det_student_name";
    } else {
      sorterField = "vision_det_student_name";
    }

    let sort = "last_modified_by,desc";
    if (sorter.order === "ascend") {
      sort = `${sorterField},asc`;
    } else if (sorter.order === "descend") {
      sort = `${sorterField},desc`;
    }
    const body = {
      token: this.props.token,
      sort: sort,
      size: size,
      page: page.current,
      search: search
    }
    this.setState({
      page: page.current,
      orderBy: sort
    }, () => {
      this.props.handleData(body)
    })
  }

  handleCloseModal = () => {
    this.setState({
      visibleCreate: false
    })
  }

  handleFinish = values => {
    this.setState({
      loadingSubmit: true
    })

    const bodySend = {
      visionDetStudentName: values.namaSiswa,
      visionDetStudentClass: values.kelas,
      visionDetStudentSchoolYear: values.tahunAjaran,
      visionDetStudentMajor: values.jurusan,
      visionDetStudentDesc: values.description,
      visionDetStudentYoutubeUrl: values.urlYoutube,
      createdBy: this.props.userId,
      lastModifiedBy: this.props.userId
    }

    const body = {
      token: this.props.token,
      sort: '',
      size: 10,
      page: 1,
      search: '',
    }

    this.props.handleCreate(this.props.token, bodySend).then(res => {
      if (res.payload.status !== 'error') {
        if (res.payload.data.data.Status === 'OK') {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Create Galeri Impian Anak-Anak Success',
          });
          this.setState({
            search: '',
            page: 1,
            orderBy: 'last_modified_by,desc',
            loadingSubmit: false,
            visibleCreate: false
          }, () => {
            this.props.handleData(body)
          })
        }else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Create Galeri Impian Anak-Anak Failed'
          });
          this.setState({
            loadingSubmit: false
          })
        }
      }else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: 'Create Galeri Impian Anak-Anak Failed'
        });
        this.setState({
          loadingSubmit: false
        })
      }
    })
  }

  playVid = val => {
    this.setState({
      dataVid: val,
      modalVid: true
    })
  }

  handleClose = () => {
    this.setState({
      dataVid: '',
      modalVid: false
    })
  }

  handleUpdate = (id, status, index) => {
    let temp = this.state.loadingButton
    temp[index] = true
    this.setState({
      loadingButton: temp
    })
    const body = {
      token: this.props.token,
      sort: '',
      size: 10,
      page: 1,
      search: '',
    }
    if (status === 'show') {
      this.props.handleShow(this.props.token, id).then(res => {
        if (res.payload.data.data.Status === 'OK') {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Update Status Kerjasama Success',
          });
          temp[index] = false
          this.setState({
            search: '',
            page: 1,
            orderBy: 'last_modified_by,desc',
            loadingButton: temp
          }, () => {
            this.props.handleData(body)
          })
        }else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Update Status Kerjasama Failed'
          });
          temp[index] = false
          this.setState({
            loadingButton: temp
          })
        }
      })
    }else {
      this.props.handleHide(this.props.token, id).then(res => {
        if (res.payload.data.data.Status === 'OK') {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Update Status Kerjasama Success',
          });
          temp[index] = false
          this.setState({
            search: '',
            page: 1,
            orderBy: 'last_modified_by,desc',
            loadingButton: temp
          }, () => {
            this.props.handleData(body)
          })
        }else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Update Status Kerjasama Failed'
          });
          temp[index] = false
          this.setState({
            loadingButton: temp
          })
        }
      })
    }
  }


  handleDelete = (id) => {
    let temp = this.state.loadingButton
    temp = true
    this.setState({
      loadingButton: temp
    })
    const body = {
      token: this.props.token,
      sort: this.state.orderBy,
      size: 10,
      page: 1,
      search: this.state.search,
    }

    this.props.handleDelete(this.props.token, id).then(res => {
      if (res.payload.data.data) {
        notification.success({
          placement: 'bottomRight',
          message: 'Success',
          description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Delete Record Success',
        });
        temp = false
        this.setState({
          search: '',
          page: 1,
          orderBy: 'last_modified_by,desc',
          loadingButton: temp
        }, () => {
          this.props.handleData(body)
        })
      }
    }).catch(err => {
      if(err){
        console.log('err : ',err.response)
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: err.response.data ? err.response.data.message : 'Delete Record Failed'
        });
        temp = false
        this.setState({
          loadingButton: temp
        })
      }
    })
  }

  handleDirect = id => {
    this.props.history.push(`/galeri-impian-detail/${id}`)
  }

  render() {

    const columnsTable = [
      {
        title: 'Daftar Video',
        dataIndex: '',
        key: 'visionDetId',
        sorter: true,
        width: '50%',
        render: (data) =>
          <div>
            <Row gutter={[ 16, 0]}>
              <Col span={8}>
                <div className='gradientCard'>
                  <CaretRightOutlined className='playButton'
                    onClick={() => this.playVid(data.visionDetStudentYoutubeUrlEmbed)}
                  />
                  <ImagePreviewComponent
                    isMobile={this.props.isMobile}
                    styles={{ width: '100%' }}
                    uri={data.visionDetStudentYoutubeUrlImage}
                    imageBorder={'6px'}
                  />
                  {/* <img
                    alt="example"
                    src={data.visionDetStudentYoutubeUrlImage}
                    width='100%'
                    style={{borderRadius: '6px'}}
                  /> */}
                </div>
              </Col>
              <Col span={16}>
                <Row>
                  <Col span={24}>
                    <Text onClick={() => this.handleDirect(data.id)} style={{ color: '#EF712B', cursor: 'pointer', fontWeight: 600 }}>{data.visionDetStudentName}</Text>,
                  </Col>
                  <Col span={24}>
                  <Text style={{ color: '#797979', fontWeight: 'normal' }}>{data.visionDetStudentDesc}</Text>,
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
      },
      {
        title: 'Status Tampil',
        dataIndex: 'button',
        key: 'button',
        sorter: true,
        align: 'center',
        render: (button) =>
        <>
          {button === 'Sembunyikan' ? (
            <CheckCircleFilled style={{ color: '#21296C', fontSize: '30px'}} />
          ) : (
            <>
            </>
          ) }
        </>
      },
      {
        title: 'Action',
        dataIndex: '',
        key: '',
        width: '20%' ,
        render: (record, row, index) =>
        <>
          {record.button === 'Sembunyikan' ? (
            <Button
              type="primary"
              className='updateButton'
              onClick={() => this.handleUpdate(record.id, 'hide', index)}
              loading={this.state.loadingButton[index]}
              size={'small'}
            >
              Sembunyikan
            </Button>
          ) : (
            <div>
              <Button
                type="primary"
                className='buttonUpload'
                onClick={() => this.handleUpdate(record.id, 'show', index)}
                loading={this.state.loadingButton[index]}
                size={'small'}
              >
                Tampilkan
              </Button>
              <div style={{height: 10}}/>
              <Popconfirm
                placement="bottomLeft"
                title={'Yakin ingin menghapus data ini?'}
                onConfirm={() => this.handleDelete(record.id)}
                okText="Yes"
                cancelText="No">
                <Button
                  type='primary'
                  className='deleteButton2'
                  loading={this.state.loadingButton[index]}
                  size={'small'}
                >
                  Delete
                </Button>
              </Popconfirm>
            </div>
          )}
        </>
      },
    ]

    const formItem = [
      {
        name: 'namaSiswa',
        type: 'input',
        maxLength: 256,
        colNumber: 23,
        rules: [
          {
            required: true,
            message: 'Please input student\'s full name!'
          }
        ],
        placeholder: 'Nama Lengkap Siswa'
      },
      {
        name: 'kelas',
        type: 'input',
        maxLength: 15,
        colNumber: 11,
        rules: [
          {
            required: true,
            message: 'Please input class!'
          }
        ],
        placeholder: 'Kelas'
      },
      {
        name: 'tahunAjaran',
        maxLength: 5,
        type: 'input',
        colNumber: 11,
        rules: [
          {
            required: true,
            message: 'Please input year!'
          }
        ],
        placeholder: 'Tahun Ajaran'
      },
      {
        name: 'jurusan',
        maxLength: 30,
        type: 'input',
        colNumber: 11,
        rules: [
          {
            required: true,
            message: 'Please input jurusan!'
          }
        ],
        placeholder: 'Jurusan'
      },
      {
        name: 'blank',
        type: 'blank',
        colNumber: 11,
      },
      {
        name: 'description',
        type: 'textArea',
        rowsText: 6,
        colNumber: 23,
        rules: [
          {
            required: true,
            message: 'Please input description!'
          }
        ],
        placeholder: 'Deskripsi'
      },
      {
        name: 'urlYoutube',
        type: 'input',
        colNumber: 23,
        rules: [
          {
            required: true,
            message: 'Please input URL youtube!'
          },
          {
            pattern:   /^http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/ , 
            message: 'Invalid youtube URL!' 
          }
        ],
        placeholder: 'URL Youtube'
      },
    ]

    const {page, visibleCreate, loading, dataVid, modalVid} = this.state
    const { dataImpian } = this.props
    return (
      <div className='root'>
        <Breadcrumb className="breadcrumb_style">
          <Breadcrumb.Item>
            <span style={{cursor: 'pointer'}} onClick={() => this.props.history.push(`/dashboard`)}>Pengaturan Umum</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className='pathNow_style'>
            Galeri Impian Anak-Anak Kami
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card className='bodyCard_style'>
          <Row>
            <Col span={8}>
              <UISearch placeholder='Search' handleSearch={key => this.handleSearch(key)} />
            </Col>
            <Col span={16} style={{ textAlign: 'right'}}>
              <Button onClick={() => this.openModal()} className='updateButton'>Tambah Video</Button>
            </Col>
          </Row>
          <Card
            bordered={false}
            loading={dataImpian.loading}
            style={{minHeight: '300px'}}>
            <UItable
              dataTable={dataImpian.data}
              columns={columnsTable}
              withRows={false}
              isMobile={this.props.isMobile}
              loading={dataImpian.status}
              page={page}
              totalData={dataImpian.totalData}
              handleTableChange={(pageNow, filters, sorter) => this.handleTableChange(pageNow, filters, sorter)}
            />
          </Card>
          <Col span={12}>
            <span className='termText'>
              *Pilih (Maks. 10) Video untuk ditampilkan di Homepage
            </span>
          </Col>
        </Card>
        <UIModalCreate
          isMobile={this.props.isMobile}
          formItem={formItem}
          visible={visibleCreate}
          loading={loading}
          title='Tambah Video'
          widthDivider='250px'
          close={() => this.handleCloseModal()}
          checkFinish={ values => this.handleFinish(values)}
          loadingSubmit={false}
        />
        <Modal
          visible={modalVid}
          centered
          className={this.props.isMobile ? 'cardVideoMobile' : 'cardVideo'}
          footer={null}
          width={this.props.isMobile ? '100%' : '75%'}
          onCancel={() => this.handleClose()}
        >
          {GlobalVideoCheck.checking(dataVid) ? (
            <iframe
              style={{ width: '-webkit-fill-available', height: this.props.isMobile ? '280px' : '500px' }}
              id="modalVideo"
              title='modalVideo'
              src={dataVid}
              frameBorder="0"
              allow="accelerometer;
              autoplay;
              encrypted-media;
              gyroscope;
              picture-in-picture"
              allowFullScreen="allowfullscreen"
            />
            ) : (
              <span className='data_text_detail'>
                Url Video Tidak Valid/Error
            </span>
          )
          }
        </Modal>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return{
    handleData: (param) => dispatch(impianAction.getData(param)),
    handleShow: (token, id) => dispatch(impianAction.handleShow(token, id)),
    handleHide: (token, id) => dispatch(impianAction.handleHide(token, id)),
    handleCreate: (token, body) => dispatch(impianAction.handleCreate(token, body)),
    handleDelete: (token, id) => dispatch(impianAction.handleDelete(token, id)),
  }
}

const mapStateToProps = (state) => {
  return {
    dataImpian: state.impian,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ListImpian)
