import React, { Component } from "react";
import { connect } from "react-redux";
import "../../global/global.css";
import {
  UIDetailAdminUnivDaftarAcara,
} from "../../components";
import { Card, notification, Breadcrumb } from "antd";
import getDetailAcara from "../../actions/DaftarAcaraInUniv";

class AdminUnivDaftarAcaraTambah extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initial: "",
      loadingPost: false,
    };
  }

  componentDidMount() {

  }

  handleRegisSubmit = (value) => {
    this.setState({
      loadingPost: true
    })
    this.props.handlePostEvent(this.props.token, value).then(res => {
      if (res.payload.status !== 'error') {
        if (res.payload.data.status === 200) {
          notification.success({
            placement: 'bottomRight',
            message: 'Success',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Registrasi Event Success',
          });
          setTimeout(() => {
            this.props.history.push(`/daftar-acara-universitas`)
          },2000);
          this.setState({
            loadingPost: false,
          })
        } else {
          notification.error({
            placement: 'bottomRight',
            message: 'Error',
            description: res.payload.data.data.Message ? res.payload.data.data.Message : 'Registrasi Event Failed'
          });
          this.setState({
            loadingPost: false,
          })
        }
      } else {
        notification.error({
          placement: 'bottomRight',
          message: 'Error',
          description: 'Registrasi Event Failed'
        });
        this.setState({
          loadingPost: false,
        })
      }
    })
  }

  render() {
    const { loadingPost } = this.state;
    return (
      <div className="root">
        <Breadcrumb className="breadcrumb_style">
          <Breadcrumb.Item>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => this.props.history.push(`/daftar-acara-universitas`)}
            >
              Daftar Acara{" "}
            </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="pathNow_style">
            Tambah Acara
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card
          className="bodyCard_style"
        >
          <UIDetailAdminUnivDaftarAcara
            from={"add-event"}
            isMobile={this.props.isMobile}
            loadingPost={loadingPost}
            handleFinish={(value) => this.handleRegisSubmit(value)}
          />
        </Card>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handlePostEvent: (token, body) => dispatch(getDetailAcara.postDataEvent(token, body)),
  };
};

const mapStateToProps = (state) => {
  return {

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminUnivDaftarAcaraTambah);
