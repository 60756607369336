const GET_TYPE_LOGGING_LOADING = 'GET_TYPE_LOGGING_LOADING'
const GET_TYPE_LOGGING_SUCCESS = 'GET_TYPE_LOGGING_SUCCESS'
const GET_TYPE_LOGGING_FAILED = 'GET_TYPE_LOGGING_FAILED'
const GET_DOWNLOAD_LOGGING_LOADING = 'GET_DOWNLOAD_LOGGING_LOADING'
const GET_DOWNLOAD_LOGGING_SUCCESS = 'GET_DOWNLOAD_LOGGING_SUCCESS'
const GET_DOWNLOAD_LOGGING_FAILED = 'GET_DOWNLOAD_LOGGING_FAILED'

export {
  GET_TYPE_LOGGING_LOADING,
  GET_TYPE_LOGGING_SUCCESS,
  GET_TYPE_LOGGING_FAILED,
  GET_DOWNLOAD_LOGGING_LOADING,
  GET_DOWNLOAD_LOGGING_SUCCESS,
  GET_DOWNLOAD_LOGGING_FAILED,
}
