import { get, put } from 'axios'
import config from '../services/config'
const { BASE_URL_LOGIN, TIMEOUT } = config

const getDataHomepage = (token) => {
  return get(`${BASE_URL_LOGIN}be/api/homepageSettingAdmin`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    const normalizeData = 
      {
        bidiktiContentId: response.data.Data.bidiktiContentId ? response.data.Data.bidiktiContentId : '',
        bidiktiContentInd: response.data.Data.bidiktiContentInd ? response.data.Data.bidiktiContentInd : '',
        bidiktiContentEng: response.data.Data.bidiktiContentEng ? response.data.Data.bidiktiContentEng : '',
        visionInd: response.data.Data.visionInd ? response.data.Data.visionInd : '',
        visionEng: response.data.Data.visionEng ? response.data.Data.visionEng : '',
        bidiktiContentSubtitleInd: response.data.Data.bidiktiContentSubtitleInd ? response.data.Data.bidiktiContentSubtitleInd : '',
        bidiktiContentSubtitleEng: response.data.Data.bidiktiContentSubtitleEng ? response.data.Data.bidiktiContentSubtitleEng : '',
        listWelcomeImage: response.data.Data.listWelcomeImage ? response.data.Data.listWelcomeImage : []
      }
    return {
      data: normalizeData,
      status:'success',
      error: ''
    }
  }).catch(error => {
    return {
      status: 'error',
      totalData: 0,
      error,
      data: []
    }
  })
}

const handleEdit = (token, contentId, body) => {
  return put(`${BASE_URL_LOGIN}be/api/homepageSettingAdmin?bidiktiContentId=${contentId}`,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: TIMEOUT
    }
  ).then(response => {
    return {
      data: response,
      status:'success',
      error: '',
    }
  }).catch(error => {
    return {
      status: 'error',
      error,
      data: [],
    }
  })
}


export default {
  getDataHomepage,
  handleEdit
}